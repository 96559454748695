// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var List = require("rescript/lib/js/list.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");
var RandomId$ReactHooksTemplate = require("./RandomId.bs.js");

function empty(param) {
  return {
          id: undefined,
          uuid: RandomId$ReactHooksTemplate.generate(undefined),
          text: "",
          description: "",
          groups: {
            hd: [
              RandomId$ReactHooksTemplate.toString(RandomId$ReactHooksTemplate.generate(undefined)),
              ""
            ],
            tl: /* [] */0
          },
          carryForwardFrom: undefined,
          answerRequirement: /* All */0,
          representation: /* Smiley */0,
          answer: undefined
        };
}

function answerRequirementMet(answers, t) {
  var answersSize = Belt_List.size(answers);
  var x = t.answerRequirement;
  if (typeof x === "number") {
    return Belt_List.size(t.groups) === answersSize;
  }
  switch (x.TAG | 0) {
    case /* AtLeast */0 :
        return x._0 <= answersSize;
    case /* AtMost */1 :
        return x._0 >= answersSize;
    case /* Exactly */2 :
        return x._0 === answersSize;
    case /* Range */3 :
        if (x._0 <= answersSize) {
          return x._1 >= answersSize;
        } else {
          return false;
        }
    
  }
}

function make(text, description, groups, representation, uuid) {
  return {
          id: undefined,
          uuid: uuid,
          text: text,
          description: description,
          groups: groups,
          carryForwardFrom: undefined,
          answerRequirement: /* All */0,
          representation: representation,
          answer: undefined
        };
}

function answered(t, mandatory) {
  var match = t.answer;
  if (match !== undefined && answerRequirementMet(match, t)) {
    return true;
  } else {
    return false;
  }
}

function text(t) {
  return t.text;
}

function description(t) {
  return t.description;
}

function groups(t) {
  return t.groups;
}

function carryForwardFrom(t) {
  return t.carryForwardFrom;
}

function representation(t) {
  return t.representation;
}

function setText(t, text) {
  return {
          id: t.id,
          uuid: t.uuid,
          text: text,
          description: t.description,
          groups: t.groups,
          carryForwardFrom: t.carryForwardFrom,
          answerRequirement: t.answerRequirement,
          representation: t.representation,
          answer: t.answer
        };
}

function setDescription(t, description) {
  return {
          id: t.id,
          uuid: t.uuid,
          text: t.text,
          description: description,
          groups: t.groups,
          carryForwardFrom: t.carryForwardFrom,
          answerRequirement: t.answerRequirement,
          representation: t.representation,
          answer: t.answer
        };
}

function setGroups(t, groups) {
  return {
          id: t.id,
          uuid: t.uuid,
          text: t.text,
          description: t.description,
          groups: groups,
          carryForwardFrom: t.carryForwardFrom,
          answerRequirement: t.answerRequirement,
          representation: t.representation,
          answer: t.answer
        };
}

function removeGroup(groupId, groups, t) {
  var newSetOfGroups = Belt_List.keep(groups, (function (param) {
          return param[0] !== groupId;
        }));
  return {
          id: t.id,
          uuid: t.uuid,
          text: t.text,
          description: t.description,
          groups: newSetOfGroups,
          carryForwardFrom: t.carryForwardFrom,
          answerRequirement: t.answerRequirement,
          representation: t.representation,
          answer: t.answer
        };
}

function setRepresentation(t, representation) {
  return {
          id: t.id,
          uuid: t.uuid,
          text: t.text,
          description: t.description,
          groups: t.groups,
          carryForwardFrom: t.carryForwardFrom,
          answerRequirement: t.answerRequirement,
          representation: representation,
          answer: t.answer
        };
}

function answer(t) {
  return t.answer;
}

function id(t) {
  return t.id;
}

function uuid(t) {
  return t.uuid;
}

function setAnswer(t, groupAnswer) {
  var groupId = groupAnswer[0];
  var answers = t.answer;
  if (answers !== undefined) {
    return {
            id: t.id,
            uuid: t.uuid,
            text: t.text,
            description: t.description,
            groups: t.groups,
            carryForwardFrom: t.carryForwardFrom,
            answerRequirement: t.answerRequirement,
            representation: t.representation,
            answer: Belt_List.concat(Belt_List.keep(answers, (function (x) {
                        return x[0] !== groupId;
                      })), {
                  hd: groupAnswer,
                  tl: /* [] */0
                })
          };
  } else {
    return {
            id: t.id,
            uuid: t.uuid,
            text: t.text,
            description: t.description,
            groups: t.groups,
            carryForwardFrom: t.carryForwardFrom,
            answerRequirement: t.answerRequirement,
            representation: t.representation,
            answer: {
              hd: groupAnswer,
              tl: /* [] */0
            }
          };
  }
}

function encodeGroup(param) {
  return {
          hd: [
            "id",
            param[0]
          ],
          tl: {
            hd: [
              "group",
              param[1]
            ],
            tl: /* [] */0
          }
        };
}

function encodeGroups(groups) {
  return Json_encode.list(Json_encode.object_, List.map(encodeGroup, groups));
}

function encodeAnswer(param) {
  return {
          hd: [
            "id",
            param[0]
          ],
          tl: {
            hd: [
              "value",
              param[1]
            ],
            tl: /* [] */0
          }
        };
}

function encodeAnswers(answer) {
  return Json_encode.list(Json_encode.object_, List.map(encodeAnswer, answer));
}

function nonEmptyGroups(t) {
  return Belt_List.keep(t.groups, (function (param) {
                return param[1] !== "";
              }));
}

function toJson(withAnswerOpt, t) {
  var withAnswer = withAnswerOpt !== undefined ? withAnswerOpt : false;
  var encodeArray_0 = [
    "id",
    Json_encode.nullable((function (prim) {
            return prim;
          }), t.id)
  ];
  var encodeArray_1 = {
    hd: [
      "text",
      t.text
    ],
    tl: {
      hd: [
        "description",
        t.description
      ],
      tl: {
        hd: [
          "groups",
          encodeGroups(nonEmptyGroups(t))
        ],
        tl: /* [] */0
      }
    }
  };
  var encodeArray = {
    hd: encodeArray_0,
    tl: encodeArray_1
  };
  return Json_encode.object_(withAnswer ? ({
                  hd: [
                    "answer",
                    encodeAnswers(Belt_Option.getWithDefault(t.answer, /* [] */0))
                  ],
                  tl: encodeArray
                }) : encodeArray);
}

function decodeGroup(json) {
  return [
          Json_decode.field("id", Json_decode.string, json),
          Json_decode.field("group", Json_decode.string, json)
        ];
}

function decodeGroups(json) {
  return Json_decode.field("groups", (function (param) {
                return Json_decode.list(decodeGroup, param);
              }), json);
}

function decodeAnswerRequirement(json) {
  var condition = Json_decode.field("condition", Json_decode.string, json);
  switch (condition) {
    case "at_least" :
        return {
                TAG: /* AtLeast */0,
                _0: Json_decode.field("count", Json_decode.$$int, json)
              };
    case "at_most" :
        return {
                TAG: /* AtMost */1,
                _0: Json_decode.field("count", Json_decode.$$int, json)
              };
    case "exactly" :
        return {
                TAG: /* Exactly */2,
                _0: Json_decode.field("count", Json_decode.$$int, json)
              };
    case "range" :
        return {
                TAG: /* Range */3,
                _0: Json_decode.field("from", Json_decode.$$int, json),
                _1: Json_decode.field("to", Json_decode.$$int, json)
              };
    default:
      return /* All */0;
  }
}

function fromJson(json) {
  return {
          id: Json_decode.field("category", (function (param) {
                  return Json_decode.field("id", (function (param) {
                                return Json_decode.optional(Json_decode.$$int, param);
                              }), param);
                }), json),
          uuid: Json_decode.field("uid", RandomId$ReactHooksTemplate.fromJson, json),
          text: Json_decode.field("category", (function (param) {
                  return Json_decode.field("text", Json_decode.string, param);
                }), json),
          description: Json_decode.field("category", (function (param) {
                  return Json_decode.field("description", Json_decode.string, param);
                }), json),
          groups: Json_decode.field("category", decodeGroups, json),
          carryForwardFrom: Json_decode.field("category", (function (param) {
                  return Json_decode.optional((function (param) {
                                return Json_decode.field("groups_from_uid", RandomId$ReactHooksTemplate.fromJson, param);
                              }), param);
                }), json),
          answerRequirement: Json_decode.field("category", (function (param) {
                  return Json_decode.withDefault(/* All */0, (function (param) {
                                return Json_decode.field("answer_requirement", decodeAnswerRequirement, param);
                              }), param);
                }), json),
          representation: /* Smiley */0,
          answer: undefined
        };
}

var categoryType = "RatingGroup";

exports.categoryType = categoryType;
exports.empty = empty;
exports.text = text;
exports.description = description;
exports.groups = groups;
exports.carryForwardFrom = carryForwardFrom;
exports.make = make;
exports.id = id;
exports.uuid = uuid;
exports.setText = setText;
exports.setDescription = setDescription;
exports.setAnswer = setAnswer;
exports.answered = answered;
exports.answer = answer;
exports.setGroups = setGroups;
exports.removeGroup = removeGroup;
exports.representation = representation;
exports.setRepresentation = setRepresentation;
exports.toJson = toJson;
exports.fromJson = fromJson;
exports.nonEmptyGroups = nonEmptyGroups;
/* RandomId-ReactHooksTemplate Not a pure module */

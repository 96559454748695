// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Js_dict = require("rescript/lib/js/js_dict.js");
var Js_json = require("rescript/lib/js/js_json.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");

function series(t) {
  return t.series;
}

function drilldown(t) {
  return t.drilldown;
}

function data(t) {
  return t.data;
}

function width(t) {
  return t.width;
}

function height(t) {
  return t.height;
}

function fromJson(json) {
  return {
          series: Belt_Option.getWithDefault(Js_dict.get(Belt_Option.getWithDefault(Js_json.decodeObject(json), {}), "series"), "empty"),
          drilldown: Belt_Option.getWithDefault(Js_dict.get(Belt_Option.getWithDefault(Js_json.decodeObject(json), {}), "drilldown"), "empty"),
          data: Belt_Option.getWithDefault(Js_dict.get(Belt_Option.getWithDefault(Js_json.decodeObject(json), {}), "data"), "empty"),
          width: Json_decode.optional((function (param) {
                  return Json_decode.field("width", Json_decode.string, param);
                }), json),
          height: Json_decode.optional((function (param) {
                  return Json_decode.field("height", Json_decode.string, param);
                }), json)
        };
}

exports.series = series;
exports.drilldown = drilldown;
exports.data = data;
exports.width = width;
exports.height = height;
exports.fromJson = fromJson;
/* No side effect */

// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var $$Array = require("rescript/lib/js/array.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Utils$ReactHooksTemplate = require("../common/utils.bs.js");
var MultipleChoice$ReactHooksTemplate = require("./MultipleChoice.bs.js");
var SurveyLanguage$ReactHooksTemplate = require("./surveyLanguage.bs.js");
var MandatoryMessage$ReactHooksTemplate = require("./MandatoryMessage.bs.js");
var RadioButtonInput$ReactHooksTemplate = require("../common/RadioButtonInput.bs.js");
var QuestionTextPreview$ReactHooksTemplate = require("./QuestionTextPreview.bs.js");

function MultipleChoicePreview(Props) {
  var multipleChoice = Props.multipleChoice;
  var surveyTheme = Props.surveyTheme;
  var language = Props.language;
  var mandatory = Props.mandatory;
  var onChange = Props.onChange;
  var onTitleChange = function ($$event) {
    return Curry._1(onChange, MultipleChoice$ReactHooksTemplate.setText(multipleChoice, Utils$ReactHooksTemplate.formTargetValue($$event)));
  };
  var onDescriptionChange = function ($$event) {
    return Curry._1(onChange, MultipleChoice$ReactHooksTemplate.setDescription(multipleChoice, Utils$ReactHooksTemplate.formTargetValue($$event)));
  };
  return React.createElement("div", {
              className: "w-full p-16"
            }, React.createElement(QuestionTextPreview$ReactHooksTemplate.make, {
                  text: MultipleChoice$ReactHooksTemplate.getTextTranslation(multipleChoice, SurveyLanguage$ReactHooksTemplate.name(language)),
                  description: MultipleChoice$ReactHooksTemplate.getDescriptionTranslation(multipleChoice, SurveyLanguage$ReactHooksTemplate.name(language)),
                  mandatory: mandatory,
                  surveyTheme: surveyTheme,
                  onTitleChange: onTitleChange,
                  onDescriptionChange: onDescriptionChange
                }), React.createElement("div", {
                  className: "flex flex-col"
                }, React.createElement("div", {
                      className: "text-aurometalsaurus text-sm mb-4 font-normal"
                    }, "Choices"), React.createElement("div", undefined, $$Array.of_list(Belt_List.mapWithIndex(MultipleChoice$ReactHooksTemplate.getChoicesTranslation(multipleChoice, SurveyLanguage$ReactHooksTemplate.name(language)), (function (param, param$1) {
                                var id = param$1[0];
                                return React.createElement("label", {
                                            key: id,
                                            className: "flex gap-1 text-sm font-normal"
                                          }, React.createElement(RadioButtonInput$ReactHooksTemplate.make, {
                                                checked: MultipleChoice$ReactHooksTemplate.$eq(multipleChoice, id),
                                                label: param$1[1],
                                                handleChange: (function (param) {
                                                    return Curry._1(onChange, MultipleChoice$ReactHooksTemplate.setAnswer(multipleChoice, id));
                                                  }),
                                                className: "mb-2"
                                              }));
                              }))))), mandatory ? React.createElement(MandatoryMessage$ReactHooksTemplate.make, {
                    language: language
                  }) : React.createElement(React.Fragment, undefined));
}

var make = MultipleChoicePreview;

exports.make = make;
/* react Not a pure module */

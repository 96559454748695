// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';


function getDates(dateRange) {
  return {
          startDate: dateRange.startDate,
          endDate: dateRange.endDate
        };
}

function getStartDate(t) {
  return t.startDate;
}

function getEndDate(t) {
  return t.endDate;
}

var DateRangeTuple = {
  getDates: getDates,
  getStartDate: getStartDate,
  getEndDate: getEndDate
};

var DateRangePicker = {};

exports.DateRangeTuple = DateRangeTuple;
exports.DateRangePicker = DateRangePicker;
/* No side effect */

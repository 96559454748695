// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var $$Array = require("rescript/lib/js/array.js");
var Curry = require("rescript/lib/js/curry.js");
var Fetch = require("bs-fetch/src/Fetch.bs.js");
var React = require("react");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Caml_array = require("rescript/lib/js/caml_array.js");
var Caml_format = require("rescript/lib/js/caml_format.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Utils$ReactHooksTemplate = require("../common/utils.bs.js");

function getInputValue(x) {
  return x._0;
}

function getValidationError(x) {
  switch (x.TAG | 0) {
    case /* Invalid */1 :
        return x._1;
    case /* Valid */0 :
    case /* Pristine */2 :
        return /* [] */0;
    
  }
}

function validFormInput(x) {
  switch (x.TAG | 0) {
    case /* Valid */0 :
        return true;
    case /* Invalid */1 :
    case /* Pristine */2 :
        return false;
    
  }
}

function validForm(form) {
  if (validFormInput(form.name) && validFormInput(form.campaignId)) {
    return validFormInput(form.file);
  } else {
    return false;
  }
}

function validFileSize(file) {
  return file.size < 5242880;
}

function DistributionCreate(Props) {
  var campaignId = Props.campaignId;
  var toggleDistributionCreate = Props.toggleDistributionCreate;
  var emptyState_name = {
    TAG: /* Valid */0,
    _0: ""
  };
  var emptyState_campaignId = {
    TAG: /* Valid */0,
    _0: Caml_format.caml_int_of_string(campaignId)
  };
  var emptyState_file = {
    TAG: /* Pristine */2,
    _0: undefined
  };
  var emptyState = {
    name: emptyState_name,
    campaignId: emptyState_campaignId,
    file: emptyState_file
  };
  var match = React.useState(function () {
        return emptyState;
      });
  var setState = match[1];
  var state = match[0];
  var handleFileChange = function ($$event) {
    var file = Caml_array.get($$event.target.files, 0);
    if (file.size < 5242880) {
      return Curry._1(setState, (function (state) {
                    return {
                            name: state.name,
                            campaignId: state.campaignId,
                            file: {
                              TAG: /* Valid */0,
                              _0: file
                            }
                          };
                  }));
    } else {
      return Curry._1(setState, (function (state) {
                    return {
                            name: state.name,
                            campaignId: state.campaignId,
                            file: {
                              TAG: /* Invalid */1,
                              _0: file,
                              _1: {
                                hd: "File size should be less\n    than 5 MB",
                                tl: /* [] */0
                              }
                            }
                          };
                  }));
    }
  };
  var handleFormSubmit = function ($$event) {
    $$event.preventDefault();
    var formData = new FormData();
    var file = state.file._0;
    if (file !== undefined) {
      formData.append("distribution[customer_source_attributes][file]", Caml_option.valFromOption(file), undefined);
    }
    formData.append("distribution[customer_source_type]", "FeedFile");
    formData.append("distribution[customer_source_attributes][name]", state.name._0);
    fetch("/api/v1/campaigns/" + (String(state.campaignId._0) + "/distributions"), Fetch.RequestInit.make(/* Post */2, [
                  [
                    "X-CSRF-TOKEN",
                    Utils$ReactHooksTemplate.csrfToken
                  ],
                  [
                    "Accept",
                    "*"
                  ]
                ], Caml_option.some(formData), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined)).then(function (response) {
          Curry._1(toggleDistributionCreate, "save");
          return Promise.resolve(response.json());
        });
    
  };
  var match$1 = state.file;
  var tmp;
  switch (match$1.TAG | 0) {
    case /* Invalid */1 :
        tmp = $$Array.of_list(Belt_List.mapWithIndex(match$1._1, (function (i, e) {
                    return React.createElement("p", {
                                key: String(i),
                                className: "text-red-500 font-medium float-right"
                              }, e);
                  })));
        break;
    case /* Valid */0 :
    case /* Pristine */2 :
        tmp = React.createElement(React.Fragment, undefined);
        break;
    
  }
  return React.createElement("div", undefined, React.createElement("form", {
                  onSubmit: handleFormSubmit
                }, React.createElement("div", {
                      className: "flex h-432-px z-20 relative"
                    }, React.createElement("div", {
                          className: "w-3/10 flex flex-col border-r border-gray-300\n                        text-darkcharcoal text-base"
                        }, React.createElement("div", {
                              className: "px-6 py-5 bg-lavenderweb"
                            }, "General Settings")), React.createElement("div", {
                          className: "w-7/10 flex flex-col p-8"
                        }, React.createElement("div", {
                              className: "flex justify-between items-center"
                            }, React.createElement("div", {
                                  className: "text-sm font-medium"
                                }, "File Upload"), React.createElement("div", {
                                  className: "w-2/3"
                                }, React.createElement("input", {
                                      className: "h-10 w-3/4 block border px-3 py-2 border-gray-400\n                           bg-white rounded capitalize text-sm cursor-pointer ",
                                      accept: ".csv",
                                      type: "file",
                                      onChange: handleFileChange
                                    }))), React.createElement("div", undefined, tmp))), React.createElement("div", {
                      className: "p-8 flex border-t border-gray-200 justify-end gap-3 font-medium\n        absolute bottom-0 text-base w-full z-30 relative"
                    }, React.createElement("button", {
                          className: "bg-ghostwhite border border-gray-400\n          flex rounded py-3 px-6 text-xs",
                          id: "close_modal",
                          onClick: (function (param) {
                              return Curry._1(toggleDistributionCreate, "close");
                            })
                        }, "Discard"), React.createElement("input", {
                          className: "bg-palatinateblue border border-gray-400\n          flex rounded py-3 px-6 text-xs text-white",
                          disabled: !validForm(state),
                          type: "submit",
                          value: "Save"
                        }))));
}

var make = DistributionCreate;

exports.getInputValue = getInputValue;
exports.getValidationError = getValidationError;
exports.validFormInput = validFormInput;
exports.validForm = validForm;
exports.validFileSize = validFileSize;
exports.make = make;
/* react Not a pure module */

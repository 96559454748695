// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var Image$ReactHooksTemplate = require("../../common/image.bs.js");

function InsightsIndex(Props) {
  var content = Props.content;
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var match = url.path;
  var selected;
  if (match && match.hd === "insights") {
    var match$1 = match.tl;
    if (match$1) {
      switch (match$1.hd) {
        case "correlation" :
            selected = match$1.tl ? "dashboard" : "correlation";
            break;
        case "dashboard" :
            selected = "dashboard";
            break;
        default:
          selected = "dashboard";
      }
    } else {
      selected = "dashboard";
    }
  } else {
    selected = "dashboard";
  }
  return React.createElement("div", {
              className: "flex"
            }, React.createElement("div", {
                  className: "min-h-screen w-16 bg-ghostwhite border-r border-gray-300"
                }, React.createElement("a", {
                      href: "/insights/dashboard"
                    }, React.createElement("div", {
                          className: "w-full h-16 relative hover:bg-palatinateblue sidebar " + (
                            selected === "dashboard" ? "bg-palatinateblue border-palatinateblue" : ""
                          )
                        }, React.createElement("img", {
                              className: "m-auto inline-block align-middle max-h-full\n                  max-w-full w-auto h-auto inset-0 absolute " + (
                                selected === "dashboard" ? "filter-white" : ""
                              ),
                              title: "Insights Dashboard",
                              src: Image$ReactHooksTemplate.dashboard
                            }))), React.createElement("a", {
                      href: "/insights/correlation"
                    }, React.createElement("div", {
                          className: "w-full h-16 relative hover:bg-palatinateblue sidebar " + (
                            selected === "correlation" ? "bg-palatinateblue border-palatinateblue" : ""
                          )
                        }, React.createElement("img", {
                              className: "m-auto inline-block align-middle max-h-full\n                  max-w-full w-auto h-auto inset-0 absolute " + (
                                selected === "correlation" ? "filter-white" : ""
                              ),
                              title: "Insights Correlation",
                              src: Image$ReactHooksTemplate.distributions
                            })))), React.createElement("div", {
                  className: "w-screen"
                }, content));
}

var make = InsightsIndex;

exports.make = make;
/* react Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var List = require("rescript/lib/js/list.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Caml_format = require("rescript/lib/js/caml_format.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");
var Nps$ReactHooksTemplate = require("./Nps.bs.js");
var Question$ReactHooksTemplate = require("./Question.bs.js");
var RandomId$ReactHooksTemplate = require("./RandomId.bs.js");
var OpinionScale$ReactHooksTemplate = require("./OpinionScale.bs.js");
var MultipleChoice$ReactHooksTemplate = require("./MultipleChoice.bs.js");

function comparatorToString(x) {
  switch (x) {
    case /* Empty */0 :
        return "None";
    case /* EqualsTo */1 :
        return "EqualsTo";
    case /* LessThanEqualTo */2 :
        return "LessThanEqualTo";
    case /* GreaterThanEqualTo */3 :
        return "GreaterThanEqualTo";
    
  }
}

function comparatorToDisplay(x) {
  switch (x) {
    case /* Empty */0 :
        return "";
    case /* EqualsTo */1 :
        return "Equals To";
    case /* LessThanEqualTo */2 :
        return "Less than equal to";
    case /* GreaterThanEqualTo */3 :
        return "Greater than equal to";
    
  }
}

function comparatorTypeFromId(x) {
  if (x > 2 || x < 0) {
    return /* Empty */0;
  } else {
    return x + 1 | 0;
  }
}

function id(t) {
  return t.id;
}

function question(t) {
  return t.question;
}

function formulae(t) {
  return t.formulae;
}

function jumpTo(t) {
  return t.jumpTo;
}

function defaultJumpTo(t) {
  return t.defaultJumpTo;
}

function setQuestion(question, t) {
  return {
          id: t.id,
          question: question,
          formulae: t.formulae,
          jumpTo: t.jumpTo,
          defaultJumpTo: t.defaultJumpTo
        };
}

function setFormulae(formulae, t) {
  return {
          id: t.id,
          question: t.question,
          formulae: formulae,
          jumpTo: t.jumpTo,
          defaultJumpTo: t.defaultJumpTo
        };
}

function setJumpTo(jumpTo, t) {
  return {
          id: t.id,
          question: t.question,
          formulae: t.formulae,
          jumpTo: jumpTo,
          defaultJumpTo: t.defaultJumpTo
        };
}

function makeFormula(comparator, compareValue) {
  return {
          id: RandomId$ReactHooksTemplate.generate(undefined),
          comparator: comparator,
          compareValue: compareValue
        };
}

function makeComparator(comparatorType) {
  return {
          id: comparatorType - 1 | 0,
          comparatorType: comparatorType,
          displayName: comparatorToDisplay(comparatorType)
        };
}

function getFormulaId(formula) {
  return formula.id;
}

function getComparatorFromFormula(formula) {
  return formula.comparator;
}

function getCompareValueFromFormula(formula) {
  return formula.compareValue;
}

function setComparatorToFormula(comparator, formula) {
  return {
          id: formula.id,
          comparator: comparator,
          compareValue: formula.compareValue
        };
}

function setCompareValueToFormula(compareValue, formula) {
  return {
          id: formula.id,
          comparator: formula.comparator,
          compareValue: compareValue
        };
}

function setDefaultJumpTo(defaultJumpTo, t) {
  return {
          id: t.id,
          question: t.question,
          formulae: t.formulae,
          jumpTo: t.jumpTo,
          defaultJumpTo: defaultJumpTo
        };
}

function getComparatorId(comparator) {
  return comparator.id;
}

function getComparatorDisplayName(comparator) {
  return comparator.displayName;
}

function makeGroup(param) {
  return /* [] */0;
}

function addToGroup(t, group) {
  return {
          hd: t,
          tl: group
        };
}

function deleteFromGroup(t, group) {
  return Belt_List.keep(group, (function (x) {
                return Caml_obj.caml_notequal(x.id, t.id);
              }));
}

function replaceInGroup(t, group) {
  return Belt_List.map(group, (function (x) {
                if (Caml_obj.caml_equal(x.id, t.id)) {
                  return t;
                } else {
                  return x;
                }
              }));
}

function make(question, formulae, jumpTo, defaultJumpTo) {
  return {
          id: RandomId$ReactHooksTemplate.generate(undefined),
          question: question,
          formulae: formulae,
          jumpTo: jumpTo,
          defaultJumpTo: defaultJumpTo
        };
}

function emptyComparator(param) {
  return makeComparator(/* Empty */0);
}

function empty(param) {
  return make(undefined, {
              hd: makeFormula(makeComparator(/* Empty */0), ""),
              tl: /* [] */0
            }, undefined, undefined);
}

function comparatorForQuestions(t) {
  var x = t.question;
  if (x === undefined) {
    return /* [] */0;
  }
  var match = Question$ReactHooksTemplate.questionType(x);
  switch (match.TAG | 0) {
    case /* OpinionScale */0 :
    case /* Nps */4 :
        break;
    case /* MultipleChoice */5 :
        return {
                hd: makeComparator(/* EqualsTo */1),
                tl: /* [] */0
              };
    default:
      return /* [] */0;
  }
  return {
          hd: makeComparator(/* EqualsTo */1),
          tl: {
            hd: makeComparator(/* LessThanEqualTo */2),
            tl: {
              hd: makeComparator(/* GreaterThanEqualTo */3),
              tl: /* [] */0
            }
          }
        };
}

function equate(question, formula) {
  var y = formula.compareValue;
  if (question === undefined) {
    return false;
  }
  var match = Question$ReactHooksTemplate.questionType(question);
  var match$1 = formula.comparator.comparatorType;
  switch (match.TAG | 0) {
    case /* OpinionScale */0 :
        var opinionScale = match._0;
        switch (match$1) {
          case /* Empty */0 :
              return false;
          case /* EqualsTo */1 :
              return OpinionScale$ReactHooksTemplate.$eq(opinionScale, Caml_format.caml_int_of_string(y));
          case /* LessThanEqualTo */2 :
              return OpinionScale$ReactHooksTemplate.$less$eq(opinionScale, Caml_format.caml_int_of_string(y));
          case /* GreaterThanEqualTo */3 :
              return OpinionScale$ReactHooksTemplate.$great$eq(opinionScale, Caml_format.caml_int_of_string(y));
          
        }
    case /* Nps */4 :
        var scale = match._0;
        switch (match$1) {
          case /* Empty */0 :
              return false;
          case /* EqualsTo */1 :
              return Nps$ReactHooksTemplate.$eq(scale, Caml_format.caml_int_of_string(y));
          case /* LessThanEqualTo */2 :
              return Nps$ReactHooksTemplate.$less$eq(scale, Caml_format.caml_int_of_string(y));
          case /* GreaterThanEqualTo */3 :
              return Nps$ReactHooksTemplate.$great$eq(scale, Caml_format.caml_int_of_string(y));
          
        }
    case /* MultipleChoice */5 :
        if (match$1 !== 1) {
          return false;
        } else {
          return MultipleChoice$ReactHooksTemplate.$eq(match._0, y);
        }
    default:
      return false;
  }
}

function iterFormulae(t) {
  var _acc = true;
  var _xs = t.formulae;
  while(true) {
    var xs = _xs;
    var acc = _acc;
    if (!xs) {
      return acc;
    }
    if (!acc) {
      return false;
    }
    _xs = xs.tl;
    _acc = equate(t.question, xs.hd);
    continue ;
  };
}

function compute(t) {
  if (iterFormulae(t)) {
    return t.jumpTo;
  } else {
    return t.defaultJumpTo;
  }
}

function hasLogicalJump(question, t) {
  var x = t.question;
  if (x !== undefined && Caml_obj.caml_equal(Question$ReactHooksTemplate.uuid(question), Question$ReactHooksTemplate.uuid(x))) {
    return t;
  }
  
}

function findQuestionId(question) {
  if (question !== undefined) {
    return RandomId$ReactHooksTemplate.toJson(Question$ReactHooksTemplate.uuid(question));
  } else {
    return RandomId$ReactHooksTemplate.toJson(RandomId$ReactHooksTemplate.generate(undefined));
  }
}

function encodeFormulae(xs) {
  var comparator = xs.comparator;
  var value = xs.compareValue;
  return {
          hd: [
            "comparator",
            Json_encode.object_({
                  hd: [
                    "id",
                    String(comparator.id)
                  ],
                  tl: {
                    hd: [
                      "key",
                      comparatorToString(comparator.comparatorType)
                    ],
                    tl: {
                      hd: [
                        "display",
                        comparator.displayName
                      ],
                      tl: /* [] */0
                    }
                  }
                })
          ],
          tl: {
            hd: [
              "value",
              value
            ],
            tl: /* [] */0
          }
        };
}

function encode(t) {
  return Json_encode.object_({
              hd: [
                "uid",
                RandomId$ReactHooksTemplate.toJson(t.id)
              ],
              tl: {
                hd: [
                  "question_uid",
                  findQuestionId(t.question)
                ],
                tl: {
                  hd: [
                    "formulae",
                    Json_encode.list(Json_encode.object_, List.map(encodeFormulae, t.formulae))
                  ],
                  tl: {
                    hd: [
                      "jump_to",
                      findQuestionId(t.jumpTo)
                    ],
                    tl: {
                      hd: [
                        "default_jump_to",
                        findQuestionId(t.defaultJumpTo)
                      ],
                      tl: /* [] */0
                    }
                  }
                }
              }
            });
}

function encodeGroup(group) {
  return Json_encode.list(encode, group);
}

function getQuestionFromList(questionList, id) {
  return Belt_List.head(Belt_List.keep(questionList, (function (question) {
                    return Caml_obj.caml_equal(id, RandomId$ReactHooksTemplate.toString(Question$ReactHooksTemplate.uuid(question)));
                  })));
}

function decodeComparator(json) {
  var id = Caml_format.caml_int_of_string(Json_decode.field("id", Json_decode.string, json));
  var comparatorType = comparatorTypeFromId(id);
  return {
          id: id,
          comparatorType: comparatorType,
          displayName: comparatorToDisplay(comparatorType)
        };
}

function decodeFormulae(json) {
  return {
          id: RandomId$ReactHooksTemplate.generate(undefined),
          comparator: Json_decode.field("comparator", decodeComparator, json),
          compareValue: Json_decode.field("value", Json_decode.string, json)
        };
}

function fromJson(questionList, json) {
  return Json_decode.field("survey", (function (param) {
                return Json_decode.withDefault(/* [] */0, (function (param) {
                              return Json_decode.field("logic_jump", (function (param) {
                                            return Json_decode.list((function (param) {
                                                          return {
                                                                  id: Json_decode.field("uid", RandomId$ReactHooksTemplate.fromJson, param),
                                                                  question: getQuestionFromList(questionList, Json_decode.field("question_uid", Json_decode.string, param)),
                                                                  formulae: Json_decode.field("formulae", (function (param) {
                                                                          return Json_decode.list(decodeFormulae, param);
                                                                        }), param),
                                                                  jumpTo: getQuestionFromList(questionList, Json_decode.field("jump_to", Json_decode.string, param)),
                                                                  defaultJumpTo: getQuestionFromList(questionList, Json_decode.field("default_jump_to", Json_decode.string, param))
                                                                };
                                                        }), param);
                                          }), param);
                            }), param);
              }), json);
}

exports.makeGroup = makeGroup;
exports.addToGroup = addToGroup;
exports.deleteFromGroup = deleteFromGroup;
exports.replaceInGroup = replaceInGroup;
exports.compute = compute;
exports.hasLogicalJump = hasLogicalJump;
exports.empty = empty;
exports.id = id;
exports.question = question;
exports.jumpTo = jumpTo;
exports.defaultJumpTo = defaultJumpTo;
exports.setQuestion = setQuestion;
exports.setJumpTo = setJumpTo;
exports.setDefaultJumpTo = setDefaultJumpTo;
exports.encodeGroup = encodeGroup;
exports.encode = encode;
exports.make = make;
exports.setFormulae = setFormulae;
exports.formulae = formulae;
exports.comparatorToDisplay = comparatorToDisplay;
exports.comparatorForQuestions = comparatorForQuestions;
exports.getComparatorDisplayName = getComparatorDisplayName;
exports.makeFormula = makeFormula;
exports.emptyComparator = emptyComparator;
exports.getFormulaId = getFormulaId;
exports.getComparatorFromFormula = getComparatorFromFormula;
exports.getCompareValueFromFormula = getCompareValueFromFormula;
exports.getComparatorId = getComparatorId;
exports.comparatorTypeFromId = comparatorTypeFromId;
exports.makeComparator = makeComparator;
exports.setComparatorToFormula = setComparatorToFormula;
exports.setCompareValueToFormula = setCompareValueToFormula;
exports.fromJson = fromJson;
/* Nps-ReactHooksTemplate Not a pure module */

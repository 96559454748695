// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");

function id(t) {
  return t.id;
}

function email(t) {
  return t.email;
}

function firstName(t) {
  return t.firstName;
}

function lastName(t) {
  return t.lastName;
}

function decode(json) {
  return {
          id: Json_decode.field("id", Json_decode.$$int, json),
          email: Json_decode.field("email", Json_decode.string, json),
          firstName: Json_decode.field("first_name", Json_decode.string, json),
          lastName: Json_decode.field("last_name", Json_decode.string, json)
        };
}

exports.id = id;
exports.email = email;
exports.firstName = firstName;
exports.lastName = lastName;
exports.decode = decode;
/* No side effect */

// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var List = require("rescript/lib/js/list.js");
var $$Array = require("rescript/lib/js/array.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Image$ReactHooksTemplate = require("../common/image.bs.js");
var Utils$ReactHooksTemplate = require("../common/utils.bs.js");
var RandomId$ReactHooksTemplate = require("./RandomId.bs.js");
var MultipleChoice$ReactHooksTemplate = require("./MultipleChoice.bs.js");
var ToggleMandatory$ReactHooksTemplate = require("./ToggleMandatory.bs.js");

function MultipleChoiceEditor(Props) {
  var multipleChoice = Props.multipleChoice;
  var onMultipleChoiceChange = Props.onMultipleChoiceChange;
  var mandatory = Props.mandatory;
  var onMandatoryChange = Props.onMandatoryChange;
  var compareChoices = Belt_List.cmpByLength(MultipleChoice$ReactHooksTemplate.nonEmptyChoices(multipleChoice), MultipleChoice$ReactHooksTemplate.choices(multipleChoice));
  var hasInvalidChoice = compareChoices !== 0;
  return React.createElement("div", undefined, React.createElement(ToggleMandatory$ReactHooksTemplate.make, {
                  mandatory: mandatory,
                  onMandatoryChange: onMandatoryChange
                }), React.createElement("button", {
                  className: "bg-ghostwhite border border-gray-400\n            flex rounded p-2 text-sm mt-0" + (
                    hasInvalidChoice ? " disabled-input" : " "
                  ),
                  disabled: hasInvalidChoice,
                  onClick: (function (param) {
                      return Curry._1(onMultipleChoiceChange, MultipleChoice$ReactHooksTemplate.setChoices(multipleChoice, Belt_List.concat(MultipleChoice$ReactHooksTemplate.choices(multipleChoice), {
                                          hd: [
                                            RandomId$ReactHooksTemplate.toString(RandomId$ReactHooksTemplate.generate(undefined)),
                                            ""
                                          ],
                                          tl: /* [] */0
                                        })));
                    })
                }, React.createElement("div", {
                      className: "flex items-center justify-between gap-1 text-darkcharcoal"
                    }, React.createElement("img", {
                          src: Image$ReactHooksTemplate.plusCircle
                        }), React.createElement("p", undefined, "Add option"))), React.createElement("div", {
                  className: "mb-7 mt-4"
                }, $$Array.of_list(List.mapi((function (i, param) {
                            var choice = param[1];
                            var choiceId = param[0];
                            return React.createElement("div", {
                                        key: String(i),
                                        className: "mb-4"
                                      }, React.createElement("div", {
                                            className: "flex justify-between mb-2"
                                          }, React.createElement("div", {
                                                className: "text-darkcharcoal text-sm font-medium"
                                              }, "Label " + String(i + 1 | 0)), React.createElement("img", {
                                                className: "h-12-px cursor-pointer",
                                                src: Image$ReactHooksTemplate.trash,
                                                onClick: (function (param) {
                                                    return Curry._1(onMultipleChoiceChange, MultipleChoice$ReactHooksTemplate.removeChoice(choiceId, MultipleChoice$ReactHooksTemplate.choices(multipleChoice), multipleChoice));
                                                  })
                                              })), React.createElement("input", {
                                            className: choice === "" ? "-40-px py-2 px-4 rounded bg-white border border-red-500 focus:border-red-500 text-xs w-full" : "-40-px py-2 px-4 rounded bg-white border border-gray-300 focus:border-gray-600 text-xs w-full",
                                            placeholder: "Enter Label " + String(i + 1 | 0),
                                            type: "text",
                                            value: choice,
                                            onChange: (function ($$event) {
                                                var value = Utils$ReactHooksTemplate.formTargetValue($$event);
                                                return Curry._1(onMultipleChoiceChange, MultipleChoice$ReactHooksTemplate.setChoices(multipleChoice, List.map((function (choice) {
                                                                      var id = choice[0];
                                                                      if (id === choiceId) {
                                                                        return [
                                                                                id,
                                                                                value
                                                                              ];
                                                                      } else {
                                                                        return choice;
                                                                      }
                                                                    }), MultipleChoice$ReactHooksTemplate.choices(multipleChoice))));
                                              })
                                          }), React.createElement("p", {
                                            className: choice === "" ? "text-red-500 text-xs float-right" : "hidden"
                                          }, "Label can't be blank"));
                          }), MultipleChoice$ReactHooksTemplate.choices(multipleChoice)))));
}

var make = MultipleChoiceEditor;

exports.make = make;
/* react Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var MobileNumber$ReactHooksTemplate = require("./MobileNumber.bs.js");
var Organization$ReactHooksTemplate = require("../settings/organization.bs.js");
var QuestionText$ReactHooksTemplate = require("./QuestionText.bs.js");
var SurveyLanguage$ReactHooksTemplate = require("./surveyLanguage.bs.js");
var MandatoryMessage$ReactHooksTemplate = require("./MandatoryMessage.bs.js");
var MobileNumberDefaultForm$ReactHooksTemplate = require("./MobileNumberDefaultForm.bs.js");

function MobileNumberForm(Props) {
  var mobileNumber = Props.mobileNumber;
  var surveyTheme = Props.surveyTheme;
  var language = Props.language;
  var client = Props.client;
  var mandatory = Props.mandatory;
  var onChange = Props.onChange;
  var borderCss = Props.borderCss;
  var handleOnchange = function ($$event) {
    var value = $$event.target.value;
    var firstChar = value[0];
    var check = Belt_Array.getBy([
          "<",
          "=",
          "+",
          "-",
          "@"
        ], (function (x) {
            return firstChar === x;
          }));
    if (check !== undefined) {
      Curry._1(onChange, MobileNumber$ReactHooksTemplate.setAnswer(mobileNumber, ""));
    } else {
      Curry._1(onChange, MobileNumber$ReactHooksTemplate.setAnswer(mobileNumber, value));
    }
    var validNumber = value.search(/^\d{1,17}$/);
    if (validNumber !== 0) {
      return Curry._1(onChange, MobileNumber$ReactHooksTemplate.setAnswer(mobileNumber, ""));
    } else {
      return Curry._1(onChange, MobileNumber$ReactHooksTemplate.setAnswer(mobileNumber, value));
    }
  };
  var match = MobileNumber$ReactHooksTemplate.representation(mobileNumber);
  var backGroundCss = match >= 2 ? "bg-black bg-opacity-50 text-white" : "bg-tropicalbreeze";
  var match$1 = Organization$ReactHooksTemplate.name(client);
  switch (match$1) {
    case "bhima" :
    case "ncell" :
    case "nykaa" :
        break;
    default:
      return React.createElement(MobileNumberDefaultForm$ReactHooksTemplate.make, {
                  mobileNumber: mobileNumber,
                  language: language,
                  mandatory: mandatory,
                  onChange: onChange,
                  borderCss: borderCss
                });
  }
  return React.createElement("div", {
              className: "flex flex-col"
            }, React.createElement(QuestionText$ReactHooksTemplate.make, {
                  text: MobileNumber$ReactHooksTemplate.getTextTranslation(mobileNumber, SurveyLanguage$ReactHooksTemplate.name(language)),
                  description: MobileNumber$ReactHooksTemplate.getDescriptionTranslation(mobileNumber, SurveyLanguage$ReactHooksTemplate.name(language)),
                  mandatory: mandatory,
                  surveyTheme: surveyTheme
                }), React.createElement("p", {
                  className: "flex flex-wrap w-full"
                }, React.createElement("input", {
                      className: "" + backGroundCss + " h-127-px overflow-x-hidden\n            w-full outline-none focus:shadow-outline rounded-lg p-2 ",
                      maxLength: 15,
                      placeholder: MobileNumber$ReactHooksTemplate.getPlaceholderTranslation(mobileNumber, SurveyLanguage$ReactHooksTemplate.name(language)),
                      type: "text",
                      value: MobileNumber$ReactHooksTemplate.answerToString(mobileNumber),
                      onChange: handleOnchange
                    })), mandatory ? React.createElement(MandatoryMessage$ReactHooksTemplate.make, {
                    language: language
                  }) : React.createElement(React.Fragment, undefined));
}

var make = MobileNumberForm;

exports.make = make;
/* react Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");
var MapAttribute$ReactHooksTemplate = require("./mapAttribute.bs.js");
var TableAttribute$ReactHooksTemplate = require("./tableAttribute.bs.js");
var GenericChartAttribute$ReactHooksTemplate = require("./genericChartAttribute.bs.js");
var ScoreCardChartAttribute$ReactHooksTemplate = require("./scoreCardChartAttribute.bs.js");

function id(t) {
  return t.id;
}

function title(t) {
  return t.title;
}

function type_(t) {
  return t.type_;
}

function setType(t, type_) {
  return {
          id: t.id,
          type_: type_,
          title: t.title
        };
}

function setTitle(t, title) {
  return {
          id: t.id,
          type_: t.type_,
          title: title
        };
}

function chartTypeToString(t) {
  var match = t.type_;
  if (typeof match === "number") {
    return "Empty Chart";
  }
  switch (match.TAG | 0) {
    case /* LineChart */0 :
        return "Line Graph";
    case /* AreaChart */1 :
        return "Area Chart";
    case /* PieChart */2 :
        return "Pie Chart";
    case /* BarChart */3 :
        return "Bar Graph";
    case /* RadialBarChart */4 :
        return "Radial Chart";
    case /* Wordcloud */5 :
        return "Word Cloud";
    case /* ComposedChart */6 :
        return "Composed Chart";
    case /* ScoreCard */7 :
        return "Score Card";
    case /* Map */8 :
        return "Map";
    case /* Table */9 :
        return "Table";
    
  }
}

function compatibleCharts(t) {
  var match = t.type_;
  if (typeof match === "number") {
    return {
            hd: "Empty Chart",
            tl: /* [] */0
          };
  }
  switch (match.TAG | 0) {
    case /* RadialBarChart */4 :
        return {
                hd: "Radial Graph",
                tl: /* [] */0
              };
    case /* Wordcloud */5 :
        return {
                hd: "Word Cloud",
                tl: /* [] */0
              };
    case /* ComposedChart */6 :
        return {
                hd: "Composed Chart",
                tl: /* [] */0
              };
    case /* ScoreCard */7 :
        return {
                hd: "Score Card",
                tl: /* [] */0
              };
    case /* Map */8 :
        return {
                hd: "Map",
                tl: /* [] */0
              };
    case /* Table */9 :
        return {
                hd: "Table",
                tl: /* [] */0
              };
    default:
      return {
              hd: "Bar Graph",
              tl: {
                hd: "Line Graph",
                tl: {
                  hd: "Pie Chart",
                  tl: {
                    hd: "Area Chart",
                    tl: /* [] */0
                  }
                }
              }
            };
  }
}

function toJson(t) {
  var match = t.type_;
  var chartType;
  if (typeof match === "number") {
    chartType = "empty";
  } else {
    switch (match.TAG | 0) {
      case /* LineChart */0 :
          chartType = "line";
          break;
      case /* AreaChart */1 :
          chartType = "area";
          break;
      case /* PieChart */2 :
          chartType = "pie";
          break;
      case /* BarChart */3 :
          chartType = "bar";
          break;
      case /* RadialBarChart */4 :
          chartType = "radial";
          break;
      case /* Wordcloud */5 :
          chartType = "word_cloud";
          break;
      case /* ComposedChart */6 :
          chartType = "composed";
          break;
      case /* ScoreCard */7 :
          chartType = "score_card";
          break;
      case /* Map */8 :
          chartType = "map";
          break;
      case /* Table */9 :
          chartType = "table";
          break;
      
    }
  }
  return Json_encode.object_({
              hd: [
                "chart_name",
                t.title
              ],
              tl: {
                hd: [
                  "chart_type",
                  chartType
                ],
                tl: /* [] */0
              }
            });
}

function decodeItem(json) {
  var category = Json_decode.either((function (param) {
            return Json_decode.field("chart_type", Json_decode.string, param);
          }), (function (param) {
            return Json_decode.field("visualization_type", Json_decode.string, param);
          }))(json);
  return {
          id: Json_decode.field("id", Json_decode.$$int, json),
          type_: category === "radial_bar" ? ({
                TAG: /* RadialBarChart */4,
                _0: GenericChartAttribute$ReactHooksTemplate.fromJson(json)
              }) : (
              category === "map" ? ({
                    TAG: /* Map */8,
                    _0: MapAttribute$ReactHooksTemplate.fromJson(json)
                  }) : (
                  category === "table" ? ({
                        TAG: /* Table */9,
                        _0: TableAttribute$ReactHooksTemplate.fromJson(json)
                      }) : (
                      category === "line" ? ({
                            TAG: /* LineChart */0,
                            _0: GenericChartAttribute$ReactHooksTemplate.fromJson(json)
                          }) : (
                          category === "pie" ? ({
                                TAG: /* PieChart */2,
                                _0: GenericChartAttribute$ReactHooksTemplate.fromJson(json)
                              }) : (
                              category === "area" ? ({
                                    TAG: /* AreaChart */1,
                                    _0: GenericChartAttribute$ReactHooksTemplate.fromJson(json)
                                  }) : (
                                  category === "bar" ? ({
                                        TAG: /* BarChart */3,
                                        _0: GenericChartAttribute$ReactHooksTemplate.fromJson(json)
                                      }) : (
                                      category === "wordcloud" ? ({
                                            TAG: /* Wordcloud */5,
                                            _0: GenericChartAttribute$ReactHooksTemplate.fromJson(json)
                                          }) : (
                                          category === "score_card" ? ({
                                                TAG: /* ScoreCard */7,
                                                _0: ScoreCardChartAttribute$ReactHooksTemplate.fromJson(json)
                                              }) : (
                                              category === "composed" ? ({
                                                    TAG: /* ComposedChart */6,
                                                    _0: GenericChartAttribute$ReactHooksTemplate.fromJson(json)
                                                  }) : /* EmptyChart */0
                                            )
                                        )
                                    )
                                )
                            )
                        )
                    )
                )
            ),
          title: Json_decode.field("title", Json_decode.string, json)
        };
}

function fromJson(json) {
  return Json_decode.field("visualization", decodeItem, json);
}

exports.fromJson = fromJson;
exports.title = title;
exports.type_ = type_;
exports.id = id;
exports.setType = setType;
exports.chartTypeToString = chartTypeToString;
exports.setTitle = setTitle;
exports.compatibleCharts = compatibleCharts;
exports.toJson = toJson;
/* GenericChartAttribute-ReactHooksTemplate Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var List = require("rescript/lib/js/list.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Project$ReactHooksTemplate = require("./project.bs.js");
var TextInputNew$ReactHooksTemplate = require("../common/TextInputNew.bs.js");

function ProjectForm(Props) {
  var toggleProjectForm = Props.toggleProjectForm;
  var selectedProject = Props.selectedProject;
  var onSubmit = Props.onSubmit;
  var match = React.useState(function () {
        return selectedProject;
      });
  var setProject = match[1];
  var project = match[0];
  var match$1 = React.useState(function () {
        return false;
      });
  var setErrors = match$1[1];
  var errors = match$1[0];
  var containsInvalidChar = function (specialCharRegex, words) {
    return List.exists((function (word) {
                  return List.exists((function (x) {
                                return Caml_obj.caml_equal(word, x);
                              }), specialCharRegex);
                }), words);
  };
  var handleNameChange = function (e) {
    var name = e.target.value;
    var words = Belt_List.fromArray(name.split(""));
    var error = containsInvalidChar({
          hd: "<",
          tl: {
            hd: "!",
            tl: {
              hd: "=",
              tl: {
                hd: "+",
                tl: {
                  hd: "-",
                  tl: {
                    hd: "@",
                    tl: {
                      hd: ">",
                      tl: {
                        hd: "?",
                        tl: {
                          hd: "'",
                          tl: {
                            hd: "\"",
                            tl: {
                              hd: "$",
                              tl: {
                                hd: "#",
                                tl: {
                                  hd: "%",
                                  tl: {
                                    hd: "^",
                                    tl: {
                                      hd: "*",
                                      tl: {
                                        hd: "(",
                                        tl: {
                                          hd: ")",
                                          tl: {
                                            hd: "/",
                                            tl: {
                                              hd: ".",
                                              tl: {
                                                hd: "&",
                                                tl: {
                                                  hd: ",",
                                                  tl: {
                                                    hd: ";",
                                                    tl: {
                                                      hd: "`",
                                                      tl: {
                                                        hd: "~",
                                                        tl: /* [] */0
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }, words);
    Curry._1(setErrors, (function (param) {
            return error;
          }));
    return Curry._1(setProject, (function (param) {
                  return Project$ReactHooksTemplate.setName(project, name);
                }));
  };
  return React.createElement("div", {
              className: "flex flex-col justify-between h-full"
            }, React.createElement("div", {
                  className: "p-8 border-t"
                }, React.createElement(TextInputNew$ReactHooksTemplate.make, {
                      label: "Project Name",
                      value: Project$ReactHooksTemplate.name(project),
                      handleChange: handleNameChange,
                      maxLength: 100
                    }), Project$ReactHooksTemplate.name(project).length === 0 ? React.createElement("div", {
                        className: "my-2"
                      }, React.createElement("p", {
                            className: "text-red-500 text-xs float-right"
                          }, "Name can't be empty")) : (
                    errors ? React.createElement("div", {
                            className: "my-2"
                          }, React.createElement("p", {
                                className: "text-red-500 text-xs float-right"
                              }, "special characters are not allowed")) : React.createElement(React.Fragment, undefined)
                  )), React.createElement("div", {
                  className: "p-8 flex border-t border-gray-200 gap-3 font-medium\n                    absolute bottom-0 text-base w-full z-30 "
                }, React.createElement("button", {
                      className: "bg-ghostwhite border border-gray-400\n        flex rounded py-3 px-6 text-xs w-full justify-center",
                      id: "close_modal",
                      onClick: (function (param) {
                          return Curry._1(toggleProjectForm, undefined);
                        })
                    }, "Discard"), React.createElement("button", {
                      className: "bg-palatinateblue border border-gray-400\n        flex rounded py-3 px-6 text-xs text-white w-full justify-center " + (
                        Project$ReactHooksTemplate.name(project).length === 0 || errors ? "disabled-input" : ""
                      ),
                      disabled: Project$ReactHooksTemplate.name(project).length === 0 || errors,
                      onClick: (function (param) {
                          return Curry._1(onSubmit, project);
                        })
                    }, "Save")));
}

var make = ProjectForm;

exports.make = make;
/* react Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Pervasives = require("rescript/lib/js/pervasives.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");
var Utils$ReactHooksTemplate = require("../../common/utils.bs.js");
var EmailSelected$ReactHooksTemplate = require("./emailSelected.bs.js");

function getDomains(t) {
  return t.domains;
}

function setSelected(selected, t) {
  return {
          domains: t.domains,
          selected: selected
        };
}

function resetSelected(t) {
  return {
          domains: t.domains,
          selected: undefined
        };
}

function selected(t) {
  return t.selected;
}

function isSelected(t) {
  return Belt_Option.isSome(t.selected);
}

function fromJson(json) {
  return {
          domains: Json_decode.withDefault(/* [] */0, (function (param) {
                  return Json_decode.field("domains", (function (param) {
                                return Json_decode.list(Json_decode.string, param);
                              }), param);
                }), json),
          selected: Json_decode.withDefault(undefined, (function (param) {
                  return Json_decode.field("selected", (function (param) {
                                return Json_decode.optional(EmailSelected$ReactHooksTemplate.decode, param);
                              }), param);
                }), json)
        };
}

function toString(t) {
  return EmailSelected$ReactHooksTemplate.toString(Belt_Option.getWithDefault(t.selected, ""));
}

function encodeParams(t) {
  return Pervasives.$at({
              hd: [
                "domains",
                Json_encode.list((function (prim) {
                        return prim;
                      }), t.domains)
              ],
              tl: /* [] */0
            }, Utils$ReactHooksTemplate.encodeOptionalParamToList("selected", t.selected, EmailSelected$ReactHooksTemplate.encode));
}

function encode(t) {
  return Json_encode.object_({
              hd: [
                "category_type",
                "email"
              ],
              tl: {
                hd: [
                  "params",
                  Json_encode.object_(encodeParams(t))
                ],
                tl: /* [] */0
              }
            });
}

exports.getDomains = getDomains;
exports.setSelected = setSelected;
exports.resetSelected = resetSelected;
exports.toString = toString;
exports.selected = selected;
exports.isSelected = isSelected;
exports.encode = encode;
exports.fromJson = fromJson;
/* Utils-ReactHooksTemplate Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Image$ReactHooksTemplate = require("./image.bs.js");

function AlertComponent(Props) {
  var alertType = Props.alertType;
  var handleClose = Props.handleClose;
  var closeIcon;
  closeIcon = alertType.TAG === /* Notice */0 ? Image$ReactHooksTemplate.closeIconWhite : Image$ReactHooksTemplate.closeIcon;
  var backgroundColorCSS;
  switch (alertType.TAG | 0) {
    case /* Notice */0 :
        backgroundColorCSS = "bg-notice";
        break;
    case /* Success */1 :
        backgroundColorCSS = "bg-success";
        break;
    case /* Failure */2 :
        backgroundColorCSS = "bg-failure";
        break;
    case /* Warning */3 :
        backgroundColorCSS = "bg-warning";
        break;
    
  }
  var textColorCSS;
  switch (alertType.TAG | 0) {
    case /* Notice */0 :
        textColorCSS = "text-notice";
        break;
    case /* Success */1 :
        textColorCSS = "text-success";
        break;
    case /* Failure */2 :
        textColorCSS = "text-failure";
        break;
    case /* Warning */3 :
        textColorCSS = "text-warning";
        break;
    
  }
  setTimeout((function (param) {
          return Curry._1(handleClose, undefined);
        }), 3000);
  return React.createElement("div", {
              className: "absolute right-0 mr-4"
            }, React.createElement("div", {
                  className: "" + backgroundColorCSS + " flex justify-between items-center w-350-px min-h-38-px\n    px-12-px py-10-px mx-auto shadow-sm rounded-4px "
                }, React.createElement("p", {
                      className: "" + textColorCSS + " font-normal text-14-px leading-16-px"
                    }, alertType._0), React.createElement("a", {
                      className: "w-10-px h-10-px flex-grow-0 flex-shrink-0",
                      href: "#",
                      onClick: (function ($$event) {
                          $$event.preventDefault();
                          return Curry._1(handleClose, undefined);
                        })
                    }, React.createElement("img", {
                          src: closeIcon
                        }))));
}

var make = AlertComponent;

exports.make = make;
/* react Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var List = require("rescript/lib/js/list.js");
var $$Array = require("rescript/lib/js/array.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Caml_int32 = require("rescript/lib/js/caml_int32.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_format = require("rescript/lib/js/caml_format.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var Http$ReactHooksTemplate = require("../common/http.bs.js");
var Image$ReactHooksTemplate = require("../common/image.bs.js");
var Utils$ReactHooksTemplate = require("../common/utils.bs.js");
var Domain$ReactHooksTemplate = require("../settings/domain.bs.js");
var Ticket$ReactHooksTemplate = require("./ticket.bs.js");
var Filters$ReactHooksTemplate = require("../dashboard/filters/filters.bs.js");
var TicketView$ReactHooksTemplate = require("./TicketView.bs.js");
var AuthProvider$ReactHooksTemplate = require("../AuthProvider.bs.js");
var TicketListItem$ReactHooksTemplate = require("./TicketListItem.bs.js");

function statusFromString(status) {
  var match = status.toLowerCase();
  switch (match) {
    case "closed" :
        return /* Closed */4;
    case "open" :
        return /* Open */1;
    case "parked" :
        return /* Parked */2;
    case "resolved" :
        return /* Resolved */3;
    default:
      return /* All */0;
  }
}

function statusToString(x) {
  switch (x) {
    case /* All */0 :
        return "all";
    case /* Open */1 :
        return "open";
    case /* Parked */2 :
        return "parked";
    case /* Resolved */3 :
        return "resolved";
    case /* Closed */4 :
        return "closed";
    
  }
}

function totalRecord(x) {
  if (x !== undefined) {
    return x;
  } else {
    return "1";
  }
}

function page(x) {
  if (x !== undefined) {
    return x;
  } else {
    return "1";
  }
}

function totalPages(total_records, perPage) {
  return Caml_int32.div(total_records - 1 | 0, perPage) + 1 | 0;
}

function TicketWorker(Props) {
  var status = Props.status;
  var initialState_status = statusFromString(status);
  var initialState = {
    loading: true,
    showFilter: false,
    tickets: /* [] */0,
    ticketId: 0,
    currentPage: "1",
    agents: /* [] */0,
    assignee: undefined,
    status: initialState_status
  };
  var match = React.useState(function () {
        return initialState;
      });
  var setState = match[1];
  var state = match[0];
  var match$1 = React.useContext(AuthProvider$ReactHooksTemplate.authContext);
  var hasPermission = match$1[0];
  var assignTicket = function (ticketId) {
    var assignee = state.assignee;
    if (assignee === undefined) {
      return ;
    }
    var assigneeId = Ticket$ReactHooksTemplate.Assignee.id(assignee);
    var params = {};
    params["assignee_id"] = assigneeId;
    var ticket = Belt_List.keep(state.tickets, (function (xs) {
            return Ticket$ReactHooksTemplate.id(xs) === ticketId;
          }));
    var ticketIds = Belt_List.map(ticket, Ticket$ReactHooksTemplate.id);
    params["ids"] = Json_encode.list((function (prim) {
            return prim;
          }), ticketIds);
    var ticket$1 = {};
    ticket$1["ticket"] = params;
    Http$ReactHooksTemplate.fetchPostJSON("/api/v1/tickets/assign", ticket$1).then(function (response) {
          console.log(response);
          var updatedTickets = List.map((function (x) {
                  if (Ticket$ReactHooksTemplate.id(x) === ticketId) {
                    return Ticket$ReactHooksTemplate.updateAssignee(state.assignee, x);
                  } else {
                    return x;
                  }
                }), state.tickets);
          return Promise.resolve(Curry._1(setState, (function (param) {
                            return {
                                    loading: state.loading,
                                    showFilter: state.showFilter,
                                    tickets: updatedTickets,
                                    ticketId: state.ticketId,
                                    currentPage: state.currentPage,
                                    agents: state.agents,
                                    assignee: state.assignee,
                                    status: state.status
                                  };
                          })));
        });
    
  };
  var handleAssigneeSelection = function ($$event) {
    var emailText = Curry._1(Domain$ReactHooksTemplate.$$String.Email.create, Utils$ReactHooksTemplate.formTargetValue($$event));
    if (Curry._1(Domain$ReactHooksTemplate.$$String.Email.isEmpty, emailText)) {
      return Curry._1(setState, (function (param) {
                    return {
                            loading: state.loading,
                            showFilter: state.showFilter,
                            tickets: state.tickets,
                            ticketId: state.ticketId,
                            currentPage: state.currentPage,
                            agents: state.agents,
                            assignee: undefined,
                            status: state.status
                          };
                  }));
    }
    var selected = List.find((function (assignee) {
            return Caml_obj.caml_equal(Ticket$ReactHooksTemplate.Assignee.email(assignee), emailText);
          }), state.agents);
    return Curry._1(setState, (function (param) {
                  return {
                          loading: state.loading,
                          showFilter: state.showFilter,
                          tickets: state.tickets,
                          ticketId: state.ticketId,
                          currentPage: state.currentPage,
                          agents: state.agents,
                          assignee: selected,
                          status: state.status
                        };
                }));
  };
  var fetchTickets = function (pageNumber) {
    var status = statusToString(state.status);
    var url = Curry._1(hasPermission, /* ManageTickets */2) || Curry._1(hasPermission, /* SelfAssignTickets */5) ? "/api/v1/tickets" : "/api/v1/tickets/assigned";
    return Http$ReactHooksTemplate.fetchGetJSONWithHeader("" + url + "?status=" + status + "&page=" + pageNumber + "&worker_page=true").then(function (param) {
                var x = param[0].get("Page");
                var page = x !== null ? (
                    x === null ? undefined : Caml_option.some(x)
                  ) : "1";
                var tickets = param[1].then(function (json) {
                      return Promise.resolve(Json_decode.field("tickets", (function (param) {
                                        return Json_decode.list(Ticket$ReactHooksTemplate.decode, param);
                                      }), json));
                    });
                return Promise.resolve([
                            tickets,
                            page
                          ]);
              });
  };
  var fetchTicketWorkers = function (param) {
    return Http$ReactHooksTemplate.fetchGetJSONDecode("/api/v1/recovery_agents", (function (param) {
                  return Json_decode.field("recovery_agents", (function (param) {
                                return Json_decode.list(Ticket$ReactHooksTemplate.Assignee.decode, param);
                              }), param);
                }));
  };
  var domElement = React.useRef(null);
  var handleScroll = function (param) {
    var element = Belt_Option.getExn(Caml_option.nullable_to_opt(domElement.current));
    var bottom = element.scrollHeight - element.scrollTop - element.clientHeight < 5.0;
    if (!bottom) {
      return ;
    }
    var page = Caml_format.caml_int_of_string(state.currentPage);
    var pageNumber = String(page + 1 | 0);
    fetchTickets(pageNumber).then(function (param) {
          var page = param[1];
          return Promise.resolve(param[0].then(function (tickets) {
                          var mergedTickets = List.append(state.tickets, tickets);
                          return Promise.resolve(Curry._1(setState, (function (param) {
                                            return {
                                                    loading: false,
                                                    showFilter: false,
                                                    tickets: mergedTickets,
                                                    ticketId: state.ticketId,
                                                    currentPage: page,
                                                    agents: state.agents,
                                                    assignee: state.assignee,
                                                    status: state.status
                                                  };
                                          })));
                        }));
        });
    
  };
  var updateTickets = function (ticketId, status) {
    var updatedTickets = List.map((function (x) {
            if (Ticket$ReactHooksTemplate.id(x) === ticketId) {
              return Ticket$ReactHooksTemplate.updateTicketStatus(status, x);
            } else {
              return x;
            }
          }), state.tickets);
    return Curry._1(setState, (function (param) {
                  return {
                          loading: state.loading,
                          showFilter: state.showFilter,
                          tickets: updatedTickets,
                          ticketId: state.ticketId,
                          currentPage: state.currentPage,
                          agents: state.agents,
                          assignee: state.assignee,
                          status: state.status
                        };
                }));
  };
  React.useEffect((function () {
          fetchTickets(state.currentPage).then(function (param) {
                var page = param[1];
                var tickets = param[0];
                return Promise.resolve(fetchTicketWorkers(undefined).then(function (agents) {
                                return tickets.then(function (tickets) {
                                            var selected = tickets ? Ticket$ReactHooksTemplate.id(tickets.hd) : 0;
                                            var mergedTickets = List.append(state.tickets, tickets);
                                            return Promise.resolve(Curry._1(setState, (function (param) {
                                                              return {
                                                                      loading: false,
                                                                      showFilter: false,
                                                                      tickets: mergedTickets,
                                                                      ticketId: selected,
                                                                      currentPage: page,
                                                                      agents: agents,
                                                                      assignee: state.assignee,
                                                                      status: state.status
                                                                    };
                                                            })));
                                          });
                              }));
              });
          
        }), []);
  var toggleFilter = function (param) {
    return Curry._1(setState, (function (param) {
                  return {
                          loading: state.loading,
                          showFilter: !state.showFilter,
                          tickets: state.tickets,
                          ticketId: state.ticketId,
                          currentPage: state.currentPage,
                          agents: state.agents,
                          assignee: state.assignee,
                          status: state.status
                        };
                }));
  };
  var handleStatusChange = function (status, _event) {
    Curry._1(setState, (function (param) {
            return {
                    loading: state.loading,
                    showFilter: state.showFilter,
                    tickets: state.tickets,
                    ticketId: state.ticketId,
                    currentPage: state.currentPage,
                    agents: state.agents,
                    assignee: state.assignee,
                    status: status
                  };
          }));
    var statusParam = statusToString(status);
    RescriptReactRouter.push("/tickets/work/" + statusParam);
    window.location.reload();
    
  };
  var displayTickets = function (ticket) {
    var isOpenFilter = state.ticketId === Ticket$ReactHooksTemplate.id(ticket);
    return React.createElement("div", {
                key: String(Ticket$ReactHooksTemplate.id(ticket)),
                className: "flex flex-col bg-white m-2 rounded"
              }, React.createElement("div", {
                    className: "flex text-sm justify-between rounded border-gray-200 " + (
                      isOpenFilter ? "bg-lavenderweb border-l-4 border-palatinateblue" : "border"
                    ),
                    onClick: (function (param) {
                        var id = Ticket$ReactHooksTemplate.id(ticket);
                        return Curry._1(setState, (function (param) {
                                      return {
                                              loading: state.loading,
                                              showFilter: state.showFilter,
                                              tickets: state.tickets,
                                              ticketId: id,
                                              currentPage: state.currentPage,
                                              agents: state.agents,
                                              assignee: state.assignee,
                                              status: state.status
                                            };
                                    }));
                      })
                  }, React.createElement(TicketListItem$ReactHooksTemplate.make, {
                        ticket: ticket
                      })));
  };
  var displaySelectedTicket = function (ticket) {
    var isOpenFilter = state.ticketId === Ticket$ReactHooksTemplate.id(ticket);
    return React.createElement("div", {
                key: String(Ticket$ReactHooksTemplate.id(ticket)),
                className: "flex flex-col 1/2"
              }, isOpenFilter ? React.createElement(TicketView$ReactHooksTemplate.make, {
                      ticket: ticket,
                      updateTickets: updateTickets,
                      handleAssigneeSelection: handleAssigneeSelection,
                      assignTicket: assignTicket,
                      agents: state.agents
                    }) : React.createElement(React.Fragment, undefined));
  };
  var onApply = function (param) {
    Curry._1(setState, (function (param) {
            return {
                    loading: true,
                    showFilter: false,
                    tickets: state.tickets,
                    ticketId: state.ticketId,
                    currentPage: state.currentPage,
                    agents: state.agents,
                    assignee: state.assignee,
                    status: state.status
                  };
          }));
    var pageNumber = state.currentPage;
    fetchTickets(pageNumber).then(function (param) {
          var page = param[1];
          return param[0].then(function (tickets) {
                      return Promise.resolve(Curry._1(setState, (function (param) {
                                        return {
                                                loading: false,
                                                showFilter: false,
                                                tickets: tickets,
                                                ticketId: state.ticketId,
                                                currentPage: page,
                                                agents: state.agents,
                                                assignee: state.assignee,
                                                status: state.status
                                              };
                                      })));
                    });
        });
    
  };
  if (state.loading) {
    return React.createElement("p", undefined, "Fetching tickets...");
  } else {
    return React.createElement(React.Fragment, undefined, state.showFilter ? React.createElement(Filters$ReactHooksTemplate.make, {
                      onClose: (function (param) {
                          return toggleFilter(undefined);
                        }),
                      onApply: onApply,
                      showDateFilter: false,
                      showFilterPills: false
                    }) : React.createElement(React.Fragment, undefined), React.createElement("div", undefined, React.createElement("div", {
                        className: "flex justify-between items-center mb-30-px border-b border-gray-300 px-30-px py-16-px"
                      }, React.createElement("h2", {
                            className: "text-xl font-semibold"
                          }, "Tickets"), React.createElement("button", {
                            className: "bg-ghostwhite border border-gray-400\n              shadow-inner flex rounded py-2 px-3 text-xs",
                            id: "filters",
                            onClick: (function (param) {
                                return toggleFilter(undefined);
                              })
                          }, React.createElement("img", {
                                className: "h-14-px mr-2-px",
                                src: Image$ReactHooksTemplate.filterButton
                              }), "Filters")), React.createElement("div", {
                        className: "flex px-30-px"
                      }, React.createElement("div", {
                            className: "w-1/4 bg-ghostwhitegray border border-gray-300 rounded-l-lg",
                            style: {
                              height: "calc(100vh - 10.6rem)"
                            }
                          }, React.createElement("div", {
                                className: "border-b border-gray-300 m-1 mb-4"
                              }, React.createElement("div", {
                                    className: "search-wrapper w-full p-2"
                                  }, React.createElement("img", {
                                        className: "search-icon",
                                        src: Image$ReactHooksTemplate.search
                                      }), React.createElement("input", {
                                        className: "border border-gray-300 hover:border-gray-400 focus:border-palatinateblue\n                      outline-none py-4 text-base bg-transparent rounded-lg search-input font-normal mb-2 w-full px-1 mr-2",
                                        disabled: true,
                                        placeholder: "Search Tickets",
                                        type: "text"
                                      })), React.createElement("div", {
                                    className: "dropdown mb-4 ml-2"
                                  }, React.createElement("div", {
                                        className: "flex items-center text-sm font-normal capitalize cursor-pointer"
                                      }, React.createElement("p", {
                                            className: "mr-5-px"
                                          }, statusToString(state.status)), React.createElement("img", {
                                            src: Image$ReactHooksTemplate.caretDown
                                          })), React.createElement("ul", {
                                        className: "dropdown-list shadow text-gray-800 text-sm"
                                      }, React.createElement("li", {
                                            className: "hover:bg-gray-300"
                                          }, React.createElement("div", {
                                                className: "block p-10-px hover:bg-palatinateblue hover:text-white \n                                   rounded cursor-pointer",
                                                onClick: (function (param) {
                                                    return handleStatusChange(/* All */0, param);
                                                  })
                                              }, "All")), React.createElement("li", {
                                            className: "hover:bg-gray-300"
                                          }, React.createElement("div", {
                                                className: "block p-10-px hover:bg-palatinateblue hover:text-white\n                                   rounded cursor-pointer",
                                                onClick: (function (param) {
                                                    return handleStatusChange(/* Open */1, param);
                                                  })
                                              }, "Open")), React.createElement("li", {
                                            className: "hover:bg-gray-300"
                                          }, React.createElement("div", {
                                                className: "block p-10-px hover:bg-palatinateblue hover:text-white\n                                   rounded cursor-pointer",
                                                onClick: (function (param) {
                                                    return handleStatusChange(/* Parked */2, param);
                                                  })
                                              }, "Parked")), React.createElement("li", {
                                            className: "hover:bg-gray-300"
                                          }, React.createElement("div", {
                                                className: "block p-10-px hover:bg-palatinateblue hover:text-white\n                                   rounded cursor-pointer",
                                                onClick: (function (param) {
                                                    return handleStatusChange(/* Resolved */3, param);
                                                  })
                                              }, "Resolved")), React.createElement("li", {
                                            className: "hover:bg-gray-300"
                                          }, React.createElement("div", {
                                                className: "block p-10-px hover:bg-palatinateblue hover:text-white\n                                   rounded cursor-pointer",
                                                onClick: (function (param) {
                                                    return handleStatusChange(/* Closed */4, param);
                                                  })
                                              }, "Closed"))))), React.createElement("div", {
                                ref: domElement,
                                className: "overflow-scroll",
                                style: {
                                  height: "calc(100vh - 19rem)"
                                },
                                onScroll: handleScroll
                              }, $$Array.of_list(Belt_List.map(state.tickets, displayTickets)))), React.createElement("div", {
                            className: "w-3/4 border border-gray-300 rounded-r-lg"
                          }, $$Array.of_list(Belt_List.map(state.tickets, displaySelectedTicket))))));
  }
}

var emptyPageInfo = {
  total: "1",
  perPage: "1"
};

var make = TicketWorker;

exports.statusFromString = statusFromString;
exports.statusToString = statusToString;
exports.totalRecord = totalRecord;
exports.page = page;
exports.totalPages = totalPages;
exports.emptyPageInfo = emptyPageInfo;
exports.make = make;
/* react Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Domain$ReactHooksTemplate = require("./domain.bs.js");

function emptyKpi(param) {
  return {
          id: undefined,
          userId: undefined,
          email: Curry._1(Domain$ReactHooksTemplate.$$String.Email.empty, undefined),
          filterFieldId: "",
          filterField: "",
          filterValue: "",
          kpiValue: 0
        };
}

function id(t) {
  return t.id;
}

function userId(t) {
  return t.userId;
}

function email(t) {
  return t.email;
}

function filterField(t) {
  return t.filterField;
}

function filterFieldId(t) {
  return t.filterFieldId;
}

function filterValue(t) {
  return t.filterValue;
}

function kpiValue(t) {
  return t.kpiValue;
}

function setFilterField(t, x) {
  return {
          id: t.id,
          userId: t.userId,
          email: t.email,
          filterFieldId: t.filterFieldId,
          filterField: x,
          filterValue: t.filterValue,
          kpiValue: t.kpiValue
        };
}

function setFilterValue(t, y) {
  return {
          id: t.id,
          userId: t.userId,
          email: t.email,
          filterFieldId: t.filterFieldId,
          filterField: t.filterField,
          filterValue: y,
          kpiValue: t.kpiValue
        };
}

function setKpi(t, z) {
  return {
          id: t.id,
          userId: t.userId,
          email: t.email,
          filterFieldId: t.filterFieldId,
          filterField: t.filterField,
          filterValue: t.filterValue,
          kpiValue: z
        };
}

function decode(json) {
  return {
          id: Json_decode.field("id", (function (param) {
                  return Json_decode.optional(Json_decode.$$int, param);
                }), json),
          userId: Json_decode.field("user_id", (function (param) {
                  return Json_decode.optional(Json_decode.$$int, param);
                }), json),
          email: Curry._1(Domain$ReactHooksTemplate.$$String.Email.create, Json_decode.field("user", (function (param) {
                      return Json_decode.field("email", Json_decode.string, param);
                    }), json)),
          filterFieldId: Json_decode.field("details", (function (param) {
                  return Json_decode.field("field_id", Json_decode.string, param);
                }), json),
          filterField: Json_decode.field("details", (function (param) {
                  return Json_decode.field("field", Json_decode.string, param);
                }), json),
          filterValue: Json_decode.field("details", (function (param) {
                  return Json_decode.field("value", Json_decode.string, param);
                }), json),
          kpiValue: Json_decode.field("details", (function (param) {
                  return Json_decode.field("kpi", Json_decode.$$int, param);
                }), json)
        };
}

exports.emptyKpi = emptyKpi;
exports.id = id;
exports.userId = userId;
exports.email = email;
exports.filterField = filterField;
exports.filterFieldId = filterFieldId;
exports.filterValue = filterValue;
exports.kpiValue = kpiValue;
exports.setFilterField = setFilterField;
exports.setFilterValue = setFilterValue;
exports.setKpi = setKpi;
exports.decode = decode;
/* No side effect */

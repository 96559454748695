// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var $$Array = require("rescript/lib/js/array.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Moment = require("moment");
var Js_dict = require("rescript/lib/js/js_dict.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Querystringify = require("querystringify");
var ReactMasonryCss = require("react-masonry-css").default;
var Http$ReactHooksTemplate = require("./common/http.bs.js");
var Chart$ReactHooksTemplate = require("./dashboard/dashboard/chart.bs.js");
var HtmlToPdf$ReactHooksTemplate = require("./dashboard/dashboard/htmlToPdf.bs.js");
var ComposedChartComponent$ReactHooksTemplate = require("./dashboard/dashboard/ComposedChartComponent.bs.js");

function ratingReportRowDecoder(json) {
  return {
          type_: Json_decode.field("type", Json_decode.string, json),
          promoterCount: Json_decode.field("promoter_count", Json_decode.$$int, json),
          promoterPercentage: Json_decode.field("promoter_percentage", (function (param) {
                  return Json_decode.optional(Json_decode.$$float, param);
                }), json),
          detractorCount: Json_decode.field("detractor_count", Json_decode.$$int, json),
          detractorPercentage: Json_decode.field("detractor_percentage", (function (param) {
                  return Json_decode.optional(Json_decode.$$float, param);
                }), json),
          passiveCount: Json_decode.field("passive_count", Json_decode.$$int, json),
          passivePercentage: Json_decode.field("passive_percentage", (function (param) {
                  return Json_decode.optional(Json_decode.$$float, param);
                }), json),
          total: Json_decode.field("total", Json_decode.$$int, json),
          npsScore: Json_decode.field("nps_score", (function (param) {
                  return Json_decode.optional(Json_decode.$$float, param);
                }), json)
        };
}

function ratingReportDecoder(json) {
  return {
          market: Json_decode.field("market", ratingReportRowDecoder, json),
          nonMarket: Json_decode.field("non_market", ratingReportRowDecoder, json),
          overall: Json_decode.field("overall", ratingReportRowDecoder, json)
        };
}

function weeklyReportRowDecoder(json) {
  return {
          week: Json_decode.optional((function (param) {
                  return Json_decode.field("date", Json_decode.string, param);
                }), json),
          monday: Json_decode.field("monday", (function (param) {
                  return Json_decode.optional(Json_decode.$$float, param);
                }), json),
          tuesday: Json_decode.field("tuesday", (function (param) {
                  return Json_decode.optional(Json_decode.$$float, param);
                }), json),
          wednesday: Json_decode.field("wednesday", (function (param) {
                  return Json_decode.optional(Json_decode.$$float, param);
                }), json),
          thursday: Json_decode.field("thursday", (function (param) {
                  return Json_decode.optional(Json_decode.$$float, param);
                }), json),
          friday: Json_decode.field("friday", (function (param) {
                  return Json_decode.optional(Json_decode.$$float, param);
                }), json),
          saturday: Json_decode.field("saturday", (function (param) {
                  return Json_decode.optional(Json_decode.$$float, param);
                }), json),
          sunday: Json_decode.field("sunday", (function (param) {
                  return Json_decode.optional(Json_decode.$$float, param);
                }), json)
        };
}

function weeklyReportDecoder(json) {
  return {
          overall: Json_decode.field("overall", weeklyReportRowDecoder, json),
          weeks: Json_decode.field("week_wise", (function (param) {
                  return Json_decode.list(weeklyReportRowDecoder, param);
                }), json)
        };
}

function weeklyReportRow(rowData) {
  var match = rowData.week;
  var rowStyle = match !== undefined ? "" : "bg-gray-200";
  var week = rowData.week;
  return React.createElement("tr", {
              className: rowStyle
            }, React.createElement("td", {
                  className: "p-2 border border-gray-600"
                }, week !== undefined ? week : "8 week average"), React.createElement("td", {
                  className: "p-2 border border-gray-600"
                }, Belt_Option.mapWithDefault(rowData.monday, "--", (function (x) {
                        return x.toString();
                      }))), React.createElement("td", {
                  className: "p-2 border border-gray-600"
                }, Belt_Option.mapWithDefault(rowData.tuesday, "--", (function (x) {
                        return x.toString();
                      }))), React.createElement("td", {
                  className: "p-2 border border-gray-600"
                }, Belt_Option.mapWithDefault(rowData.wednesday, "--", (function (x) {
                        return x.toString();
                      }))), React.createElement("td", {
                  className: "p-2 border border-gray-600"
                }, Belt_Option.mapWithDefault(rowData.thursday, "--", (function (x) {
                        return x.toString();
                      }))), React.createElement("td", {
                  className: "p-2 border border-gray-600"
                }, Belt_Option.mapWithDefault(rowData.friday, "--", (function (x) {
                        return x.toString();
                      }))), React.createElement("td", {
                  className: "p-2 border border-gray-600"
                }, Belt_Option.mapWithDefault(rowData.saturday, "--", (function (x) {
                        return x.toString();
                      }))), React.createElement("td", {
                  className: "p-2 border border-gray-600"
                }, Belt_Option.mapWithDefault(rowData.sunday, "--", (function (x) {
                        return x.toString();
                      }))));
}

function ratingReportHeader(param) {
  return React.createElement("thead", {
              className: "font-medium border-b bg-secondary\n                    text-gray-400"
            }, React.createElement("tr", undefined, React.createElement("th", {
                      className: "p-2"
                    }, "Type"), React.createElement("th", {
                      className: "p-2"
                    }, "NPS Score"), React.createElement("th", {
                      className: "p-2"
                    }, "Total Responses"), React.createElement("th", {
                      className: "p-2"
                    }, "Demoter%"), React.createElement("th", {
                      className: "p-2"
                    }, "Demoter"), React.createElement("th", {
                      className: "p-2"
                    }, "Promoter%"), React.createElement("th", {
                      className: "p-2"
                    }, "Promoter")));
}

function ratingReportRow(rowData) {
  var rowStyle = rowData.type_ === "Overall" ? "bg-gray-200" : "";
  return React.createElement("tr", {
              className: rowStyle
            }, React.createElement("td", {
                  className: "p-2 border border-gray-600"
                }, rowData.type_), React.createElement("td", {
                  className: "p-2 border border-gray-600"
                }, Belt_Option.mapWithDefault(rowData.npsScore, "--", (function (x) {
                        return x.toString();
                      }))), React.createElement("td", {
                  className: "p-2 border border-gray-600"
                }, String(rowData.total)), React.createElement("td", {
                  className: "p-2 border border-gray-600"
                }, Belt_Option.mapWithDefault(rowData.detractorPercentage, "--", (function (x) {
                        return x.toString();
                      }))), React.createElement("td", {
                  className: "p-2 border border-gray-600"
                }, String(rowData.detractorCount)), React.createElement("td", {
                  className: "p-2 border border-gray-600"
                }, Belt_Option.mapWithDefault(rowData.promoterPercentage, "--", (function (x) {
                        return x.toString();
                      }))), React.createElement("td", {
                  className: "p-2 border border-gray-600"
                }, String(rowData.promoterCount)));
}

function reportDecoder(json) {
  return {
          currentDayRating: Json_decode.field("report", (function (param) {
                  return Json_decode.field("current_day_split", ratingReportDecoder, param);
                }), json),
          currentMonthRating: Json_decode.field("report", (function (param) {
                  return Json_decode.field("current_month_split", ratingReportDecoder, param);
                }), json),
          yesterdayRating: Json_decode.field("report", (function (param) {
                  return Json_decode.field("yesterday_data", ratingReportDecoder, param);
                }), json),
          lastMonthRating: Json_decode.field("report", (function (param) {
                  return Json_decode.field("last_month_data", ratingReportDecoder, param);
                }), json),
          rolling5Day: Json_decode.field("report", (function (param) {
                  return Json_decode.field("rolling_5_day_trend", Chart$ReactHooksTemplate.fromJson, param);
                }), json),
          weeklyNpsTrend: Json_decode.field("report", (function (param) {
                  return Json_decode.field("weekly_nps_trend", weeklyReportDecoder, param);
                }), json),
          pdpTrend: Json_decode.field("report", (function (param) {
                  return Json_decode.field("pdp_trend", Chart$ReactHooksTemplate.fromJson, param);
                }), json)
        };
}

function downloadAsPdf(param) {
  var element = Belt_Option.getExn(Caml_option.nullable_to_opt(document.querySelector("#daily-report")));
  HtmlToPdf$ReactHooksTemplate.generatePdf(element);
  
}

function ratingComparisonReportRow(report, type_) {
  var rowStyle = type_ === "Overall" ? "bg-gray-200" : "";
  var match;
  switch (type_) {
    case "Marketplace" :
        match = [
          report.yesterdayRating.market,
          report.currentMonthRating.market,
          report.lastMonthRating.market
        ];
        break;
    case "Non Marketplace" :
        match = [
          report.yesterdayRating.nonMarket,
          report.currentMonthRating.nonMarket,
          report.lastMonthRating.nonMarket
        ];
        break;
    default:
      match = [
        report.yesterdayRating.overall,
        report.currentMonthRating.overall,
        report.lastMonthRating.overall
      ];
  }
  var lastMonth = match[2];
  var currentMonth = match[1];
  var yesterday = match[0];
  return React.createElement("tr", {
              className: " " + rowStyle + " border-b border-gray-600 "
            }, React.createElement("td", {
                  className: "p-2 border-l border-gray-600"
                }, type_), React.createElement("td", {
                  className: "p-2 border-l border-gray-600"
                }, Belt_Option.mapWithDefault(yesterday.npsScore, "--", (function (x) {
                        return x.toString();
                      }))), React.createElement("td", {
                  className: "p-2"
                }, String(yesterday.total)), React.createElement("td", {
                  className: "p-2 border-l border-gray-600"
                }, Belt_Option.mapWithDefault(currentMonth.npsScore, "--", (function (x) {
                        return x.toString();
                      }))), React.createElement("td", {
                  className: "p-2"
                }, String(currentMonth.total)), React.createElement("td", {
                  className: "p-2 border-l border-gray-600"
                }, Belt_Option.mapWithDefault(lastMonth.npsScore, "--", (function (x) {
                        return x.toString();
                      }))), React.createElement("td", {
                  className: "p-2 border-r border-gray-600"
                }, String(lastMonth.total)));
}

function DailyReport(Props) {
  var params = Props.params;
  var params$1 = Querystringify.parse(params);
  var reportDate = Belt_Option.getWithDefault(Js_dict.get(params$1, "date"), Moment().format("DD-MM-YYYY"));
  var match = React.useState(function () {
        return /* Loading */0;
      });
  var setState = match[1];
  var state = match[0];
  React.useEffect((function () {
          Http$ReactHooksTemplate.fetchGetJSON("/api/v1/daily_report_data?date=" + reportDate).then(function (json) {
                  return Promise.resolve((Curry._1(setState, (function (param) {
                                      return /* Available */{
                                              _0: reportDecoder(json)
                                            };
                                    })), undefined));
                }).catch(function (err) {
                return Promise.resolve((console.log(err), undefined));
              });
          
        }), []);
  var onClick = function (param, param$1, param$2) {
    
  };
  var tmp;
  if (state) {
    var report = state._0;
    var chartAttribute = Chart$ReactHooksTemplate.type_(report.rolling5Day);
    var tmp$1;
    tmp$1 = typeof chartAttribute === "number" || chartAttribute.TAG !== /* ComposedChart */6 ? React.createElement(React.Fragment, undefined) : React.createElement(ComposedChartComponent$ReactHooksTemplate.make, {
            chartId: Chart$ReactHooksTemplate.id(report.rolling5Day),
            chartAttribute: chartAttribute._0,
            drillDownKey: undefined,
            onClick: onClick,
            enlarged: true,
            chartHeight: "h-64"
          });
    var chartAttribute$1 = Chart$ReactHooksTemplate.type_(report.pdpTrend);
    var tmp$2;
    tmp$2 = typeof chartAttribute$1 === "number" || chartAttribute$1.TAG !== /* ComposedChart */6 ? React.createElement(React.Fragment, undefined) : React.createElement(ComposedChartComponent$ReactHooksTemplate.make, {
            chartId: Chart$ReactHooksTemplate.id(report.pdpTrend),
            chartAttribute: chartAttribute$1._0,
            drillDownKey: undefined,
            onClick: onClick,
            enlarged: true,
            chartHeight: "h-64"
          });
    tmp = React.createElement("div", {
          className: "flex flex-col"
        }, React.createElement("div", {
              className: "flex px-16 w-full items-center justify-between"
            }, React.createElement("div", undefined), React.createElement("button", {
                  className: "bg-white border-2 border-gray-300 p-2\n                       shadow-inner w-1/12",
                  onClick: (function (param) {
                      return downloadAsPdf(undefined);
                    })
                }, "Export")), React.createElement("div", {
              className: "m-16"
            }, React.createElement("div", {
                  className: "bg-white px-10-px",
                  id: "daily-report"
                }, React.createElement("div", {
                      className: "flex flex-col justify-center"
                    }, React.createElement("div", {
                          className: "flex flex-col items-center justify-center mt-50-px\n               mb-20-px"
                        }, React.createElement("p", {
                              className: "text-2xl font-extrabold text-radicalred w-full\n                 text-center"
                            }, "NPS Daily Report - " + reportDate)), React.createElement("div", {
                          className: "flex py-10-px justify-between items-center text-xl\n          font-bold mb-50-px"
                        }, React.createElement("div", {
                              className: "border border-black border-2 w-2/3 py-10-px mr-10-px\n              flex flex-col justify-center"
                            }, React.createElement("p", {
                                  className: "mx-auto mb-20-px"
                                }, "Yesterday's NPS score: "), React.createElement("p", {
                                  className: "mx-auto text-3xl"
                                }, Belt_Option.mapWithDefault(report.yesterdayRating.overall.npsScore, "--", (function (x) {
                                        return x.toString();
                                      })))), React.createElement("div", {
                              className: "border border-black border-2 w-2/3 p-10-px mr-10-px flex flex-col justify-center"
                            }, React.createElement("p", {
                                  className: "mx-auto mb-20-px"
                                }, "Current Month's NPS score: "), React.createElement("p", {
                                  className: "mx-auto text-3xl"
                                }, Belt_Option.mapWithDefault(report.currentMonthRating.overall.npsScore, "--", (function (x) {
                                        return x.toString();
                                      })))), React.createElement("div", {
                              className: "border border-black border-2 w-2/3 p-10-px flex\n            flex-col justify-center"
                            }, React.createElement("p", {
                                  className: "mx-auto mb-20-px"
                                }, "Last Month's NPS score: "), React.createElement("p", {
                                  className: "mx-auto text-3xl"
                                }, Belt_Option.mapWithDefault(report.lastMonthRating.overall.npsScore, "--", (function (x) {
                                        return x.toString();
                                      }))))), React.createElement("div", undefined, React.createElement(ReactMasonryCss, {
                              breakpointCols: 1,
                              className: "flex w-auto",
                              columnClassName: "",
                              children: null
                            }, React.createElement("div", {
                                  className: "mb-50-px w-auto"
                                }, React.createElement("p", {
                                      className: "text-xl font-bold mb-10-px"
                                    }, "Daily NPS Rating Report:"), React.createElement("table", {
                                      className: "w-full border border-black border-2"
                                    }, ratingReportHeader(undefined), React.createElement("tbody", {
                                          className: "text-center"
                                        }, ratingReportRow(report.currentDayRating.market), ratingReportRow(report.currentDayRating.nonMarket), ratingReportRow(report.currentDayRating.overall)))), React.createElement("div", {
                                  className: "mb-50-px"
                                }, React.createElement("p", {
                                      className: "text-xl font-bold mb-10-px"
                                    }, "Monthly NPS Rating Report:"), React.createElement("table", {
                                      className: "w-full border border-black border-2"
                                    }, ratingReportHeader(undefined), React.createElement("tbody", {
                                          className: "text-center"
                                        }, ratingReportRow(report.currentMonthRating.market), ratingReportRow(report.currentMonthRating.nonMarket), ratingReportRow(report.currentMonthRating.overall)))), React.createElement("div", {
                                  className: "mb-50-px"
                                }, React.createElement("p", {
                                      className: "text-xl font-bold mb-10-px"
                                    }, "NPS Rating Comparison:"), React.createElement("table", {
                                      className: "w-full"
                                    }, React.createElement("thead", {
                                          className: "font-medium bg-secondary\n                    text-gray-400"
                                        }, React.createElement("tr", {
                                              className: "bg-gray-600 text-white border-b-2"
                                            }, React.createElement("th", {
                                                  className: "bg-white"
                                                }, React.createElement("div", undefined)), React.createElement("th", {
                                                  className: "p-2",
                                                  colSpan: 2
                                                }, "Yesterday"), React.createElement("th", {
                                                  className: "p-2",
                                                  colSpan: 2
                                                }, "Current Month"), React.createElement("th", {
                                                  className: "p-2",
                                                  colSpan: 2
                                                }, "Last Month")), React.createElement("tr", {
                                              className: "border border-black"
                                            }, React.createElement("th", {
                                                  className: "p-2"
                                                }, "Type"), React.createElement("th", {
                                                  className: "p-2"
                                                }, "NPS Score"), React.createElement("th", {
                                                  className: "p-2"
                                                }, "Total Responses"), React.createElement("th", {
                                                  className: "p-2"
                                                }, "NPS Score"), React.createElement("th", {
                                                  className: "p-2"
                                                }, "Total Responses"), React.createElement("th", {
                                                  className: "p-2"
                                                }, "NPS Score"), React.createElement("th", {
                                                  className: "p-2"
                                                }, "Total Responses"))), React.createElement("tbody", {
                                          className: "text-center"
                                        }, ratingComparisonReportRow(report, "Marketplace"), ratingComparisonReportRow(report, "Non Marketplace"), ratingComparisonReportRow(report, "Overall")))), React.createElement("div", {
                                  className: "w-full mb-50-px border border-black border-2\n                p-10-px"
                                }, React.createElement("p", {
                                      className: "text-xl font-bold"
                                    }, "Rolling 5 day NPS trend"), tmp$1), React.createElement("div", {
                                  className: "mb-50-px"
                                }, React.createElement("p", {
                                      className: "text-xl font-bold mb-10-px"
                                    }, "Daily NPS Trend(Week wise view):"), React.createElement("table", {
                                      className: "w-full"
                                    }, React.createElement("thead", {
                                          className: "font-medium border border-gray-400 bg-ghostwhite text-aurometalsaurus rounded-t-lg"
                                        }, React.createElement("tr", undefined, React.createElement("th", {
                                                  className: "p-2"
                                                }, "Week of NPS Received Daate"), React.createElement("th", {
                                                  className: "p-2"
                                                }, "Monday"), React.createElement("th", {
                                                  className: "p-2"
                                                }, "Tuesday"), React.createElement("th", {
                                                  className: "p-2"
                                                }, "Wednesday"), React.createElement("th", {
                                                  className: "p-2"
                                                }, "Thursday"), React.createElement("th", {
                                                  className: "p-2"
                                                }, "Friday"), React.createElement("th", {
                                                  className: "p-2"
                                                }, "Saturday"), React.createElement("th", {
                                                  className: "p-2"
                                                }, "Sunday"))), React.createElement("tbody", {
                                          className: "text-center border border-gray-400"
                                        }, weeklyReportRow(report.weeklyNpsTrend.overall), $$Array.of_list(Belt_List.map(report.weeklyNpsTrend.weeks, weeklyReportRow))))), React.createElement("div", {
                                  className: "w-full mb-50-px border border-black border-2\n                p-10-px"
                                }, React.createElement("p", {
                                      className: "text-xl font-bold"
                                    }, "Promoter-Demoter Trend:"), tmp$2)))))));
  } else {
    tmp = React.createElement("p", undefined, "Loading");
  }
  return React.createElement(React.Fragment, undefined, tmp);
}

var make = DailyReport;

exports.ratingReportRowDecoder = ratingReportRowDecoder;
exports.ratingReportDecoder = ratingReportDecoder;
exports.weeklyReportRowDecoder = weeklyReportRowDecoder;
exports.weeklyReportDecoder = weeklyReportDecoder;
exports.weeklyReportRow = weeklyReportRow;
exports.ratingReportHeader = ratingReportHeader;
exports.ratingReportRow = ratingReportRow;
exports.reportDecoder = reportDecoder;
exports.downloadAsPdf = downloadAsPdf;
exports.ratingComparisonReportRow = ratingComparisonReportRow;
exports.make = make;
/* react Not a pure module */

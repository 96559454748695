// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Utils$ReactHooksTemplate = require("../common/utils.bs.js");
var LongText$ReactHooksTemplate = require("./LongText.bs.js");
var SurveyLanguage$ReactHooksTemplate = require("./surveyLanguage.bs.js");
var MandatoryMessage$ReactHooksTemplate = require("./MandatoryMessage.bs.js");
var QuestionTextPreview$ReactHooksTemplate = require("./QuestionTextPreview.bs.js");

function LongTextPreview(Props) {
  var longText = Props.longText;
  var surveyTheme = Props.surveyTheme;
  var language = Props.language;
  var mandatory = Props.mandatory;
  var onChange = Props.onChange;
  var handleOnchange = function ($$event) {
    var value = $$event.target.value;
    var firstChar = value[0];
    var check = Belt_Array.getBy([
          "<",
          "=",
          "+",
          "-",
          "@"
        ], (function (x) {
            return firstChar === x;
          }));
    if (check !== undefined) {
      return Curry._1(onChange, LongText$ReactHooksTemplate.setAnswer(longText, ""));
    } else {
      return Curry._1(onChange, LongText$ReactHooksTemplate.setAnswer(longText, value));
    }
  };
  var onTitleChange = function ($$event) {
    return Curry._1(onChange, LongText$ReactHooksTemplate.setText(longText, Utils$ReactHooksTemplate.formTargetValue($$event)));
  };
  var onDescriptionChange = function ($$event) {
    return Curry._1(onChange, LongText$ReactHooksTemplate.setDescription(longText, Utils$ReactHooksTemplate.formTargetValue($$event)));
  };
  return React.createElement("div", {
              className: "w-full p-16"
            }, React.createElement(QuestionTextPreview$ReactHooksTemplate.make, {
                  text: LongText$ReactHooksTemplate.getTextTranslation(longText, SurveyLanguage$ReactHooksTemplate.name(language)),
                  description: LongText$ReactHooksTemplate.getDescriptionTranslation(longText, SurveyLanguage$ReactHooksTemplate.name(language)),
                  mandatory: mandatory,
                  surveyTheme: surveyTheme,
                  onTitleChange: onTitleChange,
                  onDescriptionChange: onDescriptionChange
                }), React.createElement("div", undefined, React.createElement("div", {
                      className: "text-aurometalsaurus text-sm mb-2 font-normal"
                    }, "Input"), React.createElement("textarea", {
                      className: "py-2 px-4 bg-alicebluepurple border-b border-gray-500 focus:border-gray-600 text-xs w-full",
                      placeholder: LongText$ReactHooksTemplate.getPlaceholderTranslation(longText, SurveyLanguage$ReactHooksTemplate.name(language)),
                      rows: 5,
                      value: Belt_Option.getWithDefault(LongText$ReactHooksTemplate.answer(longText), ""),
                      onChange: handleOnchange
                    })), mandatory ? React.createElement(MandatoryMessage$ReactHooksTemplate.make, {
                    language: language
                  }) : React.createElement(React.Fragment, undefined));
}

var make = LongTextPreview;

exports.make = make;
/* react Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Domain$ReactHooksTemplate = require("../settings/domain.bs.js");

function NewPasswordInput(Props) {
  var password = Props.password;
  var label = Props.label;
  var handleChange = Props.handleChange;
  var passwordInputClassNameOpt = Props.passwordInputClassName;
  var labelClassNameOpt = Props.labelClassName;
  var containerClassNameOpt = Props.containerClassName;
  var passwordInputClassName = passwordInputClassNameOpt !== undefined ? passwordInputClassNameOpt : "border border-gray-500 hover:border-gray-700 focus:border-teal-500 outline-none w-400-px px-20-px py-10-px";
  var labelClassName = labelClassNameOpt !== undefined ? labelClassNameOpt : "font-semibold mb-10-px";
  var containerClassName = containerClassNameOpt !== undefined ? containerClassNameOpt : "";
  return React.createElement("label", {
              className: "block mb-10-px"
            }, React.createElement("div", {
                  className: containerClassName
                }, React.createElement("div", {
                      className: labelClassName
                    }, label), React.createElement("input", {
                      className: passwordInputClassName,
                      autoComplete: "new-password",
                      type: "password",
                      value: Curry._1(Domain$ReactHooksTemplate.$$String.Password.show, password),
                      onChange: handleChange
                    })));
}

var make = NewPasswordInput;

exports.make = make;
/* react Not a pure module */

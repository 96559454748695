// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");

function make(colors) {
  if (colors !== undefined) {
    return colors;
  } else {
    return /* [] */0;
  }
}

function getColorById(id, t) {
  return Belt_Option.getWithDefault(Belt_List.get(t, id), "#58b6bb");
}

function getFillColorById(id, t) {
  return Belt_Option.getWithDefault(Belt_List.get(t, id), "rgb(88, 182, 187, 0.1)");
}

exports.make = make;
exports.getColorById = getColorById;
exports.getFillColorById = getFillColorById;
/* No side effect */

// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var List = require("rescript/lib/js/list.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");

function decode(json) {
  return {
          title: Json_decode.field("title", Json_decode.string, json),
          description: Json_decode.field("description", Json_decode.string, json),
          button: Json_decode.field("button", Json_decode.string, json)
        };
}

function empty(param) {
  return {
          id: undefined,
          title: "",
          description: "",
          button: "",
          translations: /* [] */0,
          bgImage: undefined
        };
}

function make(title, description, button, bgImage) {
  return {
          id: undefined,
          title: title,
          description: description,
          button: button,
          translations: /* [] */0,
          bgImage: bgImage
        };
}

function title(t) {
  return t.title;
}

function description(t) {
  return t.description;
}

function button(t) {
  return t.button;
}

function bgImage(t) {
  return t.bgImage;
}

function id(t) {
  return t.id;
}

function setTitle(t, title) {
  return {
          id: t.id,
          title: title,
          description: t.description,
          button: t.button,
          translations: t.translations,
          bgImage: t.bgImage
        };
}

function setDescription(t, description) {
  return {
          id: t.id,
          title: t.title,
          description: description,
          button: t.button,
          translations: t.translations,
          bgImage: t.bgImage
        };
}

function setButton(t, button) {
  return {
          id: t.id,
          title: t.title,
          description: t.description,
          button: button,
          translations: t.translations,
          bgImage: t.bgImage
        };
}

function setBgImage(t, bgImage) {
  return {
          id: t.id,
          title: t.title,
          description: t.description,
          button: t.button,
          translations: t.translations,
          bgImage: bgImage
        };
}

function getTitleTranslation(t, language) {
  var translation = List.find_opt((function (param) {
          return param[0] === language;
        }), t.translations);
  if (translation !== undefined) {
    return translation[1].title;
  } else {
    return t.title;
  }
}

function getDescriptionTranslation(t, language) {
  var translation = List.find_opt((function (param) {
          return param[0] === language;
        }), t.translations);
  if (translation !== undefined) {
    return translation[1].description;
  } else {
    return t.description;
  }
}

function getButtonTranslation(t, language) {
  var translation = List.find_opt((function (param) {
          return param[0] === language;
        }), t.translations);
  if (translation !== undefined) {
    return translation[1].button;
  } else {
    return t.button;
  }
}

function toJson(destroyOpt, t) {
  var destroy = destroyOpt !== undefined ? destroyOpt : false;
  return Json_encode.object_({
              hd: [
                "title",
                t.title
              ],
              tl: {
                hd: [
                  "description",
                  t.description
                ],
                tl: {
                  hd: [
                    "button",
                    t.button
                  ],
                  tl: {
                    hd: [
                      "id",
                      Json_encode.nullable((function (prim) {
                              return prim;
                            }), t.id)
                    ],
                    tl: {
                      hd: [
                        "_destroy",
                        destroy
                      ],
                      tl: /* [] */0
                    }
                  }
                }
              }
            });
}

function decodeTranslation(json) {
  return [
          Json_decode.field("survey_language", (function (param) {
                  return Json_decode.field("name", Json_decode.string, param);
                }), json),
          Json_decode.field("key_string_mapping", decode, json)
        ];
}

function decodeWelcome(json) {
  return {
          id: Json_decode.optional((function (param) {
                  return Json_decode.field("id", Json_decode.$$int, param);
                }), json),
          title: Json_decode.field("title", Json_decode.string, json),
          description: Json_decode.field("description", Json_decode.string, json),
          button: Json_decode.field("button", Json_decode.string, json),
          translations: Json_decode.field("translations", (function (param) {
                  return Json_decode.list(decodeTranslation, param);
                }), json),
          bgImage: Json_decode.optional((function (param) {
                  return Json_decode.field("bg_image", Json_decode.string, param);
                }), json)
        };
}

function fromJson(json) {
  return Json_decode.optional((function (param) {
                return Json_decode.field("survey", (function (param) {
                              return Json_decode.field("welcome", decodeWelcome, param);
                            }), param);
              }), json);
}

var categoryType = "welcome";

exports.categoryType = categoryType;
exports.empty = empty;
exports.make = make;
exports.setTitle = setTitle;
exports.setDescription = setDescription;
exports.setButton = setButton;
exports.setBgImage = setBgImage;
exports.title = title;
exports.id = id;
exports.description = description;
exports.button = button;
exports.getTitleTranslation = getTitleTranslation;
exports.getDescriptionTranslation = getDescriptionTranslation;
exports.getButtonTranslation = getButtonTranslation;
exports.bgImage = bgImage;
exports.toJson = toJson;
exports.decodeWelcome = decodeWelcome;
exports.fromJson = fromJson;
/* No side effect */

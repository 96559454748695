// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");

function id(t) {
  return t.id;
}

function name(t) {
  return t.name;
}

function font(t) {
  return t.font;
}

function questionColor(t) {
  return t.questionColor;
}

function answerColor(t) {
  return t.answerColor;
}

function backButtonColor(t) {
  return t.backButtonColor;
}

function nextButtonColor(t) {
  return t.nextButtonColor;
}

function backgroundColor(t) {
  return t.backgroundColor;
}

function logoAlignment(t) {
  return t.logoAlignment;
}

function setLogoAlignment(t, logoAlignment) {
  return {
          id: t.id,
          name: t.name,
          default: t.default,
          font: t.font,
          questionColor: t.questionColor,
          answerColor: t.answerColor,
          backButtonColor: t.backButtonColor,
          nextButtonColor: t.nextButtonColor,
          backgroundColor: t.backgroundColor,
          logoAlignment: logoAlignment
        };
}

function empty(param) {
  return {
          id: undefined,
          name: "",
          default: false,
          font: "",
          questionColor: "",
          answerColor: "",
          backButtonColor: "",
          nextButtonColor: "",
          backgroundColor: "",
          logoAlignment: undefined
        };
}

function decode(json) {
  return {
          id: Json_decode.field("id", (function (param) {
                  return Json_decode.optional(Json_decode.$$int, param);
                }), json),
          name: Json_decode.field("name", Json_decode.string, json),
          default: Json_decode.field("default", Json_decode.bool, json),
          font: Json_decode.field("font", Json_decode.string, json),
          questionColor: Json_decode.field("colors", (function (param) {
                  return Json_decode.field("question", Json_decode.string, param);
                }), json),
          answerColor: Json_decode.field("colors", (function (param) {
                  return Json_decode.field("answer", Json_decode.string, param);
                }), json),
          backButtonColor: Json_decode.field("colors", (function (param) {
                  return Json_decode.field("backButton", Json_decode.string, param);
                }), json),
          nextButtonColor: Json_decode.field("colors", (function (param) {
                  return Json_decode.field("nextButton", Json_decode.string, param);
                }), json),
          backgroundColor: Json_decode.field("colors", (function (param) {
                  return Json_decode.field("background", Json_decode.string, param);
                }), json),
          logoAlignment: Json_decode.optional((function (param) {
                  return Json_decode.field("logo_properties", (function (param) {
                                return Json_decode.field("alignment", Json_decode.string, param);
                              }), param);
                }), json)
        };
}

function encode(t) {
  var findLogoProperties = function (logoAlignment) {
    if (logoAlignment !== undefined) {
      return Json_encode.object_({
                  hd: [
                    "alignment",
                    logoAlignment
                  ],
                  tl: /* [] */0
                });
    } else {
      return null;
    }
  };
  return Json_encode.object_({
              hd: [
                "id",
                Json_encode.nullable((function (prim) {
                        return prim;
                      }), t.id)
              ],
              tl: {
                hd: [
                  "name",
                  t.name
                ],
                tl: {
                  hd: [
                    "font",
                    t.font
                  ],
                  tl: {
                    hd: [
                      "default",
                      t.default
                    ],
                    tl: {
                      hd: [
                        "colors",
                        Json_encode.object_({
                              hd: [
                                "question",
                                t.questionColor
                              ],
                              tl: {
                                hd: [
                                  "answer",
                                  t.answerColor
                                ],
                                tl: {
                                  hd: [
                                    "backButton",
                                    t.backButtonColor
                                  ],
                                  tl: {
                                    hd: [
                                      "nextButton",
                                      t.nextButtonColor
                                    ],
                                    tl: {
                                      hd: [
                                        "background",
                                        t.backgroundColor
                                      ],
                                      tl: /* [] */0
                                    }
                                  }
                                }
                              }
                            })
                      ],
                      tl: {
                        hd: [
                          "logo_properties",
                          findLogoProperties(t.logoAlignment)
                        ],
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            });
}

exports.empty = empty;
exports.id = id;
exports.name = name;
exports.font = font;
exports.questionColor = questionColor;
exports.answerColor = answerColor;
exports.backButtonColor = backButtonColor;
exports.nextButtonColor = nextButtonColor;
exports.backgroundColor = backgroundColor;
exports.logoAlignment = logoAlignment;
exports.setLogoAlignment = setLogoAlignment;
exports.decode = decode;
exports.encode = encode;
/* No side effect */

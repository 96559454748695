// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Image$ReactHooksTemplate = require("../../common/image.bs.js");

function Loading(Props) {
  var imgOpt = Props.img;
  var textOpt = Props.text;
  var loadingOpt = Props.loading;
  var img = imgOpt !== undefined ? Caml_option.valFromOption(imgOpt) : undefined;
  var text = textOpt !== undefined ? textOpt : "";
  var loading = loadingOpt !== undefined ? loadingOpt : true;
  return React.createElement("div", {
              className: "h-full w-full flex flex-col justify-center bg-white"
            }, img !== undefined ? React.createElement("img", {
                    className: "h-32",
                    src: img
                  }) : React.createElement(React.Fragment, undefined), loading ? React.createElement("img", {
                    className: "h-10 m-4 loading-animation",
                    src: Image$ReactHooksTemplate.sandClock
                  }) : React.createElement(React.Fragment, undefined), React.createElement("div", {
                  className: "text-center"
                }, text));
}

var make = Loading;

exports.make = make;
/* react Not a pure module */

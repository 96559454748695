// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var List = require("rescript/lib/js/list.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");
var Image$ReactHooksTemplate = require("../common/image.bs.js");
var RandomId$ReactHooksTemplate = require("./RandomId.bs.js");
var RatingSmileySet$ReactHooksTemplate = require("./ratingSmileySet.bs.js");

function decode(json) {
  return {
          text: Json_decode.field("text", Json_decode.string, json),
          description: Json_decode.field("description", Json_decode.string, json)
        };
}

function answer(t) {
  return t.answer;
}

function answered(t) {
  return t.answer !== undefined;
}

function empty(param) {
  return {
          id: undefined,
          uuid: RandomId$ReactHooksTemplate.generate(undefined),
          text: "",
          description: "",
          ratingSmileySet: undefined,
          showLabels: true,
          negativeToPositive: true,
          translations: /* [] */0,
          answer: undefined
        };
}

function id(t) {
  return t.id;
}

function uuid(t) {
  return t.uuid;
}

function text(t) {
  return t.text;
}

function getTextTranslation(t, language) {
  var translation = List.find_opt((function (param) {
          return param[0] === language;
        }), t.translations);
  if (translation !== undefined) {
    return translation[1].text;
  } else {
    return t.text;
  }
}

function description(t) {
  return t.description;
}

function getDescriptionTranslation(t, language) {
  var translation = List.find_opt((function (param) {
          return param[0] === language;
        }), t.translations);
  if (translation !== undefined) {
    return translation[1].description;
  } else {
    return t.description;
  }
}

function showLabels(t) {
  return t.showLabels;
}

function negativeToPositive(t) {
  return t.negativeToPositive;
}

function ratingSmileySet(t) {
  return t.ratingSmileySet;
}

function ratingTypeCollection(t) {
  if (t.negativeToPositive) {
    return {
            hd: /* One */0,
            tl: {
              hd: /* Two */1,
              tl: {
                hd: /* Three */2,
                tl: {
                  hd: /* Four */3,
                  tl: {
                    hd: /* Five */4,
                    tl: /* [] */0
                  }
                }
              }
            }
          };
  } else {
    return {
            hd: /* Five */4,
            tl: {
              hd: /* Four */3,
              tl: {
                hd: /* Three */2,
                tl: {
                  hd: /* Two */1,
                  tl: {
                    hd: /* One */0,
                    tl: /* [] */0
                  }
                }
              }
            }
          };
  }
}

function ratingTypeToImage(ratingType, ratingSmileySet) {
  switch (ratingType) {
    case /* One */0 :
        return RatingSmileySet$ReactHooksTemplate.ratingOneSmiley(ratingSmileySet);
    case /* Two */1 :
        return RatingSmileySet$ReactHooksTemplate.ratingTwoSmiley(ratingSmileySet);
    case /* Three */2 :
        return RatingSmileySet$ReactHooksTemplate.ratingThreeSmiley(ratingSmileySet);
    case /* Four */3 :
        return RatingSmileySet$ReactHooksTemplate.ratingFourSmiley(ratingSmileySet);
    case /* Five */4 :
        return RatingSmileySet$ReactHooksTemplate.ratingFiveSmiley(ratingSmileySet);
    case /* NotSelected */5 :
        return Image$ReactHooksTemplate.excellentSmiley;
    
  }
}

function ratingTypeToString(x) {
  switch (x) {
    case /* One */0 :
        return "Poor";
    case /* Two */1 :
        return "Fair";
    case /* Three */2 :
        return "Good";
    case /* Four */3 :
        return "Very Good";
    case /* Five */4 :
        return "Excellent";
    case /* NotSelected */5 :
        return "";
    
  }
}

function ratingTypeToRating(x) {
  switch (x) {
    case /* One */0 :
        return 1;
    case /* Two */1 :
        return 2;
    case /* Three */2 :
        return 3;
    case /* Four */3 :
        return 4;
    case /* Five */4 :
        return 5;
    case /* NotSelected */5 :
        return -1;
    
  }
}

function answerToString(t) {
  var answer = t.answer;
  if (answer !== undefined) {
    return ratingTypeToString(answer);
  } else {
    return "";
  }
}

function answerToInt(t) {
  var answer = t.answer;
  if (answer !== undefined) {
    return ratingTypeToRating(answer);
  }
  
}

function answerRange(t) {
  return [
          1,
          5
        ];
}

function setText(t, text) {
  return {
          id: t.id,
          uuid: t.uuid,
          text: text,
          description: t.description,
          ratingSmileySet: t.ratingSmileySet,
          showLabels: t.showLabels,
          negativeToPositive: t.negativeToPositive,
          translations: t.translations,
          answer: t.answer
        };
}

function setDescription(t, description) {
  return {
          id: t.id,
          uuid: t.uuid,
          text: t.text,
          description: description,
          ratingSmileySet: t.ratingSmileySet,
          showLabels: t.showLabels,
          negativeToPositive: t.negativeToPositive,
          translations: t.translations,
          answer: t.answer
        };
}

function setShowLabels(t, showLabels) {
  return {
          id: t.id,
          uuid: t.uuid,
          text: t.text,
          description: t.description,
          ratingSmileySet: t.ratingSmileySet,
          showLabels: showLabels,
          negativeToPositive: t.negativeToPositive,
          translations: t.translations,
          answer: t.answer
        };
}

function setNegativeToPositive(t, negativeToPositive) {
  return {
          id: t.id,
          uuid: t.uuid,
          text: t.text,
          description: t.description,
          ratingSmileySet: t.ratingSmileySet,
          showLabels: t.showLabels,
          negativeToPositive: negativeToPositive,
          translations: t.translations,
          answer: t.answer
        };
}

function setAnswer(t, answer) {
  return {
          id: t.id,
          uuid: t.uuid,
          text: t.text,
          description: t.description,
          ratingSmileySet: t.ratingSmileySet,
          showLabels: t.showLabels,
          negativeToPositive: t.negativeToPositive,
          translations: t.translations,
          answer: answer
        };
}

function setRatingSmileySet(t, ratingSmileySet) {
  return {
          id: t.id,
          uuid: t.uuid,
          text: t.text,
          description: t.description,
          ratingSmileySet: ratingSmileySet,
          showLabels: t.showLabels,
          negativeToPositive: t.negativeToPositive,
          translations: t.translations,
          answer: t.answer
        };
}

function encodeAnswerTypeCollection(option) {
  return Json_encode.object_({
              hd: [
                "id",
                ratingTypeToRating(option)
              ],
              tl: {
                hd: [
                  "label",
                  ratingTypeToString(option)
                ],
                tl: /* [] */0
              }
            });
}

function toJson(withAnswerOpt, t) {
  var withAnswer = withAnswerOpt !== undefined ? withAnswerOpt : false;
  var x = t.ratingSmileySet;
  var encodeArray_0 = [
    "id",
    Json_encode.nullable((function (prim) {
            return prim;
          }), t.id)
  ];
  var encodeArray_1 = {
    hd: [
      "text",
      t.text
    ],
    tl: {
      hd: [
        "description",
        t.description
      ],
      tl: {
        hd: [
          "options",
          Json_encode.list(encodeAnswerTypeCollection, ratingTypeCollection(t))
        ],
        tl: {
          hd: [
            "rating_smiley_set_id",
            x !== undefined ? RatingSmileySet$ReactHooksTemplate.id(x) : 0
          ],
          tl: /* [] */0
        }
      }
    }
  };
  var encodeArray = {
    hd: encodeArray_0,
    tl: encodeArray_1
  };
  return Json_encode.object_(withAnswer ? ({
                  hd: [
                    "answer",
                    ratingTypeToRating(Belt_Option.getWithDefault(t.answer, /* NotSelected */5))
                  ],
                  tl: encodeArray
                }) : encodeArray);
}

function decodeTranslation(json) {
  return [
          Json_decode.field("survey_language", (function (param) {
                  return Json_decode.field("name", Json_decode.string, param);
                }), json),
          Json_decode.field("key_string_mapping", decode, json)
        ];
}

function fromJson(json) {
  return {
          id: Json_decode.field("category", (function (param) {
                  return Json_decode.field("id", (function (param) {
                                return Json_decode.optional(Json_decode.$$int, param);
                              }), param);
                }), json),
          uuid: Json_decode.field("uid", RandomId$ReactHooksTemplate.fromJson, json),
          text: Json_decode.field("category", (function (param) {
                  return Json_decode.field("text", Json_decode.string, param);
                }), json),
          description: Json_decode.field("category", (function (param) {
                  return Json_decode.field("description", Json_decode.string, param);
                }), json),
          ratingSmileySet: Json_decode.optional((function (param) {
                  return Json_decode.field("category", (function (param) {
                                return Json_decode.field("rating_smiley_set", RatingSmileySet$ReactHooksTemplate.fromJson, param);
                              }), param);
                }), json),
          showLabels: Json_decode.field("category", (function (param) {
                  return Json_decode.field("show_labels", Json_decode.bool, param);
                }), json),
          negativeToPositive: Json_decode.field("category", (function (param) {
                  return Json_decode.field("negative_to_positive", Json_decode.bool, param);
                }), json),
          translations: Json_decode.field("translations", (function (param) {
                  return Json_decode.list(decodeTranslation, param);
                }), json),
          answer: undefined
        };
}

var categoryType = "SmileyRating";

exports.text = text;
exports.getTextTranslation = getTextTranslation;
exports.description = description;
exports.answer = answer;
exports.getDescriptionTranslation = getDescriptionTranslation;
exports.showLabels = showLabels;
exports.negativeToPositive = negativeToPositive;
exports.ratingSmileySet = ratingSmileySet;
exports.ratingTypeCollection = ratingTypeCollection;
exports.ratingTypeToImage = ratingTypeToImage;
exports.ratingTypeToString = ratingTypeToString;
exports.ratingTypeToRating = ratingTypeToRating;
exports.setText = setText;
exports.setShowLabels = setShowLabels;
exports.setNegativeToPositive = setNegativeToPositive;
exports.setDescription = setDescription;
exports.setRatingSmileySet = setRatingSmileySet;
exports.categoryType = categoryType;
exports.answered = answered;
exports.empty = empty;
exports.id = id;
exports.uuid = uuid;
exports.toJson = toJson;
exports.setAnswer = setAnswer;
exports.fromJson = fromJson;
exports.answerToString = answerToString;
exports.answerRange = answerRange;
exports.answerToInt = answerToInt;
/* Image-ReactHooksTemplate Not a pure module */

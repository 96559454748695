// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");

function RadioButtonInput(Props) {
  var checked = Props.checked;
  var label = Props.label;
  var handleChange = Props.handleChange;
  var indeterminateOpt = Props.indeterminate;
  var classNameOpt = Props.className;
  var valueOpt = Props.value;
  var indeterminate = indeterminateOpt !== undefined ? indeterminateOpt : false;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var value = valueOpt !== undefined ? valueOpt : "";
  var domElement = React.useRef(null);
  React.useEffect(function () {
        var y = Belt_Option.getExn(Caml_option.nullable_to_opt(domElement.current));
        y.indeterminate = indeterminate;
        
      });
  return React.createElement("div", {
              className: "items-center mb-4 " + className
            }, React.createElement("input", {
                  ref: domElement,
                  className: "cursor-pointer custom-radio",
                  id: "box-shadow",
                  checked: indeterminate ? false : checked,
                  type: "radio",
                  value: value,
                  onChange: handleChange
                }), React.createElement("span", {
                  className: "ml-2 font-16-px leading-normal whitespace-normal w-11/12 align-top"
                }, label));
}

var make = RadioButtonInput;

exports.make = make;
/* react Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Http$ReactHooksTemplate = require("../common/http.bs.js");
var Vendor$ReactHooksTemplate = require("../common/vendor.bs.js");

function decodeLogo(json) {
  return Json_decode.optional((function (param) {
                return Json_decode.field("url", Json_decode.string, param);
              }), json);
}

var emptyState = {
  vendor: Vendor$ReactHooksTemplate.make(undefined)
};

function SurveyExpired(Props) {
  var match = React.useState(function () {
        return emptyState;
      });
  var setState = match[1];
  React.useEffect((function () {
          Http$ReactHooksTemplate.fetchGetJSON("/api/v1/clients/logo").then(function (json) {
                return Promise.resolve(Curry._1(setState, (function (param) {
                                  return {
                                          vendor: Vendor$ReactHooksTemplate.make(decodeLogo(json))
                                        };
                                })));
              });
          
        }), []);
  var logo = Vendor$ReactHooksTemplate.getLogo(match[0].vendor);
  return React.createElement("div", {
              className: "flex flex-col items-center h-screen justify-between"
            }, React.createElement("div", undefined), React.createElement("div", {
                  className: "flex flex-col items-center"
                }, logo !== undefined ? React.createElement("img", {
                        className: "w-64",
                        src: logo
                      }) : React.createElement(React.Fragment, undefined), React.createElement("div", {
                      className: "text-center"
                    }, "Oops! Thank you for trying to participate in the survey. You've already submitted your response or your session has expired.")), React.createElement("div", undefined));
}

var make = SurveyExpired;

exports.decodeLogo = decodeLogo;
exports.emptyState = emptyState;
exports.make = make;
/* emptyState Not a pure module */

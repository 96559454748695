// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var List = require("rescript/lib/js/list.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Caml_format = require("rescript/lib/js/caml_format.js");
var Nps$ReactHooksTemplate = require("./Nps.bs.js");
var Csat$ReactHooksTemplate = require("./csat.bs.js");
var DropDown$ReactHooksTemplate = require("./DropDown.bs.js");
var LongText$ReactHooksTemplate = require("./LongText.bs.js");
var Question$ReactHooksTemplate = require("./Question.bs.js");
var StarRating$ReactHooksTemplate = require("./StarRating.bs.js");
var RatingGroup$ReactHooksTemplate = require("./RatingGroup.bs.js");
var OpinionScale$ReactHooksTemplate = require("./OpinionScale.bs.js");
var SmileyRating$ReactHooksTemplate = require("./smileyRating.bs.js");
var MultipleChoice$ReactHooksTemplate = require("./MultipleChoice.bs.js");
var MultipleSelect$ReactHooksTemplate = require("./MultipleSelect.bs.js");

function getAnswer(question) {
  var smileyRating = Question$ReactHooksTemplate.questionType(question);
  switch (smileyRating.TAG | 0) {
    case /* OpinionScale */0 :
        return OpinionScale$ReactHooksTemplate.answerToString(smileyRating._0);
    case /* StarRating */1 :
        return StarRating$ReactHooksTemplate.answerToString(smileyRating._0);
    case /* LongText */2 :
        return LongText$ReactHooksTemplate.answerToString(smileyRating._0);
    case /* Nps */4 :
        return Nps$ReactHooksTemplate.answerToString(smileyRating._0);
    case /* MultipleChoice */5 :
        return MultipleChoice$ReactHooksTemplate.answerToString(smileyRating._0);
    case /* SmileyRating */7 :
        return SmileyRating$ReactHooksTemplate.answerToString(smileyRating._0);
    case /* Csat */8 :
        return Csat$ReactHooksTemplate.answerToString(smileyRating._0);
    default:
      return "";
  }
}

function getAnswerList(question) {
  var multipleSelect = Question$ReactHooksTemplate.questionType(question);
  switch (multipleSelect.TAG | 0) {
    case /* MultipleSelect */9 :
        return MultipleSelect$ReactHooksTemplate.answerIdList(multipleSelect._0);
    case /* DropDown */10 :
        return DropDown$ReactHooksTemplate.answerIdList(multipleSelect._0);
    default:
      return /* [] */0;
  }
}

function compare(lhs, branchLogicComparator) {
  switch (branchLogicComparator.TAG | 0) {
    case /* EqualsFromValue */0 :
        return lhs === branchLogicComparator._0;
    case /* EqualsFromList */1 :
        return Belt_List.has(branchLogicComparator._0, lhs, (function (item, search) {
                      return item === search;
                    }));
    case /* Conditional */2 :
        var comparator = branchLogicComparator._0;
        switch (comparator.TAG | 0) {
          case /* Equals */0 :
              var comparatorValueType = comparator._0;
              if (comparatorValueType.TAG === /* Int */0) {
                return Caml_format.caml_int_of_string(lhs) === Caml_format.caml_int_of_string(comparatorValueType._0);
              } else {
                return lhs === comparatorValueType._0;
              }
          case /* LessThan */1 :
              var comparatorValueType$1 = comparator._0;
              if (comparatorValueType$1.TAG === /* Int */0) {
                return Caml_format.caml_int_of_string(lhs) < Caml_format.caml_int_of_string(comparatorValueType$1._0);
              } else {
                return lhs < comparatorValueType$1._0;
              }
          case /* GreaterThan */2 :
              var comparatorValueType$2 = comparator._0;
              if (comparatorValueType$2.TAG === /* Int */0) {
                return Caml_format.caml_int_of_string(lhs) > Caml_format.caml_int_of_string(comparatorValueType$2._0);
              } else {
                return lhs > comparatorValueType$2._0;
              }
          
        }
    case /* StringConditional */3 :
        var stringComparator = branchLogicComparator._0;
        switch (stringComparator.TAG | 0) {
          case /* StringEquals */0 :
              return lhs === stringComparator._0;
          case /* StringNotEquals */1 :
              return lhs !== stringComparator._0;
          case /* StringContains */2 :
              return lhs.includes(stringComparator._0);
          case /* StringNotContains */3 :
              return !lhs.includes(stringComparator._0);
          
        }
    
  }
}

function isValid(x) {
  if (typeof x === "number") {
    return true;
  }
  switch (x.TAG | 0) {
    case /* BranchLogic */0 :
        return compare(getAnswer(x._0), x._1);
    case /* MultipleAnswerLogic */1 :
        var lhs = getAnswerList(x._0);
        var listLogicComparator = x._1;
        if (listLogicComparator.TAG === /* Contains */0) {
          return Belt_List.has(lhs, listLogicComparator._0, (function (item, search) {
                        return item === search;
                      }));
        } else {
          return !Belt_List.has(lhs, listLogicComparator._0, (function (item, search) {
                        return item === search;
                      }));
        }
    case /* RatingGroupLogic */2 :
        var itemId = x._1;
        var ratingGroup = Question$ReactHooksTemplate.questionType(x._0);
        if (ratingGroup.TAG !== /* RatingGroup */6) {
          return false;
        }
        var answers = RatingGroup$ReactHooksTemplate.answer(ratingGroup._0);
        if (answers === undefined) {
          return false;
        }
        var answer = List.find_opt((function (param) {
                return param[0] === itemId;
              }), answers);
        if (answer !== undefined) {
          return compare(String(answer[1]), x._2);
        } else {
          return false;
        }
    
  }
}

exports.isValid = isValid;
/* Nps-ReactHooksTemplate Not a pure module */

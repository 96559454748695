// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Utils$ReactHooksTemplate = require("../../common/utils.bs.js");
var EmailFilter$ReactHooksTemplate = require("./emailFilter.bs.js");
var EmailSelected$ReactHooksTemplate = require("./emailSelected.bs.js");

function EmailFilterForm(Props) {
  var filter = Props.filter;
  var onChange = Props.onChange;
  var handleOnChange = function ($$event) {
    return Curry._1(onChange, {
                TAG: /* Email */4,
                _0: EmailFilter$ReactHooksTemplate.setSelected(Caml_option.some(EmailSelected$ReactHooksTemplate.make(Utils$ReactHooksTemplate.formTargetValue($$event))), filter)
              });
  };
  return React.createElement("div", undefined, React.createElement("p", {
                  className: "text-xs p-2"
                }, "Email"), React.createElement("input", {
                  className: "border border-gray-200 hover:border-gray-400 focus:border-teal-500 outline-none w-full py-10-px px-1 text-sm bg-transparent",
                  type: "email",
                  value: Belt_Option.getWithDefault(Belt_Option.getWithDefault(EmailFilter$ReactHooksTemplate.selected(filter), ""), ""),
                  onChange: handleOnChange
                }));
}

var make = EmailFilterForm;

exports.make = make;
/* react Not a pure module */

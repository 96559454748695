// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");

function value(t) {
  return t.value;
}

function setValue(param, x) {
  return {
          value: x
        };
}

function decode(json) {
  return {
          value: Json_decode.field("value", Json_decode.bool, json)
        };
}

function encode(t) {
  return t.value;
}

exports.value = value;
exports.setValue = setValue;
exports.decode = decode;
exports.encode = encode;
/* No side effect */

// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Js_exn = require("rescript/lib/js/js_exn.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");
var GenerateTicket$ReactHooksTemplate = require("./generateTicket.bs.js");
var SavePartialResponse$ReactHooksTemplate = require("./savePartialResponse.bs.js");

function id(t) {
  return t.id;
}

function enforce(t) {
  return t.enforce;
}

function enforced(t) {
  return t.enforced;
}

function setEnforce(enforce, t) {
  return {
          id: t.id,
          individualSetting: t.individualSetting,
          enforce: enforce,
          enforced: t.enforced,
          enforcedFrom: t.enforcedFrom
        };
}

function individualSetting(t) {
  return t.individualSetting;
}

function setIndividualSetting(individualSetting, t) {
  return {
          id: t.id,
          individualSetting: individualSetting,
          enforce: t.enforce,
          enforced: t.enforced,
          enforcedFrom: t.enforcedFrom
        };
}

function decodeIndividualSetting(json) {
  var individualSettingName = Json_decode.field("name", Json_decode.string, json);
  switch (individualSettingName) {
    case "generate_ticket" :
        return {
                TAG: /* GenerateTicket */1,
                _0: GenerateTicket$ReactHooksTemplate.decode(json)
              };
    case "save_partial_response" :
        return {
                TAG: /* SavePartialResponse */0,
                _0: SavePartialResponse$ReactHooksTemplate.decode(json)
              };
    default:
      return Js_exn.raiseError("Unknown individual setting");
  }
}

function decode(json) {
  return {
          id: Json_decode.field("id", Json_decode.$$int, json),
          individualSetting: decodeIndividualSetting(json),
          enforce: Json_decode.field("enforce_to_lower", Json_decode.bool, json),
          enforced: Json_decode.field("enforced", Json_decode.bool, json),
          enforcedFrom: undefined
        };
}

function encode(t) {
  var x = t.individualSetting;
  var tmp;
  tmp = x.TAG === /* SavePartialResponse */0 ? SavePartialResponse$ReactHooksTemplate.encode(x._0) : GenerateTicket$ReactHooksTemplate.encode(x._0);
  return Json_encode.object_({
              hd: [
                "id",
                t.id
              ],
              tl: {
                hd: [
                  "enforce_to_lower",
                  t.enforce
                ],
                tl: {
                  hd: [
                    "value",
                    tmp
                  ],
                  tl: /* [] */0
                }
              }
            });
}

exports.id = id;
exports.enforce = enforce;
exports.enforced = enforced;
exports.setEnforce = setEnforce;
exports.individualSetting = individualSetting;
exports.setIndividualSetting = setIndividualSetting;
exports.decode = decode;
exports.encode = encode;
/* No side effect */

// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var CheckboxInput$ReactHooksTemplate = require("../common/CheckboxInput.bs.js");

function SurveyPolicy(Props) {
  return React.createElement("div", undefined, React.createElement("div", {
                  className: "px-5 py-6 border-b border-gray-300 text-darkcharcoal font-bold text-xl"
                }, "Survey Policy Settings"), React.createElement("div", {
                  className: "p-8"
                }, React.createElement("div", undefined, React.createElement("div", {
                          className: "mt-8"
                        }, React.createElement("div", {
                              className: "flex justify-between w-4/5 mb-10 items-start"
                            }, React.createElement("div", {
                                  className: "flex flex-col"
                                }, React.createElement("div", {
                                      className: "font-medium text-base text-darkcharcoal"
                                    }, "Save and continue"), React.createElement("div", {
                                      className: "text-aurometalsaurus text-xs mt-4"
                                    }, "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed id lectus natoque.")), React.createElement("div", {
                                  className: "flex custom_checkbox items-center"
                                }, React.createElement(CheckboxInput$ReactHooksTemplate.make, {
                                      checked: false,
                                      label: "Mandatory",
                                      handleChange: (function (param) {
                                          console.log("changed");
                                          
                                        }),
                                      labelClassName: "text-xs text-aurometalsaurus leading-normal whitespace-normal w-11/12 mt-2-px"
                                    }), React.createElement("label", {
                                      className: "switch ml-8"
                                    }, React.createElement("input", {
                                          type: "checkbox"
                                        }), React.createElement("span", {
                                          className: "slider round"
                                        })))), React.createElement("div", {
                              className: "flex justify-between w-4/5 mb-10 items-start"
                            }, React.createElement("div", {
                                  className: "flex flex-col"
                                }, React.createElement("div", {
                                      className: "font-medium text-base text-darkcharcoal"
                                    }, "Navigation arrows"), React.createElement("div", {
                                      className: "text-aurometalsaurus text-xs mt-4"
                                    }, "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed id lectus natoque.")), React.createElement("div", {
                                  className: "flex custom_checkbox items-center"
                                }, React.createElement(CheckboxInput$ReactHooksTemplate.make, {
                                      checked: false,
                                      label: "Mandatory",
                                      handleChange: (function (param) {
                                          console.log("changed");
                                          
                                        }),
                                      labelClassName: "text-xs text-aurometalsaurus leading-normal whitespace-normal w-11/12 mt-2-px"
                                    }), React.createElement("label", {
                                      className: "switch ml-8"
                                    }, React.createElement("input", {
                                          type: "checkbox"
                                        }), React.createElement("span", {
                                          className: "slider round"
                                        })))), React.createElement("div", {
                              className: "flex justify-between w-4/5 mb-10 items-start"
                            }, React.createElement("div", {
                                  className: "flex flex-col"
                                }, React.createElement("div", {
                                      className: "font-medium text-base text-darkcharcoal"
                                    }, "Meta Description"), React.createElement("div", {
                                      className: "text-aurometalsaurus text-xs mt-4"
                                    }, "Mandate all surveys to set a meta description")), React.createElement("div", {
                                  className: "flex custom_checkbox items-center"
                                }, React.createElement(CheckboxInput$ReactHooksTemplate.make, {
                                      checked: false,
                                      label: "Mandatory",
                                      handleChange: (function (param) {
                                          console.log("changed");
                                          
                                        }),
                                      labelClassName: "text-xs text-aurometalsaurus leading-normal whitespace-normal w-11/12 mt-2-px"
                                    }), React.createElement("label", {
                                      className: "switch ml-8"
                                    }, React.createElement("input", {
                                          type: "checkbox"
                                        }), React.createElement("span", {
                                          className: "slider round"
                                        })))), React.createElement("div", {
                              className: "flex justify-between w-4/5 mb-10 items-start"
                            }, React.createElement("div", {
                                  className: "flex flex-col"
                                }, React.createElement("div", {
                                      className: "font-medium text-base text-darkcharcoal"
                                    }, "Question Type Tags"), React.createElement("div", {
                                      className: "text-aurometalsaurus text-xs mt-4"
                                    }, "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Velit netus nibh volutpat.")), React.createElement("div", {
                                  className: "flex custom_checkbox items-center"
                                }, React.createElement("label", {
                                      className: "switch ml-8"
                                    }, React.createElement("input", {
                                          type: "checkbox"
                                        }), React.createElement("span", {
                                          className: "slider round"
                                        })))), React.createElement("div", {
                              className: "flex justify-between w-4/5 mb-10 items-start"
                            }, React.createElement("div", {
                                  className: "flex flex-col"
                                }, React.createElement("div", {
                                      className: "font-medium text-base text-darkcharcoal"
                                    }, "Track Key Metric"), React.createElement("div", {
                                      className: "text-aurometalsaurus text-xs mt-4"
                                    }, "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Odio risus vestibulum.")), React.createElement("div", {
                                  className: "flex custom_checkbox items-center"
                                }, React.createElement("label", {
                                      className: "switch ml-8"
                                    }, React.createElement("input", {
                                          type: "checkbox"
                                        }), React.createElement("span", {
                                          className: "slider round"
                                        }))))))));
}

var make = SurveyPolicy;

exports.make = make;
/* react Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var $$Array = require("rescript/lib/js/array.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Image$ReactHooksTemplate = require("../common/image.bs.js");

function SettingsMenu(Props) {
  var selectedMainMenu = Props.selectedMainMenu;
  var selectedSubMenu = Props.selectedSubMenu;
  var setSelectedMainMenu = Props.setSelectedMainMenu;
  var setSelectedSubMenu = Props.setSelectedSubMenu;
  return React.createElement("div", {
              className: "bg-ghostwhite"
            }, React.createElement("div", {
                  className: "flex items-center h-10 mt-6 mx-6"
                }, React.createElement("img", {
                      className: "h-14-px mr-1 cursor-pointer",
                      src: Image$ReactHooksTemplate.search
                    }), React.createElement("input", {
                      className: "text-sm text-aurometalsaurus mr-1 mt-1 w-150-px\n                   bg-ghostwhite focus:outline-none",
                      placeholder: "Search Settings"
                    })), React.createElement("div", {
                  className: "border-b border-gray-300 py-2"
                }, React.createElement("div", {
                      className: "my-4 mx-6 text-darkcharcoal text-base font-semibold"
                    }, "General Settings"), React.createElement("div", {
                      className: "align-middle items-center"
                    }, $$Array.of_list(Belt_List.map({
                              hd: "Organisation Profile",
                              tl: {
                                hd: "Organization Configurations",
                                tl: {
                                  hd: "Survey Policy",
                                  tl: {
                                    hd: "Campaign Policy",
                                    tl: {
                                      hd: "Languages",
                                      tl: /* [] */0
                                    }
                                  }
                                }
                              }
                            }, (function (setting) {
                                return React.createElement("div", {
                                            className: "px-6 py-3 h-10 cursor-pointer " + (
                                              selectedMainMenu === "generalSettings" && selectedSubMenu === setting ? "text-darkcharcoal text-base bg-lavenderweb" : "text-aurometalsaurus text-base"
                                            ),
                                            onClick: (function (param) {
                                                Curry._1(setSelectedMainMenu, (function (param) {
                                                        return "generalSettings";
                                                      }));
                                                return Curry._1(setSelectedSubMenu, (function (param) {
                                                              return setting;
                                                            }));
                                              })
                                          }, React.createElement("div", {
                                                className: "flex justify-between w-full items-center"
                                              }, React.createElement("div", undefined, setting)));
                              }))))), React.createElement("div", {
                  className: "border-b border-gray-300 py-2"
                }, React.createElement("div", {
                      className: "my-4 mx-6 text-darkcharcoal text-base font-semibold"
                    }, "Messaging Channels"), React.createElement("div", {
                      className: "align-middle items-center"
                    }, $$Array.of_list(Belt_List.map({
                              hd: "Email",
                              tl: {
                                hd: "SMS",
                                tl: {
                                  hd: "WhatsApp",
                                  tl: {
                                    hd: "Social",
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }, (function (setting) {
                                return React.createElement("div", {
                                            className: "px-6 py-3 h-10 cursor-pointer " + (
                                              selectedMainMenu === "messagingChannels" && selectedSubMenu === setting ? "text-darkcharcoal text-base bg-lavenderweb" : "text-aurometalsaurus text-base"
                                            ),
                                            onClick: (function (param) {
                                                Curry._1(setSelectedMainMenu, (function (param) {
                                                        return "messagingChannels";
                                                      }));
                                                return Curry._1(setSelectedSubMenu, (function (param) {
                                                              return setting;
                                                            }));
                                              })
                                          }, React.createElement("div", {
                                                className: "flex justify-between w-full items-center"
                                              }, React.createElement("div", undefined, setting)));
                              }))))), React.createElement("div", {
                  className: "border-b border-gray-300 py-2"
                }, React.createElement("div", {
                      className: "my-4 mx-6 text-darkcharcoal text-base font-semibold"
                    }, "Templates"), React.createElement("div", {
                      className: "align-middle items-center"
                    }, $$Array.of_list(Belt_List.map({
                              hd: "Email",
                              tl: {
                                hd: "Standard Pages",
                                tl: /* [] */0
                              }
                            }, (function (setting) {
                                return React.createElement("div", {
                                            className: "px-6 py-3 h-10 cursor-pointer " + (
                                              selectedMainMenu === "templates" && selectedSubMenu === setting ? "text-darkcharcoal text-base bg-lavenderweb" : "text-aurometalsaurus text-base"
                                            ),
                                            onClick: (function (param) {
                                                Curry._1(setSelectedMainMenu, (function (param) {
                                                        return "templates";
                                                      }));
                                                return Curry._1(setSelectedSubMenu, (function (param) {
                                                              return setting;
                                                            }));
                                              })
                                          }, React.createElement("div", {
                                                className: "flex justify-between w-full items-center"
                                              }, React.createElement("div", undefined, setting)));
                              }))))), React.createElement("div", {
                  className: "py-2"
                }, React.createElement("div", {
                      className: "my-4 mx-6 text-darkcharcoal text-base font-semibold"
                    }, "Distribution"), React.createElement("div", {
                      className: "align-middle items-center"
                    }, $$Array.of_list(Belt_List.map({
                              hd: "API",
                              tl: {
                                hd: "sFTP",
                                tl: /* [] */0
                              }
                            }, (function (setting) {
                                return React.createElement("div", {
                                            className: "px-6 py-3 h-10 cursor-pointer " + (
                                              selectedMainMenu === "distribution" && selectedSubMenu === setting ? "text-darkcharcoal text-base bg-lavenderweb" : "text-aurometalsaurus text-base"
                                            ),
                                            onClick: (function (param) {
                                                Curry._1(setSelectedMainMenu, (function (param) {
                                                        return "distribution";
                                                      }));
                                                return Curry._1(setSelectedSubMenu, (function (param) {
                                                              return setting;
                                                            }));
                                              })
                                          }, React.createElement("div", {
                                                className: "flex justify-between w-full items-center"
                                              }, React.createElement("div", undefined, setting)));
                              }))))));
}

var emptyState = /* [] */0;

var make = SettingsMenu;

exports.emptyState = emptyState;
exports.make = make;
/* react Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var List = require("rescript/lib/js/list.js");
var $$Array = require("rescript/lib/js/array.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Http$ReactHooksTemplate = require("../common/http.bs.js");
var Image$ReactHooksTemplate = require("../common/image.bs.js");
var Filters$ReactHooksTemplate = require("../dashboard/filters/filters.bs.js");

function email(t) {
  return t.email;
}

function lob(t) {
  return t.lob;
}

function touchPoint(t) {
  return t.touchPoint;
}

function open_(t) {
  return t.open_;
}

function closed(t) {
  return t.closed;
}

function parked(t) {
  return t.parked;
}

function resolved(t) {
  return t.resolved;
}

function fromJson(json) {
  return {
          email: Json_decode.field("agent", Json_decode.string, json),
          touchPoint: Json_decode.optional((function (param) {
                  return Json_decode.field("touch_point", Json_decode.string, param);
                }), json),
          lob: Json_decode.optional((function (param) {
                  return Json_decode.field("lob", Json_decode.string, param);
                }), json),
          open_: Json_decode.field("open", Json_decode.$$int, json),
          closed: Json_decode.field("closed", Json_decode.$$int, json),
          parked: Json_decode.field("parked", Json_decode.$$int, json),
          resolved: Json_decode.field("resolved", Json_decode.$$int, json)
        };
}

var AgentData = {
  email: email,
  lob: lob,
  touchPoint: touchPoint,
  open_: open_,
  closed: closed,
  parked: parked,
  resolved: resolved,
  fromJson: fromJson
};

function fetchAgentWiseSummary(param) {
  return Http$ReactHooksTemplate.fetchGetJSONDecode("/api/v1/tickets/agent_wise_summary", (function (param) {
                return Json_decode.field("agent_wise_summary", (function (param) {
                              return Json_decode.list(fromJson, param);
                            }), param);
              }));
}

var initialState = {
  loading: true,
  filter: false,
  data: /* [] */0
};

function AgentWiseSummary(Props) {
  var match = React.useState(function () {
        return initialState;
      });
  var setState = match[1];
  var state = match[0];
  React.useEffect((function () {
          fetchAgentWiseSummary(undefined).then(function (agentDataList) {
                return Promise.resolve(Curry._1(setState, (function (state) {
                                  return {
                                          loading: false,
                                          filter: state.filter,
                                          data: agentDataList
                                        };
                                })));
              });
          
        }), []);
  var onApplyFilter = function (param) {
    Curry._1(setState, (function (state) {
            return {
                    loading: true,
                    filter: false,
                    data: state.data
                  };
          }));
    fetchAgentWiseSummary(undefined).then(function (agentDataList) {
          return Promise.resolve(Curry._1(setState, (function (state) {
                            return {
                                    loading: false,
                                    filter: state.filter,
                                    data: agentDataList
                                  };
                          })));
        });
    
  };
  if (state.loading === true) {
    return React.createElement("p", undefined, "Loading");
  } else {
    return React.createElement(React.Fragment, undefined, state.filter ? React.createElement(Filters$ReactHooksTemplate.make, {
                      onClose: (function (param) {
                          return Curry._1(setState, (function (state) {
                                        return {
                                                loading: state.loading,
                                                filter: false,
                                                data: state.data
                                              };
                                      }));
                        }),
                      onApply: onApplyFilter,
                      showDateFilter: false,
                      showFilterPills: false
                    }) : React.createElement(React.Fragment, undefined), React.createElement("div", undefined, React.createElement("div", {
                        className: "flex justify-between items-center mb-30-px border-b border-gray-300 px-30-px py-16-px"
                      }, React.createElement("h2", {
                            className: "text-xl font-semibold"
                          }, "Agent Summary"), React.createElement("button", {
                            className: "bg-ghostwhite border border-gray-400\n            shadow-inner flex rounded py-2 px-3 text-xs",
                            id: "filters",
                            onClick: (function (param) {
                                return Curry._1(setState, (function (state) {
                                              return {
                                                      loading: state.loading,
                                                      filter: true,
                                                      data: state.data
                                                    };
                                            }));
                              })
                          }, React.createElement("img", {
                                className: "h-14-px mr-2-px",
                                src: Image$ReactHooksTemplate.filterButton
                              }), "Filters")), React.createElement("div", {
                        className: "flex items-center px-30-px"
                      }, React.createElement("table", {
                            className: "text-left table-auto w-full border-separate text-xs font-medium"
                          }, React.createElement("thead", {
                                className: "font-medium p-4 bg-ghostwhite text-aurometalsaurus"
                              }, React.createElement("tr", undefined, React.createElement("th", {
                                        className: "px-4 py-2"
                                      }, React.createElement("div", {
                                            className: "flex"
                                          }, "Email", React.createElement("img", {
                                                className: "ml-4-px",
                                                src: Image$ReactHooksTemplate.heading
                                              }))), React.createElement("th", {
                                        className: "px-4 py-2"
                                      }, React.createElement("div", {
                                            className: "flex"
                                          }, "Touch Point", React.createElement("img", {
                                                className: "ml-4-px",
                                                src: Image$ReactHooksTemplate.heading
                                              }))), React.createElement("th", {
                                        className: "px-4 py-2"
                                      }, React.createElement("div", {
                                            className: "flex"
                                          }, "Lob", React.createElement("img", {
                                                className: "ml-4-px",
                                                src: Image$ReactHooksTemplate.heading
                                              }))), React.createElement("th", {
                                        className: "px-4 py-2"
                                      }, React.createElement("div", {
                                            className: "flex"
                                          }, "Open", React.createElement("img", {
                                                className: "ml-4-px",
                                                src: Image$ReactHooksTemplate.heading
                                              }))), React.createElement("th", {
                                        className: "px-4 py-2"
                                      }, React.createElement("div", {
                                            className: "flex"
                                          }, "Parked", React.createElement("img", {
                                                className: "ml-4-px",
                                                src: Image$ReactHooksTemplate.heading
                                              }))), React.createElement("th", {
                                        className: "px-4 py-2"
                                      }, React.createElement("div", {
                                            className: "flex"
                                          }, "Closed", React.createElement("img", {
                                                className: "ml-4-px",
                                                src: Image$ReactHooksTemplate.heading
                                              }))), React.createElement("th", {
                                        className: "px-4 py-2"
                                      }, React.createElement("div", {
                                            className: "flex"
                                          }, "Resolved", React.createElement("img", {
                                                className: "ml-4-px",
                                                src: Image$ReactHooksTemplate.heading
                                              }))), React.createElement("th", {
                                        className: "px-4 py-2"
                                      }, React.createElement("div", {
                                            className: "flex"
                                          }, "Total", React.createElement("img", {
                                                className: "ml-4-px",
                                                src: Image$ReactHooksTemplate.heading
                                              }))))), React.createElement("tbody", undefined, $$Array.of_list(List.map((function (x) {
                                          return React.createElement("tr", {
                                                      key: x.email,
                                                      className: "border-b border-bottom-gray-600"
                                                    }, React.createElement("td", {
                                                          className: "px-4 py-2"
                                                        }, x.email), React.createElement("td", {
                                                          className: "px-4 py-2"
                                                        }, Belt_Option.getWithDefault(x.touchPoint, "")), React.createElement("td", {
                                                          className: "px-4 py-2"
                                                        }, Belt_Option.getWithDefault(x.lob, "")), React.createElement("td", {
                                                          className: "px-4 py-2"
                                                        }, String(x.open_)), React.createElement("td", {
                                                          className: "px-4 py-2"
                                                        }, String(x.parked)), React.createElement("td", {
                                                          className: "px-4 py-2"
                                                        }, String(x.closed)), React.createElement("td", {
                                                          className: "px-4 py-2"
                                                        }, String(x.resolved)), React.createElement("td", {
                                                          className: "px-4 py-2"
                                                        }, String(((x.resolved + x.open_ | 0) + x.parked | 0) + x.closed | 0)));
                                        }), state.data)))))));
  }
}

var make = AgentWiseSummary;

exports.AgentData = AgentData;
exports.fetchAgentWiseSummary = fetchAgentWiseSummary;
exports.initialState = initialState;
exports.make = make;
/* react Not a pure module */

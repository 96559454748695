// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var DataType$ReactHooksTemplate = require("./dataType.bs.js");

function id(t) {
  return t.id;
}

function name(t) {
  return t.name;
}

function dataType(t) {
  return t.dataType;
}

function decode(json) {
  return {
          id: Json_decode.field("id", Json_decode.$$int, json),
          name: Json_decode.field("name", Json_decode.string, json),
          dataType: DataType$ReactHooksTemplate.decode(json)
        };
}

exports.id = id;
exports.name = name;
exports.dataType = dataType;
exports.decode = decode;
/* No side effect */

// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';


var Jspdf = require('jspdf');
var DomToImage = require('dom-to-image');
;

function generatePdf(element) {
  return (DomToImage.toPng(element).then(function(dataUrl) {
    var htmlImage = new Image();
    htmlImage.src = dataUrl;
    var jsPdf = new Jspdf.jsPDF({
      orientation: 'portrait',
      unit: 'pt',
      format: [500, 1000]
    });
    jsPdf.addImage(htmlImage, 'JPEG', 0, 0, 500, 1000);
    jsPdf.save('download.pdf');
    return Promise.resolve();
  }));
}

function generateDashboardPdf(element) {
  return (DomToImage.toPng(element, {filter: function(node) {
  return node.tagName !== 'BUTTON';}}).then(function(dataUrl) {
    var htmlImage = new Image();
    htmlImage.src = dataUrl;
    var jsPdf = new Jspdf.jsPDF({
      orientation: 'portrait',
      unit: 'pt',
      format: [1000, 1000]
    });
    jsPdf.addImage(htmlImage, 'JPEG', 0, 0, 1000, 1000);
    jsPdf.save('download.pdf');
    return Promise.resolve();
  }));
}

exports.generatePdf = generatePdf;
exports.generateDashboardPdf = generateDashboardPdf;
/*  Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var VisualizationSettings$ReactHooksTemplate = require("./visualizationSettings.bs.js");

function id(t) {
  return t.id;
}

function name(t) {
  return t.name;
}

function dashboardId(t) {
  return t.dashboardId;
}

function visualizationType(t) {
  return t.visualizationType;
}

function decodeItem(json) {
  var visualizationType = Json_decode.field("visualization_type", Json_decode.string, json);
  return {
          id: Json_decode.field("id", Json_decode.$$int, json),
          name: Json_decode.field("name", Json_decode.string, json),
          dashboardId: Json_decode.field("dashboard_id", Json_decode.$$int, json),
          settings: Json_decode.field("settings", (function (param) {
                  return Json_decode.withDefault(undefined, (function (param) {
                                return VisualizationSettings$ReactHooksTemplate.fromJson(visualizationType, param);
                              }), param);
                }), json),
          visualizationType: visualizationType === "chart" ? /* Chart */0 : (
              visualizationType === "map" ? /* Map */2 : (
                  visualizationType === "score_card" ? /* ScoreCard */1 : /* Empty */4
                )
            )
        };
}

function fromJson(json) {
  return Json_decode.field("visualizations", (function (param) {
                return Json_decode.list(decodeItem, param);
              }), json);
}

exports.fromJson = fromJson;
exports.id = id;
exports.name = name;
exports.dashboardId = dashboardId;
exports.visualizationType = visualizationType;
/* No side effect */

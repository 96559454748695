// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var List = require("rescript/lib/js/list.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");

function name(t) {
  return t.name;
}

function code(t) {
  return t.code;
}

function messages(t) {
  return t.messages;
}

function message(t, key) {
  var x = List.find_opt((function (x) {
          return x.key === key;
        }), t.messages);
  if (x !== undefined) {
    return x.verbiage;
  }
  switch (key) {
    case "back" :
        return "Back";
    case "mandatory" :
        return "Request your response. This is a mandatory question.";
    case "next" :
        return "Next";
    case "submit" :
        return "Submit";
    default:
      return "";
  }
}

function decodeMessage(json) {
  return {
          key: Json_decode.field("key", Json_decode.string, json),
          verbiage: Json_decode.field("verbiage", Json_decode.string, json)
        };
}

function fromJson(json) {
  return {
          name: Json_decode.field("name", Json_decode.string, json),
          code: Json_decode.field("code", Json_decode.string, json),
          messages: Json_decode.field("messages", (function (param) {
                  return Json_decode.list(decodeMessage, param);
                }), json)
        };
}

var $$default = {
  name: "English",
  code: "en",
  messages: {
    hd: {
      key: "next",
      verbiage: "Next"
    },
    tl: {
      hd: {
        key: "back",
        verbiage: "Back"
      },
      tl: {
        hd: {
          key: "submit",
          verbiage: "Submit"
        },
        tl: {
          hd: {
            key: "mandatory",
            verbiage: "Request your response. This is a mandatory question."
          },
          tl: /* [] */0
        }
      }
    }
  }
};

exports.$$default = $$default;
exports.default = $$default;
exports.__esModule = true;
exports.name = name;
exports.code = code;
exports.messages = messages;
exports.message = message;
exports.decodeMessage = decodeMessage;
exports.fromJson = fromJson;
/* No side effect */

// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var List = require("rescript/lib/js/list.js");
var $$Array = require("rescript/lib/js/array.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Js_dict = require("rescript/lib/js/js_dict.js");
var Belt_Int = require("rescript/lib/js/belt_Int.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Caml_int32 = require("rescript/lib/js/caml_int32.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_format = require("rescript/lib/js/caml_format.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");
var Querystringify = require("querystringify");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var Http$ReactHooksTemplate = require("../common/http.bs.js");
var Image$ReactHooksTemplate = require("../common/image.bs.js");
var Utils$ReactHooksTemplate = require("../common/utils.bs.js");
var Report$ReactHooksTemplate = require("./report.bs.js");
var Campaign$ReactHooksTemplate = require("../surveys/campaign.bs.js");
var DataField$ReactHooksTemplate = require("../settings/dataField.bs.js");
var Pagination$ReactHooksTemplate = require("../common/Pagination.bs.js");
var ReportCrud$ReactHooksTemplate = require("./ReportCrud.bs.js");
var UserDetail$ReactHooksTemplate = require("../common/UserDetail.bs.js");
var SurveyDetail$ReactHooksTemplate = require("../surveys/surveyDetail.bs.js");
var AggregateField$ReactHooksTemplate = require("./AggregateField.bs.js");
var ReportTemplate$ReactHooksTemplate = require("./ReportTemplate.bs.js");
var ResponseReport$ReactHooksTemplate = require("./ResponseReport.bs.js");
var AggregateReport$ReactHooksTemplate = require("./AggregateReport.bs.js");
var ReportListHeader$ReactHooksTemplate = require("./ReportListHeader.bs.js");

function reducer(state, action) {
  if (typeof action === "number") {
    return {
            reports: state.reports,
            selectedReport: state.selectedReport,
            reportChangeModalToggle: !state.reportChangeModalToggle,
            action: state.action,
            outsideClick: state.outsideClick
          };
  }
  switch (action.TAG | 0) {
    case /* LoadReports */0 :
        var reports = action._0;
        if (reports !== undefined) {
          return {
                  reports: reports,
                  selectedReport: state.selectedReport,
                  reportChangeModalToggle: state.reportChangeModalToggle,
                  action: state.action,
                  outsideClick: state.outsideClick
                };
        } else {
          return {
                  reports: undefined,
                  selectedReport: state.selectedReport,
                  reportChangeModalToggle: state.reportChangeModalToggle,
                  action: state.action,
                  outsideClick: state.outsideClick
                };
        }
    case /* SetReport */1 :
        var report = action._0;
        if (report !== undefined) {
          return {
                  reports: state.reports,
                  selectedReport: report,
                  reportChangeModalToggle: state.reportChangeModalToggle,
                  action: state.action,
                  outsideClick: state.outsideClick
                };
        } else {
          return {
                  reports: state.reports,
                  selectedReport: undefined,
                  reportChangeModalToggle: state.reportChangeModalToggle,
                  action: state.action,
                  outsideClick: state.outsideClick
                };
        }
    case /* SetAction */2 :
        return {
                reports: state.reports,
                selectedReport: state.selectedReport,
                reportChangeModalToggle: state.reportChangeModalToggle,
                action: action._0,
                outsideClick: state.outsideClick
              };
    case /* SetReportChangeModal */3 :
        return {
                reports: state.reports,
                selectedReport: state.selectedReport,
                reportChangeModalToggle: action._0,
                action: state.action,
                outsideClick: state.outsideClick
              };
    case /* SetOutsideClick */4 :
        return {
                reports: state.reports,
                selectedReport: state.selectedReport,
                reportChangeModalToggle: state.reportChangeModalToggle,
                action: state.action,
                outsideClick: action._0
              };
    
  }
}

function totalPages(total_records, perPage) {
  return Caml_int32.div(total_records - 1 | 0, perPage) + 1 | 0;
}

var emptyPageInfo = {
  total: "1",
  perPage: "1",
  currentPage: "1"
};

function totalRecord(x) {
  if (x !== undefined) {
    return x;
  } else {
    return "1";
  }
}

function perPage(x) {
  if (x !== undefined) {
    return x;
  } else {
    return "1";
  }
}

var initialState = {
  reports: undefined,
  selectedReport: undefined,
  reportChangeModalToggle: false,
  action: "",
  outsideClick: true
};

function ReportList(Props) {
  var search = Props.search;
  var match = React.useReducer(reducer, initialState);
  var dispatch = match[1];
  var state = match[0];
  var queryParams = Querystringify.parse(search);
  var x = Js_dict.get(queryParams, "page");
  var currentPage;
  if (x !== undefined && x !== "") {
    var page = Belt_Int.fromString(x);
    currentPage = page !== undefined ? page : 1;
  } else {
    currentPage = 1;
  }
  var match$1 = React.useState(function () {
        return emptyPageInfo;
      });
  var setPageInfo = match$1[1];
  var pageInfo = match$1[0];
  var fetchReports = function (param) {
    return Http$ReactHooksTemplate.fetchGetJSONWithHeader("/api/v1/reports.json").then(function (param) {
                var headers = param[0];
                var x = headers.get("Total");
                var total = x !== null ? (
                    x === null ? undefined : Caml_option.some(x)
                  ) : "1";
                var x$1 = headers.get("Per-Page");
                var perPage = x$1 !== null ? (
                    x$1 === null ? undefined : Caml_option.some(x$1)
                  ) : "1";
                var reports = param[1].then(function (json) {
                      return Promise.resolve(Json_decode.field("reports", (function (param) {
                                        return Json_decode.list(Report$ReactHooksTemplate.decode, param);
                                      }), json));
                    });
                return Promise.resolve([
                            reports,
                            [
                              total,
                              perPage
                            ]
                          ]);
              });
  };
  var buildTargetDict = function (report) {
    var params = {};
    var template = Report$ReactHooksTemplate.reportTemplate(report);
    if (template !== undefined) {
      var template$1 = ReportTemplate$ReactHooksTemplate.template(template);
      switch (template$1.TAG | 0) {
        case /* AggregateReport */0 :
            var template$2 = template$1._0;
            var aggregateFieldParams = {};
            aggregateFieldParams["data_field_id"] = DataField$ReactHooksTemplate.id(Belt_Option.getExn(AggregateField$ReactHooksTemplate.dataField(Belt_Option.getExn(AggregateReport$ReactHooksTemplate.aggregateFields(template$2)))));
            aggregateFieldParams["is_timestamp"] = AggregateField$ReactHooksTemplate.isTimestamp(Belt_Option.getExn(AggregateReport$ReactHooksTemplate.aggregateFields(template$2)));
            var value = AggregateField$ReactHooksTemplate.timeFrame(Belt_Option.getExn(AggregateReport$ReactHooksTemplate.aggregateFields(template$2)));
            var timeFrame = value !== undefined ? AggregateField$ReactHooksTemplate.timeFrameToString(value) : "";
            aggregateFieldParams["time_frame"] = timeFrame;
            var aggregateParams = {};
            aggregateParams["metric"] = AggregateReport$ReactHooksTemplate.metricToString(Belt_Option.getExn(AggregateReport$ReactHooksTemplate.metric(template$2)));
            aggregateParams["aggregate_fields_attributes"] = Json_encode.array((function (prim) {
                    return prim;
                  }), [aggregateFieldParams]);
            params[AggregateReport$ReactHooksTemplate.templateType] = aggregateParams;
            break;
        case /* CustomReport */1 :
            break;
        case /* ResponseReport */2 :
            var template$3 = template$1._0;
            var responseParams = {};
            responseParams["survey_id"] = SurveyDetail$ReactHooksTemplate.id(Belt_Option.getExn(ResponseReport$ReactHooksTemplate.survey(template$3)));
            var campaign_ids = Belt_List.toArray(Belt_List.map(ResponseReport$ReactHooksTemplate.campaigns(template$3), Campaign$ReactHooksTemplate.id));
            responseParams["campaign_ids"] = Json_encode.array((function (prim) {
                    return prim;
                  }), campaign_ids);
            params[ResponseReport$ReactHooksTemplate.templateType] = responseParams;
            break;
        
      }
    }
    params["id"] = Json_encode.nullable((function (prim) {
            return prim;
          }), Report$ReactHooksTemplate.id(report));
    params["name"] = Report$ReactHooksTemplate.name(report);
    params["description"] = Report$ReactHooksTemplate.description(report);
    params["time_frame"] = Report$ReactHooksTemplate.timeFrame(report);
    params["template_type"] = ReportTemplate$ReactHooksTemplate.templateType(ReportTemplate$ReactHooksTemplate.template(Belt_Option.getExn(Report$ReactHooksTemplate.reportTemplate(report))));
    var responseParams$1 = {};
    responseParams$1["report"] = params;
    return responseParams$1;
  };
  var handlePageChange = function (pageNumber) {
    var pageNumber$1 = String(pageNumber);
    RescriptReactRouter.push("/users/all?page=" + pageNumber$1);
    Curry._1(setPageInfo, (function (pageInfo) {
            return {
                    total: pageInfo.total,
                    perPage: pageInfo.perPage,
                    currentPage: pageNumber$1
                  };
          }));
    window.scrollTo(0.0, 0.0);
    
  };
  var handleChange = function (report, action) {
    if (report === undefined) {
      return ;
    }
    switch (action) {
      case "create" :
          Http$ReactHooksTemplate.fetchPostJSON("/api/v1/reports", buildTargetDict(report)).then(function (response) {
                var status = Json_decode.field("status", Json_decode.string, response);
                var report = Json_decode.field("report", Report$ReactHooksTemplate.decode, response);
                if (status !== "success") {
                  return Promise.resolve((console.log("error"), undefined));
                }
                var reports = state.reports;
                if (reports !== undefined) {
                  return Promise.resolve((Curry._1(dispatch, {
                                    TAG: /* LoadReports */0,
                                    _0: List.append({
                                          hd: report,
                                          tl: /* [] */0
                                        }, reports)
                                  }), Curry._1(dispatch, {
                                    TAG: /* SetReportChangeModal */3,
                                    _0: false
                                  })));
                } else {
                  return Promise.resolve((Curry._1(dispatch, {
                                    TAG: /* LoadReports */0,
                                    _0: {
                                      hd: report,
                                      tl: /* [] */0
                                    }
                                  }), Curry._1(dispatch, {
                                    TAG: /* SetReportChangeModal */3,
                                    _0: false
                                  })));
                }
              });
          return ;
      case "delete" :
          var reportId = Report$ReactHooksTemplate.id(report);
          Http$ReactHooksTemplate.fetchDeleteJSON("/api/v1/reports/" + reportId).then(function (response) {
                var status = Json_decode.field("status", Json_decode.string, response);
                if (status !== "success") {
                  return Promise.resolve((console.log("error"), undefined));
                }
                var reportId = Report$ReactHooksTemplate.id(report);
                var reports = state.reports;
                if (reports !== undefined) {
                  return Promise.resolve((Curry._1(dispatch, {
                                    TAG: /* LoadReports */0,
                                    _0: Belt_List.keep(reports, (function (x) {
                                            return Caml_obj.caml_notequal(Report$ReactHooksTemplate.id(x), reportId);
                                          }))
                                  }), Curry._1(dispatch, {
                                    TAG: /* SetReportChangeModal */3,
                                    _0: false
                                  })));
                } else {
                  return Promise.resolve(Curry._1(dispatch, {
                                  TAG: /* SetReportChangeModal */3,
                                  _0: false
                                }));
                }
              });
          return ;
      case "edit" :
          var reportId$1 = Report$ReactHooksTemplate.id(report);
          Http$ReactHooksTemplate.fetchPutJSON("/api/v1/reports/" + reportId$1, buildTargetDict(report)).then(function (response) {
                var status = Json_decode.field("status", Json_decode.string, response);
                if (status !== "success") {
                  return Promise.resolve((console.log("error"), undefined));
                }
                var reportId = Report$ReactHooksTemplate.id(report);
                var reports = state.reports;
                if (reports !== undefined) {
                  return Promise.resolve((Curry._1(dispatch, {
                                    TAG: /* LoadReports */0,
                                    _0: List.append({
                                          hd: report,
                                          tl: /* [] */0
                                        }, Belt_List.keep(reports, (function (x) {
                                                return Caml_obj.caml_notequal(Report$ReactHooksTemplate.id(x), reportId);
                                              })))
                                  }), Curry._1(dispatch, {
                                    TAG: /* SetReportChangeModal */3,
                                    _0: false
                                  })));
                } else {
                  return Promise.resolve(Curry._1(dispatch, {
                                  TAG: /* SetReportChangeModal */3,
                                  _0: false
                                }));
                }
              });
          return ;
      default:
        console.log("Undefined Action");
        return ;
    }
  };
  React.useEffect((function () {
          fetchReports(undefined).then(function (param) {
                var paginationInfo = param[1];
                var perPage = paginationInfo[1];
                var total = paginationInfo[0];
                Curry._1(setPageInfo, (function (state) {
                        return {
                                total: total,
                                perPage: perPage,
                                currentPage: state.currentPage
                              };
                      }));
                return Promise.resolve(param[0].then(function (xs) {
                                return Promise.resolve(Curry._1(dispatch, {
                                                TAG: /* LoadReports */0,
                                                _0: xs
                                              }));
                              }));
              });
          
        }), [pageInfo.currentPage]);
  var reports = state.reports;
  return React.createElement("div", undefined, React.createElement("div", {
                  className: "flex items-center border-b border-gray-300 px-30-px py-16-px justify-between"
                }, React.createElement("h2", {
                      className: "text-xl font-semibold"
                    }, "Reports")), React.createElement("div", {
                  className: "flex justify-between px-30-px pt-20-px items-center"
                }, React.createElement("div", {
                      className: "flex items-center"
                    }, React.createElement(Pagination$ReactHooksTemplate.make, {
                          total: totalPages(Caml_format.caml_int_of_string(pageInfo.total), Caml_format.caml_int_of_string(pageInfo.perPage)),
                          currentPage: currentPage,
                          handlePageChange: handlePageChange
                        })), React.createElement("button", {
                      className: "bg-ghostwhite border border-gray-400\n        shadow-inner flex rounded py-2 px-3 text-xs focus:outline-none",
                      id: "add-campaign",
                      onClick: (function (param) {
                          Curry._1(dispatch, {
                                TAG: /* SetAction */2,
                                _0: "create"
                              });
                          Curry._1(dispatch, {
                                TAG: /* SetReport */1,
                                _0: undefined
                              });
                          return Curry._1(dispatch, /* ToggleReportChangeModal */0);
                        })
                    }, React.createElement("img", {
                          className: "h-14-px mr-2-px",
                          src: Image$ReactHooksTemplate.plusCircle
                        }), "New Report")), reports !== undefined ? React.createElement("div", {
                    className: "flex px-30-px pb-30-px"
                  }, React.createElement("table", {
                        className: "text-left table-auto w-full border-separate text-sm font-medium"
                      }, React.createElement(ReportListHeader$ReactHooksTemplate.make, {}), React.createElement("tbody", undefined, $$Array.of_list(List.map((function (report) {
                                      var reportId = Report$ReactHooksTemplate.id(report);
                                      var selectedReport = state.selectedReport;
                                      return React.createElement("tr", {
                                                  className: "border-b border-bottom-gray-600"
                                                }, React.createElement("td", {
                                                      className: "px-4 py-3 capitalize"
                                                    }, React.createElement("a", {
                                                          className: "flex items-center",
                                                          href: "/reports/" + reportId
                                                        }, React.createElement("div", {
                                                              className: "w-8 h-8 flex items-center text-white uppercase mr-3 rounded-lg " + Utils$ReactHooksTemplate.randomBgColor(undefined)
                                                            }, React.createElement("div", {
                                                                  className: "m-auto"
                                                                }, Report$ReactHooksTemplate.name(report)[0])), Report$ReactHooksTemplate.name(report))), React.createElement("td", {
                                                      className: "px-4 py-3"
                                                    }, React.createElement("a", {
                                                          href: "/reports/" + reportId
                                                        }, Report$ReactHooksTemplate.description(report))), React.createElement("td", {
                                                      className: "px-4 py-3"
                                                    }, React.createElement("a", {
                                                          href: "/reports/" + reportId
                                                        }, ReportTemplate$ReactHooksTemplate.templateName(Belt_Option.getExn(Report$ReactHooksTemplate.reportTemplate(report))))), React.createElement("td", {
                                                      className: "px-4 py-3 capitalize"
                                                    }, React.createElement("a", {
                                                          href: "/reports/" + reportId
                                                        }, Utils$ReactHooksTemplate.humanize(Report$ReactHooksTemplate.timeFrame(report)))), React.createElement("td", {
                                                      className: "px-4 py-3"
                                                    }, React.createElement("a", {
                                                          href: "/reports/" + reportId
                                                        }, UserDetail$ReactHooksTemplate.email(Belt_Option.getExn(Report$ReactHooksTemplate.creator(report))))), React.createElement("td", {
                                                      className: "px-4 py-3"
                                                    }, React.createElement("a", {
                                                          href: "/reports/" + reportId
                                                        }, UserDetail$ReactHooksTemplate.email(Belt_Option.getExn(Report$ReactHooksTemplate.modifier(report))))), React.createElement("td", {
                                                      className: "px-4 py-3"
                                                    }, React.createElement("div", {
                                                          className: "flex justify-end items-center relative"
                                                        }, React.createElement("img", {
                                                              className: "h-18-px",
                                                              src: Image$ReactHooksTemplate.verticalDots,
                                                              onClick: (function (param) {
                                                                  Curry._1(dispatch, {
                                                                        TAG: /* SetOutsideClick */4,
                                                                        _0: !state.outsideClick
                                                                      });
                                                                  return Curry._1(dispatch, {
                                                                              TAG: /* SetReport */1,
                                                                              _0: report
                                                                            });
                                                                })
                                                            }), React.createElement("div", {
                                                              className: "flex flex-col items-search p-2 absolute rounded border border-gray-400 bg-white\n                                        text-darkcharcoal text-xs question-dropdown z-50 opacity-100 gap-2 w-20 " + (
                                                                selectedReport !== undefined && state.outsideClick === false && Caml_obj.caml_equal(selectedReport, report) ? "block" : "hidden"
                                                              )
                                                            }, React.createElement("a", {
                                                                  className: "flex text-xs gap-2",
                                                                  onClick: (function (param) {
                                                                      Curry._1(dispatch, {
                                                                            TAG: /* SetOutsideClick */4,
                                                                            _0: true
                                                                          });
                                                                      Curry._1(dispatch, {
                                                                            TAG: /* SetAction */2,
                                                                            _0: "edit"
                                                                          });
                                                                      return Curry._1(dispatch, /* ToggleReportChangeModal */0);
                                                                    })
                                                                }, React.createElement("img", {
                                                                      className: "h-12-px",
                                                                      src: Image$ReactHooksTemplate.edit
                                                                    }), "Edit"), React.createElement("a", {
                                                                  className: "flex text-xs gap-2",
                                                                  onClick: (function (param) {
                                                                      Curry._1(dispatch, {
                                                                            TAG: /* SetOutsideClick */4,
                                                                            _0: true
                                                                          });
                                                                      Curry._1(dispatch, {
                                                                            TAG: /* SetAction */2,
                                                                            _0: "delete"
                                                                          });
                                                                      Curry._1(dispatch, {
                                                                            TAG: /* SetReport */1,
                                                                            _0: report
                                                                          });
                                                                      return Curry._1(dispatch, /* ToggleReportChangeModal */0);
                                                                    })
                                                                }, React.createElement("img", {
                                                                      className: "h-12-px",
                                                                      src: Image$ReactHooksTemplate.trash
                                                                    }), "Delete")))));
                                    }), reports))))) : React.createElement(React.Fragment, undefined, React.createElement("p", undefined, "Fetching users...")), state.reportChangeModalToggle ? React.createElement("div", {
                    className: "w-screen z-10 disabled-wrapper absolute inset-0 flex justify-center items-center",
                    style: {
                      height: "calc(100vh + 5rem)"
                    }
                  }, React.createElement(ReportCrud$ReactHooksTemplate.make, {
                        handleChange: handleChange,
                        report: state.selectedReport,
                        action: state.action,
                        toggleModal: (function (param) {
                            return Curry._1(dispatch, /* ToggleReportChangeModal */0);
                          })
                      })) : React.createElement(React.Fragment, undefined));
}

var make = ReportList;

exports.reducer = reducer;
exports.totalPages = totalPages;
exports.emptyPageInfo = emptyPageInfo;
exports.totalRecord = totalRecord;
exports.perPage = perPage;
exports.initialState = initialState;
exports.make = make;
/* react Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var List = require("rescript/lib/js/list.js");
var $$Array = require("rescript/lib/js/array.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Js_dict = require("rescript/lib/js/js_dict.js");
var Belt_Int = require("rescript/lib/js/belt_Int.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Caml_int32 = require("rescript/lib/js/caml_int32.js");
var Caml_format = require("rescript/lib/js/caml_format.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Querystringify = require("querystringify");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var Kpi$ReactHooksTemplate = require("./kpi.bs.js");
var Http$ReactHooksTemplate = require("../common/http.bs.js");
var Image$ReactHooksTemplate = require("../common/image.bs.js");
var Domain$ReactHooksTemplate = require("./domain.bs.js");
var KpiCrud$ReactHooksTemplate = require("./KpiCrud.bs.js");
var Pagination$ReactHooksTemplate = require("../common/Pagination.bs.js");
var UserKpiListHeader$ReactHooksTemplate = require("./UserKpiListHeader.bs.js");

function reducer(state, action) {
  if (typeof action === "number") {
    return {
            kpis: state.kpis,
            selectedKpi: state.selectedKpi,
            kpiChangeModalToggle: !state.kpiChangeModalToggle,
            action: state.action,
            outsideClick: state.outsideClick
          };
  }
  switch (action.TAG | 0) {
    case /* LoadKpis */0 :
        var kpis = action._0;
        if (kpis !== undefined) {
          return {
                  kpis: kpis,
                  selectedKpi: state.selectedKpi,
                  kpiChangeModalToggle: state.kpiChangeModalToggle,
                  action: state.action,
                  outsideClick: state.outsideClick
                };
        } else {
          return {
                  kpis: undefined,
                  selectedKpi: state.selectedKpi,
                  kpiChangeModalToggle: state.kpiChangeModalToggle,
                  action: state.action,
                  outsideClick: state.outsideClick
                };
        }
    case /* SetKpi */1 :
        var kpi = action._0;
        if (kpi !== undefined) {
          return {
                  kpis: state.kpis,
                  selectedKpi: kpi,
                  kpiChangeModalToggle: state.kpiChangeModalToggle,
                  action: state.action,
                  outsideClick: state.outsideClick
                };
        } else {
          return {
                  kpis: state.kpis,
                  selectedKpi: undefined,
                  kpiChangeModalToggle: state.kpiChangeModalToggle,
                  action: state.action,
                  outsideClick: state.outsideClick
                };
        }
    case /* SetAction */2 :
        return {
                kpis: state.kpis,
                selectedKpi: state.selectedKpi,
                kpiChangeModalToggle: state.kpiChangeModalToggle,
                action: action._0,
                outsideClick: state.outsideClick
              };
    case /* SetKpiChangeModal */3 :
        return {
                kpis: state.kpis,
                selectedKpi: state.selectedKpi,
                kpiChangeModalToggle: action._0,
                action: state.action,
                outsideClick: state.outsideClick
              };
    case /* SetOutsideClick */4 :
        return {
                kpis: state.kpis,
                selectedKpi: state.selectedKpi,
                kpiChangeModalToggle: state.kpiChangeModalToggle,
                action: state.action,
                outsideClick: action._0
              };
    
  }
}

function totalPages(total_records, perPage) {
  return Caml_int32.div(total_records - 1 | 0, perPage) + 1 | 0;
}

var emptyPageInfo = {
  total: "1",
  perPage: "1",
  currentPage: "1"
};

function totalRecord(x) {
  if (x !== undefined) {
    return x;
  } else {
    return "1";
  }
}

function perPage(x) {
  if (x !== undefined) {
    return x;
  } else {
    return "1";
  }
}

var initialState = {
  kpis: undefined,
  selectedKpi: undefined,
  kpiChangeModalToggle: false,
  action: "",
  outsideClick: true
};

function UserKpiManagement(Props) {
  var search = Props.search;
  var match = React.useReducer(reducer, initialState);
  var dispatch = match[1];
  var state = match[0];
  var queryParams = Querystringify.parse(search);
  var x = Js_dict.get(queryParams, "page");
  var currentPage;
  if (x !== undefined && x !== "") {
    var page = Belt_Int.fromString(x);
    currentPage = page !== undefined ? page : 1;
  } else {
    currentPage = 1;
  }
  var match$1 = React.useState(function () {
        return emptyPageInfo;
      });
  var setPageInfo = match$1[1];
  var pageInfo = match$1[0];
  var fetchUsers = function (param) {
    var pageNumber = pageInfo.currentPage;
    return Http$ReactHooksTemplate.fetchGetJSONWithHeader("/api/v1/kpis?page=" + pageNumber).then(function (param) {
                var headers = param[0];
                var x = headers.get("Total");
                var total = x !== null ? (
                    x === null ? undefined : Caml_option.some(x)
                  ) : "1";
                var x$1 = headers.get("Per-Page");
                var perPage = x$1 !== null ? (
                    x$1 === null ? undefined : Caml_option.some(x$1)
                  ) : "1";
                var kpis = param[1].then(function (json) {
                      return Promise.resolve(Json_decode.field("kpis", (function (param) {
                                        return Json_decode.list(Kpi$ReactHooksTemplate.decode, param);
                                      }), json));
                    });
                return Promise.resolve([
                            kpis,
                            [
                              total,
                              perPage
                            ]
                          ]);
              });
  };
  var buildSurveyFormDict = function (kpi) {
    var params = {};
    params["user_id"] = Curry._1(Domain$ReactHooksTemplate.$$String.Email.show, Kpi$ReactHooksTemplate.email(kpi));
    params["field"] = Kpi$ReactHooksTemplate.filterField(kpi);
    params["field_id"] = Kpi$ReactHooksTemplate.filterFieldId(kpi);
    params["value"] = Kpi$ReactHooksTemplate.filterValue(kpi);
    params["kpi"] = Kpi$ReactHooksTemplate.kpiValue(kpi);
    return params;
  };
  var buildTargetDict = function (kpi) {
    var params = {};
    params["field"] = Kpi$ReactHooksTemplate.filterField(kpi);
    params["field_id"] = Kpi$ReactHooksTemplate.filterFieldId(kpi);
    params["value"] = Kpi$ReactHooksTemplate.filterValue(kpi);
    params["kpi"] = Kpi$ReactHooksTemplate.kpiValue(kpi);
    return params;
  };
  var handlePageChange = function (pageNumber) {
    var pageNumber$1 = String(pageNumber);
    RescriptReactRouter.push("/users/all?page=" + pageNumber$1);
    Curry._1(setPageInfo, (function (pageInfo) {
            return {
                    total: pageInfo.total,
                    perPage: pageInfo.perPage,
                    currentPage: pageNumber$1
                  };
          }));
    window.scrollTo(0.0, 0.0);
    
  };
  var handleChange = function (kpi, action) {
    if (kpi === undefined) {
      return ;
    }
    switch (action) {
      case "create" :
          Http$ReactHooksTemplate.fetchPostJSON("/api/v1/kpis", buildSurveyFormDict(kpi)).then(function (response) {
                var status = Json_decode.field("status", Json_decode.string, response);
                var kpi = Json_decode.field("kpi", Kpi$ReactHooksTemplate.decode, response);
                if (status !== "success") {
                  return Promise.resolve((console.log("error"), undefined));
                }
                var kpis = state.kpis;
                if (kpis !== undefined) {
                  return Promise.resolve((Curry._1(dispatch, {
                                    TAG: /* LoadKpis */0,
                                    _0: List.append({
                                          hd: kpi,
                                          tl: /* [] */0
                                        }, kpis)
                                  }), Curry._1(dispatch, {
                                    TAG: /* SetKpiChangeModal */3,
                                    _0: false
                                  })));
                } else {
                  return Promise.resolve((Curry._1(dispatch, {
                                    TAG: /* LoadKpis */0,
                                    _0: {
                                      hd: kpi,
                                      tl: /* [] */0
                                    }
                                  }), Curry._1(dispatch, {
                                    TAG: /* SetKpiChangeModal */3,
                                    _0: false
                                  })));
                }
              });
          return ;
      case "delete" :
          var kpiId = Kpi$ReactHooksTemplate.id(kpi);
          Http$ReactHooksTemplate.fetchDeleteJSON("/api/v1/kpis/" + kpiId).then(function (response) {
                var status = Json_decode.field("status", Json_decode.string, response);
                if (status !== "success") {
                  return Promise.resolve((console.log("error"), undefined));
                }
                var kpiId = Kpi$ReactHooksTemplate.id(kpi);
                var kpis = state.kpis;
                if (kpis !== undefined) {
                  return Promise.resolve((Curry._1(dispatch, {
                                    TAG: /* LoadKpis */0,
                                    _0: Belt_List.keep(kpis, (function (x) {
                                            return Caml_obj.caml_notequal(Kpi$ReactHooksTemplate.id(x), kpiId);
                                          }))
                                  }), Curry._1(dispatch, {
                                    TAG: /* SetKpiChangeModal */3,
                                    _0: false
                                  })));
                } else {
                  return Promise.resolve(Curry._1(dispatch, {
                                  TAG: /* SetKpiChangeModal */3,
                                  _0: false
                                }));
                }
              });
          return ;
      case "edit" :
          var kpiId$1 = Kpi$ReactHooksTemplate.id(kpi);
          Http$ReactHooksTemplate.fetchPutJSON("/api/v1/kpis/" + kpiId$1, buildTargetDict(kpi)).then(function (response) {
                var status = Json_decode.field("status", Json_decode.string, response);
                if (status !== "success") {
                  return Promise.resolve((console.log("error"), undefined));
                }
                var kpiId = Kpi$ReactHooksTemplate.id(kpi);
                var kpis = state.kpis;
                if (kpis !== undefined) {
                  return Promise.resolve((Curry._1(dispatch, {
                                    TAG: /* LoadKpis */0,
                                    _0: List.append({
                                          hd: kpi,
                                          tl: /* [] */0
                                        }, Belt_List.keep(kpis, (function (x) {
                                                return Caml_obj.caml_notequal(Kpi$ReactHooksTemplate.id(x), kpiId);
                                              })))
                                  }), Curry._1(dispatch, {
                                    TAG: /* SetKpiChangeModal */3,
                                    _0: false
                                  })));
                } else {
                  return Promise.resolve(Curry._1(dispatch, {
                                  TAG: /* SetKpiChangeModal */3,
                                  _0: false
                                }));
                }
              });
          return ;
      default:
        console.log("Undefined Action");
        return ;
    }
  };
  React.useEffect((function () {
          fetchUsers(undefined).then(function (param) {
                var paginationInfo = param[1];
                var perPage = paginationInfo[1];
                var total = paginationInfo[0];
                Curry._1(setPageInfo, (function (state) {
                        return {
                                total: total,
                                perPage: perPage,
                                currentPage: state.currentPage
                              };
                      }));
                return Promise.resolve(param[0].then(function (xs) {
                                return Promise.resolve(Curry._1(dispatch, {
                                                TAG: /* LoadKpis */0,
                                                _0: xs
                                              }));
                              }));
              });
          
        }), [pageInfo.currentPage]);
  var kpis = state.kpis;
  return React.createElement("div", undefined, React.createElement("div", {
                  className: "flex items-center border-b border-gray-300 px-30-px py-16-px justify-between"
                }, React.createElement("h2", {
                      className: "text-xl font-semibold"
                    }, "KPI Management")), React.createElement("div", {
                  className: "flex justify-between px-30-px pt-20-px items-center"
                }, React.createElement("div", {
                      className: "flex items-center"
                    }, React.createElement(Pagination$ReactHooksTemplate.make, {
                          total: totalPages(Caml_format.caml_int_of_string(pageInfo.total), Caml_format.caml_int_of_string(pageInfo.perPage)),
                          currentPage: currentPage,
                          handlePageChange: handlePageChange
                        })), React.createElement("button", {
                      className: "bg-ghostwhite border border-gray-400\n        shadow-inner flex rounded py-2 px-3 text-xs focus:outline-none",
                      id: "add-campaign",
                      onClick: (function (param) {
                          Curry._1(dispatch, {
                                TAG: /* SetAction */2,
                                _0: "create"
                              });
                          Curry._1(dispatch, {
                                TAG: /* SetKpi */1,
                                _0: undefined
                              });
                          return Curry._1(dispatch, /* ToggleKpiChangeModal */0);
                        })
                    }, React.createElement("img", {
                          className: "h-14-px mr-2-px",
                          src: Image$ReactHooksTemplate.plusCircle
                        }), "New KPI")), kpis !== undefined ? React.createElement("div", {
                    className: "flex px-30-px pb-30-px"
                  }, React.createElement("table", {
                        className: "text-left table-auto w-full border-separate text-sm font-medium"
                      }, React.createElement(UserKpiListHeader$ReactHooksTemplate.make, {}), React.createElement("tbody", undefined, $$Array.of_list(List.map((function (kpi) {
                                      var selectedKpi = state.selectedKpi;
                                      return React.createElement("tr", {
                                                  className: "border-b border-bottom-gray-600"
                                                }, React.createElement("td", {
                                                      className: "px-4 py-3"
                                                    }, Curry._1(Domain$ReactHooksTemplate.$$String.Email.show, Kpi$ReactHooksTemplate.email(kpi))), React.createElement("td", {
                                                      className: "px-4 py-3"
                                                    }, Kpi$ReactHooksTemplate.filterField(kpi)), React.createElement("td", {
                                                      className: "px-4 py-3"
                                                    }, Kpi$ReactHooksTemplate.filterValue(kpi)), React.createElement("td", {
                                                      className: "px-4 py-3"
                                                    }, String(Kpi$ReactHooksTemplate.kpiValue(kpi))), React.createElement("td", {
                                                      className: "px-4 py-3"
                                                    }, React.createElement("div", {
                                                          className: "flex justify-end items-center relative"
                                                        }, React.createElement("img", {
                                                              className: "h-18-px",
                                                              src: Image$ReactHooksTemplate.verticalDots,
                                                              onClick: (function (param) {
                                                                  Curry._1(dispatch, {
                                                                        TAG: /* SetOutsideClick */4,
                                                                        _0: !state.outsideClick
                                                                      });
                                                                  return Curry._1(dispatch, {
                                                                              TAG: /* SetKpi */1,
                                                                              _0: kpi
                                                                            });
                                                                })
                                                            }), React.createElement("div", {
                                                              className: "flex flex-col items-search p-2 absolute rounded border border-gray-400 bg-white\n                                        text-darkcharcoal text-xs question-dropdown z-50 opacity-100 gap-2 w-20 " + (
                                                                selectedKpi !== undefined && state.outsideClick === false && Caml_obj.caml_equal(selectedKpi, kpi) ? "block" : "hidden"
                                                              )
                                                            }, React.createElement("a", {
                                                                  className: "flex text-xs gap-2",
                                                                  onClick: (function (param) {
                                                                      Curry._1(dispatch, {
                                                                            TAG: /* SetOutsideClick */4,
                                                                            _0: true
                                                                          });
                                                                      Curry._1(dispatch, {
                                                                            TAG: /* SetAction */2,
                                                                            _0: "edit"
                                                                          });
                                                                      return Curry._1(dispatch, /* ToggleKpiChangeModal */0);
                                                                    })
                                                                }, React.createElement("img", {
                                                                      className: "h-12-px",
                                                                      src: Image$ReactHooksTemplate.edit
                                                                    }), "Edit"), React.createElement("a", {
                                                                  className: "flex text-xs gap-2",
                                                                  href: "#",
                                                                  onClick: (function (param) {
                                                                      Curry._1(dispatch, {
                                                                            TAG: /* SetOutsideClick */4,
                                                                            _0: true
                                                                          });
                                                                      Curry._1(dispatch, {
                                                                            TAG: /* SetAction */2,
                                                                            _0: "delete"
                                                                          });
                                                                      Curry._1(dispatch, {
                                                                            TAG: /* SetKpi */1,
                                                                            _0: kpi
                                                                          });
                                                                      return Curry._1(dispatch, /* ToggleKpiChangeModal */0);
                                                                    })
                                                                }, React.createElement("img", {
                                                                      className: "h-12-px",
                                                                      src: Image$ReactHooksTemplate.trash
                                                                    }), "Delete")))));
                                    }), kpis))))) : React.createElement(React.Fragment, undefined, React.createElement("p", undefined, "Fetching users...")), state.kpiChangeModalToggle ? React.createElement("div", {
                    className: "w-screen z-10 disabled-wrapper absolute inset-0 flex justify-center items-center",
                    style: {
                      height: "calc(100vh + 5rem)"
                    }
                  }, React.createElement(KpiCrud$ReactHooksTemplate.make, {
                        handleChange: handleChange,
                        kpi: state.selectedKpi,
                        action: state.action,
                        toggleModal: (function (param) {
                            return Curry._1(dispatch, /* ToggleKpiChangeModal */0);
                          })
                      })) : React.createElement(React.Fragment, undefined));
}

var make = UserKpiManagement;

exports.reducer = reducer;
exports.totalPages = totalPages;
exports.emptyPageInfo = emptyPageInfo;
exports.totalRecord = totalRecord;
exports.perPage = perPage;
exports.initialState = initialState;
exports.make = make;
/* react Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var List = require("rescript/lib/js/list.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");
var RandomId$ReactHooksTemplate = require("./RandomId.bs.js");

function decode(json) {
  return {
          text: Json_decode.field("text", Json_decode.string, json),
          description: Json_decode.field("description", Json_decode.string, json),
          placeholder: Json_decode.field("placeholder", Json_decode.string, json)
        };
}

function empty(param) {
  return {
          id: undefined,
          uuid: RandomId$ReactHooksTemplate.generate(undefined),
          text: "",
          description: "",
          placeholder: "Type here...",
          representation: /* TropicalBreezeBackground */1,
          translations: /* [] */0,
          answer: undefined
        };
}

function make(text, description, representation, placeholder, uuid) {
  return {
          id: undefined,
          uuid: uuid,
          text: text,
          description: description,
          placeholder: placeholder,
          representation: representation,
          translations: /* [] */0,
          answer: undefined
        };
}

function id(t) {
  return t.id;
}

function uuid(t) {
  return t.uuid;
}

function text(t) {
  return t.text;
}

function placeholder(t) {
  return t.placeholder;
}

function getTextTranslation(t, language) {
  var translation = List.find_opt((function (param) {
          return param[0] === language;
        }), t.translations);
  if (translation !== undefined) {
    return translation[1].text;
  } else {
    return t.text;
  }
}

function getPlaceholderTranslation(t, language) {
  var translation = List.find_opt((function (param) {
          return param[0] === language;
        }), t.translations);
  if (translation !== undefined) {
    return translation[1].placeholder;
  } else {
    return t.placeholder;
  }
}

function getDescriptionTranslation(t, language) {
  var translation = List.find_opt((function (param) {
          return param[0] === language;
        }), t.translations);
  if (translation !== undefined) {
    return translation[1].description;
  } else {
    return t.description;
  }
}

function description(t) {
  return t.description;
}

function representation(t) {
  return t.representation;
}

function answer(t) {
  return t.answer;
}

function answered(t) {
  return t.answer !== undefined;
}

function answerToString(t) {
  var answer = t.answer;
  if (answer !== undefined) {
    return answer;
  } else {
    return "";
  }
}

function setText(t, text) {
  return {
          id: t.id,
          uuid: t.uuid,
          text: text,
          description: t.description,
          placeholder: t.placeholder,
          representation: t.representation,
          translations: t.translations,
          answer: t.answer
        };
}

function setDescription(t, description) {
  return {
          id: t.id,
          uuid: t.uuid,
          text: t.text,
          description: description,
          placeholder: t.placeholder,
          representation: t.representation,
          translations: t.translations,
          answer: t.answer
        };
}

function setPlaceholder(t, placeholder) {
  return {
          id: t.id,
          uuid: t.uuid,
          text: t.text,
          description: t.description,
          placeholder: placeholder,
          representation: t.representation,
          translations: t.translations,
          answer: t.answer
        };
}

function setAnswer(t, answer) {
  return {
          id: t.id,
          uuid: t.uuid,
          text: t.text,
          description: t.description,
          placeholder: t.placeholder,
          representation: t.representation,
          translations: t.translations,
          answer: answer
        };
}

function setRepresentation(t, representation) {
  return {
          id: t.id,
          uuid: t.uuid,
          text: t.text,
          description: t.description,
          placeholder: t.placeholder,
          representation: representation,
          translations: t.translations,
          answer: t.answer
        };
}

function toJson(withAnswerOpt, t) {
  var withAnswer = withAnswerOpt !== undefined ? withAnswerOpt : false;
  var encodeArray_0 = [
    "id",
    Json_encode.nullable((function (prim) {
            return prim;
          }), t.id)
  ];
  var encodeArray_1 = {
    hd: [
      "text",
      t.text
    ],
    tl: {
      hd: [
        "description",
        t.description
      ],
      tl: {
        hd: [
          "placeholder",
          t.placeholder
        ],
        tl: /* [] */0
      }
    }
  };
  var encodeArray = {
    hd: encodeArray_0,
    tl: encodeArray_1
  };
  return Json_encode.object_(withAnswer ? ({
                  hd: [
                    "answer",
                    Belt_Option.getWithDefault(t.answer, "")
                  ],
                  tl: encodeArray
                }) : encodeArray);
}

function decodeTranslation(json) {
  return [
          Json_decode.field("survey_language", (function (param) {
                  return Json_decode.field("name", Json_decode.string, param);
                }), json),
          Json_decode.field("key_string_mapping", decode, json)
        ];
}

function fromJson(json) {
  return {
          id: Json_decode.field("category", (function (param) {
                  return Json_decode.field("id", (function (param) {
                                return Json_decode.optional(Json_decode.$$int, param);
                              }), param);
                }), json),
          uuid: Json_decode.field("uid", RandomId$ReactHooksTemplate.fromJson, json),
          text: Json_decode.field("category", (function (param) {
                  return Json_decode.field("text", Json_decode.string, param);
                }), json),
          description: Json_decode.field("category", (function (param) {
                  return Json_decode.field("description", Json_decode.string, param);
                }), json),
          placeholder: Json_decode.field("category", (function (param) {
                  return Json_decode.field("placeholder", Json_decode.string, param);
                }), json),
          representation: /* TropicalBreezeBackground */1,
          translations: Json_decode.field("translations", (function (param) {
                  return Json_decode.list(decodeTranslation, param);
                }), json),
          answer: undefined
        };
}

var categoryType = "LongText";

var maximumLength = 3500;

exports.categoryType = categoryType;
exports.empty = empty;
exports.description = description;
exports.placeholder = placeholder;
exports.text = text;
exports.getTextTranslation = getTextTranslation;
exports.getPlaceholderTranslation = getPlaceholderTranslation;
exports.getDescriptionTranslation = getDescriptionTranslation;
exports.answer = answer;
exports.representation = representation;
exports.make = make;
exports.setText = setText;
exports.setDescription = setDescription;
exports.setPlaceholder = setPlaceholder;
exports.setAnswer = setAnswer;
exports.setRepresentation = setRepresentation;
exports.answerToString = answerToString;
exports.answered = answered;
exports.toJson = toJson;
exports.fromJson = fromJson;
exports.id = id;
exports.uuid = uuid;
exports.maximumLength = maximumLength;
/* RandomId-ReactHooksTemplate Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");

function Modal(Props) {
  var show = Props.show;
  var children = Props.children;
  var handleClose = Props.handleClose;
  var modalCss = "fixed top-0 left-0 w-full h-screen bg-white z-50";
  var showHideClassName = show ? "" + modalCss + " block" : "" + modalCss + " hidden";
  return React.createElement("div", {
              className: showHideClassName
            }, React.createElement("div", {
                  className: "flex justify-end bg-secondary px-20-px py-10-px z-50 text-gray-400 text-sm"
                }, React.createElement("button", {
                      type: "button",
                      onClick: handleClose
                    }, "close")), React.createElement("section", {
                  className: "fixed top-50 left-50 modal-main h-full bg-white overflow-y-scroll mt-8"
                }, children));
}

var make = Modal;

exports.make = make;
/* react Not a pure module */

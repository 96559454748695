// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");

function make(top, right, bottom, left) {
  return {
          top: top,
          right: right,
          bottom: bottom,
          left: left
        };
}

var Margin = {
  make: make
};

function make$1(left, right) {
  return {
          left: left,
          right: right
        };
}

var Padding = {
  make: make$1
};

function make$2(fontSize, angle, position) {
  return function (param, param$1, param$2) {
    return {
            fontSize: fontSize,
            angle: angle,
            position: position,
            textAnchor: param,
            width: param$1,
            scaleToFit: param$2
          };
  };
}

var Tick = {
  make: make$2
};

function make$3(fill) {
  return {
          fill: fill
        };
}

var Cursor = {
  make: make$3
};

var LineChart = {};

var AreaChart = {};

var BarChart = {};

var Line = {};

var Area = {};

var CartesianGrid = {};

var ReferenceLine = {};

function encode(x) {
  if (typeof x !== "number") {
    return x._0;
  }
  switch (x) {
    case /* PreserveStart */0 :
        return "preserveStart";
    case /* PreserveEnd */1 :
        return "preserveEnd";
    case /* PreserveStartEnd */2 :
        return "preserveStartEnd";
    
  }
}

function encodeOpt(__x) {
  return Belt_Option.map(__x, encode);
}

var AxisInterval = {
  encode: encode,
  encodeOpt: encodeOpt
};

function makeProps(interval) {
  var arg = Belt_Option.map(interval, encode);
  return function (param, param$1, param$2, param$3, param$4) {
    return function (param$5, param$6, param$7, param$8, param$9, param$10, param$11) {
      var tmp = {};
      if (param !== undefined) {
        tmp.minTickGap = param;
      }
      if (param$1 !== undefined) {
        tmp.dataKey = param$1;
      }
      if (param$2 !== undefined) {
        tmp.tickLine = param$2;
      }
      if (param$3 !== undefined) {
        tmp.axisLine = param$3;
      }
      if (param$4 !== undefined) {
        tmp.tick = Caml_option.valFromOption(param$4);
      }
      if (arg !== undefined) {
        tmp.interval = Caml_option.valFromOption(arg);
      }
      if (param$5 !== undefined) {
        tmp.padding = Caml_option.valFromOption(param$5);
      }
      if (param$6 !== undefined) {
        tmp.height = param$6;
      }
      if (param$7 !== undefined) {
        tmp.tickMargin = param$7;
      }
      if (param$8 !== undefined) {
        tmp.tickFormatter = Caml_option.valFromOption(param$8);
      }
      if (param$9 !== undefined) {
        tmp.type = Caml_option.valFromOption(param$9);
      }
      if (param$10 !== undefined) {
        tmp.key = param$10;
      }
      return tmp;
    };
  };
}

var XAxis = {
  makeProps: makeProps
};

function makeProps$1(interval) {
  var arg = Belt_Option.map(interval, encode);
  return function (param, param$1, param$2, param$3, param$4, param$5) {
    return function (param$6, param$7, param$8, param$9, param$10, param$11, param$12, param$13, param$14) {
      var tmp = {};
      if (param !== undefined) {
        tmp.minTickGap = param;
      }
      if (param$1 !== undefined) {
        tmp.dataKey = param$1;
      }
      if (param$2 !== undefined) {
        tmp.yAxisId = param$2;
      }
      if (param$3 !== undefined) {
        tmp.width = param$3;
      }
      if (param$4 !== undefined) {
        tmp.height = param$4;
      }
      if (param$5 !== undefined) {
        tmp.tick = Caml_option.valFromOption(param$5);
      }
      if (arg !== undefined) {
        tmp.interval = Caml_option.valFromOption(arg);
      }
      if (param$6 !== undefined) {
        tmp.tickFormatter = Caml_option.valFromOption(param$6);
      }
      if (param$7 !== undefined) {
        tmp.orientation = Caml_option.valFromOption(param$7);
      }
      if (param$8 !== undefined) {
        tmp.domain = Caml_option.valFromOption(param$8);
      }
      if (param$9 !== undefined) {
        tmp.type = Caml_option.valFromOption(param$9);
      }
      if (param$10 !== undefined) {
        tmp.tickLine = param$10;
      }
      if (param$11 !== undefined) {
        tmp.axisLine = param$11;
      }
      if (param$12 !== undefined) {
        tmp.tickMargin = param$12;
      }
      if (param$13 !== undefined) {
        tmp.key = param$13;
      }
      return tmp;
    };
  };
}

var YAxis = {
  makeProps: makeProps$1
};

var Tooltip = {};

var Legend = {};

var ComposedChart = {};

var Bar = {};

var Scatter = {};

var LabelList = {};

var ResponsiveContainer = {};

var Brush = {};

var PieChart = {};

var Pie = {};

var Cell = {};

exports.Margin = Margin;
exports.Padding = Padding;
exports.Tick = Tick;
exports.Cursor = Cursor;
exports.LineChart = LineChart;
exports.AreaChart = AreaChart;
exports.BarChart = BarChart;
exports.Line = Line;
exports.Area = Area;
exports.CartesianGrid = CartesianGrid;
exports.ReferenceLine = ReferenceLine;
exports.AxisInterval = AxisInterval;
exports.XAxis = XAxis;
exports.YAxis = YAxis;
exports.Tooltip = Tooltip;
exports.Legend = Legend;
exports.ComposedChart = ComposedChart;
exports.Bar = Bar;
exports.Scatter = Scatter;
exports.LabelList = LabelList;
exports.ResponsiveContainer = ResponsiveContainer;
exports.Brush = Brush;
exports.PieChart = PieChart;
exports.Pie = Pie;
exports.Cell = Cell;
/* No side effect */

// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");

function id(t) {
  return t.id;
}

function name(t) {
  return t.name;
}

function html_template(t) {
  return t.html_template;
}

function decode(json) {
  return {
          id: Json_decode.field("id", Json_decode.$$int, json),
          name: Json_decode.field("name", Json_decode.string, json),
          html_template: Json_decode.field("html_safe_template", Json_decode.string, json)
        };
}

exports.id = id;
exports.name = name;
exports.html_template = html_template;
exports.decode = decode;
/* No side effect */

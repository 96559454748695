// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");

function background(t) {
  return t.title.background;
}

function titleTextColor(t) {
  return t.title.text_color;
}

function boxBackground(t) {
  return t.title.box_bg;
}

function innerContainerBackground(t) {
  return t.title.inner_container_bg;
}

function borderColor(t) {
  return t.title.border_color;
}

function fromJson(json) {
  return {
          title: Json_decode.field("title", (function (x) {
                  return {
                          background: Json_decode.field("background", Json_decode.string, x),
                          text_color: Json_decode.field("text_color", Json_decode.string, x),
                          box_bg: Json_decode.field("box_bg", Json_decode.string, x),
                          inner_container_bg: Json_decode.field("inner_container_bg", Json_decode.string, x),
                          border_color: Json_decode.field("border_color", Json_decode.string, x)
                        };
                }), json)
        };
}

exports.background = background;
exports.titleTextColor = titleTextColor;
exports.boxBackground = boxBackground;
exports.innerContainerBackground = innerContainerBackground;
exports.borderColor = borderColor;
exports.fromJson = fromJson;
/* No side effect */

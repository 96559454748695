// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Image$ReactHooksTemplate = require("../common/image.bs.js");
var Domain$ReactHooksTemplate = require("../settings/domain.bs.js");
var Ticket$ReactHooksTemplate = require("./ticket.bs.js");

function TicketListItem(Props) {
  var ticket = Props.ticket;
  var ticketPriority = function (priority) {
    switch (priority) {
      case "High" :
          return "bg-orange-200 text-orange-500";
      case "Low" :
          return "bg-green-100 text-green-600";
      case "Medium" :
          return "bg-yellow-200 text-yellow-500";
      case "red_alert" :
          return "bg-lavenderblush text-firebrick";
      default:
        return "bg-white text-blackcoral";
    }
  };
  var ticketPriorityString = function (priority) {
    if (priority === "red_alert") {
      return "Red Alert";
    } else {
      return priority;
    }
  };
  var match = Ticket$ReactHooksTemplate.assignee(ticket);
  return React.createElement("div", {
              className: "w-full p-4"
            }, React.createElement("div", {
                  className: "flex justify-between pb-3 border-b border-gray-400 items-center"
                }, React.createElement("div", {
                      className: "flex font-medium text-sm"
                    }, "#", String(Ticket$ReactHooksTemplate.id(ticket))), React.createElement("div", {
                      className: "flex justify-center items-center font-normal border border-gray-300 rounded-2xl\n        py-1 px-3 uppercase text-10-px " + ticketPriority(Ticket$ReactHooksTemplate.Priority.name(Ticket$ReactHooksTemplate.priority(ticket)))
                    }, ticketPriorityString(Ticket$ReactHooksTemplate.Priority.name(Ticket$ReactHooksTemplate.priority(ticket))))), React.createElement("div", {
                  className: "flex text-10-p mt-6 items-center"
                }, React.createElement("img", {
                      className: "mr-1 mt-1",
                      src: Image$ReactHooksTemplate.curvedArrow
                    }), React.createElement("div", {
                      className: "mt-1 " + (
                        match !== undefined ? "" : "text-spanishgray"
                      )
                    }, Belt_Option.mapWithDefault(Ticket$ReactHooksTemplate.assignee(ticket), "Not assigned", (function (x) {
                            return Curry._1(Domain$ReactHooksTemplate.$$String.Email.show, Ticket$ReactHooksTemplate.Assignee.email(x));
                          })))), React.createElement("div", {
                  className: "flex justify-between items-center mt-4"
                }, React.createElement("div", {
                      className: "text-white bg-aurometalsaurus py-1 px-2 uppercase rounded text-10-px font-normal"
                    }, Ticket$ReactHooksTemplate.Status.toString(Ticket$ReactHooksTemplate.status(ticket))), React.createElement("div", {
                      className: "flex text-10-px"
                    }, React.createElement("img", {
                          className: "h-12-px mr-1",
                          src: Image$ReactHooksTemplate.clockCircle
                        }), React.createElement("div", undefined, Ticket$ReactHooksTemplate.createdAt(ticket).format("lll")))));
}

var make = TicketListItem;

exports.make = make;
/* react Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var List = require("rescript/lib/js/list.js");
var Js_exn = require("rescript/lib/js/js_exn.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");
var Question$ReactHooksTemplate = require("./Question.bs.js");
var ThankYou$ReactHooksTemplate = require("./ThankYou.bs.js");

function id(t) {
  return t.id;
}

function actionType(t) {
  return t.actionType;
}

function setActionType(t, actionType) {
  return {
          id: t.id,
          actionType: actionType
        };
}

function emptyAction(target) {
  return {
          id: undefined,
          actionType: {
            TAG: /* Jump */1,
            _0: target
          }
        };
}

function emptySkipAction(target) {
  return {
          id: undefined,
          actionType: {
            TAG: /* Skip */0,
            _0: target
          }
        };
}

function decodeJumpTarget(json, questions, thankYouList) {
  var targetType = Json_decode.field("target_type", Json_decode.string, json);
  var targetId = List.hd(Json_decode.field("target_ids", (function (param) {
              return Json_decode.list(Json_decode.$$int, param);
            }), json));
  switch (targetType) {
    case "Question" :
        return {
                TAG: /* Question */0,
                _0: List.find((function (x) {
                        return Belt_Option.getExn(Question$ReactHooksTemplate.id(x)) === targetId;
                      }), questions)
              };
    case "ThankYou" :
        return {
                TAG: /* ThankYou */1,
                _0: List.find((function (x) {
                        return Belt_Option.getExn(ThankYou$ReactHooksTemplate.id(x)) === targetId;
                      }), thankYouList)
              };
    default:
      return Js_exn.raiseError("Unknown Jump type");
  }
}

function decodeSkipTarget(json, questions, thankYouList) {
  var targetType = Json_decode.field("target_type", Json_decode.string, json);
  var targetIds = Json_decode.field("target_ids", (function (param) {
          return Json_decode.list(Json_decode.$$int, param);
        }), json);
  switch (targetType) {
    case "Question" :
        var skipQuestions = List.map((function (x) {
                return List.find((function (y) {
                              return Belt_Option.getExn(Question$ReactHooksTemplate.id(y)) === x;
                            }), questions);
              }), targetIds);
        return {
                TAG: /* Questions */0,
                _0: skipQuestions
              };
    case "ThankYou" :
        var skipThankYous = List.map((function (x) {
                return List.find((function (y) {
                              return Belt_Option.getExn(ThankYou$ReactHooksTemplate.id(y)) === x;
                            }), thankYouList);
              }), targetIds);
        return {
                TAG: /* ThankYous */1,
                _0: skipThankYous
              };
    default:
      return Js_exn.raiseError("Unknown Skip type");
  }
}

function decodeActionType(json, questions, thankYouList) {
  var actionType = Json_decode.field("action_type", Json_decode.string, json);
  switch (actionType) {
    case "jump" :
        return {
                TAG: /* Jump */1,
                _0: decodeJumpTarget(json, questions, thankYouList)
              };
    case "skip" :
        return {
                TAG: /* Skip */0,
                _0: decodeSkipTarget(json, questions, thankYouList)
              };
    default:
      return Js_exn.raiseError("Unknown action type");
  }
}

function decode(json, questions, thankYouList) {
  return {
          id: Json_decode.field("id", (function (param) {
                  return Json_decode.optional(Json_decode.$$int, param);
                }), json),
          actionType: decodeActionType(json, questions, thankYouList)
        };
}

function skipTargetIds(x) {
  var match = x.actionType;
  if (match.TAG !== /* Skip */0) {
    return /* [] */0;
  }
  var y = match._0;
  if (y.TAG === /* Questions */0) {
    return Belt_List.map(y._0, (function (xs) {
                  return Belt_Option.getExn(Question$ReactHooksTemplate.id(xs));
                }));
  } else {
    return Belt_List.map(y._0, (function (xs) {
                  return Belt_Option.getExn(ThankYou$ReactHooksTemplate.id(xs));
                }));
  }
}

function skipActionId(t) {
  var match = t.actionType;
  if (match.TAG === /* Skip */0) {
    return t.id;
  }
  
}

function stringOfActionType(t) {
  var match = t.actionType;
  if (match.TAG === /* Skip */0) {
    return "skip";
  } else {
    return "jump";
  }
}

function stringOfSkipTargetType(t) {
  var match = t.actionType;
  if (match.TAG === /* Skip */0) {
    if (match._0.TAG === /* Questions */0) {
      return "Question";
    } else {
      return "ThankYou";
    }
  } else {
    return "";
  }
}

function stringOfJumpTargetType(t) {
  var match = t.actionType;
  if (match.TAG === /* Skip */0) {
    return "";
  } else if (match._0.TAG === /* Question */0) {
    return "Question";
  } else {
    return "ThankYou";
  }
}

function jumpTargetIds(t) {
  var match = t.actionType;
  if (match.TAG === /* Skip */0) {
    return /* [] */0;
  }
  var y = match._0;
  if (y.TAG === /* Question */0) {
    return {
            hd: Belt_Option.getExn(Question$ReactHooksTemplate.id(y._0)),
            tl: /* [] */0
          };
  } else {
    return {
            hd: Belt_Option.getExn(ThankYou$ReactHooksTemplate.id(y._0)),
            tl: /* [] */0
          };
  }
}

function skipActionToJson(destroyOpt, t) {
  var destroy = destroyOpt !== undefined ? destroyOpt : false;
  var targetIds = skipTargetIds(t);
  return Json_encode.object_({
              hd: [
                "id",
                Json_encode.nullable((function (prim) {
                        return prim;
                      }), skipActionId(t))
              ],
              tl: {
                hd: [
                  "target_type",
                  stringOfSkipTargetType(t)
                ],
                tl: {
                  hd: [
                    "target_ids",
                    Belt_List.toArray(Belt_List.map(targetIds, (function (x) {
                                return x;
                              })))
                  ],
                  tl: {
                    hd: [
                      "_destroy",
                      destroy
                    ],
                    tl: /* [] */0
                  }
                }
              }
            });
}

function jumpActionToJson(destroyOpt, t) {
  var destroy = destroyOpt !== undefined ? destroyOpt : false;
  var targetIds = jumpTargetIds(t);
  return Json_encode.object_({
              hd: [
                "target_type",
                stringOfJumpTargetType(t)
              ],
              tl: {
                hd: [
                  "target_ids",
                  Belt_List.toArray(Belt_List.map(targetIds, (function (x) {
                              return x;
                            })))
                ],
                tl: {
                  hd: [
                    "_destroy",
                    destroy
                  ],
                  tl: /* [] */0
                }
              }
            });
}

var actions = {
  hd: "Jump",
  tl: {
    hd: "Skip",
    tl: /* [] */0
  }
};

exports.actions = actions;
exports.id = id;
exports.actionType = actionType;
exports.setActionType = setActionType;
exports.emptyAction = emptyAction;
exports.emptySkipAction = emptySkipAction;
exports.stringOfActionType = stringOfActionType;
exports.decode = decode;
exports.skipActionToJson = skipActionToJson;
exports.jumpActionToJson = jumpActionToJson;
/* Question-ReactHooksTemplate Not a pure module */

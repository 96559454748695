// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var List = require("rescript/lib/js/list.js");
var $$Array = require("rescript/lib/js/array.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Pervasives = require("rescript/lib/js/pervasives.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Utils$ReactHooksTemplate = require("../common/utils.bs.js");
var Action$ReactHooksTemplate = require("./Action.bs.js");
var OptionGroups$ReactHooksTemplate = require("../common/optionGroups.bs.js");
var FilteredSelectBox$ReactHooksTemplate = require("./FilteredSelectBox.bs.js");

function ActionForm(Props) {
  var jumpExists = Props.jumpExists;
  var action = Props.action;
  var updateLogicAction = Props.updateLogicAction;
  var thankYous = Props.thankYous;
  var targetQuestions = Props.targetQuestions;
  var skipTarget = Action$ReactHooksTemplate.actionType(action);
  var selectedOption;
  if (skipTarget.TAG === /* Skip */0) {
    var skipTarget$1 = skipTarget._0;
    selectedOption = skipTarget$1.TAG === /* Questions */0 ? ({
          TAG: /* Questions */0,
          _0: skipTarget$1._0
        }) : ({
          TAG: /* ThankYous */1,
          _0: skipTarget$1._0
        });
  } else {
    var jumpTarget = skipTarget._0;
    selectedOption = jumpTarget.TAG === /* Question */0 ? ({
          TAG: /* Questions */0,
          _0: {
            hd: jumpTarget._0,
            tl: /* [] */0
          }
        }) : ({
          TAG: /* ThankYous */1,
          _0: {
            hd: jumpTarget._0,
            tl: /* [] */0
          }
        });
  }
  var handleOptionChange = function (updatedOptions) {
    var match = Action$ReactHooksTemplate.actionType(action);
    var tmp;
    if (match.TAG === /* Skip */0) {
      tmp = updatedOptions.TAG === /* Questions */0 ? Action$ReactHooksTemplate.setActionType(action, {
              TAG: /* Skip */0,
              _0: {
                TAG: /* Questions */0,
                _0: updatedOptions._0
              }
            }) : Action$ReactHooksTemplate.setActionType(action, {
              TAG: /* Skip */0,
              _0: {
                TAG: /* ThankYous */1,
                _0: updatedOptions._0
              }
            });
    } else if (updatedOptions.TAG === /* Questions */0) {
      var q = Belt_List.head(updatedOptions._0);
      tmp = q !== undefined ? Action$ReactHooksTemplate.setActionType(action, {
              TAG: /* Jump */1,
              _0: {
                TAG: /* Question */0,
                _0: q
              }
            }) : action;
    } else {
      var t = Belt_List.head(updatedOptions._0);
      tmp = t !== undefined ? Action$ReactHooksTemplate.setActionType(action, {
              TAG: /* Jump */1,
              _0: {
                TAG: /* ThankYou */1,
                _0: Caml_option.valFromOption(t)
              }
            }) : action;
    }
    return Curry._1(updateLogicAction, tmp);
  };
  var handleActionTypeChange = function ($$event) {
    var action$p = Utils$ReactHooksTemplate.formTargetValue($$event);
    var tmp;
    switch (action$p) {
      case "Jump" :
          tmp = List.length(targetQuestions) === 0 ? Action$ReactHooksTemplate.emptyAction({
                  TAG: /* ThankYou */1,
                  _0: List.hd(thankYous)
                }) : Action$ReactHooksTemplate.emptyAction({
                  TAG: /* Question */0,
                  _0: List.hd(targetQuestions)
                });
          break;
      case "Skip" :
          tmp = List.length(targetQuestions) === 0 ? Action$ReactHooksTemplate.emptySkipAction({
                  TAG: /* ThankYous */1,
                  _0: {
                    hd: List.hd(thankYous),
                    tl: /* [] */0
                  }
                }) : Action$ReactHooksTemplate.emptySkipAction({
                  TAG: /* Questions */0,
                  _0: {
                    hd: List.hd(targetQuestions),
                    tl: /* [] */0
                  }
                });
          break;
      default:
        tmp = Pervasives.failwith("Not Valid Action Type");
    }
    return Curry._1(updateLogicAction, tmp);
  };
  var match = Action$ReactHooksTemplate.actionType(action);
  var enableMultipleSelection;
  enableMultipleSelection = match.TAG === /* Skip */0 ? true : false;
  var stringOfActionType = Action$ReactHooksTemplate.stringOfActionType(action);
  return React.createElement("div", {
              className: "bg-white"
            }, React.createElement("div", {
                  className: "flex gap-4"
                }, React.createElement("div", {
                      className: "text-base font-semibold text-darkcharcoal pt-30-px"
                    }, "then"), React.createElement("div", undefined, React.createElement("div", {
                          className: "w-48-px h-20-px font-normal text-xs text-aurometalsaurus"
                        }, "Action"), React.createElement("select", {
                          className: "h-40-px py-2 px-1 rounded bg-white border cursor-pointer \n                     border-gray-300 focus:border-gray-600 text-xs w-80-px",
                          placeholder: "Select Action",
                          onChange: handleActionTypeChange
                        }, $$Array.of_list(Belt_List.map(Action$ReactHooksTemplate.actions, (function (xs) {
                                    var selected = xs.toLocaleLowerCase() === stringOfActionType;
                                    return React.createElement("option", {
                                                key: xs,
                                                className: "text-left bg-white-300 px-2 py-1 hover:bg-gray-300 " + (
                                                  xs === "Jump" && jumpExists ? "hidden" : "visible"
                                                ),
                                                selected: selected,
                                                value: xs
                                              }, xs);
                                  }))))), React.createElement("div", {
                      className: "text-base font-semibold text-darkcharcoal pt-30-px"
                    }, "to"), React.createElement("div", undefined, React.createElement("div", {
                          className: "w-48-px h-20-px font-normal text-xs text-aurometalsaurus "
                        }, "Question"), React.createElement(FilteredSelectBox$ReactHooksTemplate.make, {
                          options: OptionGroups$ReactHooksTemplate.make(targetQuestions, thankYous),
                          placeholder: "select questions",
                          onChange: handleOptionChange,
                          className: "py-2 px-1 rounded bg-white border border-gray-300 focus:border-gray-600 text-xs w-250-px ",
                          selectedOption: selectedOption,
                          multipleSelect: enableMultipleSelection
                        }))));
}

var make = ActionForm;

exports.make = make;
/* react Not a pure module */

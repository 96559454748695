// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var List = require("rescript/lib/js/list.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");
var RandomId$ReactHooksTemplate = require("./RandomId.bs.js");
var OpinionScaleLabel$ReactHooksTemplate = require("./OpinionScaleLabel.bs.js");

function decode(json) {
  return {
          text: Json_decode.field("text", Json_decode.string, json),
          description: Json_decode.field("description", Json_decode.string, json),
          label: OpinionScaleLabel$ReactHooksTemplate.make(Json_decode.field("left_label", Json_decode.string, json), Json_decode.field("center_label", Json_decode.string, json), Json_decode.field("right_label", Json_decode.string, json))
        };
}

function empty(param) {
  return {
          id: undefined,
          uuid: RandomId$ReactHooksTemplate.generate(undefined),
          text: "",
          description: "",
          label: OpinionScaleLabel$ReactHooksTemplate.empty(undefined),
          representation: /* Plain */0,
          autoAdvance: false,
          translations: /* [] */0,
          answer: undefined
        };
}

function answered(t) {
  return t.answer !== undefined;
}

function make(text, description, representation, autoAdvance, label, uuid) {
  return {
          id: undefined,
          uuid: uuid,
          text: text,
          description: description,
          label: label,
          representation: representation,
          autoAdvance: autoAdvance,
          translations: /* [] */0,
          answer: undefined
        };
}

function setText(t, text) {
  return {
          id: t.id,
          uuid: t.uuid,
          text: text,
          description: t.description,
          label: t.label,
          representation: t.representation,
          autoAdvance: t.autoAdvance,
          translations: t.translations,
          answer: t.answer
        };
}

function setDescription(t, description) {
  return {
          id: t.id,
          uuid: t.uuid,
          text: t.text,
          description: description,
          label: t.label,
          representation: t.representation,
          autoAdvance: t.autoAdvance,
          translations: t.translations,
          answer: t.answer
        };
}

function setLabel(t, label) {
  return {
          id: t.id,
          uuid: t.uuid,
          text: t.text,
          description: t.description,
          label: label,
          representation: t.representation,
          autoAdvance: t.autoAdvance,
          translations: t.translations,
          answer: t.answer
        };
}

function setRepresentation(t, representation) {
  return {
          id: t.id,
          uuid: t.uuid,
          text: t.text,
          description: t.description,
          label: t.label,
          representation: representation,
          autoAdvance: t.autoAdvance,
          translations: t.translations,
          answer: t.answer
        };
}

function setAutoAdvance(autoAdvance, t) {
  return {
          id: t.id,
          uuid: t.uuid,
          text: t.text,
          description: t.description,
          label: t.label,
          representation: t.representation,
          autoAdvance: autoAdvance,
          translations: t.translations,
          answer: t.answer
        };
}

function setAnswer(t, scale) {
  var x = t.answer;
  return {
          id: t.id,
          uuid: t.uuid,
          text: t.text,
          description: t.description,
          label: t.label,
          representation: t.representation,
          autoAdvance: t.autoAdvance,
          translations: t.translations,
          answer: x !== undefined && x === scale ? undefined : scale
        };
}

function id(t) {
  return t.id;
}

function uuid(t) {
  return t.uuid;
}

function text(t) {
  return t.text;
}

function getTextTranslation(t, language) {
  var translation = List.find_opt((function (param) {
          return param[0] === language;
        }), t.translations);
  if (translation !== undefined) {
    return translation[1].text;
  } else {
    return t.text;
  }
}

function description(t) {
  return t.description;
}

function getDescriptionTranslation(t, language) {
  var translation = List.find_opt((function (param) {
          return param[0] === language;
        }), t.translations);
  if (translation !== undefined) {
    return translation[1].description;
  } else {
    return t.description;
  }
}

function label(t) {
  return t.label;
}

function getLabelTranslation(t, language) {
  var translation = List.find_opt((function (param) {
          return param[0] === language;
        }), t.translations);
  if (translation !== undefined) {
    return translation[1].label;
  } else {
    return t.label;
  }
}

function representation(t) {
  return t.representation;
}

function autoAdvance(t) {
  return t.autoAdvance;
}

function answer(t) {
  return t.answer;
}

function rating(t) {
  var answer = t.answer;
  if (answer !== undefined) {
    return answer;
  }
  
}

function answerToString(t) {
  var answer = t.answer;
  if (answer !== undefined) {
    return String(answer);
  } else {
    return "";
  }
}

function answerToInt(t) {
  var answer = t.answer;
  if (answer !== undefined) {
    return answer;
  }
  
}

function $eq(t, x) {
  var y = t.answer;
  if (y !== undefined) {
    return y === x;
  } else {
    return false;
  }
}

function $great$eq(t, x) {
  var y = t.answer;
  if (y !== undefined) {
    return y >= x;
  } else {
    return false;
  }
}

function $less$eq(t, x) {
  var y = t.answer;
  if (y !== undefined) {
    return y <= x;
  } else {
    return false;
  }
}

var isAnswer = $eq;

function answerRange(t) {
  return [
          1,
          5
        ];
}

function toJson(withAnswerOpt, t) {
  var withAnswer = withAnswerOpt !== undefined ? withAnswerOpt : false;
  var encodeArray_0 = [
    "id",
    Json_encode.nullable((function (prim) {
            return prim;
          }), t.id)
  ];
  var encodeArray_1 = {
    hd: [
      "text",
      t.text
    ],
    tl: {
      hd: [
        "description",
        t.description
      ],
      tl: {
        hd: [
          "left_label",
          OpinionScaleLabel$ReactHooksTemplate.left(t.label)
        ],
        tl: {
          hd: [
            "center_label",
            OpinionScaleLabel$ReactHooksTemplate.center(t.label)
          ],
          tl: {
            hd: [
              "right_label",
              OpinionScaleLabel$ReactHooksTemplate.right(t.label)
            ],
            tl: /* [] */0
          }
        }
      }
    }
  };
  var encodeArray = {
    hd: encodeArray_0,
    tl: encodeArray_1
  };
  return Json_encode.object_(withAnswer ? ({
                  hd: [
                    "answer",
                    Belt_Option.getWithDefault(t.answer, -1)
                  ],
                  tl: encodeArray
                }) : encodeArray);
}

function decodeTranslation(json) {
  return [
          Json_decode.field("survey_language", (function (param) {
                  return Json_decode.field("name", Json_decode.string, param);
                }), json),
          Json_decode.field("key_string_mapping", decode, json)
        ];
}

function fromJson(json) {
  return {
          id: Json_decode.field("category", (function (param) {
                  return Json_decode.field("id", (function (param) {
                                return Json_decode.optional(Json_decode.$$int, param);
                              }), param);
                }), json),
          uuid: Json_decode.field("uid", RandomId$ReactHooksTemplate.fromJson, json),
          text: Json_decode.field("category", (function (param) {
                  return Json_decode.field("text", Json_decode.string, param);
                }), json),
          description: Json_decode.field("category", (function (param) {
                  return Json_decode.field("description", Json_decode.string, param);
                }), json),
          label: OpinionScaleLabel$ReactHooksTemplate.make(Json_decode.field("category", (function (param) {
                      return Json_decode.field("left_label", Json_decode.string, param);
                    }), json), Json_decode.field("category", (function (param) {
                      return Json_decode.field("center_label", Json_decode.string, param);
                    }), json), Json_decode.field("category", (function (param) {
                      return Json_decode.field("right_label", Json_decode.string, param);
                    }), json)),
          representation: /* Plain */0,
          autoAdvance: false,
          translations: Json_decode.field("translations", (function (param) {
                  return Json_decode.list(decodeTranslation, param);
                }), json),
          answer: undefined
        };
}

var categoryType = "OpinionScale";

exports.categoryType = categoryType;
exports.empty = empty;
exports.make = make;
exports.setText = setText;
exports.setDescription = setDescription;
exports.setLabel = setLabel;
exports.setRepresentation = setRepresentation;
exports.setAutoAdvance = setAutoAdvance;
exports.setAnswer = setAnswer;
exports.text = text;
exports.getTextTranslation = getTextTranslation;
exports.description = description;
exports.getDescriptionTranslation = getDescriptionTranslation;
exports.label = label;
exports.getLabelTranslation = getLabelTranslation;
exports.representation = representation;
exports.autoAdvance = autoAdvance;
exports.answer = answer;
exports.rating = rating;
exports.answered = answered;
exports.$eq = $eq;
exports.$great$eq = $great$eq;
exports.$less$eq = $less$eq;
exports.isAnswer = isAnswer;
exports.toJson = toJson;
exports.fromJson = fromJson;
exports.id = id;
exports.uuid = uuid;
exports.answerToString = answerToString;
exports.answerRange = answerRange;
exports.answerToInt = answerToInt;
/* RandomId-ReactHooksTemplate Not a pure module */

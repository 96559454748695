// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Pervasives = require("rescript/lib/js/pervasives.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");
var Utils$ReactHooksTemplate = require("../../common/utils.bs.js");

function make(min, max) {
  return {
          min: min,
          max: max
        };
}

function getMax(t) {
  return t.max;
}

function getMin(t) {
  return t.min;
}

function setMin(min, t) {
  return {
          min: min,
          max: t.max
        };
}

function setMax(max, t) {
  return {
          min: t.min,
          max: max
        };
}

function toString(t) {
  var match = t.min;
  var match$1 = t.max;
  if (match !== undefined) {
    if (match$1 !== undefined) {
      return String(match) + ("-" + String(match$1));
    } else {
      return String(match);
    }
  } else if (match$1 !== undefined) {
    return String(match$1);
  } else {
    return "";
  }
}

function encodeParams(t) {
  return Pervasives.$at(Utils$ReactHooksTemplate.encodeOptionalParamToList("min", t.min, (function (prim) {
                    return prim;
                  })), Utils$ReactHooksTemplate.encodeOptionalParamToList("max", t.max, (function (prim) {
                    return prim;
                  })));
}

function encode(t) {
  return Json_encode.object_(encodeParams(t));
}

exports.make = make;
exports.getMax = getMax;
exports.getMin = getMin;
exports.setMin = setMin;
exports.setMax = setMax;
exports.toString = toString;
exports.encode = encode;
/* Utils-ReactHooksTemplate Not a pure module */

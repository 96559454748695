// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var DateType$ReactHooksTemplate = require("./dateType.bs.js");
var ListType$ReactHooksTemplate = require("./listType.bs.js");
var TextType$ReactHooksTemplate = require("./textType.bs.js");
var EmailType$ReactHooksTemplate = require("./emailType.bs.js");
var MsisdnType$ReactHooksTemplate = require("./msisdnType.bs.js");
var NumberType$ReactHooksTemplate = require("./numberType.bs.js");

function decode(json) {
  var dataTypeName = Json_decode.field("data_type", Json_decode.string, json);
  if (dataTypeName === ListType$ReactHooksTemplate.dataTypeName) {
    return {
            TAG: /* ListType */0,
            _0: Json_decode.field("data_type_property", ListType$ReactHooksTemplate.decode, json)
          };
  } else if (dataTypeName === NumberType$ReactHooksTemplate.dataTypeName) {
    return {
            TAG: /* NumberType */1,
            _0: Json_decode.field("data_type_property", NumberType$ReactHooksTemplate.decode, json)
          };
  } else if (dataTypeName === EmailType$ReactHooksTemplate.dataTypeName) {
    return {
            TAG: /* EmailType */2,
            _0: Json_decode.field("data_type_property", EmailType$ReactHooksTemplate.decode, json)
          };
  } else if (dataTypeName === MsisdnType$ReactHooksTemplate.dataTypeName) {
    return {
            TAG: /* MsisdnType */4,
            _0: Json_decode.field("data_type_property", MsisdnType$ReactHooksTemplate.decode, json)
          };
  } else if (dataTypeName === DateType$ReactHooksTemplate.dataTypeName) {
    return {
            TAG: /* DateType */3,
            _0: Json_decode.field("data_type_property", DateType$ReactHooksTemplate.decode, json)
          };
  } else {
    return {
            TAG: /* TextType */5,
            _0: Json_decode.field("data_type_property", TextType$ReactHooksTemplate.decode, json)
          };
  }
}

exports.decode = decode;
/* No side effect */

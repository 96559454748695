// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var List = require("rescript/lib/js/list.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");
var Http$ReactHooksTemplate = require("../common/http.bs.js");
var Image$ReactHooksTemplate = require("../common/image.bs.js");
var Utils$ReactHooksTemplate = require("../common/utils.bs.js");
var Domain$ReactHooksTemplate = require("../settings/domain.bs.js");
var Ticket$ReactHooksTemplate = require("./ticket.bs.js");
var TicketView$ReactHooksTemplate = require("./TicketView.bs.js");

function fetchTicket(id) {
  return Http$ReactHooksTemplate.fetchGetJSONDecode("/api/v1/tickets/" + id, (function (param) {
                return Json_decode.field("ticket", Ticket$ReactHooksTemplate.decode, param);
              }));
}

function TicketStandAloneView(Props) {
  var id = Props.id;
  var match = React.useState(function () {
        
      });
  var setTicket = match[1];
  var ticket = match[0];
  var match$1 = React.useState(function () {
        return {
                agents: /* [] */0,
                assignee: undefined
              };
      });
  var setState = match$1[1];
  var state = match$1[0];
  var fetchTicketWorkers = function (param) {
    return Http$ReactHooksTemplate.fetchGetJSONDecode("/api/v1/recovery_agents", (function (param) {
                  return Json_decode.field("recovery_agents", (function (param) {
                                return Json_decode.list(Ticket$ReactHooksTemplate.Assignee.decode, param);
                              }), param);
                }));
  };
  React.useEffect((function () {
          fetchTicket(id).then(function (x) {
                return Promise.resolve((Curry._1(setTicket, (function (param) {
                                    return x;
                                  })), fetchTicketWorkers(undefined).then(function (agents) {
                                  return Promise.resolve(Curry._1(setState, (function (param) {
                                                    return {
                                                            agents: agents,
                                                            assignee: state.assignee
                                                          };
                                                  })));
                                }), undefined));
              });
          
        }), []);
  var updateTickets = function (ticketId, status) {
    if (ticket === undefined) {
      return ;
    }
    if (Ticket$ReactHooksTemplate.id(ticket) !== ticketId) {
      return Curry._1(setTicket, (function (param) {
                    return ticket;
                  }));
    }
    var tic = Ticket$ReactHooksTemplate.updateTicketStatus(status, ticket);
    return Curry._1(setTicket, (function (param) {
                  return tic;
                }));
  };
  var assignTicket = function (ticketId) {
    var assignee = state.assignee;
    if (assignee === undefined) {
      return ;
    }
    var assigneeId = Ticket$ReactHooksTemplate.Assignee.id(assignee);
    var params = {};
    params["assignee_id"] = assigneeId;
    if (ticket === undefined) {
      return ;
    }
    if (Ticket$ReactHooksTemplate.id(ticket) !== ticketId) {
      return Curry._1(setTicket, (function (param) {
                    return ticket;
                  }));
    }
    var ticketArray = Belt_List.fromArray([ticket]);
    var ticketIds = Belt_List.map(ticketArray, Ticket$ReactHooksTemplate.id);
    params["ids"] = Json_encode.list((function (prim) {
            return prim;
          }), ticketIds);
    var ticket$1 = {};
    ticket$1["ticket"] = params;
    Http$ReactHooksTemplate.fetchPostJSON("/api/v1/tickets/assign", ticket$1).then(function (param) {
          var tic = Ticket$ReactHooksTemplate.updateAssignee(state.assignee, ticket);
          return Promise.resolve(Curry._1(setTicket, (function (param) {
                            return tic;
                          })));
        });
    
  };
  var handleAssigneeSelection = function ($$event) {
    var emailText = Curry._1(Domain$ReactHooksTemplate.$$String.Email.create, Utils$ReactHooksTemplate.formTargetValue($$event));
    if (Curry._1(Domain$ReactHooksTemplate.$$String.Email.isEmpty, emailText)) {
      return Curry._1(setState, (function (param) {
                    return {
                            agents: state.agents,
                            assignee: undefined
                          };
                  }));
    }
    var selected = List.find((function (assignee) {
            return Caml_obj.caml_equal(Ticket$ReactHooksTemplate.Assignee.email(assignee), emailText);
          }), state.agents);
    return Curry._1(setState, (function (param) {
                  return {
                          agents: state.agents,
                          assignee: selected
                        };
                }));
  };
  return React.createElement("div", {
              className: "flex flex-col 1/2"
            }, ticket !== undefined ? React.createElement("div", {
                    className: "p-30-px"
                  }, React.createElement("a", {
                        href: "/tickets/manage/all"
                      }, React.createElement("img", {
                            className: "custom-back mb-4",
                            src: Image$ReactHooksTemplate.backArrow
                          })), React.createElement("div", {
                        className: "w-full border border-gray-300 rounded-lg"
                      }, React.createElement(TicketView$ReactHooksTemplate.make, {
                            ticket: ticket,
                            updateTickets: updateTickets,
                            handleAssigneeSelection: handleAssigneeSelection,
                            assignTicket: assignTicket,
                            agents: state.agents
                          }))) : React.createElement(React.Fragment, undefined));
}

var make = TicketStandAloneView;

exports.fetchTicket = fetchTicket;
exports.make = make;
/* react Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var List = require("rescript/lib/js/list.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var ApplicationSetting$ReactHooksTemplate = require("./applicationSetting.bs.js");
var SavePartialResponse$ReactHooksTemplate = require("./savePartialResponse.bs.js");

function empty(param) {
  return /* [] */0;
}

function savePartialResponse(t) {
  var applicationSetting = List.find((function (x) {
          var match = ApplicationSetting$ReactHooksTemplate.individualSetting(x);
          if (match.TAG === /* SavePartialResponse */0) {
            return true;
          } else {
            return false;
          }
        }), t);
  var y = ApplicationSetting$ReactHooksTemplate.individualSetting(applicationSetting);
  if (y.TAG === /* SavePartialResponse */0) {
    return SavePartialResponse$ReactHooksTemplate.value(y._0);
  } else {
    return false;
  }
}

function generateTicket(t) {
  var applicationSetting = List.find((function (x) {
          var match = ApplicationSetting$ReactHooksTemplate.individualSetting(x);
          if (match.TAG === /* SavePartialResponse */0) {
            return true;
          } else {
            return false;
          }
        }), t);
  var y = ApplicationSetting$ReactHooksTemplate.individualSetting(applicationSetting);
  if (y.TAG === /* SavePartialResponse */0) {
    return SavePartialResponse$ReactHooksTemplate.value(y._0);
  } else {
    return false;
  }
}

function decoder(param) {
  return Json_decode.list(ApplicationSetting$ReactHooksTemplate.decode, param);
}

exports.empty = empty;
exports.savePartialResponse = savePartialResponse;
exports.generateTicket = generateTicket;
exports.decoder = decoder;
/* No side effect */

// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");
var FormField$ReactHooksTemplate = require("../common/FormField.bs.js");

function empty(param) {
  return {
          subject: "",
          body: "",
          emailTemplateId: undefined
        };
}

function setSubject(x, t) {
  return {
          subject: x,
          body: t.body,
          emailTemplateId: t.emailTemplateId
        };
}

function setBody(x, t) {
  return {
          subject: t.subject,
          body: x,
          emailTemplateId: t.emailTemplateId
        };
}

function setEmailTemplateId(x, t) {
  return {
          subject: t.subject,
          body: t.body,
          emailTemplateId: x
        };
}

function validSubject(x) {
  return x !== "";
}

function validBody(x) {
  return x !== "";
}

function validEmailTemplateId(x) {
  return x !== undefined;
}

function toForm(t) {
  return {
          subject: t.subject !== "" ? ({
                TAG: /* Valid */0,
                _0: t.subject
              }) : ({
                TAG: /* Invalid */1,
                _0: t.subject,
                _1: {
                  hd: "can't be empty",
                  tl: /* [] */0
                }
              }),
          body: t.body !== "" ? ({
                TAG: /* Valid */0,
                _0: t.body
              }) : ({
                TAG: /* Invalid */1,
                _0: t.body,
                _1: {
                  hd: "can't be empty",
                  tl: /* [] */0
                }
              }),
          emailTemplateId: validEmailTemplateId(t.emailTemplateId) ? ({
                TAG: /* Valid */0,
                _0: t.emailTemplateId
              }) : ({
                TAG: /* Invalid */1,
                _0: t.emailTemplateId,
                _1: {
                  hd: "can't be empty",
                  tl: /* [] */0
                }
              })
        };
}

function fromForm(form) {
  return {
          subject: FormField$ReactHooksTemplate.getInputValue(form.subject),
          body: FormField$ReactHooksTemplate.getInputValue(form.body),
          emailTemplateId: FormField$ReactHooksTemplate.getInputValue(form.emailTemplateId)
        };
}

function encoder(t) {
  return Json_encode.object_({
              hd: [
                "subject",
                t.subject
              ],
              tl: {
                hd: [
                  "body",
                  t.body
                ],
                tl: {
                  hd: [
                    "email_template_id",
                    Json_encode.nullable((function (prim) {
                            return prim;
                          }), t.emailTemplateId)
                  ],
                  tl: /* [] */0
                }
              }
            });
}

function decoder(json) {
  return {
          subject: Json_decode.field("subject", Json_decode.string, json),
          body: Json_decode.field("body", Json_decode.string, json),
          emailTemplateId: Json_decode.optional((function (param) {
                  return Json_decode.field("email_template_id", Json_decode.$$int, param);
                }), json)
        };
}

var modeType = "Email";

exports.modeType = modeType;
exports.empty = empty;
exports.setSubject = setSubject;
exports.setBody = setBody;
exports.setEmailTemplateId = setEmailTemplateId;
exports.validSubject = validSubject;
exports.validBody = validBody;
exports.validEmailTemplateId = validEmailTemplateId;
exports.toForm = toForm;
exports.fromForm = fromForm;
exports.encoder = encoder;
exports.decoder = decoder;
/* No side effect */

// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var List = require("rescript/lib/js/list.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Pervasives = require("rescript/lib/js/pervasives.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");
var RandomId$ReactHooksTemplate = require("./RandomId.bs.js");

function decodeChoice(json) {
  return [
          Json_decode.field("id", Json_decode.string, json),
          Json_decode.field("label", Json_decode.string, json)
        ];
}

function decode(json) {
  return {
          text: Json_decode.field("text", Json_decode.string, json),
          description: Json_decode.field("description", Json_decode.string, json),
          choices: Json_decode.field("choices", (function (param) {
                  return Json_decode.list(decodeChoice, param);
                }), json)
        };
}

function makeChoice(text, allowNonSpecifiedText, notSpecifiedText) {
  return {
          id: RandomId$ReactHooksTemplate.generate(undefined),
          text: text,
          allowNonSpecifiedText: allowNonSpecifiedText,
          notSpecifiedText: notSpecifiedText
        };
}

function toggleAllowNonSpecifiedText(choice) {
  return {
          id: choice.id,
          text: choice.text,
          allowNonSpecifiedText: !choice.allowNonSpecifiedText,
          notSpecifiedText: choice.notSpecifiedText
        };
}

function getAllowNonSpecifiedText(choice) {
  return choice.allowNonSpecifiedText;
}

function choiceText(choice) {
  return choice.text;
}

function setNotSpecifiedText(notSpecifiedText, choice) {
  return {
          id: choice.id,
          text: choice.text,
          allowNonSpecifiedText: choice.allowNonSpecifiedText,
          notSpecifiedText: notSpecifiedText
        };
}

function choiceId(choice) {
  return choice.id;
}

function setChoiceText(text, choice) {
  return {
          id: choice.id,
          text: text,
          allowNonSpecifiedText: choice.allowNonSpecifiedText,
          notSpecifiedText: choice.notSpecifiedText
        };
}

function getChoiceText(choice) {
  return choice.text;
}

function getAnswer(t) {
  return t.answer;
}

function answerIdList(t) {
  var choices = t.answer;
  if (choices !== undefined && choices) {
    return Belt_List.map(choices, (function (x) {
                  return RandomId$ReactHooksTemplate.toString(x.id);
                }));
  } else {
    return /* [] */0;
  }
}

function answerList(t) {
  return Belt_List.map(t.choices, (function (x) {
                return [
                        RandomId$ReactHooksTemplate.toString(x.id),
                        x.text
                      ];
              }));
}

function answered(t) {
  return t.answer !== undefined;
}

function empty(param) {
  return {
          id: undefined,
          uuid: RandomId$ReactHooksTemplate.generate(undefined),
          text: "",
          description: "",
          choices: {
            hd: {
              id: RandomId$ReactHooksTemplate.generate(undefined),
              text: "Choice 1",
              allowNonSpecifiedText: false,
              notSpecifiedText: undefined
            },
            tl: {
              hd: {
                id: RandomId$ReactHooksTemplate.generate(undefined),
                text: "Choice 2",
                allowNonSpecifiedText: false,
                notSpecifiedText: undefined
              },
              tl: /* [] */0
            }
          },
          translations: /* [] */0,
          representation: /* TropicalBreezeBackground */1,
          answer: undefined
        };
}

function hasChoiceInAnswer(choice, xs) {
  return Belt_List.has(xs, choice, (function (xs, ys) {
                return Caml_obj.caml_equal(xs.id, ys.id);
              }));
}

function setAnswer(t, choice) {
  var answer = {
    hd: choice,
    tl: /* [] */0
  };
  return {
          id: t.id,
          uuid: t.uuid,
          text: t.text,
          description: t.description,
          choices: t.choices,
          translations: t.translations,
          representation: t.representation,
          answer: answer
        };
}

function setNotSpecifiedTextForAnswer(t, choice) {
  var xs = t.answer;
  var answer = xs !== undefined ? (
      hasChoiceInAnswer(choice, xs) ? Belt_List.map(xs, (function (ys) {
                if (Caml_obj.caml_equal(ys.id, choice.id)) {
                  return choice;
                } else {
                  return ys;
                }
              })) : xs
    ) : undefined;
  return {
          id: t.id,
          uuid: t.uuid,
          text: t.text,
          description: t.description,
          choices: t.choices,
          translations: t.translations,
          representation: t.representation,
          answer: answer
        };
}

function text(t) {
  return t.text;
}

function getTextTranslation(t, language) {
  var translation = List.find_opt((function (param) {
          return param[0] === language;
        }), t.translations);
  if (translation !== undefined) {
    return translation[1].text;
  } else {
    return t.text;
  }
}

function description(t) {
  return t.description;
}

function getDescriptionTranslation(t, language) {
  var translation = List.find_opt((function (param) {
          return param[0] === language;
        }), t.translations);
  if (translation !== undefined) {
    return translation[1].description;
  } else {
    return t.description;
  }
}

function id(t) {
  return t.id;
}

function uuid(t) {
  return t.uuid;
}

function representation(t) {
  return t.representation;
}

function choices(t) {
  return t.choices;
}

function getChoiceTextTranslation(t, choice, language) {
  var translation = List.find_opt((function (param) {
          return param[0] === language;
        }), t.translations);
  if (translation !== undefined) {
    return List.find((function (param) {
                    return param[0] === RandomId$ReactHooksTemplate.toString(choice.id);
                  }), translation[1].choices)[1];
  } else {
    return choice.text;
  }
}

function setText(t, text) {
  return {
          id: t.id,
          uuid: t.uuid,
          text: text,
          description: t.description,
          choices: t.choices,
          translations: t.translations,
          representation: t.representation,
          answer: t.answer
        };
}

function setDescription(t, description) {
  return {
          id: t.id,
          uuid: t.uuid,
          text: t.text,
          description: description,
          choices: t.choices,
          translations: t.translations,
          representation: t.representation,
          answer: t.answer
        };
}

function setRepresentation(t, representation) {
  return {
          id: t.id,
          uuid: t.uuid,
          text: t.text,
          description: t.description,
          choices: t.choices,
          translations: t.translations,
          representation: representation,
          answer: t.answer
        };
}

function setChoice(choice, t) {
  var exist = Belt_List.has(t.choices, choice, (function (xs, choice) {
          return Caml_obj.caml_equal(xs.id, choice.id);
        }));
  if (exist) {
    return {
            id: t.id,
            uuid: t.uuid,
            text: t.text,
            description: t.description,
            choices: Belt_List.map(t.choices, (function (xs) {
                    if (Caml_obj.caml_equal(xs.id, choice.id)) {
                      return choice;
                    } else {
                      return xs;
                    }
                  })),
            translations: t.translations,
            representation: t.representation,
            answer: t.answer
          };
  } else {
    return {
            id: t.id,
            uuid: t.uuid,
            text: t.text,
            description: t.description,
            choices: Belt_List.concat(t.choices, {
                  hd: choice,
                  tl: /* [] */0
                }),
            translations: t.translations,
            representation: t.representation,
            answer: t.answer
          };
  }
}

function removeChoice(choice, choices, t) {
  var newSetOfChoices = Belt_List.keep(choices, (function (xs) {
          return Caml_obj.caml_notequal(xs.id, choice.id);
        }));
  return {
          id: t.id,
          uuid: t.uuid,
          text: t.text,
          description: t.description,
          choices: newSetOfChoices,
          translations: t.translations,
          representation: t.representation,
          answer: t.answer
        };
}

function encodeChoice(choice) {
  var objectEncoderList_0 = [
    "id",
    RandomId$ReactHooksTemplate.toString(choice.id)
  ];
  var objectEncoderList_1 = {
    hd: [
      "label",
      choice.text
    ],
    tl: {
      hd: [
        "allow_non_specified_text",
        choice.allowNonSpecifiedText
      ],
      tl: /* [] */0
    }
  };
  var objectEncoderList = {
    hd: objectEncoderList_0,
    tl: objectEncoderList_1
  };
  var text = choice.notSpecifiedText;
  var textForNonSpecified = text !== undefined ? ({
        hd: [
          "notSpecifiedText",
          text
        ],
        tl: /* [] */0
      }) : /* [] */0;
  return Json_encode.object_(Pervasives.$at(objectEncoderList, textForNonSpecified));
}

function encodeAnswer(answer) {
  return Json_encode.object_({
              hd: [
                "id",
                RandomId$ReactHooksTemplate.toString(answer.id)
              ],
              tl: {
                hd: [
                  "label",
                  answer.text
                ],
                tl: /* [] */0
              }
            });
}

function encodeOther(xs) {
  var other = Belt_List.head(Belt_List.reverse(xs));
  if (other !== undefined) {
    var x = other.notSpecifiedText;
    if (x !== undefined) {
      return {
              hd: [
                "other",
                x
              ],
              tl: /* [] */0
            };
    } else {
      return /* [] */0;
    }
  } else {
    return /* [] */0;
  }
}

function nonEmptyChoices(t) {
  return Belt_List.keep(t.choices, (function (choice) {
                return choice.text !== "";
              }));
}

function toJson(withAnswerOpt, t) {
  var withAnswer = withAnswerOpt !== undefined ? withAnswerOpt : false;
  var encodeArray_0 = [
    "id",
    Json_encode.nullable((function (prim) {
            return prim;
          }), t.id)
  ];
  var encodeArray_1 = {
    hd: [
      "text",
      t.text
    ],
    tl: {
      hd: [
        "description",
        t.description
      ],
      tl: {
        hd: [
          "options",
          Json_encode.list(encodeChoice, nonEmptyChoices(t))
        ],
        tl: /* [] */0
      }
    }
  };
  var encodeArray = {
    hd: encodeArray_0,
    tl: encodeArray_1
  };
  var tmp;
  if (withAnswer) {
    var xs = t.answer;
    if (xs !== undefined) {
      var encodedOther = encodeOther(xs);
      tmp = Pervasives.$at({
            hd: [
              "answer",
              Json_encode.list(encodeAnswer, xs)
            ],
            tl: encodeArray
          }, encodedOther);
    } else {
      tmp = /* [] */0;
    }
  } else {
    tmp = encodeArray;
  }
  return Json_encode.object_(tmp);
}

function decodeChoice$1(json) {
  return {
          id: Json_decode.field("id", RandomId$ReactHooksTemplate.fromJson, json),
          text: Json_decode.field("label", Json_decode.string, json),
          allowNonSpecifiedText: Json_decode.withDefault(false, (function (param) {
                  return Json_decode.field("allow_non_specified_text", Json_decode.bool, param);
                }), json),
          notSpecifiedText: undefined
        };
}

function decodeTranslation(json) {
  return [
          Json_decode.field("survey_language", (function (param) {
                  return Json_decode.field("name", Json_decode.string, param);
                }), json),
          Json_decode.field("key_string_mapping", decode, json)
        ];
}

function fromJson(json) {
  return {
          id: Json_decode.field("category", (function (param) {
                  return Json_decode.field("id", (function (param) {
                                return Json_decode.optional(Json_decode.$$int, param);
                              }), param);
                }), json),
          uuid: Json_decode.field("uid", RandomId$ReactHooksTemplate.fromJson, json),
          text: Json_decode.field("category", (function (param) {
                  return Json_decode.field("text", Json_decode.string, param);
                }), json),
          description: Json_decode.field("category", (function (param) {
                  return Json_decode.field("description", Json_decode.string, param);
                }), json),
          choices: Json_decode.field("category", (function (param) {
                  return Json_decode.field("options", (function (param) {
                                return Json_decode.list(decodeChoice$1, param);
                              }), param);
                }), json),
          translations: Json_decode.field("translations", (function (param) {
                  return Json_decode.list(decodeTranslation, param);
                }), json),
          representation: /* TropicalBreezeBackground */1,
          answer: undefined
        };
}

var categoryType = "DropDown";

exports.text = text;
exports.getTextTranslation = getTextTranslation;
exports.description = description;
exports.getDescriptionTranslation = getDescriptionTranslation;
exports.choices = choices;
exports.representation = representation;
exports.choiceText = choiceText;
exports.choiceId = choiceId;
exports.setText = setText;
exports.setDescription = setDescription;
exports.setRepresentation = setRepresentation;
exports.setChoiceText = setChoiceText;
exports.getChoiceText = getChoiceText;
exports.getChoiceTextTranslation = getChoiceTextTranslation;
exports.setChoice = setChoice;
exports.removeChoice = removeChoice;
exports.id = id;
exports.uuid = uuid;
exports.setAnswer = setAnswer;
exports.answered = answered;
exports.toJson = toJson;
exports.categoryType = categoryType;
exports.empty = empty;
exports.makeChoice = makeChoice;
exports.getAnswer = getAnswer;
exports.answerIdList = answerIdList;
exports.answerList = answerList;
exports.toggleAllowNonSpecifiedText = toggleAllowNonSpecifiedText;
exports.getAllowNonSpecifiedText = getAllowNonSpecifiedText;
exports.fromJson = fromJson;
exports.setNotSpecifiedText = setNotSpecifiedText;
exports.setNotSpecifiedTextForAnswer = setNotSpecifiedTextForAnswer;
exports.nonEmptyChoices = nonEmptyChoices;
/* RandomId-ReactHooksTemplate Not a pure module */

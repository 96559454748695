// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");

function options(t) {
  return t.options;
}

function id(t) {
  return t.id;
}

function decode(json) {
  return {
          id: Json_decode.field("id", Json_decode.$$int, json),
          options: Json_decode.field("options", (function (param) {
                  return Json_decode.list(Json_decode.string, param);
                }), json)
        };
}

var dataTypeName = "ListType";

exports.dataTypeName = dataTypeName;
exports.id = id;
exports.options = options;
exports.decode = decode;
/* No side effect */

// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Moment = require("moment");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");

function parkedBy(t) {
  return t.parkedBy;
}

function parkedTill(t) {
  return t.parkedTill;
}

function comment(t) {
  return t.comment;
}

function fromJson(json) {
  return {
          parkedBy: Json_decode.field("user", (function (param) {
                  return Json_decode.field("email", Json_decode.string, param);
                }), json),
          parkedTill: Moment(new Date(Json_decode.field("more_details", (function (param) {
                          return Json_decode.field("parked_till", Json_decode.string, param);
                        }), json))),
          comment: Json_decode.field("more_details", (function (param) {
                  return Json_decode.field("comment", Json_decode.string, param);
                }), json)
        };
}

var activityType = "park";

exports.activityType = activityType;
exports.parkedBy = parkedBy;
exports.parkedTill = parkedTill;
exports.comment = comment;
exports.fromJson = fromJson;
/* moment Not a pure module */

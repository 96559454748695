// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var PermissionTree$ReactHooksTemplate = require("./settings/permissionTree.bs.js");

function initValue_0(param) {
  return false;
}

var initValue_1 = PermissionTree$ReactHooksTemplate.emptyPermission(undefined);

var initValue = [
  initValue_0,
  initValue_1
];

var authContext = React.createContext(initValue);

function makeProps(value, children, param) {
  return {
          value: value,
          children: children
        };
}

var make = authContext.Provider;

exports.initValue = initValue;
exports.authContext = authContext;
exports.makeProps = makeProps;
exports.make = make;
/* initValue Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Http$ReactHooksTemplate = require("../common/http.bs.js");
var SmileyRating$ReactHooksTemplate = require("./smileyRating.bs.js");
var RatingSmileySet$ReactHooksTemplate = require("./ratingSmileySet.bs.js");
var ToggleMandatory$ReactHooksTemplate = require("./ToggleMandatory.bs.js");

function SmileyRatingEditor(Props) {
  var smileyRating = Props.smileyRating;
  var onSmileyRatingChange = Props.onSmileyRatingChange;
  var mandatory = Props.mandatory;
  var onMandatoryChange = Props.onMandatoryChange;
  React.useEffect((function () {
          var match = SmileyRating$ReactHooksTemplate.ratingSmileySet(smileyRating);
          if (match !== undefined) {
            
          } else {
            Http$ReactHooksTemplate.fetchGetJSONDecode("/api/v1/rating_smiley_sets/default", (function (json) {
                      return Json_decode.field("rating_smiley_set", RatingSmileySet$ReactHooksTemplate.fromJson, json);
                    })).then(function (ratingSmileySet) {
                  return Promise.resolve(Curry._1(onSmileyRatingChange, SmileyRating$ReactHooksTemplate.setRatingSmileySet(smileyRating, ratingSmileySet)));
                });
          }
          
        }), [smileyRating]);
  return React.createElement("div", undefined, React.createElement(ToggleMandatory$ReactHooksTemplate.make, {
                  mandatory: mandatory,
                  onMandatoryChange: onMandatoryChange
                }));
}

var make = SmileyRatingEditor;

exports.make = make;
/* react Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Js_dict = require("rescript/lib/js/js_dict.js");
var Js_json = require("rescript/lib/js/js_json.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");

function fromJson(json) {
  return {
          title: Json_decode.field("title", (function (x) {
                  return {
                          text_color: Json_decode.field("text_color", Json_decode.string, x)
                        };
                }), json),
          series: Belt_Option.getWithDefault(Js_dict.get(Belt_Option.getWithDefault(Js_json.decodeObject(json), {}), "series"), "empty"),
          drilldown: Belt_Option.getWithDefault(Js_dict.get(Belt_Option.getWithDefault(Js_json.decodeObject(json), {}), "drilldown"), "empty")
        };
}

exports.fromJson = fromJson;
/* No side effect */

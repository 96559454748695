// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");

function id(t) {
  return t.id;
}

function senderId(t) {
  return t.senderId;
}

function $$default(t) {
  return t.default;
}

function decode(json) {
  return {
          id: Json_decode.field("id", Json_decode.$$int, json),
          senderId: Json_decode.field("sender_id", Json_decode.string, json),
          default: Json_decode.field("default", Json_decode.bool, json)
        };
}

function make(id, senderId, defaultOpt, param) {
  var $$default = defaultOpt !== undefined ? defaultOpt : false;
  return {
          id: id,
          senderId: senderId,
          default: $$default
        };
}

exports.id = id;
exports.senderId = senderId;
exports.$$default = $$default;
exports.default = $$default;
exports.__esModule = true;
exports.decode = decode;
exports.make = make;
/* No side effect */

// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var List = require("rescript/lib/js/list.js");
var $$Array = require("rescript/lib/js/array.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Image$ReactHooksTemplate = require("../common/image.bs.js");
var Survey$ReactHooksTemplate = require("./survey.bs.js");
var Question$ReactHooksTemplate = require("./Question.bs.js");
var RandomId$ReactHooksTemplate = require("./RandomId.bs.js");
var LogicForm$ReactHooksTemplate = require("./LogicForm.bs.js");

function LogicPage(Props) {
  var survey = Props.survey;
  var updateSurvey = Props.updateSurvey;
  var match = React.useState(function () {
        
      });
  var setActiveQuestion = match[1];
  var activeQuestion = match[0];
  var removeLogics = function (logic$p) {
    return Curry._1(updateSurvey, Survey$ReactHooksTemplate.setDeletedLogics(Survey$ReactHooksTemplate.setLogics(survey, Belt_List.keep(survey.logics, (function (x) {
                              return Caml_obj.caml_notequal(x, logic$p);
                            }))), Belt_List.add(survey.deletedLogics, logic$p)));
  };
  var hasNoThankYous = List.length(survey.thankYouList) === 0;
  var lastQuestionIndex = List.length(Survey$ReactHooksTemplate.questions(survey)) - 1 | 0;
  return React.createElement("div", {
              className: "flex flex-col px-16 py-8"
            }, React.createElement("div", {
                  className: "w-110-px h-40-px"
                }, React.createElement("div", {
                      className: "w-48-px h-40-px py-2 border-b-2 border-pigmentblue text-lg font-semibold"
                    }, "Logic")), $$Array.of_list(List.mapi((function (index, x) {
                        var isLastQuestion = index === lastQuestionIndex;
                        var disableAddLogic = isLastQuestion && hasNoThankYous;
                        return React.createElement("div", {
                                    key: RandomId$ReactHooksTemplate.toString(Question$ReactHooksTemplate.uuid(x)),
                                    className: "bg-ghostwhiteblue w-full min-h-110-px my-2 p-4 rounded"
                                  }, React.createElement("div", {
                                        className: "flex justify-between items-center pb-3"
                                      }, React.createElement("div", {
                                            className: "flex items-center text-base font-normal"
                                          }, String(index + 1 | 0) + ". " + Question$ReactHooksTemplate.text(x)), disableAddLogic ? React.createElement(React.Fragment, undefined) : React.createElement("div", {
                                              className: "flex items-center justify-center rounded-md cursor-pointer " + (
                                                Caml_obj.caml_equal(activeQuestion, x) ? "bg-lavenderweb w-8 h-8 " : "bg-white w-8 h-8 "
                                              ),
                                              id: RandomId$ReactHooksTemplate.toString(Question$ReactHooksTemplate.uuid(x)),
                                              onClick: (function (param) {
                                                  if (Caml_obj.caml_equal(activeQuestion, x)) {
                                                    return Curry._1(setActiveQuestion, (function (param) {
                                                                  
                                                                }));
                                                  } else {
                                                    return Curry._1(setActiveQuestion, (function (param) {
                                                                  return x;
                                                                }));
                                                  }
                                                })
                                            }, Caml_obj.caml_equal(activeQuestion, x) ? React.createElement("img", {
                                                    src: Image$ReactHooksTemplate.vectorDown
                                                  }) : React.createElement("img", {
                                                    src: Image$ReactHooksTemplate.vectorRight
                                                  }))), Caml_obj.caml_equal(activeQuestion, x) ? $$Array.of_list(Belt_List.mapWithIndex(survey.logics, (function (index, y) {
                                                if (Caml_obj.caml_equal(y.trigger, {
                                                        TAG: /* Question */1,
                                                        _0: x
                                                      })) {
                                                  return React.createElement("div", {
                                                              key: String(index),
                                                              className: "flex-col items-center mb-3"
                                                            }, React.createElement(LogicForm$ReactHooksTemplate.make, {
                                                                  survey: survey,
                                                                  logic: y,
                                                                  updateLogic: (function (param) {
                                                                      return Curry._1(updateSurvey, Survey$ReactHooksTemplate.setLogics(survey, List.mapi((function (i, logic) {
                                                                                            if (i === index) {
                                                                                              return param;
                                                                                            } else {
                                                                                              return logic;
                                                                                            }
                                                                                          }), Survey$ReactHooksTemplate.logics(survey))));
                                                                    }),
                                                                  deleteLogic: removeLogics
                                                                }));
                                                } else {
                                                  return React.createElement(React.Fragment, undefined);
                                                }
                                              }))) : React.createElement(React.Fragment, undefined), Caml_obj.caml_equal(activeQuestion, x) && !disableAddLogic ? React.createElement("div", {
                                          className: "mt-3 mb-3 flex items-center"
                                        }, React.createElement("div", {
                                              className: "flex bg-ghostwhite border border-gray-400 shadow-inner rounded py-2 px-3 text-xs font-normal cursor-pointer",
                                              onClick: (function (param) {
                                                  var logicListCount = List.length(Belt_List.keep(Survey$ReactHooksTemplate.logics(survey), (function (x$1) {
                                                              return Caml_obj.caml_equal(x$1.trigger, {
                                                                          TAG: /* Question */1,
                                                                          _0: x
                                                                        });
                                                            })));
                                                  var newPriority = logicListCount + 1 | 0;
                                                  var logic = Survey$ReactHooksTemplate.emptyLogic(survey, newPriority, x);
                                                  Curry._1(updateSurvey, Survey$ReactHooksTemplate.setLogics(survey, List.append(Survey$ReactHooksTemplate.logics(survey), {
                                                                hd: logic,
                                                                tl: /* [] */0
                                                              })));
                                                  return Curry._1(setActiveQuestion, (function (param) {
                                                                return x;
                                                              }));
                                                })
                                            }, React.createElement("img", {
                                                  className: "h-14-px mr-2-px",
                                                  src: Image$ReactHooksTemplate.plusCircle
                                                }), "Add Logic")) : React.createElement(React.Fragment, undefined));
                      }), Survey$ReactHooksTemplate.questions(survey))));
}

var make = LogicPage;

exports.make = make;
/* react Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';


function make(questions, thankYous) {
  return [
          {
            TAG: /* Questions */0,
            _0: questions
          },
          {
            TAG: /* ThankYous */1,
            _0: thankYous
          }
        ];
}

function questions(t) {
  var xs = t[0];
  if (xs.TAG === /* Questions */0) {
    return xs._0;
  } else {
    return /* [] */0;
  }
}

function thankYous(t) {
  var xs = t[1];
  if (xs.TAG === /* Questions */0) {
    return /* [] */0;
  } else {
    return xs._0;
  }
}

exports.make = make;
exports.questions = questions;
exports.thankYous = thankYous;
/* No side effect */

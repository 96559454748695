// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var Fetch = require("bs-fetch/src/Fetch.bs.js");
var React = require("react");
var Caml_array = require("rescript/lib/js/caml_array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Http$ReactHooksTemplate = require("../common/http.bs.js");
var Utils$ReactHooksTemplate = require("../common/utils.bs.js");
var Organization$ReactHooksTemplate = require("../settings/organization.bs.js");
var TextInputNew$ReactHooksTemplate = require("../common/TextInputNew.bs.js");
var AlertComponent$ReactHooksTemplate = require("../common/AlertComponent.bs.js");

function OrganisationProfile(Props) {
  var initialState_client = Organization$ReactHooksTemplate.empty(undefined);
  var initialState = {
    logo_edited: false,
    name_edited: false,
    client: initialState_client,
    showUploadInputField: false,
    logoUrl: undefined,
    logoFile: undefined,
    notification: undefined
  };
  var match = React.useState(function () {
        return initialState;
      });
  var setState = match[1];
  var state = match[0];
  React.useEffect((function () {
          Http$ReactHooksTemplate.fetchGetJSONDecode("/api/v1/organization_profiles/0", (function (param) {
                    return Json_decode.field("organization_profile", Organization$ReactHooksTemplate.decode, param);
                  })).then(function (organization) {
                return Promise.resolve(Curry._1(setState, (function (state) {
                                  return {
                                          logo_edited: state.logo_edited,
                                          name_edited: state.name_edited,
                                          client: organization,
                                          showUploadInputField: state.showUploadInputField,
                                          logoUrl: Organization$ReactHooksTemplate.logo(organization),
                                          logoFile: state.logoFile,
                                          notification: state.notification
                                        };
                                })));
              });
          
        }), []);
  var handleLogoChange = function ($$event) {
    var logoFile = Caml_array.get($$event.target.files, 0);
    var logoUrl = window.URL.createObjectURL(logoFile);
    return Curry._1(setState, (function (param) {
                  return {
                          logo_edited: true,
                          name_edited: state.name_edited,
                          client: state.client,
                          showUploadInputField: false,
                          logoUrl: logoUrl,
                          logoFile: Caml_option.some(logoFile),
                          notification: state.notification
                        };
                }));
  };
  var showUploadInputField = function ($$event) {
    $$event.preventDefault();
    return Curry._1(setState, (function (state) {
                  return {
                          logo_edited: true,
                          name_edited: state.name_edited,
                          client: state.client,
                          showUploadInputField: true,
                          logoUrl: state.logoUrl,
                          logoFile: state.logoFile,
                          notification: state.notification
                        };
                }));
  };
  var revertLogoChanges = function ($$event) {
    $$event.preventDefault();
    return Curry._1(setState, (function (state) {
                  return {
                          logo_edited: false,
                          name_edited: state.name_edited,
                          client: state.client,
                          showUploadInputField: false,
                          logoUrl: Organization$ReactHooksTemplate.logo(state.client),
                          logoFile: state.logoFile,
                          notification: state.notification
                        };
                }));
  };
  var saveClientDetails = function ($$event) {
    $$event.preventDefault();
    var formData = new FormData();
    var organizationName = Organization$ReactHooksTemplate.name(state.client);
    if (organizationName !== Organization$ReactHooksTemplate.emptyString) {
      var file = state.logoFile;
      if (file !== undefined) {
        formData.append("organization_profile[logo]", Caml_option.valFromOption(file), undefined);
        formData.append("organization_profile[name]", organizationName);
      } else {
        formData.append("organization_profile[name]", organizationName);
      }
    }
    fetch("/api/v1/organization_profiles/" + String(Belt_Option.getExn(Organization$ReactHooksTemplate.id(state.client))), Fetch.RequestInit.make(/* Put */3, [
                    [
                      "X-CSRF-TOKEN",
                      Utils$ReactHooksTemplate.csrfToken
                    ],
                    [
                      "Accept",
                      "*"
                    ]
                  ], Caml_option.some(formData), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined)).then(function (response) {
            Curry._1(setState, (function (state) {
                    return {
                            logo_edited: false,
                            name_edited: false,
                            client: state.client,
                            showUploadInputField: state.showUploadInputField,
                            logoUrl: state.logoUrl,
                            logoFile: state.logoFile,
                            notification: state.notification
                          };
                  }));
            return response.json();
          }).then(function (response) {
          var status = Json_decode.field("status", (function (param) {
                  return Json_decode.withDefault("failure", Json_decode.string, param);
                }), response);
          if (status === "success") {
            var logoUrl = Json_decode.field("organization_profile", (function (param) {
                    return Json_decode.field("logo", (function (param) {
                                  return Json_decode.optional(Json_decode.string, param);
                                }), param);
                  }), response);
            var name = Json_decode.field("organization_profile", (function (param) {
                    return Json_decode.field("name", Json_decode.string, param);
                  }), response);
            var newClient = Organization$ReactHooksTemplate.setLogo(state.client, logoUrl);
            Curry._1(setState, (function (state) {
                    return {
                            logo_edited: state.logo_edited,
                            name_edited: state.name_edited,
                            client: Organization$ReactHooksTemplate.setName(newClient, name),
                            showUploadInputField: state.showUploadInputField,
                            logoUrl: logoUrl,
                            logoFile: state.logoFile,
                            notification: {
                              TAG: /* Success */1,
                              _0: "Updated the Organization"
                            }
                          };
                  }));
          } else {
            Curry._1(setState, (function (state) {
                    return {
                            logo_edited: state.logo_edited,
                            name_edited: state.name_edited,
                            client: state.client,
                            showUploadInputField: state.showUploadInputField,
                            logoUrl: state.logoUrl,
                            logoFile: state.logoFile,
                            notification: {
                              TAG: /* Failure */2,
                              _0: "Something went wrong. Please contact admin"
                            }
                          };
                  }));
          }
          return Promise.resolve(undefined);
        });
    
  };
  var enableUpdate = Organization$ReactHooksTemplate.name(state.client) === Organization$ReactHooksTemplate.emptyString ? true : !state.logo_edited && !state.name_edited;
  var alertType = state.notification;
  var partial_arg = state.client;
  return React.createElement("div", undefined, React.createElement("div", {
                  className: "flex justify-between px-5 py-6 border-b border-gray-300 text-darkcharcoal font-bold text-xl"
                }, "Organization Profile", alertType !== undefined ? React.createElement(AlertComponent$ReactHooksTemplate.make, {
                        alertType: alertType,
                        handleClose: (function (param) {
                            return Curry._1(setState, (function (state) {
                                          return {
                                                  logo_edited: state.logo_edited,
                                                  name_edited: state.name_edited,
                                                  client: state.client,
                                                  showUploadInputField: state.showUploadInputField,
                                                  logoUrl: state.logoUrl,
                                                  logoFile: state.logoFile,
                                                  notification: undefined
                                                };
                                        }));
                          })
                      }) : React.createElement(React.Fragment, undefined), React.createElement("button", {
                      className: enableUpdate ? "border text-gray-400 rounded p-2 text-sm disabled-input" : "border rounded p-2 text-sm text-darkcharcoal\n          bg-white",
                      disabled: enableUpdate,
                      onClick: saveClientDetails
                    }, "Update")), React.createElement("div", {
                  className: "p-8"
                }, React.createElement("div", undefined, React.createElement("div", {
                          className: "text-base font-bold"
                        }, "Organization Details"), React.createElement("div", {
                          className: "mt-8"
                        }, React.createElement("div", {
                              className: "w-2/3 mb-3"
                            }, React.createElement(TextInputNew$ReactHooksTemplate.make, {
                                  label: "Organization Name",
                                  value: Organization$ReactHooksTemplate.name(state.client),
                                  handleChange: (function (param) {
                                      var name = param.target.value;
                                      var newClient = Organization$ReactHooksTemplate.setName(partial_arg, name);
                                      return Curry._1(setState, (function (state) {
                                                    return {
                                                            logo_edited: state.logo_edited,
                                                            name_edited: true,
                                                            client: newClient,
                                                            showUploadInputField: state.showUploadInputField,
                                                            logoUrl: state.logoUrl,
                                                            logoFile: state.logoFile,
                                                            notification: state.notification
                                                          };
                                                  }));
                                    }),
                                  labelCSS: "text-base font-medium",
                                  placeholder: "Type the name of the Company"
                                }), Organization$ReactHooksTemplate.name(state.client) === Organization$ReactHooksTemplate.emptyString ? React.createElement("div", {
                                    className: "my-2"
                                  }, React.createElement("p", {
                                        className: "text-red-500 text-xs float-right"
                                      }, "Name can't be empty")) : React.createElement(React.Fragment, undefined))))), React.createElement("div", {
                  className: "p-8"
                }, React.createElement("div", {
                      className: "mt-4"
                    }, React.createElement("div", {
                          className: "w-2/3 mb-3"
                        }, React.createElement("div", {
                              className: "flex justify-between items-center"
                            }, React.createElement("div", {
                                  className: "text-base font-medium"
                                }, "Organization Logo"), React.createElement("div", {
                                  className: "bg-ghostwhitegray\n                          items-center rounded-lg\n                          border border-gray-300 w-3/5 p-8"
                                }, state.showUploadInputField ? React.createElement("div", {
                                        className: "flex items-center justify-between h-128-px"
                                      }, React.createElement("input", {
                                            className: "h-10 w-3/4 block border px-3 py-2 border-gray-400 \n                                 bg-white rounded capitalize text-sm cursor-pointer",
                                            accept: ".png",
                                            type: "file",
                                            onChange: handleLogoChange
                                          }), React.createElement("button", {
                                            className: state.logo_edited ? "border rounded p-2 text-sm text-darkcharcoal\n                        bg-white" : "border text-gray-400 rounded p-2 text-sm",
                                            disabled: !state.logo_edited,
                                            onClick: revertLogoChanges
                                          }, "Revert")) : React.createElement("div", {
                                        className: "flex items-center justify-between h-128-px"
                                      }, React.createElement("div", {
                                            className: "flex justify-center w-full"
                                          }, React.createElement("img", {
                                                className: "max-h-100-px",
                                                src: Belt_Option.getWithDefault(state.logoUrl, "#")
                                              })), React.createElement("div", {
                                            className: "flex gap-1"
                                          }, React.createElement("button", {
                                                className: "round border text-sm bg-ghostwhite\n                          border-gray-400 p-2 rounded",
                                                onClick: showUploadInputField
                                              }, "Change"), React.createElement("button", {
                                                className: state.logo_edited ? "border rounded p-2 text-sm text-darkcharcoal\n                          bg-white" : "border text-gray-400 rounded p-2 text-sm",
                                                disabled: !state.logo_edited,
                                                onClick: revertLogoChanges
                                              }, "Revert")))))))));
}

var make = OrganisationProfile;

exports.make = make;
/* react Not a pure module */

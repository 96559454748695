// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var List = require("rescript/lib/js/list.js");
var $$Array = require("rescript/lib/js/array.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Js_dict = require("rescript/lib/js/js_dict.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Querystringify = require("querystringify");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var Http$ReactHooksTemplate = require("../common/http.bs.js");
var Image$ReactHooksTemplate = require("../common/image.bs.js");
var Filters$ReactHooksTemplate = require("../dashboard/filters/filters.bs.js");

function emptyState(param) {
  return {
          category: "",
          score: 0.0,
          total_triggers: 0,
          response_percentage: 0.0,
          promoter: undefined,
          detractor: undefined,
          passive: undefined,
          promoter_percentage: undefined,
          detractor_percentage: undefined,
          passive_percentage: undefined,
          total: 0
        };
}

function category(t) {
  return t.category;
}

function score(t) {
  return t.score;
}

function promoter(t) {
  return t.promoter;
}

function passive(t) {
  return t.passive;
}

function promoter_percentage(t) {
  return t.promoter_percentage;
}

function detractor_percentage(t) {
  return t.detractor_percentage;
}

function passive_percentage(t) {
  return t.passive_percentage;
}

function detractor(t) {
  return t.detractor;
}

function total(t) {
  return t.total;
}

function total_triggers(t) {
  return t.total_triggers;
}

function response_percentage(t) {
  return t.response_percentage;
}

function decodeDownloadReport(json) {
  return Json_decode.field("file_name", Json_decode.string, json);
}

function decode(json) {
  return {
          category: Json_decode.field("category", Json_decode.string, json),
          score: Json_decode.field("score", Json_decode.$$float, json),
          total_triggers: Json_decode.field("total_triggers", Json_decode.$$int, json),
          response_percentage: Json_decode.field("response_percentage", Json_decode.$$float, json),
          promoter: Json_decode.optional((function (param) {
                  return Json_decode.field("promoter", Json_decode.$$int, param);
                }), json),
          detractor: Json_decode.optional((function (param) {
                  return Json_decode.field("detractor", Json_decode.$$int, param);
                }), json),
          passive: Json_decode.optional((function (param) {
                  return Json_decode.field("passive", Json_decode.$$int, param);
                }), json),
          promoter_percentage: Json_decode.optional((function (param) {
                  return Json_decode.field("promoter_percentage", Json_decode.$$float, param);
                }), json),
          detractor_percentage: Json_decode.optional((function (param) {
                  return Json_decode.field("detractor_percentage", Json_decode.$$float, param);
                }), json),
          passive_percentage: Json_decode.optional((function (param) {
                  return Json_decode.field("passive_percentage", Json_decode.$$float, param);
                }), json),
          total: Json_decode.field("total", Json_decode.$$int, json)
        };
}

var Report = {
  emptyState: emptyState,
  category: category,
  score: score,
  promoter: promoter,
  passive: passive,
  promoter_percentage: promoter_percentage,
  detractor_percentage: detractor_percentage,
  passive_percentage: passive_percentage,
  detractor: detractor,
  total: total,
  total_triggers: total_triggers,
  response_percentage: response_percentage,
  decodeDownloadReport: decodeDownloadReport,
  decode: decode
};

var emptyReports = emptyState(undefined);

var initialState = {
  loading: true,
  reports: emptyReports,
  toggleFilter: false
};

function reducer(state, x) {
  if (typeof x === "number") {
    if (x !== 0) {
      return {
              loading: state.loading,
              reports: state.reports,
              toggleFilter: !state.toggleFilter
            };
    } else {
      return {
              loading: true,
              reports: state.reports,
              toggleFilter: state.toggleFilter
            };
    }
  } else {
    return {
            loading: false,
            reports: x._0,
            toggleFilter: state.toggleFilter
          };
  }
}

function DataFieldReport(Props) {
  var search = Props.search;
  var campaignId = Props.campaignId;
  var match = React.useReducer(reducer, initialState);
  var dispatch = match[1];
  var match$1 = React.useState(function () {
        return /* [] */0;
      });
  var setReports = match$1[1];
  var queryParams = Querystringify.parse(search);
  var x = Js_dict.get(queryParams, "score_type");
  var score_type = x !== undefined ? x : "";
  var toggleFilter = function (param) {
    return Curry._1(dispatch, /* ToggleFilter */1);
  };
  var x$1 = Js_dict.get(queryParams, "filter_field_id");
  var filterFieldId = x$1 !== undefined ? x$1 : "";
  var url = "/api/v1/score_split?split_by_field_id=" + filterFieldId + "&campaign_id=" + campaignId + "&score_type=" + score_type;
  var fetchReport = function (param) {
    return Http$ReactHooksTemplate.fetchGetJSONDecode(url, (function (param) {
                  return Json_decode.field("data_points", (function (param) {
                                return Json_decode.list(decode, param);
                              }), param);
                }));
  };
  var onApplyFilter = function (param) {
    Curry._1(dispatch, /* ToggleFilter */1);
    Curry._1(dispatch, /* FetchReportsInProgress */0);
    fetchReport(undefined).then(function (reports) {
          return Promise.resolve(Curry._1(setReports, (function (param) {
                            return reports;
                          })));
        });
    
  };
  React.useEffect((function () {
          Http$ReactHooksTemplate.fetchGetJSONDecode(url, (function (param) {
                    return Json_decode.field("data_points", (function (param) {
                                  return Json_decode.list(decode, param);
                                }), param);
                  })).then(function (reports) {
                return Promise.resolve(Curry._1(setReports, (function (param) {
                                  return reports;
                                })));
              });
          
        }), []);
  var tmp = score_type === "csat" ? React.createElement("th", {
          className: "px-4 py-2"
        }, React.createElement("div", {
              className: "flex"
            }, "Csat Score", React.createElement("img", {
                  className: "ml-4-px",
                  src: Image$ReactHooksTemplate.heading
                }))) : React.createElement("th", {
          className: "px-4 py-2"
        }, React.createElement("div", {
              className: "flex"
            }, "Nps Score", React.createElement("img", {
                  className: "ml-4-px",
                  src: Image$ReactHooksTemplate.heading
                })));
  var tmp$1 = score_type === "csat" ? React.createElement(React.Fragment, undefined) : React.createElement(React.Fragment, undefined, React.createElement("th", {
              className: "px-4 py-2"
            }, React.createElement("div", {
                  className: "flex"
                }, "Promoter", React.createElement("img", {
                      className: "ml-4-px",
                      src: Image$ReactHooksTemplate.heading
                    }))), React.createElement("th", {
              className: "px-4 py-2"
            }, React.createElement("div", {
                  className: "flex"
                }, "Promoter %", React.createElement("img", {
                      className: "ml-4-px",
                      src: Image$ReactHooksTemplate.heading
                    }))), React.createElement("th", {
              className: "px-4 py-2"
            }, React.createElement("div", {
                  className: "flex"
                }, "Passive", React.createElement("img", {
                      className: "ml-4-px",
                      src: Image$ReactHooksTemplate.heading
                    }))), React.createElement("th", {
              className: "px-4 py-2"
            }, React.createElement("div", {
                  className: "flex"
                }, "Passive %", React.createElement("img", {
                      className: "ml-4-px",
                      src: Image$ReactHooksTemplate.heading
                    }))), React.createElement("th", {
              className: "px-4 py-2"
            }, React.createElement("div", {
                  className: "flex"
                }, "Detractor", React.createElement("img", {
                      className: "ml-4-px",
                      src: Image$ReactHooksTemplate.heading
                    }))), React.createElement("th", {
              className: "px-4 py-2"
            }, React.createElement("div", {
                  className: "flex"
                }, "Detractor %", React.createElement("img", {
                      className: "ml-4-px",
                      src: Image$ReactHooksTemplate.heading
                    }))));
  return React.createElement(React.Fragment, undefined, match[0].toggleFilter ? React.createElement(Filters$ReactHooksTemplate.make, {
                    onClose: toggleFilter,
                    onApply: onApplyFilter,
                    showDateFilter: false,
                    showFilterPills: false
                  }) : React.createElement(React.Fragment, undefined), React.createElement("div", undefined, React.createElement("div", {
                      className: "flex justify-between items-center p-20-px"
                    }, React.createElement("h2", {
                          className: "text-xl font-semibold"
                        }, "Category Wise Report"), React.createElement("div", {
                          className: "flex gap-4"
                        }, React.createElement("button", {
                              className: "bg-ghostwhite border border-gray-400\n            shadow-inner flex rounded py-2 px-3 text-xs",
                              id: "filters",
                              onClick: (function (param) {
                                  return Curry._1(dispatch, /* ToggleFilter */1);
                                })
                            }, React.createElement("img", {
                                  className: "h-14-px mr-2-px",
                                  src: Image$ReactHooksTemplate.filterButton
                                }), "Filters"), React.createElement("button", {
                              className: "bg-pigmentblue border border-gray-400\n              flex rounded py-2 px-3 text-xs text-white",
                              onClick: (function ($$event) {
                                  $$event.preventDefault();
                                  var download_url = "/api/v1/download_df_report?campaign_id=" + campaignId + "&split_by_field_id=" + filterFieldId + "&score_type=" + score_type;
                                  Http$ReactHooksTemplate.fetchGetJSONDecode(download_url, decodeDownloadReport).then(function (file_name) {
                                        return Promise.resolve((console.log(file_name), RescriptReactRouter.push("/campaigns/report_downloads/" + campaignId)));
                                      });
                                  
                                })
                            }, React.createElement("img", {
                                  className: "h-14-px mr-1 filter-white",
                                  src: Image$ReactHooksTemplate.download
                                }), "Download"))), React.createElement("div", {
                      className: "flex px-20-px"
                    }, React.createElement("table", {
                          className: "text-left table-auto w-full border-separate text-xs font-medium"
                        }, React.createElement("thead", {
                              className: "font-medium p-4 bg-ghostwhite text-aurometalsaurus"
                            }, React.createElement("tr", undefined, React.createElement("th", {
                                      className: "px-4 py-2"
                                    }, React.createElement("div", {
                                          className: "flex"
                                        }, "Category", React.createElement("img", {
                                              className: "ml-4-px",
                                              src: Image$ReactHooksTemplate.heading
                                            }))), tmp, React.createElement("th", {
                                      className: "px-4 py-2"
                                    }, React.createElement("div", {
                                          className: "flex"
                                        }, "Total Trigger", React.createElement("img", {
                                              className: "ml-4-px",
                                              src: Image$ReactHooksTemplate.heading
                                            }))), React.createElement("th", {
                                      className: "px-4 py-2"
                                    }, React.createElement("div", {
                                          className: "flex"
                                        }, "Total Responses", React.createElement("img", {
                                              className: "ml-4-px",
                                              src: Image$ReactHooksTemplate.heading
                                            }))), React.createElement("th", {
                                      className: "px-4 py-2"
                                    }, React.createElement("div", {
                                          className: "flex"
                                        }, "Response %", React.createElement("img", {
                                              className: "ml-4-px",
                                              src: Image$ReactHooksTemplate.heading
                                            }))), tmp$1)), React.createElement("tbody", undefined, $$Array.of_list(List.map((function (report) {
                                        var tmp;
                                        if (score_type === "csat") {
                                          tmp = React.createElement(React.Fragment, undefined);
                                        } else {
                                          var promoter = report.promoter;
                                          var promoter_percentage = report.promoter_percentage;
                                          var passive = report.passive;
                                          var passive_percentage = report.passive_percentage;
                                          var detractor = report.detractor;
                                          var detractor_percentage = report.detractor_percentage;
                                          tmp = React.createElement(React.Fragment, undefined, React.createElement("td", {
                                                    className: "px-4 py-2"
                                                  }, React.createElement("div", {
                                                        className: "flex"
                                                      }, promoter !== undefined ? String(promoter) : "")), React.createElement("td", {
                                                    className: "px-4 py-2"
                                                  }, React.createElement("div", {
                                                        className: "flex"
                                                      }, promoter_percentage !== undefined ? promoter_percentage.toString() : "")), React.createElement("td", {
                                                    className: "px-4 py-2"
                                                  }, React.createElement("div", {
                                                        className: "flex"
                                                      }, passive !== undefined ? String(passive) : "")), React.createElement("td", {
                                                    className: "px-4 py-2"
                                                  }, React.createElement("div", {
                                                        className: "flex"
                                                      }, passive_percentage !== undefined ? passive_percentage.toString() : "")), React.createElement("td", {
                                                    className: "px-4 py-2"
                                                  }, React.createElement("div", {
                                                        className: "flex"
                                                      }, detractor !== undefined ? String(detractor) : "")), React.createElement("td", {
                                                    className: "px-4 py-2"
                                                  }, React.createElement("div", {
                                                        className: "flex"
                                                      }, detractor_percentage !== undefined ? detractor_percentage.toString() : "")));
                                        }
                                        return React.createElement("tr", {
                                                    className: "border-b border-bottom-gray-600"
                                                  }, React.createElement("td", {
                                                        className: "px-4 py-2"
                                                      }, report.category), React.createElement("td", {
                                                        className: "px-4 py-2"
                                                      }, report.score.toString()), React.createElement("td", {
                                                        className: "px-4 py-2"
                                                      }, String(report.total_triggers)), React.createElement("td", {
                                                        className: "px-4 py-2"
                                                      }, String(report.total)), React.createElement("td", {
                                                        className: "px-4 py-2"
                                                      }, report.response_percentage.toString()), tmp);
                                      }), match$1[0])))))));
}

var emptyReport = /* [] */0;

var make = DataFieldReport;

exports.emptyReport = emptyReport;
exports.Report = Report;
exports.emptyReports = emptyReports;
exports.initialState = initialState;
exports.reducer = reducer;
exports.make = make;
/* emptyReports Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Campaign$ReactHooksTemplate = require("../surveys/campaign.bs.js");
var SurveyDetail$ReactHooksTemplate = require("../surveys/surveyDetail.bs.js");

function survey(t) {
  return t.survey;
}

function campaigns(t) {
  return t.campaigns;
}

function empty(param) {
  return {
          survey: undefined,
          campaigns: /* [] */0
        };
}

function setSurvey(survey, t) {
  return {
          survey: survey,
          campaigns: t.campaigns
        };
}

function setCampaigns(campaigns, t) {
  return {
          survey: t.survey,
          campaigns: campaigns
        };
}

function decode(json) {
  return {
          survey: Json_decode.field("survey", (function (param) {
                  return Json_decode.optional(SurveyDetail$ReactHooksTemplate.decode, param);
                }), json),
          campaigns: Json_decode.field("campaigns", (function (param) {
                  return Json_decode.list(Campaign$ReactHooksTemplate.decode, param);
                }), json)
        };
}

var templateType = "ResponseReport";

exports.survey = survey;
exports.campaigns = campaigns;
exports.empty = empty;
exports.templateType = templateType;
exports.setSurvey = setSurvey;
exports.setCampaigns = setCampaigns;
exports.decode = decode;
/* No side effect */

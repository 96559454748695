// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var List = require("rescript/lib/js/list.js");
var $$Array = require("rescript/lib/js/array.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Js_json = require("rescript/lib/js/js_json.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Http$ReactHooksTemplate = require("../../common/http.bs.js");
var Image$ReactHooksTemplate = require("../../common/image.bs.js");
var Utils$ReactHooksTemplate = require("../../common/utils.bs.js");
var ChartId$ReactHooksTemplate = require("./chartId.bs.js");
var ChartComponent$ReactHooksTemplate = require("./ChartComponent.bs.js");
var InsightsFilters$ReactHooksTemplate = require("../filters/InsightsFilters.bs.js");

function reducer(state, x) {
  if (typeof x === "number") {
    switch (x) {
      case /* FetchFiltersInProgress */0 :
          return {
                  loadingProduct: true,
                  loadingVoc: true,
                  loadingCorrelationData: state.loadingCorrelationData,
                  productCharts: state.productCharts,
                  vocCharts: state.vocCharts,
                  toggleFilter: state.toggleFilter,
                  filterPills: state.filterPills,
                  filters: state.filters,
                  currentFilter: state.currentFilter,
                  correlationData: state.correlationData
                };
      case /* ToggleFilter */1 :
          return {
                  loadingProduct: state.loadingProduct,
                  loadingVoc: state.loadingVoc,
                  loadingCorrelationData: state.loadingCorrelationData,
                  productCharts: state.productCharts,
                  vocCharts: state.vocCharts,
                  toggleFilter: !state.toggleFilter,
                  filterPills: state.filterPills,
                  filters: state.filters,
                  currentFilter: state.currentFilter,
                  correlationData: state.correlationData
                };
      case /* FetchProductChartsInProgress */2 :
      case /* FetchVocChartsInProgress */3 :
          break;
      case /* FetchCorrelationDataInProgress */4 :
          return {
                  loadingProduct: state.loadingProduct,
                  loadingVoc: state.loadingVoc,
                  loadingCorrelationData: true,
                  productCharts: state.productCharts,
                  vocCharts: state.vocCharts,
                  toggleFilter: state.toggleFilter,
                  filterPills: state.filterPills,
                  filters: state.filters,
                  currentFilter: state.currentFilter,
                  correlationData: state.correlationData
                };
      
    }
  } else {
    switch (x.TAG | 0) {
      case /* FetchedCorrelationData */0 :
          return {
                  loadingProduct: state.loadingProduct,
                  loadingVoc: state.loadingVoc,
                  loadingCorrelationData: state.loadingCorrelationData,
                  productCharts: state.productCharts,
                  vocCharts: state.vocCharts,
                  toggleFilter: state.toggleFilter,
                  filterPills: state.filterPills,
                  filters: state.filters,
                  currentFilter: state.currentFilter,
                  correlationData: x._0
                };
      case /* FetchProductChartsCompleted */1 :
          return {
                  loadingProduct: false,
                  loadingVoc: state.loadingVoc,
                  loadingCorrelationData: state.loadingCorrelationData,
                  productCharts: x._0,
                  vocCharts: state.vocCharts,
                  toggleFilter: state.toggleFilter,
                  filterPills: state.filterPills,
                  filters: state.filters,
                  currentFilter: state.currentFilter,
                  correlationData: state.correlationData
                };
      case /* SetFiltersAndPills */2 :
          return {
                  loadingProduct: true,
                  loadingVoc: true,
                  loadingCorrelationData: true,
                  productCharts: state.productCharts,
                  vocCharts: state.vocCharts,
                  toggleFilter: state.toggleFilter,
                  filterPills: x._1,
                  filters: x._0,
                  currentFilter: state.currentFilter,
                  correlationData: state.correlationData
                };
      case /* SetFilters */3 :
          return {
                  loadingProduct: state.loadingProduct,
                  loadingVoc: state.loadingVoc,
                  loadingCorrelationData: state.loadingCorrelationData,
                  productCharts: state.productCharts,
                  vocCharts: state.vocCharts,
                  toggleFilter: state.toggleFilter,
                  filterPills: state.filterPills,
                  filters: x._0,
                  currentFilter: state.currentFilter,
                  correlationData: state.correlationData
                };
      case /* FetchVocChartsCompleted */4 :
          return {
                  loadingProduct: state.loadingProduct,
                  loadingVoc: false,
                  loadingCorrelationData: state.loadingCorrelationData,
                  productCharts: state.productCharts,
                  vocCharts: x._0,
                  toggleFilter: state.toggleFilter,
                  filterPills: state.filterPills,
                  filters: state.filters,
                  currentFilter: state.currentFilter,
                  correlationData: state.correlationData
                };
      
    }
  }
  return {
          loadingProduct: true,
          loadingVoc: state.loadingVoc,
          loadingCorrelationData: state.loadingCorrelationData,
          productCharts: state.productCharts,
          vocCharts: state.vocCharts,
          toggleFilter: state.toggleFilter,
          filterPills: state.filterPills,
          filters: state.filters,
          currentFilter: state.currentFilter,
          correlationData: state.correlationData
        };
}

var initialState_correlationData = {
  categories: /* [] */0,
  data: null
};

var initialState = {
  loadingProduct: true,
  loadingVoc: true,
  loadingCorrelationData: true,
  productCharts: undefined,
  vocCharts: undefined,
  toggleFilter: false,
  filterPills: /* [] */0,
  filters: /* [] */0,
  currentFilter: 0,
  correlationData: initialState_correlationData
};

var style = {
  lineWidth: 1,
  stroke: "#fff"
};

function Insights(Props) {
  var match = React.useReducer(reducer, initialState);
  var dispatch = match[1];
  var state = match[0];
  var fetchVisualizations = function (param) {
    Http$ReactHooksTemplate.fetchGetJSONDecode("/api/v1/insights/visualizations?session=product", ChartId$ReactHooksTemplate.fromJson).then(function (xs) {
            console.log(xs);
            return Promise.resolve(xs);
          }).then(function (xs) {
          return Promise.resolve(setTimeout((function (param) {
                              return Curry._1(dispatch, {
                                          TAG: /* FetchProductChartsCompleted */1,
                                          _0: xs
                                        });
                            }), Utils$ReactHooksTemplate.timeout)).then(function (param) {
                      return Http$ReactHooksTemplate.fetchGetJSONDecode("/api/v1/insights/visualizations?session=voc", ChartId$ReactHooksTemplate.fromJson).then(function (xs) {
                                    console.log(xs);
                                    return Promise.resolve(xs);
                                  }).then(function (xs) {
                                  return Promise.resolve(Promise.resolve((setTimeout((function (param) {
                                                            return Curry._1(dispatch, {
                                                                        TAG: /* FetchVocChartsCompleted */4,
                                                                        _0: xs
                                                                      });
                                                          }), Utils$ReactHooksTemplate.timeout), undefined)).then(function (param) {
                                                  return Http$ReactHooksTemplate.fetchGetJSONDecode("/api/v1/insights/correlation_data", (function (json) {
                                                                    return {
                                                                            categories: Json_decode.field("categories", (function (param) {
                                                                                    return Json_decode.list(Json_decode.string, param);
                                                                                  }), json),
                                                                            data: Belt_Option.getWithDefault(Js_json.decodeObject(json), {})["data"]
                                                                          };
                                                                  })).then(function (xs) {
                                                                console.log(xs);
                                                                return Promise.resolve(xs);
                                                              }).then(function (xs) {
                                                              return Promise.resolve((setTimeout((function (param) {
                                                                                  return Curry._1(dispatch, {
                                                                                              TAG: /* FetchedCorrelationData */0,
                                                                                              _0: xs
                                                                                            });
                                                                                }), Utils$ReactHooksTemplate.timeout), undefined));
                                                            });
                                                }));
                                });
                    });
        });
    
  };
  React.useEffect((function () {
          fetchVisualizations(undefined);
          
        }), []);
  var onApplyFilter = function (param) {
    fetchVisualizations(undefined);
    
  };
  var toggleFilter = function (param) {
    Curry._1(dispatch, /* ToggleFilter */1);
    return Curry._1(dispatch, {
                TAG: /* SetFilters */3,
                _0: state.filters
              });
  };
  var tmp;
  if (state.loadingProduct) {
    tmp = React.createElement("div", undefined, React.createElement("p", undefined, "Loading..."));
  } else {
    var xs = state.productCharts;
    tmp = xs !== undefined ? $$Array.of_list(List.map((function (chartId) {
                  return React.createElement(ChartComponent$ReactHooksTemplate.make, {
                              chartId: chartId,
                              drillDownChartDetails: undefined,
                              onClick: (function (param, param$1, param$2) {
                                  
                                }),
                              onEnlarge: (function (param) {
                                  
                                }),
                              insight: true,
                              key: String(ChartId$ReactHooksTemplate.id(chartId))
                            });
                }), xs)) : React.createElement(React.Fragment, undefined);
  }
  var tmp$1;
  if (state.loadingVoc) {
    tmp$1 = React.createElement("div", undefined, React.createElement("p", undefined, "Loading..."));
  } else {
    var xs$1 = state.vocCharts;
    tmp$1 = xs$1 !== undefined ? $$Array.of_list(List.map((function (chartId) {
                  return React.createElement(ChartComponent$ReactHooksTemplate.make, {
                              chartId: chartId,
                              drillDownChartDetails: undefined,
                              onClick: (function (param, param$1, param$2) {
                                  
                                }),
                              onEnlarge: (function (param) {
                                  
                                }),
                              insight: true,
                              key: String(ChartId$ReactHooksTemplate.id(chartId)) + "5"
                            });
                }), xs$1)) : React.createElement(React.Fragment, undefined);
  }
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  className: "p-20-px bg-porcelain"
                }, state.toggleFilter ? React.createElement(InsightsFilters$ReactHooksTemplate.make, {
                        onClose: toggleFilter,
                        onApply: onApplyFilter,
                        showDateFilter: false,
                        showFilterPills: false,
                        correlation: false
                      }) : React.createElement(React.Fragment, undefined), React.createElement("div", {
                      className: "px-16-px flex justify-between pb-20-px"
                    }, React.createElement("div", {
                          className: "flex flex-wrap gap-2"
                        }, React.createElement("div", {
                              className: "flex items-center font-normal text-sm h-8"
                            }, "Active Filters"), React.createElement(InsightsFilters$ReactHooksTemplate.make, {
                              onClose: toggleFilter,
                              onApply: onApplyFilter,
                              showDateFilter: false,
                              showFilterPills: true,
                              correlation: false
                            })), React.createElement("div", {
                          className: "float-right"
                        }, React.createElement("button", {
                              className: "bg-ghostwhite border border-gray-400\n                              shadow-inner flex rounded py-2 px-3 text-xs",
                              id: "filters",
                              onClick: (function (param) {
                                  return toggleFilter(undefined);
                                })
                            }, React.createElement("img", {
                                  className: "h-14-px mr-2-px",
                                  src: Image$ReactHooksTemplate.filterButton
                                }), "Filters"))), React.createElement("div", {
                      className: "flex flex-col"
                    }, React.createElement("div", {
                          className: "w-full border mb-2 bg-white h-10"
                        }, React.createElement("div", {
                              className: "flex items-center"
                            }, React.createElement("div", {
                                  className: "w-3 bg-red-600 h-10 mr-2"
                                }), React.createElement("p", {
                                  className: "text-lg font-extrabold"
                                }, "PRODUCT / SERVICE IMPACT"))), React.createElement("div", {
                          className: "flex flex-wrap"
                        }, tmp)), React.createElement("div", {
                      className: "flex flex-col"
                    }, React.createElement("div", {
                          className: "w-full border mb-2 bg-white h-10"
                        }, React.createElement("div", {
                              className: "flex items-center mr-16"
                            }, React.createElement("div", {
                                  className: "w-3 bg-red-600 h-10 mr-2"
                                }), React.createElement("p", {
                                  className: "text-lg font-extrabold"
                                }, "VOC IMPACT"))), React.createElement("div", {
                          className: "flex flex-wrap"
                        }, tmp$1))));
}

var make = Insights;

exports.reducer = reducer;
exports.initialState = initialState;
exports.style = style;
exports.make = make;
/* initialState Not a pure module */

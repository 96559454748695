// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var $$Array = require("rescript/lib/js/array.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Belt_List = require("rescript/lib/js/belt_List.js");
var ListFilter$ReactHooksTemplate = require("./listFilter.bs.js");
var CheckboxInput$ReactHooksTemplate = require("../../common/CheckboxInput.bs.js");

function ListFilterForm(Props) {
  var filter = Props.filter;
  var onChange = Props.onChange;
  return React.createElement("label", undefined, React.createElement("div", undefined, $$Array.of_list(Belt_List.mapWithIndex(ListFilter$ReactHooksTemplate.getList(filter), (function (i, xs) {
                            return React.createElement(CheckboxInput$ReactHooksTemplate.make, {
                                        checked: Belt_List.has(ListFilter$ReactHooksTemplate.selected(filter), xs, (function (x, y) {
                                                return x === y;
                                              })),
                                        label: xs,
                                        handleChange: (function (param) {
                                            return Curry._1(onChange, {
                                                        TAG: /* List */2,
                                                        _0: ListFilter$ReactHooksTemplate.setSelected(xs, filter)
                                                      });
                                          }),
                                        className: "items-center mb-1",
                                        checkboxClassName: "mt-0 custom_checkbox",
                                        labelClassName: "text-sm text-darkcharcoal leading-normal whitespace-normal",
                                        key: String(i)
                                      });
                          })))));
}

var make = ListFilterForm;

exports.make = make;
/* react Not a pure module */

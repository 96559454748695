// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var $$Array = require("rescript/lib/js/array.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Utils$ReactHooksTemplate = require("../common/utils.bs.js");
var RandomId$ReactHooksTemplate = require("./RandomId.bs.js");
var CheckboxInput$ReactHooksTemplate = require("../common/CheckboxInput.bs.js");
var MultipleSelect$ReactHooksTemplate = require("./MultipleSelect.bs.js");
var SurveyLanguage$ReactHooksTemplate = require("./surveyLanguage.bs.js");
var MandatoryMessage$ReactHooksTemplate = require("./MandatoryMessage.bs.js");
var QuestionTextPreview$ReactHooksTemplate = require("./QuestionTextPreview.bs.js");

function MultipleSelectPreview(Props) {
  var multipleSelect = Props.multipleSelect;
  var surveyTheme = Props.surveyTheme;
  var language = Props.language;
  var mandatory = Props.mandatory;
  var onChange = Props.onChange;
  var matchChoicesWithAnswer = function (choice) {
    var xs = MultipleSelect$ReactHooksTemplate.getAnswer(multipleSelect);
    if (xs !== undefined) {
      return Belt_List.has(xs, choice, (function (xs, ys) {
                    return Caml_obj.caml_equal(MultipleSelect$ReactHooksTemplate.choiceId(xs), MultipleSelect$ReactHooksTemplate.choiceId(ys));
                  }));
    } else {
      return false;
    }
  };
  var onTitleChange = function ($$event) {
    return Curry._1(onChange, MultipleSelect$ReactHooksTemplate.setText(multipleSelect, Utils$ReactHooksTemplate.formTargetValue($$event)));
  };
  var onDescriptionChange = function ($$event) {
    return Curry._1(onChange, MultipleSelect$ReactHooksTemplate.setDescription(multipleSelect, Utils$ReactHooksTemplate.formTargetValue($$event)));
  };
  return React.createElement("div", {
              className: "w-full p-16"
            }, React.createElement(QuestionTextPreview$ReactHooksTemplate.make, {
                  text: MultipleSelect$ReactHooksTemplate.getTextTranslation(multipleSelect, SurveyLanguage$ReactHooksTemplate.name(language)),
                  description: MultipleSelect$ReactHooksTemplate.getDescriptionTranslation(multipleSelect, SurveyLanguage$ReactHooksTemplate.name(language)),
                  mandatory: mandatory,
                  surveyTheme: surveyTheme,
                  onTitleChange: onTitleChange,
                  onDescriptionChange: onDescriptionChange
                }), React.createElement("div", {
                  className: "flex flex-col"
                }, React.createElement("div", {
                      className: "text-aurometalsaurus text-sm mb-2 font-normal"
                    }, "Choices"), React.createElement("div", undefined, $$Array.of_list(Belt_List.map(MultipleSelect$ReactHooksTemplate.nonEmptyChoices(multipleSelect), (function (choice) {
                                return React.createElement(React.Fragment, undefined, React.createElement("div", {
                                                key: RandomId$ReactHooksTemplate.toString(MultipleSelect$ReactHooksTemplate.choiceId(choice)),
                                                className: "text-sm font-normal"
                                              }, React.createElement(CheckboxInput$ReactHooksTemplate.make, {
                                                    checked: matchChoicesWithAnswer(choice),
                                                    label: MultipleSelect$ReactHooksTemplate.getChoiceTextTranslation(multipleSelect, choice, SurveyLanguage$ReactHooksTemplate.name(language)),
                                                    handleChange: (function (param) {
                                                        return Curry._1(onChange, MultipleSelect$ReactHooksTemplate.setAnswer(multipleSelect, choice));
                                                      }),
                                                    className: "mb-2",
                                                    checkboxClassName: "custom_checkbox"
                                                  })), React.createElement("div", undefined, matchChoicesWithAnswer(choice) && MultipleSelect$ReactHooksTemplate.getAllowNonSpecifiedText(choice) ? React.createElement("input", {
                                                      className: "h-48-px py-2 px-4 bg-alicebluepurple border-b border-gray-500 focus:border-gray-600 text-xs w-full",
                                                      placeholder: "Type Here",
                                                      type: "text",
                                                      onChange: (function (param) {
                                                          return Curry._1(onChange, MultipleSelect$ReactHooksTemplate.setNotSpecifiedTextForAnswer(multipleSelect, MultipleSelect$ReactHooksTemplate.setNotSpecifiedText(Utils$ReactHooksTemplate.formTargetValue(param), choice)));
                                                        })
                                                    }) : React.createElement(React.Fragment, undefined)));
                              }))))), mandatory ? React.createElement(MandatoryMessage$ReactHooksTemplate.make, {
                    language: language
                  }) : React.createElement(React.Fragment, undefined));
}

var make = MultipleSelectPreview;

exports.make = make;
/* react Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var List = require("rescript/lib/js/list.js");
var $$Array = require("rescript/lib/js/array.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var Http$ReactHooksTemplate = require("../common/http.bs.js");
var Role$ReactHooksTemplate = require("./role.bs.js");
var Image$ReactHooksTemplate = require("../common/image.bs.js");
var Utils$ReactHooksTemplate = require("../common/utils.bs.js");
var Loading$ReactHooksTemplate = require("../dashboard/dashboard/Loading.bs.js");
var AuthProvider$ReactHooksTemplate = require("../AuthProvider.bs.js");
var CheckboxInput$ReactHooksTemplate = require("../common/CheckboxInput.bs.js");
var AlertComponent$ReactHooksTemplate = require("../common/AlertComponent.bs.js");
var CheckboxStatus$ReactHooksTemplate = require("./checkboxStatus.bs.js");
var PermissionTree$ReactHooksTemplate = require("./permissionTree.bs.js");

function fetchPermissions(param) {
  return Http$ReactHooksTemplate.fetchGetJSONDecode("/api/v1/permissions", PermissionTree$ReactHooksTemplate.decode);
}

function getInputValue(x) {
  return x._0;
}

function getValidationError(x) {
  switch (x.TAG | 0) {
    case /* Invalid */1 :
        return x._1;
    case /* Valid */0 :
    case /* NotValidated */2 :
        return /* [] */0;
    
  }
}

function RoleCreate(Props) {
  var role = Props.role;
  var initialState = role !== undefined ? ({
        role: Caml_option.valFromOption(role),
        action: /* Update */1,
        notification: undefined,
        nameError: undefined,
        permissionError: undefined
      }) : ({
        role: Role$ReactHooksTemplate.empty(undefined),
        action: /* Create */0,
        notification: undefined,
        nameError: undefined,
        permissionError: undefined
      });
  var match = React.useState(function () {
        return initialState;
      });
  var setState = match[1];
  var state = match[0];
  var match$1 = React.useContext(AuthProvider$ReactHooksTemplate.authContext);
  React.useEffect((function () {
          if (state.action === /* Create */0) {
            Http$ReactHooksTemplate.fetchGetJSONDecode("/api/v1/permissions", PermissionTree$ReactHooksTemplate.decode).then(function (permissions) {
                  var updatedRole = Role$ReactHooksTemplate.setPermissions(state.role, permissions);
                  return Promise.resolve(Curry._1(setState, (function (state) {
                                    return {
                                            role: updatedRole,
                                            action: state.action,
                                            notification: state.notification,
                                            nameError: state.nameError,
                                            permissionError: state.permissionError
                                          };
                                  })));
                });
          }
          
        }), []);
  var toggleCheckboxState = function (name) {
    var newRole = Role$ReactHooksTemplate.setPermissions(state.role, Belt_List.map(Role$ReactHooksTemplate.permissions(state.role), (function (param) {
                return PermissionTree$ReactHooksTemplate.toggle(name, param);
              })));
    var permissionValidation = List.length(Role$ReactHooksTemplate.enabledCapabilities(newRole)) === 0 ? ({
          hd: "can't be blank",
          tl: /* [] */0
        }) : undefined;
    return Curry._1(setState, (function (state) {
                  return {
                          role: newRole,
                          action: state.action,
                          notification: state.notification,
                          nameError: state.nameError,
                          permissionError: permissionValidation
                        };
                }));
  };
  var capabilityCheckBoxItem = function (capability, status) {
    return React.createElement("div", {
                key: capability,
                className: "pl-5"
              }, React.createElement(CheckboxInput$ReactHooksTemplate.make, {
                    checked: CheckboxStatus$ReactHooksTemplate.toBool(status),
                    label: capability,
                    handleChange: (function (param) {
                        return toggleCheckboxState(capability);
                      })
                  }));
  };
  var groupCheckboxTree = function (group, status, children) {
    return React.createElement("div", {
                key: group
              }, React.createElement(CheckboxInput$ReactHooksTemplate.make, {
                    checked: CheckboxStatus$ReactHooksTemplate.toBool(status),
                    label: group,
                    handleChange: (function (param) {
                        return toggleCheckboxState(group);
                      }),
                    indeterminate: CheckboxStatus$ReactHooksTemplate.isIndeterminate(status)
                  }), React.createElement("div", {
                    className: "pl-5"
                  }, Belt_List.toArray(children)));
  };
  var permissionCheckboxTree = function (permissionTree) {
    return PermissionTree$ReactHooksTemplate.postOrder(capabilityCheckBoxItem, groupCheckboxTree, permissionTree);
  };
  var handleNameChange = function ($$event) {
    var value = Utils$ReactHooksTemplate.formTargetValue($$event).toLowerCase();
    return Curry._1(setState, (function (state) {
                  var updatedRole = Role$ReactHooksTemplate.setName(state.role, value);
                  var nameValidation = Role$ReactHooksTemplate.name(updatedRole) === Role$ReactHooksTemplate.emptyString ? ({
                        hd: "name can't be empty",
                        tl: /* [] */0
                      }) : undefined;
                  return {
                          role: updatedRole,
                          action: state.action,
                          notification: state.notification,
                          nameError: nameValidation,
                          permissionError: state.permissionError
                        };
                }));
  };
  var handleDescriptionChange = function ($$event) {
    var value = Utils$ReactHooksTemplate.formTargetValue($$event);
    return Curry._1(setState, (function (state) {
                  var updatedRole = Role$ReactHooksTemplate.setDescription(state.role, value);
                  return {
                          role: updatedRole,
                          action: state.action,
                          notification: state.notification,
                          nameError: state.nameError,
                          permissionError: state.permissionError
                        };
                }));
  };
  var handleSubmit = function ($$event) {
    $$event.preventDefault();
    var encodedRole = Role$ReactHooksTemplate.encode(state.role);
    var roleId = Role$ReactHooksTemplate.id(state.role);
    var params = {};
    params["role"] = encodedRole;
    var match = state.action;
    var submitRole = match ? Http$ReactHooksTemplate.fetchPutJSON("/api/v1/roles/" + String(roleId), params) : Http$ReactHooksTemplate.fetchPostJSON("/api/v1/roles", params);
    submitRole.then(function (response) {
          var status = Json_decode.field("status", (function (param) {
                  return Json_decode.withDefault("failure", Json_decode.string, param);
                }), response);
          if (status === "success") {
            Curry._1(setState, (function (state) {
                    return {
                            role: state.role,
                            action: state.action,
                            notification: {
                              TAG: /* Success */1,
                              _0: "Updated the role"
                            },
                            nameError: undefined,
                            permissionError: undefined
                          };
                  }));
            setTimeout((function (param) {
                    return RescriptReactRouter.push("/roles/all");
                  }), 1000);
            window.scrollTo(0.0, 0.0);
          } else {
            var nameError = Json_decode.field("errors", (function (param) {
                    return Json_decode.optional((function (param) {
                                  return Json_decode.field("name", (function (param) {
                                                return Json_decode.list(Json_decode.string, param);
                                              }), param);
                                }), param);
                  }), response);
            var permissionError = Json_decode.field("errors", (function (param) {
                    return Json_decode.optional((function (param) {
                                  return Json_decode.field("permissions", (function (param) {
                                                return Json_decode.list(Json_decode.string, param);
                                              }), param);
                                }), param);
                  }), response);
            Curry._1(setState, (function (state) {
                    return {
                            role: state.role,
                            action: state.action,
                            notification: {
                              TAG: /* Failure */2,
                              _0: "Something went wrong please contact admin"
                            },
                            nameError: nameError,
                            permissionError: permissionError
                          };
                  }));
            window.scrollTo(0.0, 0.0);
          }
          return Promise.resolve(undefined);
        });
    
  };
  var permissionTree = Belt_List.toArray(Belt_List.map(Role$ReactHooksTemplate.permissions(state.role), permissionCheckboxTree));
  var isFormNotValid = function (state) {
    var errors = state.nameError;
    var nameErrorCount = errors !== undefined ? List.length(errors) : 0;
    if (Role$ReactHooksTemplate.name(state.role) === Role$ReactHooksTemplate.emptyString || List.length(Role$ReactHooksTemplate.enabledCapabilities(state.role)) === 0) {
      return true;
    } else {
      return nameErrorCount > 0;
    }
  };
  var alertType = state.notification;
  var tmp;
  if (Curry._1(match$1[0], /* AddRole */13)) {
    var match$2 = state.action;
    var xs = state.nameError;
    var xs$1 = state.permissionError;
    var match$3 = state.action;
    tmp = React.createElement("div", {
          className: "p-30-px"
        }, React.createElement("h1", {
              className: "text-32-px mb-30-px"
            }, match$2 ? React.createElement("div", undefined, "Update Role") : React.createElement("div", undefined, "Add New Role")), React.createElement("form", {
              className: "p-4",
              onSubmit: handleSubmit
            }, React.createElement("div", {
                  className: "mb-30-px"
                }, React.createElement("label", {
                      className: "block mb-10-px"
                    }, React.createElement("p", {
                          className: "mb-10-px font-semibold"
                        }, "Name"), React.createElement("input", {
                          className: "border border-gray-500 hover:border-gray-700 focus:border-teal-500 outline-none w-400-px py-10-px px-20-px",
                          type: "text",
                          value: Role$ReactHooksTemplate.name(state.role),
                          onChange: handleNameChange
                        }), $$Array.of_list(xs !== undefined ? List.map((function (x) {
                                  return React.createElement("div", {
                                              className: "my-2"
                                            }, React.createElement("p", {
                                                  className: "text-red-500 text-xs float-left"
                                                }, x));
                                }), xs) : /* [] */0))), React.createElement("div", {
                  className: "mb-30-px"
                }, React.createElement("label", {
                      className: "block mb-10-px"
                    }, React.createElement("p", {
                          className: "mb-10-px font-semibold"
                        }, "Description"), React.createElement("input", {
                          className: "border border-gray-500 hover:border-gray-700 focus:border-teal-500 outline-none w-400-px py-10-px px-20-px",
                          type: "text",
                          value: Role$ReactHooksTemplate.description(state.role),
                          onChange: handleDescriptionChange
                        }))), React.createElement("div", {
                  className: "mb-30-px"
                }, React.createElement("label", {
                      className: "block mb-10-px"
                    }, React.createElement("p", {
                          className: "mb-10-px font-semibold"
                        }, "Permissions: "), $$Array.of_list(xs$1 !== undefined ? List.map((function (x) {
                                  return React.createElement("div", {
                                              className: "my-2 pb-10-px"
                                            }, React.createElement("p", {
                                                  className: "text-red-500 text-xs float-left"
                                                }, x));
                                }), xs$1) : /* [] */0), React.createElement("div", {
                          className: "ml-20-px"
                        }, permissionTree))), React.createElement("div", undefined, React.createElement("a", {
                      className: "text-dark border border-bg-black hover:bg-gray-200 py-6-px px-20-px rounded shadow mr-4",
                      href: "/roles/all"
                    }, "Cancel"), React.createElement("input", {
                      className: "text-white bg-primary hover:bg-teal-700\n                         py-6-px px-20-px rounded shadow " + (
                        isFormNotValid(state) ? "disabled-input" : "cursor-pointer"
                      ),
                      disabled: isFormNotValid(state),
                      type: "submit",
                      value: match$3 ? "Update Role" : "Add Role"
                    }))));
  } else {
    tmp = React.createElement("div", {
          className: "h-screen w-full animated fadeIn"
        }, React.createElement(Loading$ReactHooksTemplate.make, {
              img: Image$ReactHooksTemplate.unAuthorized,
              text: "401: Unauthorized!!",
              loading: false
            }));
  }
  return React.createElement("div", undefined, React.createElement("div", {
                  className: "bg-white w-full p-4 items-center"
                }, alertType !== undefined ? React.createElement(AlertComponent$ReactHooksTemplate.make, {
                        alertType: alertType,
                        handleClose: (function (param) {
                            return Curry._1(setState, (function (state) {
                                          return {
                                                  role: state.role,
                                                  action: state.action,
                                                  notification: undefined,
                                                  nameError: state.nameError,
                                                  permissionError: state.permissionError
                                                };
                                        }));
                          })
                      }) : React.createElement(React.Fragment, undefined)), tmp);
}

var make = RoleCreate;

exports.fetchPermissions = fetchPermissions;
exports.getInputValue = getInputValue;
exports.getValidationError = getValidationError;
exports.make = make;
/* react Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Utils$ReactHooksTemplate = require("./common/utils.bs.js");
var Domain$ReactHooksTemplate = require("./settings/domain.bs.js");

function empty(param) {
  return {
          firstName: "",
          lastName: "",
          email: Curry._1(Domain$ReactHooksTemplate.$$String.Email.empty, undefined),
          organization: "",
          password: "",
          passwordConfirmation: ""
        };
}

function setFirstName(firstName, t) {
  return {
          firstName: firstName,
          lastName: t.lastName,
          email: t.email,
          organization: t.organization,
          password: t.password,
          passwordConfirmation: t.passwordConfirmation
        };
}

function setLastName(lastName, t) {
  return {
          firstName: t.firstName,
          lastName: lastName,
          email: t.email,
          organization: t.organization,
          password: t.password,
          passwordConfirmation: t.passwordConfirmation
        };
}

function setEmail(email, t) {
  return {
          firstName: t.firstName,
          lastName: t.lastName,
          email: email,
          organization: t.organization,
          password: t.password,
          passwordConfirmation: t.passwordConfirmation
        };
}

function setOrgnaization(organization, t) {
  return {
          firstName: t.firstName,
          lastName: t.lastName,
          email: t.email,
          organization: organization,
          password: t.password,
          passwordConfirmation: t.passwordConfirmation
        };
}

function setPassword(password, t) {
  return {
          firstName: t.firstName,
          lastName: t.lastName,
          email: t.email,
          organization: t.organization,
          password: password,
          passwordConfirmation: t.passwordConfirmation
        };
}

function setPasswordConfirmation(passwordConfirmation, t) {
  return {
          firstName: t.firstName,
          lastName: t.lastName,
          email: t.email,
          organization: t.organization,
          password: t.password,
          passwordConfirmation: passwordConfirmation
        };
}

function firstName(t) {
  return t.firstName;
}

function lastName(t) {
  return t.lastName;
}

function email(t) {
  return t.email;
}

function organization(t) {
  return t.organization;
}

function password(t) {
  return t.password;
}

function passwordConfirmation(t) {
  return t.passwordConfirmation;
}

var SignUpParams = {
  empty: empty,
  setFirstName: setFirstName,
  setLastName: setLastName,
  setEmail: setEmail,
  setOrgnaization: setOrgnaization,
  setPassword: setPassword,
  setPasswordConfirmation: setPasswordConfirmation,
  firstName: firstName,
  lastName: lastName,
  email: email,
  organization: organization,
  password: password,
  passwordConfirmation: passwordConfirmation
};

function SignUp(Props) {
  var match = React.useState(function () {
        return empty(undefined);
      });
  var setState = match[1];
  var state = match[0];
  var handleFormInputChange = function ($$event) {
    var name = $$event.target.name;
    var value = $$event.target.value;
    switch (name) {
      case "organization" :
          return Curry._1(setState, (function (state) {
                        return {
                                firstName: state.firstName,
                                lastName: state.lastName,
                                email: state.email,
                                organization: value,
                                password: state.password,
                                passwordConfirmation: state.passwordConfirmation
                              };
                      }));
      case "user[email]" :
          return Curry._1(setState, (function (state) {
                        return {
                                firstName: state.firstName,
                                lastName: state.lastName,
                                email: value,
                                organization: state.organization,
                                password: state.password,
                                passwordConfirmation: state.passwordConfirmation
                              };
                      }));
      case "user[first_name]" :
          return Curry._1(setState, (function (state) {
                        return {
                                firstName: value,
                                lastName: state.lastName,
                                email: state.email,
                                organization: state.organization,
                                password: state.password,
                                passwordConfirmation: state.passwordConfirmation
                              };
                      }));
      case "user[last_name]" :
          return Curry._1(setState, (function (state) {
                        return {
                                firstName: state.firstName,
                                lastName: value,
                                email: state.email,
                                organization: state.organization,
                                password: state.password,
                                passwordConfirmation: state.passwordConfirmation
                              };
                      }));
      case "user[password]" :
          return Curry._1(setState, (function (state) {
                        return {
                                firstName: state.firstName,
                                lastName: state.lastName,
                                email: state.email,
                                organization: state.organization,
                                password: value,
                                passwordConfirmation: state.passwordConfirmation
                              };
                      }));
      case "user[password_confirmation]" :
          return Curry._1(setState, (function (state) {
                        return {
                                firstName: state.firstName,
                                lastName: state.lastName,
                                email: state.email,
                                organization: state.organization,
                                password: state.password,
                                passwordConfirmation: value
                              };
                      }));
      default:
        return ;
    }
  };
  var validateForm = function (param) {
    if (Caml_obj.caml_equal(state.email, Curry._1(Domain$ReactHooksTemplate.$$String.Email.empty, undefined)) || state.firstName === "" || state.lastName === "" || state.organization === "" || state.password === "" || state.passwordConfirmation === "") {
      return true;
    } else {
      return state.password !== state.passwordConfirmation;
    }
  };
  return React.createElement("div", undefined, React.createElement("form", {
                  action: "/api/v1/users/signup",
                  autoComplete: "off",
                  charSet: "UTF-8",
                  method: "post"
                }, React.createElement("input", {
                      name: "authenticity_token",
                      type: "hidden",
                      value: Utils$ReactHooksTemplate.csrfToken
                    }), React.createElement("div", {
                      className: "flex justify-between gap-8"
                    }, React.createElement("div", {
                          className: "mb-30-px w-1/2"
                        }, React.createElement("label", {
                              className: "block text-18-px mb-20-px",
                              name: "first_name"
                            }, "First Name"), React.createElement("input", {
                              className: "border border-gray-500 hover:border-gray-700 focus:border-teal-500\n              outline-none p-10-px w-full rounded",
                              id: "user_first_name",
                              autoComplete: "off",
                              name: "user[first_name]",
                              type: "text",
                              value: state.firstName,
                              onChange: handleFormInputChange
                            })), React.createElement("div", {
                          className: "mb-30-px w-1/2"
                        }, React.createElement("label", {
                              className: "block text-18-px mb-20-px",
                              name: "last_name"
                            }, "Last Name"), React.createElement("input", {
                              className: "border border-gray-500 hover:border-gray-700 focus:border-teal-500\n              outline-none p-10-px w-full rounded",
                              id: "user_last_name",
                              autoComplete: "off",
                              name: "user[last_name]",
                              type: "texst",
                              value: state.lastName,
                              onChange: handleFormInputChange
                            }))), React.createElement("div", {
                      className: "flex justify-between gap-8"
                    }, React.createElement("div", {
                          className: "mb-30-px w-1/2"
                        }, React.createElement("label", {
                              className: "block text-18-px mb-20-px",
                              name: "email"
                            }, "Email"), React.createElement("input", {
                              className: "border border-gray-500 hover:border-gray-700 focus:border-teal-500\n              outline-none p-10-px w-full rounded",
                              id: "user_email",
                              autoComplete: "off",
                              name: "user[email]",
                              type: "email",
                              value: Curry._1(Domain$ReactHooksTemplate.$$String.Email.show, state.email),
                              onChange: handleFormInputChange
                            })), React.createElement("div", {
                          className: "mb-30-px w-1/2"
                        }, React.createElement("label", {
                              className: "block text-18-px\n            mb-20-px",
                              name: "organization"
                            }, "Organization Name"), React.createElement("input", {
                              className: "border border-gray-500 hover:border-gray-700 focus:border-teal-500\n              outline-none p-10-px w-full rounded",
                              id: "user_organization",
                              name: "organization",
                              type: "organization",
                              value: state.organization,
                              onChange: handleFormInputChange
                            }))), React.createElement("div", {
                      className: "flex justify-between gap-8"
                    }, React.createElement("div", {
                          className: "mb-30-px w-1/2"
                        }, React.createElement("label", {
                              className: "block text-18-px mb-20-px",
                              name: "password"
                            }, "Password"), React.createElement("input", {
                              className: "border border-gray-500 hover:border-gray-700 focus:border-teal-500\n              outline-none p-10-px w-full rounded",
                              id: "user_password",
                              autoComplete: "off",
                              name: "user[password]",
                              type: "password",
                              value: state.password,
                              onChange: handleFormInputChange
                            })), React.createElement("div", {
                          className: "mb-30-px w-1/2"
                        }, React.createElement("label", {
                              className: "block text-18-px mb-20-px",
                              name: "password_confirmation"
                            }, "Confirm Password"), React.createElement("input", {
                              className: "border border-gray-500 hover:border-gray-700 focus:border-teal-500\n              outline-none p-10-px w-full rounded",
                              id: "user_password_confirmation",
                              autoComplete: "off",
                              name: "user[password_confirmation]",
                              type: "password",
                              value: state.passwordConfirmation,
                              onChange: handleFormInputChange
                            }))), React.createElement("div", {
                      className: "flex items-center w-full items-center mt-20-px"
                    }, React.createElement("div", {
                          className: "m-auto"
                        }, React.createElement("input", {
                              className: "text-white bg-teal-500 hover:bg-teal-700 py-10-px px-30-px rounded " + (
                                validateForm(undefined) ? "disabled-input" : "cursor-pointer"
                              ),
                              disabled: validateForm(undefined),
                              name: "commit",
                              type: "submit",
                              value: "Sign Up"
                            })))));
}

var make = SignUp;

exports.SignUpParams = SignUpParams;
exports.make = make;
/* react Not a pure module */

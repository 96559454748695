// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");

function UserListHeader(Props) {
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  className: "flex font-medium p-4 border border-gray-400 bg-ghostwhite text-aurometalsaurus rounded-t-lg "
                }, React.createElement("p", {
                      className: "w-1/4"
                    }, "Email"), React.createElement("p", {
                      className: "w-60-px mr-30-px"
                    }, "Status"), React.createElement("p", {
                      className: "w-1/4"
                    }, "Roles"), React.createElement("p", {
                      className: "w-1/4"
                    }, "Data Restrictions"), React.createElement("p", {
                      className: "w-1/4"
                    }, "Last SignIn At"), React.createElement("p", {
                      className: "w-1/4"
                    })));
}

var make = UserListHeader;

exports.make = make;
/* react Not a pure module */

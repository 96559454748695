// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");

function SmsChannel(Props) {
  return React.createElement("div", undefined, React.createElement("div", {
                  className: "px-5 py-6 border-b border-gray-300 text-darkcharcoal font-bold text-xl"
                }, "SMS"), React.createElement("div", {
                  className: "p-8"
                }));
}

var make = SmsChannel;

exports.make = make;
/* react Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var ToggleInput$ReactHooksTemplate = require("../common/ToggleInput.bs.js");

function ToggleMandatory(Props) {
  var mandatory = Props.mandatory;
  var onMandatoryChange = Props.onMandatoryChange;
  return React.createElement("div", undefined, React.createElement("div", {
                  className: "mb-4 pb-4 border-b border-gray-300"
                }, React.createElement("div", {
                      className: "flex justify-between"
                    }, React.createElement("div", {
                          className: "text-darkcharcoal text-sm font-medium"
                        }, "Mandatory"), React.createElement(ToggleInput$ReactHooksTemplate.make, {
                          checked: mandatory,
                          handleChange: (function (param) {
                              return Curry._1(onMandatoryChange, !mandatory);
                            }),
                          labelClassName: "flex items-center"
                        }))));
}

var make = ToggleMandatory;

exports.make = make;
/* react Not a pure module */

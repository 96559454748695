// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var $$Array = require("rescript/lib/js/array.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Pervasives = require("rescript/lib/js/pervasives.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");
var Http$ReactHooksTemplate = require("../../common/http.bs.js");
var Filter$ReactHooksTemplate = require("./filter.bs.js");
var FilterForm$ReactHooksTemplate = require("./FilterForm.bs.js");
var FilterPills$ReactHooksTemplate = require("./filterPills.bs.js");
var DateFilterForm$ReactHooksTemplate = require("./DateFilterForm.bs.js");
var FilterPillsElement$ReactHooksTemplate = require("./FilterPillsElement.bs.js");

var initialState = {
  loading: false,
  currentFilter: 0,
  filterPills: /* [] */0,
  filters: /* [] */0
};

function reducer(state, x) {
  if (typeof x === "number") {
    return {
            loading: true,
            currentFilter: state.currentFilter,
            filterPills: state.filterPills,
            filters: state.filters
          };
  } else if (x.TAG === /* FetchFiltersCompleted */0) {
    return {
            loading: false,
            currentFilter: state.currentFilter,
            filterPills: x._1,
            filters: x._0
          };
  } else {
    return {
            loading: state.loading,
            currentFilter: x._0,
            filterPills: x._1,
            filters: x._2
          };
  }
}

function Filters(Props) {
  var onClose = Props.onClose;
  var onApply = Props.onApply;
  var showDateFilter = Props.showDateFilter;
  var showFilterPills = Props.showFilterPills;
  var match = React.useReducer(reducer, initialState);
  var dispatch = match[1];
  var state = match[0];
  var applyFilter = function (filters) {
    return Http$ReactHooksTemplate.fetchPostEncodedJSON("/api/v1/filters", Json_encode.object_({
                    hd: [
                      "filters",
                      Json_encode.list(Filter$ReactHooksTemplate.encode, filters)
                    ],
                    tl: /* [] */0
                  }));
  };
  React.useEffect((function () {
          Curry._1(dispatch, /* FetchFiltersInProgress */0);
          Http$ReactHooksTemplate.fetchGetJSONDecode("/api/v1/filters", Filter$ReactHooksTemplate.fromJson).then(function (xs) {
                var filterPills = Belt_List.map(Belt_List.keep(xs, Filter$ReactHooksTemplate.isSelected), (function (x) {
                        return FilterPills$ReactHooksTemplate.make(Filter$ReactHooksTemplate.toString(x), x);
                      }));
                return Promise.resolve(Curry._1(dispatch, {
                                TAG: /* FetchFiltersCompleted */0,
                                _0: xs,
                                _1: filterPills
                              }));
              });
          
        }), []);
  var handleSetFilter = function (filter) {
    var filterId = Filter$ReactHooksTemplate.id(filter);
    var filterString = Filter$ReactHooksTemplate.toString(filter);
    var filterPills = Belt_Option.isNone(Belt_List.head(Belt_List.keep(state.filterPills, (function (xs) {
                    return filterId === FilterPills$ReactHooksTemplate.id(xs);
                  })))) ? Pervasives.$at(state.filterPills, {
            hd: FilterPills$ReactHooksTemplate.make(filterString, filter),
            tl: /* [] */0
          }) : Belt_List.map(state.filterPills, (function (xs) {
              if (FilterPills$ReactHooksTemplate.id(xs) === filterId) {
                return FilterPills$ReactHooksTemplate.make(filterString, filter);
              } else {
                return xs;
              }
            }));
    console.log(filterPills);
    var filters = Belt_List.map(state.filters, (function (xs) {
            if (Filter$ReactHooksTemplate.id(xs) === filterId) {
              return filter;
            } else {
              return xs;
            }
          }));
    console.log(filters);
    return Curry._1(dispatch, {
                TAG: /* AddFilter */1,
                _0: filterId,
                _1: filterPills,
                _2: filters
              });
  };
  var handleResetFilter = function (filter) {
    var filterId = Filter$ReactHooksTemplate.id(filter);
    var filterPills = Belt_List.keep(state.filterPills, (function (x) {
            return filterId !== FilterPills$ReactHooksTemplate.id(x);
          }));
    var filters = Belt_List.map(state.filters, (function (x) {
            if (filterId === Filter$ReactHooksTemplate.id(x)) {
              return filter;
            } else {
              return x;
            }
          }));
    applyFilter(filters).then(function (param) {
          return Promise.resolve((Curry._1(dispatch, {
                            TAG: /* AddFilter */1,
                            _0: filterId,
                            _1: filterPills,
                            _2: filters
                          }), Curry._1(onApply, undefined)));
        });
    
  };
  var displayDateFilter = function (xs) {
    var filter = Filter$ReactHooksTemplate.filterType(xs);
    var tmp;
    tmp = filter.TAG === /* Date */1 ? React.createElement(DateFilterForm$ReactHooksTemplate.make, {
            filter: filter._0,
            onChange: (function (param) {
                var filter = Filter$ReactHooksTemplate.setFilterTypes(param, xs);
                var filterId = Filter$ReactHooksTemplate.id(filter);
                var filters = Belt_List.map(state.filters, (function (xs) {
                        if (Filter$ReactHooksTemplate.id(xs) === filterId) {
                          return filter;
                        } else {
                          return xs;
                        }
                      }));
                var filterPills = Belt_List.map(Belt_List.keep(filters, Filter$ReactHooksTemplate.isSelected), (function (x) {
                        return FilterPills$ReactHooksTemplate.make(Filter$ReactHooksTemplate.toString(x), x);
                      }));
                applyFilter(filters).then(function (param) {
                      return Promise.resolve((Curry._1(dispatch, {
                                        TAG: /* FetchFiltersCompleted */0,
                                        _0: filters,
                                        _1: filterPills
                                      }), Curry._1(onApply, undefined)));
                    });
                
              }),
            displayFilterPills: false,
            disableDatePicker: false
          }) : React.createElement(React.Fragment, undefined);
    return React.createElement("div", undefined, tmp);
  };
  var tmp;
  if (showDateFilter) {
    var match$1 = state.filters;
    tmp = match$1 ? React.createElement(React.Fragment, undefined, displayDateFilter(match$1.hd)) : React.createElement(React.Fragment, undefined);
  } else {
    tmp = showFilterPills ? $$Array.of_list(Belt_List.mapWithIndex(state.filterPills, (function (i, t) {
                  return React.createElement("div", {
                              key: String(i),
                              className: "flex flex-wrap mr-1"
                            }, React.createElement(FilterPillsElement$ReactHooksTemplate.make, {
                                  t: t,
                                  handleResetFilter: handleResetFilter,
                                  height: "h-8"
                                }));
                }))) : React.createElement("div", {
            className: "w-screen z-10 disabled-wrapper absolute inset-0 flex justify-center items-center",
            style: {
              height: "calc(100vh + 5rem)"
            }
          }, React.createElement("div", {
                className: "bg-transparent top-64-px w-1/2 h-screen fixed right-0 z-10 shadow"
              }, React.createElement("div", {
                    className: "bg-white relative z-10 shadow",
                    style: {
                      height: "calc(100vh - 4rem)"
                    }
                  }, React.createElement(FilterForm$ReactHooksTemplate.make, {
                        filters: state.filters,
                        filterPills: state.filterPills,
                        onClose: onClose,
                        onApply: onApply,
                        handleSetFilter: handleSetFilter,
                        handleResetFilter: handleResetFilter,
                        currentFilter: state.currentFilter
                      }))));
  }
  return React.createElement(React.Fragment, undefined, tmp);
}

var make = Filters;

exports.initialState = initialState;
exports.reducer = reducer;
exports.make = make;
/* react Not a pure module */

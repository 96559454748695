// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");

function name(t) {
  return t.name;
}

function count(t) {
  return t.count;
}

function status(t) {
  return t.status;
}

function decode(json) {
  return {
          name: Json_decode.field("name", Json_decode.string, json),
          count: Json_decode.field("count", (function (param) {
                  return Json_decode.withDefault(0, Json_decode.$$int, param);
                }), json),
          status: Json_decode.field("status", Json_decode.string, json)
        };
}

var customerSourceType = "FeedFile";

exports.name = name;
exports.count = count;
exports.status = status;
exports.customerSourceType = customerSourceType;
exports.decode = decode;
/* No side effect */

// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");

function CheckboxInput(Props) {
  var checked = Props.checked;
  var disabledOpt = Props.disabled;
  var label = Props.label;
  var handleChange = Props.handleChange;
  var indeterminateOpt = Props.indeterminate;
  var classNameOpt = Props.className;
  var checkboxClassNameOpt = Props.checkboxClassName;
  var labelClassNameOpt = Props.labelClassName;
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var indeterminate = indeterminateOpt !== undefined ? indeterminateOpt : false;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var checkboxClassName = checkboxClassNameOpt !== undefined ? checkboxClassNameOpt : "checkbox";
  var labelClassName = labelClassNameOpt !== undefined ? labelClassNameOpt : "text-base leading-normal whitespace-normal";
  var domElement = React.useRef(null);
  React.useEffect(function () {
        var y = Belt_Option.getExn(Caml_option.nullable_to_opt(domElement.current));
        y.indeterminate = indeterminate;
        
      });
  return React.createElement("div", {
              className: "flex gap-2 " + className
            }, React.createElement("label", undefined, React.createElement("input", {
                      ref: domElement,
                      checked: indeterminate ? false : checked,
                      disabled: disabled,
                      type: "checkbox",
                      onChange: handleChange
                    }), React.createElement("div", {
                      className: disabled ? "disabled-input " + checkboxClassName : "cursor-pointer " + checkboxClassName
                    })), React.createElement("div", {
                  className: labelClassName
                }, label));
}

var make = CheckboxInput;

exports.make = make;
/* react Not a pure module */

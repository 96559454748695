// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var MomentRe = require("bs-moment/src/MomentRe.bs.js");
var Pervasives = require("rescript/lib/js/pervasives.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");
var Utils$ReactHooksTemplate = require("../../common/utils.bs.js");
var DateSelected$ReactHooksTemplate = require("./dateSelected.bs.js");

function getFrom(t) {
  return t.from;
}

function getTo(t) {
  return t.to_;
}

function selected(t) {
  return t.selected;
}

function isSelected(t) {
  return Belt_Option.isSome(t.selected);
}

function setSelected(selected, t) {
  return {
          from: t.from,
          to_: t.to_,
          selected: selected
        };
}

function resetSelected(t) {
  return {
          from: t.from,
          to_: t.to_,
          selected: undefined
        };
}

function convertDateToMoment(date) {
  if (date !== undefined) {
    return Caml_option.some(MomentRe.moment(undefined, date));
  }
  
}

function fromJson(json) {
  return {
          from: convertDateToMoment(Json_decode.withDefault(undefined, (function (param) {
                      return Json_decode.field("start_date", (function (param) {
                                    return Json_decode.optional(Json_decode.string, param);
                                  }), param);
                    }), json)),
          to_: convertDateToMoment(Json_decode.withDefault(undefined, (function (param) {
                      return Json_decode.field("end_date", (function (param) {
                                    return Json_decode.optional(Json_decode.string, param);
                                  }), param);
                    }), json)),
          selected: Json_decode.withDefault(undefined, (function (param) {
                  return Json_decode.optional((function (param) {
                                return Json_decode.field("selected", DateSelected$ReactHooksTemplate.decode, param);
                              }), param);
                }), json)
        };
}

function toString(t) {
  var selected = t.selected;
  if (selected !== undefined) {
    return DateSelected$ReactHooksTemplate.toString(selected);
  } else {
    return "";
  }
}

function encodeParams(t) {
  return Pervasives.$at(Utils$ReactHooksTemplate.encodeOptionalMomentDateParamToList("start_date", t.from), Pervasives.$at(Utils$ReactHooksTemplate.encodeOptionalMomentDateParamToList("end_date", t.to_), Utils$ReactHooksTemplate.encodeOptionalParamToList("selected", t.selected, DateSelected$ReactHooksTemplate.encode)));
}

function encode(t) {
  return Json_encode.object_({
              hd: [
                "category_type",
                "date"
              ],
              tl: {
                hd: [
                  "params",
                  Json_encode.object_(encodeParams(t))
                ],
                tl: /* [] */0
              }
            });
}

exports.getFrom = getFrom;
exports.getTo = getTo;
exports.setSelected = setSelected;
exports.resetSelected = resetSelected;
exports.toString = toString;
exports.encode = encode;
exports.fromJson = fromJson;
exports.selected = selected;
exports.isSelected = isSelected;
/* MomentRe Not a pure module */

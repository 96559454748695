// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var $$Array = require("rescript/lib/js/array.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Js_dict = require("rescript/lib/js/js_dict.js");
var Js_json = require("rescript/lib/js/js_json.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Recharts = require("recharts");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Caml_array = require("rescript/lib/js/caml_array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var AxisData$ReactHooksTemplate = require("./axisData.bs.js");
var Recharts$ReactHooksTemplate = require("../common/Recharts.bs.js");
var ChartComponentColor$ReactHooksTemplate = require("./chartComponentColor.bs.js");
var GenericChartAttribute$ReactHooksTemplate = require("./genericChartAttribute.bs.js");

function ComposedChartComponent(Props) {
  var chartId = Props.chartId;
  var chartAttribute = Props.chartAttribute;
  var drillDownKey = Props.drillDownKey;
  var onClick = Props.onClick;
  var enlarged = Props.enlarged;
  var chartHeight = Props.chartHeight;
  var renderToolTip = function (data) {
    var payload = data["payload"];
    if (payload == null) {
      return React.createElement("div", undefined);
    }
    var newPayload = Js_dict.values(payload);
    var match = newPayload.length;
    if (match === 0) {
      return React.createElement("div", undefined);
    }
    console.log(Caml_array.get(newPayload, 0));
    var payloadData = Js_dict.get(Caml_array.get(newPayload, 0), "payload");
    if (payloadData !== undefined) {
      return React.createElement("div", {
                  className: "recharts-default-tooltip bg-white border border-solid border-1\n          border-gray-300 p-10-px"
                }, React.createElement("ul", {
                      className: "recharts-tooltip-itemlist"
                    }, Belt_Array.map(Js_dict.entries(Caml_option.valFromOption(payloadData)), (function (param) {
                            return React.createElement("li", {
                                        className: "recharts-tooltip-item py-3-px"
                                      }, React.createElement("span", undefined, String(param[0])), React.createElement("span", undefined, " : "), React.createElement("span", undefined, param[1]));
                          }))));
    } else {
      return React.createElement("div", undefined);
    }
  };
  var renderLegend = function (value, param) {
    return React.createElement("span", {
                className: "text-xs"
              }, value);
  };
  var enableCartesianGrid = GenericChartAttribute$ReactHooksTemplate.enableCartesianGrid(chartAttribute);
  var showTick = GenericChartAttribute$ReactHooksTemplate.showTick(chartAttribute);
  var data = GenericChartAttribute$ReactHooksTemplate.data(chartAttribute);
  var dataLength;
  if (data !== undefined) {
    var data$1 = Js_json.decodeArray(Caml_option.valFromOption(data));
    dataLength = data$1 !== undefined ? data$1.length : 0;
  } else {
    dataLength = 0;
  }
  var xs = GenericChartAttribute$ReactHooksTemplate.yAxisList(chartAttribute);
  return React.createElement("div", {
              className: " w-full " + chartHeight + " p-20-px "
            }, React.createElement(Recharts.ResponsiveContainer, {
                  children: React.createElement(Recharts.ComposedChart, {
                        data: GenericChartAttribute$ReactHooksTemplate.data(chartAttribute),
                        margin: Recharts$ReactHooksTemplate.Margin.make(20, 10, 0, 10),
                        children: null
                      }, enableCartesianGrid !== undefined ? (
                          enableCartesianGrid ? React.createElement(Recharts.CartesianGrid, {}) : React.createElement(React.Fragment, undefined)
                        ) : React.createElement(Recharts.CartesianGrid, {}), React.createElement(Recharts.XAxis, Curry._7(Recharts$ReactHooksTemplate.XAxis.makeProps(/* Num */{
                                      _0: 0
                                    })(enlarged ? 4 : 2, AxisData$ReactHooksTemplate.key(GenericChartAttribute$ReactHooksTemplate.xAxis(chartAttribute)), undefined, undefined, Caml_option.some(showTick !== undefined && !showTick ? Recharts$ReactHooksTemplate.Tick.make(0, undefined, undefined)(undefined, undefined, undefined) : Recharts$ReactHooksTemplate.Tick.make(10, 0, undefined)("middle", 10, undefined))), undefined, chartHeight === "h-96" ? 100 : 90, 10, (function (key) {
                                  return key.split("_").join(" ").split("-").join(" ");
                                }), undefined, undefined, undefined)), (console.log(GenericChartAttribute$ReactHooksTemplate.yAxisList(chartAttribute)), React.createElement(React.Fragment, undefined)), dataLength > 5 ? React.createElement(Recharts.Brush, {
                              dataKey: AxisData$ReactHooksTemplate.key(GenericChartAttribute$ReactHooksTemplate.xAxis(chartAttribute)),
                              endIndex: enlarged || Belt_Option.getWithDefault(GenericChartAttribute$ReactHooksTemplate.width(chartAttribute), "") === "w-full" ? undefined : (
                                  dataLength > 10 ? 9 : dataLength - 1 | 0
                                ),
                              height: enlarged ? 20 : 10
                            }) : React.createElement(React.Fragment, undefined), xs ? $$Array.of_list(Belt_List.map(xs, (function (x) {
                                    var id = AxisData$ReactHooksTemplate.yAxisId(x);
                                    var y = AxisData$ReactHooksTemplate.yAxisOrientation(x);
                                    return React.createElement(Recharts.YAxis, Curry.app(Recharts$ReactHooksTemplate.YAxis.makeProps(undefined)(undefined, undefined, id !== undefined ? id : "left", undefined, undefined, Caml_option.some(Recharts$ReactHooksTemplate.Tick.make(12, undefined, undefined)("end", 10, true))), [
                                                    undefined,
                                                    y === "right" ? "right" : "left",
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined
                                                  ]));
                                  }))) : $$Array.of_list({
                              hd: React.createElement(Recharts.YAxis, Curry.app(Recharts$ReactHooksTemplate.YAxis.makeProps(undefined)(undefined, undefined, "left", undefined, undefined, Caml_option.some(Recharts$ReactHooksTemplate.Tick.make(12, undefined, undefined)("end", 10, true))), [
                                        undefined,
                                        "left",
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined
                                      ])),
                              tl: /* [] */0
                            }), React.createElement(Recharts.Tooltip, {
                            content: renderToolTip
                          }), React.createElement(Recharts.Legend, {
                            formatter: renderLegend
                          }), $$Array.of_list(Belt_List.mapWithIndex(GenericChartAttribute$ReactHooksTemplate.yAxis(chartAttribute), (function (i, xs) {
                                  var match = AxisData$ReactHooksTemplate.type_(xs);
                                  switch (match) {
                                    case /* Line */0 :
                                        var color = AxisData$ReactHooksTemplate.fill(xs);
                                        var match$1 = AxisData$ReactHooksTemplate.key(xs);
                                        var tmp = match$1 === "KPI" ? "3 4 5 2" : "";
                                        var y = AxisData$ReactHooksTemplate.yAxisId(xs);
                                        var match$2 = AxisData$ReactHooksTemplate.key(xs);
                                        var tmp$1 = match$2 === "KPI" ? React.createElement(React.Fragment, undefined) : React.createElement(Recharts.LabelList, {
                                                dataKey: AxisData$ReactHooksTemplate.key(xs),
                                                position: "top",
                                                fill: "black",
                                                fontSize: 12
                                              });
                                        return React.createElement(Recharts.Line, {
                                                    type: "monotone",
                                                    dataKey: AxisData$ReactHooksTemplate.key(xs),
                                                    stroke: color !== undefined ? color : ChartComponentColor$ReactHooksTemplate.getColorById(i, GenericChartAttribute$ReactHooksTemplate.colors(chartAttribute)),
                                                    strokeDasharray: tmp,
                                                    connectNulls: true,
                                                    yAxisId: y !== undefined ? y : "left",
                                                    children: tmp$1,
                                                    key: String(i)
                                                  });
                                    case /* Bar */1 :
                                        var color$1 = AxisData$ReactHooksTemplate.fill(xs);
                                        var y$1 = AxisData$ReactHooksTemplate.yAxisId(xs);
                                        var json = GenericChartAttribute$ReactHooksTemplate.data(chartAttribute);
                                        return React.createElement(Recharts.Bar, {
                                                    dataKey: AxisData$ReactHooksTemplate.key(xs),
                                                    barSize: 30,
                                                    fill: color$1 !== undefined ? color$1 : ChartComponentColor$ReactHooksTemplate.getColorById(i, GenericChartAttribute$ReactHooksTemplate.colors(chartAttribute)),
                                                    stackId: Belt_Option.getWithDefault(AxisData$ReactHooksTemplate.stackId(xs), ""),
                                                    yAxisId: y$1 !== undefined ? y$1 : "left",
                                                    onClick: (function (data, param) {
                                                        var key = Json_decode.field(AxisData$ReactHooksTemplate.key(GenericChartAttribute$ReactHooksTemplate.xAxis(chartAttribute)), Json_decode.string, Belt_Option.getExn((data == null) ? undefined : Caml_option.some(data)).payload);
                                                        return Curry._3(onClick, key, chartId, GenericChartAttribute$ReactHooksTemplate.onClick(chartAttribute));
                                                      }),
                                                    children: json !== undefined ? Belt_Array.map(Belt_Option.getWithDefault(Js_json.decodeArray(Caml_option.valFromOption(json)), []), (function (x) {
                                                              var tmp;
                                                              if (drillDownKey !== undefined) {
                                                                if (Caml_obj.caml_equal(Js_json.decodeString(Belt_Option.getWithDefault(Js_dict.get(Belt_Option.getWithDefault(Js_json.decodeObject(x), {}), AxisData$ReactHooksTemplate.key(GenericChartAttribute$ReactHooksTemplate.xAxis(chartAttribute))), null)), String(drillDownKey))) {
                                                                  var color = AxisData$ReactHooksTemplate.fill(xs);
                                                                  tmp = color !== undefined ? color : ChartComponentColor$ReactHooksTemplate.getColorById(i, GenericChartAttribute$ReactHooksTemplate.colors(chartAttribute));
                                                                } else {
                                                                  tmp = "#d8d8d8";
                                                                }
                                                              } else {
                                                                var color$1 = AxisData$ReactHooksTemplate.fill(xs);
                                                                tmp = color$1 !== undefined ? color$1 : ChartComponentColor$ReactHooksTemplate.getColorById(i, GenericChartAttribute$ReactHooksTemplate.colors(chartAttribute));
                                                              }
                                                              return React.createElement(Recharts.Cell, {
                                                                          key: String(chartId),
                                                                          fill: tmp
                                                                        });
                                                            })) : React.createElement(React.Fragment, undefined),
                                                    key: String(i)
                                                  });
                                    case /* Scatter */2 :
                                        var color$2 = AxisData$ReactHooksTemplate.fill(xs);
                                        var y$2 = AxisData$ReactHooksTemplate.yAxisId(xs);
                                        var enableCartesianGrid = GenericChartAttribute$ReactHooksTemplate.enableLabel(chartAttribute);
                                        return React.createElement(Recharts.Scatter, {
                                                    dataKey: AxisData$ReactHooksTemplate.key(xs),
                                                    fill: color$2 !== undefined ? color$2 : ChartComponentColor$ReactHooksTemplate.getColorById(i, GenericChartAttribute$ReactHooksTemplate.colors(chartAttribute)),
                                                    yAxisId: y$2 !== undefined ? y$2 : "left",
                                                    children: enableCartesianGrid !== undefined ? (
                                                        enableCartesianGrid ? React.createElement(Recharts.LabelList, {
                                                                dataKey: AxisData$ReactHooksTemplate.key(xs),
                                                                position: "top",
                                                                fill: "black",
                                                                fontSize: 12
                                                              }) : React.createElement(React.Fragment, undefined)
                                                      ) : React.createElement(Recharts.LabelList, {
                                                            dataKey: AxisData$ReactHooksTemplate.key(xs),
                                                            position: "top",
                                                            fill: "black",
                                                            fontSize: 12
                                                          }),
                                                    key: String(i)
                                                  });
                                    
                                  }
                                }))))
                }));
}

var make = ComposedChartComponent;

exports.make = make;
/* react Not a pure module */

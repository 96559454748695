// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Domain$ReactHooksTemplate = require("../settings/domain.bs.js");

function configId(t) {
  return t.configId;
}

function address(t) {
  return t.address;
}

function port(t) {
  return t.port;
}

function userName(t) {
  return t.userName;
}

function password(t) {
  return t.password;
}

function opensslVerifyMode(t) {
  return t.opensslVerifyMode;
}

function enableStarttlsAuto(t) {
  return t.enableStarttlsAuto;
}

function setAddress(t, address) {
  return {
          configId: t.configId,
          address: address,
          port: t.port,
          userName: t.userName,
          password: t.password,
          opensslVerifyMode: t.opensslVerifyMode,
          enableStarttlsAuto: t.enableStarttlsAuto
        };
}

function setPort(t, port) {
  return {
          configId: t.configId,
          address: t.address,
          port: port,
          userName: t.userName,
          password: t.password,
          opensslVerifyMode: t.opensslVerifyMode,
          enableStarttlsAuto: t.enableStarttlsAuto
        };
}

function setUserName(t, userName) {
  return {
          configId: t.configId,
          address: t.address,
          port: t.port,
          userName: userName,
          password: t.password,
          opensslVerifyMode: t.opensslVerifyMode,
          enableStarttlsAuto: t.enableStarttlsAuto
        };
}

function setPassWord(t, password) {
  return {
          configId: t.configId,
          address: t.address,
          port: t.port,
          userName: t.userName,
          password: password,
          opensslVerifyMode: t.opensslVerifyMode,
          enableStarttlsAuto: t.enableStarttlsAuto
        };
}

function setopensslVerifyMode(t, opensslVerifyMode) {
  return {
          configId: t.configId,
          address: t.address,
          port: t.port,
          userName: t.userName,
          password: t.password,
          opensslVerifyMode: opensslVerifyMode,
          enableStarttlsAuto: t.enableStarttlsAuto
        };
}

function setenableStarttlsAuto(t, enableStarttlsAuto) {
  return {
          configId: t.configId,
          address: t.address,
          port: t.port,
          userName: t.userName,
          password: t.password,
          opensslVerifyMode: t.opensslVerifyMode,
          enableStarttlsAuto: enableStarttlsAuto
        };
}

var initialConf_userName = "usernames";

var initialConf_password = Curry._1(Domain$ReactHooksTemplate.$$String.Password.empty, undefined);

var initialConf_opensslVerifyMode = "none";

var initialConf = {
  configId: 0,
  address: "address",
  port: "25",
  userName: initialConf_userName,
  password: initialConf_password,
  opensslVerifyMode: initialConf_opensslVerifyMode,
  enableStarttlsAuto: false
};

function fromJson(json) {
  return {
          configId: Json_decode.field("id", Json_decode.$$int, json),
          address: Json_decode.field("address", Json_decode.string, json),
          port: Json_decode.field("port", Json_decode.string, json),
          userName: Json_decode.optional((function (param) {
                  return Json_decode.field("user_name", Json_decode.string, param);
                }), json),
          password: Json_decode.field("password", Domain$ReactHooksTemplate.$$String.Password.decode, json),
          opensslVerifyMode: Json_decode.optional((function (param) {
                  return Json_decode.field("openssl_verify_mode", Json_decode.string, param);
                }), json),
          enableStarttlsAuto: Json_decode.field("enable_starttls_auto", Json_decode.bool, json)
        };
}

function decode(json) {
  return Json_decode.field("smtp_configuration", fromJson, json);
}

exports.configId = configId;
exports.address = address;
exports.port = port;
exports.userName = userName;
exports.password = password;
exports.opensslVerifyMode = opensslVerifyMode;
exports.enableStarttlsAuto = enableStarttlsAuto;
exports.setAddress = setAddress;
exports.setPort = setPort;
exports.setUserName = setUserName;
exports.setPassWord = setPassWord;
exports.setopensslVerifyMode = setopensslVerifyMode;
exports.setenableStarttlsAuto = setenableStarttlsAuto;
exports.initialConf = initialConf;
exports.decode = decode;
/* initialConf Not a pure module */

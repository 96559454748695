// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");

function empty(param) {
  return {
          id: undefined,
          name: "",
          surveyCount: 0
        };
}

function id(t) {
  return t.id;
}

function name(t) {
  return t.name;
}

function surveyCount(t) {
  return t.surveyCount;
}

function setName(t, name) {
  return {
          id: t.id,
          name: name,
          surveyCount: t.surveyCount
        };
}

function fromJson(json) {
  return {
          id: Json_decode.field("id", (function (param) {
                  return Json_decode.optional(Json_decode.$$int, param);
                }), json),
          name: Json_decode.field("name", Json_decode.string, json),
          surveyCount: Json_decode.field("get_survey_count", Json_decode.$$int, json)
        };
}

function toJson(t) {
  return Json_encode.object_({
              hd: [
                "id",
                Json_encode.nullable((function (prim) {
                        return prim;
                      }), t.id)
              ],
              tl: {
                hd: [
                  "name",
                  t.name
                ],
                tl: /* [] */0
              }
            });
}

exports.empty = empty;
exports.id = id;
exports.name = name;
exports.setName = setName;
exports.surveyCount = surveyCount;
exports.fromJson = fromJson;
exports.toJson = toJson;
/* No side effect */

// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var CustomReport$ReactHooksTemplate = require("./CustomReport.bs.js");
var ResponseReport$ReactHooksTemplate = require("./ResponseReport.bs.js");
var AggregateReport$ReactHooksTemplate = require("./AggregateReport.bs.js");

function templateName(t) {
  return t.templateName;
}

function template(t) {
  return t.template;
}

function setTemplateFromName(templateName) {
  switch (templateName) {
    case "AggregateReport" :
        return {
                templateName: templateName,
                template: {
                  TAG: /* AggregateReport */0,
                  _0: AggregateReport$ReactHooksTemplate.empty(undefined)
                }
              };
    case "ResponseReport" :
        return {
                templateName: templateName,
                template: {
                  TAG: /* ResponseReport */2,
                  _0: ResponseReport$ReactHooksTemplate.empty(undefined)
                }
              };
    default:
      return {
              templateName: "CustomReport",
              template: {
                TAG: /* CustomReport */1,
                _0: CustomReport$ReactHooksTemplate.empty(undefined)
              }
            };
  }
}

function templateType(templateType$1) {
  switch (templateType$1.TAG | 0) {
    case /* AggregateReport */0 :
        return AggregateReport$ReactHooksTemplate.templateType;
    case /* CustomReport */1 :
        return CustomReport$ReactHooksTemplate.templateType;
    case /* ResponseReport */2 :
        return ResponseReport$ReactHooksTemplate.templateType;
    
  }
}

function templateFields(template, value) {
  switch (template.TAG | 0) {
    case /* AggregateReport */0 :
        return [
                value.metric,
                value.aggregateFields
              ];
    case /* CustomReport */1 :
        return value.name;
    case /* ResponseReport */2 :
        return [
                value.survey,
                value.campaigns
              ];
    
  }
}

function setAggregateReportTemplate(template) {
  return {
          templateName: "AggregateReport",
          template: {
            TAG: /* AggregateReport */0,
            _0: template
          }
        };
}

function setResponseReportTemplate(template) {
  return {
          templateName: "ResponseReport",
          template: {
            TAG: /* ResponseReport */2,
            _0: template
          }
        };
}

function decode(json) {
  var templateName = Json_decode.field("template_type", Json_decode.string, json);
  var template = templateName === AggregateReport$ReactHooksTemplate.templateType ? ({
        TAG: /* AggregateReport */0,
        _0: Json_decode.field("template", AggregateReport$ReactHooksTemplate.decode, json)
      }) : (
      templateName === ResponseReport$ReactHooksTemplate.templateType ? ({
            TAG: /* ResponseReport */2,
            _0: Json_decode.field("template", ResponseReport$ReactHooksTemplate.decode, json)
          }) : ({
            TAG: /* CustomReport */1,
            _0: Json_decode.field("template", CustomReport$ReactHooksTemplate.decode, json)
          })
    );
  return {
          templateName: templateName,
          template: template
        };
}

var templateList = {
  hd: "AggregateReport",
  tl: {
    hd: "CustomReport",
    tl: {
      hd: "ResponseReport",
      tl: /* [] */0
    }
  }
};

exports.templateName = templateName;
exports.template = template;
exports.templateList = templateList;
exports.setTemplateFromName = setTemplateFromName;
exports.templateType = templateType;
exports.templateFields = templateFields;
exports.setAggregateReportTemplate = setAggregateReportTemplate;
exports.setResponseReportTemplate = setResponseReportTemplate;
exports.decode = decode;
/* No side effect */

// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var List = require("rescript/lib/js/list.js");
var $$Array = require("rescript/lib/js/array.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Image$ReactHooksTemplate = require("../common/image.bs.js");
var Logic$ReactHooksTemplate = require("./Logic.bs.js");
var Action$ReactHooksTemplate = require("./Action.bs.js");
var Survey$ReactHooksTemplate = require("./survey.bs.js");
var ActionForm$ReactHooksTemplate = require("./ActionForm.bs.js");
var ConditionForm$ReactHooksTemplate = require("./ConditionForm.bs.js");
var LogicCondition$ReactHooksTemplate = require("./LogicCondition.bs.js");

function LogicForm(Props) {
  var survey = Props.survey;
  var logic = Props.logic;
  var updateLogic = Props.updateLogic;
  var deleteLogic = Props.deleteLogic;
  var questions = Logic$ReactHooksTemplate.availableOptionsForLhs(logic, survey.questions);
  var availableTargets = Logic$ReactHooksTemplate.availableTargets(logic, survey.questions);
  return React.createElement("div", {
              className: "flex flex-col bg-white rounded p-3 gap-6"
            }, React.createElement("div", {
                  className: "flex justify-end cursor-pointer",
                  onClick: (function (param) {
                      return Curry._1(deleteLogic, List.find((function (x) {
                                        return Caml_obj.caml_equal(x, logic);
                                      }), Survey$ReactHooksTemplate.logics(survey)));
                    })
                }, React.createElement("img", {
                      className: "w-3",
                      src: Image$ReactHooksTemplate.closeIcon
                    })), $$Array.of_list(List.mapi((function (index, x) {
                        var disabled = List.length(logic.conditions) > 1 ? "cursor-pointer" : "disabled-input";
                        return React.createElement("div", {
                                    className: "flex justify-between items-center w-full gap-2"
                                  }, React.createElement(ConditionForm$ReactHooksTemplate.make, {
                                        questions: questions,
                                        condition: x,
                                        updateLogicCondition: (function (param) {
                                            return Curry._1(updateLogic, Logic$ReactHooksTemplate.setConditions(Belt_List.mapWithIndex(logic.conditions, (function (i, x) {
                                                                  if (i === index) {
                                                                    return param;
                                                                  } else {
                                                                    return x;
                                                                  }
                                                                })), logic));
                                          })
                                      }), React.createElement("div", {
                                        className: "flex gap-4 min-h-full items-center pt-20-px"
                                      }, React.createElement("div", {
                                            className: "cursor-pointer",
                                            onClick: (function (param) {
                                                var condition = LogicCondition$ReactHooksTemplate.emptyCondition(undefined, List.hd(List.rev(questions)));
                                                return Curry._1(updateLogic, Logic$ReactHooksTemplate.setConditions(List.append(logic.conditions, {
                                                                    hd: condition,
                                                                    tl: /* [] */0
                                                                  }), logic));
                                              })
                                          }, React.createElement("img", {
                                                className: "h-20-px w-20-px",
                                                src: Image$ReactHooksTemplate.plusCircle
                                              })), React.createElement("div", {
                                            className: disabled,
                                            onClick: (function (param) {
                                                var deletedList = Belt_List.add(logic.deletedConditions, x);
                                                if (List.length(logic.conditions) > 1) {
                                                  return Curry._1(updateLogic, Logic$ReactHooksTemplate.setDeletedConditions(Logic$ReactHooksTemplate.setConditions(Belt_List.keepWithIndex(logic.conditions, (function (param, i) {
                                                                            return i !== index;
                                                                          })), logic), deletedList));
                                                }
                                                
                                              })
                                          }, React.createElement("img", {
                                                className: "h-20-px w-20-px",
                                                src: Image$ReactHooksTemplate.trashGrey
                                              }))));
                      }), logic.conditions)), $$Array.of_list(List.mapi((function (index, x) {
                        var jumpExists = Belt_List.some(logic.actions, (function (action) {
                                var match = Action$ReactHooksTemplate.actionType(action);
                                if (match.TAG === /* Skip */0) {
                                  return false;
                                } else {
                                  return true;
                                }
                              }));
                        var disabled = List.length(logic.actions) > 1;
                        return React.createElement("div", {
                                    className: "flex justify-between items-center w-full gap-2 "
                                  }, React.createElement(ActionForm$ReactHooksTemplate.make, {
                                        jumpExists: jumpExists,
                                        action: x,
                                        updateLogicAction: (function (param) {
                                            var deletedActions = Belt_List.concat(logic.deletedActions, Belt_List.keepWithIndex(logic.actions, (function (x, i) {
                                                        if (i === index) {
                                                          return Action$ReactHooksTemplate.stringOfActionType(x) !== Action$ReactHooksTemplate.stringOfActionType(param);
                                                        } else {
                                                          return false;
                                                        }
                                                      })));
                                            return Curry._1(updateLogic, Logic$ReactHooksTemplate.setDeletedActions(Logic$ReactHooksTemplate.setActions(Belt_List.mapWithIndex(logic.actions, (function (i, x) {
                                                                      if (i === index) {
                                                                        return param;
                                                                      } else {
                                                                        return x;
                                                                      }
                                                                    })), logic), deletedActions));
                                          }),
                                        thankYous: survey.thankYouList,
                                        targetQuestions: availableTargets
                                      }), React.createElement("div", {
                                        className: "flex gap-4 min-h-full items-center pt-20-px"
                                      }, React.createElement("div", {
                                            className: "cursor-pointer",
                                            onClick: (function (param) {
                                                var jumpExists = Belt_List.some(logic.actions, (function (x) {
                                                        var match = Action$ReactHooksTemplate.actionType(x);
                                                        if (match.TAG === /* Skip */0) {
                                                          return false;
                                                        } else {
                                                          return true;
                                                        }
                                                      }));
                                                var addJumpAction = List.length(availableTargets) === 0 ? Action$ReactHooksTemplate.emptyAction({
                                                        TAG: /* ThankYou */1,
                                                        _0: List.hd(survey.thankYouList)
                                                      }) : Action$ReactHooksTemplate.emptyAction({
                                                        TAG: /* Question */0,
                                                        _0: List.hd(availableTargets)
                                                      });
                                                var addSkipAction = List.length(availableTargets) === 0 ? Action$ReactHooksTemplate.emptySkipAction({
                                                        TAG: /* ThankYous */1,
                                                        _0: {
                                                          hd: List.hd(survey.thankYouList),
                                                          tl: /* [] */0
                                                        }
                                                      }) : Action$ReactHooksTemplate.emptySkipAction({
                                                        TAG: /* Questions */0,
                                                        _0: {
                                                          hd: List.hd(availableTargets),
                                                          tl: /* [] */0
                                                        }
                                                      });
                                                var newAction = jumpExists ? addSkipAction : addJumpAction;
                                                return Curry._1(updateLogic, Logic$ReactHooksTemplate.setActions(List.append(logic.actions, {
                                                                    hd: newAction,
                                                                    tl: /* [] */0
                                                                  }), logic));
                                              })
                                          }, React.createElement("img", {
                                                className: "h-20-px w-20-px",
                                                src: Image$ReactHooksTemplate.plusCircle
                                              })), React.createElement("div", {
                                            className: disabled ? "cursor-pointer" : "disabled-input",
                                            onClick: (function (param) {
                                                if (List.length(logic.actions) <= 1) {
                                                  return ;
                                                }
                                                var deletedActions = Belt_List.add(logic.deletedActions, x);
                                                return Curry._1(updateLogic, Logic$ReactHooksTemplate.setDeletedActions(Logic$ReactHooksTemplate.setActions(Belt_List.keepWithIndex(logic.actions, (function (param, i) {
                                                                          return i !== index;
                                                                        })), logic), deletedActions));
                                              })
                                          }, React.createElement("img", {
                                                className: "h-20-px w-20-px",
                                                src: Image$ReactHooksTemplate.trashGrey
                                              }))));
                      }), logic.actions)));
}

var make = LogicForm;

exports.make = make;
/* react Not a pure module */

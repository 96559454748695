// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Utils$ReactHooksTemplate = require("../../common/utils.bs.js");
var Filter$ReactHooksTemplate = require("./filter.bs.js");

function make(text, filter) {
  return {
          text: text,
          filter: filter
        };
}

function toString(t) {
  return Utils$ReactHooksTemplate.humanize(t.text);
}

function id(t) {
  return Filter$ReactHooksTemplate.id(t.filter);
}

function filter(t) {
  return t.filter;
}

exports.make = make;
exports.toString = toString;
exports.id = id;
exports.filter = filter;
/* Utils-ReactHooksTemplate Not a pure module */

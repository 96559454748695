// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var $$Array = require("rescript/lib/js/array.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Js_json = require("rescript/lib/js/js_json.js");
var Recharts = require("recharts");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var AxisData$ReactHooksTemplate = require("./axisData.bs.js");
var Recharts$ReactHooksTemplate = require("../common/Recharts.bs.js");
var ChartComponentColor$ReactHooksTemplate = require("./chartComponentColor.bs.js");
var GenericChartAttribute$ReactHooksTemplate = require("./genericChartAttribute.bs.js");

function AreaChartComponent(Props) {
  var chartAttribute = Props.chartAttribute;
  var enlarged = Props.enlarged;
  var chartHeight = Props.chartHeight;
  var renderLegend = function (value, param) {
    return React.createElement("span", {
                className: "text-xs"
              }, value);
  };
  var data = GenericChartAttribute$ReactHooksTemplate.data(chartAttribute);
  var dataLength;
  if (data !== undefined) {
    var data$1 = Js_json.decodeArray(Caml_option.valFromOption(data));
    dataLength = data$1 !== undefined ? data$1.length : 0;
  } else {
    dataLength = 0;
  }
  return React.createElement("div", {
              className: " w-full " + chartHeight + " p-4 "
            }, React.createElement(Recharts.ResponsiveContainer, {
                  children: React.createElement(Recharts.AreaChart, {
                        data: GenericChartAttribute$ReactHooksTemplate.data(chartAttribute),
                        margin: Recharts$ReactHooksTemplate.Margin.make(20, 50, 0, 0),
                        children: null
                      }, React.createElement(Recharts.ReferenceLine, {
                            y: 0,
                            stroke: "#ccc"
                          }), React.createElement(Recharts.XAxis, Curry._7(Recharts$ReactHooksTemplate.XAxis.makeProps(undefined)(1, AxisData$ReactHooksTemplate.key(GenericChartAttribute$ReactHooksTemplate.xAxis(chartAttribute)), false, false, Caml_option.some(Recharts$ReactHooksTemplate.Tick.make(12, undefined, undefined)("middle", undefined, true))), undefined, undefined, 10, undefined, undefined, undefined, undefined)), React.createElement(Recharts.YAxis, Curry.app(Recharts$ReactHooksTemplate.YAxis.makeProps(undefined)(undefined, undefined, undefined, undefined, undefined, Caml_option.some(Recharts$ReactHooksTemplate.Tick.make(12, undefined, undefined)("end", 10, true))), [
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                false,
                                false,
                                10,
                                undefined,
                                undefined
                              ])), dataLength > 5 ? React.createElement(Recharts.Brush, {
                              dataKey: AxisData$ReactHooksTemplate.key(GenericChartAttribute$ReactHooksTemplate.xAxis(chartAttribute)),
                              endIndex: enlarged || Belt_Option.getWithDefault(GenericChartAttribute$ReactHooksTemplate.width(chartAttribute), "") === "w-full" ? undefined : (
                                  dataLength > 10 ? 9 : dataLength - 1 | 0
                                ),
                              height: enlarged ? 20 : 10
                            }) : React.createElement(React.Fragment, undefined), $$Array.of_list(Belt_List.mapWithIndex(GenericChartAttribute$ReactHooksTemplate.yAxis(chartAttribute), (function (i, xs) {
                                  return React.createElement(Recharts.Area, {
                                              type: "monotone",
                                              dataKey: AxisData$ReactHooksTemplate.key(xs),
                                              stroke: ChartComponentColor$ReactHooksTemplate.getColorById(i, GenericChartAttribute$ReactHooksTemplate.colors(chartAttribute)),
                                              strokeWidth: 3.0,
                                              fill: ChartComponentColor$ReactHooksTemplate.getColorById(i, GenericChartAttribute$ReactHooksTemplate.colors(chartAttribute)),
                                              children: React.createElement(Recharts.LabelList, {
                                                    dataKey: AxisData$ReactHooksTemplate.key(xs),
                                                    position: "top",
                                                    fill: "black",
                                                    fontSize: 12
                                                  }),
                                              key: String(i)
                                            });
                                }))), React.createElement(Recharts.Tooltip, {
                            contentStyle: {
                              fontSize: "10pt"
                            }
                          }), React.createElement(Recharts.Legend, {
                            formatter: renderLegend
                          }))
                }));
}

var make = AreaChartComponent;

exports.make = make;
/* react Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var List = require("rescript/lib/js/list.js");
var $$Array = require("rescript/lib/js/array.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Js_dict = require("rescript/lib/js/js_dict.js");
var Belt_Int = require("rescript/lib/js/belt_Int.js");
var MomentRe = require("bs-moment/src/MomentRe.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Caml_int32 = require("rescript/lib/js/caml_int32.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_format = require("rescript/lib/js/caml_format.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");
var Querystringify = require("querystringify");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var Http$ReactHooksTemplate = require("./common/http.bs.js");
var Image$ReactHooksTemplate = require("./common/image.bs.js");
var Utils$ReactHooksTemplate = require("./common/utils.bs.js");
var Filters$ReactHooksTemplate = require("./dashboard/filters/filters.bs.js");
var Pagination$ReactHooksTemplate = require("./common/Pagination.bs.js");

var categoryList = {
  hd: "Agent Related::Lack Agent Etiquette",
  tl: {
    hd: "Agent Related::Need To Provide Proper Information",
    tl: {
      hd: "Agent Related::Should Speak Politely",
      tl: {
        hd: "B1w Issue",
        tl: {
          hd: "Balance Deduction",
          tl: {
            hd: "Better Offers And Services",
            tl: {
              hd: "Cgt Issue",
              tl: {
                hd: "Comment Namilekaharu",
                tl: {
                  hd: "Contact Center Related",
                  tl: {
                    hd: "Contact Center Related::Direct Call Transfer To Operator",
                    tl: {
                      hd: "Contact Center Related::Multilingual",
                      tl: {
                        hd: "Contact Center Related::Quick Response",
                        tl: {
                          hd: "Contact Center Related::Quick Solution",
                          tl: {
                            hd: "Dissatisfied Customer",
                            tl: {
                              hd: "Expand Network",
                              tl: {
                                hd: "Free Offers And Services",
                                tl: {
                                  hd: "Implement 4g Services",
                                  tl: {
                                    hd: "Improve Network",
                                    tl: {
                                      hd: "More Offers And Services",
                                      tl: {
                                        hd: "N/A",
                                        tl: {
                                          hd: "Ncell Center Related::Dissatisfied Customer",
                                          tl: {
                                            hd: "Ncell Center Related::More Centers",
                                            tl: {
                                              hd: "No Match Found",
                                              tl: {
                                                hd: "Others",
                                                tl: {
                                                  hd: "Others::Auto Activation Of Services",
                                                  tl: {
                                                    hd: "Others::Drc Issue",
                                                    tl: {
                                                      hd: "Others::Service Issue",
                                                      tl: {
                                                        hd: "Others::Service Issues",
                                                        tl: {
                                                          hd: "Others::Service Queries",
                                                          tl: {
                                                            hd: "Others::Sim Recycle Issue",
                                                            tl: {
                                                              hd: "Product Enhancement Suggestions",
                                                              tl: {
                                                                hd: "Product Enhancement Suggestions::Data Transfer Service",
                                                                tl: {
                                                                  hd: "Product Enhancement Suggestions::Instant Data Pack End Notification",
                                                                  tl: {
                                                                    hd: "Product Enhancement Suggestions::More Offers For Postpaid",
                                                                    tl: {
                                                                      hd: "Product Enhancement Suggestions::Postpaid To Prepaid Conversion",
                                                                      tl: {
                                                                        hd: "Product Enhancement Suggestions::Provide Bonus",
                                                                        tl: {
                                                                          hd: "Product Enhancement Suggestions::Revise Data Pack",
                                                                          tl: {
                                                                            hd: "Product Enhancement Suggestions::Revise Loan Service",
                                                                            tl: {
                                                                              hd: "Product Enhancement Suggestions::Revise Voice Pack",
                                                                              tl: {
                                                                                hd: "Product Enhancement Suggestions::Service Deactivation Process",
                                                                                tl: {
                                                                                  hd: "Reduce Tariff",
                                                                                  tl: {
                                                                                    hd: "Retail Channel Related::Photo Copy Issue",
                                                                                    tl: {
                                                                                      hd: "Retail Channel Related::Quick Sim Activation",
                                                                                      tl: {
                                                                                        hd: "Satisfied Customer",
                                                                                        tl: {
                                                                                          hd: "Tsc Issue",
                                                                                          tl: {
                                                                                            hd: "Unlimited Offers And Services",
                                                                                            tl: /* [] */0
                                                                                          }
                                                                                        }
                                                                                      }
                                                                                    }
                                                                                  }
                                                                                }
                                                                              }
                                                                            }
                                                                          }
                                                                        }
                                                                      }
                                                                    }
                                                                  }
                                                                }
                                                              }
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
};

function id(t) {
  return t.id;
}

function msisdn(t) {
  return t.msisdn;
}

function lob(t) {
  return t.lob;
}

function shopName(t) {
  return t.shopName;
}

function status(t) {
  return t.status;
}

function longTextResponse(t) {
  return t.longTextResponse;
}

function sentiment(t) {
  return t.sentiment;
}

function triggerDate(t) {
  return t.triggerDate;
}

function categories(t) {
  return t.categories;
}

function edited(t) {
  return t.edited;
}

function lastEditedAt(t) {
  return t.lastEditedAt;
}

function lastEditedBy(t) {
  return t.lastEditedBy;
}

function setCategories(categories, t) {
  return {
          id: t.id,
          msisdn: t.msisdn,
          lob: t.lob,
          shopName: t.shopName,
          status: t.status,
          triggerDate: t.triggerDate,
          longTextResponse: t.longTextResponse,
          sentiment: t.sentiment,
          categories: categories,
          edited: t.edited,
          lastEditedAt: t.lastEditedAt,
          lastEditedBy: t.lastEditedBy
        };
}

function setEdited(edited, t) {
  return {
          id: t.id,
          msisdn: t.msisdn,
          lob: t.lob,
          shopName: t.shopName,
          status: t.status,
          triggerDate: t.triggerDate,
          longTextResponse: t.longTextResponse,
          sentiment: t.sentiment,
          categories: t.categories,
          edited: edited,
          lastEditedAt: t.lastEditedAt,
          lastEditedBy: t.lastEditedBy
        };
}

function setSentiment(sentiment, t) {
  return {
          id: t.id,
          msisdn: t.msisdn,
          lob: t.lob,
          shopName: t.shopName,
          status: t.status,
          triggerDate: t.triggerDate,
          longTextResponse: t.longTextResponse,
          sentiment: sentiment,
          categories: t.categories,
          edited: t.edited,
          lastEditedAt: t.lastEditedAt,
          lastEditedBy: t.lastEditedBy
        };
}

function fromJson(json) {
  return {
          id: Json_decode.field("response_id", Json_decode.$$int, json),
          msisdn: Json_decode.optional((function (param) {
                  return Json_decode.field("msisdn", Json_decode.string, param);
                }), json),
          lob: Json_decode.optional((function (param) {
                  return Json_decode.field("lob", Json_decode.string, param);
                }), json),
          shopName: Json_decode.optional((function (param) {
                  return Json_decode.field("shop_name", Json_decode.string, param);
                }), json),
          status: Json_decode.field("status", Json_decode.string, json),
          triggerDate: MomentRe.moment(undefined, Json_decode.field("trigger_date", Json_decode.string, json)),
          longTextResponse: Json_decode.field("long_text_response", Json_decode.string, json),
          sentiment: Json_decode.field("sentiment", Json_decode.string, json),
          categories: Json_decode.field("categories", (function (param) {
                  return Json_decode.list(Json_decode.string, param);
                }), json),
          edited: Json_decode.field("edited", Json_decode.bool, json),
          lastEditedAt: Belt_Option.map(Json_decode.field("last_edited_info", (function (param) {
                      return Json_decode.optional((function (json) {
                                    return Json_decode.field("updated_at", Json_decode.string, json);
                                  }), param);
                    }), json), (function (x) {
                  return MomentRe.moment(undefined, x);
                })),
          lastEditedBy: Json_decode.field("last_edited_info", (function (param) {
                  return Json_decode.optional((function (json) {
                                return Json_decode.field("editor", (function (json) {
                                              return Json_decode.field("email", Json_decode.string, json);
                                            }), json);
                              }), param);
                }), json)
        };
}

var CategoryReport = {
  id: id,
  msisdn: msisdn,
  lob: lob,
  shopName: shopName,
  status: status,
  longTextResponse: longTextResponse,
  sentiment: sentiment,
  triggerDate: triggerDate,
  categories: categories,
  edited: edited,
  lastEditedAt: lastEditedAt,
  lastEditedBy: lastEditedBy,
  setCategories: setCategories,
  setEdited: setEdited,
  setSentiment: setSentiment,
  fromJson: fromJson
};

var initialState = {
  loading: true,
  filter: false,
  editCategory: undefined,
  categoryReport: /* [] */0,
  total: 1,
  perPage: 1
};

function CategoryEdit(Props) {
  var params = Props.params;
  var match = React.useState(function () {
        return initialState;
      });
  var setState = match[1];
  var state = match[0];
  var queryParams = Querystringify.parse(params);
  var x = Js_dict.get(queryParams, "page");
  var currentPage;
  if (x !== undefined && x !== "") {
    var page = Belt_Int.fromString(x);
    currentPage = page !== undefined ? page : 1;
  } else {
    currentPage = 1;
  }
  var x$1 = Js_dict.get(queryParams, "sortedCol");
  var sortedColumn = x$1 !== undefined && x$1 !== "" ? x$1 : undefined;
  var x$2 = Js_dict.get(queryParams, "sortOrder");
  var sortingOrder = x$2 !== undefined && x$2 !== "" ? x$2 : undefined;
  var initialSortingInfo = {
    sortedColumn: sortedColumn,
    sortingOrder: sortingOrder
  };
  var match$1 = React.useState(function () {
        return initialSortingInfo;
      });
  var setSortingInfo = match$1[1];
  var sortingInfo = match$1[0];
  var match$2 = React.useState(function () {
        return false;
      });
  var setOpenSortModal = match$2[1];
  var fetchCategoryReport = function (pageNo) {
    var match = sortingInfo.sortedColumn;
    var match$1 = sortingInfo.sortingOrder;
    var url = match !== undefined ? (
        match$1 !== undefined ? "/api/v1/campaign_reports/text_category_report?page=" + (String(pageNo) + ("&sort_column=" + (match + ("&sort_order=" + match$1)))) : "/api/v1/campaign_reports/text_category_report?page=" + String(pageNo)
      ) : "/api/v1/campaign_reports/text_category_report?page=" + String(pageNo);
    return Http$ReactHooksTemplate.fetchGetJSONWithHeader(url).then(function (param) {
                var headers = param[0];
                var total = Caml_format.caml_int_of_string(Belt_Option.getWithDefault(Caml_option.null_to_opt(headers.get("Total")), "1"));
                var perPage = Caml_format.caml_int_of_string(Belt_Option.getWithDefault(Caml_option.null_to_opt(headers.get("Per-Page")), "20"));
                var report = param[1].then(function (json) {
                      return Promise.resolve(Json_decode.field("report", (function (param) {
                                        return Json_decode.list(fromJson, param);
                                      }), json));
                    });
                return Promise.resolve([
                            report,
                            [
                              total,
                              perPage
                            ]
                          ]);
              });
  };
  var applySort = function (param) {
    var sortedCol = sortingInfo.sortedColumn;
    var sortOrder = sortingInfo.sortingOrder;
    RescriptReactRouter.push("/category_edit?page=1&sortedCol=" + sortedCol + "&sortOrder=" + sortOrder);
    Curry._1(setState, (function (state) {
            return {
                    loading: true,
                    filter: false,
                    editCategory: state.editCategory,
                    categoryReport: state.categoryReport,
                    total: state.total,
                    perPage: state.perPage
                  };
          }));
    fetchCategoryReport(1).then(function (param) {
          var match = param[1];
          var perPage = match[1];
          var total = match[0];
          return Promise.resolve(param[0].then(function (categoryReport) {
                          return Promise.resolve(Curry._1(setState, (function (state) {
                                            return {
                                                    loading: false,
                                                    filter: state.filter,
                                                    editCategory: state.editCategory,
                                                    categoryReport: categoryReport,
                                                    total: total,
                                                    perPage: perPage
                                                  };
                                          })));
                        }));
        });
    
  };
  var handlePageChange = function (pageNumber) {
    return RescriptReactRouter.push("/category_edit?page=" + String(pageNumber));
  };
  React.useEffect((function () {
          fetchCategoryReport(currentPage).then(function (param) {
                var match = param[1];
                var perPage = match[1];
                var total = match[0];
                return Promise.resolve(param[0].then(function (categoryReport) {
                                return Promise.resolve(Curry._1(setState, (function (state) {
                                                  return {
                                                          loading: false,
                                                          filter: state.filter,
                                                          editCategory: state.editCategory,
                                                          categoryReport: categoryReport,
                                                          total: total,
                                                          perPage: perPage
                                                        };
                                                })));
                              }));
              });
          
        }), [currentPage]);
  var onApplyFilter = function (param) {
    if (currentPage > 1) {
      return RescriptReactRouter.push("/category_edit");
    } else {
      Curry._1(setState, (function (state) {
              return {
                      loading: true,
                      filter: false,
                      editCategory: state.editCategory,
                      categoryReport: state.categoryReport,
                      total: state.total,
                      perPage: state.perPage
                    };
            }));
      fetchCategoryReport(1).then(function (param) {
            var match = param[1];
            var perPage = match[1];
            var total = match[0];
            return Promise.resolve(param[0].then(function (categoryReport) {
                            return Promise.resolve(Curry._1(setState, (function (state) {
                                              return {
                                                      loading: false,
                                                      filter: state.filter,
                                                      editCategory: state.editCategory,
                                                      categoryReport: categoryReport,
                                                      total: total,
                                                      perPage: perPage
                                                    };
                                            })));
                          }));
          });
      return ;
    }
  };
  if (state.loading) {
    return React.createElement("p", undefined, "Loading...");
  } else {
    return React.createElement(React.Fragment, undefined, state.filter ? React.createElement(Filters$ReactHooksTemplate.make, {
                      onClose: (function (param) {
                          return Curry._1(setState, (function (state) {
                                        return {
                                                loading: state.loading,
                                                filter: false,
                                                editCategory: state.editCategory,
                                                categoryReport: state.categoryReport,
                                                total: state.total,
                                                perPage: state.perPage
                                              };
                                      }));
                        }),
                      onApply: onApplyFilter,
                      showDateFilter: false,
                      showFilterPills: false
                    }) : React.createElement(React.Fragment, undefined), React.createElement("div", undefined, React.createElement("div", {
                        className: "flex justify-between items-center mb-30-px border-b border-gray-300 px-30-px py-16-px"
                      }, React.createElement("h2", {
                            className: "text-xl font-semibold"
                          }, "Category Report"), React.createElement("button", {
                            className: "bg-ghostwhite border border-gray-400\n              shadow-inner flex rounded py-2 px-3 text-xs",
                            id: "filters",
                            onClick: (function (param) {
                                return Curry._1(setState, (function (state) {
                                              return {
                                                      loading: state.loading,
                                                      filter: true,
                                                      editCategory: state.editCategory,
                                                      categoryReport: state.categoryReport,
                                                      total: state.total,
                                                      perPage: state.perPage
                                                    };
                                            }));
                              })
                          }, React.createElement("img", {
                                className: "h-14-px mr-2-px",
                                src: Image$ReactHooksTemplate.filterButton
                              }), "Filters")), React.createElement("div", {
                        className: "flex items-center px-30-px"
                      }, React.createElement("table", {
                            className: "text-left table-auto w-full border-separate text-xs font-medium"
                          }, React.createElement("thead", {
                                className: "font-medium p-4 bg-ghostwhite text-aurometalsaurus"
                              }, React.createElement("tr", undefined, React.createElement("th", {
                                        className: "px-4 py-2"
                                      }, React.createElement("div", {
                                            className: "flex cursor-pointer",
                                            onClick: (function (param) {
                                                return Curry._1(setOpenSortModal, (function (param) {
                                                              return true;
                                                            }));
                                              })
                                          }, "Trigger Date", React.createElement("img", {
                                                className: "ml-4-px sort-img",
                                                src: Image$ReactHooksTemplate.heading
                                              })), match$2[0] ? React.createElement("div", {
                                              className: "flex border border-gray-400 rounded-lg shadow-md flex-col justify-start\n                          items-start gap-2 p-4 z-10 absolute bg-white"
                                            }, React.createElement("div", {
                                                  className: "text-sm font-light text-aurometalsaurus"
                                                }, "Sort"), React.createElement("button", {
                                                  className: "w-48 flex flex-row justify-start items-center p-2 rounded " + (
                                                    Belt_Option.getWithDefault(sortingInfo.sortedColumn, "") !== "trigger_date" || Belt_Option.getWithDefault(sortingInfo.sortingOrder, "") === "desc" ? "bg-lavenderweb border border-lavenderweb" : ""
                                                  ),
                                                  onClick: (function (param) {
                                                      return Curry._1(setSortingInfo, (function (param) {
                                                                    return {
                                                                            sortedColumn: "trigger_date",
                                                                            sortingOrder: "desc"
                                                                          };
                                                                  }));
                                                    })
                                                }, "Sort by latest first"), React.createElement("button", {
                                                  className: "w-48 flex flex-row justify-start items-center p-2 rounded " + (
                                                    Belt_Option.getWithDefault(sortingInfo.sortedColumn, "") === "trigger_date" && Belt_Option.getWithDefault(sortingInfo.sortingOrder, "") === "asc" ? "bg-lavenderweb border border-lavenderweb" : ""
                                                  ),
                                                  onClick: (function (param) {
                                                      return Curry._1(setSortingInfo, (function (param) {
                                                                    return {
                                                                            sortedColumn: "trigger_date",
                                                                            sortingOrder: "asc"
                                                                          };
                                                                  }));
                                                    })
                                                }, "Sort by oldest first"), React.createElement("hr", {
                                                  className: "border border-gray-200 w-full"
                                                }), React.createElement("div", {
                                                  className: "flex justify-between w-full font-semibold text-sm gap-2"
                                                }, React.createElement("button", {
                                                      className: "py-2 w-1/2 border border-gray-300 text-aurometalsaurus rounded",
                                                      onClick: (function (param) {
                                                          return Curry._1(setOpenSortModal, (function (param) {
                                                                        return false;
                                                                      }));
                                                        })
                                                    }, "Cancel"), React.createElement("button", {
                                                      className: "py-2 w-1/2 bg-pigmentblue text-white rounded",
                                                      onClick: (function (param) {
                                                          applySort(undefined);
                                                          return Curry._1(setOpenSortModal, (function (param) {
                                                                        return false;
                                                                      }));
                                                        })
                                                    }, "Apply"))) : React.createElement(React.Fragment, undefined)), React.createElement("th", {
                                        className: "px-4 py-2"
                                      }, React.createElement("div", {
                                            className: "flex"
                                          }, "MSISDN", React.createElement("img", {
                                                className: "ml-4-px",
                                                src: Image$ReactHooksTemplate.heading
                                              }))), React.createElement("th", {
                                        className: "px-4 py-2"
                                      }, React.createElement("div", {
                                            className: "flex"
                                          }, "LOB", React.createElement("img", {
                                                className: "ml-4-px",
                                                src: Image$ReactHooksTemplate.heading
                                              }))), React.createElement("th", {
                                        className: "px-4 py-2"
                                      }, React.createElement("div", {
                                            className: "flex"
                                          }, "SHOP NAME", React.createElement("img", {
                                                className: "ml-4-px",
                                                src: Image$ReactHooksTemplate.heading
                                              }))), React.createElement("th", {
                                        className: "px-4 py-2"
                                      }, React.createElement("div", {
                                            className: "flex"
                                          }, "OPEN COMMENT", React.createElement("img", {
                                                className: "ml-4-px",
                                                src: Image$ReactHooksTemplate.heading
                                              }))), React.createElement("th", {
                                        className: "px-4 py-2"
                                      }, React.createElement("div", {
                                            className: "flex"
                                          }, "LAST EDITED AT", React.createElement("img", {
                                                className: "ml-4-px",
                                                src: Image$ReactHooksTemplate.heading
                                              }))), React.createElement("th", {
                                        className: "px-4 py-2"
                                      }, React.createElement("div", {
                                            className: "flex"
                                          }, "LAST EDITED BY", React.createElement("img", {
                                                className: "ml-4-px",
                                                src: Image$ReactHooksTemplate.heading
                                              }))), React.createElement("th", {
                                        className: "px-4 py-2"
                                      }, React.createElement("div", {
                                            className: "flex"
                                          }, "SENTIMENT", React.createElement("img", {
                                                className: "ml-4-px",
                                                src: Image$ReactHooksTemplate.heading
                                              }))), React.createElement("th", {
                                        className: "px-4 py-2"
                                      }, React.createElement("div", {
                                            className: "flex"
                                          }, "CATEGORIES", React.createElement("img", {
                                                className: "ml-4-px",
                                                src: Image$ReactHooksTemplate.heading
                                              }))), React.createElement("th", {
                                        className: "px-4 py-2"
                                      }, React.createElement("div", {
                                            className: "flex"
                                          })))), React.createElement("tbody", undefined, $$Array.of_list(List.map((function (x) {
                                          var y = x.msisdn;
                                          var lob = x.lob;
                                          var y$1 = x.shopName;
                                          var categoryReport = state.editCategory;
                                          var categoryReport$1 = state.editCategory;
                                          return React.createElement("tr", {
                                                      className: "border-b border-bottom-gray-600"
                                                    }, React.createElement("td", {
                                                          className: "px-4 py-2"
                                                        }, x.triggerDate.format("YYYY/MM/DD HH:MM:SS")), React.createElement("td", {
                                                          className: "px-4 py-2"
                                                        }, y !== undefined ? y : "-"), React.createElement("td", {
                                                          className: "px-4 py-2"
                                                        }, lob !== undefined ? lob : "-"), React.createElement("td", {
                                                          className: "px-4 py-2"
                                                        }, y$1 !== undefined ? y$1 : "-"), React.createElement("td", {
                                                          className: "px-4 py-2"
                                                        }, x.longTextResponse), React.createElement("td", {
                                                          className: "px-4 py-2"
                                                        }, Belt_Option.mapWithDefault(x.lastEditedAt, "-", (function (y) {
                                                                return y.format("YYYY/MM/DD HH:MM:SS");
                                                              }))), React.createElement("td", {
                                                          className: "px-4 py-2"
                                                        }, Belt_Option.getWithDefault(x.lastEditedBy, "-")), React.createElement("td", {
                                                          className: "px-4 py-2"
                                                        }, React.createElement("div", {
                                                              className: "w-full"
                                                            }, categoryReport !== undefined ? (
                                                                categoryReport.id === x.id ? React.createElement("select", {
                                                                        className: "border\n                                      border-gray-400 shade w-full",
                                                                        placeholder: "Select Sentiment",
                                                                        value: x.sentiment === "-" ? "Select Sentiment" : x.sentiment,
                                                                        onChange: (function (param) {
                                                                            var sentiment = Utils$ReactHooksTemplate.formTargetValue(param);
                                                                            var newCategoryReport = setSentiment(sentiment, x);
                                                                            var newCategoryReportList = List.map((function (x$1) {
                                                                                    if (x$1.id === x.id) {
                                                                                      return newCategoryReport;
                                                                                    } else {
                                                                                      return x$1;
                                                                                    }
                                                                                  }), state.categoryReport);
                                                                            return Curry._1(setState, (function (state) {
                                                                                          return {
                                                                                                  loading: state.loading,
                                                                                                  filter: state.filter,
                                                                                                  editCategory: state.editCategory,
                                                                                                  categoryReport: newCategoryReportList,
                                                                                                  total: state.total,
                                                                                                  perPage: state.perPage
                                                                                                };
                                                                                        }));
                                                                          })
                                                                      }, React.createElement("option", {
                                                                            key: "0",
                                                                            disabled: true,
                                                                            value: "Select Sentiment"
                                                                          }, "Select Sentiment"), React.createElement("option", {
                                                                            key: "1",
                                                                            selected: "positive" === x.sentiment,
                                                                            value: "positive"
                                                                          }, "Positive"), React.createElement("option", {
                                                                            key: "2",
                                                                            selected: "Negative" === x.sentiment,
                                                                            value: "negative"
                                                                          }, "Negative"), React.createElement("option", {
                                                                            key: "3",
                                                                            selected: "neutral" === x.sentiment,
                                                                            value: "neutral"
                                                                          }, "Neutral"), React.createElement("option", {
                                                                            key: "4",
                                                                            selected: "na" === x.sentiment,
                                                                            value: "na"
                                                                          }, "NA")) : React.createElement("p", undefined, x.sentiment)
                                                              ) : React.createElement("p", {
                                                                    className: "capitalize"
                                                                  }, x.sentiment))), React.createElement("td", {
                                                          className: "px-4 py-2"
                                                        }, $$Array.of_list(Belt_List.mapWithIndex(x.categories, (function (idx, y) {
                                                                    var categoryReport = state.editCategory;
                                                                    return React.createElement("div", {
                                                                                className: "flex mb-10-px items-center w-full"
                                                                              }, React.createElement("p", {
                                                                                    className: "font-medium"
                                                                                  }, String(idx + 1 | 0) + " : "), categoryReport !== undefined ? (
                                                                                  categoryReport.id === x.id ? React.createElement("select", {
                                                                                          className: "border\n                                      border-gray-400\n                                      shade w-5/6",
                                                                                          placeholder: "Select Category",
                                                                                          value: y === "-" ? "Select Category" : y,
                                                                                          onChange: (function (param) {
                                                                                              var value = Utils$ReactHooksTemplate.formTargetValue(param);
                                                                                              var newCategories = Belt_List.mapWithIndex(x.categories, (function (idx$1, x) {
                                                                                                      if (idx$1 === idx) {
                                                                                                        return value;
                                                                                                      } else {
                                                                                                        return x;
                                                                                                      }
                                                                                                    }));
                                                                                              var newCategoryReport = setCategories(newCategories, x);
                                                                                              var newCategoryReportList = List.map((function (x$1) {
                                                                                                      if (x$1.id === x.id) {
                                                                                                        return newCategoryReport;
                                                                                                      } else {
                                                                                                        return x$1;
                                                                                                      }
                                                                                                    }), state.categoryReport);
                                                                                              return Curry._1(setState, (function (state) {
                                                                                                            return {
                                                                                                                    loading: state.loading,
                                                                                                                    filter: state.filter,
                                                                                                                    editCategory: state.editCategory,
                                                                                                                    categoryReport: newCategoryReportList,
                                                                                                                    total: state.total,
                                                                                                                    perPage: state.perPage
                                                                                                                  };
                                                                                                          }));
                                                                                            })
                                                                                        }, React.createElement("option", {
                                                                                              key: "0",
                                                                                              disabled: true,
                                                                                              value: "Select Category"
                                                                                            }, "Select Category"), $$Array.of_list(Belt_List.mapWithIndex(categoryList, (function (id, category) {
                                                                                                    return React.createElement("option", {
                                                                                                                key: String(id + 1 | 0),
                                                                                                                selected: category === y,
                                                                                                                value: category
                                                                                                              }, category);
                                                                                                  })))) : React.createElement("p", {
                                                                                          className: "w-5/6"
                                                                                        }, y)
                                                                                ) : React.createElement("p", undefined, y));
                                                                  })))), React.createElement("td", {
                                                          className: "px-4 py-2"
                                                        }, React.createElement("div", {
                                                              className: "flex w-full justify-center"
                                                            }, categoryReport$1 !== undefined ? (
                                                                categoryReport$1.id === x.id ? React.createElement("div", {
                                                                        className: "flex flex-col"
                                                                      }, React.createElement("button", {
                                                                            className: "bg-teal-500 hover:bg-teal-700\n                                    py-6-px px-20-px font-normal text-white\n                                    font-16-px leading-snug mb-10-px",
                                                                            style: {
                                                                              outline: "None"
                                                                            },
                                                                            onClick: (function (param) {
                                                                                var params = {};
                                                                                params["id"] = String(x.id);
                                                                                if (x.sentiment !== "-") {
                                                                                  params["sentiment"] = x.sentiment;
                                                                                }
                                                                                params["categories"] = Json_encode.list((function (prim) {
                                                                                        return prim;
                                                                                      }), List.filter(function (x) {
                                                                                            return x !== "-";
                                                                                          })(x.categories));
                                                                                Http$ReactHooksTemplate.fetchPostJSON("/api/v1/long_text_responses/update_categories", params).then(function (param) {
                                                                                      var newCategoryReport = setEdited(true, x);
                                                                                      var newCategoryReportList = List.map((function (x$1) {
                                                                                              if (x$1.id === x.id) {
                                                                                                return newCategoryReport;
                                                                                              } else {
                                                                                                return x$1;
                                                                                              }
                                                                                            }), state.categoryReport);
                                                                                      return Promise.resolve(Curry._1(setState, (function (state) {
                                                                                                        return {
                                                                                                                loading: state.loading,
                                                                                                                filter: state.filter,
                                                                                                                editCategory: undefined,
                                                                                                                categoryReport: newCategoryReportList,
                                                                                                                total: state.total,
                                                                                                                perPage: state.perPage
                                                                                                              };
                                                                                                      })));
                                                                                    });
                                                                                
                                                                              })
                                                                          }, "Update"), React.createElement("button", {
                                                                            className: "hover:text-gray-500 py-6-px\n                                  px-10-px border border-gray-400\n                                  hover:border-gray-300 mr-20-px",
                                                                            style: {
                                                                              outline: "None",
                                                                              outlineStyle: "unset"
                                                                            },
                                                                            onClick: (function (param) {
                                                                                return Curry._1(setState, (function (state) {
                                                                                              return {
                                                                                                      loading: state.loading,
                                                                                                      filter: state.filter,
                                                                                                      editCategory: undefined,
                                                                                                      categoryReport: state.categoryReport,
                                                                                                      total: state.total,
                                                                                                      perPage: state.perPage
                                                                                                    };
                                                                                            }));
                                                                              })
                                                                          }, "cancel")) : React.createElement("div", {
                                                                        className: "flex items-center"
                                                                      }, React.createElement("button", {
                                                                            className: "hover:text-teal-500 py-6-px\n                                  px-10-px border border-gray-400\n                                  hover:border-teal-300 mr-20-px",
                                                                            style: {
                                                                              outline: "None",
                                                                              outlineStyle: "unset"
                                                                            },
                                                                            onClick: (function (param) {
                                                                                return Curry._1(setState, (function (state) {
                                                                                              return {
                                                                                                      loading: state.loading,
                                                                                                      filter: state.filter,
                                                                                                      editCategory: x,
                                                                                                      categoryReport: state.categoryReport,
                                                                                                      total: state.total,
                                                                                                      perPage: state.perPage
                                                                                                    };
                                                                                            }));
                                                                              })
                                                                          }, "Edit"))
                                                              ) : React.createElement("div", {
                                                                    className: "flex items-center"
                                                                  }, React.createElement("button", {
                                                                        className: "hover:text-teal-500 py-6-px px-10-px\n                              border border-gray-400 hover:border-teal-300\n                              mr-20-px",
                                                                        style: {
                                                                          outline: "None",
                                                                          outlineStyle: "unset"
                                                                        },
                                                                        onClick: (function (param) {
                                                                            return Curry._1(setState, (function (state) {
                                                                                          return {
                                                                                                  loading: state.loading,
                                                                                                  filter: state.filter,
                                                                                                  editCategory: x,
                                                                                                  categoryReport: state.categoryReport,
                                                                                                  total: state.total,
                                                                                                  perPage: state.perPage
                                                                                                };
                                                                                        }));
                                                                          })
                                                                      }, "Edit")))));
                                        }), state.categoryReport)))))), React.createElement("div", {
                    className: "flex justify-end"
                  }, React.createElement(Pagination$ReactHooksTemplate.make, {
                        total: Caml_int32.div(state.total - 1 | 0, state.perPage) + 1 | 0,
                        currentPage: currentPage,
                        handlePageChange: handlePageChange
                      })));
  }
}

var make = CategoryEdit;

exports.categoryList = categoryList;
exports.CategoryReport = CategoryReport;
exports.initialState = initialState;
exports.make = make;
/* react Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var List = require("rescript/lib/js/list.js");
var Js_exn = require("rescript/lib/js/js_exn.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");
var Nps$ReactHooksTemplate = require("./Nps.bs.js");
var DropDown$ReactHooksTemplate = require("./DropDown.bs.js");
var Question$ReactHooksTemplate = require("./Question.bs.js");
var ListCondition$ReactHooksTemplate = require("./ListCondition.bs.js");
var MultipleChoice$ReactHooksTemplate = require("./MultipleChoice.bs.js");
var MultipleSelect$ReactHooksTemplate = require("./MultipleSelect.bs.js");
var NumberCondition$ReactHooksTemplate = require("./numberCondition.bs.js");
var StringCondition$ReactHooksTemplate = require("./StringCondition.bs.js");

function id(t) {
  return t.id;
}

function conditionType(t) {
  return t.conditionType;
}

function setConditionType(t, conditionType) {
  return {
          id: t.id,
          conditionType: conditionType
        };
}

function lhs(t) {
  var x = t.conditionType;
  switch (x.TAG | 0) {
    case /* NumberCondition */0 :
        return NumberCondition$ReactHooksTemplate.lhs(x._0);
    case /* StringCondition */1 :
        return StringCondition$ReactHooksTemplate.lhs(x._0);
    case /* ListCondition */2 :
        return ListCondition$ReactHooksTemplate.lhs(x._0);
    
  }
}

function emptyCondition(idOpt, question) {
  var id = idOpt !== undefined ? Caml_option.valFromOption(idOpt) : undefined;
  var nps = question.questionType;
  switch (nps.TAG | 0) {
    case /* Nps */4 :
        var condition_operator = List.hd(NumberCondition$ReactHooksTemplate.availableOperators(undefined));
        var condition_rhs = Nps$ReactHooksTemplate.includeZero(nps._0) ? 0 : 1;
        var condition = {
          lhs: question,
          operator: condition_operator,
          rhs: condition_rhs
        };
        return {
                id: id,
                conditionType: {
                  TAG: /* NumberCondition */0,
                  _0: condition
                }
              };
    case /* MultipleChoice */5 :
        var rhs = List.hd(MultipleChoice$ReactHooksTemplate.choices(nps._0));
        var condition_operator$1 = List.hd(ListCondition$ReactHooksTemplate.availableOperators(question));
        var condition_rhs$1 = {
          hd: rhs,
          tl: /* [] */0
        };
        var condition$1 = {
          lhs: question,
          operator: condition_operator$1,
          rhs: condition_rhs$1
        };
        return {
                id: id,
                conditionType: {
                  TAG: /* ListCondition */2,
                  _0: condition$1
                }
              };
    case /* MultipleSelect */9 :
        var rhs$1 = List.hd(MultipleSelect$ReactHooksTemplate.answerList(nps._0));
        var condition_operator$2 = List.hd(ListCondition$ReactHooksTemplate.availableOperators(question));
        var condition_rhs$2 = {
          hd: rhs$1,
          tl: /* [] */0
        };
        var condition$2 = {
          lhs: question,
          operator: condition_operator$2,
          rhs: condition_rhs$2
        };
        return {
                id: id,
                conditionType: {
                  TAG: /* ListCondition */2,
                  _0: condition$2
                }
              };
    case /* DropDown */10 :
        var rhs$2 = List.hd(DropDown$ReactHooksTemplate.answerList(nps._0));
        var condition_operator$3 = List.hd(ListCondition$ReactHooksTemplate.availableOperators(question));
        var condition_rhs$3 = {
          hd: rhs$2,
          tl: /* [] */0
        };
        var condition$3 = {
          lhs: question,
          operator: condition_operator$3,
          rhs: condition_rhs$3
        };
        return {
                id: id,
                conditionType: {
                  TAG: /* ListCondition */2,
                  _0: condition$3
                }
              };
    case /* LongText */2 :
    case /* ShortText */3 :
    case /* MobileNumber */11 :
        break;
    default:
      var condition_operator$4 = List.hd(NumberCondition$ReactHooksTemplate.availableOperators(undefined));
      var condition$4 = {
        lhs: question,
        operator: condition_operator$4,
        rhs: 1
      };
      return {
              id: id,
              conditionType: {
                TAG: /* NumberCondition */0,
                _0: condition$4
              }
            };
  }
  var condition_operator$5 = List.hd(StringCondition$ReactHooksTemplate.availableOperators(undefined));
  var condition$5 = {
    lhs: question,
    operator: condition_operator$5,
    rhs: ""
  };
  return {
          id: id,
          conditionType: {
            TAG: /* StringCondition */1,
            _0: condition$5
          }
        };
}

function decodeConditionType(json, questions) {
  var conditionType = Json_decode.field("condition_type", Json_decode.string, json);
  switch (conditionType) {
    case "list_condition" :
        return {
                TAG: /* ListCondition */2,
                _0: ListCondition$ReactHooksTemplate.decode(json, questions)
              };
    case "number_condition" :
        return {
                TAG: /* NumberCondition */0,
                _0: NumberCondition$ReactHooksTemplate.decode(json, questions)
              };
    case "string_condition" :
        return {
                TAG: /* StringCondition */1,
                _0: StringCondition$ReactHooksTemplate.decode(json, questions)
              };
    default:
      return Js_exn.raiseError("Unknown trigger type");
  }
}

function decode(json, questions) {
  return {
          id: Json_decode.field("id", (function (param) {
                  return Json_decode.optional(Json_decode.$$int, param);
                }), json),
          conditionType: decodeConditionType(json, questions)
        };
}

function encodeConditionType(t) {
  var match = t.conditionType;
  switch (match.TAG | 0) {
    case /* NumberCondition */0 :
        return "number_condition";
    case /* StringCondition */1 :
        return "string_condition";
    case /* ListCondition */2 :
        return "list_condition";
    
  }
}

function lhsId(t) {
  var x = t.conditionType;
  switch (x.TAG | 0) {
    case /* NumberCondition */0 :
        return Question$ReactHooksTemplate.id(NumberCondition$ReactHooksTemplate.lhs(x._0));
    case /* StringCondition */1 :
        return Question$ReactHooksTemplate.id(StringCondition$ReactHooksTemplate.lhs(x._0));
    case /* ListCondition */2 :
        return Question$ReactHooksTemplate.id(ListCondition$ReactHooksTemplate.lhs(x._0));
    
  }
}

function operator(t) {
  var x = t.conditionType;
  switch (x.TAG | 0) {
    case /* NumberCondition */0 :
        return NumberCondition$ReactHooksTemplate.operator(x._0);
    case /* StringCondition */1 :
        return StringCondition$ReactHooksTemplate.operator(x._0);
    case /* ListCondition */2 :
        return ListCondition$ReactHooksTemplate.operator(x._0);
    
  }
}

function encodeRhs(t) {
  var x = t.conditionType;
  switch (x.TAG | 0) {
    case /* NumberCondition */0 :
        return NumberCondition$ReactHooksTemplate.encodeRhs(x._0);
    case /* StringCondition */1 :
        return StringCondition$ReactHooksTemplate.encodeRhs(x._0);
    case /* ListCondition */2 :
        return ListCondition$ReactHooksTemplate.encodeRhs(x._0);
    
  }
}

function toJson(destroyOpt, t) {
  var destroy = destroyOpt !== undefined ? destroyOpt : false;
  return Json_encode.object_({
              hd: [
                "id",
                Json_encode.nullable((function (prim) {
                        return prim;
                      }), t.id)
              ],
              tl: {
                hd: [
                  "lhs_id",
                  Json_encode.nullable((function (prim) {
                          return prim;
                        }), lhsId(t))
                ],
                tl: {
                  hd: [
                    "lhs_type",
                    "Question"
                  ],
                  tl: {
                    hd: [
                      "operator",
                      operator(t)
                    ],
                    tl: {
                      hd: [
                        "condition_type",
                        encodeConditionType(t)
                      ],
                      tl: {
                        hd: [
                          "rhs",
                          encodeRhs(t)
                        ],
                        tl: {
                          hd: [
                            "_destroy",
                            destroy
                          ],
                          tl: /* [] */0
                        }
                      }
                    }
                  }
                }
              }
            });
}

exports.id = id;
exports.conditionType = conditionType;
exports.setConditionType = setConditionType;
exports.lhs = lhs;
exports.emptyCondition = emptyCondition;
exports.decode = decode;
exports.toJson = toJson;
/* Nps-ReactHooksTemplate Not a pure module */

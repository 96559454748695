// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Pervasives = require("rescript/lib/js/pervasives.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Http$ReactHooksTemplate = require("../common/http.bs.js");
var Utils$ReactHooksTemplate = require("../common/utils.bs.js");
var Domain$ReactHooksTemplate = require("../settings/domain.bs.js");
var SelectBoxNew$ReactHooksTemplate = require("../common/SelectBoxNew.bs.js");
var SmtpSettings$ReactHooksTemplate = require("./SmtpSettings.bs.js");
var TextInputNew$ReactHooksTemplate = require("../common/TextInputNew.bs.js");
var AlertComponent$ReactHooksTemplate = require("../common/AlertComponent.bs.js");
var NewPasswordInput$ReactHooksTemplate = require("../common/NewPasswordInput.bs.js");

var initialState = {
  notification: undefined
};

function fetchSmtpConf(param) {
  return Http$ReactHooksTemplate.fetchGetJSONDecode("/api/v1/smtp_configurations", SmtpSettings$ReactHooksTemplate.decode);
}

function EmailChannel(Props) {
  var match = React.useState(function () {
        return SmtpSettings$ReactHooksTemplate.initialConf;
      });
  var setsmtpConf = match[1];
  var smtpConf = match[0];
  var match$1 = React.useState(function () {
        return initialState;
      });
  var setState = match$1[1];
  React.useEffect((function () {
          Http$ReactHooksTemplate.fetchGetJSONDecode("/api/v1/smtp_configurations", SmtpSettings$ReactHooksTemplate.decode).then(function (conf) {
                return Promise.resolve(Curry._1(setsmtpConf, (function (param) {
                                  return conf;
                                })));
              });
          
        }), []);
  var buildSmtpConfigDict = function (form) {
    var params = {};
    params["id"] = SmtpSettings$ReactHooksTemplate.configId(form);
    params["address"] = SmtpSettings$ReactHooksTemplate.address(form);
    params["port"] = SmtpSettings$ReactHooksTemplate.port(form);
    var uName = SmtpSettings$ReactHooksTemplate.userName(form);
    params["user_name"] = uName !== undefined ? uName : "";
    params["password"] = Curry._1(Domain$ReactHooksTemplate.$$String.Password.show, SmtpSettings$ReactHooksTemplate.password(form));
    var openSsl = SmtpSettings$ReactHooksTemplate.opensslVerifyMode(form);
    params["openssl_verify_mode"] = openSsl !== undefined ? openSsl : "";
    params["enable_starttls_auto"] = Pervasives.string_of_bool(SmtpSettings$ReactHooksTemplate.enableStarttlsAuto(form));
    return params;
  };
  var handleInputAddressChange = function ($$event) {
    var value = Utils$ReactHooksTemplate.formTargetValue($$event);
    return Curry._1(setsmtpConf, (function (smtpConf) {
                  return SmtpSettings$ReactHooksTemplate.setAddress(smtpConf, value);
                }));
  };
  var handleInputPortChange = function ($$event) {
    var port = Utils$ReactHooksTemplate.formTargetValue($$event);
    return Curry._1(setsmtpConf, (function (smtpConf) {
                  return SmtpSettings$ReactHooksTemplate.setPort(smtpConf, port);
                }));
  };
  var handleInputUserNameChange = function ($$event) {
    var userName = Utils$ReactHooksTemplate.formTargetValue($$event);
    return Curry._1(setsmtpConf, (function (smtpConf) {
                  return SmtpSettings$ReactHooksTemplate.setUserName(smtpConf, userName);
                }));
  };
  var handleInputPasswordChange = function ($$event) {
    var password = Utils$ReactHooksTemplate.formTargetValue($$event);
    return Curry._1(setsmtpConf, (function (smtpConf) {
                  return SmtpSettings$ReactHooksTemplate.setPassWord(smtpConf, Curry._1(Domain$ReactHooksTemplate.$$String.Password.create, password));
                }));
  };
  var handleInputChangeForOpenSSl = function ($$event) {
    var openSsl = Utils$ReactHooksTemplate.formTargetValue($$event);
    return Curry._1(setsmtpConf, (function (smtpConf) {
                  return SmtpSettings$ReactHooksTemplate.setopensslVerifyMode(smtpConf, openSsl);
                }));
  };
  var handleInputChangeForEnableStarttls = function ($$event) {
    var enalbeStart = Utils$ReactHooksTemplate.formTargetValue($$event);
    return Curry._1(setsmtpConf, (function (smtpConf) {
                  return SmtpSettings$ReactHooksTemplate.setenableStarttlsAuto(smtpConf, Pervasives.bool_of_string(enalbeStart));
                }));
  };
  var alertType = match$1[0].notification;
  var u = SmtpSettings$ReactHooksTemplate.userName(smtpConf);
  var u$1 = SmtpSettings$ReactHooksTemplate.opensslVerifyMode(smtpConf);
  return React.createElement("div", undefined, React.createElement("div", {
                  className: "px-5 py-6 border-b border-gray-300 text-darkcharcoal font-bold text-xl"
                }, "Email", alertType !== undefined ? React.createElement(AlertComponent$ReactHooksTemplate.make, {
                        alertType: alertType,
                        handleClose: (function (param) {
                            return Curry._1(setState, (function (param) {
                                          return {
                                                  notification: undefined
                                                };
                                        }));
                          })
                      }) : React.createElement(React.Fragment, undefined)), React.createElement("div", {
                  className: "p-8 flex flex-col h-full"
                }, React.createElement("div", undefined, React.createElement("div", {
                          className: "text-base font-bold"
                        }, "SMTP Configuration"), React.createElement("form", {
                          onSubmit: (function (param) {
                              param.preventDefault();
                              var configId = SmtpSettings$ReactHooksTemplate.configId(smtpConf);
                              Http$ReactHooksTemplate.fetchPutJSON("/api/v1/smtp_configurations/" + configId, buildSmtpConfigDict(smtpConf)).then(function (response) {
                                    var status = Json_decode.field("status", Json_decode.string, response);
                                    switch (status) {
                                      case "error" :
                                          Curry._1(setState, (function (_state) {
                                                  return {
                                                          notification: {
                                                            TAG: /* Failure */2,
                                                            _0: "Error in updating the Configuration"
                                                          }
                                                        };
                                                }));
                                          break;
                                      case "success" :
                                          Curry._1(setState, (function (_state) {
                                                  return {
                                                          notification: {
                                                            TAG: /* Success */1,
                                                            _0: "Updated the Configuration"
                                                          }
                                                        };
                                                }));
                                          break;
                                      default:
                                        Curry._1(setState, (function (_state) {
                                                return {
                                                        notification: {
                                                          TAG: /* Failure */2,
                                                          _0: "Something went wrong. Please contact admin"
                                                        }
                                                      };
                                              }));
                                    }
                                    return Promise.resolve((console.log(response), undefined));
                                  });
                              return Curry._1(setsmtpConf, (function (param) {
                                            return smtpConf;
                                          }));
                            })
                        }, React.createElement("div", {
                              className: "mt-8"
                            }, React.createElement("div", {
                                  className: "w-2/3 mb-6"
                                }, React.createElement(TextInputNew$ReactHooksTemplate.make, {
                                      label: "Address",
                                      value: SmtpSettings$ReactHooksTemplate.address(smtpConf),
                                      handleChange: handleInputAddressChange,
                                      labelCSS: "text-base font-medium",
                                      placeholder: "Type SMTP Address"
                                    }), SmtpSettings$ReactHooksTemplate.address(smtpConf).length === 0 ? React.createElement("div", {
                                        className: "my-2"
                                      }, React.createElement("p", {
                                            className: "text-red-500 text-xs float-right"
                                          }, "Address can't be emtpy")) : React.createElement(React.Fragment, undefined)), React.createElement("div", {
                                  className: "w-2/3 mb-6"
                                }, React.createElement(TextInputNew$ReactHooksTemplate.make, {
                                      label: "Port",
                                      value: SmtpSettings$ReactHooksTemplate.port(smtpConf),
                                      handleChange: handleInputPortChange,
                                      labelCSS: "text-base font-medium",
                                      placeholder: "Type SMTP Port"
                                    }), SmtpSettings$ReactHooksTemplate.port(smtpConf).length === 0 ? React.createElement("div", {
                                        className: "my-2"
                                      }, React.createElement("p", {
                                            className: "text-red-500 text-xs float-right"
                                          }, "Port can't be emtpy")) : React.createElement(React.Fragment, undefined)), React.createElement("div", {
                                  className: "w-2/3 mb-6"
                                }, React.createElement(TextInputNew$ReactHooksTemplate.make, {
                                      label: "User Name",
                                      value: u !== undefined ? u : "",
                                      handleChange: handleInputUserNameChange,
                                      labelCSS: "text-base font-medium",
                                      placeholder: "Type SMTP User Name"
                                    })), React.createElement("div", {
                                  className: "w-2/3 mb-6"
                                }, React.createElement(NewPasswordInput$ReactHooksTemplate.make, {
                                      password: SmtpSettings$ReactHooksTemplate.password(smtpConf),
                                      label: "Password",
                                      handleChange: handleInputPasswordChange,
                                      passwordInputClassName: "outline-none border border-gray-400 h-10 w-3/5 rounded px-3 py-2 text-sm",
                                      labelClassName: "text-base font-medium",
                                      containerClassName: "flex justify-between items-center"
                                    })), React.createElement("div", {
                                  className: "w-2/3 mb-6"
                                }, React.createElement(TextInputNew$ReactHooksTemplate.make, {
                                      label: "OpenSSL Verify Mode",
                                      value: u$1 !== undefined ? u$1 : "",
                                      handleChange: handleInputChangeForOpenSSl,
                                      labelCSS: "text-base font-medium",
                                      placeholder: ""
                                    })), React.createElement("div", {
                                  className: "w-2/3 mb-6"
                                }, React.createElement(SelectBoxNew$ReactHooksTemplate.make, {
                                      label: "Enable StarttlsAuto",
                                      value: Pervasives.string_of_bool(SmtpSettings$ReactHooksTemplate.enableStarttlsAuto(smtpConf)),
                                      handleChange: handleInputChangeForEnableStarttls,
                                      labelCSS: "text-base font-medium",
                                      options: {
                                        hd: "true",
                                        tl: {
                                          hd: "false",
                                          tl: /* [] */0
                                        }
                                      }
                                    })), React.createElement("div", {
                                  className: "w-2/3 mb-6"
                                }, React.createElement("div", {
                                      className: "flex justify-end"
                                    }, React.createElement("a", {
                                          className: "bg-white ml-4 px-5 py-3 rounded border border-bg-black hover:text-pigmentblue hover:border-pigmentblue text-black font-16px",
                                          href: "/general_settings"
                                        }, "Cancel"), React.createElement("input", {
                                          className: "bg-pigmentblue rounded hover:bg-cetaceanblue ml-4 px-6 py-3 font-normal text-white font-16-px leading-snug",
                                          type: "submit",
                                          value: "Submit"
                                        }))))))));
}

var make = EmailChannel;

exports.initialState = initialState;
exports.fetchSmtpConf = fetchSmtpConf;
exports.make = make;
/* react Not a pure module */

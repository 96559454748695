// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Shortid = require("shortid");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");

function generate(param) {
  return Shortid.generate() + Shortid.generate();
}

function toString(t) {
  return t;
}

function toJson(t) {
  return t;
}

var fromJson = Json_decode.string;

exports.generate = generate;
exports.toString = toString;
exports.toJson = toJson;
exports.fromJson = fromJson;
/* shortid Not a pure module */

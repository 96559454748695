// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var ThankYou$ReactHooksTemplate = require("./ThankYou.bs.js");
var SurveyLanguage$ReactHooksTemplate = require("./surveyLanguage.bs.js");

function ThankYouFormYesbank(Props) {
  var $$default = Props.default;
  var thankYou = Props.thankYou;
  var language = Props.language;
  var redirectionUrl = Props.redirectionUrl;
  React.useEffect((function () {
          var timerId = setTimeout((function (param) {
                  if (redirectionUrl !== undefined) {
                    window.location.href = redirectionUrl;
                    return ;
                  }
                  
                }), 1000);
          return (function (param) {
                    clearTimeout(timerId);
                    
                  });
        }), []);
  var withDefault = function ($$default, x) {
    if (x.length === 0) {
      return $$default;
    } else {
      return x;
    }
  };
  return React.createElement("div", {
              className: "px-12 small:px-4 pb-100-px small:pb-80-px h-full"
            }, React.createElement("div", {
                  className: "h-full bg-cover bg-center rounded"
                }, React.createElement("div", {
                      className: "flex items-center small:items-start justify-center small:px-12 w-full small:pt-32 h-full"
                    }, React.createElement("p", {
                          className: "text-xl leading-normal font-medium small:pl-20-px"
                        }, withDefault(ThankYou$ReactHooksTemplate.text($$default), ThankYou$ReactHooksTemplate.getTextTranslation(thankYou, SurveyLanguage$ReactHooksTemplate.name(language)))))));
}

var make = ThankYouFormYesbank;

exports.make = make;
/* react Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");
var ApplicationSetting$ReactHooksTemplate = require("../general_settings/applicationSetting.bs.js");

var emptyString = "";

function empty(param) {
  return {
          id: undefined,
          name: emptyString,
          logo: undefined,
          applicationSettings: /* [] */0
        };
}

function id(t) {
  return t.id;
}

function logo(t) {
  return t.logo;
}

function name(t) {
  return t.name;
}

function applicationSettings(t) {
  return t.applicationSettings;
}

function setName(t, name) {
  return {
          id: t.id,
          name: name,
          logo: t.logo,
          applicationSettings: t.applicationSettings
        };
}

function setLogo(t, logo) {
  return {
          id: t.id,
          name: t.name,
          logo: logo,
          applicationSettings: t.applicationSettings
        };
}

function setApplicationSettings(t, xs) {
  return {
          id: t.id,
          name: t.name,
          logo: t.logo,
          applicationSettings: xs
        };
}

function decode(json) {
  return {
          id: Json_decode.field("id", (function (param) {
                  return Json_decode.optional(Json_decode.$$int, param);
                }), json),
          name: Json_decode.field("name", Json_decode.string, json),
          logo: Json_decode.optional((function (param) {
                  return Json_decode.field("logo", Json_decode.string, param);
                }), json),
          applicationSettings: Json_decode.field("system_settings", (function (param) {
                  return Json_decode.list(ApplicationSetting$ReactHooksTemplate.decode, param);
                }), json)
        };
}

function encode(t) {
  return Json_encode.object_({
              hd: [
                "name",
                t.name
              ],
              tl: {
                hd: [
                  "system_settings_attributes",
                  Json_encode.list(ApplicationSetting$ReactHooksTemplate.encode, t.applicationSettings)
                ],
                tl: /* [] */0
              }
            });
}

exports.id = id;
exports.emptyString = emptyString;
exports.name = name;
exports.logo = logo;
exports.applicationSettings = applicationSettings;
exports.setName = setName;
exports.setLogo = setLogo;
exports.setApplicationSettings = setApplicationSettings;
exports.empty = empty;
exports.decode = decode;
exports.encode = encode;
/* No side effect */

// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Pervasives = require("rescript/lib/js/pervasives.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");
var Utils$ReactHooksTemplate = require("../../common/utils.bs.js");
var NumberSelected$ReactHooksTemplate = require("./numberSelected.bs.js");

function getMin(t) {
  return t.min;
}

function getMax(t) {
  return t.max;
}

function selected(t) {
  return t.selected;
}

function isSelected(t) {
  return Belt_Option.isSome(t.selected);
}

function setSelected(selected, t) {
  return {
          min: t.min,
          max: t.max,
          selected: selected
        };
}

function resetSelected(t) {
  return {
          min: t.min,
          max: t.max,
          selected: undefined
        };
}

function fromJson(param) {
  return {
          min: 10,
          max: 30,
          selected: undefined
        };
}

function toString(t) {
  var selected = t.selected;
  if (selected !== undefined) {
    return NumberSelected$ReactHooksTemplate.toString(Caml_option.valFromOption(selected));
  } else {
    return "";
  }
}

function encodeParams(t) {
  return Pervasives.$at(Utils$ReactHooksTemplate.encodeOptionalParamToList("min", t.min, (function (prim) {
                    return prim;
                  })), Pervasives.$at(Utils$ReactHooksTemplate.encodeOptionalParamToList("max", t.max, (function (prim) {
                        return prim;
                      })), Utils$ReactHooksTemplate.encodeOptionalParamToList("selected", t.selected, NumberSelected$ReactHooksTemplate.encode)));
}

function encode(t) {
  return Json_encode.object_({
              hd: [
                "category_type",
                "number"
              ],
              tl: {
                hd: [
                  "params",
                  Json_encode.object_(encodeParams(t))
                ],
                tl: /* [] */0
              }
            });
}

exports.getMin = getMin;
exports.getMax = getMax;
exports.selected = selected;
exports.isSelected = isSelected;
exports.fromJson = fromJson;
exports.setSelected = setSelected;
exports.resetSelected = resetSelected;
exports.toString = toString;
exports.encode = encode;
/* Utils-ReactHooksTemplate Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var ReactCsv = require("react-csv");
var Pervasives = require("rescript/lib/js/pervasives.js");
var FileSaver = require("file-saver");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var DomToImage = require("dom-to-image");
var Webapi__Dom__Document = require("bs-webapi/src/Webapi/Webapi__Dom/Webapi__Dom__Document.bs.js");
var Http$ReactHooksTemplate = require("../../common/http.bs.js");
var Chart$ReactHooksTemplate = require("./chart.bs.js");
var Image$ReactHooksTemplate = require("../../common/image.bs.js");
var Utils$ReactHooksTemplate = require("../../common/utils.bs.js");
var ChartId$ReactHooksTemplate = require("./chartId.bs.js");
var Loading$ReactHooksTemplate = require("./Loading.bs.js");
var MapAttribute$ReactHooksTemplate = require("./mapAttribute.bs.js");
var MapComponent$ReactHooksTemplate = require("./MapComponent.bs.js");
var TableAttribute$ReactHooksTemplate = require("./tableAttribute.bs.js");
var TableComponent$ReactHooksTemplate = require("./TableComponent.bs.js");
var BarChartComponent$ReactHooksTemplate = require("./BarChartComponent.bs.js");
var MultipleSelectBox$ReactHooksTemplate = require("../../common/MultipleSelectBox.bs.js");
var PieChartComponent$ReactHooksTemplate = require("./PieChartComponent.bs.js");
var AreaChartComponent$ReactHooksTemplate = require("./AreaChartComponent.bs.js");
var LineChartComponent$ReactHooksTemplate = require("./LineChartComponent.bs.js");
var ScoreCardComponent$ReactHooksTemplate = require("./ScoreCardComponent.bs.js");
var WordcloudComponent$ReactHooksTemplate = require("./WordcloudComponent.bs.js");
var GenericChartAttribute$ReactHooksTemplate = require("./genericChartAttribute.bs.js");
var ComposedChartComponent$ReactHooksTemplate = require("./ComposedChartComponent.bs.js");
var RadialBarChartComponent$ReactHooksTemplate = require("./RadialBarChartComponent.bs.js");

var initialState = {
  loading: true,
  chart: undefined,
  drillDownKey: undefined,
  settingsDropDown: false,
  widgetSettings: false,
  downloadMenu: false
};

function reducer(state, x) {
  if (typeof x === "number") {
    return {
            loading: true,
            chart: state.chart,
            drillDownKey: state.drillDownKey,
            settingsDropDown: state.settingsDropDown,
            widgetSettings: state.widgetSettings,
            downloadMenu: state.downloadMenu
          };
  }
  switch (x.TAG | 0) {
    case /* FetchChartCompleted */0 :
        return {
                loading: false,
                chart: x._0,
                drillDownKey: state.drillDownKey,
                settingsDropDown: state.settingsDropDown,
                widgetSettings: state.widgetSettings,
                downloadMenu: state.downloadMenu
              };
    case /* DrillDown */1 :
        return {
                loading: state.loading,
                chart: state.chart,
                drillDownKey: x._0,
                settingsDropDown: state.settingsDropDown,
                widgetSettings: state.widgetSettings,
                downloadMenu: state.downloadMenu
              };
    case /* SetSettingsDropDown */2 :
        return {
                loading: state.loading,
                chart: state.chart,
                drillDownKey: state.drillDownKey,
                settingsDropDown: x._0,
                widgetSettings: state.widgetSettings,
                downloadMenu: state.downloadMenu
              };
    case /* SetWidgetSettings */3 :
        var toggle = x._0;
        var bodyElement = Belt_Option.getExn(Webapi__Dom__Document.asHtmlDocument(document)).body;
        var $$class = Belt_Option.getExn((bodyElement == null) ? undefined : Caml_option.some(bodyElement)).classList;
        if (toggle) {
          $$class.add("overflow-hidden");
        } else {
          $$class.remove("overflow-hidden");
        }
        return {
                loading: state.loading,
                chart: state.chart,
                drillDownKey: state.drillDownKey,
                settingsDropDown: state.settingsDropDown,
                widgetSettings: toggle,
                downloadMenu: state.downloadMenu
              };
    case /* SetDownloadMenu */4 :
        return {
                loading: state.loading,
                chart: state.chart,
                drillDownKey: state.drillDownKey,
                settingsDropDown: state.settingsDropDown,
                widgetSettings: state.widgetSettings,
                downloadMenu: x._0
              };
    
  }
}

function ChartComponent(Props) {
  var chartId = Props.chartId;
  var drillDownChartDetails = Props.drillDownChartDetails;
  var onClick = Props.onClick;
  var onEnlarge = Props.onEnlarge;
  var scoreCardOpt = Props.scoreCard;
  var enlargedOpt = Props.enlarged;
  var insightOpt = Props.insight;
  var campaignOpt = Props.campaign;
  var scoreCard = scoreCardOpt !== undefined ? scoreCardOpt : false;
  var enlarged = enlargedOpt !== undefined ? enlargedOpt : false;
  var insight = insightOpt !== undefined ? insightOpt : false;
  var campaign = campaignOpt !== undefined ? campaignOpt : false;
  var match = React.useReducer(reducer, initialState);
  var dispatch = match[1];
  var state = match[0];
  var fetchVisualization = function (param) {
    Curry._1(dispatch, /* FetchChartInProgress */0);
    var url = insight ? "/api/v1/insights/show_visualization?visualization=" + ChartId$ReactHooksTemplate.name(chartId) : (
        campaign ? "/api/v1/campaigns/visualization_data?question_id=" + (String(ChartId$ReactHooksTemplate.id(chartId)) + ("&campaign_id=" + (String(ChartId$ReactHooksTemplate.dashboardId(chartId)) + ("&name=" + ChartId$ReactHooksTemplate.name(chartId))))) : "/api/v1/dashboards/" + (String(ChartId$ReactHooksTemplate.dashboardId(chartId)) + ("/visualizations/" + String(ChartId$ReactHooksTemplate.id(chartId))))
      );
    return Http$ReactHooksTemplate.fetchGetJSONDecode(url, Chart$ReactHooksTemplate.fromJson).then(function (xs) {
                  return Promise.resolve((setTimeout((function (param) {
                                      return Curry._1(dispatch, {
                                                  TAG: /* FetchChartCompleted */0,
                                                  _0: Caml_option.some(xs)
                                                });
                                    }), Utils$ReactHooksTemplate.timeout), undefined));
                }).catch(function (err) {
                console.log(err);
                return Promise.resolve(Curry._1(dispatch, {
                                TAG: /* FetchChartCompleted */0,
                                _0: undefined
                              }));
              });
  };
  React.useEffect((function () {
          if (drillDownChartDetails !== undefined) {
            var key = drillDownChartDetails[1];
            if (key === "") {
              Curry._1(dispatch, {
                    TAG: /* DrillDown */1,
                    _0: key
                  });
            }
            if (drillDownChartDetails[0] !== ChartId$ReactHooksTemplate.id(chartId)) {
              fetchVisualization(undefined);
            } else {
              Curry._1(dispatch, {
                    TAG: /* DrillDown */1,
                    _0: key
                  });
            }
          } else {
            fetchVisualization(undefined);
          }
          
        }), [drillDownChartDetails]);
  var chartElement = React.useRef(null);
  var saveChart = function (chart) {
    var params = {};
    params["visualization"] = Chart$ReactHooksTemplate.toJson(chart);
    var url = "/api/v1/dashboards/" + (String(ChartId$ReactHooksTemplate.dashboardId(chartId)) + ("/visualizations/" + String(ChartId$ReactHooksTemplate.id(chartId))));
    return Http$ReactHooksTemplate.fetchPutJSON(url, params).then(function (response) {
                var status = Json_decode.field("status", (function (param) {
                        return Json_decode.withDefault("failed", Json_decode.string, param);
                      }), response);
                if (status === "success") {
                  console.log(status);
                  Curry._1(dispatch, {
                        TAG: /* FetchChartCompleted */0,
                        _0: Caml_option.some(Chart$ReactHooksTemplate.fromJson(response))
                      });
                } else {
                  Curry._1(dispatch, {
                        TAG: /* FetchChartCompleted */0,
                        _0: undefined
                      });
                }
                return Promise.resolve(undefined);
              });
  };
  var handleChartTypeChange = function (chartType) {
    var chart;
    switch (chartType) {
      case "Area Chart" :
          var x = state.chart;
          if (x !== undefined) {
            var x$1 = Caml_option.valFromOption(x);
            var match = Chart$ReactHooksTemplate.type_(x$1);
            var exit = 0;
            if (typeof match === "number") {
              chart = x$1;
            } else {
              switch (match.TAG | 0) {
                case /* LineChart */0 :
                case /* AreaChart */1 :
                case /* PieChart */2 :
                case /* BarChart */3 :
                    exit = 1;
                    break;
                default:
                  chart = x$1;
              }
            }
            if (exit === 1) {
              chart = Chart$ReactHooksTemplate.setType(x$1, {
                    TAG: /* AreaChart */1,
                    _0: match._0
                  });
            }
            
          } else {
            chart = Pervasives.failwith("No Chart");
          }
          break;
      case "Bar Graph" :
          var x$2 = state.chart;
          if (x$2 !== undefined) {
            var x$3 = Caml_option.valFromOption(x$2);
            var match$1 = Chart$ReactHooksTemplate.type_(x$3);
            var exit$1 = 0;
            if (typeof match$1 === "number") {
              chart = x$3;
            } else {
              switch (match$1.TAG | 0) {
                case /* LineChart */0 :
                case /* AreaChart */1 :
                case /* PieChart */2 :
                case /* BarChart */3 :
                    exit$1 = 1;
                    break;
                default:
                  chart = x$3;
              }
            }
            if (exit$1 === 1) {
              chart = Chart$ReactHooksTemplate.setType(x$3, {
                    TAG: /* BarChart */3,
                    _0: match$1._0
                  });
            }
            
          } else {
            chart = Pervasives.failwith("No Chart");
          }
          break;
      case "Line Graph" :
          var x$4 = state.chart;
          if (x$4 !== undefined) {
            var x$5 = Caml_option.valFromOption(x$4);
            var match$2 = Chart$ReactHooksTemplate.type_(x$5);
            var exit$2 = 0;
            if (typeof match$2 === "number") {
              chart = x$5;
            } else {
              switch (match$2.TAG | 0) {
                case /* LineChart */0 :
                case /* AreaChart */1 :
                case /* PieChart */2 :
                case /* BarChart */3 :
                    exit$2 = 1;
                    break;
                default:
                  chart = x$5;
              }
            }
            if (exit$2 === 1) {
              chart = Chart$ReactHooksTemplate.setType(x$5, {
                    TAG: /* LineChart */0,
                    _0: match$2._0
                  });
            }
            
          } else {
            chart = Pervasives.failwith("No Chart");
          }
          break;
      case "Pie Chart" :
          var x$6 = state.chart;
          if (x$6 !== undefined) {
            var x$7 = Caml_option.valFromOption(x$6);
            var match$3 = Chart$ReactHooksTemplate.type_(x$7);
            var exit$3 = 0;
            if (typeof match$3 === "number") {
              chart = x$7;
            } else {
              switch (match$3.TAG | 0) {
                case /* LineChart */0 :
                case /* AreaChart */1 :
                case /* PieChart */2 :
                case /* BarChart */3 :
                    exit$3 = 1;
                    break;
                default:
                  chart = x$7;
              }
            }
            if (exit$3 === 1) {
              chart = Chart$ReactHooksTemplate.setType(x$7, {
                    TAG: /* PieChart */2,
                    _0: match$3._0
                  });
            }
            
          } else {
            chart = Pervasives.failwith("No Chart");
          }
          break;
      default:
        chart = Pervasives.failwith("No Chart");
    }
    return Curry._1(dispatch, {
                TAG: /* FetchChartCompleted */0,
                _0: Caml_option.some(chart)
              });
  };
  var handleChartNameChange = function ($$event) {
    var chartName = Utils$ReactHooksTemplate.formTargetValue($$event);
    if (!Utils$ReactHooksTemplate.checkMaxLength(60, chartName)) {
      return ;
    }
    var x = state.chart;
    var chart = x !== undefined ? Chart$ReactHooksTemplate.setTitle(Caml_option.valFromOption(x), chartName) : Pervasives.failwith("No Chart");
    return Curry._1(dispatch, {
                TAG: /* FetchChartCompleted */0,
                _0: Caml_option.some(chart)
              });
  };
  var loadChart = function (chart) {
    var chartWidth;
    if (enlarged) {
      chartWidth = "w-full";
    } else {
      var mapAttribute = Chart$ReactHooksTemplate.type_(chart);
      if (typeof mapAttribute === "number") {
        chartWidth = "w-1/2";
      } else {
        switch (mapAttribute.TAG | 0) {
          case /* ScoreCard */7 :
              chartWidth = "w-1/2";
              break;
          case /* Map */8 :
              var w = MapAttribute$ReactHooksTemplate.width(mapAttribute._0);
              chartWidth = w !== undefined ? w : "w-1/2";
              break;
          case /* Table */9 :
              var w$1 = TableAttribute$ReactHooksTemplate.width(mapAttribute._0);
              chartWidth = w$1 !== undefined ? w$1 : "w-1/2";
              break;
          default:
            var w$2 = GenericChartAttribute$ReactHooksTemplate.width(mapAttribute._0);
            chartWidth = w$2 !== undefined ? w$2 : "w-1/2";
        }
      }
    }
    var chartHeight;
    if (enlarged) {
      var match = Chart$ReactHooksTemplate.type_(chart);
      if (typeof match === "number") {
        chartHeight = "h-96";
      } else {
        switch (match.TAG | 0) {
          case /* Map */8 :
          case /* Table */9 :
              chartHeight = "h-full";
              break;
          default:
            chartHeight = "h-96";
        }
      }
    } else {
      var mapAttribute$1 = Chart$ReactHooksTemplate.type_(chart);
      if (typeof mapAttribute$1 === "number") {
        chartHeight = "h-64";
      } else {
        switch (mapAttribute$1.TAG | 0) {
          case /* ScoreCard */7 :
              chartHeight = "h-64";
              break;
          case /* Map */8 :
              var h = MapAttribute$ReactHooksTemplate.height(mapAttribute$1._0);
              chartHeight = h !== undefined ? h : "h-64";
              break;
          case /* Table */9 :
              var h$1 = TableAttribute$ReactHooksTemplate.height(mapAttribute$1._0);
              chartHeight = h$1 !== undefined ? h$1 : "h-64";
              break;
          default:
            var w$3 = GenericChartAttribute$ReactHooksTemplate.height(mapAttribute$1._0);
            chartHeight = w$3 !== undefined ? w$3 : "h-64";
        }
      }
    }
    var zIndex = state.widgetSettings || state.settingsDropDown ? "z-10" : "";
    var chartAttribute = Chart$ReactHooksTemplate.type_(chart);
    var tmp;
    if (typeof chartAttribute === "number") {
      tmp = React.createElement(React.Fragment, undefined);
    } else {
      switch (chartAttribute.TAG | 0) {
        case /* LineChart */0 :
            tmp = React.createElement(LineChartComponent$ReactHooksTemplate.make, {
                  chartAttribute: chartAttribute._0,
                  onClick: onClick,
                  enlarged: enlarged,
                  chartHeight: chartHeight
                });
            break;
        case /* AreaChart */1 :
            tmp = React.createElement(AreaChartComponent$ReactHooksTemplate.make, {
                  chartAttribute: chartAttribute._0,
                  onClick: onClick,
                  enlarged: enlarged,
                  chartHeight: chartHeight
                });
            break;
        case /* PieChart */2 :
            tmp = React.createElement(PieChartComponent$ReactHooksTemplate.make, {
                  chartAttribute: chartAttribute._0,
                  onClick: onClick,
                  chartHeight: chartHeight
                });
            break;
        case /* BarChart */3 :
            tmp = React.createElement(BarChartComponent$ReactHooksTemplate.make, {
                  chartId: Chart$ReactHooksTemplate.id(chart),
                  chartAttribute: chartAttribute._0,
                  drillDownKey: state.drillDownKey,
                  onClick: onClick,
                  enlarged: enlarged,
                  chartHeight: chartHeight
                });
            break;
        case /* RadialBarChart */4 :
            tmp = React.createElement(RadialBarChartComponent$ReactHooksTemplate.make, {
                  chartHeight: chartHeight
                });
            break;
        case /* Wordcloud */5 :
            tmp = React.createElement(WordcloudComponent$ReactHooksTemplate.make, {
                  chartId: Chart$ReactHooksTemplate.id(chart),
                  chartAttribute: chartAttribute._0
                });
            break;
        case /* ComposedChart */6 :
            tmp = React.createElement(ComposedChartComponent$ReactHooksTemplate.make, {
                  chartId: Chart$ReactHooksTemplate.id(chart),
                  chartAttribute: chartAttribute._0,
                  drillDownKey: state.drillDownKey,
                  onClick: onClick,
                  enlarged: enlarged,
                  chartHeight: chartHeight
                });
            break;
        case /* ScoreCard */7 :
            tmp = React.createElement(React.Fragment, undefined);
            break;
        case /* Map */8 :
            tmp = React.createElement(MapComponent$ReactHooksTemplate.make, {
                  mapAttribute: chartAttribute._0,
                  enlarged: enlarged,
                  chartHeight: chartHeight
                });
            break;
        case /* Table */9 :
            tmp = React.createElement(TableComponent$ReactHooksTemplate.make, {
                  attribute: chartAttribute._0,
                  chartHeight: chartHeight,
                  chartId: chartId
                });
            break;
        
      }
    }
    var mapAttribute$2 = Chart$ReactHooksTemplate.type_(chart);
    var tmp$1;
    if (typeof mapAttribute$2 === "number") {
      tmp$1 = React.createElement(React.Fragment, undefined);
    } else {
      switch (mapAttribute$2.TAG | 0) {
        case /* ScoreCard */7 :
            tmp$1 = React.createElement(React.Fragment, undefined);
            break;
        case /* Map */8 :
            tmp$1 = React.createElement("div", {
                  className: "w-full rounded hover:bg-palatinateblue hover:text-white p-2 sidebar"
                }, React.createElement("div", {
                      className: " flex text-xs gap-2"
                    }, React.createElement(ReactCsv.CSVLink, {
                          data: MapAttribute$ReactHooksTemplate.data(mapAttribute$2._0),
                          separator: ",",
                          filename: Chart$ReactHooksTemplate.title(chart) + ".csv",
                          children: null
                        }, React.createElement("img", {
                              className: "w-5",
                              src: Image$ReactHooksTemplate.csvDownloadButton
                            }), React.createElement("a", {
                              className: "pt-2-px"
                            }, "Download as CSV"))));
            break;
        case /* Table */9 :
            tmp$1 = React.createElement("div", {
                  className: "w-full rounded hover:bg-palatinateblue hover:text-white p-2 sidebar"
                }, React.createElement("div", {
                      className: " flex text-xs gap-2"
                    }, React.createElement(ReactCsv.CSVLink, {
                          data: TableAttribute$ReactHooksTemplate.data(mapAttribute$2._0),
                          separator: ",",
                          filename: Chart$ReactHooksTemplate.title(chart) + ".csv",
                          children: null
                        }, React.createElement("img", {
                              className: "w-5",
                              src: Image$ReactHooksTemplate.csvDownloadButton
                            }), React.createElement("a", {
                              className: "pt-2-px"
                            }, "Download as CSV"))));
            break;
        default:
          tmp$1 = React.createElement("div", {
                className: "w-full rounded hover:bg-palatinateblue hover:text-white p-2 sidebar"
              }, React.createElement("div", {
                    className: "flex items-center"
                  }, React.createElement(ReactCsv.CSVLink, {
                        data: GenericChartAttribute$ReactHooksTemplate.data(mapAttribute$2._0),
                        separator: ",",
                        filename: Chart$ReactHooksTemplate.title(chart) + ".csv",
                        children: React.createElement("div", {
                              className: "flex text-xs items-center gap-2"
                            }, React.createElement("img", {
                                  className: "w-5",
                                  src: Image$ReactHooksTemplate.csvDownloadButton
                                }), React.createElement("a", {
                                  className: "pt-2-px"
                                }, "Download as CSV"))
                      })));
      }
    }
    return React.createElement("div", {
                className: "" + chartWidth + " " + zIndex + " pb-16-px pr-16-px rounded-sm animated fadeIn\n    "
              }, React.createElement("div", {
                    className: "relative"
                  }, React.createElement("div", {
                        ref: chartElement,
                        className: "border border-gray-400 rounded bg-white"
                      }, React.createElement("h4", {
                            className: "animated fadeIn font-bold text-charcoal p-20-px flex"
                          }, React.createElement("img", {
                                alt: "doubleMinimize",
                                src: Image$ReactHooksTemplate.doubleMinimize
                              }), Chart$ReactHooksTemplate.title(chart)), React.createElement("div", {
                            className: "w-full animated fadeIn"
                          }, tmp), React.createElement("div", {
                            className: "absolute top-0 right-0 " + (
                              state.settingsDropDown ? "p-2" : "p-4"
                            )
                          }, React.createElement("div", {
                                className: state.settingsDropDown ? "rounded-md bg-lavenderweb p-2 cursor-pointer" : "",
                                onClick: (function (param) {
                                    Curry._1(dispatch, {
                                          TAG: /* SetSettingsDropDown */2,
                                          _0: !state.settingsDropDown
                                        });
                                    return Curry._1(dispatch, {
                                                TAG: /* SetDownloadMenu */4,
                                                _0: false
                                              });
                                  })
                              }, React.createElement("img", {
                                    className: "h-18-px cursor-pointer",
                                    src: Image$ReactHooksTemplate.verticalDots
                                  })), React.createElement("div", {
                                className: "p-2"
                              }, React.createElement("div", {
                                    className: "absolute shadow-inner rounded border border-gray-400 bg-white\n                            text-darkcharcoal rounded text-xs z-50 w-131-px py-2 gap-2 cursor-pointer " + (
                                      state.settingsDropDown === true ? "block" : "hidden"
                                    ),
                                    style: {
                                      transform: "translateX(-70%)"
                                    }
                                  }, React.createElement("div", {
                                        className: "w-full hover:bg-palatinateblue hover:text-white sidebar "
                                      }, React.createElement("a", {
                                            className: "flex text-xs gap-2 p-2",
                                            onClick: (function (param) {
                                                Curry._1(dispatch, {
                                                      TAG: /* SetSettingsDropDown */2,
                                                      _0: false
                                                    });
                                                return Curry._1(dispatch, {
                                                            TAG: /* SetWidgetSettings */3,
                                                            _0: !state.widgetSettings
                                                          });
                                              })
                                          }, React.createElement("img", {
                                                src: Image$ReactHooksTemplate.settings
                                              }), "Settings")), React.createElement("div", {
                                        className: "w-full rounded "
                                      }, React.createElement("a", {
                                            className: "flex text-xs gap-2 p-2 hover:bg-palatinateblue hover:text-white sidebar",
                                            onClick: (function (param) {
                                                return Curry._1(dispatch, {
                                                            TAG: /* SetDownloadMenu */4,
                                                            _0: !state.downloadMenu
                                                          });
                                              })
                                          }, React.createElement("img", {
                                                className: "h-16-px",
                                                src: Image$ReactHooksTemplate.download
                                              }), "Download", React.createElement("img", {
                                                className: "pl-4",
                                                src: Image$ReactHooksTemplate.vectorRight
                                              })), React.createElement("div", {
                                            className: "absolute shadow-inner -mt-8 ml-130-px w-40 z-50 rounded border border-gray-400 bg-white\n                              text-darkcharcoal text-xs z-50 gap-2 py-2 cursor-pointer " + (
                                              state.downloadMenu === true ? "block" : "hidden"
                                            ),
                                            style: {
                                              transform: "translateX(-181%)"
                                            }
                                          }, React.createElement("div", {
                                                className: "w-full hover:bg-palatinateblue hover:text-white p-2 sidebar"
                                              }, React.createElement("a", {
                                                    className: "flex text-xs gap-2",
                                                    onClick: (function (e) {
                                                        e.preventDefault();
                                                        var ref_ = Belt_Option.getExn(Caml_option.nullable_to_opt(chartElement.current));
                                                        DomToImage.toBlob(ref_).then(function (response) {
                                                              return Promise.resolve((FileSaver.saveAs(response, "chart.png"), undefined));
                                                            });
                                                        
                                                      })
                                                  }, React.createElement("img", {
                                                        className: "w-5",
                                                        src: Image$ReactHooksTemplate.pngDownloadButton
                                                      }), React.createElement("a", {
                                                        className: "pt-2-px"
                                                      }, "Download as PNG"))), tmp$1)))))), enlarged ? React.createElement(React.Fragment, undefined) : React.createElement("div", {
                          className: "absolute top-0 right-32-px p-16-px"
                        }, React.createElement("button", {
                              onClick: (function (e) {
                                  e.preventDefault();
                                  return Curry._1(onEnlarge, undefined);
                                })
                            }, React.createElement("img", {
                                  className: "w-5",
                                  alt: "Expand",
                                  src: Image$ReactHooksTemplate.expand
                                })))), React.createElement("div", {
                    className: "w-screen z-50 disabled-wrapper absolute inset-0 flex justify-center items-center " + (
                      state.widgetSettings === true ? "block" : "hidden"
                    ),
                    style: {
                      height: "calc(100vh + 5rem)",
                      overflow: "hidden",
                      position: "fixed"
                    }
                  }, React.createElement("div", {
                        className: "bg-white relative border border-gray-300 rounded-lg w-3/5 h-5/9 flex flex-col"
                      }, React.createElement("div", {
                            className: "border-b border-lavenderweb border-x-8"
                          }, React.createElement("div", {
                                className: "p-8 text-xl font-semibold flex justify-between"
                              }, React.createElement("div", undefined, "Widget Settings"), React.createElement("img", {
                                    className: "h-14-px float-right cursor-pointer",
                                    src: Image$ReactHooksTemplate.closeIcon,
                                    onClick: (function (param) {
                                        Curry._1(dispatch, {
                                              TAG: /* SetSettingsDropDown */2,
                                              _0: true
                                            });
                                        Curry._1(dispatch, {
                                              TAG: /* SetWidgetSettings */3,
                                              _0: !state.widgetSettings
                                            });
                                        return Curry._1(dispatch, {
                                                    TAG: /* SetSettingsDropDown */2,
                                                    _0: false
                                                  });
                                      })
                                  }))), React.createElement("div", {
                            className: "flex-grow flex"
                          }, React.createElement("div", {
                                className: "w-4/12 border-r border-lavenderweb border-x-8"
                              }, React.createElement("div", {
                                    className: "border-b border-lavenderweb py-3 pl-8"
                                  }, React.createElement("a", {
                                        className: "text-base font-semibold"
                                      }, "Appearance"))), React.createElement("div", {
                                className: "w-full pt-6 px-6"
                              }, React.createElement("div", {
                                    className: "flex items-center justify-between py-2 w-full h-32-px"
                                  }, React.createElement("div", {
                                        className: "text-md font-normal"
                                      }, "Representation"), React.createElement("div", {
                                        className: "w-3/5"
                                      }, React.createElement(MultipleSelectBox$ReactHooksTemplate.make, {
                                            options: Chart$ReactHooksTemplate.compatibleCharts(chart),
                                            placeholder: Chart$ReactHooksTemplate.chartTypeToString(chart),
                                            handleChange: handleChartTypeChange,
                                            selectedOptions: /* [] */0,
                                            className: "border border-gray-400 rounded-md px-3 py-2",
                                            optionsHoverColor: "hover:bg-pigmentblue hover:text-white",
                                            listBoxCss: "py-2 "
                                          }))), React.createElement("div", {
                                    className: "flex items-center justify-between pt-10 py-2 w-full h-32-px"
                                  }, React.createElement("div", {
                                        className: "text-md font-normal"
                                      }, "Name"), React.createElement("input", {
                                        className: "border border-gray-400 rounded-md pl-2 w-3/5 h-32-px",
                                        id: "chart_name",
                                        type: "text",
                                        value: Chart$ReactHooksTemplate.title(chart),
                                        onChange: handleChartNameChange
                                      })), Chart$ReactHooksTemplate.title(chart).length === 0 ? React.createElement("p", {
                                      className: "text-red-500 text-xs pt-2 float-right"
                                    }, "Name can't be empty") : React.createElement("p", {
                                      className: "text-gray-500 text-xs pt-2 float-right"
                                    }, String(Chart$ReactHooksTemplate.title(chart).length) + (" / " + String(60))))), React.createElement("div", {
                            className: "py-5 pr-8 flex border-t border-gray-200 gap-3 font-medium text-base w-full justify-end"
                          }, React.createElement("button", {
                                className: "border border-gray-400 flex rounded py-3 px-6 text-base font-semibold justify-center",
                                id: "close_modal",
                                onClick: (function (param) {
                                    Curry._1(dispatch, {
                                          TAG: /* SetWidgetSettings */3,
                                          _0: !state.widgetSettings
                                        });
                                    Curry._1(dispatch, {
                                          TAG: /* SetSettingsDropDown */2,
                                          _0: false
                                        });
                                    fetchVisualization(undefined);
                                    
                                  })
                              }, "Cancel"), React.createElement("button", {
                                className: "bg-palatinateblue border border-gray-400 flex rounded\n                         py-3 px-6 text-base font-semibold text-white justify-center " + (
                                  Chart$ReactHooksTemplate.title(chart).length === 0 ? "disabled-input" : ""
                                ),
                                disabled: Chart$ReactHooksTemplate.title(chart).length === 0,
                                onClick: (function (param) {
                                    Curry._1(dispatch, {
                                          TAG: /* SetWidgetSettings */3,
                                          _0: !state.widgetSettings
                                        });
                                    Curry._1(dispatch, {
                                          TAG: /* SetSettingsDropDown */2,
                                          _0: false
                                        });
                                    saveChart(chart);
                                    
                                  })
                              }, "Save")))));
  };
  var tmp;
  if (state.loading) {
    tmp = React.createElement(Loading$ReactHooksTemplate.make, {});
  } else {
    var chart = state.chart;
    if (chart !== undefined) {
      var chart$1 = Caml_option.valFromOption(chart);
      var chartAttribute = Chart$ReactHooksTemplate.type_(chart$1);
      tmp = typeof chartAttribute === "number" || chartAttribute.TAG !== /* ScoreCard */7 ? React.createElement(React.Fragment, undefined) : React.createElement("div", {
              className: "w-full"
            }, React.createElement(ScoreCardComponent$ReactHooksTemplate.make, {
                  chartId: Chart$ReactHooksTemplate.id(chart$1),
                  chartAttribute: chartAttribute._0,
                  onClick: onClick
                }));
    } else {
      tmp = "No Data Loaded for the chart";
    }
  }
  var loadScoreCard = React.createElement("div", {
        className: "min-w-265-px"
      }, tmp);
  var loadOtherCharts;
  if (state.loading) {
    loadOtherCharts = React.createElement("div", {
          className: "w-1/2 pb-16-px pr-16-px rounded-sm animated fadeIn " + (
            state.settingsDropDown ? "z-50" : ""
          )
        }, React.createElement(Loading$ReactHooksTemplate.make, {
              img: Image$ReactHooksTemplate.dashboardLoading,
              text: "Loading chart"
            }));
  } else {
    var chart$2 = state.chart;
    loadOtherCharts = chart$2 !== undefined ? loadChart(Caml_option.valFromOption(chart$2)) : "No Data Loaded for the chart";
  }
  if (scoreCard) {
    return loadScoreCard;
  } else {
    return loadOtherCharts;
  }
}

var make = ChartComponent;

exports.initialState = initialState;
exports.reducer = reducer;
exports.make = make;
/* react Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Pervasives = require("rescript/lib/js/pervasives.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");
var Utils$ReactHooksTemplate = require("../../common/utils.bs.js");
var MsisdnSelected$ReactHooksTemplate = require("./msisdnSelected.bs.js");

function setSelected(selected, t) {
  return {
          length: t.length,
          selected: selected
        };
}

function resetSelected(t) {
  return {
          length: t.length,
          selected: undefined
        };
}

function getLength(t) {
  return t.length;
}

function fromJson(json) {
  return {
          length: Json_decode.field("length", Json_decode.$$int, json),
          selected: Json_decode.withDefault(undefined, (function (param) {
                  return Json_decode.field("selected", (function (param) {
                                return Json_decode.optional(MsisdnSelected$ReactHooksTemplate.decode, param);
                              }), param);
                }), json)
        };
}

function toString(t) {
  var selected = t.selected;
  if (selected !== undefined) {
    return MsisdnSelected$ReactHooksTemplate.toString(Caml_option.valFromOption(selected));
  } else {
    return "";
  }
}

function isSelected(t) {
  return Belt_Option.isSome(t.selected);
}

function encodeParams(t) {
  return Pervasives.$at({
              hd: [
                "length",
                t.length
              ],
              tl: /* [] */0
            }, Utils$ReactHooksTemplate.encodeOptionalParamToList("selected", t.selected, MsisdnSelected$ReactHooksTemplate.encode));
}

function encode(t) {
  return Json_encode.object_({
              hd: [
                "category_type",
                "msisdn"
              ],
              tl: {
                hd: [
                  "params",
                  Json_encode.object_(encodeParams(t))
                ],
                tl: /* [] */0
              }
            });
}

exports.getLength = getLength;
exports.setSelected = setSelected;
exports.resetSelected = resetSelected;
exports.isSelected = isSelected;
exports.toString = toString;
exports.encode = encode;
exports.fromJson = fromJson;
/* Utils-ReactHooksTemplate Not a pure module */

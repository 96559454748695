// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var List = require("rescript/lib/js/list.js");
var $$Array = require("rescript/lib/js/array.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Image$ReactHooksTemplate = require("./image.bs.js");

function Pagination(Props) {
  var total = Props.total;
  var currentPageOpt = Props.currentPage;
  var siblingCountOpt = Props.siblingCount;
  var handlePageChange = Props.handlePageChange;
  var currentPage = currentPageOpt !== undefined ? currentPageOpt : 1;
  var siblingCount = siblingCountOpt !== undefined ? siblingCountOpt : 2;
  var currentPage$1 = currentPage < 1 ? 1 : (
      currentPage > total ? total : currentPage
    );
  var leftSiblingCount;
  if (currentPage$1 === 1) {
    leftSiblingCount = 0;
  } else if ((currentPage$1 - 1 | 0) < siblingCount) {
    leftSiblingCount = currentPage$1 - 1 | 0;
  } else if ((currentPage$1 - 1 | 0) === siblingCount || (total - currentPage$1 | 0) >= siblingCount) {
    leftSiblingCount = siblingCount;
  } else {
    var addToLeft = siblingCount - (total - currentPage$1 | 0) | 0;
    leftSiblingCount = (currentPage$1 - 1 | 0) > (siblingCount + addToLeft | 0) ? siblingCount + addToLeft | 0 : currentPage$1 - 1 | 0;
  }
  var rightSiblingCount;
  if (total === currentPage$1) {
    rightSiblingCount = 0;
  } else if ((total - currentPage$1 | 0) < siblingCount) {
    rightSiblingCount = total - currentPage$1 | 0;
  } else if ((total - currentPage$1 | 0) === siblingCount || (currentPage$1 - 1 | 0) >= siblingCount) {
    rightSiblingCount = siblingCount;
  } else {
    var addToRight = siblingCount - (currentPage$1 - 1 | 0) | 0;
    console.log(addToRight);
    console.log("sfsd");
    rightSiblingCount = (total - currentPage$1 | 0) > (siblingCount + addToRight | 0) ? siblingCount + addToRight | 0 : total - currentPage$1 | 0;
  }
  var leftSiblings = function (currentPage, leftSiblingCount) {
    if (leftSiblingCount !== 0) {
      if (leftSiblingCount !== 1) {
        return List.append(leftSiblings(currentPage - 1 | 0, leftSiblingCount - 1 | 0), {
                    hd: {
                      TAG: /* Page */0,
                      _0: currentPage - 1 | 0
                    },
                    tl: /* [] */0
                  });
      } else {
        return {
                hd: {
                  TAG: /* Page */0,
                  _0: currentPage - 1 | 0
                },
                tl: /* [] */0
              };
      }
    } else {
      return /* [] */0;
    }
  };
  var rightSiblings = function (currentPage, rightSiblingCount) {
    if (rightSiblingCount !== 0) {
      if (rightSiblingCount !== 1) {
        return List.append({
                    hd: {
                      TAG: /* Page */0,
                      _0: currentPage + 1 | 0
                    },
                    tl: /* [] */0
                  }, rightSiblings(currentPage + 1 | 0, rightSiblingCount - 1 | 0));
      } else {
        return {
                hd: {
                  TAG: /* Page */0,
                  _0: currentPage + 1 | 0
                },
                tl: /* [] */0
              };
      }
    } else {
      return /* [] */0;
    }
  };
  var pageButtons = function (param) {
    var xs = leftSiblings(currentPage$1, leftSiblingCount);
    var firstPart;
    var exit = 0;
    if (xs) {
      var match = xs.hd;
      if (typeof match === "number" || match.TAG !== /* Page */0) {
        exit = 1;
      } else {
        var match$1 = match._0;
        if (match$1 !== 1) {
          if (match$1 !== 2) {
            exit = 1;
          } else {
            firstPart = List.append({
                  hd: {
                    TAG: /* Page */0,
                    _0: 1
                  },
                  tl: /* [] */0
                }, {
                  hd: {
                    TAG: /* Page */0,
                    _0: 2
                  },
                  tl: xs.tl
                });
          }
        } else {
          firstPart = {
            hd: {
              TAG: /* Page */0,
              _0: 1
            },
            tl: xs.tl
          };
        }
      }
    } else {
      firstPart = /* [] */0;
    }
    if (exit === 1) {
      firstPart = List.append({
            hd: {
              TAG: /* Page */0,
              _0: 1
            },
            tl: {
              hd: {
                TAG: /* Separator */1,
                _0: "..."
              },
              tl: /* [] */0
            }
          }, xs);
    }
    var xs$1 = List.rev(rightSiblings(currentPage$1, rightSiblingCount));
    var lastPart;
    var exit$1 = 0;
    if (xs$1) {
      var x = xs$1.hd;
      if (typeof x === "number" || x.TAG !== /* Page */0) {
        exit$1 = 1;
      } else {
        var rest = xs$1.tl;
        var x$1 = x._0;
        if (x$1 === total) {
          lastPart = List.rev({
                hd: {
                  TAG: /* Page */0,
                  _0: x$1
                },
                tl: rest
              });
        } else if (x$1 === (total - 1 | 0)) {
          lastPart = List.append(List.rev({
                    hd: {
                      TAG: /* Page */0,
                      _0: x$1
                    },
                    tl: rest
                  }), {
                hd: {
                  TAG: /* Page */0,
                  _0: total
                },
                tl: /* [] */0
              });
        } else {
          exit$1 = 1;
        }
      }
    } else {
      lastPart = /* [] */0;
    }
    if (exit$1 === 1) {
      lastPart = List.append(List.rev(xs$1), {
            hd: {
              TAG: /* Separator */1,
              _0: "..."
            },
            tl: {
              hd: {
                TAG: /* Page */0,
                _0: total
              },
              tl: /* [] */0
            }
          });
    }
    return List.append({
                hd: /* PreviousPage */0,
                tl: /* [] */0
              }, List.append(firstPart, List.append({
                        hd: {
                          TAG: /* Page */0,
                          _0: currentPage$1
                        },
                        tl: /* [] */0
                      }, List.append(lastPart, {
                            hd: /* NextPage */1,
                            tl: /* [] */0
                          }))));
  };
  var buttonCSS = "focus:outline-none text-xs h-30-px";
  var activeButtonCSS = "focus:outline-none text-xs h-30-px border border-black rounded-full h-30-px";
  var disabledButtonCSS = "focus:outline-none text-xs h-30-px cursor-not-allowed";
  return React.createElement("div", {
              className: "bg-white"
            }, React.createElement("nav", undefined, React.createElement("ul", {
                      className: "flex justify-between p-20-px"
                    }, $$Array.of_list(Belt_List.map(pageButtons(undefined), (function (pageButton) {
                                if (typeof pageButton === "number") {
                                  if (pageButton === /* PreviousPage */0) {
                                    return React.createElement("li", {
                                                key: String(0)
                                              }, React.createElement("button", {
                                                    key: String(0),
                                                    className: currentPage$1 === 1 ? disabledButtonCSS : buttonCSS,
                                                    disabled: currentPage$1 === 1,
                                                    onClick: (function (param) {
                                                        return Curry._1(handlePageChange, currentPage$1 - 1 | 0);
                                                      })
                                                  }, React.createElement("img", {
                                                        src: Image$ReactHooksTemplate.arrowLeft
                                                      })));
                                  } else {
                                    return React.createElement("li", {
                                                key: String(total + 1 | 0)
                                              }, React.createElement("button", {
                                                    key: String(total + 1 | 0),
                                                    className: currentPage$1 === total ? disabledButtonCSS : buttonCSS,
                                                    disabled: currentPage$1 === total,
                                                    onClick: (function (param) {
                                                        return Curry._1(handlePageChange, currentPage$1 + 1 | 0);
                                                      })
                                                  }, React.createElement("img", {
                                                        src: Image$ReactHooksTemplate.arrowRight
                                                      })));
                                  }
                                }
                                if (pageButton.TAG !== /* Page */0) {
                                  return React.createElement("li", undefined, React.createElement("button", {
                                                  key: String(total + 2 | 0),
                                                  className: buttonCSS,
                                                  disabled: true,
                                                  onClick: (function (param) {
                                                      return Curry._1(handlePageChange, currentPage$1 - 1 | 0);
                                                    })
                                                }, pageButton._0));
                                }
                                var x = pageButton._0;
                                var css = x === currentPage$1 ? activeButtonCSS : buttonCSS;
                                return React.createElement("li", {
                                            key: String(x)
                                          }, React.createElement("button", {
                                                key: String(x),
                                                className: css + " mx-2 w-30-px text-aurometalsaurus",
                                                value: String(x),
                                                onClick: (function (param) {
                                                    return Curry._1(handlePageChange, x);
                                                  })
                                              }, String(x)));
                              }))))));
}

var make = Pagination;

exports.make = make;
/* react Not a pure module */

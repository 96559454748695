// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var DataField$ReactHooksTemplate = require("../settings/dataField.bs.js");

function timeFrameFromString(timeFrameString) {
  switch (timeFrameString) {
    case "day" :
        return /* DAY */2;
    case "hour" :
        return /* HOUR */1;
    case "minute" :
        return /* MINUTE */0;
    case "month" :
        return /* MONTH */3;
    case "quarter" :
        return /* QUARTER */4;
    case "year" :
        return /* YEAR */5;
    default:
      return /* None */6;
  }
}

function timeFrameToString(timeFrame) {
  switch (timeFrame) {
    case /* MINUTE */0 :
        return "minute";
    case /* HOUR */1 :
        return "hour";
    case /* DAY */2 :
        return "day";
    case /* MONTH */3 :
        return "month";
    case /* QUARTER */4 :
        return "quarter";
    case /* YEAR */5 :
        return "year";
    case /* None */6 :
        return "";
    
  }
}

function decodeTimeFrame(json) {
  var timeFrameString = Json_decode.field("time_frame", (function (param) {
          return Json_decode.optional(Json_decode.string, param);
        }), json);
  if (timeFrameString !== undefined) {
    return timeFrameFromString(timeFrameString);
  } else {
    return /* None */6;
  }
}

function dataField(t) {
  return t.dataField;
}

function timeFrame(t) {
  return t.timeFrame;
}

function isTimestamp(t) {
  return t.isTimestamp;
}

function decode(json) {
  return {
          dataField: Json_decode.field("data_field", (function (param) {
                  return Json_decode.optional(DataField$ReactHooksTemplate.decode, param);
                }), json),
          timeFrame: decodeTimeFrame(json),
          isTimestamp: Json_decode.field("is_timestamp", Json_decode.bool, json)
        };
}

function setDataField(dataField, t) {
  return {
          dataField: dataField,
          timeFrame: t.timeFrame,
          isTimestamp: t.isTimestamp
        };
}

function setTimeFrame(timeFrame, t) {
  return {
          dataField: t.dataField,
          timeFrame: timeFrameFromString(timeFrame),
          isTimestamp: t.isTimestamp
        };
}

function setIsTimestamp(isTimestamp, t) {
  return {
          dataField: t.dataField,
          timeFrame: t.timeFrame,
          isTimestamp: isTimestamp
        };
}

var empty = {
  dataField: undefined,
  timeFrame: undefined,
  isTimestamp: false
};

var timeFrames = {
  hd: "minute",
  tl: {
    hd: "hour",
    tl: {
      hd: "day",
      tl: {
        hd: "month",
        tl: {
          hd: "quarter",
          tl: {
            hd: "year",
            tl: /* [] */0
          }
        }
      }
    }
  }
};

exports.timeFrameFromString = timeFrameFromString;
exports.timeFrameToString = timeFrameToString;
exports.decodeTimeFrame = decodeTimeFrame;
exports.empty = empty;
exports.dataField = dataField;
exports.timeFrame = timeFrame;
exports.isTimestamp = isTimestamp;
exports.timeFrames = timeFrames;
exports.decode = decode;
exports.setDataField = setDataField;
exports.setTimeFrame = setTimeFrame;
exports.setIsTimestamp = setIsTimestamp;
/* No side effect */

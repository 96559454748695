// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var List = require("rescript/lib/js/list.js");
var Moment = require("moment");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");
var PermissionTree$ReactHooksTemplate = require("./permissionTree.bs.js");

function create(id, name, description, createdAt, permissions, checksum) {
  return {
          id: id,
          name: name,
          description: description,
          permissions: permissions,
          createdAt: createdAt,
          checksum: checksum
        };
}

var emptyString = "";

function empty(param) {
  return {
          id: 0,
          name: emptyString,
          description: emptyString,
          permissions: /* [] */0,
          createdAt: Moment(new Date()),
          checksum: undefined
        };
}

function id(t) {
  return t.id;
}

function name(t) {
  return t.name;
}

function setName(t, name) {
  return {
          id: t.id,
          name: name,
          description: t.description,
          permissions: t.permissions,
          createdAt: t.createdAt,
          checksum: t.checksum
        };
}

function setDescription(t, description) {
  return {
          id: t.id,
          name: t.name,
          description: description,
          permissions: t.permissions,
          createdAt: t.createdAt,
          checksum: t.checksum
        };
}

function setPermissions(t, permissions) {
  return {
          id: t.id,
          name: t.name,
          description: t.description,
          permissions: permissions,
          createdAt: t.createdAt,
          checksum: t.checksum
        };
}

function description(t) {
  return t.description;
}

function createdAt(t) {
  return t.createdAt;
}

function permissions(t) {
  return t.permissions;
}

function enabledCapabilities(t) {
  return List.concat(Belt_List.map(t.permissions, (function (permission) {
                    return PermissionTree$ReactHooksTemplate.enabledCapabilities(/* [] */0, permission);
                  })));
}

function decode(json) {
  return {
          id: Json_decode.field("id", Json_decode.$$int, json),
          name: Json_decode.field("name", Json_decode.string, json),
          description: Json_decode.field("description", Json_decode.string, json),
          permissions: Json_decode.withDefault(/* [] */0, PermissionTree$ReactHooksTemplate.decode, json),
          createdAt: Moment(new Date(Json_decode.field("created_at", Json_decode.string, json))),
          checksum: Json_decode.field("checksum", (function (param) {
                  return Json_decode.optional(Json_decode.string, param);
                }), json)
        };
}

function encode(t) {
  var enabledCapabilities = List.fold_left(PermissionTree$ReactHooksTemplate.enabledCapabilities, /* [] */0, t.permissions);
  var disabledCapabilities = List.fold_left(PermissionTree$ReactHooksTemplate.disabledCapabilities, /* [] */0, t.permissions);
  return Json_encode.object_({
              hd: [
                "name",
                t.name
              ],
              tl: {
                hd: [
                  "description",
                  t.description
                ],
                tl: {
                  hd: [
                    "permissions",
                    Json_encode.object_({
                          hd: [
                            "add",
                            Json_encode.list((function (prim) {
                                    return prim;
                                  }), enabledCapabilities)
                          ],
                          tl: {
                            hd: [
                              "remove",
                              Json_encode.list((function (prim) {
                                      return prim;
                                    }), disabledCapabilities)
                            ],
                            tl: {
                              hd: [
                                "checksum",
                                Json_encode.nullable((function (prim) {
                                        return prim;
                                      }), t.checksum)
                              ],
                              tl: /* [] */0
                            }
                          }
                        })
                  ],
                  tl: {
                    hd: [
                      "createdAt",
                      t.createdAt.format("YYYY-MM-DD HH:mm:SS")
                    ],
                    tl: /* [] */0
                  }
                }
              }
            });
}

function debug(t) {
  console.log("Role ID   : " + String(t.id));
  console.log("Role Name : " + t.name);
  
}

exports.create = create;
exports.emptyString = emptyString;
exports.empty = empty;
exports.id = id;
exports.name = name;
exports.setName = setName;
exports.description = description;
exports.setDescription = setDescription;
exports.setPermissions = setPermissions;
exports.createdAt = createdAt;
exports.permissions = permissions;
exports.enabledCapabilities = enabledCapabilities;
exports.decode = decode;
exports.encode = encode;
exports.debug = debug;
/* moment Not a pure module */

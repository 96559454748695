// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");

function id(t) {
  return t.id;
}

function name(t) {
  return t.name;
}

function $$default(t) {
  return t.default;
}

function decodeItems(json) {
  return {
          id: Json_decode.field("id", Json_decode.$$int, json),
          name: Json_decode.field("name", Json_decode.string, json),
          default: Json_decode.field("default", Json_decode.bool, json)
        };
}

function fromJson(json) {
  return Json_decode.field("dashboards", (function (param) {
                return Json_decode.list(decodeItems, param);
              }), json);
}

exports.fromJson = fromJson;
exports.name = name;
exports.id = id;
exports.$$default = $$default;
exports.default = $$default;
exports.__esModule = true;
/* No side effect */

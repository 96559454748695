// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");

function OverlayClosable(Props) {
  var hidden = Props.hidden;
  var children = Props.children;
  var hiddenCSS = hidden ? "hidden" : "";
  return React.createElement("div", {
              className: "" + hiddenCSS + " fixed inset-0 bg-gray-900 opacity-75 z-50 p-20-px"
            }, children);
}

var make = OverlayClosable;

exports.make = make;
/* react Not a pure module */

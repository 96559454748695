// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var $$Array = require("rescript/lib/js/array.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Js_json = require("rescript/lib/js/js_json.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Http$ReactHooksTemplate = require("../../common/http.bs.js");
var Image$ReactHooksTemplate = require("../../common/image.bs.js");
var Utils$ReactHooksTemplate = require("../../common/utils.bs.js");
var Highcharts$ReactHooksTemplate = require("../../common/Highcharts.bs.js");
var InsightsFilters$ReactHooksTemplate = require("../filters/InsightsFilters.bs.js");

function reducer(state, x) {
  if (typeof x === "number") {
    switch (x) {
      case /* ToggleFilter */1 :
          return {
                  loadingCorrelationData: state.loadingCorrelationData,
                  toggleFilter: !state.toggleFilter,
                  filterPills: state.filterPills,
                  filters: state.filters,
                  currentFilter: state.currentFilter,
                  correlationData: state.correlationData
                };
      case /* FetchFiltersInProgress */0 :
      case /* FetchCorrelationDataInProgress */2 :
          break;
      
    }
  } else {
    switch (x.TAG | 0) {
      case /* FetchedCorrelationData */0 :
          return {
                  loadingCorrelationData: state.loadingCorrelationData,
                  toggleFilter: state.toggleFilter,
                  filterPills: state.filterPills,
                  filters: state.filters,
                  currentFilter: state.currentFilter,
                  correlationData: x._0
                };
      case /* SetFiltersAndPills */1 :
          return {
                  loadingCorrelationData: true,
                  toggleFilter: state.toggleFilter,
                  filterPills: x._1,
                  filters: x._0,
                  currentFilter: state.currentFilter,
                  correlationData: state.correlationData
                };
      case /* SetFilters */2 :
          return {
                  loadingCorrelationData: state.loadingCorrelationData,
                  toggleFilter: state.toggleFilter,
                  filterPills: state.filterPills,
                  filters: x._0,
                  currentFilter: state.currentFilter,
                  correlationData: state.correlationData
                };
      
    }
  }
  return {
          loadingCorrelationData: true,
          toggleFilter: state.toggleFilter,
          filterPills: state.filterPills,
          filters: state.filters,
          currentFilter: state.currentFilter,
          correlationData: state.correlationData
        };
}

var initialState_correlationData = {
  categories: /* [] */0,
  data: null
};

var initialState = {
  loadingCorrelationData: true,
  toggleFilter: false,
  filterPills: /* [] */0,
  filters: /* [] */0,
  currentFilter: 0,
  correlationData: initialState_correlationData
};

function InsightsCorrelation(Props) {
  var match = React.useReducer(reducer, initialState);
  var dispatch = match[1];
  var state = match[0];
  var fetchVisualizations = function (param) {
    Http$ReactHooksTemplate.fetchGetJSONDecode("/api/v1/insights/correlation_data", (function (json) {
                return {
                        categories: Json_decode.field("categories", (function (param) {
                                return Json_decode.list(Json_decode.string, param);
                              }), json),
                        data: Belt_Option.getWithDefault(Js_json.decodeObject(json), {})["data"]
                      };
              })).then(function (xs) {
            console.log(xs);
            return Promise.resolve(xs);
          }).then(function (xs) {
          return Promise.resolve(setTimeout((function (param) {
                            return Curry._1(dispatch, {
                                        TAG: /* FetchedCorrelationData */0,
                                        _0: xs
                                      });
                          }), Utils$ReactHooksTemplate.timeout));
        });
    
  };
  React.useEffect((function () {
          fetchVisualizations(undefined);
          
        }), []);
  var onApplyFilter = function (param) {
    fetchVisualizations(undefined);
    
  };
  var toggleFilter = function (param) {
    Curry._1(dispatch, /* ToggleFilter */1);
    return Curry._1(dispatch, {
                TAG: /* SetFilters */2,
                _0: state.filters
              });
  };
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  className: "p-20-px bg-porcelain"
                }, state.toggleFilter ? React.createElement(InsightsFilters$ReactHooksTemplate.make, {
                        onClose: toggleFilter,
                        onApply: onApplyFilter,
                        showDateFilter: false,
                        showFilterPills: false,
                        correlation: true
                      }) : React.createElement(React.Fragment, undefined), React.createElement("div", {
                      className: "px-16-px flex justify-between pb-20-px"
                    }, React.createElement("div", {
                          className: "flex flex-wrap gap-2"
                        }, React.createElement("div", {
                              className: "flex items-center font-normal text-sm h-8"
                            }, "Active Filters"), React.createElement(InsightsFilters$ReactHooksTemplate.make, {
                              onClose: toggleFilter,
                              onApply: onApplyFilter,
                              showDateFilter: false,
                              showFilterPills: true,
                              correlation: true
                            })), React.createElement("div", {
                          className: "float-right"
                        }, React.createElement("button", {
                              className: "bg-ghostwhite border border-gray-400\n                              shadow-inner flex rounded py-2 px-3 text-xs",
                              id: "filters",
                              onClick: (function (param) {
                                  return toggleFilter(undefined);
                                })
                            }, React.createElement("img", {
                                  className: "h-14-px mr-2-px",
                                  src: Image$ReactHooksTemplate.filterButton
                                }), "Filters"))), React.createElement("div", {
                      className: "flex flex-col pb-20-px"
                    }, React.createElement("div", {
                          className: "w-full border mb-2 bg-white h-10"
                        }, React.createElement("div", {
                              className: "flex items-center"
                            }, React.createElement("div", {
                                  className: "w-3 bg-red-600 h-10 mr-2"
                                }), React.createElement("p", {
                                  className: "text-lg font-extrabold"
                                }, "CORRELATION MATRIX"))), React.createElement("div", {
                          className: "border bg-white flex justify-center p-2 h-full w-full"
                        }, React.createElement(Highcharts$ReactHooksTemplate.HeatmapBinding.make, {
                              mapHeight: "30%",
                              categories: $$Array.of_list(state.correlationData.categories),
                              data: state.correlationData.data
                            })))));
}

var make = InsightsCorrelation;

exports.reducer = reducer;
exports.initialState = initialState;
exports.make = make;
/* initialState Not a pure module */

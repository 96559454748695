// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var List = require("rescript/lib/js/list.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Http$ReactHooksTemplate = require("../common/http.bs.js");
var Utils$ReactHooksTemplate = require("../common/utils.bs.js");
var Domain$ReactHooksTemplate = require("./domain.bs.js");
var Notification$ReactHooksTemplate = require("../common/Notification.bs.js");
var NewPasswordInput$ReactHooksTemplate = require("../common/NewPasswordInput.bs.js");

function isPasswordFilled(x) {
  return !Curry._1(Domain$ReactHooksTemplate.$$String.Password.isEmpty, x);
}

function isPasswordValid(x) {
  if (isPasswordFilled(x)) {
    return Utils$ReactHooksTemplate.checkMinLength8(Curry._1(Domain$ReactHooksTemplate.$$String.Password.show, x));
  } else {
    return true;
  }
}

function isAlphanumericPassword(x) {
  if (isPasswordFilled(x)) {
    return Utils$ReactHooksTemplate.checkAlphanumeric(Curry._1(Domain$ReactHooksTemplate.$$String.Password.show, x));
  } else {
    return true;
  }
}

function isUserNameInPassword(email, x) {
  var unameInPwd = Utils$ReactHooksTemplate.checkUsernameExistInPassword(email, Curry._1(Domain$ReactHooksTemplate.$$String.Password.show, x));
  if (isPasswordFilled(x)) {
    return unameInPwd;
  } else {
    return false;
  }
}

function areIndpendentFieldsValid(passwordInputFields) {
  if (isPasswordValid(passwordInputFields.newPassword)) {
    return isPasswordValid(passwordInputFields.newPasswordConfirmation);
  } else {
    return false;
  }
}

function arePasswordsFilled(x, y) {
  if (isPasswordFilled(x)) {
    return isPasswordFilled(y);
  } else {
    return false;
  }
}

function arePasswordsExactMatch(x, y) {
  if (arePasswordsFilled(x, y)) {
    return Caml_obj.caml_equal(x, y);
  } else {
    return true;
  }
}

function areDepedentFieldsValid(passwordInputFields) {
  return arePasswordsExactMatch(passwordInputFields.newPassword, passwordInputFields.newPasswordConfirmation);
}

var emptyPasswordConfirmation_oldPassword = Curry._1(Domain$ReactHooksTemplate.$$String.Password.empty, undefined);

var emptyPasswordConfirmation_newPassword = Curry._1(Domain$ReactHooksTemplate.$$String.Password.empty, undefined);

var emptyPasswordConfirmation_newPasswordConfirmation = Curry._1(Domain$ReactHooksTemplate.$$String.Password.empty, undefined);

var emptyPasswordConfirmation = {
  oldPassword: emptyPasswordConfirmation_oldPassword,
  newPassword: emptyPasswordConfirmation_newPassword,
  newPasswordConfirmation: emptyPasswordConfirmation_newPasswordConfirmation,
  flash: undefined
};

function buildPasswordDict(passwordConfirmation) {
  var params = {};
  params["old_password"] = Curry._1(Domain$ReactHooksTemplate.$$String.Password.show, passwordConfirmation.oldPassword);
  params["password"] = Curry._1(Domain$ReactHooksTemplate.$$String.Password.show, passwordConfirmation.newPassword);
  params["password_confirmation"] = Curry._1(Domain$ReactHooksTemplate.$$String.Password.show, passwordConfirmation.newPasswordConfirmation);
  var user = {};
  user["user"] = params;
  return user;
}

function updatePassword(passwordConfirmation) {
  return Http$ReactHooksTemplate.fetchPostJSON("/api/v1/users/update_password", buildPasswordDict(passwordConfirmation));
}

function hasNewPassword(passwordConfirmation) {
  return Caml_obj.caml_notequal(passwordConfirmation.newPassword, Curry._1(Domain$ReactHooksTemplate.$$String.Password.empty, undefined));
}

function hasNewPasswordConfirmation(passwordConfirmation) {
  return Caml_obj.caml_notequal(passwordConfirmation.newPasswordConfirmation, Curry._1(Domain$ReactHooksTemplate.$$String.Password.empty, undefined));
}

function hasMandatoryParams(passwordConfirmation) {
  if (hasNewPassword(passwordConfirmation)) {
    return hasNewPasswordConfirmation(passwordConfirmation);
  } else {
    return false;
  }
}

function isFormValid(passwordConfirmation) {
  if (hasMandatoryParams(passwordConfirmation) && areIndpendentFieldsValid(passwordConfirmation)) {
    return areDepedentFieldsValid(passwordConfirmation);
  } else {
    return false;
  }
}

function UserUpdatePassword(Props) {
  var match = React.useState(function () {
        return emptyPasswordConfirmation;
      });
  var setState = match[1];
  var passwordConfirmation = match[0];
  var handleOldPasswordChange = function ($$event) {
    return Utils$ReactHooksTemplate.handleInput(Utils$ReactHooksTemplate.checkMaxLength36, (function (x) {
                  return Curry._1(setState, (function (passwordConfirmation) {
                                return {
                                        oldPassword: x,
                                        newPassword: passwordConfirmation.newPassword,
                                        newPasswordConfirmation: passwordConfirmation.newPasswordConfirmation,
                                        flash: passwordConfirmation.flash
                                      };
                              }));
                }), $$event);
  };
  var handlePasswordChange = function ($$event) {
    return Utils$ReactHooksTemplate.handleInput(Utils$ReactHooksTemplate.checkMaxLength36, (function (x) {
                  return Curry._1(setState, (function (passwordConfirmation) {
                                return {
                                        oldPassword: passwordConfirmation.oldPassword,
                                        newPassword: x,
                                        newPasswordConfirmation: passwordConfirmation.newPasswordConfirmation,
                                        flash: passwordConfirmation.flash
                                      };
                              }));
                }), $$event);
  };
  var handlePasswordConfirmationChange = function ($$event) {
    return Utils$ReactHooksTemplate.handleInput(Utils$ReactHooksTemplate.checkMaxLength36, (function (x) {
                  return Curry._1(setState, (function (passwordConfirmation) {
                                return {
                                        oldPassword: passwordConfirmation.oldPassword,
                                        newPassword: passwordConfirmation.newPassword,
                                        newPasswordConfirmation: x,
                                        flash: passwordConfirmation.flash
                                      };
                              }));
                }), $$event);
  };
  var decodeApiResponseStatus = function (response) {
    return Json_decode.field("status", Json_decode.string, response).toLowerCase();
  };
  var handleSubmit = function ($$event) {
    $$event.preventDefault();
    Http$ReactHooksTemplate.fetchPostJSON("/api/v1/users/update_password", buildPasswordDict(passwordConfirmation)).then(function (response) {
          if (decodeApiResponseStatus(response) === "success") {
            ((window.location.href="/users/sign_in"));
          } else {
            var errorMessage = List.fold_left((function (x, y) {
                    return x + y;
                  }), "", Json_decode.field("errors", (function (x) {
                        return Json_decode.field("password", (function (param) {
                                      return Json_decode.list(Json_decode.string, param);
                                    }), x);
                      }), response));
            Curry._1(setState, (function (passwordConfirmation) {
                    return {
                            oldPassword: passwordConfirmation.oldPassword,
                            newPassword: passwordConfirmation.newPassword,
                            newPasswordConfirmation: passwordConfirmation.newPasswordConfirmation,
                            flash: /* ErrorFlash */{
                              _0: errorMessage
                            }
                          };
                  }));
          }
          return Promise.resolve(undefined);
        });
    
  };
  var validCSS = "hidden";
  var errorCSS = "text-red-600 font-medium";
  var passwordMinLengthCSS = function (x) {
    if (isPasswordValid(x)) {
      return validCSS;
    } else {
      return errorCSS;
    }
  };
  var passwordAlphanumericCSS = function (x) {
    if (isAlphanumericPassword(x)) {
      return validCSS;
    } else {
      return errorCSS;
    }
  };
  var passwordMatchCSS = function (x, y) {
    if (arePasswordsExactMatch(x, y)) {
      return validCSS;
    } else {
      return errorCSS;
    }
  };
  var handleFlashClose = function (param) {
    return Curry._1(setState, (function (passwordConfirmation) {
                  return {
                          oldPassword: passwordConfirmation.oldPassword,
                          newPassword: passwordConfirmation.newPassword,
                          newPasswordConfirmation: passwordConfirmation.newPasswordConfirmation,
                          flash: undefined
                        };
                }));
  };
  var match$1 = passwordConfirmation.flash;
  return React.createElement("div", {
              className: "p-30-px"
            }, match$1 !== undefined ? (
                match$1 ? React.createElement(Notification$ReactHooksTemplate.make, {
                        text: match$1._0,
                        isError: true,
                        handleClose: handleFlashClose
                      }) : React.createElement(React.Fragment, undefined, React.createElement(Notification$ReactHooksTemplate.make, {
                            text: "Successfully updated password",
                            handleClose: handleFlashClose
                          }), React.createElement("p", undefined, "sdsfds"))
              ) : React.createElement(React.Fragment, undefined), React.createElement("h1", {
                  className: "text-32-px mb-30-px"
                }, "Change Password"), React.createElement("form", {
                  onSubmit: handleSubmit
                }, React.createElement("div", {
                      className: "mb-30-px"
                    }, React.createElement(NewPasswordInput$ReactHooksTemplate.make, {
                          password: passwordConfirmation.oldPassword,
                          label: "Old Password",
                          handleChange: handleOldPasswordChange
                        })), React.createElement("div", {
                      className: "mb-30-px"
                    }, React.createElement(NewPasswordInput$ReactHooksTemplate.make, {
                          password: passwordConfirmation.newPassword,
                          label: "New Password",
                          handleChange: handlePasswordChange
                        }), React.createElement("div", {
                          className: passwordMinLengthCSS(passwordConfirmation.newPassword) + " mb-10-px"
                        }, "Minimum length should be 8."), React.createElement("div", {
                          className: passwordAlphanumericCSS(passwordConfirmation.newPassword) + " mb-10-px"
                        }, "Password should contain atleast one lowercase, one uppercase, one number and one special character"), React.createElement("div", {
                          className: passwordMatchCSS(passwordConfirmation.newPassword, passwordConfirmation.newPasswordConfirmation)
                        }, "Passwords do not match.")), React.createElement("div", {
                      className: "mb-30-px"
                    }, React.createElement(NewPasswordInput$ReactHooksTemplate.make, {
                          password: passwordConfirmation.newPasswordConfirmation,
                          label: "Confirm Password",
                          handleChange: handlePasswordConfirmationChange
                        }), React.createElement("div", {
                          className: passwordMinLengthCSS(passwordConfirmation.newPasswordConfirmation) + " mb-10-px"
                        }, "Minimum length should be 8."), React.createElement("div", {
                          className: passwordMatchCSS(passwordConfirmation.newPassword, passwordConfirmation.newPasswordConfirmation)
                        }, "Passwords do not match."), React.createElement("div", {
                          className: passwordAlphanumericCSS(passwordConfirmation.newPasswordConfirmation) + " mb-10-px"
                        }, "Password should contain atleast one lowercase, one uppercase, one number and one special character")), React.createElement("div", undefined, React.createElement("input", {
                          className: "bg-teal-500 hover:bg-teal-700 py-6-px px-20-px font-normal \n                     text-white font-16-px leading-snug cursor-pointer",
                          disabled: !isFormValid(passwordConfirmation),
                          type: "submit",
                          value: "Submit"
                        }))));
}

var make = UserUpdatePassword;

exports.isPasswordFilled = isPasswordFilled;
exports.isPasswordValid = isPasswordValid;
exports.isAlphanumericPassword = isAlphanumericPassword;
exports.isUserNameInPassword = isUserNameInPassword;
exports.areIndpendentFieldsValid = areIndpendentFieldsValid;
exports.arePasswordsFilled = arePasswordsFilled;
exports.arePasswordsExactMatch = arePasswordsExactMatch;
exports.areDepedentFieldsValid = areDepedentFieldsValid;
exports.emptyPasswordConfirmation = emptyPasswordConfirmation;
exports.buildPasswordDict = buildPasswordDict;
exports.updatePassword = updatePassword;
exports.hasNewPassword = hasNewPassword;
exports.hasNewPasswordConfirmation = hasNewPasswordConfirmation;
exports.hasMandatoryParams = hasMandatoryParams;
exports.isFormValid = isFormValid;
exports.make = make;
/* emptyPasswordConfirmation Not a pure module */

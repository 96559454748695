// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var WordcloudJs = require("./wordcloud.js").default;

var Chart = {};

var make = WordcloudJs;

var Wordcloud = {
  make: make
};

var Arg = {};

var ColorProp = {
  Arg: Arg
};

var Geom = {
  ColorProp: ColorProp
};

var TitleProp = {};

var Axis = {
  TitleProp: TitleProp
};

var Tooltip = {};

var Arg$1 = {};

var ContentProp = {
  Arg: Arg$1
};

var Label = {
  ContentProp: ContentProp
};

var Legend = {};

var Coord = {};

exports.Chart = Chart;
exports.Wordcloud = Wordcloud;
exports.Geom = Geom;
exports.Axis = Axis;
exports.Tooltip = Tooltip;
exports.Label = Label;
exports.Legend = Legend;
exports.Coord = Coord;
/* make Not a pure module */

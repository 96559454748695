// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';


function getInputValue(x) {
  return x._0;
}

function getValidationError(x) {
  switch (x.TAG | 0) {
    case /* Invalid */1 :
        return x._1;
    case /* Valid */0 :
    case /* Pristine */2 :
        return /* [] */0;
    
  }
}

exports.getInputValue = getInputValue;
exports.getValidationError = getValidationError;
/* No side effect */

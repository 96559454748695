// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var List = require("rescript/lib/js/list.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var Http$ReactHooksTemplate = require("../../common/http.bs.js");
var Image$ReactHooksTemplate = require("../../common/image.bs.js");
var Utils$ReactHooksTemplate = require("../../common/utils.bs.js");
var Filter$ReactHooksTemplate = require("../filters/filter.bs.js");
var Loading$ReactHooksTemplate = require("./Loading.bs.js");
var Dashboard$ReactHooksTemplate = require("./dashboard.bs.js");
var ListFilter$ReactHooksTemplate = require("../filters/listFilter.bs.js");
var DashboardComponent$ReactHooksTemplate = require("./DashboardComponent.bs.js");

function lableFunction(a, b) {
  console.log(a);
  console.log(b);
  return ('<p>' + a.title + '</p><ul><li>NPS: ' + a.nps + '</li></ul>');
}

var initialState = {
  loading: true,
  dashboard: undefined,
  dashboards: /* [] */0,
  drillDownChartDetails: undefined,
  currentRef: undefined,
  breadCrumbs: /* [] */0
};

function reducer(state, x) {
  if (typeof x === "number") {
    return {
            loading: true,
            dashboard: state.dashboard,
            dashboards: state.dashboards,
            drillDownChartDetails: state.drillDownChartDetails,
            currentRef: state.currentRef,
            breadCrumbs: state.breadCrumbs
          };
  }
  switch (x.TAG | 0) {
    case /* FetchDashboardCompleted */0 :
        var dashboardId = x._1;
        var dashboards = x._0;
        var defaultDashboard = List.find_opt(Dashboard$ReactHooksTemplate.$$default, dashboards);
        var headOfList = defaultDashboard !== undefined ? Caml_option.some(Caml_option.valFromOption(defaultDashboard)) : Belt_List.head(dashboards);
        var selectedDashboard;
        if (dashboardId !== undefined) {
          var x$1 = List.find_opt((function (x) {
                  return Dashboard$ReactHooksTemplate.id(x) === dashboardId;
                }), dashboards);
          if (x$1 !== undefined) {
            selectedDashboard = Caml_option.some(Caml_option.valFromOption(x$1));
          } else {
            RescriptReactRouter.push("/404");
            window.location.reload();
            selectedDashboard = undefined;
          }
        } else {
          selectedDashboard = defaultDashboard !== undefined ? Caml_option.some(Caml_option.valFromOption(defaultDashboard)) : headOfList;
        }
        console.log(headOfList);
        var dashboard = selectedDashboard !== undefined ? Caml_option.some(Caml_option.valFromOption(selectedDashboard)) : defaultDashboard;
        return {
                loading: false,
                dashboard: dashboard,
                dashboards: dashboards,
                drillDownChartDetails: state.drillDownChartDetails,
                currentRef: dashboard,
                breadCrumbs: dashboard !== undefined ? ({
                      hd: Caml_option.valFromOption(dashboard),
                      tl: /* [] */0
                    }) : /* [] */0
              };
    case /* DrillDown */1 :
        return {
                loading: state.loading,
                dashboard: state.dashboard,
                dashboards: state.dashboards,
                drillDownChartDetails: x._0,
                currentRef: state.currentRef,
                breadCrumbs: state.breadCrumbs
              };
    case /* ChangeDashboard */2 :
        var x$2 = x._0;
        return {
                loading: state.loading,
                dashboard: Caml_option.some(x$2),
                dashboards: state.dashboards,
                drillDownChartDetails: undefined,
                currentRef: Caml_option.some(x$2),
                breadCrumbs: {
                  hd: x$2,
                  tl: /* [] */0
                }
              };
    case /* GoToLinkedDashboard */3 :
        var x$3 = x._0;
        return {
                loading: state.loading,
                dashboard: state.dashboard,
                dashboards: state.dashboards,
                drillDownChartDetails: undefined,
                currentRef: Caml_option.some(x$3),
                breadCrumbs: Belt_List.reverse({
                      hd: x$3,
                      tl: state.breadCrumbs
                    })
              };
    case /* GoToClickedDashboard */4 :
        var xs = Belt_List.take(state.breadCrumbs, x._1);
        var breadCrumbs = xs !== undefined ? xs : /* [] */0;
        return {
                loading: state.loading,
                dashboard: state.dashboard,
                dashboards: state.dashboards,
                drillDownChartDetails: undefined,
                currentRef: Caml_option.some(x._0),
                breadCrumbs: breadCrumbs
              };
    
  }
}

function Dashboards(Props) {
  var dashboardId = Props.dashboardId;
  console.log(dashboardId);
  var match = React.useReducer(reducer, initialState);
  var dispatch = match[1];
  var state = match[0];
  var clearTempFilter = function (param) {
    return Http$ReactHooksTemplate.fetchPostEncodedJSON("/api/v1/filters", Json_encode.object_({
                    hd: [
                      "scope",
                      "temporary"
                    ],
                    tl: {
                      hd: [
                        "filters",
                        Json_encode.list(Filter$ReactHooksTemplate.encode, /* [] */0)
                      ],
                      tl: /* [] */0
                    }
                  }));
  };
  React.useEffect((function () {
          clearTempFilter(undefined).then(function (param) {
                    Curry._1(dispatch, /* FetchDashboardInProgress */0);
                    return Http$ReactHooksTemplate.fetchGetJSONDecode("/api/v1/dashboards", Dashboard$ReactHooksTemplate.fromJson).then(function (xs) {
                                  return Promise.resolve((setTimeout((function (param) {
                                                      return Curry._1(dispatch, {
                                                                  TAG: /* FetchDashboardCompleted */0,
                                                                  _0: xs,
                                                                  _1: dashboardId
                                                                });
                                                    }), Utils$ReactHooksTemplate.timeout), undefined));
                                }).catch(function (err) {
                                console.log(err);
                                return Promise.resolve(Curry._1(dispatch, {
                                                TAG: /* FetchDashboardCompleted */0,
                                                _0: /* [] */0,
                                                _1: undefined
                                              }));
                              });
                  }).then(function (result) {
                  return Promise.resolve((console.log(result), undefined));
                }).catch(function (err) {
                return Promise.resolve((console.log(err), undefined));
              });
          
        }), []);
  var onDashboardChange = function (dashboard) {
    return Curry._1(dispatch, {
                TAG: /* ChangeDashboard */2,
                _0: dashboard
              });
  };
  var tmp;
  if (state.loading) {
    tmp = React.createElement("div", {
          className: "h-screen w-full animated fadeIn"
        }, React.createElement(Loading$ReactHooksTemplate.make, {
              img: Image$ReactHooksTemplate.dashboardLoading,
              text: "Loading Dashboards"
            }));
  } else {
    var match$1 = state.currentRef;
    var match$2 = state.dashboard;
    tmp = match$1 !== undefined && match$2 !== undefined ? React.createElement(React.Fragment, undefined, React.createElement(DashboardComponent$ReactHooksTemplate.make, {
                dashboard: Caml_option.valFromOption(match$2),
                dashboards: state.dashboards,
                drillDownChartDetails: state.drillDownChartDetails,
                onClick: (function (key, chartId, xs) {
                    if (typeof xs === "number") {
                      return ;
                    }
                    if (xs.TAG === /* DrillDown */0) {
                      var x = xs._0;
                      var match = state.drillDownChartDetails;
                      var empty = match !== undefined ? match[1] === key : false;
                      var selectedFilter;
                      if (empty) {
                        selectedFilter = /* [] */0;
                      } else {
                        var ys = Filter$ReactHooksTemplate.filterType(x);
                        var tmp;
                        tmp = ys.TAG === /* List */2 ? ({
                              TAG: /* List */2,
                              _0: ListFilter$ReactHooksTemplate.setSelected(key, ys._0)
                            }) : Filter$ReactHooksTemplate.filterType(x);
                        selectedFilter = {
                          hd: Filter$ReactHooksTemplate.setFilterTypes(tmp, x),
                          tl: /* [] */0
                        };
                      }
                      Http$ReactHooksTemplate.fetchPostEncodedJSON("/api/v1/filters", Json_encode.object_({
                                    hd: [
                                      "scope",
                                      "temporary"
                                    ],
                                    tl: {
                                      hd: [
                                        "filters",
                                        Json_encode.list(Filter$ReactHooksTemplate.encode, selectedFilter)
                                      ],
                                      tl: /* [] */0
                                    }
                                  })).then(function (param) {
                              return Promise.resolve(empty ? Curry._1(dispatch, {
                                                TAG: /* DrillDown */1,
                                                _0: [
                                                  chartId,
                                                  ""
                                                ]
                                              }) : Curry._1(dispatch, {
                                                TAG: /* DrillDown */1,
                                                _0: [
                                                  chartId,
                                                  key
                                                ]
                                              }));
                            }).catch(function (err) {
                            return Promise.resolve((console.log(err), undefined));
                          });
                      return ;
                    }
                    var dashboard = List.find_opt((function (y) {
                            return Dashboard$ReactHooksTemplate.name(y) === key;
                          }), state.dashboards);
                    if (dashboard === undefined) {
                      return ;
                    }
                    var dashboard$1 = Caml_option.valFromOption(dashboard);
                    clearTempFilter(undefined).then(function (param) {
                          return Promise.resolve(Curry._1(dispatch, {
                                          TAG: /* GoToLinkedDashboard */3,
                                          _0: dashboard$1
                                        }));
                        });
                    
                  }),
                onDashboardChange: onDashboardChange,
                breadCrumbs: state.breadCrumbs,
                currentRef: Caml_option.valFromOption(match$1),
                breadCrumbsOnClick: (function (item, index) {
                    clearTempFilter(undefined).then(function (param) {
                          return Promise.resolve(Curry._1(dispatch, {
                                          TAG: /* GoToClickedDashboard */4,
                                          _0: item,
                                          _1: index
                                        }));
                        });
                    
                  })
              })) : "No Dashboards are loaded for the application.";
  }
  return React.createElement(React.Fragment, undefined, tmp);
}

var make = Dashboards;

exports.lableFunction = lableFunction;
exports.initialState = initialState;
exports.reducer = reducer;
exports.make = make;
/* react Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Utils$ReactHooksTemplate = require("../common/utils.bs.js");
var ThankYou$ReactHooksTemplate = require("./ThankYou.bs.js");

function ThankYouPreview(Props) {
  var thankYou = Props.thankYou;
  var onThankYouChange = Props.onThankYouChange;
  var urlPath = ThankYou$ReactHooksTemplate.image(thankYou);
  return React.createElement("div", {
              className: "flex flex-col w-full p-16"
            }, urlPath !== undefined ? React.createElement("img", {
                    className: "w-full h-275-px small:h-200-px mb-48-px",
                    src: window.location.origin + urlPath
                  }) : React.createElement(React.Fragment, undefined), React.createElement("div", {
                  className: "mb-8"
                }, React.createElement("div", {
                      className: "text-aurometalsaurus text-sm mb-2 font-normal"
                    }, "Main Title"), React.createElement("input", {
                      className: "h-48-px py-2 px-4 bg-alicebluepurple border-b border-gray-500 focus:border-gray-600 text-xs w-full",
                      disabled: true,
                      placeholder: "Enter Title",
                      type: "text",
                      value: "Thank you for your feedback!"
                    })), React.createElement("div", {
                  className: "mb-8"
                }, React.createElement("div", {
                      className: "text-aurometalsaurus text-sm mb-2 font-normal"
                    }, "Custom Text"), React.createElement("input", {
                      className: "h-48-px py-2 px-4 bg-alicebluepurple border-b border-gray-500 focus:border-gray-600 text-xs w-full",
                      placeholder: "Enter Text",
                      type: "text",
                      value: ThankYou$ReactHooksTemplate.text(thankYou),
                      onChange: (function ($$event) {
                          return Curry._1(onThankYouChange, ThankYou$ReactHooksTemplate.setText(thankYou, Utils$ReactHooksTemplate.formTargetValue($$event)));
                        })
                    })));
}

var make = ThankYouPreview;

exports.make = make;
/* react Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var List = require("rescript/lib/js/list.js");
var Curry = require("rescript/lib/js/curry.js");
var Moment = require("moment");
var Pervasives = require("rescript/lib/js/pervasives.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Role$ReactHooksTemplate = require("./role.bs.js");
var Domain$ReactHooksTemplate = require("./domain.bs.js");
var DataRestriction$ReactHooksTemplate = require("./dataRestriction.bs.js");

function id(t) {
  return t.id;
}

function email(t) {
  return t.email;
}

function firstName(t) {
  return t.firstName;
}

function lastName(t) {
  return t.lastName;
}

function username(t) {
  return t.username;
}

function active(t) {
  return t.active;
}

function roles(t) {
  return t.roles;
}

function dataRestrictions(t) {
  return t.dataRestrictions;
}

function lastSignInAt(t) {
  return t.lastSignInAt;
}

function decodeNameField(label, json) {
  return Curry._1(Domain$ReactHooksTemplate.$$String.Name.create, Belt_Option.getWithDefault(Json_decode.field(label, (function (param) {
                        return Json_decode.optional(Json_decode.string, param);
                      }), json), ""));
}

function decodeLastSignInAt(label, json) {
  var lastSignInAt = Json_decode.optional((function (param) {
          return Json_decode.field(label, Json_decode.string, param);
        }), json);
  if (lastSignInAt !== undefined) {
    return Caml_option.some(Moment(new Date(lastSignInAt)));
  }
  
}

function decode(json) {
  return {
          id: Json_decode.field("id", Json_decode.$$int, json),
          email: Curry._1(Domain$ReactHooksTemplate.$$String.Email.create, Json_decode.field("email", Json_decode.string, json)),
          firstName: decodeNameField("first_name", json),
          lastName: decodeNameField("last_name", json),
          username: Json_decode.optional((function (param) {
                  return decodeNameField("username", param);
                }), json),
          active: Json_decode.field("active", Json_decode.bool, json),
          roles: Json_decode.field("user_roles", (function (param) {
                  return Json_decode.list(Role$ReactHooksTemplate.decode, param);
                }), json),
          dataRestrictions: Json_decode.field("data_restrictions", (function (param) {
                  return Json_decode.list(DataRestriction$ReactHooksTemplate.decode, param);
                }), json),
          lastSignInAt: decodeLastSignInAt("last_sign_in_at", json)
        };
}

function showRoles(t) {
  return List.fold_left((function (acc, x) {
                if (acc === "") {
                  return Role$ReactHooksTemplate.name(x);
                } else {
                  return acc + (", " + Role$ReactHooksTemplate.name(x));
                }
              }), "", t.roles);
}

function debug(t) {
  console.log("------------");
  console.log("email     : " + Curry._1(Domain$ReactHooksTemplate.$$String.Email.show, t.email));
  console.log("full name : " + (Curry._1(Domain$ReactHooksTemplate.$$String.Name.show, t.firstName) + (" " + Curry._1(Domain$ReactHooksTemplate.$$String.Name.show, t.lastName))));
  console.log("active : " + Pervasives.string_of_bool(t.active));
  console.log("------------");
  
}

exports.id = id;
exports.email = email;
exports.firstName = firstName;
exports.lastName = lastName;
exports.username = username;
exports.active = active;
exports.roles = roles;
exports.dataRestrictions = dataRestrictions;
exports.lastSignInAt = lastSignInAt;
exports.decodeNameField = decodeNameField;
exports.decodeLastSignInAt = decodeLastSignInAt;
exports.decode = decode;
exports.showRoles = showRoles;
exports.debug = debug;
/* moment Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Utils$ReactHooksTemplate = require("../common/utils.bs.js");
var Welcome$ReactHooksTemplate = require("./Welcome.bs.js");
var SurveyLanguage$ReactHooksTemplate = require("./surveyLanguage.bs.js");

function WelcomePreview(Props) {
  var $$default = Props.default;
  var welcome = Props.welcome;
  var buttonBgColor = Props.buttonBgColor;
  var language = Props.language;
  var onWelcomeChange = Props.onWelcomeChange;
  var withDefault = function ($$default, x) {
    if (x.length === 0) {
      return $$default;
    } else {
      return x;
    }
  };
  return React.createElement("div", {
              className: "flex flex-col w-full"
            }, React.createElement("div", {
                  className: "flex flex-col p-16"
                }, React.createElement("div", {
                      className: "mb-8"
                    }, React.createElement("div", {
                          className: "text-aurometalsaurus text-sm mb-2 font-normal"
                        }, "Title"), React.createElement("input", {
                          className: "h-48-px py-2 px-4 bg-alicebluepurple border-b border-gray-500 focus:border-gray-600 text-xs w-full",
                          placeholder: "Enter Title",
                          type: "text",
                          value: Welcome$ReactHooksTemplate.title(welcome),
                          onChange: (function ($$event) {
                              return Curry._1(onWelcomeChange, Welcome$ReactHooksTemplate.setTitle(welcome, Utils$ReactHooksTemplate.formTargetValue($$event)));
                            })
                        })), React.createElement("div", {
                      className: "mb-8"
                    }, React.createElement("div", {
                          className: "text-aurometalsaurus text-sm mb-2 font-normal"
                        }, "Description"), React.createElement("input", {
                          className: "h-48-px py-2 px-4 bg-alicebluepurple border-b border-gray-500 focus:border-gray-600 text-xs w-full",
                          placeholder: "Enter Title",
                          type: "text",
                          value: Welcome$ReactHooksTemplate.description(welcome),
                          onChange: (function ($$event) {
                              return Curry._1(onWelcomeChange, Welcome$ReactHooksTemplate.setDescription(welcome, Utils$ReactHooksTemplate.formTargetValue($$event)));
                            })
                        })), React.createElement("button", {
                      className: "max-w-30% h-48-px small:h-40-px rounded-sm text-white\n        text-base leading-4 font-semibold text-center",
                      style: {
                        backgroundColor: buttonBgColor === "" ? "rgb(43, 97, 171)" : buttonBgColor
                      }
                    }, withDefault(Welcome$ReactHooksTemplate.button($$default), Welcome$ReactHooksTemplate.getButtonTranslation(welcome, SurveyLanguage$ReactHooksTemplate.name(language))))));
}

var make = WelcomePreview;

exports.make = make;
/* react Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var $$Array = require("rescript/lib/js/array.js");
var React = require("react");
var Js_json = require("rescript/lib/js/js_json.js");
var Recharts = require("recharts");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var AxisData$ReactHooksTemplate = require("./axisData.bs.js");
var ChartComponentColor$ReactHooksTemplate = require("./chartComponentColor.bs.js");
var GenericChartAttribute$ReactHooksTemplate = require("./genericChartAttribute.bs.js");

function PieChartComponent(Props) {
  var chartAttribute = Props.chartAttribute;
  var chartHeight = Props.chartHeight;
  var renderLegend = function (value, param) {
    return React.createElement("span", {
                className: "text-xs"
              }, value);
  };
  return React.createElement("div", {
              className: " w-full " + chartHeight + " p-4 "
            }, React.createElement(Recharts.ResponsiveContainer, {
                  children: React.createElement(Recharts.PieChart, {
                        width: 100,
                        height: 100,
                        children: null
                      }, $$Array.of_list(Belt_List.mapWithIndex(GenericChartAttribute$ReactHooksTemplate.yAxis(chartAttribute), (function (i, xs) {
                                  var json = GenericChartAttribute$ReactHooksTemplate.data(chartAttribute);
                                  return React.createElement(Recharts.Pie, {
                                              data: GenericChartAttribute$ReactHooksTemplate.data(chartAttribute),
                                              innerRadius: "40%",
                                              fill: "red",
                                              dataKey: AxisData$ReactHooksTemplate.key(xs),
                                              nameKey: AxisData$ReactHooksTemplate.key(GenericChartAttribute$ReactHooksTemplate.xAxis(chartAttribute)),
                                              children: null,
                                              key: String(i)
                                            }, React.createElement(Recharts.LabelList, {
                                                  dataKey: AxisData$ReactHooksTemplate.key(xs),
                                                  position: "top",
                                                  fill: "black",
                                                  fontSize: 12
                                                }), json !== undefined ? Belt_Array.mapWithIndex(Belt_Option.getWithDefault(Js_json.decodeArray(Caml_option.valFromOption(json)), []), (function (i, param) {
                                                      return React.createElement(Recharts.Cell, {
                                                                  key: "cell-" + String(i),
                                                                  fill: ChartComponentColor$ReactHooksTemplate.getColorById(i, GenericChartAttribute$ReactHooksTemplate.colors(chartAttribute))
                                                                });
                                                    })) : React.createElement(React.Fragment, undefined));
                                }))), React.createElement(Recharts.Tooltip, {
                            contentStyle: {
                              fontSize: "10pt"
                            }
                          }), React.createElement(Recharts.Legend, {
                            formatter: renderLegend
                          }))
                }));
}

var make = PieChartComponent;

exports.make = make;
/* react Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var List = require("rescript/lib/js/list.js");
var $$Array = require("rescript/lib/js/array.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");
var ListSelected$ReactHooksTemplate = require("./listSelected.bs.js");

function getList(t) {
  return t.options;
}

function fromJson(json) {
  return {
          options: Json_decode.withDefault(/* [] */0, (function (param) {
                  return Json_decode.field("options", (function (param) {
                                return Json_decode.list(Json_decode.string, param);
                              }), param);
                }), json),
          selected: Json_decode.withDefault(/* [] */0, (function (param) {
                  return Json_decode.field("selected", ListSelected$ReactHooksTemplate.decode, param);
                }), json)
        };
}

function selected(t) {
  return t.selected;
}

function isSelected(t) {
  return Belt_List.length(t.selected) > 0;
}

function setSelected(selected, t) {
  var selectedList = Belt_Option.isNone(Belt_List.head(Belt_List.keep(t.selected, (function (xs) {
                  return xs === selected;
                })))) ? ({
        hd: selected,
        tl: t.selected
      }) : Belt_List.keep(t.selected, (function (xs) {
            return xs !== selected;
          }));
  return {
          options: t.options,
          selected: selectedList
        };
}

function resetSelected(t) {
  return {
          options: t.options,
          selected: /* [] */0
        };
}

function toString(t) {
  console.log(t.selected);
  return $$Array.of_list(ListSelected$ReactHooksTemplate.toString(t.selected)).join(", ");
}

function encodeSelected(selected) {
  return Json_encode.object_({
              hd: [
                "options",
                Json_encode.list((function (prim) {
                        return prim;
                      }), selected)
              ],
              tl: /* [] */0
            });
}

function encodeParams(t) {
  if (List.length(t.selected) > 0) {
    return {
            hd: [
              "options",
              Json_encode.list((function (prim) {
                      return prim;
                    }), t.options)
            ],
            tl: {
              hd: [
                "selected",
                encodeSelected(t.selected)
              ],
              tl: /* [] */0
            }
          };
  } else {
    return {
            hd: [
              "options",
              Json_encode.list((function (prim) {
                      return prim;
                    }), t.options)
            ],
            tl: /* [] */0
          };
  }
}

function encode(t) {
  return Json_encode.object_({
              hd: [
                "category_type",
                "list"
              ],
              tl: {
                hd: [
                  "params",
                  Json_encode.object_(encodeParams(t))
                ],
                tl: /* [] */0
              }
            });
}

exports.setSelected = setSelected;
exports.resetSelected = resetSelected;
exports.getList = getList;
exports.selected = selected;
exports.isSelected = isSelected;
exports.toString = toString;
exports.fromJson = fromJson;
exports.encode = encode;
/* No side effect */

// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var List = require("rescript/lib/js/list.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Caml_exceptions = require("rescript/lib/js/caml_exceptions.js");
var Nps$ReactHooksTemplate = require("./Nps.bs.js");
var Csat$ReactHooksTemplate = require("./csat.bs.js");
var Question$ReactHooksTemplate = require("./Question.bs.js");
var StarRating$ReactHooksTemplate = require("./StarRating.bs.js");
var OpinionScale$ReactHooksTemplate = require("./OpinionScale.bs.js");
var SmileyRating$ReactHooksTemplate = require("./smileyRating.bs.js");

var AnswerNotFound = /* @__PURE__ */Caml_exceptions.create("NumberCondition-ReactHooksTemplate.AnswerNotFound");

function lhs(t) {
  return t.lhs;
}

function operator(t) {
  return t.operator;
}

function rhs(t) {
  return t.rhs;
}

function setLhs(t, lhs) {
  return {
          lhs: lhs,
          operator: t.operator,
          rhs: t.rhs
        };
}

function setOperator(t, operator) {
  return {
          lhs: t.lhs,
          operator: operator,
          rhs: t.rhs
        };
}

function setRhs(t, rhs) {
  return {
          lhs: t.lhs,
          operator: t.operator,
          rhs: rhs
        };
}

function getAnswer(question) {
  var smileyRating = question.questionType;
  switch (smileyRating.TAG | 0) {
    case /* OpinionScale */0 :
        return OpinionScale$ReactHooksTemplate.answerToInt(smileyRating._0);
    case /* StarRating */1 :
        return StarRating$ReactHooksTemplate.answerToInt(smileyRating._0);
    case /* Nps */4 :
        return Nps$ReactHooksTemplate.answerToInt(smileyRating._0);
    case /* SmileyRating */7 :
        return SmileyRating$ReactHooksTemplate.answerToInt(smileyRating._0);
    case /* Csat */8 :
        return Csat$ReactHooksTemplate.answerToInt(smileyRating._0);
    default:
      return ;
  }
}

function humanizeOperator(text) {
  switch (text) {
    case "equals" :
        return "Equals";
    case "greater_than" :
        return "Greater Than";
    case "greater_than_or_equal_to" :
        return "Greater Than Or Equal To";
    case "less_than" :
        return "Less Than";
    case "less_than_or_equal_to" :
        return "Less Than Or Equal To";
    case "not_equals" :
        return "Not Equals";
    default:
      return "";
  }
}

function evaluate(t) {
  if (Question$ReactHooksTemplate.answered(t.lhs)) {
    var x = getAnswer(t.lhs);
    if (x !== undefined) {
      var match = t.operator;
      switch (match) {
        case "equals" :
            return x === t.rhs;
        case "greater_than" :
            return x > t.rhs;
        case "greater_than_or_equal_to" :
            return x >= t.rhs;
        case "less_than" :
            return x < t.rhs;
        case "less_than_or_equal_to" :
            return x <= t.rhs;
        case "not_equals" :
            return x !== t.rhs;
        default:
          return false;
      }
    } else {
      throw {
            RE_EXN_ID: AnswerNotFound,
            _1: "The Answer Not Found",
            Error: new Error()
          };
    }
  } else {
    throw {
          RE_EXN_ID: AnswerNotFound,
          _1: "The Answer Not Found",
          Error: new Error()
        };
  }
}

function availableOperators(param) {
  return {
          hd: "equals",
          tl: {
            hd: "not_equals",
            tl: {
              hd: "greater_than",
              tl: {
                hd: "less_than",
                tl: {
                  hd: "greater_than_or_equal_to",
                  tl: {
                    hd: "less_than_or_equal_to",
                    tl: /* [] */0
                  }
                }
              }
            }
          }
        };
}

function decodeLhs(json, questions) {
  var lhsId = Json_decode.field("lhs_id", Json_decode.$$int, json);
  return List.find((function (n) {
                return Belt_Option.getExn(Question$ReactHooksTemplate.id(n)) === lhsId;
              }), questions);
}

function decode(json, questions) {
  return {
          lhs: decodeLhs(json, questions),
          operator: Json_decode.field("operator", Json_decode.string, json),
          rhs: Json_decode.field("rhs", Json_decode.$$int, json)
        };
}

function answerRange(question) {
  var nps = question.questionType;
  switch (nps.TAG | 0) {
    case /* OpinionScale */0 :
        return OpinionScale$ReactHooksTemplate.answerRange(nps._0);
    case /* StarRating */1 :
        return StarRating$ReactHooksTemplate.answerRange(nps._0);
    case /* Nps */4 :
        return Nps$ReactHooksTemplate.answerRange(nps._0);
    case /* SmileyRating */7 :
        return SmileyRating$ReactHooksTemplate.answerRange(nps._0);
    case /* Csat */8 :
        return Csat$ReactHooksTemplate.answerRange(nps._0);
    default:
      return [
              0,
              0
            ];
  }
}

function encodeRhs(t) {
  return t.rhs;
}

exports.lhs = lhs;
exports.operator = operator;
exports.rhs = rhs;
exports.setLhs = setLhs;
exports.setOperator = setOperator;
exports.setRhs = setRhs;
exports.evaluate = evaluate;
exports.availableOperators = availableOperators;
exports.decode = decode;
exports.answerRange = answerRange;
exports.humanizeOperator = humanizeOperator;
exports.encodeRhs = encodeRhs;
/* Nps-ReactHooksTemplate Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var ReactDraftWysiwyg = require("react-draft-wysiwyg");

function Editor(Props) {
  return React.createElement("div", {
              className: "flex"
            }, React.createElement(ReactDraftWysiwyg.Editor, {
                  wrapperClassName: "w-1/2"
                }), React.createElement("div", {
                  className: "w-1/2"
                }, "Html Preview"));
}

var defaultHtml = "<p> hello </p>";

var make = Editor;

exports.defaultHtml = defaultHtml;
exports.make = make;
/* react Not a pure module */

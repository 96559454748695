// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var List = require("rescript/lib/js/list.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");
var RandomId$ReactHooksTemplate = require("./RandomId.bs.js");

function decodeLabel(json) {
  return {
          rating: Json_decode.field("id", Json_decode.$$int, json),
          label: Json_decode.field("label", Json_decode.string, json)
        };
}

function decode(json) {
  return {
          text: Json_decode.field("text", Json_decode.string, json),
          description: Json_decode.field("description", Json_decode.string, json),
          ratingLabels: Json_decode.field("options", (function (param) {
                  return Json_decode.list(decodeLabel, param);
                }), json)
        };
}

function empty(param) {
  return {
          id: undefined,
          uuid: RandomId$ReactHooksTemplate.generate(undefined),
          text: "",
          ratingLabels: {
            hd: {
              rating: 1,
              label: "Poor"
            },
            tl: {
              hd: {
                rating: 2,
                label: "Fair"
              },
              tl: {
                hd: {
                  rating: 3,
                  label: "Good"
                },
                tl: {
                  hd: {
                    rating: 4,
                    label: "Very Good"
                  },
                  tl: {
                    hd: {
                      rating: 5,
                      label: "Excellent"
                    },
                    tl: /* [] */0
                  }
                }
              }
            }
          },
          description: "",
          translations: /* [] */0,
          negativeToPositive: true,
          answer: undefined
        };
}

function answered(t) {
  return t.answer !== undefined;
}

function id(t) {
  return t.id;
}

function uuid(t) {
  return t.uuid;
}

function text(t) {
  return t.text;
}

function getTextTranslation(t, language) {
  var translation = List.find_opt((function (param) {
          return param[0] === language;
        }), t.translations);
  if (translation !== undefined) {
    return translation[1].text;
  } else {
    return t.text;
  }
}

function description(t) {
  return t.description;
}

function getDescriptionTranslation(t, language) {
  var translation = List.find_opt((function (param) {
          return param[0] === language;
        }), t.translations);
  if (translation !== undefined) {
    return translation[1].description;
  } else {
    return t.description;
  }
}

function negativeToPositive(t) {
  return t.negativeToPositive;
}

function toggleNegativeToPositive(t) {
  return {
          id: t.id,
          uuid: t.uuid,
          text: t.text,
          ratingLabels: t.ratingLabels,
          description: t.description,
          translations: t.translations,
          negativeToPositive: !t.negativeToPositive,
          answer: t.answer
        };
}

function answerTypeToUnicode(x) {
  switch (x) {
    case /* One */0 :
        return "😧";
    case /* Two */1 :
        return "🙁";
    case /* Three */2 :
        return "😐";
    case /* Four */3 :
        return "🙂";
    case /* Five */4 :
        return "😃";
    case /* NotSelected */5 :
        return "";
    
  }
}

function answerTypeToRating(x) {
  switch (x) {
    case /* One */0 :
        return 1;
    case /* Two */1 :
        return 2;
    case /* Three */2 :
        return 3;
    case /* Four */3 :
        return 4;
    case /* Five */4 :
        return 5;
    case /* NotSelected */5 :
        return -1;
    
  }
}

function answerTypeCollection(t) {
  if (t.negativeToPositive) {
    return Belt_List.reverse({
                hd: /* Five */4,
                tl: {
                  hd: /* Four */3,
                  tl: {
                    hd: /* Three */2,
                    tl: {
                      hd: /* Two */1,
                      tl: {
                        hd: /* One */0,
                        tl: /* [] */0
                      }
                    }
                  }
                }
              });
  } else {
    return {
            hd: /* Five */4,
            tl: {
              hd: /* Four */3,
              tl: {
                hd: /* Three */2,
                tl: {
                  hd: /* Two */1,
                  tl: {
                    hd: /* One */0,
                    tl: /* [] */0
                  }
                }
              }
            }
          };
  }
}

function answerTypeToString(t, answerType, language) {
  var translation = List.find_opt((function (param) {
          return param[0] === language;
        }), t.translations);
  var rating = answerTypeToRating(answerType);
  var ratingLabels = translation !== undefined ? translation[1].ratingLabels : t.ratingLabels;
  if (rating !== -1) {
    return List.find((function (x) {
                  return x.rating === rating;
                }), ratingLabels).label;
  } else {
    return "";
  }
}

function ratingToAnswerType(x) {
  if (x > 5 || x < 1) {
    return /* NotSelected */5;
  } else {
    return x - 1 | 0;
  }
}

function answerToString(t) {
  var answer = t.answer;
  if (answer !== undefined) {
    return answerTypeToString(t, answer, "");
  } else {
    return answerTypeToString(t, /* NotSelected */5, "");
  }
}

function answerToInt(t) {
  var answer = t.answer;
  if (answer !== undefined) {
    return answerTypeToRating(answer);
  }
  
}

function setText(t, text) {
  return {
          id: t.id,
          uuid: t.uuid,
          text: text,
          ratingLabels: t.ratingLabels,
          description: t.description,
          translations: t.translations,
          negativeToPositive: t.negativeToPositive,
          answer: t.answer
        };
}

function setDescription(t, description) {
  return {
          id: t.id,
          uuid: t.uuid,
          text: t.text,
          ratingLabels: t.ratingLabels,
          description: description,
          translations: t.translations,
          negativeToPositive: t.negativeToPositive,
          answer: t.answer
        };
}

function setAnswer(t, answer) {
  return {
          id: t.id,
          uuid: t.uuid,
          text: t.text,
          ratingLabels: t.ratingLabels,
          description: t.description,
          translations: t.translations,
          negativeToPositive: t.negativeToPositive,
          answer: answer
        };
}

function getAnswer(t) {
  return t.answer;
}

function answerRange(t) {
  return [
          1,
          5
        ];
}

function encodeAnswerTypeCollection(ratingLabel) {
  return Json_encode.object_({
              hd: [
                "id",
                ratingLabel.rating
              ],
              tl: {
                hd: [
                  "label",
                  ratingLabel.label
                ],
                tl: /* [] */0
              }
            });
}

function toJson(withAnswerOpt, t) {
  var withAnswer = withAnswerOpt !== undefined ? withAnswerOpt : false;
  var encodeArray_0 = [
    "id",
    Json_encode.nullable((function (prim) {
            return prim;
          }), t.id)
  ];
  var encodeArray_1 = {
    hd: [
      "text",
      t.text
    ],
    tl: {
      hd: [
        "description",
        t.description
      ],
      tl: {
        hd: [
          "negative_to_positive",
          t.negativeToPositive
        ],
        tl: {
          hd: [
            "options",
            Json_encode.list(encodeAnswerTypeCollection, t.ratingLabels)
          ],
          tl: /* [] */0
        }
      }
    }
  };
  var encodeArray = {
    hd: encodeArray_0,
    tl: encodeArray_1
  };
  return Json_encode.object_(withAnswer ? ({
                  hd: [
                    "answer",
                    answerTypeToRating(Belt_Option.getWithDefault(t.answer, /* NotSelected */5))
                  ],
                  tl: encodeArray
                }) : encodeArray);
}

function decodeTranslation(json) {
  return [
          Json_decode.field("survey_language", (function (param) {
                  return Json_decode.field("name", Json_decode.string, param);
                }), json),
          Json_decode.field("key_string_mapping", decode, json)
        ];
}

function decodeLabel$1(json) {
  return {
          rating: Json_decode.field("id", Json_decode.$$int, json),
          label: Json_decode.field("label", Json_decode.string, json)
        };
}

function fromJson(json) {
  return {
          id: Json_decode.field("category", (function (param) {
                  return Json_decode.field("id", (function (param) {
                                return Json_decode.optional(Json_decode.$$int, param);
                              }), param);
                }), json),
          uuid: Json_decode.field("uid", RandomId$ReactHooksTemplate.fromJson, json),
          text: Json_decode.field("category", (function (param) {
                  return Json_decode.field("text", Json_decode.string, param);
                }), json),
          ratingLabels: Json_decode.field("category", (function (param) {
                  return Json_decode.field("options", (function (param) {
                                return Json_decode.list(decodeLabel$1, param);
                              }), param);
                }), json),
          description: Json_decode.field("category", (function (param) {
                  return Json_decode.field("description", Json_decode.string, param);
                }), json),
          translations: Json_decode.field("translations", (function (param) {
                  return Json_decode.list(decodeTranslation, param);
                }), json),
          negativeToPositive: Json_decode.field("category", (function (param) {
                  return Json_decode.field("negative_to_positive", Json_decode.bool, param);
                }), json),
          answer: undefined
        };
}

var categoryType = "Csat";

exports.text = text;
exports.getTextTranslation = getTextTranslation;
exports.description = description;
exports.negativeToPositive = negativeToPositive;
exports.toggleNegativeToPositive = toggleNegativeToPositive;
exports.getDescriptionTranslation = getDescriptionTranslation;
exports.ratingToAnswerType = ratingToAnswerType;
exports.answerTypeToUnicode = answerTypeToUnicode;
exports.answerTypeCollection = answerTypeCollection;
exports.answerTypeToString = answerTypeToString;
exports.setText = setText;
exports.setDescription = setDescription;
exports.categoryType = categoryType;
exports.empty = empty;
exports.id = id;
exports.uuid = uuid;
exports.toJson = toJson;
exports.setAnswer = setAnswer;
exports.getAnswer = getAnswer;
exports.answered = answered;
exports.fromJson = fromJson;
exports.answerToString = answerToString;
exports.answerRange = answerRange;
exports.answerToInt = answerToInt;
/* RandomId-ReactHooksTemplate Not a pure module */

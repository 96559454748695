// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var UserDetail$ReactHooksTemplate = require("../common/UserDetail.bs.js");
var ReportTemplate$ReactHooksTemplate = require("./ReportTemplate.bs.js");

function empty(param) {
  return {
          id: undefined,
          name: "",
          description: "",
          reportTemplate: undefined,
          timeFrame: "",
          creator: undefined,
          modifier: undefined
        };
}

function id(t) {
  return t.id;
}

function name(t) {
  return t.name;
}

function description(t) {
  return t.description;
}

function reportTemplate(t) {
  return t.reportTemplate;
}

function timeFrame(t) {
  return t.timeFrame;
}

function creator(t) {
  return t.creator;
}

function modifier(t) {
  return t.modifier;
}

function setName(t, name) {
  return {
          id: t.id,
          name: name,
          description: t.description,
          reportTemplate: t.reportTemplate,
          timeFrame: t.timeFrame,
          creator: t.creator,
          modifier: t.modifier
        };
}

function decode(json) {
  return {
          id: Json_decode.field("id", (function (param) {
                  return Json_decode.optional(Json_decode.$$int, param);
                }), json),
          name: Json_decode.field("name", Json_decode.string, json),
          description: Json_decode.field("description", Json_decode.string, json),
          reportTemplate: Json_decode.field("report_template", ReportTemplate$ReactHooksTemplate.decode, json),
          timeFrame: Json_decode.field("time_frame", Json_decode.string, json),
          creator: Json_decode.field("creator", (function (param) {
                  return Json_decode.optional(UserDetail$ReactHooksTemplate.decode, param);
                }), json),
          modifier: Json_decode.field("modifier", (function (param) {
                  return Json_decode.optional(UserDetail$ReactHooksTemplate.decode, param);
                }), json)
        };
}

exports.empty = empty;
exports.id = id;
exports.name = name;
exports.description = description;
exports.reportTemplate = reportTemplate;
exports.timeFrame = timeFrame;
exports.creator = creator;
exports.modifier = modifier;
exports.setName = setName;
exports.decode = decode;
/* No side effect */

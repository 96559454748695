// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");
var FormField$ReactHooksTemplate = require("../common/FormField.bs.js");

function empty(param) {
  return {
          senderId: 0
        };
}

function make(senderId) {
  return senderId;
}

function setSenderId(x) {
  return {
          senderId: x
        };
}

function senderId(t) {
  return t.senderId;
}

function validSenderId(x) {
  return x !== 0;
}

function toForm(t) {
  return {
          senderId: t.senderId !== 0 ? ({
                TAG: /* Valid */0,
                _0: t.senderId
              }) : ({
                TAG: /* Invalid */1,
                _0: t.senderId,
                _1: {
                  hd: "Sender Id Can't be Empty",
                  tl: /* [] */0
                }
              })
        };
}

function fromForm(form) {
  return {
          senderId: FormField$ReactHooksTemplate.getInputValue(form.senderId)
        };
}

function encoder(t) {
  return Json_encode.object_({
              hd: [
                "sender_id",
                t.senderId
              ],
              tl: /* [] */0
            });
}

function decoder(json) {
  return {
          senderId: Json_decode.field("sender_id", Json_decode.$$int, json)
        };
}

var modeType = "Sms";

exports.modeType = modeType;
exports.empty = empty;
exports.make = make;
exports.setSenderId = setSenderId;
exports.senderId = senderId;
exports.validSenderId = validSenderId;
exports.toForm = toForm;
exports.fromForm = fromForm;
exports.encoder = encoder;
exports.decoder = decoder;
/* No side effect */

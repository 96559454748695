// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var List = require("rescript/lib/js/list.js");
var $$Array = require("rescript/lib/js/array.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var $$String = require("rescript/lib/js/string.js");
var Js_dict = require("rescript/lib/js/js_dict.js");
var Belt_Int = require("rescript/lib/js/belt_Int.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Caml_array = require("rescript/lib/js/caml_array.js");
var Caml_int32 = require("rescript/lib/js/caml_int32.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_format = require("rescript/lib/js/caml_format.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Querystringify = require("querystringify");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var Http$ReactHooksTemplate = require("./common/http.bs.js");
var Image$ReactHooksTemplate = require("./common/image.bs.js");
var Filters$ReactHooksTemplate = require("./dashboard/filters/filters.bs.js");
var Loading$ReactHooksTemplate = require("./dashboard/dashboard/Loading.bs.js");
var Pagination$ReactHooksTemplate = require("./common/Pagination.bs.js");

function emptyState(param) {
  return {
          header: /* [] */0,
          contents: /* [] */0
        };
}

function noMessage(param) {
  return "";
}

function decodeDownloadReport(json) {
  return Json_decode.field("file_name", Json_decode.string, json);
}

function decodeCustomerDetail(header, json) {
  return List.map((function (name) {
                return Json_decode.optional((function (param) {
                              return Json_decode.field(name, Json_decode.string, param);
                            }), json);
              }), header);
}

function decodeResponses(header, json) {
  return List.map((function (name) {
                return Json_decode.optional((function (param) {
                              return Json_decode.field(name, Json_decode.string, param);
                            }), json);
              }), header);
}

function decodeContent(header, json) {
  var responseHeaders = Json_decode.field("response_headers", (function (param) {
          return Json_decode.list(Json_decode.string, param);
        }), json);
  return {
          customerDetails: Json_decode.field("customer_details", (function (param) {
                  return decodeCustomerDetail(header, param);
                }), json),
          responseHeaders: responseHeaders,
          responses: Json_decode.field("responses", (function (param) {
                  return decodeResponses(responseHeaders, param);
                }), json)
        };
}

function decode(json) {
  var header = Json_decode.field("header", (function (param) {
          return Json_decode.list(Json_decode.string, param);
        }), json);
  return {
          header: header,
          contents: Json_decode.field("report", (function (param) {
                  return Json_decode.list((function (param) {
                                return decodeContent(header, param);
                              }), param);
                }), json)
        };
}

var Report = {
  emptyState: emptyState,
  noMessage: noMessage,
  decodeDownloadReport: decodeDownloadReport,
  decodeCustomerDetail: decodeCustomerDetail,
  decodeResponses: decodeResponses,
  decodeContent: decodeContent,
  decode: decode
};

var emptyReports = {
  header: /* [] */0,
  contents: /* [] */0
};

var initialState = {
  loading: true,
  reports: emptyReports,
  toggleFilter: false,
  selectedRow: undefined
};

function reducer(state, x) {
  if (typeof x === "number") {
    if (x === /* FetchReportsInProgress */0) {
      return {
              loading: true,
              reports: state.reports,
              toggleFilter: state.toggleFilter,
              selectedRow: state.selectedRow
            };
    } else {
      return {
              loading: state.loading,
              reports: state.reports,
              toggleFilter: !state.toggleFilter,
              selectedRow: state.selectedRow
            };
    }
  } else if (x.TAG === /* FetchReportsCompleted */0) {
    return {
            loading: false,
            reports: x._0,
            toggleFilter: state.toggleFilter,
            selectedRow: state.selectedRow
          };
  } else {
    return {
            loading: state.loading,
            reports: state.reports,
            toggleFilter: state.toggleFilter,
            selectedRow: x._0
          };
  }
}

function totalRecord(x) {
  if (x !== undefined) {
    return x;
  } else {
    return "1";
  }
}

function perPage(x) {
  if (x !== undefined) {
    return x;
  } else {
    return "1";
  }
}

function totalPages(total_records, perPage) {
  return Caml_int32.div(total_records - 1 | 0, perPage) + 1 | 0;
}

var emptyPageInfo = {
  total: "1",
  perPage: "1"
};

function Responses(Props) {
  var params = Props.params;
  var match = React.useReducer(reducer, initialState);
  var dispatch = match[1];
  var state = match[0];
  var match$1 = React.useState(function () {
        return emptyPageInfo;
      });
  var setPageInfo = match$1[1];
  var pageInfo = match$1[0];
  var queryParams = Querystringify.parse(params);
  var visualizationId = Js_dict.get(queryParams, "visualization_id");
  var word = Js_dict.get(queryParams, "keyword");
  var selectedRow = function (i) {
    var x = state.selectedRow;
    if (x !== undefined) {
      return x === i;
    } else {
      return false;
    }
  };
  var x = Js_dict.get(queryParams, "page");
  var currentPage;
  if (x !== undefined && x !== "") {
    var page = Belt_Int.fromString(x);
    currentPage = page !== undefined ? page : 1;
  } else {
    currentPage = 1;
  }
  var url = "/api/v1/responses?visualization_id=" + visualizationId + "&word=" + word + "&page=" + String(currentPage);
  var handlePageChange = function (pageNumber) {
    var pageNo = String(pageNumber);
    return RescriptReactRouter.push("/responses?visualization_id=" + visualizationId + "&word=" + word + "&page=" + pageNo);
  };
  var fetchReport = function (param) {
    return Http$ReactHooksTemplate.fetchGetJSONWithHeader(url).then(function (param) {
                var headers = param[0];
                var x = headers.get("Total");
                var total = x !== null ? (
                    x === null ? undefined : Caml_option.some(x)
                  ) : "1";
                var x$1 = headers.get("Per-Page");
                var perPage = x$1 !== null ? (
                    x$1 === null ? undefined : Caml_option.some(x$1)
                  ) : "1";
                var report = param[1].then(function (json) {
                      return Promise.resolve(Json_decode.field("report", decode, json));
                    });
                return Promise.resolve([
                            report,
                            [
                              total,
                              perPage
                            ]
                          ]);
              });
  };
  var onApplyFilter = function (param) {
    Curry._1(dispatch, /* ToggleFilter */1);
    Curry._1(dispatch, /* FetchReportsInProgress */0);
    fetchReport(undefined).then(function (param) {
          var pageInfo = param[1];
          var perPage = pageInfo[1];
          var total = pageInfo[0];
          Curry._1(setPageInfo, (function (param) {
                  return {
                          total: total,
                          perPage: perPage
                        };
                }));
          param[0].then(function (report) {
                return Promise.resolve(Curry._1(dispatch, {
                                TAG: /* FetchReportsCompleted */0,
                                _0: report
                              }));
              });
          return Promise.resolve(undefined);
        });
    
  };
  var toggleFilter = function (param) {
    return Curry._1(dispatch, /* ToggleFilter */1);
  };
  React.useEffect((function () {
          Curry._1(dispatch, /* FetchReportsInProgress */0);
          fetchReport(undefined).then(function (param) {
                var pageInfo = param[1];
                var perPage = pageInfo[1];
                var total = pageInfo[0];
                Curry._1(setPageInfo, (function (param) {
                        return {
                                total: total,
                                perPage: perPage
                              };
                      }));
                param[0].then(function (report) {
                      return Promise.resolve(Curry._1(dispatch, {
                                      TAG: /* FetchReportsCompleted */0,
                                      _0: report
                                    }));
                    });
                return Promise.resolve(undefined);
              });
          
        }), [currentPage]);
  return React.createElement(React.Fragment, undefined, state.toggleFilter ? React.createElement(Filters$ReactHooksTemplate.make, {
                    onClose: toggleFilter,
                    onApply: onApplyFilter,
                    showDateFilter: false,
                    showFilterPills: false
                  }) : React.createElement(React.Fragment, undefined), state.loading ? React.createElement("div", {
                    className: "h-screen w-full animated fadeIn"
                  }, React.createElement(Loading$ReactHooksTemplate.make, {
                        text: "Loading..."
                      })) : React.createElement("div", undefined, React.createElement("div", {
                        className: "flex justify-between items-center p-20-px"
                      }, React.createElement("div", {
                            className: "flex"
                          }, React.createElement("h2", {
                                className: "text-xl font-semibold pt-6"
                              }, "Transactions"), React.createElement(Pagination$ReactHooksTemplate.make, {
                                total: totalPages(Caml_format.caml_int_of_string(pageInfo.total), Caml_format.caml_int_of_string(pageInfo.perPage)),
                                currentPage: currentPage,
                                handlePageChange: handlePageChange
                              })), React.createElement("div", {
                            className: "flex gap-4"
                          }, React.createElement("button", {
                                className: "bg-ghostwhite border border-gray-400\n                shadow-inner flex rounded py-2 px-3 text-xs",
                                id: "filters",
                                onClick: (function (param) {
                                    return Curry._1(dispatch, /* ToggleFilter */1);
                                  })
                              }, React.createElement("img", {
                                    className: "h-14-px mr-2-px",
                                    src: Image$ReactHooksTemplate.filterButton
                                  }), "Filters"), React.createElement("a", {
                                className: "bg-pigmentblue border border-gray-400\n                  flex rounded py-2 px-3 text-xs text-white",
                                href: "/api/v1/responses/download?word=" + word + "&visualization_id=" + visualizationId
                              }, React.createElement("img", {
                                    className: "h-14-px mr-1 filter-white",
                                    src: Image$ReactHooksTemplate.download
                                  }), "Download"))), React.createElement("div", {
                        className: "flex px-20-px"
                      }, React.createElement("table", {
                            className: "text-left table-auto w-full border-separate text-xs font-medium"
                          }, React.createElement("thead", {
                                className: "font-medium p-4 bg-ghostwhite text-aurometalsaurus"
                              }, React.createElement("tr", undefined, $$Array.of_list(List.mapi((function (i, header) {
                                              return React.createElement("th", {
                                                          key: String(i),
                                                          className: "px-4 py-2"
                                                        }, React.createElement("div", {
                                                              className: "flex"
                                                            }, $$String.capitalize_ascii(header), React.createElement("img", {
                                                                  className: "ml-4-px",
                                                                  src: Image$ReactHooksTemplate.heading
                                                                })));
                                            }), state.reports.header)), React.createElement("th", {
                                        className: "w-10"
                                      }))), React.createElement("tbody", undefined, $$Array.of_list(List.mapi((function (i, x) {
                                          return React.createElement(React.Fragment, undefined, React.createElement("tr", {
                                                          key: String(i),
                                                          className: "border-b border-bottom-gray-600 " + (
                                                            selectedRow(i) ? "bg-alicebluesolid" : ""
                                                          ),
                                                          onClick: (function (param) {
                                                              if (selectedRow(i)) {
                                                                return Curry._1(dispatch, {
                                                                            TAG: /* SelectRow */1,
                                                                            _0: undefined
                                                                          });
                                                              } else {
                                                                return Curry._1(dispatch, {
                                                                            TAG: /* SelectRow */1,
                                                                            _0: i
                                                                          });
                                                              }
                                                            })
                                                        }, $$Array.of_list(List.mapi((function (k, step) {
                                                                    if (step !== undefined) {
                                                                      return React.createElement("td", {
                                                                                  key: String(k),
                                                                                  className: "px-4 py-2"
                                                                                }, step);
                                                                    } else {
                                                                      return React.createElement("td", {
                                                                                  key: String(k),
                                                                                  className: "px-4 py-2"
                                                                                }, "");
                                                                    }
                                                                  }), x.customerDetails)), React.createElement("td", {
                                                              className: "px-4 py-2"
                                                            }, selectedRow(i) ? React.createElement("img", {
                                                                    className: "h-7-px",
                                                                    src: Image$ReactHooksTemplate.chevronUp
                                                                  }) : React.createElement("img", {
                                                                    className: "h-7-px filter-gray-400",
                                                                    src: Image$ReactHooksTemplate.chevronDown
                                                                  }))), selectedRow(i) ? React.createElement("tr", {
                                                            className: "border-b border-gray-200 w-full"
                                                          }, React.createElement("td", {
                                                                className: "px-20 py-10",
                                                                colSpan: 8
                                                              }, React.createElement("table", {
                                                                    className: "text-left table-auto w-full border-separate text-xs font-medium"
                                                                  }, React.createElement("thead", {
                                                                        className: "font-medium p-4 bg-ghostwhite text-aurometalsaurus"
                                                                      }, React.createElement("th", {
                                                                            className: "w-1/3 px-4 py-2"
                                                                          }, React.createElement("p", undefined, "Question")), React.createElement("th", {
                                                                            className: "w-2/3 px-4 py-2"
                                                                          }, React.createElement("p", undefined, "Answer"))), React.createElement("tbody", {
                                                                        className: "border-b border-bottom-gray-600"
                                                                      }, $$Array.of_list(List.mapi((function (i, h) {
                                                                                  return React.createElement("tr", {
                                                                                              className: "border-b border-gray-200 w-full"
                                                                                            }, React.createElement("td", {
                                                                                                  key: String(i),
                                                                                                  className: "w-1/3 px-4 py-2"
                                                                                                }, React.createElement("p", undefined, h)), React.createElement("td", {
                                                                                                  className: "w-2/3 px-4 py-2"
                                                                                                }, React.createElement("p", undefined, Belt_Option.getWithDefault(Caml_array.get(Belt_List.toArray(x.responses), i), ""))));
                                                                                }), x.responseHeaders)))))) : React.createElement(React.Fragment, undefined));
                                        }), state.reports.contents)))))));
}

var noMessage$1 = "";

var make = Responses;

exports.Report = Report;
exports.emptyReports = emptyReports;
exports.initialState = initialState;
exports.reducer = reducer;
exports.totalRecord = totalRecord;
exports.perPage = perPage;
exports.totalPages = totalPages;
exports.emptyPageInfo = emptyPageInfo;
exports.noMessage = noMessage$1;
exports.make = make;
/* react Not a pure module */

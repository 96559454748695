// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var MomentRe = require("bs-moment/src/MomentRe.bs.js");
var Pervasives = require("rescript/lib/js/pervasives.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");
var Utils$ReactHooksTemplate = require("../../common/utils.bs.js");

function make(from, to_) {
  return {
          from: from,
          to_: to_
        };
}

function from(t) {
  return t.from;
}

function to_(t) {
  return t.to_;
}

function getMomentString(date) {
  if (date !== undefined) {
    return Caml_option.valFromOption(date).format("DD/MM/YYYY");
  } else {
    return "";
  }
}

function toString(t) {
  return "from: " + (getMomentString(t.from) + (" to: " + getMomentString(t.to_)));
}

function encode(t) {
  return Json_encode.object_(Pervasives.$at(Utils$ReactHooksTemplate.encodeOptionalMomentDateParamToList("start_date", t.from), Utils$ReactHooksTemplate.encodeOptionalMomentDateParamToList("end_date", t.to_)));
}

function convertDateToMoment(date) {
  if (date !== undefined) {
    return Caml_option.some(MomentRe.moment(undefined, date));
  }
  
}

function decode(json) {
  return {
          from: convertDateToMoment(Json_decode.withDefault(undefined, (function (param) {
                      return Json_decode.field("start_date", (function (param) {
                                    return Json_decode.optional(Json_decode.string, param);
                                  }), param);
                    }), json)),
          to_: convertDateToMoment(Json_decode.withDefault(undefined, (function (param) {
                      return Json_decode.field("end_date", (function (param) {
                                    return Json_decode.optional(Json_decode.string, param);
                                  }), param);
                    }), json))
        };
}

exports.make = make;
exports.getMomentString = getMomentString;
exports.toString = toString;
exports.encode = encode;
exports.decode = decode;
exports.from = from;
exports.to_ = to_;
/* MomentRe Not a pure module */

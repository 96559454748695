// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var TicketPark$ReactHooksTemplate = require("./ticketPark.bs.js");

function TicketParkView(Props) {
  var activity = Props.activity;
  return React.createElement("div", undefined, React.createElement("div", {
                  className: "flex justify-between text-darkcharcoal"
                }, React.createElement("div", {
                      className: "text-sm font-semibold"
                    }, "Ticket Parked"), React.createElement("div", {
                      className: "text-xs font-normal"
                    }, TicketPark$ReactHooksTemplate.parkedBy(activity))), React.createElement("div", {
                  className: "flex text-xs font-normal mt-2"
                }, "Parked Till: ", TicketPark$ReactHooksTemplate.parkedTill(activity).format("lll")), React.createElement("div", {
                  className: "mt-2 text-xs font-normal"
                }, TicketPark$ReactHooksTemplate.comment(activity)));
}

var make = TicketParkView;

exports.make = make;
/* react Not a pure module */

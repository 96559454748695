// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Caml_format = require("rescript/lib/js/caml_format.js");
var Es6Promise = require("es6-promise");
var IsomorphicFetch = require("isomorphic-fetch").default;
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var Http$ReactHooksTemplate = require("./common/http.bs.js");
var Editor$ReactHooksTemplate = require("./surveys/WysiwygEditor/Editor.bs.js");
var Header$ReactHooksTemplate = require("./common/Header.bs.js");
var SignIn$ReactHooksTemplate = require("./SignIn.bs.js");
var SignUp$ReactHooksTemplate = require("./SignUp.bs.js");
var Tickets$ReactHooksTemplate = require("./tickets/Tickets.bs.js");
var Insights$ReactHooksTemplate = require("./dashboard/dashboard/Insights.bs.js");
var NpsTrend$ReactHooksTemplate = require("./surveys/NpsTrend.bs.js");
var Projects$ReactHooksTemplate = require("./surveys/Projects.bs.js");
var RoleEdit$ReactHooksTemplate = require("./settings/RoleEdit.bs.js");
var RoleList$ReactHooksTemplate = require("./settings/RoleList.bs.js");
var UserEdit$ReactHooksTemplate = require("./settings/UserEdit.bs.js");
var UserForm$ReactHooksTemplate = require("./settings/UserForm.bs.js");
var UserList$ReactHooksTemplate = require("./settings/UserList.bs.js");
var AdminView$ReactHooksTemplate = require("./AdminView.bs.js");
var Campaigns$ReactHooksTemplate = require("./surveys/Campaigns.bs.js");
var Responses$ReactHooksTemplate = require("./Responses.bs.js");
var Dashboards$ReactHooksTemplate = require("./dashboard/dashboard/Dashboards.bs.js");
var ReportList$ReactHooksTemplate = require("./reports/ReportList.bs.js");
var ReportView$ReactHooksTemplate = require("./reports/ReportView.bs.js");
var RoleCreate$ReactHooksTemplate = require("./settings/RoleCreate.bs.js");
var SurveyEdit$ReactHooksTemplate = require("./surveys/SurveyEdit.bs.js");
var SurveyPage$ReactHooksTemplate = require("./surveys/SurveyPage.bs.js");
var DailyReport$ReactHooksTemplate = require("./DailyReport.bs.js");
var AuthProvider$ReactHooksTemplate = require("./AuthProvider.bs.js");
var CampaignList$ReactHooksTemplate = require("./surveys/CampaignList.bs.js");
var CategoryEdit$ReactHooksTemplate = require("./CategoryEdit.bs.js");
var DownloadList$ReactHooksTemplate = require("./tickets/DownloadList.bs.js");
var ReleaseFlags$ReactHooksTemplate = require("./ReleaseFlags.bs.js");
var TicketWorker$ReactHooksTemplate = require("./tickets/TicketWorker.bs.js");
var InsightsIndex$ReactHooksTemplate = require("./dashboard/dashboard/InsightsIndex.bs.js");
var SurveyExpired$ReactHooksTemplate = require("./surveys/SurveyExpired.bs.js");
var TicketManager$ReactHooksTemplate = require("./tickets/TicketManager.bs.js");
var UserKpiCreate$ReactHooksTemplate = require("./settings/UserKpiCreate.bs.js");
var CampaignReport$ReactHooksTemplate = require("./surveys/CampaignReport.bs.js");
var PermissionTree$ReactHooksTemplate = require("./settings/permissionTree.bs.js");
var DataFieldReport$ReactHooksTemplate = require("./surveys/DataFieldReport.bs.js");
var GeneralSettings$ReactHooksTemplate = require("./general_settings/GeneralSettings.bs.js");
var QuickSurveyForm$ReactHooksTemplate = require("./surveys/QuickSurveyForm.bs.js");
var AgentWiseSummary$ReactHooksTemplate = require("./tickets/AgentWiseSummary.bs.js");
var DistributionList$ReactHooksTemplate = require("./surveys/DistributionList.bs.js");
var CampaignDashboard$ReactHooksTemplate = require("./surveys/CampaignDashboard.bs.js");
var QuickSurveyReport$ReactHooksTemplate = require("./surveys/QuickSurveyReport.bs.js");
var TelephonicSurveys$ReactHooksTemplate = require("./surveys/TelephonicSurveys.bs.js");
var UserKpiManagement$ReactHooksTemplate = require("./settings/UserKpiManagement.bs.js");
var CampaignReportList$ReactHooksTemplate = require("./surveys/CampaignReportList.bs.js");
var ResponseModeReport$ReactHooksTemplate = require("./surveys/ResponseModeReport.bs.js");
var SurveyPageNotFound$ReactHooksTemplate = require("./surveys/SurveyPageNotFound.bs.js");
var UserUpdatePassword$ReactHooksTemplate = require("./settings/UserUpdatePassword.bs.js");
var InsightsCorrelation$ReactHooksTemplate = require("./dashboard/dashboard/InsightsCorrelation.bs.js");
var TicketStandAloneView$ReactHooksTemplate = require("./tickets/TicketStandAloneView.bs.js");
var CampaignReportDownloadList$ReactHooksTemplate = require("./surveys/CampaignReportDownloadList.bs.js");

Es6Promise.polyfill();

var emptyState_permissions = PermissionTree$ReactHooksTemplate.emptyPermission(undefined);

var emptyState_releaseFlags = ReleaseFlags$ReactHooksTemplate.emptyFlags(undefined);

var emptyState = {
  permissions: emptyState_permissions,
  releaseFlags: emptyState_releaseFlags
};

function fetchPermissions(param) {
  return Http$ReactHooksTemplate.fetchGetJSONDecode("/api/v1/user_permissions", PermissionTree$ReactHooksTemplate.decode);
}

function fetchReleaseFlags(param) {
  return Http$ReactHooksTemplate.fetchGetJSONDecode("/api/v1/release_flags", ReleaseFlags$ReactHooksTemplate.decode);
}

function Application(Props) {
  var match = React.useState(function () {
        return emptyState;
      });
  var setState = match[1];
  var state = match[0];
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var match$1 = url.path;
  var authorizationRequired;
  if (match$1) {
    switch (match$1.hd) {
      case "surveys" :
          var match$2 = match$1.tl;
          if (match$2) {
            switch (match$2.hd) {
              case "404" :
              case "410" :
                  authorizationRequired = match$2.tl ? true : false;
                  break;
              case "quick_survey" :
                  authorizationRequired = true;
                  break;
              default:
                authorizationRequired = match$2.tl ? true : false;
            }
          } else {
            authorizationRequired = true;
          }
          break;
      case "users" :
          var match$3 = match$1.tl;
          authorizationRequired = match$3 && match$3.hd === "sign_in" && !match$3.tl ? false : true;
          break;
      default:
        authorizationRequired = true;
    }
  } else {
    authorizationRequired = true;
  }
  React.useEffect((function () {
          if (authorizationRequired) {
            Http$ReactHooksTemplate.fetchGetJSONDecode("/api/v1/user_permissions", PermissionTree$ReactHooksTemplate.decode).then(function (permissions) {
                  return Promise.resolve(Curry._1(setState, (function (prevState) {
                                    return {
                                            permissions: permissions,
                                            releaseFlags: prevState.releaseFlags
                                          };
                                  })));
                });
          }
          Http$ReactHooksTemplate.fetchGetJSONDecode("/api/v1/release_flags", ReleaseFlags$ReactHooksTemplate.decode).then(function (flags) {
                return Promise.resolve(Curry._1(setState, (function (prevState) {
                                  return {
                                          permissions: prevState.permissions,
                                          releaseFlags: flags
                                        };
                                })));
              });
          
        }), []);
  var headerView = function (children, parent) {
    var partial_arg = state.releaseFlags;
    return React.createElement(React.Fragment, undefined, React.createElement(Header$ReactHooksTemplate.make, {
                    parent: parent,
                    flagEnabled: (function (param) {
                        return ReleaseFlags$ReactHooksTemplate.isEnabled(partial_arg, param);
                      })
                  }), React.createElement("div", {
                    className: "pt-62-px"
                  }, children));
  };
  var standaloneView = function (children) {
    return React.createElement(React.Fragment, undefined, children);
  };
  var home = function (param) {
    if (PermissionTree$ReactHooksTemplate.hasCapability(/* ShowDashboard */0, state.permissions)) {
      return React.createElement(Dashboards$ReactHooksTemplate.make, {});
    }
    if (!PermissionTree$ReactHooksTemplate.hasCapability(/* ShowSurvey */8, state.permissions)) {
      if (PermissionTree$ReactHooksTemplate.hasCapability(/* ManageTickets */2, state.permissions)) {
        return React.createElement(TicketManager$ReactHooksTemplate.make, {
                    scope: "all",
                    search: url.search
                  });
      } else if (PermissionTree$ReactHooksTemplate.hasCapability(/* ResolveTickets */3, state.permissions) || PermissionTree$ReactHooksTemplate.hasCapability(/* ManageTickets */2, state.permissions)) {
        return React.createElement(TicketWorker$ReactHooksTemplate.make, {
                    status: "all"
                  });
      } else if (PermissionTree$ReactHooksTemplate.hasCapability(/* ShowUser */12, state.permissions)) {
        return React.createElement(UserList$ReactHooksTemplate.make, {
                    search: url.search
                  });
      } else if (PermissionTree$ReactHooksTemplate.hasCapability(/* ShowRole */16, state.permissions)) {
        return React.createElement(RoleList$ReactHooksTemplate.make, {});
      } else if (PermissionTree$ReactHooksTemplate.hasCapability(/* EditTextCategories */23, state.permissions)) {
        return React.createElement(CategoryEdit$ReactHooksTemplate.make, {
                    params: url.search
                  });
      } else {
        return React.createElement(React.Fragment, undefined);
      }
    }
    var partial_arg = state.releaseFlags;
    return React.createElement(Projects$ReactHooksTemplate.make, {
                urlParams: url.search,
                flagEnabled: (function (param) {
                    return ReleaseFlags$ReactHooksTemplate.isEnabled(partial_arg, param);
                  })
              });
  };
  var match$4 = url.path;
  var children;
  if (match$4) {
    switch (match$4.hd) {
      case "admin" :
          var match$5 = match$4.tl;
          children = match$5 && match$5.hd === "view" && !match$5.tl ? headerView(React.createElement(AdminView$ReactHooksTemplate.make, {}), "admin") : headerView(home(undefined), "dashboard");
          break;
      case "campaign" :
          var match$6 = match$4.tl;
          if (match$6) {
            var campaignId = match$6.hd;
            var match$7 = match$6.tl;
            if (match$7) {
              if (match$7.hd === "campaign_report") {
                children = match$7.tl ? headerView(home(undefined), "dashboard") : headerView(React.createElement(Campaigns$ReactHooksTemplate.make, {
                            content: React.createElement(CampaignReport$ReactHooksTemplate.make, {
                                  search: url.search,
                                  campaignId: campaignId
                                }),
                            campaignId: campaignId
                          }), "campaigns");
              } else if (campaignId === "reports") {
                var match$8 = match$6.tl;
                if (match$8.tl) {
                  children = headerView(home(undefined), "dashboard");
                } else {
                  var campaignId$1 = match$8.hd;
                  children = headerView(React.createElement(Campaigns$ReactHooksTemplate.make, {
                            content: React.createElement(CampaignReportList$ReactHooksTemplate.make, {
                                  id: campaignId$1
                                }),
                            campaignId: campaignId$1
                          }), "campaigns");
                }
              } else {
                var match$9 = match$6.tl;
                switch (match$9.hd) {
                  case "data_field_reports" :
                      children = match$9.tl ? headerView(home(undefined), "dashboard") : headerView(React.createElement(Campaigns$ReactHooksTemplate.make, {
                                  content: React.createElement(DataFieldReport$ReactHooksTemplate.make, {
                                        search: url.search,
                                        campaignId: campaignId
                                      }),
                                  campaignId: campaignId
                                }), "campaigns");
                      break;
                  case "nps_trend" :
                      children = match$9.tl ? headerView(home(undefined), "dashboard") : headerView(React.createElement(Campaigns$ReactHooksTemplate.make, {
                                  content: React.createElement(NpsTrend$ReactHooksTemplate.make, {
                                        search: url.search,
                                        campaignId: campaignId
                                      }),
                                  campaignId: campaignId
                                }), "campaigns");
                      break;
                  case "response_mode_reports" :
                      children = match$9.tl ? headerView(home(undefined), "dashboard") : headerView(React.createElement(Campaigns$ReactHooksTemplate.make, {
                                  content: React.createElement(ResponseModeReport$ReactHooksTemplate.make, {
                                        campaignId: campaignId
                                      }),
                                  campaignId: campaignId
                                }), "campaigns");
                      break;
                  default:
                    children = headerView(home(undefined), "dashboard");
                }
              }
            } else {
              children = headerView(home(undefined), "dashboard");
            }
          } else {
            children = headerView(home(undefined), "dashboard");
          }
          break;
      case "campaigns" :
          var match$10 = match$4.tl;
          if (match$10) {
            var campaignId$2 = match$10.hd;
            var exit = 0;
            switch (campaignId$2) {
              case "all" :
                  if (match$10.tl) {
                    exit = 1;
                  } else {
                    children = headerView(React.createElement(CampaignList$ReactHooksTemplate.make, {
                              search: url.search
                            }), "campaigns");
                  }
                  break;
              case "telephonic_surveys" :
                  if (match$10.tl) {
                    exit = 1;
                  } else {
                    children = headerView(React.createElement(TelephonicSurveys$ReactHooksTemplate.make, {}), "telephonic_surveys");
                  }
                  break;
              default:
                exit = 1;
            }
            if (exit === 1) {
              var match$11 = match$10.tl;
              if (match$11) {
                switch (match$11.hd) {
                  case "dashboard" :
                      children = match$11.tl ? headerView(home(undefined), "dashboard") : headerView(React.createElement(Campaigns$ReactHooksTemplate.make, {
                                  content: React.createElement(CampaignDashboard$ReactHooksTemplate.make, {
                                        campaignId: campaignId$2
                                      }),
                                  campaignId: campaignId$2
                                }), "campaigns");
                      break;
                  case "distributions" :
                      children = match$11.tl ? headerView(home(undefined), "dashboard") : headerView(React.createElement(Campaigns$ReactHooksTemplate.make, {
                                  content: React.createElement(DistributionList$ReactHooksTemplate.make, {
                                        campaignId: campaignId$2
                                      }),
                                  campaignId: campaignId$2
                                }), "campaigns");
                      break;
                  default:
                    if (campaignId$2 === "report_downloads") {
                      var match$12 = match$10.tl;
                      if (match$12.tl) {
                        children = headerView(home(undefined), "dashboard");
                      } else {
                        var campaignId$3 = match$12.hd;
                        children = headerView(React.createElement(Campaigns$ReactHooksTemplate.make, {
                                  content: React.createElement(CampaignReportDownloadList$ReactHooksTemplate.make, {
                                        id: campaignId$3
                                      }),
                                  campaignId: campaignId$3
                                }), "campaigns");
                      }
                    } else {
                      children = headerView(home(undefined), "dashboard");
                    }
                }
              } else {
                children = headerView(home(undefined), "dashboard");
              }
            }
            
          } else {
            children = headerView(home(undefined), "dashboard");
          }
          break;
      case "category_edit" :
          children = match$4.tl ? headerView(home(undefined), "dashboard") : headerView(React.createElement(CategoryEdit$ReactHooksTemplate.make, {
                      params: url.search
                    }), "comment_categorization");
          break;
      case "daily_report" :
          children = match$4.tl ? headerView(home(undefined), "dashboard") : headerView(React.createElement(DailyReport$ReactHooksTemplate.make, {
                      params: url.search
                    }), "daily_report");
          break;
      case "dashboard" :
          var match$13 = match$4.tl;
          children = match$13 && !match$13.tl ? headerView(React.createElement(Dashboards$ReactHooksTemplate.make, {
                      dashboardId: Caml_format.caml_int_of_string(match$13.hd)
                    }), "dashboard") : headerView(home(undefined), "dashboard");
          break;
      case "editor" :
          children = match$4.tl ? headerView(home(undefined), "dashboard") : headerView(React.createElement(Editor$ReactHooksTemplate.make, {}), "editor");
          break;
      case "general_settings" :
          children = match$4.tl ? headerView(home(undefined), "dashboard") : headerView(React.createElement(GeneralSettings$ReactHooksTemplate.make, {}), "general_settings");
          break;
      case "insights" :
          var match$14 = match$4.tl;
          if (match$14) {
            switch (match$14.hd) {
              case "correlation" :
                  children = match$14.tl ? headerView(home(undefined), "dashboard") : headerView(React.createElement(InsightsIndex$ReactHooksTemplate.make, {
                              content: React.createElement(InsightsCorrelation$ReactHooksTemplate.make, {})
                            }), "insights");
                  break;
              case "dashboard" :
                  children = match$14.tl ? headerView(home(undefined), "dashboard") : headerView(React.createElement(InsightsIndex$ReactHooksTemplate.make, {
                              content: React.createElement(Insights$ReactHooksTemplate.make, {})
                            }), "insights");
                  break;
              default:
                children = headerView(home(undefined), "dashboard");
            }
          } else {
            children = headerView(home(undefined), "dashboard");
          }
          break;
      case "projects" :
          var match$15 = match$4.tl;
          if (match$15) {
            var projectId = match$15.hd;
            var exit$1 = 0;
            if (projectId === "all" && !match$15.tl) {
              var partial_arg = state.releaseFlags;
              children = headerView(React.createElement(Projects$ReactHooksTemplate.make, {
                        urlParams: url.search,
                        flagEnabled: (function (param) {
                            return ReleaseFlags$ReactHooksTemplate.isEnabled(partial_arg, param);
                          })
                      }), "surveys");
            } else {
              exit$1 = 1;
            }
            if (exit$1 === 1) {
              var match$16 = match$15.tl;
              if (match$16 && match$16.hd === "surveys") {
                var match$17 = match$16.tl;
                if (match$17 && match$17.hd === "edit") {
                  var match$18 = match$17.tl;
                  if (match$18 && !match$18.tl) {
                    var partial_arg$1 = state.releaseFlags;
                    children = headerView(React.createElement(SurveyEdit$ReactHooksTemplate.make, {
                              id: match$18.hd,
                              projectId: Caml_format.caml_int_of_string(projectId),
                              flagEnabled: (function (param) {
                                  return ReleaseFlags$ReactHooksTemplate.isEnabled(partial_arg$1, param);
                                })
                            }), "surveys");
                  } else {
                    children = headerView(home(undefined), "dashboard");
                  }
                } else {
                  children = headerView(home(undefined), "dashboard");
                }
              } else {
                children = headerView(home(undefined), "dashboard");
              }
            }
            
          } else {
            children = headerView(home(undefined), "dashboard");
          }
          break;
      case "quick_survey" :
          var match$19 = match$4.tl;
          children = match$19 && match$19.hd === "report" && !match$19.tl ? headerView(React.createElement(QuickSurveyReport$ReactHooksTemplate.make, {
                      search: url.search
                    }), "quick_survey") : headerView(home(undefined), "dashboard");
          break;
      case "reports" :
          var match$20 = match$4.tl;
          children = match$20 ? (
              match$20.tl ? headerView(home(undefined), "dashboard") : headerView(React.createElement(ReportView$ReactHooksTemplate.make, {
                          search: url.search,
                          reportId: match$20.hd
                        }), "reports")
            ) : headerView(React.createElement(ReportList$ReactHooksTemplate.make, {
                      search: url.search
                    }), "reports");
          break;
      case "responses" :
          children = match$4.tl ? headerView(home(undefined), "dashboard") : headerView(React.createElement(Responses$ReactHooksTemplate.make, {
                      params: url.search
                    }), "responses");
          break;
      case "roles" :
          var match$21 = match$4.tl;
          if (match$21) {
            switch (match$21.hd) {
              case "all" :
                  children = match$21.tl ? headerView(home(undefined), "dashboard") : headerView(React.createElement(RoleList$ReactHooksTemplate.make, {}), "roles");
                  break;
              case "edit" :
                  var match$22 = match$21.tl;
                  children = match$22 && !match$22.tl ? headerView(React.createElement(RoleEdit$ReactHooksTemplate.make, {
                              id: match$22.hd
                            }), "roles") : headerView(home(undefined), "dashboard");
                  break;
              case "new" :
                  children = match$21.tl ? headerView(home(undefined), "dashboard") : headerView(React.createElement(RoleCreate$ReactHooksTemplate.make, {}), "roles");
                  break;
              default:
                children = headerView(home(undefined), "dashboard");
            }
          } else {
            children = headerView(home(undefined), "dashboard");
          }
          break;
      case "signup" :
          children = match$4.tl ? headerView(home(undefined), "dashboard") : standaloneView(React.createElement(SignUp$ReactHooksTemplate.make, {}));
          break;
      case "surveys" :
          var match$23 = match$4.tl;
          if (match$23) {
            var id = match$23.hd;
            switch (id) {
              case "404" :
                  children = match$23.tl ? headerView(home(undefined), "dashboard") : standaloneView(React.createElement(SurveyPageNotFound$ReactHooksTemplate.make, {}));
                  break;
              case "410" :
                  children = match$23.tl ? headerView(home(undefined), "dashboard") : standaloneView(React.createElement(SurveyExpired$ReactHooksTemplate.make, {}));
                  break;
              case "quick_survey" :
                  children = match$23.tl ? headerView(home(undefined), "dashboard") : headerView(React.createElement(QuickSurveyForm$ReactHooksTemplate.make, {}), "quick_survey");
                  break;
              default:
                if (match$23.tl) {
                  children = headerView(home(undefined), "dashboard");
                } else {
                  var partial_arg$2 = state.releaseFlags;
                  children = standaloneView(React.createElement(SurveyPage$ReactHooksTemplate.make, {
                            id: id,
                            flagEnabled: (function (param) {
                                return ReleaseFlags$ReactHooksTemplate.isEnabled(partial_arg$2, param);
                              })
                          }));
                }
            }
          } else {
            children = headerView(home(undefined), "dashboard");
          }
          break;
      case "tickets" :
          var match$24 = match$4.tl;
          if (match$24) {
            switch (match$24.hd) {
              case "agent_summary" :
                  children = match$24.tl ? headerView(home(undefined), "dashboard") : headerView(React.createElement(Tickets$ReactHooksTemplate.make, {
                              content: React.createElement(AgentWiseSummary$ReactHooksTemplate.make, {})
                            }), "tickets");
                  break;
              case "downloadList" :
                  children = match$24.tl ? headerView(home(undefined), "dashboard") : headerView(React.createElement(Tickets$ReactHooksTemplate.make, {
                              content: React.createElement(DownloadList$ReactHooksTemplate.make, {})
                            }), "tickets");
                  break;
              case "manage" :
                  var match$25 = match$24.tl;
                  children = match$25 && !match$25.tl ? headerView(React.createElement(Tickets$ReactHooksTemplate.make, {
                              content: React.createElement(TicketManager$ReactHooksTemplate.make, {
                                    scope: match$25.hd,
                                    search: url.search
                                  })
                            }), "tickets") : headerView(home(undefined), "dashboard");
                  break;
              case "show" :
                  var match$26 = match$24.tl;
                  children = match$26 && !match$26.tl ? headerView(React.createElement(TicketStandAloneView$ReactHooksTemplate.make, {
                              id: match$26.hd
                            }), "tickets") : headerView(home(undefined), "dashboard");
                  break;
              case "work" :
                  var match$27 = match$24.tl;
                  children = match$27 && !match$27.tl ? headerView(React.createElement(Tickets$ReactHooksTemplate.make, {
                              content: React.createElement(TicketWorker$ReactHooksTemplate.make, {
                                    status: match$27.hd
                                  })
                            }), "tickets") : headerView(home(undefined), "dashboard");
                  break;
              default:
                children = headerView(home(undefined), "dashboard");
            }
          } else {
            children = headerView(home(undefined), "dashboard");
          }
          break;
      case "users" :
          var match$28 = match$4.tl;
          if (match$28) {
            switch (match$28.hd) {
              case "all" :
                  children = match$28.tl ? headerView(home(undefined), "dashboard") : headerView(React.createElement(UserList$ReactHooksTemplate.make, {
                              search: url.search
                            }), "users");
                  break;
              case "edit" :
                  var match$29 = match$28.tl;
                  children = match$29 && !match$29.tl ? headerView(React.createElement(UserEdit$ReactHooksTemplate.make, {
                              id: match$29.hd
                            }), "users") : headerView(home(undefined), "dashboard");
                  break;
              case "kpi" :
                  var match$30 = match$28.tl;
                  children = match$30 ? (
                      match$30.hd === "new" && !match$30.tl ? headerView(React.createElement(UserKpiCreate$ReactHooksTemplate.make, {}), "users") : headerView(home(undefined), "dashboard")
                    ) : headerView(React.createElement(UserKpiManagement$ReactHooksTemplate.make, {
                              search: url.search
                            }), "users");
                  break;
              case "new" :
                  children = match$28.tl ? headerView(home(undefined), "dashboard") : headerView(React.createElement(UserForm$ReactHooksTemplate.make, {}), "users");
                  break;
              case "sign_in" :
                  children = match$28.tl ? headerView(home(undefined), "dashboard") : standaloneView(React.createElement(SignIn$ReactHooksTemplate.make, {}));
                  break;
              case "update_password" :
                  children = match$28.tl ? headerView(home(undefined), "dashboard") : headerView(React.createElement(UserUpdatePassword$ReactHooksTemplate.make, {}), "users");
                  break;
              default:
                children = headerView(home(undefined), "dashboard");
            }
          } else {
            children = headerView(home(undefined), "dashboard");
          }
          break;
      default:
        children = headerView(home(undefined), "dashboard");
    }
  } else {
    children = headerView(home(undefined), "dashboard");
  }
  var partial_arg$3 = state.permissions;
  var match$31 = state.permissions;
  var match$32 = url.path;
  var tmp;
  if (match$31) {
    tmp = React.createElement("div", {
          className: "font-inter"
        }, children);
  } else if (match$32) {
    switch (match$32.hd) {
      case "signup" :
          tmp = match$32.tl ? React.createElement(React.Fragment, undefined) : standaloneView(React.createElement(SignUp$ReactHooksTemplate.make, {}));
          break;
      case "surveys" :
          var match$33 = match$32.tl;
          if (match$33) {
            var id$1 = match$33.hd;
            switch (id$1) {
              case "404" :
                  tmp = match$33.tl ? React.createElement(React.Fragment, undefined) : standaloneView(React.createElement(SurveyPageNotFound$ReactHooksTemplate.make, {}));
                  break;
              case "410" :
                  tmp = match$33.tl ? React.createElement(React.Fragment, undefined) : standaloneView(React.createElement(SurveyExpired$ReactHooksTemplate.make, {}));
                  break;
              default:
                if (match$33.tl) {
                  tmp = React.createElement(React.Fragment, undefined);
                } else {
                  var partial_arg$4 = state.releaseFlags;
                  tmp = standaloneView(React.createElement(SurveyPage$ReactHooksTemplate.make, {
                            id: id$1,
                            flagEnabled: (function (param) {
                                return ReleaseFlags$ReactHooksTemplate.isEnabled(partial_arg$4, param);
                              })
                          }));
                }
            }
          } else {
            tmp = React.createElement(React.Fragment, undefined);
          }
          break;
      case "users" :
          var match$34 = match$32.tl;
          tmp = match$34 && match$34.hd === "sign_in" && !match$34.tl ? standaloneView(React.createElement(SignIn$ReactHooksTemplate.make, {})) : React.createElement(React.Fragment, undefined);
          break;
      default:
        tmp = React.createElement(React.Fragment, undefined);
    }
  } else {
    tmp = React.createElement(React.Fragment, undefined);
  }
  return React.createElement(AuthProvider$ReactHooksTemplate.make, AuthProvider$ReactHooksTemplate.makeProps([
                  (function (param) {
                      return PermissionTree$ReactHooksTemplate.hasCapability(param, partial_arg$3);
                    }),
                  state.permissions
                ], tmp, undefined));
}

var make = Application;

exports.emptyState = emptyState;
exports.fetchPermissions = fetchPermissions;
exports.fetchReleaseFlags = fetchReleaseFlags;
exports.make = make;
/*  Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");

function ToggleInput(Props) {
  var checked = Props.checked;
  var disabledOpt = Props.disabled;
  var handleChange = Props.handleChange;
  var labelClassNameOpt = Props.labelClassName;
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var labelClassName = labelClassNameOpt !== undefined ? labelClassNameOpt : "flex items-center";
  return React.createElement("label", {
              className: disabled ? "disabled-input " + labelClassName : "mandatory " + labelClassName
            }, React.createElement("input", {
                  checked: checked,
                  disabled: disabled,
                  type: "checkbox",
                  onChange: handleChange
                }), React.createElement("span", {
                  className: "slider round"
                }));
}

var make = ToggleInput;

exports.make = make;
/* react Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var List = require("rescript/lib/js/list.js");
var $$Array = require("rescript/lib/js/array.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Js_dict = require("rescript/lib/js/js_dict.js");
var Js_json = require("rescript/lib/js/js_json.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Recharts = require("recharts");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var AxisData$ReactHooksTemplate = require("./axisData.bs.js");
var Recharts$ReactHooksTemplate = require("../common/Recharts.bs.js");
var ChartComponentColor$ReactHooksTemplate = require("./chartComponentColor.bs.js");
var GenericChartAttribute$ReactHooksTemplate = require("./genericChartAttribute.bs.js");

function BarChartComponent(Props) {
  var chartId = Props.chartId;
  var chartAttribute = Props.chartAttribute;
  var drillDownKey = Props.drillDownKey;
  var onClick = Props.onClick;
  var enlarged = Props.enlarged;
  var chartHeight = Props.chartHeight;
  var renderLegend = function (value, param) {
    return React.createElement("span", {
                className: "text-xs"
              }, value);
  };
  var layout = GenericChartAttribute$ReactHooksTemplate.layout(chartAttribute);
  var partial_arg = AxisData$ReactHooksTemplate.key(GenericChartAttribute$ReactHooksTemplate.xAxis(chartAttribute));
  var partial_arg$1 = function (param) {
    return Json_decode.field(partial_arg, Json_decode.string, param);
  };
  var decodeXAxisTableData = Json_decode.list((function (param) {
          return Json_decode.withDefault("", partial_arg$1, param);
        }), Belt_Option.getExn(GenericChartAttribute$ReactHooksTemplate.data(chartAttribute)));
  var decodeYAxisTableData = Belt_List.map(GenericChartAttribute$ReactHooksTemplate.yAxis(chartAttribute), (function (item) {
          var partial_arg = AxisData$ReactHooksTemplate.key(item);
          var partial_arg$1 = function (param) {
            return Json_decode.field(partial_arg, Json_decode.$$float, param);
          };
          return Json_decode.list((function (param) {
                        return Json_decode.withDefault(0, partial_arg$1, param);
                      }), Belt_Option.getExn(GenericChartAttribute$ReactHooksTemplate.data(chartAttribute)));
        }));
  var isStacked = Belt_List.reduce(GenericChartAttribute$ReactHooksTemplate.yAxis(chartAttribute), false, (function (acc, xs) {
          if (Belt_Option.isSome(AxisData$ReactHooksTemplate.stackId(xs))) {
            return true;
          } else {
            return false;
          }
        }));
  var data = GenericChartAttribute$ReactHooksTemplate.data(chartAttribute);
  var dataLength;
  if (data !== undefined) {
    var data$1 = Js_json.decodeArray(Caml_option.valFromOption(data));
    dataLength = data$1 !== undefined ? data$1.length : 0;
  } else {
    dataLength = 0;
  }
  var tmp;
  if (isStacked || !enlarged) {
    tmp = React.createElement(React.Fragment, undefined);
  } else {
    var axisData = Belt_List.head(GenericChartAttribute$ReactHooksTemplate.yAxis(chartAttribute));
    tmp = React.createElement("div", {
          className: "flex p-2 items-center justify-stretch"
        }, React.createElement("div", undefined, React.createElement("div", {
                  className: "px-4 text-xs font-bold border border-1 p-1 flex-grow",
                  style: {
                    borderColor: ChartComponentColor$ReactHooksTemplate.getColorById(0, GenericChartAttribute$ReactHooksTemplate.colors(chartAttribute))
                  }
                }, AxisData$ReactHooksTemplate.key(GenericChartAttribute$ReactHooksTemplate.xAxis(chartAttribute))), $$Array.of_list(Belt_List.map(decodeXAxisTableData, (function (item) {
                        return React.createElement("div", {
                                    className: "px-4 text-xs font-bold border border-1\n                border-t-0 p-1",
                                    style: {
                                      borderColor: ChartComponentColor$ReactHooksTemplate.getColorById(0, GenericChartAttribute$ReactHooksTemplate.colors(chartAttribute))
                                    }
                                  }, item === "" ? "default" : item);
                      })))), React.createElement("div", undefined, React.createElement("div", {
                  className: "px-4 text-xs font-bold border border-1 border-l-0\n             p-1 flex-grow",
                  style: {
                    borderColor: ChartComponentColor$ReactHooksTemplate.getColorById(0, GenericChartAttribute$ReactHooksTemplate.colors(chartAttribute))
                  }
                }, axisData !== undefined ? AxisData$ReactHooksTemplate.key(Caml_option.valFromOption(axisData)) : ""), $$Array.of_list(List.flatten(Belt_List.map(decodeYAxisTableData, (function (list) {
                            return Belt_List.map(list, (function (item) {
                                          return React.createElement("div", {
                                                      className: "px-4 text-xs font-bold border border-1\n                  border-t-0 border-l-0 p-1 flex-grow",
                                                      style: {
                                                        borderColor: ChartComponentColor$ReactHooksTemplate.getColorById(0, GenericChartAttribute$ReactHooksTemplate.colors(chartAttribute))
                                                      }
                                                    }, item.toString());
                                        }));
                          }))))));
  }
  return React.createElement("div", {
              className: "flex flex-col items-center"
            }, React.createElement("div", {
                  className: " w-full " + chartHeight + " p-20-px "
                }, React.createElement(Recharts.ResponsiveContainer, {
                      children: React.createElement(Recharts.BarChart, {
                            data: GenericChartAttribute$ReactHooksTemplate.data(chartAttribute),
                            margin: Recharts$ReactHooksTemplate.Margin.make(20, 65, 0, 25),
                            layout: layout,
                            children: null
                          }, React.createElement(Recharts.CartesianGrid, {}), layout === "vertical" ? React.createElement(Recharts.XAxis, Curry._7(Recharts$ReactHooksTemplate.XAxis.makeProps(undefined)(1, undefined, undefined, undefined, undefined), undefined, undefined, undefined, undefined, "number", undefined, undefined)) : React.createElement(Recharts.XAxis, Curry._7(Recharts$ReactHooksTemplate.XAxis.makeProps(isStacked ? /* Num */({
                                                _0: 0
                                              }) : /* PreserveStartEnd */2)(2, AxisData$ReactHooksTemplate.key(GenericChartAttribute$ReactHooksTemplate.xAxis(chartAttribute)), undefined, undefined, Caml_option.some(Recharts$ReactHooksTemplate.Tick.make(12, 0, "bottom")("middle", 10, true))), undefined, 80, 13, (function (key) {
                                        return key.split("_").join(" ").split("-").join(" ");
                                      }), undefined, undefined, undefined)), layout === "horizontal" ? React.createElement(Recharts.YAxis, Curry.app(Recharts$ReactHooksTemplate.YAxis.makeProps(undefined)(1, undefined, undefined, undefined, undefined, Caml_option.some(Recharts$ReactHooksTemplate.Tick.make(12, undefined, undefined)("end", 10, true))), [
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined
                                    ])) : React.createElement(Recharts.YAxis, Curry.app(Recharts$ReactHooksTemplate.YAxis.makeProps(isStacked ? /* Num */({
                                                _0: 0
                                              }) : /* PreserveStartEnd */2)(1, AxisData$ReactHooksTemplate.key(GenericChartAttribute$ReactHooksTemplate.xAxis(chartAttribute)), undefined, 300, undefined, Caml_option.some(Recharts$ReactHooksTemplate.Tick.make(12, undefined, "top")("end", 10, true))), [
                                      undefined,
                                      undefined,
                                      undefined,
                                      "category",
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined
                                    ])), dataLength > 5 ? React.createElement(Recharts.Brush, {
                                  dataKey: AxisData$ReactHooksTemplate.key(GenericChartAttribute$ReactHooksTemplate.xAxis(chartAttribute)),
                                  endIndex: enlarged || Belt_Option.getWithDefault(GenericChartAttribute$ReactHooksTemplate.width(chartAttribute), "") === "w-full" ? undefined : (
                                      dataLength > 10 ? 9 : dataLength - 1 | 0
                                    ),
                                  height: enlarged ? 20 : 10
                                }) : React.createElement(React.Fragment, undefined), React.createElement(Recharts.Tooltip, {
                                cursor: Recharts$ReactHooksTemplate.Cursor.make("#F0F0F0"),
                                contentStyle: {
                                  fontSize: "10pt"
                                }
                              }), React.createElement(Recharts.Legend, {
                                formatter: renderLegend
                              }), $$Array.of_list(Belt_List.mapWithIndex(GenericChartAttribute$ReactHooksTemplate.yAxis(chartAttribute), (function (i, xs) {
                                      var json = GenericChartAttribute$ReactHooksTemplate.data(chartAttribute);
                                      return React.createElement(Recharts.Bar, {
                                                  dataKey: AxisData$ReactHooksTemplate.key(xs),
                                                  barSize: 30,
                                                  fill: ChartComponentColor$ReactHooksTemplate.getColorById(i, GenericChartAttribute$ReactHooksTemplate.colors(chartAttribute)),
                                                  stackId: Belt_Option.getWithDefault(AxisData$ReactHooksTemplate.stackId(xs), ""),
                                                  onClick: (function (data, param) {
                                                      var key = Json_decode.field(AxisData$ReactHooksTemplate.key(GenericChartAttribute$ReactHooksTemplate.xAxis(chartAttribute)), Json_decode.string, Belt_Option.getExn((data == null) ? undefined : Caml_option.some(data)).payload);
                                                      return Curry._3(onClick, key, chartId, GenericChartAttribute$ReactHooksTemplate.onClick(chartAttribute));
                                                    }),
                                                  children: null,
                                                  key: String(i)
                                                }, React.createElement(Recharts.LabelList, {
                                                      dataKey: AxisData$ReactHooksTemplate.key(xs),
                                                      fill: "#F0F0F0",
                                                      fontSize: 10
                                                    }), json !== undefined ? Belt_Array.map(Belt_Option.getWithDefault(Js_json.decodeArray(Caml_option.valFromOption(json)), []), (function (xs) {
                                                          return React.createElement(Recharts.Cell, {
                                                                      key: String(chartId),
                                                                      fill: drillDownKey !== undefined && !(drillDownKey === "" || Caml_obj.caml_equal(Js_json.decodeString(Belt_Option.getWithDefault(Js_dict.get(Belt_Option.getWithDefault(Js_json.decodeObject(xs), {}), AxisData$ReactHooksTemplate.key(GenericChartAttribute$ReactHooksTemplate.xAxis(chartAttribute))), null)), String(drillDownKey))) ? "#d8d8d8" : ChartComponentColor$ReactHooksTemplate.getColorById(i, GenericChartAttribute$ReactHooksTemplate.colors(chartAttribute))
                                                                    });
                                                        })) : React.createElement(React.Fragment, undefined));
                                    }))))
                    })), tmp);
}

var make = BarChartComponent;

exports.make = make;
/* react Not a pure module */

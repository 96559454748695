// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var ToggleMandatory$ReactHooksTemplate = require("./ToggleMandatory.bs.js");

function ShortTextEditor(Props) {
  var mandatory = Props.mandatory;
  var onMandatoryChange = Props.onMandatoryChange;
  return React.createElement("div", undefined, React.createElement(ToggleMandatory$ReactHooksTemplate.make, {
                  mandatory: mandatory,
                  onMandatoryChange: onMandatoryChange
                }));
}

var make = ShortTextEditor;

exports.make = make;
/* react Not a pure module */

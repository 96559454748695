// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';


function empty(param) {
  return {
          left: "",
          center: "",
          right: ""
        };
}

function make(left, center, right) {
  return {
          left: left,
          center: center,
          right: right
        };
}

function setLeft(t, left) {
  return {
          left: left,
          center: t.center,
          right: t.right
        };
}

function setCenter(t, center) {
  return {
          left: t.left,
          center: center,
          right: t.right
        };
}

function setRight(t, right) {
  return {
          left: t.left,
          center: t.center,
          right: right
        };
}

function left(t) {
  return t.left;
}

function center(t) {
  return t.center;
}

function right(t) {
  return t.right;
}

exports.empty = empty;
exports.make = make;
exports.setLeft = setLeft;
exports.setCenter = setCenter;
exports.setRight = setRight;
exports.left = left;
exports.center = center;
exports.right = right;
/* No side effect */

// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var EncryptRsa = require("encrypt-rsa").default;
var Utils$ReactHooksTemplate = require("./common/utils.bs.js");
var Domain$ReactHooksTemplate = require("./settings/domain.bs.js");
var RsaEncrypt$ReactHooksTemplate = require("./common/rsaEncrypt.bs.js");

function empty(param) {
  return {
          email: Curry._1(Domain$ReactHooksTemplate.$$String.Email.empty, undefined),
          password: "",
          rememberMe: false
        };
}

function setEmail(email, t) {
  return {
          email: email,
          password: t.password,
          rememberMe: t.rememberMe
        };
}

function setPassword(password, t) {
  return {
          email: t.email,
          password: password,
          rememberMe: t.rememberMe
        };
}

function toggleRememberMe(t) {
  return {
          email: t.email,
          password: t.password,
          rememberMe: !t.rememberMe
        };
}

function rememberMe(t) {
  return t.rememberMe;
}

function email(t) {
  return t.email;
}

function password(t) {
  return t.password;
}

var SignInParams = {
  empty: empty,
  setEmail: setEmail,
  setPassword: setPassword,
  toggleRememberMe: toggleRememberMe,
  rememberMe: rememberMe,
  email: email,
  password: password
};

function SignIn(Props) {
  var match = React.useState(function () {
        return empty(undefined);
      });
  var setState = match[1];
  var state = match[0];
  var __x = document.querySelector("meta[name='rsa-public-key']");
  var rsaPublicKey = Belt_Option.getWithDefault(Belt_Option.flatMap((__x == null) ? undefined : Caml_option.some(__x), (function (param) {
              return Caml_option.nullable_to_opt(param.getAttribute("content"));
            })), "");
  var handleSubmit = function (param) {
    var encryptedPassword = new EncryptRsa().encryptStringWithRsaPublicKey(RsaEncrypt$ReactHooksTemplate.EncryptionOptions.make(state.password, rsaPublicKey));
    return Curry._1(setState, (function (state) {
                  return {
                          email: state.email,
                          password: encryptedPassword,
                          rememberMe: state.rememberMe
                        };
                }));
  };
  return React.createElement("div", undefined, React.createElement("form", {
                  action: "/users/sign_in",
                  autoComplete: "off",
                  charSet: "UTF-8",
                  method: "post",
                  onSubmit: handleSubmit
                }, React.createElement("input", {
                      name: "authenticity_token",
                      type: "hidden",
                      value: Utils$ReactHooksTemplate.csrfToken
                    }), React.createElement("div", {
                      className: "mb-20-px"
                    }, React.createElement("label", {
                          className: "block text-18-px\n          mb-20-px",
                          name: "email"
                        }, "Email"), React.createElement("input", {
                          className: "border\n        border-gray-500 hover:border-gray-700 focus:border-teal-500 outline-none\n        p-10-px w-full",
                          id: "user_email",
                          name: "user[email]",
                          type: "email",
                          value: Curry._1(Domain$ReactHooksTemplate.$$String.Email.show, state.email),
                          onChange: (function (e) {
                              var email = e.target.value;
                              return Curry._1(setState, (function (state) {
                                            return {
                                                    email: email,
                                                    password: state.password,
                                                    rememberMe: state.rememberMe
                                                  };
                                          }));
                            })
                        })), React.createElement("div", {
                      className: "mb-30-px"
                    }, React.createElement("label", {
                          className: "block text-18-px mb-20-px",
                          name: "password"
                        }, "Password"), React.createElement("input", {
                          className: "border\n        border-gray-500 hover:border-gray-700 focus:border-teal-500 outline-none\n        p-10-px w-full",
                          id: "user_password",
                          autoComplete: "off",
                          name: "user[password]",
                          type: "password",
                          value: state.password,
                          onChange: (function (e) {
                              var password = e.target.value;
                              return Curry._1(setState, (function (state) {
                                            return {
                                                    email: state.email,
                                                    password: password,
                                                    rememberMe: state.rememberMe
                                                  };
                                          }));
                            })
                        })), React.createElement("div", {
                      className: "mb-20-px"
                    }, React.createElement("input", {
                          className: "text-white bg-teal-500 hover:bg-teal-700 p-10-px w-full cursor-pointer",
                          name: "commit",
                          type: "submit",
                          value: "Log in"
                        })), React.createElement("label", {
                      className: "flex items-center justify-center\n      mb-20-px",
                      name: "remember_me"
                    }, React.createElement("input", {
                          name: "user[remember_me]",
                          type: "checkbox",
                          value: state.rememberMe ? "1" : "0",
                          onChange: (function (param) {
                              return Curry._1(setState, (function (state) {
                                            return {
                                                    email: state.email,
                                                    password: state.password,
                                                    rememberMe: !state.rememberMe
                                                  };
                                          }));
                            })
                        }), React.createElement("span", {
                          className: "checkbox mr-10-px cursor-pointer"
                        }), React.createElement("span", undefined, "Remember Me"))));
}

var make = SignIn;

exports.SignInParams = SignInParams;
exports.make = make;
/* react Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var $$Array = require("rescript/lib/js/array.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var Http$ReactHooksTemplate = require("../common/http.bs.js");
var Utils$ReactHooksTemplate = require("../common/utils.bs.js");
var TextInput$ReactHooksTemplate = require("../common/TextInput.bs.js");

function fieldId(t) {
  return t.fieldId;
}

function name(t) {
  return t.name;
}

function decode(json) {
  return {
          fieldId: Json_decode.field("id", Json_decode.$$int, json),
          name: Json_decode.field("name", Json_decode.string, json)
        };
}

var KpiFields = {
  fieldId: fieldId,
  name: name,
  emptyFields: /* [] */0,
  decode: decode
};

function userId(t) {
  return t.userId;
}

function email(t) {
  return t.email;
}

function decode$1(json) {
  return {
          userId: Json_decode.field("id", Json_decode.$$int, json),
          email: Json_decode.field("email", Json_decode.string, json)
        };
}

var KpiUser = {
  userId: userId,
  email: email,
  emptyUsers: /* [] */0,
  decode: decode$1
};

var emptyFormInfo = {
  email: "",
  field: "",
  value: "",
  kpi: 0
};

function UserKpiCreate(Props) {
  var match = React.useState(function () {
        return emptyFormInfo;
      });
  var setFormInfo = match[1];
  var formInfo = match[0];
  var match$1 = React.useState(function () {
        return /* [] */0;
      });
  var setUsers = match$1[1];
  var match$2 = React.useState(function () {
        return /* [] */0;
      });
  var setFields = match$2[1];
  var match$3 = React.useState(function () {
        return /* [] */0;
      });
  var setValues = match$3[1];
  var match$4 = React.useState(function () {
        return "";
      });
  var setformSubmitMessage = match$4[1];
  var formSubmitMessage = match$4[0];
  React.useEffect((function () {
          Http$ReactHooksTemplate.fetchGetJSONDecode("/api/v1/kpis/users", (function (param) {
                    return Json_decode.field("users", (function (param) {
                                  return Json_decode.list(decode$1, param);
                                }), param);
                  })).then(function (users) {
                return Promise.resolve(Curry._1(setUsers, (function (param) {
                                  return users;
                                })));
              });
          Http$ReactHooksTemplate.fetchGetJSONDecode("/api/v1/kpis/kpi_fields", (function (param) {
                    return Json_decode.field("fields", (function (param) {
                                  return Json_decode.list(decode, param);
                                }), param);
                  })).then(function (fields) {
                return Promise.resolve(Curry._1(setFields, (function (param) {
                                  return fields;
                                })));
              });
          
        }), []);
  var buildSurveyFormDict = function (form) {
    var params = {};
    params["user_id"] = form.email;
    params["field"] = form.field;
    params["value"] = form.value;
    params["kpi"] = form.kpi;
    console.log("-----PARAMS-----");
    console.log(params);
    console.log("-----PARAMS-----");
    return params;
  };
  var handleEmailChange = function ($$event) {
    var email = Utils$ReactHooksTemplate.formTargetValue($$event);
    console.log(email);
    return Curry._1(setFormInfo, (function (formInfo) {
                  return {
                          email: email,
                          field: formInfo.field,
                          value: formInfo.value,
                          kpi: formInfo.kpi
                        };
                }));
  };
  var handleFieldChange = function ($$event) {
    var field = Utils$ReactHooksTemplate.formTargetValue($$event);
    Http$ReactHooksTemplate.fetchGetJSONDecode("/api/v1/kpis/field_values?field_id=" + field, (function (param) {
              return Json_decode.field("field_values", (function (param) {
                            return Json_decode.list(Json_decode.string, param);
                          }), param);
            })).then(function (values) {
          return Promise.resolve(Curry._1(setValues, (function (param) {
                            return values;
                          })));
        });
    return Curry._1(setFormInfo, (function (formInfo) {
                  return {
                          email: formInfo.email,
                          field: field,
                          value: formInfo.value,
                          kpi: formInfo.kpi
                        };
                }));
  };
  var handleValueChange = function ($$event) {
    var value = Utils$ReactHooksTemplate.formTargetValue($$event);
    return Curry._1(setFormInfo, (function (formInfo) {
                  return {
                          email: formInfo.email,
                          field: formInfo.field,
                          value: value,
                          kpi: formInfo.kpi
                        };
                }));
  };
  var handleKpiChange = function ($$event) {
    var kpi = Utils$ReactHooksTemplate.formTargetValue($$event);
    return Curry._1(setFormInfo, (function (formInfo) {
                  return {
                          email: formInfo.email,
                          field: formInfo.field,
                          value: formInfo.value,
                          kpi: kpi
                        };
                }));
  };
  var handleClose = function (param) {
    return Curry._1(setformSubmitMessage, (function (param) {
                  return "";
                }));
  };
  var tmp = formSubmitMessage === "" ? React.createElement(React.Fragment, undefined) : React.createElement(React.Fragment, undefined, React.createElement("div", {
              className: "bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative",
              role: "alert"
            }, React.createElement("strong", {
                  className: "font-bold"
                }, "Error!"), React.createElement("span", {
                  className: "block sm:inline"
                }, formSubmitMessage), React.createElement("span", {
                  className: "absolute top-0 bottom-0 right-0 px-4 py-3"
                }, React.createElement("svg", {
                      className: "fill-current h-6 w-6 text-red-500",
                      role: "button",
                      onClick: handleClose,
                      viewBox: "0 0 20 20",
                      xmlns: "http://www.w3.org/2000/svg"
                    }, React.createElement("title", undefined, "Close"), React.createElement("path", {
                          d: "M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"
                        })))));
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  className: "p-30-px m-30-px w-2/3 bg-white"
                }, React.createElement("h1", {
                      className: "text-32-px mb-30-px"
                    }, "New KPI"), tmp, React.createElement("form", {
                      onSubmit: (function (param) {
                          param.preventDefault();
                          Http$ReactHooksTemplate.fetchPostJSON("/api/v1/kpis", buildSurveyFormDict(formInfo)).then(function (response) {
                                var status = Json_decode.field("status", Json_decode.string, response);
                                var description = Json_decode.field("description", Json_decode.string, response);
                                switch (status) {
                                  case "error" :
                                      Curry._1(setformSubmitMessage, (function (param) {
                                              return description;
                                            }));
                                      break;
                                  case "success" :
                                      RescriptReactRouter.push("/users/kpi");
                                      break;
                                  default:
                                    Curry._1(setformSubmitMessage, (function (param) {
                                            return "Success.........";
                                          }));
                                }
                                return Promise.resolve((console.log(response), undefined));
                              });
                          
                        })
                    }, React.createElement("div", {
                          className: "flex mt-30-px mb-30-px items-center"
                        }, React.createElement("select", {
                              className: "block py-2.5 px-0 w-400-px text-sm text-gray-700 bg-transparent border-0 border-b-2 border-gray-200 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer",
                              placeholder: "Select Email",
                              value: formInfo.email === "" ? "Select Email" : formInfo.email,
                              onChange: handleEmailChange
                            }, React.createElement("option", {
                                  key: "0",
                                  disabled: true,
                                  value: "Select Email"
                                }, "Select Email"), $$Array.of_list(Belt_List.map(match$1[0], (function (user) {
                                        return React.createElement("option", {
                                                    key: String(user.userId),
                                                    value: String(user.userId)
                                                  }, user.email);
                                      }))))), React.createElement("div", {
                          className: "flex mb-30-px items-center"
                        }, React.createElement("select", {
                              className: "block py-2.5 px-0 w-400-px text-sm text-gray-700 bg-transparent border-0 border-b-2 border-gray-200 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer",
                              placeholder: "Select Field",
                              value: formInfo.field === "" ? "Select Field" : formInfo.field,
                              onChange: handleFieldChange
                            }, React.createElement("option", {
                                  key: "0",
                                  disabled: true,
                                  value: "Select Field"
                                }, "Select Field"), $$Array.of_list(Belt_List.map(match$2[0], (function (field) {
                                        return React.createElement("option", {
                                                    key: String(field.fieldId),
                                                    value: String(field.fieldId)
                                                  }, field.name);
                                      }))))), React.createElement("div", {
                          className: "flex mb-30-px items-center"
                        }, React.createElement("select", {
                              className: "block py-2.5 px-0 w-400-px text-sm text-gray-700 bg-transparent border-0 border-b-2 border-gray-200 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer",
                              placeholder: "Select Value",
                              value: formInfo.value === "" ? "Select Value" : formInfo.value,
                              onChange: handleValueChange
                            }, React.createElement("option", {
                                  key: "00",
                                  disabled: true,
                                  value: "Select Value"
                                }, "Select Value"), $$Array.of_list(Belt_List.mapWithIndex(match$3[0], (function (id, value) {
                                        return React.createElement("option", {
                                                    key: String(id + 1 | 0),
                                                    value: value
                                                  }, value);
                                      }))))), React.createElement("div", {
                          className: "flex mb-30-px items-center"
                        }, React.createElement("div", {
                              className: "w-1/2"
                            }, React.createElement(TextInput$ReactHooksTemplate.make, {
                                  label: "KPI",
                                  value: String(formInfo.kpi),
                                  handleChange: handleKpiChange
                                }))), React.createElement("div", undefined, React.createElement("input", {
                              className: "bg-teal-500 hover:bg-teal-700 py-6-px px-20-px font-normal text-white font-16-px",
                              type: "submit",
                              value: "Submit"
                            })))));
}

var make = UserKpiCreate;

exports.KpiFields = KpiFields;
exports.KpiUser = KpiUser;
exports.emptyFormInfo = emptyFormInfo;
exports.make = make;
/* react Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");

function assigner(t) {
  return t.assigner;
}

function assignee(t) {
  return t.assignee;
}

function fromJson(json) {
  return {
          assigner: Json_decode.field("user", (function (param) {
                  return Json_decode.field("email", Json_decode.string, param);
                }), json),
          assignee: Json_decode.field("assignee", (function (param) {
                  return Json_decode.field("email", Json_decode.string, param);
                }), json)
        };
}

var activityType = "assign";

exports.activityType = activityType;
exports.assigner = assigner;
exports.assignee = assignee;
exports.fromJson = fromJson;
/* No side effect */

// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");

function empty(param) {
  return {
          rmName: ""
        };
}

function rmName(t) {
  return t.rmName;
}

function decodeRmName(json) {
  return Json_decode.field("name", (function (param) {
                return Json_decode.withDefault("", Json_decode.string, param);
              }), json);
}

function decodeCustomerInfo(json) {
  return {
          rmName: Json_decode.field("more_details", (function (param) {
                  return Json_decode.withDefault("", decodeRmName, param);
                }), json)
        };
}

function fromJson(json) {
  return Json_decode.field("survey", (function (param) {
                return Json_decode.field("customer_details", decodeCustomerInfo, param);
              }), json);
}

exports.rmName = rmName;
exports.empty = empty;
exports.fromJson = fromJson;
/* No side effect */

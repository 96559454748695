// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var List = require("rescript/lib/js/list.js");
var Js_exn = require("rescript/lib/js/js_exn.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");
var Action$ReactHooksTemplate = require("./Action.bs.js");
var Welcome$ReactHooksTemplate = require("./Welcome.bs.js");
var Question$ReactHooksTemplate = require("./Question.bs.js");
var RandomId$ReactHooksTemplate = require("./RandomId.bs.js");
var LogicCondition$ReactHooksTemplate = require("./LogicCondition.bs.js");

function id(t) {
  return t.id;
}

function trigger(t) {
  return t.trigger;
}

function conditions(t) {
  return t.conditions;
}

function deletedConditions(t) {
  return t.deletedConditions;
}

function actions(t) {
  return t.actions;
}

function setTrigger(t, trigger) {
  return {
          id: t.id,
          trigger: trigger,
          priority: t.priority,
          conditions: t.conditions,
          deletedConditions: t.deletedConditions,
          actions: t.actions,
          deletedActions: t.deletedActions
        };
}

function setConditions(conditions, t) {
  return {
          id: t.id,
          trigger: t.trigger,
          priority: t.priority,
          conditions: conditions,
          deletedConditions: t.deletedConditions,
          actions: t.actions,
          deletedActions: t.deletedActions
        };
}

function setDeletedConditions(t, deletedConditions) {
  return {
          id: t.id,
          trigger: t.trigger,
          priority: t.priority,
          conditions: t.conditions,
          deletedConditions: deletedConditions,
          actions: t.actions,
          deletedActions: t.deletedActions
        };
}

function setActions(actions, t) {
  return {
          id: t.id,
          trigger: t.trigger,
          priority: t.priority,
          conditions: t.conditions,
          deletedConditions: t.deletedConditions,
          actions: actions,
          deletedActions: t.deletedActions
        };
}

function deletedActions(t) {
  return t.deletedActions;
}

function setDeletedActions(t, deletedActions) {
  return {
          id: t.id,
          trigger: t.trigger,
          priority: t.priority,
          conditions: t.conditions,
          deletedConditions: t.deletedConditions,
          actions: t.actions,
          deletedActions: deletedActions
        };
}

function make(trigger, conditions, priority, actions) {
  return {
          id: undefined,
          trigger: trigger,
          priority: priority,
          conditions: conditions,
          deletedConditions: /* [] */0,
          actions: actions,
          deletedActions: /* [] */0
        };
}

function decodeTrigger(json, questions, welcome) {
  var triggerType = Json_decode.field("trigger_type", Json_decode.string, json);
  var triggerId = Json_decode.field("trigger_id", Json_decode.$$int, json);
  switch (triggerType) {
    case "Question" :
        return {
                TAG: /* Question */1,
                _0: List.find((function (n) {
                        return Belt_Option.getExn(Question$ReactHooksTemplate.id(n)) === triggerId;
                      }), questions)
              };
    case "Welcome" :
        return {
                TAG: /* Welcome */0,
                _0: Belt_Option.getExn(welcome)
              };
    default:
      return Js_exn.raiseError("Unknown trigger type");
  }
}

function decode(json, questions, welcome, thankYouList) {
  return {
          id: Json_decode.field("id", (function (param) {
                  return Json_decode.optional(Json_decode.$$int, param);
                }), json),
          trigger: decodeTrigger(json, questions, welcome),
          priority: Json_decode.field("priority", Json_decode.$$int, json),
          conditions: Json_decode.field("conditions", (function (param) {
                  return Json_decode.list((function (json) {
                                return LogicCondition$ReactHooksTemplate.decode(json, questions);
                              }), param);
                }), json),
          deletedConditions: /* [] */0,
          actions: Json_decode.field("actions", (function (param) {
                  return Json_decode.list((function (json) {
                                return Action$ReactHooksTemplate.decode(json, questions, thankYouList);
                              }), param);
                }), json),
          deletedActions: /* [] */0
        };
}

function availableOptionsForLhs(t, questions) {
  var xs = t.trigger;
  if (xs.TAG === /* Welcome */0) {
    return /* [] */0;
  }
  var xs$1 = xs._0;
  return List.flatten(List.mapi((function (index, x) {
                    if (Caml_obj.caml_equal(xs$1, x)) {
                      return Belt_Option.getExn(Belt_List.splitAt(questions, index + 1 | 0))[0];
                    } else {
                      return /* [] */0;
                    }
                  }), questions));
}

function toJson(destroyOpt, t) {
  var destroy = destroyOpt !== undefined ? destroyOpt : false;
  var match = t.trigger;
  var triggerType;
  triggerType = match.TAG === /* Welcome */0 ? "Welcome" : "Question";
  var x = t.trigger;
  var triggerId;
  triggerId = x.TAG === /* Welcome */0 ? Welcome$ReactHooksTemplate.id(x._0) : Question$ReactHooksTemplate.id(x._0);
  var action = Belt_List.head(Belt_List.keep(t.actions, (function (x) {
              var match = x.actionType;
              if (match.TAG === /* Skip */0) {
                return false;
              } else {
                return true;
              }
            })));
  var encodedJumpAction = action !== undefined ? Action$ReactHooksTemplate.jumpActionToJson(false, action) : Action$ReactHooksTemplate.jumpActionToJson(true, Belt_List.headExn(Belt_List.keep(t.deletedActions, (function (x) {
                    var match = x.actionType;
                    if (match.TAG === /* Skip */0) {
                      return false;
                    } else {
                      return true;
                    }
                  }))));
  var encodedSkipActions = Belt_List.toArray(Belt_List.concat(Belt_List.map(Belt_List.keep(t.deletedActions, (function (x) {
                      var match = x.actionType;
                      if (match.TAG === /* Skip */0) {
                        return true;
                      } else {
                        return false;
                      }
                    })), (function (x) {
                  return Action$ReactHooksTemplate.skipActionToJson(true, x);
                })), Belt_List.map(Belt_List.keep(t.actions, (function (x) {
                      var match = x.actionType;
                      if (match.TAG === /* Skip */0) {
                        return true;
                      } else {
                        return false;
                      }
                    })), (function (x) {
                  return Action$ReactHooksTemplate.skipActionToJson(undefined, x);
                }))));
  var encodedConditions = Belt_List.toArray(Belt_List.concat(Belt_List.map(t.conditions, (function (x) {
                  return LogicCondition$ReactHooksTemplate.toJson(undefined, x);
                })), Belt_List.map(t.deletedConditions, (function (x) {
                  return LogicCondition$ReactHooksTemplate.toJson(true, x);
                }))));
  return Json_encode.object_({
              hd: [
                "id",
                Json_encode.nullable((function (prim) {
                        return prim;
                      }), t.id)
              ],
              tl: {
                hd: [
                  "priority",
                  t.priority
                ],
                tl: {
                  hd: [
                    "trigger_type",
                    triggerType
                  ],
                  tl: {
                    hd: [
                      "trigger_id",
                      Json_encode.nullable((function (prim) {
                              return prim;
                            }), triggerId)
                    ],
                    tl: {
                      hd: [
                        "jump_action_attributes",
                        encodedJumpAction
                      ],
                      tl: {
                        hd: [
                          "skip_actions_attributes",
                          encodedSkipActions
                        ],
                        tl: {
                          hd: [
                            "conditions_attributes",
                            encodedConditions
                          ],
                          tl: {
                            hd: [
                              "_destroy",
                              destroy
                            ],
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

function availableTargets(t, questions) {
  var xs = t.trigger;
  if (xs.TAG === /* Welcome */0) {
    return /* [] */0;
  }
  var xs$1 = xs._0;
  return List.flatten(List.mapi((function (index, x) {
                    if (Caml_obj.caml_equal(xs$1, x)) {
                      return Belt_Option.getExn(Belt_List.splitAt(questions, index + 1 | 0))[1];
                    } else {
                      return /* [] */0;
                    }
                  }), questions));
}

function isQuestionInTrigger(t, question) {
  var x = t.trigger;
  if (x.TAG === /* Welcome */0) {
    return false;
  } else {
    return RandomId$ReactHooksTemplate.toString(Question$ReactHooksTemplate.uuid(x._0)) === RandomId$ReactHooksTemplate.toString(Question$ReactHooksTemplate.uuid(question));
  }
}

function isQuestionInCondition(t, question) {
  return List.mem(true, Belt_List.map(t.conditions, (function (x) {
                    var x$1 = x.conditionType;
                    return RandomId$ReactHooksTemplate.toString(Question$ReactHooksTemplate.uuid(x$1._0.lhs)) === RandomId$ReactHooksTemplate.toString(Question$ReactHooksTemplate.uuid(question));
                  })));
}

function questionInLogic(t, question) {
  return Belt_List.keep(t, (function (x) {
                if (isQuestionInTrigger(x, question) || isQuestionInCondition(x, question)) {
                  return true;
                } else {
                  return List.mem(true, Belt_List.flatten(Belt_List.map(x.actions, (function (x) {
                                        var x$1 = x.actionType;
                                        if (x$1.TAG === /* Skip */0) {
                                          var x$2 = x$1._0;
                                          if (x$2.TAG === /* Questions */0) {
                                            return Belt_List.map(x$2._0, (function (x) {
                                                          return RandomId$ReactHooksTemplate.toString(Question$ReactHooksTemplate.uuid(x)) === RandomId$ReactHooksTemplate.toString(Question$ReactHooksTemplate.uuid(question));
                                                        }));
                                          } else {
                                            return /* [] */0;
                                          }
                                        }
                                        var x$3 = x$1._0;
                                        if (x$3.TAG === /* Question */0) {
                                          return {
                                                  hd: RandomId$ReactHooksTemplate.toString(Question$ReactHooksTemplate.uuid(x$3._0)) === RandomId$ReactHooksTemplate.toString(Question$ReactHooksTemplate.uuid(question)),
                                                  tl: /* [] */0
                                                };
                                        } else {
                                          return /* [] */0;
                                        }
                                      }))));
                }
              }));
}

function getThankYouAssociatedLogic(thankYou, logicList) {
  return Belt_List.keep(logicList, (function (logic) {
                return Belt_List.some(logic.actions, (function (action) {
                              var match = action.actionType;
                              if (match.TAG === /* Skip */0) {
                                var thankYouList = match._0;
                                if (thankYouList.TAG === /* Questions */0) {
                                  return false;
                                } else {
                                  return Belt_List.some(thankYouList._0, (function (t) {
                                                return Caml_obj.caml_equal(t, thankYou);
                                              }));
                                }
                              }
                              var targetThankYou = match._0;
                              if (targetThankYou.TAG === /* Question */0) {
                                return false;
                              } else {
                                return Caml_obj.caml_equal(targetThankYou._0, thankYou);
                              }
                            }));
              }));
}

exports.id = id;
exports.trigger = trigger;
exports.conditions = conditions;
exports.deletedConditions = deletedConditions;
exports.actions = actions;
exports.deletedActions = deletedActions;
exports.setDeletedActions = setDeletedActions;
exports.setTrigger = setTrigger;
exports.setConditions = setConditions;
exports.setDeletedConditions = setDeletedConditions;
exports.setActions = setActions;
exports.make = make;
exports.decode = decode;
exports.availableOptionsForLhs = availableOptionsForLhs;
exports.availableTargets = availableTargets;
exports.toJson = toJson;
exports.questionInLogic = questionInLogic;
exports.getThankYouAssociatedLogic = getThankYouAssociatedLogic;
/* Action-ReactHooksTemplate Not a pure module */

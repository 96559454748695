// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var $$Array = require("rescript/lib/js/array.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Http$ReactHooksTemplate = require("../common/http.bs.js");
var Image$ReactHooksTemplate = require("../common/image.bs.js");
var Utils$ReactHooksTemplate = require("../common/utils.bs.js");
var Report$ReactHooksTemplate = require("./report.bs.js");
var Campaign$ReactHooksTemplate = require("../surveys/campaign.bs.js");
var DataField$ReactHooksTemplate = require("../settings/dataField.bs.js");
var UserDetail$ReactHooksTemplate = require("../common/UserDetail.bs.js");
var CustomReport$ReactHooksTemplate = require("./CustomReport.bs.js");
var SurveyDetail$ReactHooksTemplate = require("../surveys/surveyDetail.bs.js");
var AggregateField$ReactHooksTemplate = require("./AggregateField.bs.js");
var ReportTemplate$ReactHooksTemplate = require("./ReportTemplate.bs.js");
var ResponseReport$ReactHooksTemplate = require("./ResponseReport.bs.js");
var AggregateReport$ReactHooksTemplate = require("./AggregateReport.bs.js");
var ResponseReportForm$ReactHooksTemplate = require("./ResponseReportForm.bs.js");
var AggregateReportForm$ReactHooksTemplate = require("./AggregateReportForm.bs.js");

function ReportCrud(Props) {
  var handleChange = Props.handleChange;
  var report = Props.report;
  var action = Props.action;
  var toggleModal = Props.toggleModal;
  var match = React.useState(function () {
        return Report$ReactHooksTemplate.empty(undefined);
      });
  var setFormReport = match[1];
  var formReport = match[0];
  var match$1 = React.useState(function () {
        return /* [] */0;
      });
  var setTimeFrames = match$1[1];
  var match$2 = React.useState(function () {
        return /* [] */0;
      });
  var setDataFields = match$2[1];
  var dataFields = match$2[0];
  var match$3 = React.useState(function () {
        return /* [] */0;
      });
  var setSurveys = match$3[1];
  var surveys = match$3[0];
  var match$4 = React.useState(function () {
        return /* [] */0;
      });
  var setCampaigns = match$4[1];
  var campaigns = match$4[0];
  var fetchDataFields = function (param) {
    Http$ReactHooksTemplate.fetchGetJSONDecode("/api/v1/data_fields", (function (param) {
              return Json_decode.field("data_fields", (function (param) {
                            return Json_decode.list(DataField$ReactHooksTemplate.decode, param);
                          }), param);
            })).then(function (dataFields) {
          return Promise.resolve(Curry._1(setDataFields, (function (param) {
                            return dataFields;
                          })));
        });
    
  };
  var fetchSurveysAndCampaigns = function (param) {
    Http$ReactHooksTemplate.fetchGetJSONDecode("/api/v1/all_surveys", (function (param) {
              return Json_decode.field("surveys", (function (param) {
                            return Json_decode.list(SurveyDetail$ReactHooksTemplate.decode, param);
                          }), param);
            })).then(function (surveys) {
          return Promise.resolve(Curry._1(setSurveys, (function (param) {
                            return surveys;
                          })));
        });
    
  };
  React.useEffect((function () {
          if (report !== undefined) {
            Curry._1(setFormReport, (function (param) {
                    return report;
                  }));
          } else {
            Curry._1(setFormReport, (function (param) {
                    return Report$ReactHooksTemplate.empty(undefined);
                  }));
          }
          Http$ReactHooksTemplate.fetchGetJSONDecode("/api/v1/reports/time_frames", (function (param) {
                    return Json_decode.field("time_frames", (function (param) {
                                  return Json_decode.list(Json_decode.string, param);
                                }), param);
                  })).then(function (timeFrames) {
                return Promise.resolve(Curry._1(setTimeFrames, (function (param) {
                                  return timeFrames;
                                })));
              });
          fetchDataFields(undefined);
          fetchSurveysAndCampaigns(undefined);
          
        }), []);
  var handleReportChange = function (field, $$event) {
    var value = Utils$ReactHooksTemplate.formTargetValue($$event);
    switch (field) {
      case /* Name */0 :
          return Curry._1(setFormReport, (function (formReport) {
                        return {
                                id: formReport.id,
                                name: value,
                                description: formReport.description,
                                reportTemplate: formReport.reportTemplate,
                                timeFrame: formReport.timeFrame,
                                creator: formReport.creator,
                                modifier: formReport.modifier
                              };
                      }));
      case /* Description */1 :
          return Curry._1(setFormReport, (function (formReport) {
                        return {
                                id: formReport.id,
                                name: formReport.name,
                                description: value,
                                reportTemplate: formReport.reportTemplate,
                                timeFrame: formReport.timeFrame,
                                creator: formReport.creator,
                                modifier: formReport.modifier
                              };
                      }));
      case /* ReportTemplate */2 :
          return Curry._1(setFormReport, (function (formReport) {
                        return {
                                id: formReport.id,
                                name: formReport.name,
                                description: formReport.description,
                                reportTemplate: ReportTemplate$ReactHooksTemplate.setTemplateFromName(value),
                                timeFrame: formReport.timeFrame,
                                creator: formReport.creator,
                                modifier: formReport.modifier
                              };
                      }));
      case /* TimeFrame */3 :
          return Curry._1(setFormReport, (function (formReport) {
                        return {
                                id: formReport.id,
                                name: formReport.name,
                                description: formReport.description,
                                reportTemplate: formReport.reportTemplate,
                                timeFrame: value,
                                creator: formReport.creator,
                                modifier: formReport.modifier
                              };
                      }));
      
    }
  };
  var handleAggregateReportChange = function (template, field, $$event) {
    var value = Utils$ReactHooksTemplate.formTargetValue($$event);
    var updateTemplate;
    switch (field) {
      case "dataField" :
          var dataField = Belt_List.getBy(dataFields, (function (dataField) {
                  return DataField$ReactHooksTemplate.name(dataField) === value;
                }));
          var aggregateField = AggregateReport$ReactHooksTemplate.aggregateFields(template);
          var aggregateField$1 = aggregateField !== undefined ? AggregateField$ReactHooksTemplate.setDataField(dataField, aggregateField) : AggregateField$ReactHooksTemplate.setDataField(dataField, AggregateField$ReactHooksTemplate.empty);
          var match = DataField$ReactHooksTemplate.dataType(Belt_Option.getExn(dataField));
          var timestampUpdatedAggregateField;
          timestampUpdatedAggregateField = match.TAG === /* DateType */3 ? AggregateField$ReactHooksTemplate.setIsTimestamp(true, aggregateField$1) : AggregateField$ReactHooksTemplate.setIsTimestamp(false, aggregateField$1);
          updateTemplate = AggregateReport$ReactHooksTemplate.setAggregateFields(timestampUpdatedAggregateField, template);
          break;
      case "metric" :
          updateTemplate = AggregateReport$ReactHooksTemplate.setMetric(AggregateReport$ReactHooksTemplate.metricFromString(value), template);
          break;
      case "timeFrame" :
          var aggregateField$2 = AggregateReport$ReactHooksTemplate.aggregateFields(template);
          var aggregateField$3 = aggregateField$2 !== undefined ? AggregateField$ReactHooksTemplate.setTimeFrame(value, aggregateField$2) : AggregateField$ReactHooksTemplate.setTimeFrame(value, AggregateField$ReactHooksTemplate.empty);
          updateTemplate = AggregateReport$ReactHooksTemplate.setAggregateFields(aggregateField$3, template);
          break;
      default:
        updateTemplate = template;
    }
    return Curry._1(setFormReport, (function (formReport) {
                  return {
                          id: formReport.id,
                          name: formReport.name,
                          description: formReport.description,
                          reportTemplate: ReportTemplate$ReactHooksTemplate.setAggregateReportTemplate(updateTemplate),
                          timeFrame: formReport.timeFrame,
                          creator: formReport.creator,
                          modifier: formReport.modifier
                        };
                }));
  };
  var handleResponseReportSurveyChange = function (template, $$event) {
    var value = Utils$ReactHooksTemplate.formTargetValue($$event);
    var survey = Belt_List.getBy(surveys, (function (survey) {
            return SurveyDetail$ReactHooksTemplate.name(survey) === value;
          }));
    var updateTemplate = ResponseReport$ReactHooksTemplate.setSurvey(survey, template);
    Http$ReactHooksTemplate.fetchGetJSONDecode("/api/v1/survey_campaigns?survey_id=" + String(SurveyDetail$ReactHooksTemplate.id(Belt_Option.getExn(survey))), (function (param) {
              return Json_decode.field("campaigns", (function (param) {
                            return Json_decode.list(Campaign$ReactHooksTemplate.decode, param);
                          }), param);
            })).then(function (campaigns) {
          return Promise.resolve(Curry._1(setCampaigns, (function (param) {
                            return campaigns;
                          })));
        });
    return Curry._1(setFormReport, (function (formReport) {
                  return {
                          id: formReport.id,
                          name: formReport.name,
                          description: formReport.description,
                          reportTemplate: ReportTemplate$ReactHooksTemplate.setResponseReportTemplate(updateTemplate),
                          timeFrame: formReport.timeFrame,
                          creator: formReport.creator,
                          modifier: formReport.modifier
                        };
                }));
  };
  var handleResponseReportCampaignChange = function (template, options) {
    var campaigns$1 = Belt_List.map(options, (function (campaign) {
            return Belt_Option.getExn(Belt_List.getBy(campaigns, (function (campaign$1) {
                              return Campaign$ReactHooksTemplate.name(campaign$1) === campaign;
                            })));
          }));
    var updateTemplate = ResponseReport$ReactHooksTemplate.setCampaigns(campaigns$1, template);
    return Curry._1(setFormReport, (function (formReport) {
                  return {
                          id: formReport.id,
                          name: formReport.name,
                          description: formReport.description,
                          reportTemplate: ReportTemplate$ReactHooksTemplate.setResponseReportTemplate(updateTemplate),
                          timeFrame: formReport.timeFrame,
                          creator: formReport.creator,
                          modifier: formReport.modifier
                        };
                }));
  };
  var tmp;
  switch (action) {
    case "create" :
        tmp = React.createElement("div", undefined, "New Report");
        break;
    case "delete" :
        tmp = React.createElement("div", undefined, "Delete Report");
        break;
    case "edit" :
        tmp = React.createElement("div", undefined, "Edit Report");
        break;
    default:
      tmp = React.createElement(React.Fragment, undefined);
  }
  var tmp$1;
  var exit = 0;
  switch (action) {
    case "delete" :
        var template = Report$ReactHooksTemplate.reportTemplate(formReport);
        var tmp$2;
        if (template !== undefined) {
          var match$5 = ReportTemplate$ReactHooksTemplate.template(template);
          switch (match$5.TAG | 0) {
            case /* AggregateReport */0 :
                tmp$2 = AggregateReport$ReactHooksTemplate.templateType;
                break;
            case /* CustomReport */1 :
                tmp$2 = CustomReport$ReactHooksTemplate.templateType;
                break;
            case /* ResponseReport */2 :
                tmp$2 = ResponseReport$ReactHooksTemplate.templateType;
                break;
            
          }
        } else {
          tmp$2 = "Select Template";
        }
        var user = Report$ReactHooksTemplate.creator(formReport);
        tmp$1 = React.createElement("div", undefined, React.createElement("div", {
                  className: "flex mt-30-px mb-30-px"
                }, React.createElement("div", {
                      className: "flex gap-1 items-center w-full"
                    }, React.createElement("div", {
                          className: "text-sm font-medium w-1/5"
                        }, "Name"), React.createElement("input", {
                          className: "outline-none border border-gray-400 h-10 w-3/5 rounded px-3 py-2 text-sm",
                          disabled: true,
                          type: "text",
                          value: Report$ReactHooksTemplate.name(formReport)
                        }))), React.createElement("div", {
                  className: "flex mb-30-px"
                }, React.createElement("div", {
                      className: "flex gap-1 items-center w-full"
                    }, React.createElement("div", {
                          className: "text-sm font-medium w-1/5"
                        }, "Description"), React.createElement("input", {
                          className: "outline-none border border-gray-400 h-10 w-3/5 rounded px-3 py-2 text-sm",
                          disabled: true,
                          type: "text",
                          value: Report$ReactHooksTemplate.description(formReport)
                        }))), React.createElement("div", {
                  className: "flex mb-30-px"
                }, React.createElement("div", {
                      className: "flex gap-1 items-center w-full"
                    }, React.createElement("div", {
                          className: "text-sm font-medium w-1/5"
                        }, "Report Template"), React.createElement("input", {
                          className: "outline-none border border-gray-400 h-10 w-3/5 rounded px-3 py-2 text-sm",
                          disabled: true,
                          type: "text",
                          value: tmp$2
                        }))), React.createElement("div", {
                  className: "flex mb-30-px"
                }, React.createElement("div", {
                      className: "flex gap-1 items-center w-full"
                    }, React.createElement("div", {
                          className: "text-sm font-medium w-1/5"
                        }, "Time Frame"), React.createElement("input", {
                          className: "outline-none border border-gray-400 h-10 w-3/5 rounded px-3 py-2 text-sm capitalize",
                          disabled: true,
                          type: "text",
                          value: Utils$ReactHooksTemplate.humanize(Report$ReactHooksTemplate.timeFrame(formReport))
                        }))), React.createElement("div", {
                  className: "flex mb-30-px"
                }, React.createElement("div", {
                      className: "flex gap-1 items-center w-full"
                    }, React.createElement("div", {
                          className: "text-sm font-medium w-1/5"
                        }, "Created By"), React.createElement("input", {
                          className: "outline-none border border-gray-400 h-10 w-3/5 rounded px-3 py-2 text-sm",
                          disabled: true,
                          type: "text",
                          value: user !== undefined ? UserDetail$ReactHooksTemplate.email(user) : ""
                        }))));
        break;
    case "create" :
    case "edit" :
        exit = 1;
        break;
    default:
      tmp$1 = React.createElement(React.Fragment, undefined);
  }
  if (exit === 1) {
    var tmp$3;
    if (action === "edit") {
      var template$1 = Report$ReactHooksTemplate.reportTemplate(formReport);
      var tmp$4;
      if (template$1 !== undefined) {
        var match$6 = ReportTemplate$ReactHooksTemplate.template(template$1);
        switch (match$6.TAG | 0) {
          case /* AggregateReport */0 :
              tmp$4 = AggregateReport$ReactHooksTemplate.templateType;
              break;
          case /* CustomReport */1 :
              tmp$4 = CustomReport$ReactHooksTemplate.templateType;
              break;
          case /* ResponseReport */2 :
              tmp$4 = ResponseReport$ReactHooksTemplate.templateType;
              break;
          
        }
      } else {
        tmp$4 = "Select Template";
      }
      tmp$3 = React.createElement("input", {
            className: "outline-none border border-gray-400 h-10 w-3/5 rounded px-3 py-2 text-sm",
            disabled: true,
            type: "text",
            value: tmp$4
          });
    } else {
      var template$2 = Report$ReactHooksTemplate.reportTemplate(formReport);
      var tmp$5;
      if (template$2 !== undefined) {
        var match$7 = ReportTemplate$ReactHooksTemplate.template(template$2);
        switch (match$7.TAG | 0) {
          case /* AggregateReport */0 :
              tmp$5 = AggregateReport$ReactHooksTemplate.templateType;
              break;
          case /* CustomReport */1 :
              tmp$5 = CustomReport$ReactHooksTemplate.templateType;
              break;
          case /* ResponseReport */2 :
              tmp$5 = ResponseReport$ReactHooksTemplate.templateType;
              break;
          
        }
      } else {
        tmp$5 = "Select Template";
      }
      tmp$3 = React.createElement("select", {
            className: "block border px-3 py-2 border-gray-400 cursor-pointer\n                                    w-3/5 bg-white rounded capitalize mr-6 text-sm",
            placeholder: "Select Template",
            value: tmp$5,
            onChange: (function (param) {
                return handleReportChange(/* ReportTemplate */2, param);
              })
          }, React.createElement("option", {
                key: "0",
                disabled: true,
                value: "Select Template"
              }, "Select Template"), $$Array.of_list(Belt_List.map(ReportTemplate$ReactHooksTemplate.templateList, (function (templateName) {
                      return React.createElement("option", {
                                  key: templateName,
                                  disabled: templateName === CustomReport$ReactHooksTemplate.templateType,
                                  value: templateName
                                }, templateName);
                    }))));
    }
    var reportTemplate = Report$ReactHooksTemplate.reportTemplate(formReport);
    var tmp$6;
    if (reportTemplate !== undefined) {
      var template$3 = ReportTemplate$ReactHooksTemplate.template(reportTemplate);
      switch (template$3.TAG | 0) {
        case /* AggregateReport */0 :
            tmp$6 = React.createElement(AggregateReportForm$ReactHooksTemplate.make, {
                  template: template$3._0,
                  handleAggregateReportChange: handleAggregateReportChange,
                  dataFields: dataFields
                });
            break;
        case /* CustomReport */1 :
            tmp$6 = React.createElement(React.Fragment, undefined);
            break;
        case /* ResponseReport */2 :
            tmp$6 = React.createElement(ResponseReportForm$ReactHooksTemplate.make, {
                  template: template$3._0,
                  surveys: surveys,
                  campaigns: campaigns,
                  handleResponseReportSurveyChange: handleResponseReportSurveyChange,
                  handleResponseReportCampaignChange: handleResponseReportCampaignChange
                });
            break;
        
      }
    } else {
      tmp$6 = React.createElement(React.Fragment, undefined);
    }
    tmp$1 = React.createElement("div", undefined, React.createElement("div", {
              className: "flex mb-30-px items-center"
            }, React.createElement("div", {
                  className: "flex gap-1 items-center w-full"
                }, React.createElement("div", {
                      className: "text-sm font-medium w-1/5"
                    }, "Name"), React.createElement("input", {
                      className: "outline-none border border-gray-400 h-10 w-3/5 rounded px-3 py-2 text-sm",
                      type: "text",
                      value: Report$ReactHooksTemplate.name(formReport),
                      onChange: (function (param) {
                          return handleReportChange(/* Name */0, param);
                        })
                    }))), React.createElement("div", {
              className: "flex mb-30-px items-center"
            }, React.createElement("div", {
                  className: "flex gap-1 items-center w-full"
                }, React.createElement("div", {
                      className: "text-sm font-medium w-1/5"
                    }, "Description"), React.createElement("input", {
                      className: "outline-none border border-gray-400 h-10 w-3/5 rounded px-3 py-2 text-sm",
                      type: "text",
                      value: Report$ReactHooksTemplate.description(formReport),
                      onChange: (function (param) {
                          return handleReportChange(/* Description */1, param);
                        })
                    }))), React.createElement("div", {
              className: "flex mb-30-px items-center"
            }, React.createElement("div", {
                  className: "flex gap-1 items-center w-full"
                }, React.createElement("div", {
                      className: "text-sm font-medium w-1/5"
                    }, "Time Range"), React.createElement("select", {
                      className: "block border px-3 py-2 border-gray-400\n                             w-3/5 bg-white rounded capitalize mr-6 text-sm cursor-pointer",
                      placeholder: "Select Time Range",
                      value: Report$ReactHooksTemplate.timeFrame(formReport) === "" ? "Select Time Range" : Report$ReactHooksTemplate.timeFrame(formReport),
                      onChange: (function (param) {
                          return handleReportChange(/* TimeFrame */3, param);
                        })
                    }, React.createElement("option", {
                          key: "0",
                          disabled: true,
                          value: "Select Time Range"
                        }, "Select Time Range"), $$Array.of_list(Belt_List.map(match$1[0], (function (timeFrame) {
                                return React.createElement("option", {
                                            key: timeFrame,
                                            value: timeFrame
                                          }, Utils$ReactHooksTemplate.humanize(timeFrame));
                              })))))), React.createElement("div", {
              className: "flex mb-30-px items-center"
            }, React.createElement("div", {
                  className: "flex gap-1 items-center w-full"
                }, React.createElement("div", {
                      className: "text-sm font-medium w-1/5"
                    }, "Report Template"), tmp$3)), tmp$6);
  }
  var tmp$7 = action === "delete" ? "Delete" : "Save";
  return React.createElement("div", {
              className: "bg-white modal-campaign z-10 shadow relative border border-gray-300 pointer-events-auto"
            }, React.createElement("div", {
                  className: "flex flex-col justify-between h-full"
                }, React.createElement("div", {
                      className: "p-8 text-lg font-bold flex justify-between border-b border-gray-200"
                    }, tmp, React.createElement("img", {
                          className: "h-14-px float-right cursor-pointer",
                          src: Image$ReactHooksTemplate.closeIcon,
                          onClick: toggleModal
                        })), React.createElement("div", {
                      className: "h-full text-base p-8 overflow-auto"
                    }, tmp$1), React.createElement("div", {
                      className: "p-8 flex gap-3 justify-end border-t border-gray-200"
                    }, React.createElement("button", {
                          className: "bg-ghostwhite border border-gray-400\n          flex rounded py-3 px-6 text-xs",
                          id: "close_modal",
                          onClick: toggleModal
                        }, "Discard"), React.createElement("input", {
                          className: "border border-gray-400\n            flex rounded py-3 px-6 text-xs text-white " + (
                            action === "delete" ? "bg-deepcarminepink" : "bg-palatinateblue"
                          ),
                          type: "submit",
                          value: tmp$7,
                          onClick: (function (param) {
                              return Curry._2(handleChange, formReport, action);
                            })
                        }))));
}

var make = ReportCrud;

exports.make = make;
/* react Not a pure module */

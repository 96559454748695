// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");

function make(id, resourceType) {
  return {
          id: id,
          resourceType: resourceType
        };
}

function resourceType(t) {
  return t.resourceType;
}

function resourceTypeString(t) {
  var match = t.resourceType;
  if (match.TAG === /* Role */0) {
    return "Role";
  } else {
    return "User";
  }
}

function id(t) {
  return t.id;
}

function decodeRole(json) {
  return {
          id: Json_decode.field("id", Json_decode.$$int, json),
          name: Json_decode.field("name", Json_decode.string, json)
        };
}

function decodeUser(json) {
  return {
          id: Json_decode.field("id", Json_decode.$$int, json),
          email: Json_decode.field("email", Json_decode.string, json)
        };
}

function decode(json) {
  var resourceType = Json_decode.field("shareable_type", Json_decode.string, json);
  var id = Json_decode.optional((function (param) {
          return Json_decode.field("id", Json_decode.$$int, param);
        }), json);
  if (resourceType === "Role") {
    return {
            id: id,
            resourceType: {
              TAG: /* Role */0,
              _0: {
                id: Json_decode.field("shareable", (function (param) {
                        return Json_decode.field("id", Json_decode.$$int, param);
                      }), json),
                name: Json_decode.field("shareable", (function (param) {
                        return Json_decode.field("name", Json_decode.string, param);
                      }), json)
              }
            }
          };
  } else {
    return {
            id: id,
            resourceType: {
              TAG: /* User */1,
              _0: {
                id: Json_decode.field("shareable", (function (param) {
                        return Json_decode.field("id", Json_decode.$$int, param);
                      }), json),
                email: Json_decode.field("shareable", (function (param) {
                        return Json_decode.field("email", Json_decode.string, param);
                      }), json)
              }
            }
          };
  }
}

function encode(destroyOpt, t) {
  var destroy = destroyOpt !== undefined ? destroyOpt : false;
  var role = t.resourceType;
  var encodeArray_0 = [
    "shareable_type",
    resourceTypeString(t)
  ];
  var encodeArray_1 = {
    hd: [
      "shareable_id",
      role._0.id
    ],
    tl: {
      hd: [
        "id",
        Json_encode.nullable((function (prim) {
                return prim;
              }), t.id)
      ],
      tl: /* [] */0
    }
  };
  var encodeArray = {
    hd: encodeArray_0,
    tl: encodeArray_1
  };
  return Json_encode.object_(destroy ? ({
                  hd: [
                    "_destroy",
                    destroy
                  ],
                  tl: encodeArray
                }) : encodeArray);
}

exports.make = make;
exports.resourceType = resourceType;
exports.resourceTypeString = resourceTypeString;
exports.id = id;
exports.decodeRole = decodeRole;
exports.decodeUser = decodeUser;
exports.decode = decode;
exports.encode = encode;
/* No side effect */

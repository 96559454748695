// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var List = require("rescript/lib/js/list.js");
var Curry = require("rescript/lib/js/curry.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var CheckboxStatus$ReactHooksTemplate = require("./checkboxStatus.bs.js");

function getPermissionCategoryName(x) {
  switch (x) {
    case /* ShowDashboard */0 :
        return "show_dashboard";
    case /* CreateTicket */1 :
        return "create_ticket";
    case /* ManageTickets */2 :
        return "manage_ticket";
    case /* ResolveTickets */3 :
        return "resolve_ticket";
    case /* AssignTickets */4 :
        return "assign_tickets";
    case /* SelfAssignTickets */5 :
        return "self_assign_tickets";
    case /* BuildSurvey */6 :
        return "build_survey";
    case /* DeleteSurvey */7 :
        return "delete_survey";
    case /* ShowSurvey */8 :
        return "show_survey";
    case /* AddUser */9 :
        return "add_user";
    case /* DeleteUser */10 :
        return "delete_user";
    case /* UpdateUser */11 :
        return "update_user";
    case /* ShowUser */12 :
        return "show_user";
    case /* AddRole */13 :
        return "add_role";
    case /* DeleteRole */14 :
        return "delete_role";
    case /* UpdateRole */15 :
        return "update_role";
    case /* ShowRole */16 :
        return "show_role";
    case /* ShowCampaign */17 :
        return "show_campaign";
    case /* CreateCampaign */18 :
        return "create_campaign";
    case /* ShowQuickSurvey */19 :
        return "show_quick_survey";
    case /* ShowDistribution */20 :
        return "show_distribution";
    case /* CreateDistribution */21 :
        return "create_distribution";
    case /* ShowReport */22 :
        return "show_report";
    case /* EditTextCategories */23 :
        return "edit_text_categories";
    case /* ShowKpi */24 :
        return "show_kpi";
    case /* AddKpi */25 :
        return "add_kpi";
    case /* UpdateKpi */26 :
        return "update_kpi";
    case /* DeleteKpi */27 :
        return "delete_kpi";
    
  }
}

function status(t) {
  if (t.TAG === /* Capability */0) {
    return CheckboxStatus$ReactHooksTemplate.fromBool(t._1);
  } else {
    return CheckboxStatus$ReactHooksTemplate.reduce(Belt_List.map(t._1, status));
  }
}

function postOrder(onCapability, onGroup, tree) {
  if (tree.TAG === /* Capability */0) {
    return Curry._2(onCapability, tree._0, CheckboxStatus$ReactHooksTemplate.fromBool(tree._1));
  } else {
    return Curry._3(onGroup, tree._0, status(tree), Belt_List.map(tree._1, (function (param) {
                      return postOrder(onCapability, onGroup, param);
                    })));
  }
}

function enabledCapabilities(xs, tree) {
  if (tree.TAG === /* Capability */0) {
    if (tree._1) {
      return {
              hd: tree._0,
              tl: xs
            };
    } else {
      return xs;
    }
  } else {
    return List.fold_left(enabledCapabilities, xs, tree._1);
  }
}

function disabledCapabilities(xs, tree) {
  if (tree.TAG === /* Capability */0) {
    if (tree._1) {
      return xs;
    } else {
      return {
              hd: tree._0,
              tl: xs
            };
    }
  } else {
    return List.fold_left(disabledCapabilities, xs, tree._1);
  }
}

function hasCapability(permissionCategory, treeList) {
  var checkCapabilityList = List.filter(function (name) {
          return name === getPermissionCategoryName(permissionCategory);
        })(List.fold_left(enabledCapabilities, /* [] */0, treeList));
  return Belt_List.head(checkCapabilityList) !== undefined;
}

function setEnabled(x, t) {
  if (t.TAG === /* Capability */0) {
    return {
            TAG: /* Capability */0,
            _0: t._0,
            _1: x
          };
  } else {
    return {
            TAG: /* Group */1,
            _0: t._0,
            _1: Belt_List.map(t._1, (function (param) {
                    return setEnabled(x, param);
                  }))
          };
  }
}

function toggle(x, t) {
  if (t.TAG === /* Capability */0) {
    var capability = t._0;
    if (x === capability) {
      return {
              TAG: /* Capability */0,
              _0: capability,
              _1: !t._1
            };
    } else {
      return t;
    }
  }
  var children = t._1;
  var group = t._0;
  if (x !== group) {
    return {
            TAG: /* Group */1,
            _0: group,
            _1: Belt_List.map(children, (function (param) {
                    return toggle(x, param);
                  }))
          };
  }
  var checked = CheckboxStatus$ReactHooksTemplate.toBool(status(t));
  var partial_arg = !checked;
  return {
          TAG: /* Group */1,
          _0: group,
          _1: Belt_List.map(children, (function (param) {
                  return setEnabled(partial_arg, param);
                }))
        };
}

function decodeCapability(json) {
  var capability = Json_decode.field("capability", Json_decode.string, json);
  var enabled = Json_decode.field("enabled", Json_decode.bool, json);
  return {
          TAG: /* Capability */0,
          _0: capability,
          _1: enabled
        };
}

function decodeGroup(json) {
  var group = Json_decode.field("group", Json_decode.string, json);
  var children = Json_decode.field("children", (function (param) {
          return Json_decode.list(decodeChild, param);
        }), json);
  return {
          TAG: /* Group */1,
          _0: group,
          _1: children
        };
}

function decodeChild(json) {
  return Json_decode.either(decodeGroup, decodeCapability)(json);
}

function decode(json) {
  return Json_decode.field("permissions", (function (param) {
                return Json_decode.list(decodeChild, param);
              }), json);
}

function emptyPermission(param) {
  return /* [] */0;
}

exports.postOrder = postOrder;
exports.enabledCapabilities = enabledCapabilities;
exports.disabledCapabilities = disabledCapabilities;
exports.status = status;
exports.toggle = toggle;
exports.decode = decode;
exports.emptyPermission = emptyPermission;
exports.hasCapability = hasCapability;
/* No side effect */

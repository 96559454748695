// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var List = require("rescript/lib/js/list.js");
var $$Array = require("rescript/lib/js/array.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Image$ReactHooksTemplate = require("../../common/image.bs.js");
var Dashboard$ReactHooksTemplate = require("../dashboard/dashboard.bs.js");

function BreadCrumbsComponent(Props) {
  var breadCrumbs = Props.breadCrumbs;
  var breadCrumbsOnClick = Props.breadCrumbsOnClick;
  if (List.length(breadCrumbs) > 1) {
    return React.createElement("div", {
                className: "flex"
              }, $$Array.of_list(List.mapi((function (i, item) {
                          if (i < (List.length(breadCrumbs) - 1 | 0)) {
                            return React.createElement("button", {
                                        className: "mr-4",
                                        onClick: (function (param) {
                                            return Curry._2(breadCrumbsOnClick, item, i + 1 | 0);
                                          })
                                      }, React.createElement("img", {
                                            src: Image$ReactHooksTemplate.backArrow
                                          }));
                          } else {
                            return React.createElement(React.Fragment, undefined);
                          }
                        }), breadCrumbs)), $$Array.of_list(List.mapi((function (i, item) {
                          var dashboardName = Dashboard$ReactHooksTemplate.name(item);
                          if (i < (List.length(breadCrumbs) - 1 | 0)) {
                            return React.createElement("span", {
                                        key: String(i),
                                        className: "py-2"
                                      }, React.createElement("button", {
                                            className: "text-aurometalsaurus text-16-px font-semibold",
                                            onClick: (function (param) {
                                                return Curry._2(breadCrumbsOnClick, item, i + 1 | 0);
                                              })
                                          }, dashboardName), React.createElement("span", {
                                            className: "ml-2 mr-3"
                                          }, " > "));
                          } else {
                            return React.createElement("span", {
                                        key: String(i),
                                        className: "text-16-px text-charcoal font-semibold py-2"
                                      }, dashboardName);
                          }
                        }), breadCrumbs)));
  } else {
    return React.createElement(React.Fragment, undefined);
  }
}

var make = BreadCrumbsComponent;

exports.make = make;
/* react Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Moment = require("moment");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var TicketPark$ReactHooksTemplate = require("./ticketPark.bs.js");
var TicketClose$ReactHooksTemplate = require("./ticketClose.bs.js");
var TicketAssign$ReactHooksTemplate = require("./ticketAssign.bs.js");
var TicketReopen$ReactHooksTemplate = require("./ticketReopen.bs.js");
var TicketComment$ReactHooksTemplate = require("./ticketComment.bs.js");
var TicketResolve$ReactHooksTemplate = require("./ticketResolve.bs.js");

function id(t) {
  return t.id;
}

function categoryType(t) {
  return t.categoryType;
}

function createdAt(t) {
  return t.createdAt;
}

function activityDetails(t) {
  return t.activityDetails;
}

function decode(json) {
  var activityType = Json_decode.field("activity", Json_decode.string, json);
  if (activityType === TicketClose$ReactHooksTemplate.activityType) {
    return {
            id: Json_decode.field("id", Json_decode.$$int, json),
            categoryType: activityType,
            ticketId: Json_decode.field("ticket_id", Json_decode.$$int, json),
            createdAt: Moment(new Date(Json_decode.field("created_at", Json_decode.string, json))),
            activityDetails: {
              TAG: /* TicketClose */0,
              _0: TicketClose$ReactHooksTemplate.fromJson(json)
            }
          };
  } else if (activityType === TicketPark$ReactHooksTemplate.activityType) {
    return {
            id: Json_decode.field("id", Json_decode.$$int, json),
            categoryType: activityType,
            ticketId: Json_decode.field("ticket_id", Json_decode.$$int, json),
            createdAt: Moment(new Date(Json_decode.field("created_at", Json_decode.string, json))),
            activityDetails: {
              TAG: /* TicketPark */3,
              _0: TicketPark$ReactHooksTemplate.fromJson(json)
            }
          };
  } else if (activityType === TicketAssign$ReactHooksTemplate.activityType) {
    return {
            id: Json_decode.field("id", Json_decode.$$int, json),
            categoryType: activityType,
            ticketId: Json_decode.field("ticket_id", Json_decode.$$int, json),
            createdAt: Moment(new Date(Json_decode.field("created_at", Json_decode.string, json))),
            activityDetails: {
              TAG: /* TicketAssign */1,
              _0: TicketAssign$ReactHooksTemplate.fromJson(json)
            }
          };
  } else if (activityType === TicketClose$ReactHooksTemplate.activityType) {
    return {
            id: Json_decode.field("id", Json_decode.$$int, json),
            categoryType: activityType,
            ticketId: Json_decode.field("ticket_id", Json_decode.$$int, json),
            createdAt: Moment(new Date(Json_decode.field("created_at", Json_decode.string, json))),
            activityDetails: {
              TAG: /* TicketClose */0,
              _0: TicketClose$ReactHooksTemplate.fromJson(json)
            }
          };
  } else if (activityType === TicketResolve$ReactHooksTemplate.activityType) {
    return {
            id: Json_decode.field("id", Json_decode.$$int, json),
            categoryType: activityType,
            ticketId: Json_decode.field("ticket_id", Json_decode.$$int, json),
            createdAt: Moment(new Date(Json_decode.field("created_at", Json_decode.string, json))),
            activityDetails: {
              TAG: /* TicketResolve */2,
              _0: TicketResolve$ReactHooksTemplate.fromJson(json)
            }
          };
  } else if (activityType === TicketReopen$ReactHooksTemplate.activityType) {
    return {
            id: Json_decode.field("id", Json_decode.$$int, json),
            categoryType: activityType,
            ticketId: Json_decode.field("ticket_id", Json_decode.$$int, json),
            createdAt: Moment(new Date(Json_decode.field("created_at", Json_decode.string, json))),
            activityDetails: {
              TAG: /* TicketReopen */5,
              _0: TicketReopen$ReactHooksTemplate.fromJson(json)
            }
          };
  } else {
    return {
            id: Json_decode.field("id", Json_decode.$$int, json),
            categoryType: activityType,
            ticketId: Json_decode.field("ticket_id", Json_decode.$$int, json),
            createdAt: Moment(new Date(Json_decode.field("created_at", Json_decode.string, json))),
            activityDetails: {
              TAG: /* TicketComment */4,
              _0: TicketComment$ReactHooksTemplate.fromJson(json)
            }
          };
  }
}

exports.id = id;
exports.categoryType = categoryType;
exports.createdAt = createdAt;
exports.activityDetails = activityDetails;
exports.decode = decode;
/* moment Not a pure module */

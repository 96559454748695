// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var $$Array = require("rescript/lib/js/array.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Utils$ReactHooksTemplate = require("../common/utils.bs.js");
var TextInputNew$ReactHooksTemplate = require("../common/TextInputNew.bs.js");
var StringCondition$ReactHooksTemplate = require("./StringCondition.bs.js");

function StringConditionForm(Props) {
  var stringCondition = Props.stringCondition;
  var updateCondition = Props.updateCondition;
  var operator = StringCondition$ReactHooksTemplate.operator(stringCondition);
  var rhs = StringCondition$ReactHooksTemplate.rhs(stringCondition);
  var availableOperators = StringCondition$ReactHooksTemplate.availableOperators(undefined);
  var handleOperatorChange = function ($$event) {
    var selectedOperator = Utils$ReactHooksTemplate.formTargetValue($$event);
    var updatedStringCondition = StringCondition$ReactHooksTemplate.setOperator(stringCondition, selectedOperator);
    return Curry._1(updateCondition, {
                TAG: /* StringCondition */1,
                _0: updatedStringCondition
              });
  };
  var handleRhsChange = function ($$event) {
    var inputValue = Utils$ReactHooksTemplate.formTargetValue($$event);
    var maxLength = StringCondition$ReactHooksTemplate.maximumLength(StringCondition$ReactHooksTemplate.lhs(stringCondition));
    if (inputValue.length > maxLength) {
      return ;
    }
    var updatedStringCondition = StringCondition$ReactHooksTemplate.setRhs(stringCondition, inputValue);
    return Curry._1(updateCondition, {
                TAG: /* StringCondition */1,
                _0: updatedStringCondition
              });
  };
  return React.createElement("div", {
              className: "flex items-center gap-2"
            }, React.createElement("div", {
                  className: "flex flex-col"
                }, React.createElement("div", {
                      className: "w-48-px h-20-px font-normal text-xs text-aurometalsaurus "
                    }, "Condition"), React.createElement("select", {
                      className: "h-40-px py-2 px-1 rounded bg-white border border-gray-300\n                   cursor-pointer focus:border-gray-600 text-xs",
                      placeholder: "Select Operator",
                      value: operator,
                      onChange: handleOperatorChange
                    }, $$Array.of_list(Belt_List.map(availableOperators, (function (operator) {
                                return React.createElement("option", {
                                            key: operator,
                                            className: "text-left bg-white-300 px-2 py-1 hover:bg-gray-300",
                                            value: operator
                                          }, StringCondition$ReactHooksTemplate.humanizeOperator(operator));
                              }))))), React.createElement("div", {
                  className: "flex flex-col"
                }, React.createElement("div", {
                      className: "h-20-px font-normal text-xs text-aurometalsaurus "
                    }, "Answer"), React.createElement(TextInputNew$ReactHooksTemplate.make, {
                      label: "",
                      value: rhs,
                      handleChange: handleRhsChange,
                      maxLength: StringCondition$ReactHooksTemplate.maximumLength(StringCondition$ReactHooksTemplate.lhs(stringCondition)),
                      placeholder: "Enter Answer",
                      textInputClassName: "h-40-px py-2 px-1 rounded bg-white border border-gray-300 \n                            cursor-pointer focus:border-gray-600 text-xs"
                    })));
}

var make = StringConditionForm;

exports.make = make;
/* react Not a pure module */

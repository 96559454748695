// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var List = require("rescript/lib/js/list.js");
var $$Array = require("rescript/lib/js/array.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var Http$ReactHooksTemplate = require("../common/http.bs.js");
var Role$ReactHooksTemplate = require("./role.bs.js");
var User$ReactHooksTemplate = require("./user.bs.js");
var Utils$ReactHooksTemplate = require("../common/utils.bs.js");
var Domain$ReactHooksTemplate = require("./domain.bs.js");
var ListType$ReactHooksTemplate = require("./listType.bs.js");
var DataField$ReactHooksTemplate = require("./dataField.bs.js");
var SelectBox$ReactHooksTemplate = require("../common/SelectBox.bs.js");
var EmailInput$ReactHooksTemplate = require("../common/EmailInput.bs.js");
var TextInputNew$ReactHooksTemplate = require("../common/TextInputNew.bs.js");
var CheckboxInput$ReactHooksTemplate = require("../common/CheckboxInput.bs.js");
var DataRestriction$ReactHooksTemplate = require("./dataRestriction.bs.js");
var NewPasswordInput$ReactHooksTemplate = require("../common/NewPasswordInput.bs.js");
var MultipleSelectBox$ReactHooksTemplate = require("../common/MultipleSelectBox.bs.js");

function create(enabledOpt, role) {
  var enabled = enabledOpt !== undefined ? enabledOpt : false;
  return {
          role: role,
          enabled: enabled
        };
}

function role(t) {
  return t.role;
}

function roleId(t) {
  return Role$ReactHooksTemplate.id(t.role);
}

function enabled(t) {
  return t.enabled;
}

function toggleStatus(t) {
  return {
          role: t.role,
          enabled: !t.enabled
        };
}

var RoleStatus = {
  create: create,
  role: role,
  roleId: roleId,
  enabled: enabled,
  toggleStatus: toggleStatus
};

function create$1(idOpt, name, dataFieldOpt, _destroyOpt, showOptionsOpt, operator, value) {
  var id = idOpt !== undefined ? Caml_option.valFromOption(idOpt) : undefined;
  var dataField = dataFieldOpt !== undefined ? Caml_option.valFromOption(dataFieldOpt) : undefined;
  var _destroy = _destroyOpt !== undefined ? _destroyOpt : false;
  var showOptions = showOptionsOpt !== undefined ? showOptionsOpt : false;
  return {
          id: id,
          name: name,
          dataField: dataField,
          operator: operator,
          value: value,
          _destroy: _destroy,
          showOptions: showOptions
        };
}

var empty = {
  id: undefined,
  name: "Select Field",
  dataField: undefined,
  operator: "in",
  value: /* [] */0,
  _destroy: false,
  showOptions: false
};

function id(t) {
  return t.id;
}

function value(t) {
  return t.value;
}

function name(t) {
  return t.name;
}

function dataField(t) {
  return t.dataField;
}

function operator(t) {
  return t.operator;
}

function _destroy(t) {
  return t._destroy;
}

function showOptions(t) {
  return t.showOptions;
}

var DataRestrictionCondition = {
  create: create$1,
  empty: empty,
  id: id,
  value: value,
  name: name,
  dataField: dataField,
  operator: operator,
  _destroy: _destroy,
  showOptions: showOptions
};

function reducer(state, action) {
  if (!state) {
    if (typeof action === "number" || action.TAG !== /* ShowAddNewUserForm */0) {
      return state;
    } else {
      return /* Available */{
              _0: action._0
            };
    }
  }
  var formView = state._0;
  if (typeof action === "number") {
    if (formView.TAG === /* NewUser */0) {
      var userForm = formView._0;
      return /* Available */{
              _0: {
                TAG: /* NewUser */0,
                _0: {
                  id: userForm.id,
                  email: userForm.email,
                  emailAvailable: userForm.emailAvailable,
                  firstName: userForm.firstName,
                  lastName: userForm.lastName,
                  username: userForm.username,
                  usernameAvailable: userForm.usernameAvailable,
                  activeStatus: !userForm.activeStatus,
                  roleStatuses: userForm.roleStatuses,
                  dataRestrictionConditions: userForm.dataRestrictionConditions,
                  dataFields: userForm.dataFields
                },
                _1: formView._1
              }
            };
    }
    var userForm$1 = formView._0;
    return /* Available */{
            _0: {
              TAG: /* EditUser */1,
              _0: {
                id: userForm$1.id,
                email: userForm$1.email,
                emailAvailable: userForm$1.emailAvailable,
                firstName: userForm$1.firstName,
                lastName: userForm$1.lastName,
                username: userForm$1.username,
                usernameAvailable: userForm$1.usernameAvailable,
                activeStatus: !userForm$1.activeStatus,
                roleStatuses: userForm$1.roleStatuses,
                dataRestrictionConditions: userForm$1.dataRestrictionConditions,
                dataFields: userForm$1.dataFields
              }
            }
          };
  }
  switch (action.TAG | 0) {
    case /* ShowAddNewUserForm */0 :
        return /* Available */{
                _0: action._0
              };
    case /* ChangeEmail */1 :
        var email = action._0;
        if (formView.TAG === /* NewUser */0) {
          var userForm$2 = formView._0;
          return /* Available */{
                  _0: {
                    TAG: /* NewUser */0,
                    _0: {
                      id: userForm$2.id,
                      email: email,
                      emailAvailable: userForm$2.emailAvailable,
                      firstName: userForm$2.firstName,
                      lastName: userForm$2.lastName,
                      username: userForm$2.username,
                      usernameAvailable: userForm$2.usernameAvailable,
                      activeStatus: userForm$2.activeStatus,
                      roleStatuses: userForm$2.roleStatuses,
                      dataRestrictionConditions: userForm$2.dataRestrictionConditions,
                      dataFields: userForm$2.dataFields
                    },
                    _1: formView._1
                  }
                };
        }
        var userForm$3 = formView._0;
        return /* Available */{
                _0: {
                  TAG: /* EditUser */1,
                  _0: {
                    id: userForm$3.id,
                    email: email,
                    emailAvailable: userForm$3.emailAvailable,
                    firstName: userForm$3.firstName,
                    lastName: userForm$3.lastName,
                    username: userForm$3.username,
                    usernameAvailable: userForm$3.usernameAvailable,
                    activeStatus: userForm$3.activeStatus,
                    roleStatuses: userForm$3.roleStatuses,
                    dataRestrictionConditions: userForm$3.dataRestrictionConditions,
                    dataFields: userForm$3.dataFields
                  }
                }
              };
    case /* ChangeEmailAvailability */2 :
        var emailAvailable = action._0;
        if (formView.TAG === /* NewUser */0) {
          var userForm$4 = formView._0;
          return /* Available */{
                  _0: {
                    TAG: /* NewUser */0,
                    _0: {
                      id: userForm$4.id,
                      email: userForm$4.email,
                      emailAvailable: emailAvailable,
                      firstName: userForm$4.firstName,
                      lastName: userForm$4.lastName,
                      username: userForm$4.username,
                      usernameAvailable: userForm$4.usernameAvailable,
                      activeStatus: userForm$4.activeStatus,
                      roleStatuses: userForm$4.roleStatuses,
                      dataRestrictionConditions: userForm$4.dataRestrictionConditions,
                      dataFields: userForm$4.dataFields
                    },
                    _1: formView._1
                  }
                };
        }
        var userForm$5 = formView._0;
        return /* Available */{
                _0: {
                  TAG: /* EditUser */1,
                  _0: {
                    id: userForm$5.id,
                    email: userForm$5.email,
                    emailAvailable: emailAvailable,
                    firstName: userForm$5.firstName,
                    lastName: userForm$5.lastName,
                    username: userForm$5.username,
                    usernameAvailable: userForm$5.usernameAvailable,
                    activeStatus: userForm$5.activeStatus,
                    roleStatuses: userForm$5.roleStatuses,
                    dataRestrictionConditions: userForm$5.dataRestrictionConditions,
                    dataFields: userForm$5.dataFields
                  }
                }
              };
    case /* ChangeFirstName */3 :
        var firstName = action._0;
        if (formView.TAG === /* NewUser */0) {
          var userForm$6 = formView._0;
          return /* Available */{
                  _0: {
                    TAG: /* NewUser */0,
                    _0: {
                      id: userForm$6.id,
                      email: userForm$6.email,
                      emailAvailable: userForm$6.emailAvailable,
                      firstName: firstName,
                      lastName: userForm$6.lastName,
                      username: userForm$6.username,
                      usernameAvailable: userForm$6.usernameAvailable,
                      activeStatus: userForm$6.activeStatus,
                      roleStatuses: userForm$6.roleStatuses,
                      dataRestrictionConditions: userForm$6.dataRestrictionConditions,
                      dataFields: userForm$6.dataFields
                    },
                    _1: formView._1
                  }
                };
        }
        var userForm$7 = formView._0;
        return /* Available */{
                _0: {
                  TAG: /* EditUser */1,
                  _0: {
                    id: userForm$7.id,
                    email: userForm$7.email,
                    emailAvailable: userForm$7.emailAvailable,
                    firstName: firstName,
                    lastName: userForm$7.lastName,
                    username: userForm$7.username,
                    usernameAvailable: userForm$7.usernameAvailable,
                    activeStatus: userForm$7.activeStatus,
                    roleStatuses: userForm$7.roleStatuses,
                    dataRestrictionConditions: userForm$7.dataRestrictionConditions,
                    dataFields: userForm$7.dataFields
                  }
                }
              };
    case /* ChangeLastName */4 :
        var lastName = action._0;
        if (formView.TAG === /* NewUser */0) {
          var userForm$8 = formView._0;
          return /* Available */{
                  _0: {
                    TAG: /* NewUser */0,
                    _0: {
                      id: userForm$8.id,
                      email: userForm$8.email,
                      emailAvailable: userForm$8.emailAvailable,
                      firstName: userForm$8.firstName,
                      lastName: lastName,
                      username: userForm$8.username,
                      usernameAvailable: userForm$8.usernameAvailable,
                      activeStatus: userForm$8.activeStatus,
                      roleStatuses: userForm$8.roleStatuses,
                      dataRestrictionConditions: userForm$8.dataRestrictionConditions,
                      dataFields: userForm$8.dataFields
                    },
                    _1: formView._1
                  }
                };
        }
        var userForm$9 = formView._0;
        return /* Available */{
                _0: {
                  TAG: /* EditUser */1,
                  _0: {
                    id: userForm$9.id,
                    email: userForm$9.email,
                    emailAvailable: userForm$9.emailAvailable,
                    firstName: userForm$9.firstName,
                    lastName: lastName,
                    username: userForm$9.username,
                    usernameAvailable: userForm$9.usernameAvailable,
                    activeStatus: userForm$9.activeStatus,
                    roleStatuses: userForm$9.roleStatuses,
                    dataRestrictionConditions: userForm$9.dataRestrictionConditions,
                    dataFields: userForm$9.dataFields
                  }
                }
              };
    case /* ChangeUserName */5 :
        var username = action._0;
        if (formView.TAG === /* NewUser */0) {
          var userForm$10 = formView._0;
          return /* Available */{
                  _0: {
                    TAG: /* NewUser */0,
                    _0: {
                      id: userForm$10.id,
                      email: userForm$10.email,
                      emailAvailable: userForm$10.emailAvailable,
                      firstName: userForm$10.firstName,
                      lastName: userForm$10.lastName,
                      username: username,
                      usernameAvailable: userForm$10.usernameAvailable,
                      activeStatus: userForm$10.activeStatus,
                      roleStatuses: userForm$10.roleStatuses,
                      dataRestrictionConditions: userForm$10.dataRestrictionConditions,
                      dataFields: userForm$10.dataFields
                    },
                    _1: formView._1
                  }
                };
        }
        var userForm$11 = formView._0;
        return /* Available */{
                _0: {
                  TAG: /* EditUser */1,
                  _0: {
                    id: userForm$11.id,
                    email: userForm$11.email,
                    emailAvailable: userForm$11.emailAvailable,
                    firstName: userForm$11.firstName,
                    lastName: userForm$11.lastName,
                    username: username,
                    usernameAvailable: userForm$11.usernameAvailable,
                    activeStatus: userForm$11.activeStatus,
                    roleStatuses: userForm$11.roleStatuses,
                    dataRestrictionConditions: userForm$11.dataRestrictionConditions,
                    dataFields: userForm$11.dataFields
                  }
                }
              };
    case /* ChangeUserNameAvailability */6 :
        var usernameAvailable = action._0;
        if (formView.TAG === /* NewUser */0) {
          var userForm$12 = formView._0;
          return /* Available */{
                  _0: {
                    TAG: /* NewUser */0,
                    _0: {
                      id: userForm$12.id,
                      email: userForm$12.email,
                      emailAvailable: userForm$12.emailAvailable,
                      firstName: userForm$12.firstName,
                      lastName: userForm$12.lastName,
                      username: userForm$12.username,
                      usernameAvailable: usernameAvailable,
                      activeStatus: userForm$12.activeStatus,
                      roleStatuses: userForm$12.roleStatuses,
                      dataRestrictionConditions: userForm$12.dataRestrictionConditions,
                      dataFields: userForm$12.dataFields
                    },
                    _1: formView._1
                  }
                };
        }
        var userForm$13 = formView._0;
        return /* Available */{
                _0: {
                  TAG: /* EditUser */1,
                  _0: {
                    id: userForm$13.id,
                    email: userForm$13.email,
                    emailAvailable: userForm$13.emailAvailable,
                    firstName: userForm$13.firstName,
                    lastName: userForm$13.lastName,
                    username: userForm$13.username,
                    usernameAvailable: usernameAvailable,
                    activeStatus: userForm$13.activeStatus,
                    roleStatuses: userForm$13.roleStatuses,
                    dataRestrictionConditions: userForm$13.dataRestrictionConditions,
                    dataFields: userForm$13.dataFields
                  }
                }
              };
    case /* ChangeNewPassword */7 :
        if (formView.TAG === /* NewUser */0) {
          return /* Available */{
                  _0: {
                    TAG: /* NewUser */0,
                    _0: formView._0,
                    _1: {
                      newPassword: action._0,
                      newPasswordConfirmation: formView._1.newPasswordConfirmation
                    }
                  }
                };
        } else {
          return /* Available */{
                  _0: {
                    TAG: /* EditUser */1,
                    _0: formView._0
                  }
                };
        }
    case /* ChangeNewPasswordConfirmation */8 :
        if (formView.TAG === /* NewUser */0) {
          return /* Available */{
                  _0: {
                    TAG: /* NewUser */0,
                    _0: formView._0,
                    _1: {
                      newPassword: formView._1.newPassword,
                      newPasswordConfirmation: action._0
                    }
                  }
                };
        } else {
          return /* Available */{
                  _0: {
                    TAG: /* EditUser */1,
                    _0: formView._0
                  }
                };
        }
    case /* ToggleRoleStatus */9 :
        var roleStatus = action._0;
        if (formView.TAG === /* NewUser */0) {
          var userForm$14 = formView._0;
          var roleStatuses = List.map((function (x) {
                  if (Caml_obj.caml_equal(x, roleStatus)) {
                    return toggleStatus(x);
                  } else {
                    return x;
                  }
                }), userForm$14.roleStatuses);
          return /* Available */{
                  _0: {
                    TAG: /* NewUser */0,
                    _0: {
                      id: userForm$14.id,
                      email: userForm$14.email,
                      emailAvailable: userForm$14.emailAvailable,
                      firstName: userForm$14.firstName,
                      lastName: userForm$14.lastName,
                      username: userForm$14.username,
                      usernameAvailable: userForm$14.usernameAvailable,
                      activeStatus: userForm$14.activeStatus,
                      roleStatuses: roleStatuses,
                      dataRestrictionConditions: userForm$14.dataRestrictionConditions,
                      dataFields: userForm$14.dataFields
                    },
                    _1: formView._1
                  }
                };
        }
        var userForm$15 = formView._0;
        var roleStatuses$1 = List.map((function (x) {
                if (Caml_obj.caml_equal(x, roleStatus)) {
                  return toggleStatus(x);
                } else {
                  return x;
                }
              }), userForm$15.roleStatuses);
        return /* Available */{
                _0: {
                  TAG: /* EditUser */1,
                  _0: {
                    id: userForm$15.id,
                    email: userForm$15.email,
                    emailAvailable: userForm$15.emailAvailable,
                    firstName: userForm$15.firstName,
                    lastName: userForm$15.lastName,
                    username: userForm$15.username,
                    usernameAvailable: userForm$15.usernameAvailable,
                    activeStatus: userForm$15.activeStatus,
                    roleStatuses: roleStatuses$1,
                    dataRestrictionConditions: userForm$15.dataRestrictionConditions,
                    dataFields: userForm$15.dataFields
                  }
                }
              };
    case /* AddDataRestrictionCondition */10 :
        var condition = action._0;
        if (formView.TAG === /* NewUser */0) {
          var userForm$16 = formView._0;
          return /* Available */{
                  _0: {
                    TAG: /* NewUser */0,
                    _0: {
                      id: userForm$16.id,
                      email: userForm$16.email,
                      emailAvailable: userForm$16.emailAvailable,
                      firstName: userForm$16.firstName,
                      lastName: userForm$16.lastName,
                      username: userForm$16.username,
                      usernameAvailable: userForm$16.usernameAvailable,
                      activeStatus: userForm$16.activeStatus,
                      roleStatuses: userForm$16.roleStatuses,
                      dataRestrictionConditions: List.append(userForm$16.dataRestrictionConditions, {
                            hd: condition,
                            tl: /* [] */0
                          }),
                      dataFields: userForm$16.dataFields
                    },
                    _1: formView._1
                  }
                };
        }
        var userForm$17 = formView._0;
        return /* Available */{
                _0: {
                  TAG: /* EditUser */1,
                  _0: {
                    id: userForm$17.id,
                    email: userForm$17.email,
                    emailAvailable: userForm$17.emailAvailable,
                    firstName: userForm$17.firstName,
                    lastName: userForm$17.lastName,
                    username: userForm$17.username,
                    usernameAvailable: userForm$17.usernameAvailable,
                    activeStatus: userForm$17.activeStatus,
                    roleStatuses: userForm$17.roleStatuses,
                    dataRestrictionConditions: List.append(userForm$17.dataRestrictionConditions, {
                          hd: condition,
                          tl: /* [] */0
                        }),
                    dataFields: userForm$17.dataFields
                  }
                }
              };
    case /* UpdateDataRestrictionConditions */11 :
        var dataRestrictionConditions = action._0;
        if (formView.TAG === /* NewUser */0) {
          var userForm$18 = formView._0;
          return /* Available */{
                  _0: {
                    TAG: /* NewUser */0,
                    _0: {
                      id: userForm$18.id,
                      email: userForm$18.email,
                      emailAvailable: userForm$18.emailAvailable,
                      firstName: userForm$18.firstName,
                      lastName: userForm$18.lastName,
                      username: userForm$18.username,
                      usernameAvailable: userForm$18.usernameAvailable,
                      activeStatus: userForm$18.activeStatus,
                      roleStatuses: userForm$18.roleStatuses,
                      dataRestrictionConditions: dataRestrictionConditions,
                      dataFields: userForm$18.dataFields
                    },
                    _1: formView._1
                  }
                };
        }
        var userForm$19 = formView._0;
        return /* Available */{
                _0: {
                  TAG: /* EditUser */1,
                  _0: {
                    id: userForm$19.id,
                    email: userForm$19.email,
                    emailAvailable: userForm$19.emailAvailable,
                    firstName: userForm$19.firstName,
                    lastName: userForm$19.lastName,
                    username: userForm$19.username,
                    usernameAvailable: userForm$19.usernameAvailable,
                    activeStatus: userForm$19.activeStatus,
                    roleStatuses: userForm$19.roleStatuses,
                    dataRestrictionConditions: dataRestrictionConditions,
                    dataFields: userForm$19.dataFields
                  }
                }
              };
    
  }
}

function isPasswordFilled(x) {
  return !Curry._1(Domain$ReactHooksTemplate.$$String.Password.isEmpty, x);
}

function isEmailFilled(x) {
  return !Curry._1(Domain$ReactHooksTemplate.$$String.Email.isEmpty, x);
}

function isPasswordValid(x) {
  if (isPasswordFilled(x)) {
    return Utils$ReactHooksTemplate.checkMinLength8(Curry._1(Domain$ReactHooksTemplate.$$String.Password.show, x));
  } else {
    return true;
  }
}

function isAlphanumericPassword(x) {
  if (isPasswordFilled(x)) {
    return Utils$ReactHooksTemplate.checkAlphanumeric(Curry._1(Domain$ReactHooksTemplate.$$String.Password.show, x));
  } else {
    return true;
  }
}

function isUserNameInPassword(email, x) {
  var emailInPwd = Utils$ReactHooksTemplate.checkUsernameExistInPassword(Curry._1(Domain$ReactHooksTemplate.$$String.Email.show, email), Curry._1(Domain$ReactHooksTemplate.$$String.Password.show, x));
  if (isPasswordFilled(x) && isEmailFilled(email)) {
    return emailInPwd;
  } else {
    return false;
  }
}

function areIndpendentFieldsValid(formView) {
  if (formView.TAG !== /* NewUser */0) {
    return true;
  }
  var passwordConfirmation = formView._1;
  if (isPasswordValid(passwordConfirmation.newPassword)) {
    return isPasswordValid(passwordConfirmation.newPasswordConfirmation);
  } else {
    return false;
  }
}

function arePasswordsFilled(x, y) {
  if (isPasswordFilled(x)) {
    return isPasswordFilled(y);
  } else {
    return false;
  }
}

function arePasswordsExactMatch(x, y) {
  if (arePasswordsFilled(x, y)) {
    return Caml_obj.caml_equal(x, y);
  } else {
    return true;
  }
}

function areDepedentFieldsValid(formView) {
  if (formView.TAG !== /* NewUser */0) {
    return true;
  }
  var passwordConfirmation = formView._1;
  return arePasswordsExactMatch(passwordConfirmation.newPassword, passwordConfirmation.newPasswordConfirmation);
}

function hasEmail(form) {
  if (Caml_obj.caml_notequal(form.email, Curry._1(Domain$ReactHooksTemplate.$$String.Email.empty, undefined))) {
    return form.emailAvailable;
  } else {
    return false;
  }
}

function hasFirstName(form) {
  return Caml_obj.caml_notequal(form.firstName, Curry._1(Domain$ReactHooksTemplate.$$String.Name.empty, undefined));
}

function hasLastName(form) {
  return Caml_obj.caml_notequal(form.lastName, Curry._1(Domain$ReactHooksTemplate.$$String.Name.empty, undefined));
}

function hasNewPassword(form) {
  return Caml_obj.caml_notequal(form.newPassword, Curry._1(Domain$ReactHooksTemplate.$$String.Password.empty, undefined));
}

function hasNewPasswordConfirmation(form) {
  return Caml_obj.caml_notequal(form.newPasswordConfirmation, Curry._1(Domain$ReactHooksTemplate.$$String.Password.empty, undefined));
}

function hasSomeRoleEnabled(form) {
  return Belt_List.some(form.roleStatuses, enabled);
}

function hasMandatoryParams(formView) {
  if (formView.TAG === /* NewUser */0) {
    var passwordConfirmation = formView._1;
    var userForm = formView._0;
    if (hasEmail(userForm) && hasFirstName(userForm) && hasLastName(userForm) && hasNewPassword(passwordConfirmation) && hasNewPasswordConfirmation(passwordConfirmation)) {
      return Belt_List.some(userForm.roleStatuses, enabled);
    } else {
      return false;
    }
  }
  var userForm$1 = formView._0;
  if (hasEmail(userForm$1) && hasFirstName(userForm$1) && hasLastName(userForm$1)) {
    return Belt_List.some(userForm$1.roleStatuses, enabled);
  } else {
    return false;
  }
}

function isFormValid(formView) {
  if (hasMandatoryParams(formView) && areIndpendentFieldsValid(formView)) {
    return areDepedentFieldsValid(formView);
  } else {
    return false;
  }
}

var emptyUser_email = Curry._1(Domain$ReactHooksTemplate.$$String.Email.empty, undefined);

var emptyUser_firstName = Curry._1(Domain$ReactHooksTemplate.$$String.Name.empty, undefined);

var emptyUser_lastName = Curry._1(Domain$ReactHooksTemplate.$$String.Name.empty, undefined);

var emptyUser_username = Curry._1(Domain$ReactHooksTemplate.$$String.Name.empty, undefined);

var emptyUser = {
  id: undefined,
  email: emptyUser_email,
  emailAvailable: true,
  firstName: emptyUser_firstName,
  lastName: emptyUser_lastName,
  username: emptyUser_username,
  usernameAvailable: true,
  activeStatus: false,
  roleStatuses: /* [] */0,
  dataRestrictionConditions: /* [] */0,
  dataFields: /* [] */0
};

var emptyPasswordConfirmation_newPassword = Curry._1(Domain$ReactHooksTemplate.$$String.Password.empty, undefined);

var emptyPasswordConfirmation_newPasswordConfirmation = Curry._1(Domain$ReactHooksTemplate.$$String.Password.empty, undefined);

var emptyPasswordConfirmation = {
  newPassword: emptyPasswordConfirmation_newPassword,
  newPasswordConfirmation: emptyPasswordConfirmation_newPasswordConfirmation
};

function fetchRoles(param) {
  return Http$ReactHooksTemplate.fetchGetJSONDecode("/api/v1/roles", (function (param) {
                return Json_decode.field("roles", (function (param) {
                              return Json_decode.list(Role$ReactHooksTemplate.decode, param);
                            }), param);
              }));
}

function fetchDataFields(param) {
  return Http$ReactHooksTemplate.fetchGetJSONDecode("/api/v1/data_fields", (function (param) {
                return Json_decode.field("data_fields", (function (param) {
                              return Json_decode.list(DataField$ReactHooksTemplate.decode, param);
                            }), param);
              }));
}

function buildUserDict(formView) {
  var params = {};
  var userForm = formView._0;
  params["email"] = Curry._1(Domain$ReactHooksTemplate.$$String.Email.show, userForm.email);
  params["username"] = Curry._1(Domain$ReactHooksTemplate.$$String.Name.show, userForm.username);
  if (formView.TAG === /* NewUser */0) {
    var passwordConfirmation = formView._1;
    params["password"] = Curry._1(Domain$ReactHooksTemplate.$$String.Password.show, passwordConfirmation.newPassword);
    params["password_confirmation"] = Curry._1(Domain$ReactHooksTemplate.$$String.Password.show, passwordConfirmation.newPasswordConfirmation);
  }
  params["first_name"] = Curry._1(Domain$ReactHooksTemplate.$$String.Name.show, userForm.firstName);
  params["last_name"] = Curry._1(Domain$ReactHooksTemplate.$$String.Name.show, userForm.lastName);
  params["active"] = userForm.activeStatus;
  params["role_ids"] = $$Array.of_list(List.map((function (prim) {
              return prim;
            }), List.map(roleId, List.filter(enabled)(userForm.roleStatuses))));
  var dataRestrictionAttributes = Belt_List.map(Belt_List.keep(userForm.dataRestrictionConditions, (function (x) {
              var match = x.dataField;
              var match$1 = x.value;
              if (match === undefined) {
                return false;
              }
              if (!match$1) {
                return false;
              }
              switch (match$1.hd) {
                case "" :
                case "Select Option" :
                    return false;
                default:
                  return true;
              }
            })), (function (x) {
          var attribute = {};
          var id = x.id;
          if (id !== undefined) {
            attribute["id"] = id;
            attribute["_destroy"] = x._destroy;
          }
          var dataField = x.dataField;
          if (dataField !== undefined) {
            attribute["data_field_id"] = DataField$ReactHooksTemplate.id(Caml_option.valFromOption(dataField));
            attribute["operator"] = x.operator;
            attribute["value"] = Json_encode.list((function (y) {
                    return y;
                  }), x.value);
          }
          return attribute;
        }));
  params["data_restrictions_attributes"] = $$Array.of_list(Belt_List.map(dataRestrictionAttributes, (function (prim) {
              return prim;
            })));
  var user = {};
  user["user"] = params;
  return user;
}

function createUser(formView) {
  return Http$ReactHooksTemplate.fetchPostJSON("/api/v1/users", buildUserDict(formView));
}

function updateUser(formView) {
  var id = formView._0.id;
  return Http$ReactHooksTemplate.fetchPutJSON("/api/v1/users/" + id, buildUserDict(formView));
}

function checkEmailAPI(email, userId) {
  var api = "/users/check_email?[user][email]=" + Curry._1(Domain$ReactHooksTemplate.$$String.Email.show, email);
  if (userId !== undefined) {
    return api + ("&[user][id]=" + String(userId));
  } else {
    return api;
  }
}

function checkUsernameAPI(username, userId) {
  var api = "/users/check_username?[user][username]=" + Curry._1(Domain$ReactHooksTemplate.$$String.Name.show, username);
  if (userId !== undefined) {
    return api + ("&[user][id]=" + String(userId));
  } else {
    return api;
  }
}

function checkEmail(email, userId) {
  return Http$ReactHooksTemplate.fetchGetJSONDecode(checkEmailAPI(email, userId), (function (param) {
                return Json_decode.field("available", Json_decode.bool, param);
              }));
}

function checkUsername(username, userId) {
  return Http$ReactHooksTemplate.fetchGetJSONDecode(checkUsernameAPI(username, userId), (function (param) {
                return Json_decode.field("available", Json_decode.bool, param);
              }));
}

function editForm(formView) {
  if (formView.TAG === /* NewUser */0) {
    return false;
  } else {
    return true;
  }
}

function UserForm(Props) {
  var user = Props.user;
  var initialFormView;
  if (user !== undefined) {
    var x = User$ReactHooksTemplate.username(user);
    initialFormView = {
      TAG: /* EditUser */1,
      _0: {
        id: User$ReactHooksTemplate.id(user),
        email: User$ReactHooksTemplate.email(user),
        emailAvailable: true,
        firstName: User$ReactHooksTemplate.firstName(user),
        lastName: User$ReactHooksTemplate.lastName(user),
        username: x !== undefined ? Caml_option.valFromOption(x) : Curry._1(Domain$ReactHooksTemplate.$$String.Name.empty, undefined),
        usernameAvailable: true,
        activeStatus: User$ReactHooksTemplate.active(user),
        roleStatuses: List.map((function (x) {
                return create(true, x);
              }), User$ReactHooksTemplate.roles(user)),
        dataRestrictionConditions: Belt_List.map(User$ReactHooksTemplate.dataRestrictions(user), (function (x) {
                return create$1(Caml_option.some(DataRestriction$ReactHooksTemplate.id(x)), DataField$ReactHooksTemplate.name(DataRestriction$ReactHooksTemplate.dataField(x)), Caml_option.some(Caml_option.some(DataRestriction$ReactHooksTemplate.dataField(x))), false, false, DataRestriction$ReactHooksTemplate.operator(x), DataRestriction$ReactHooksTemplate.value(x));
              })),
        dataFields: /* [] */0
      }
    };
  } else {
    initialFormView = {
      TAG: /* NewUser */0,
      _0: emptyUser,
      _1: emptyPasswordConfirmation
    };
  }
  var match = React.useReducer(reducer, /* FetchRolesInProgress */0);
  var dispatch = match[1];
  var state = match[0];
  var match$1 = React.useState(function () {
        return /* [] */0;
      });
  var setDataFields = match$1[1];
  var dataFields = match$1[0];
  React.useEffect((function () {
          fetchRoles(undefined).then(function (roles) {
                var tmp;
                if (initialFormView.TAG === /* NewUser */0) {
                  var userForm = initialFormView._0;
                  var partial_arg = false;
                  tmp = {
                    TAG: /* NewUser */0,
                    _0: {
                      id: userForm.id,
                      email: userForm.email,
                      emailAvailable: userForm.emailAvailable,
                      firstName: userForm.firstName,
                      lastName: userForm.lastName,
                      username: userForm.username,
                      usernameAvailable: userForm.usernameAvailable,
                      activeStatus: userForm.activeStatus,
                      roleStatuses: Belt_List.map(roles, (function (param) {
                              return create(partial_arg, param);
                            })),
                      dataRestrictionConditions: userForm.dataRestrictionConditions,
                      dataFields: userForm.dataFields
                    },
                    _1: initialFormView._1
                  };
                } else {
                  var userForm$1 = initialFormView._0;
                  tmp = {
                    TAG: /* EditUser */1,
                    _0: {
                      id: userForm$1.id,
                      email: userForm$1.email,
                      emailAvailable: userForm$1.emailAvailable,
                      firstName: userForm$1.firstName,
                      lastName: userForm$1.lastName,
                      username: userForm$1.username,
                      usernameAvailable: userForm$1.usernameAvailable,
                      activeStatus: userForm$1.activeStatus,
                      roleStatuses: Belt_List.map(roles, (function (role) {
                              var enabled = Belt_List.length(Belt_List.keep(userForm$1.roleStatuses, (function (x) {
                                          return Role$ReactHooksTemplate.id(x.role) === Role$ReactHooksTemplate.id(role);
                                        }))) > 0;
                              return create(enabled, role);
                            })),
                      dataRestrictionConditions: userForm$1.dataRestrictionConditions,
                      dataFields: userForm$1.dataFields
                    }
                  };
                }
                return Promise.resolve(Curry._1(dispatch, {
                                TAG: /* ShowAddNewUserForm */0,
                                _0: tmp
                              }));
              });
          
        }), []);
  React.useEffect((function () {
          fetchDataFields(undefined).then(function (dataFields) {
                return Promise.resolve(Curry._1(setDataFields, (function (param) {
                                  return dataFields;
                                })));
              });
          
        }), []);
  var handleFirstNameInput = function ($$event) {
    return Utils$ReactHooksTemplate.handleInput(Utils$ReactHooksTemplate.checkMaxLength255, (function (x) {
                  return Curry._1(dispatch, {
                              TAG: /* ChangeFirstName */3,
                              _0: Curry._1(Domain$ReactHooksTemplate.$$String.Name.create, x)
                            });
                }), $$event);
  };
  var handleLastNameInput = function ($$event) {
    return Utils$ReactHooksTemplate.handleInput(Utils$ReactHooksTemplate.checkMaxLength255, (function (x) {
                  return Curry._1(dispatch, {
                              TAG: /* ChangeLastName */4,
                              _0: Curry._1(Domain$ReactHooksTemplate.$$String.Name.create, x)
                            });
                }), $$event);
  };
  var handleNewPasswordInput = function ($$event) {
    return Utils$ReactHooksTemplate.handleInput(Utils$ReactHooksTemplate.checkMaxLength36, (function (x) {
                  return Curry._1(dispatch, {
                              TAG: /* ChangeNewPassword */7,
                              _0: Curry._1(Domain$ReactHooksTemplate.$$String.Password.create, x)
                            });
                }), $$event);
  };
  var handleNewPasswordConfirmationInput = function ($$event) {
    return Utils$ReactHooksTemplate.handleInput(Utils$ReactHooksTemplate.checkMaxLength36, (function (x) {
                  return Curry._1(dispatch, {
                              TAG: /* ChangeNewPasswordConfirmation */8,
                              _0: Curry._1(Domain$ReactHooksTemplate.$$String.Password.create, x)
                            });
                }), $$event);
  };
  var addRestrictionCondition = function ($$event) {
    $$event.preventDefault();
    return Curry._1(dispatch, {
                TAG: /* AddDataRestrictionCondition */10,
                _0: empty
              });
  };
  var validCSS = "hidden";
  var errorCSS = "text-red-600 font-medium";
  var passwordMinLengthCSS = function (x) {
    if (isPasswordValid(x)) {
      return validCSS;
    } else {
      return errorCSS;
    }
  };
  var passwordAlphanumericCSS = function (x) {
    if (isAlphanumericPassword(x)) {
      return validCSS;
    } else {
      return errorCSS;
    }
  };
  var emailInPasswordCss = function (email, x) {
    if (isUserNameInPassword(email, x)) {
      return errorCSS;
    } else {
      return validCSS;
    }
  };
  var passwordMatchCSS = function (x, y) {
    if (arePasswordsExactMatch(x, y)) {
      return validCSS;
    } else {
      return errorCSS;
    }
  };
  var emailAvailableCSS = function (form) {
    if (Curry._1(Domain$ReactHooksTemplate.$$String.Email.isEmpty, form.email) || form.emailAvailable) {
      return validCSS;
    } else {
      return errorCSS;
    }
  };
  if (!state) {
    return React.createElement("p", undefined, "Form is loading...");
  }
  var formView = state._0;
  var userForm = formView._0;
  var tmp;
  if (formView.TAG === /* NewUser */0) {
    var passwordConfirmation = formView._1;
    tmp = React.createElement("div", {
          className: "block items-top mb-30-px w-6/11"
        }, React.createElement("div", {
              className: "mb-30-px"
            }, React.createElement(NewPasswordInput$ReactHooksTemplate.make, {
                  password: passwordConfirmation.newPassword,
                  label: "Password",
                  handleChange: handleNewPasswordInput,
                  passwordInputClassName: "outline-none border border-gray-400 max-h-12 w-10/12 rounded px-3 py-2 text-lg",
                  labelClassName: "text-sm font-medium mb-20-px"
                }), React.createElement("div", {
                  className: passwordMinLengthCSS(passwordConfirmation.newPassword) + " mb-10-px"
                }, "Minimum length should be 8."), React.createElement("div", {
                  className: passwordAlphanumericCSS(passwordConfirmation.newPassword) + " mb-10-px"
                }, "Password should contain atleast one lowercase, one uppercase, one number and one special character"), React.createElement("div", {
                  className: emailInPasswordCss(userForm.email, passwordConfirmation.newPassword) + " mb-10-px"
                }, "Password Contains Email."), React.createElement("div", {
                  className: passwordMatchCSS(passwordConfirmation.newPassword, passwordConfirmation.newPasswordConfirmation)
                }, "Passwords do not match.")), React.createElement("div", {
              className: "mb-30-px"
            }, React.createElement(NewPasswordInput$ReactHooksTemplate.make, {
                  password: passwordConfirmation.newPasswordConfirmation,
                  label: "Confirm Password",
                  handleChange: handleNewPasswordConfirmationInput,
                  passwordInputClassName: "outline-none border border-gray-400 max-h-12 w-10/12 rounded px-3 py-2 text-lg",
                  labelClassName: "text-sm font-medium mb-20-px"
                }), React.createElement("div", {
                  className: passwordMinLengthCSS(passwordConfirmation.newPasswordConfirmation) + " mb-10-px"
                }, "Minimum length should be 8."), React.createElement("div", {
                  className: passwordMatchCSS(passwordConfirmation.newPassword, passwordConfirmation.newPasswordConfirmation)
                }, "Passwords do not match."), React.createElement("div", {
                  className: passwordAlphanumericCSS(passwordConfirmation.newPasswordConfirmation) + " mb-10-px"
                }, "Password should contain atleast one lowercase, one uppercase, one number and one special character"), React.createElement("div", {
                  className: emailInPasswordCss(userForm.email, passwordConfirmation.newPasswordConfirmation) + " mb-10-px"
                }, "Password Contains Email.")));
  } else {
    tmp = React.createElement(React.Fragment, undefined);
  }
  var options = List.map((function (x) {
          return Role$ReactHooksTemplate.name(x.role);
        }), userForm.roleStatuses);
  var roleEnabledRoles = List.find_all(function (y) {
          return y.enabled === true;
        })(userForm.roleStatuses);
  var roleNames = List.map((function (x) {
          return Role$ReactHooksTemplate.name(x.role);
        }), roleEnabledRoles);
  var partial_arg = userForm.roleStatuses;
  return React.createElement("div", undefined, React.createElement("div", {
                  className: "flex justify-between items-center border-b border-gray-300 px-30-px py-16-px"
                }, React.createElement("h1", {
                      className: "text-xl font-semibold text-charcoal"
                    }, "Add New User")), React.createElement("div", {
                  className: "m-50-px w-2/3 bg-white"
                }, React.createElement("form", {
                      onSubmit: (function (param) {
                          param.preventDefault();
                          (
                                editForm(formView) ? updateUser(formView) : Http$ReactHooksTemplate.fetchPostJSON("/api/v1/users", buildUserDict(formView))
                              ).then(function (response) {
                                  return Promise.resolve((console.log(response), undefined));
                                }).then(function (param) {
                                return Promise.resolve(RescriptReactRouter.push("/users/all"));
                              });
                          
                        })
                    }, React.createElement("div", {
                          className: "block mb-30-px w-6/11"
                        }, React.createElement("div", {
                              className: "mb-30-px"
                            }, React.createElement(EmailInput$ReactHooksTemplate.make, {
                                  email: userForm.email,
                                  label: "Email",
                                  handleChange: (function (param) {
                                      return Utils$ReactHooksTemplate.handleInput(Utils$ReactHooksTemplate.checkMaxLength255, (function (x) {
                                                    var email = Curry._1(Domain$ReactHooksTemplate.$$String.Email.create, x);
                                                    checkEmail(email, userForm.id).then(function (available) {
                                                          return Promise.resolve(Curry._1(dispatch, {
                                                                          TAG: /* ChangeEmailAvailability */2,
                                                                          _0: available
                                                                        }));
                                                        });
                                                    return Curry._1(dispatch, {
                                                                TAG: /* ChangeEmail */1,
                                                                _0: email
                                                              });
                                                  }), param);
                                    }),
                                  errorCSS: emailAvailableCSS(userForm),
                                  errorText: "Email is already taken",
                                  emailInputClassName: "outline-none border border-gray-400 max-h-44-px w-10/12 rounded px-3 py-2 text-lg",
                                  emailLabelClassName: "text-sm font-medium mb-20-px"
                                })), React.createElement("div", {
                              className: "mb-30-px"
                            }, React.createElement(TextInputNew$ReactHooksTemplate.make, {
                                  label: "Username",
                                  value: Curry._1(Domain$ReactHooksTemplate.$$String.Name.show, userForm.username),
                                  handleChange: (function (param) {
                                      return Utils$ReactHooksTemplate.handleInput(Utils$ReactHooksTemplate.checkMaxLength255, (function (x) {
                                                    var username = Curry._1(Domain$ReactHooksTemplate.$$String.Name.create, x);
                                                    checkUsername(username, userForm.id).then(function (available) {
                                                          return Promise.resolve(Curry._1(dispatch, {
                                                                          TAG: /* ChangeUserNameAvailability */6,
                                                                          _0: available
                                                                        }));
                                                        });
                                                    return Curry._1(dispatch, {
                                                                TAG: /* ChangeUserName */5,
                                                                _0: username
                                                              });
                                                  }), param);
                                    }),
                                  errorText: "Username is already taken",
                                  error: !(Curry._1(Domain$ReactHooksTemplate.$$String.Name.isEmpty, userForm.username) || userForm.usernameAvailable),
                                  textInputClassName: "outline-none border border-gray-400 max-h-12 w-10/12 rounded px-3 py-2 text-lg",
                                  containerClassName: "block mb-30-px",
                                  labelClassName: "mb-20-px"
                                })), React.createElement("div", {
                              className: "mb-30-px"
                            }, React.createElement(TextInputNew$ReactHooksTemplate.make, {
                                  label: "First Name",
                                  value: Curry._1(Domain$ReactHooksTemplate.$$String.Name.show, userForm.firstName),
                                  handleChange: handleFirstNameInput,
                                  textInputClassName: "outline-none border border-gray-400 max-h-12 w-10/12 rounded px-3 py-2 text-lg",
                                  containerClassName: "block mb-30-px",
                                  labelClassName: "mb-20-px"
                                })), React.createElement("div", {
                              className: "mb-30-px"
                            }, React.createElement(TextInputNew$ReactHooksTemplate.make, {
                                  label: "Last Name",
                                  value: Curry._1(Domain$ReactHooksTemplate.$$String.Name.show, userForm.lastName),
                                  handleChange: handleLastNameInput,
                                  textInputClassName: "outline-none border border-gray-400 max-h-12 w-10/12 rounded px-3 py-2 text-lg",
                                  containerClassName: "block mb-30-px",
                                  labelClassName: "mb-20-px"
                                }))), tmp, React.createElement("div", {
                          className: "block items-top mb-30-px w-5/11"
                        }, React.createElement("div", {
                              className: "mb-30-px"
                            }, React.createElement("p", {
                                  className: "text-sm font-medium mb-20-px"
                                }, "Roles"), React.createElement(MultipleSelectBox$ReactHooksTemplate.make, {
                                  options: options,
                                  placeholder: "Select Options",
                                  handleChange: (function (param) {
                                      var selectedRole = List.hd(List.filter(function (y) {
                                                  return Role$ReactHooksTemplate.name(y.role) === param;
                                                })(partial_arg));
                                      return Curry._1(dispatch, {
                                                  TAG: /* ToggleRoleStatus */9,
                                                  _0: selectedRole
                                                });
                                    }),
                                  selectedOptions: roleNames,
                                  className: "outline-none border border-gray-400 min-h-38-px rounded px-3 py-2 text-lg ",
                                  optionsHoverColor: "hover:bg-gray-300"
                                })), React.createElement("div", {
                              className: "flex items-center w-1/2 p-1"
                            }, React.createElement(CheckboxInput$ReactHooksTemplate.make, {
                                  checked: userForm.activeStatus,
                                  label: "Active",
                                  handleChange: (function (_evt) {
                                      return Curry._1(dispatch, /* ToggleActiveStatus */0);
                                    }),
                                  checkboxClassName: "custom_checkbox",
                                  labelClassName: "flex items-center"
                                }))), React.createElement("div", {
                          className: "flex mb-30-px items-center"
                        }, React.createElement("div", {
                              className: "w-full"
                            }, React.createElement("p", {
                                  className: "font-semibold mb-5"
                                }, "Data Restrictions: "), React.createElement("div", {
                                  className: ""
                                }, $$Array.of_list(Belt_List.mapWithIndex(userForm.dataRestrictionConditions, (function (index, x) {
                                            if (x._destroy) {
                                              return React.createElement(React.Fragment, undefined);
                                            }
                                            var dataField = x.dataField;
                                            var partial_arg = userForm.dataRestrictionConditions;
                                            var dataField$1 = x.dataField;
                                            var tmp;
                                            if (dataField$1 !== undefined) {
                                              var dataField$2 = Caml_option.valFromOption(dataField$1);
                                              tmp = React.createElement("option", {
                                                    key: DataField$ReactHooksTemplate.name(dataField$2),
                                                    value: String(DataField$ReactHooksTemplate.id(dataField$2))
                                                  }, DataField$ReactHooksTemplate.name(dataField$2));
                                            } else {
                                              tmp = React.createElement("option", {
                                                    key: "0",
                                                    value: "0"
                                                  }, "Select Field");
                                            }
                                            var dataField$3 = x.dataField;
                                            var options;
                                            if (dataField$3 !== undefined) {
                                              var listType = DataField$ReactHooksTemplate.dataType(Caml_option.valFromOption(dataField$3));
                                              options = listType.TAG === /* ListType */0 ? ListType$ReactHooksTemplate.options(listType._0) : /* [] */0;
                                            } else {
                                              options = /* [] */0;
                                            }
                                            var partial_arg$1 = userForm.dataRestrictionConditions;
                                            var partial_arg$2 = userForm.dataRestrictionConditions;
                                            return React.createElement("div", {
                                                        key: String(index),
                                                        className: "flex items-center justify-between h-5 mb-5"
                                                      }, React.createElement("div", {
                                                            className: "w-2/6"
                                                          }, React.createElement("select", {
                                                                className: "border pl-1 cursor-pointer",
                                                                placeholder: "Select field",
                                                                value: dataField !== undefined ? String(DataField$ReactHooksTemplate.id(Caml_option.valFromOption(dataField))) : "Select field",
                                                                onChange: (function (param) {
                                                                    var dataFieldId = param.target.value;
                                                                    var dataField = List.filter(function (y) {
                                                                            return String(DataField$ReactHooksTemplate.id(y)) === dataFieldId;
                                                                          })(dataFields);
                                                                    var conditions = Belt_List.mapWithIndex(partial_arg, (function (i, x) {
                                                                            if (index !== i) {
                                                                              return x;
                                                                            }
                                                                            if (!dataField) {
                                                                              return x;
                                                                            }
                                                                            var head = dataField.hd;
                                                                            return {
                                                                                    id: x.id,
                                                                                    name: DataField$ReactHooksTemplate.name(head),
                                                                                    dataField: Caml_option.some(head),
                                                                                    operator: x.operator,
                                                                                    value: /* [] */0,
                                                                                    _destroy: x._destroy,
                                                                                    showOptions: x.showOptions
                                                                                  };
                                                                          }));
                                                                    return Curry._1(dispatch, {
                                                                                TAG: /* UpdateDataRestrictionConditions */11,
                                                                                _0: conditions
                                                                              });
                                                                  })
                                                              }, tmp, $$Array.of_list(Belt_List.map(Belt_List.keep(Belt_List.keep(dataFields, (function (x) {
                                                                                  var match = DataField$ReactHooksTemplate.dataType(x);
                                                                                  if (match.TAG === /* ListType */0) {
                                                                                    return true;
                                                                                  } else {
                                                                                    return false;
                                                                                  }
                                                                                })), (function (x) {
                                                                              var addedDataFields = Belt_List.keepMap(Belt_List.map(userForm.dataRestrictionConditions, (function (x) {
                                                                                          return x.dataField;
                                                                                        })), (function (x) {
                                                                                      return x;
                                                                                    }));
                                                                              return !List.exists((function (y) {
                                                                                            return Caml_obj.caml_equal(x, y);
                                                                                          }), addedDataFields);
                                                                            })), (function (x) {
                                                                          return React.createElement("option", {
                                                                                      key: DataField$ReactHooksTemplate.name(x),
                                                                                      value: String(DataField$ReactHooksTemplate.id(x))
                                                                                    }, DataField$ReactHooksTemplate.name(x));
                                                                        }))))), React.createElement(SelectBox$ReactHooksTemplate.make, {
                                                            options: options,
                                                            placeholder: "Select Options",
                                                            handleChange: (function (param) {
                                                                var conditions = Belt_List.mapWithIndex(partial_arg$1, (function (i, x) {
                                                                        if (index === i) {
                                                                          return {
                                                                                  id: x.id,
                                                                                  name: x.name,
                                                                                  dataField: x.dataField,
                                                                                  operator: x.operator,
                                                                                  value: param,
                                                                                  _destroy: x._destroy,
                                                                                  showOptions: x.showOptions
                                                                                };
                                                                        } else {
                                                                          return x;
                                                                        }
                                                                      }));
                                                                return Curry._1(dispatch, {
                                                                            TAG: /* UpdateDataRestrictionConditions */11,
                                                                            _0: conditions
                                                                          });
                                                              }),
                                                            selectedOptions: x.value,
                                                            className: "w-3/6 cursor-pointer "
                                                          }), React.createElement("div", {
                                                            className: "w-1/6"
                                                          }, React.createElement("a", {
                                                                className: "px-2 text-blue-800 text-xs\n                             font-extrabold cursor-pointer underline",
                                                                onClick: (function (param) {
                                                                    param.preventDefault();
                                                                    var conditionToRemove = Belt_List.get(partial_arg$2, index);
                                                                    var conditions;
                                                                    if (conditionToRemove !== undefined) {
                                                                      var match = conditionToRemove.id;
                                                                      conditions = match !== undefined ? Belt_List.mapWithIndex(partial_arg$2, (function (i, x) {
                                                                                if (i === index) {
                                                                                  return {
                                                                                          id: x.id,
                                                                                          name: x.name,
                                                                                          dataField: x.dataField,
                                                                                          operator: x.operator,
                                                                                          value: x.value,
                                                                                          _destroy: true,
                                                                                          showOptions: x.showOptions
                                                                                        };
                                                                                } else {
                                                                                  return x;
                                                                                }
                                                                              })) : Belt_List.keepWithIndex(partial_arg$2, (function (param, i) {
                                                                                return i !== index;
                                                                              }));
                                                                    } else {
                                                                      conditions = partial_arg$2;
                                                                    }
                                                                    return Curry._1(dispatch, {
                                                                                TAG: /* UpdateDataRestrictionConditions */11,
                                                                                _0: conditions
                                                                              });
                                                                  })
                                                              }, "Remove")));
                                          }))), React.createElement("a", {
                                      className: "text-blue-800 text-xs font-extrabold cursor-pointer\n                underline",
                                      onClick: addRestrictionCondition
                                    }, "+Add new condition")))), React.createElement("div", undefined, React.createElement("a", {
                              className: "bg-white ml-4 px-5 py-3 rounded border border-bg-black hover:text-pigmentblue hover:border-pigmentblue text-black font-16px",
                              href: "/users/all"
                            }, "Cancel"), React.createElement("input", {
                              className: "bg-pigmentblue rounded hover:bg-cetaceanblue ml-4 px-6 py-3 font-normal \n                         text-white font-16-px leading-snug cursor-pointer ",
                              disabled: !isFormValid(formView),
                              type: "submit",
                              value: editForm(formView) ? "Update" : "Create"
                            })))));
}

var make = UserForm;

exports.RoleStatus = RoleStatus;
exports.DataRestrictionCondition = DataRestrictionCondition;
exports.reducer = reducer;
exports.isPasswordFilled = isPasswordFilled;
exports.isEmailFilled = isEmailFilled;
exports.isPasswordValid = isPasswordValid;
exports.isAlphanumericPassword = isAlphanumericPassword;
exports.isUserNameInPassword = isUserNameInPassword;
exports.areIndpendentFieldsValid = areIndpendentFieldsValid;
exports.arePasswordsFilled = arePasswordsFilled;
exports.arePasswordsExactMatch = arePasswordsExactMatch;
exports.areDepedentFieldsValid = areDepedentFieldsValid;
exports.hasEmail = hasEmail;
exports.hasFirstName = hasFirstName;
exports.hasLastName = hasLastName;
exports.hasNewPassword = hasNewPassword;
exports.hasNewPasswordConfirmation = hasNewPasswordConfirmation;
exports.hasSomeRoleEnabled = hasSomeRoleEnabled;
exports.hasMandatoryParams = hasMandatoryParams;
exports.isFormValid = isFormValid;
exports.emptyUser = emptyUser;
exports.emptyPasswordConfirmation = emptyPasswordConfirmation;
exports.fetchRoles = fetchRoles;
exports.fetchDataFields = fetchDataFields;
exports.buildUserDict = buildUserDict;
exports.createUser = createUser;
exports.updateUser = updateUser;
exports.checkEmailAPI = checkEmailAPI;
exports.checkUsernameAPI = checkUsernameAPI;
exports.checkEmail = checkEmail;
exports.checkUsername = checkUsername;
exports.editForm = editForm;
exports.make = make;
/* emptyUser Not a pure module */

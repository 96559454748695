// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");
var Http$ReactHooksTemplate = require("../common/http.bs.js");
var Survey$ReactHooksTemplate = require("./survey.bs.js");
var Loading$ReactHooksTemplate = require("../dashboard/dashboard/Loading.bs.js");
var SurveyView$ReactHooksTemplate = require("./SurveyView.bs.js");
var SurveyTheme$ReactHooksTemplate = require("./surveyTheme.bs.js");
var CustomerInfo$ReactHooksTemplate = require("./CustomerInfo.bs.js");
var Organization$ReactHooksTemplate = require("../settings/organization.bs.js");
var SurveyLanguage$ReactHooksTemplate = require("./surveyLanguage.bs.js");
var ApplicationSettings$ReactHooksTemplate = require("../general_settings/applicationSettings.bs.js");

function decodeSurvey(json) {
  var survey = Json_decode.field("survey", Survey$ReactHooksTemplate.fromJson, json);
  var surveyLanguages = Json_decode.field("survey", (function (param) {
          return Json_decode.field("survey_languages", (function (param) {
                        return Json_decode.list(SurveyLanguage$ReactHooksTemplate.fromJson, param);
                      }), param);
        }), json);
  var language = Belt_Option.getWithDefault(Json_decode.field("survey", (function (param) {
              return Json_decode.optional((function (param) {
                            return Json_decode.field("survey_language", SurveyLanguage$ReactHooksTemplate.fromJson, param);
                          }), param);
            }), json), SurveyLanguage$ReactHooksTemplate.$$default);
  var client = Json_decode.field("survey", (function (param) {
          return Json_decode.field("organization_profile", Organization$ReactHooksTemplate.decode, param);
        }), json);
  var applicationSettings = Json_decode.field("survey", (function (param) {
          return Json_decode.field("system_settings", ApplicationSettings$ReactHooksTemplate.decoder, param);
        }), json);
  var transactionStatus = Json_decode.field("survey", (function (param) {
          return Json_decode.field("transaction_status", Json_decode.string, param);
        }), json);
  return [
          survey,
          CustomerInfo$ReactHooksTemplate.fromJson(json),
          client,
          transactionStatus,
          surveyLanguages,
          language,
          applicationSettings
        ];
}

function reducer(state, action) {
  return {
          survey: action._0,
          customerInfo: action._1,
          skipSurvey: state.skipSurvey,
          theme: state.theme,
          client: action._2,
          transactionStatus: action._3,
          surveyLanguages: action._4,
          language: action._5,
          applicationSettings: action._6
        };
}

function SurveyPage(Props) {
  var id = Props.id;
  var flagEnabled = Props.flagEnabled;
  var emptyState_survey = Survey$ReactHooksTemplate.empty(undefined);
  var emptyState_customerInfo = CustomerInfo$ReactHooksTemplate.empty(undefined);
  var emptyState_theme = SurveyTheme$ReactHooksTemplate.empty(undefined);
  var emptyState_client = Organization$ReactHooksTemplate.empty(undefined);
  var emptyState_applicationSettings = ApplicationSettings$ReactHooksTemplate.empty(undefined);
  var emptyState = {
    survey: emptyState_survey,
    customerInfo: emptyState_customerInfo,
    skipSurvey: false,
    theme: emptyState_theme,
    client: emptyState_client,
    transactionStatus: "",
    surveyLanguages: /* [] */0,
    language: SurveyLanguage$ReactHooksTemplate.$$default,
    applicationSettings: emptyState_applicationSettings
  };
  var match = React.useReducer(reducer, emptyState);
  var dispatch = match[1];
  var state = match[0];
  var match$1 = React.useState(function () {
        return /* Loading */0;
      });
  var setMode = match$1[1];
  React.useEffect((function () {
          Http$ReactHooksTemplate.fetchPostEncodedJSON("/api/v1/survey_details", Json_encode.object_({
                        hd: [
                          "token",
                          id
                        ],
                        tl: /* [] */0
                      })).then(function (json) {
                  return Promise.resolve(decodeSurvey(json));
                }).then(function (param) {
                Curry._1(dispatch, /* Initialize */{
                      _0: param[0],
                      _1: param[1],
                      _2: param[2],
                      _3: param[3],
                      _4: param[4],
                      _5: param[5],
                      _6: param[6]
                    });
                return Promise.resolve(Curry._1(setMode, (function (param) {
                                  return /* Ready */1;
                                })));
              });
          
        }), []);
  switch (match$1[0]) {
    case /* Loading */0 :
        return React.createElement("div", {
                    className: "h-screen w-full animated fadeIn"
                  }, React.createElement(Loading$ReactHooksTemplate.make, {
                        text: "Loading Survey..."
                      }));
    case /* Ready */1 :
        return React.createElement(React.Fragment, undefined, React.createElement(SurveyView$ReactHooksTemplate.make, {
                        flagEnabled: flagEnabled,
                        survey: state.survey,
                        client: state.client,
                        transactionStatus: state.transactionStatus,
                        surveyLanguages: state.surveyLanguages,
                        language: state.language,
                        customerInfo: state.customerInfo,
                        preview: false,
                        token: id,
                        applicationSettings: state.applicationSettings
                      }));
    case /* Saving */2 :
        return React.createElement("p", undefined, "Saving survey in progress...");
    
  }
}

var make = SurveyPage;

exports.decodeSurvey = decodeSurvey;
exports.reducer = reducer;
exports.make = make;
/* react Not a pure module */

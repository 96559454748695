// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var $$Array = require("rescript/lib/js/array.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Nps$ReactHooksTemplate = require("./Nps.bs.js");
var Image$ReactHooksTemplate = require("../common/image.bs.js");
var NpsLabel$ReactHooksTemplate = require("./NpsLabel.bs.js");
var CustomerInfo$ReactHooksTemplate = require("./CustomerInfo.bs.js");
var Organization$ReactHooksTemplate = require("../settings/organization.bs.js");
var QuestionText$ReactHooksTemplate = require("./QuestionText.bs.js");
var NpsDefaultForm$ReactHooksTemplate = require("./NpsDefaultForm.bs.js");
var SurveyLanguage$ReactHooksTemplate = require("./surveyLanguage.bs.js");
var MandatoryMessage$ReactHooksTemplate = require("./MandatoryMessage.bs.js");

function NpsForm$NpsItem(Props) {
  var step = Props.step;
  var className = Props.className;
  var imageClassName = Props.imageClassName;
  var onSelection = Props.onSelection;
  var style = Props.style;
  var representation = Props.representation;
  var imageBasedOnIndex = Props.imageBasedOnIndex;
  var client = Props.client;
  var match = Organization$ReactHooksTemplate.name(client);
  if (match === "yesbank") {
    return React.createElement("div", {
                className: "flex flex-col gap-2 justify-between"
              }, React.createElement("div", {
                    className: "small:h-6 h-12"
                  }, Curry._2(imageBasedOnIndex, step, imageClassName)), React.createElement("button", {
                    className: className,
                    style: style,
                    onClick: (function ($$event) {
                        $$event.preventDefault();
                        return Curry._1(onSelection, step);
                      })
                  }, representation ? React.createElement("div", {
                          className: "p-1"
                        }, React.createElement("span", undefined, String(step))) : React.createElement(React.Fragment, undefined)));
  } else {
    return React.createElement("button", {
                className: className,
                style: style,
                onClick: (function ($$event) {
                    $$event.preventDefault();
                    return Curry._1(onSelection, step);
                  })
              }, representation ? React.createElement("div", {
                      className: "py-5 small:py-2"
                    }, React.createElement("span", undefined, String(step))) : React.createElement(React.Fragment, undefined));
  }
}

var NpsItem = {
  make: NpsForm$NpsItem
};

function colorBasedOnLikelyness(index) {
  switch (index) {
    case 0 :
        return "#cf0000";
    case 1 :
        return "#da0000";
    case 2 :
        return "#f12500";
    case 3 :
        return "#ff331a";
    case 4 :
        return "#ff4118";
    case 5 :
        return "#ff541a";
    case 6 :
        return "#ff8000";
    case 7 :
        return "#ffc400";
    case 8 :
        return "#ffd50c";
    case 9 :
        return "#1abf00";
    case 10 :
        return "#169f00";
    default:
      return "#ffffff";
  }
}

function imageBasedOnIndex(index, imageClassName) {
  if (index >= 9) {
    if (index < 11) {
      return React.createElement("img", {
                  className: imageClassName,
                  src: Image$ReactHooksTemplate.happyButton
                });
    }
    
  } else if (index >= 7) {
    return React.createElement("img", {
                className: imageClassName,
                src: Image$ReactHooksTemplate.neutralButton
              });
  }
  return React.createElement("img", {
              className: imageClassName,
              src: Image$ReactHooksTemplate.sadButton
            });
}

function NpsForm(Props) {
  var nps = Props.nps;
  var customerInfo = Props.customerInfo;
  var surveyTheme = Props.surveyTheme;
  var language = Props.language;
  var client = Props.client;
  var mandatory = Props.mandatory;
  var onChange = Props.onChange;
  var borderCss = Props.borderCss;
  var steps = function (count) {
    return Belt_List.makeBy(count, (function (x) {
                  if (Nps$ReactHooksTemplate.includeZero(nps)) {
                    return x;
                  } else {
                    return x + 1 | 0;
                  }
                }));
  };
  var scale = Nps$ReactHooksTemplate.scale(nps);
  var includeZero = Nps$ReactHooksTemplate.includeZero(nps);
  var selectedRating = Nps$ReactHooksTemplate.rating(nps);
  var stepsCount = scale ? 11 : 5;
  var normalCss = "  w-1/" + stepsCount + " focus:outline-none text-white text-xl\n    font-medium ";
  var startRating = scale ? 0 : 1;
  var endRating = scale ? 10 : 5;
  var selectedCss = function (x) {
    if (Nps$ReactHooksTemplate.isAnswer(nps, x)) {
      return "text-blue-800";
    } else {
      return "";
    }
  };
  var selectedCssYesBank = function (x) {
    if (Nps$ReactHooksTemplate.isAnswer(nps, x)) {
      return "text-blue-800 border-2 border-bleudefrance";
    } else {
      return "";
    }
  };
  var selectedImageCssYesBank = function (x) {
    if (Nps$ReactHooksTemplate.isAnswer(nps, x)) {
      return "block";
    } else {
      return "hidden";
    }
  };
  var likelyness;
  if (scale) {
    if (selectedRating !== undefined) {
      switch (selectedRating) {
        case 0 :
        case 1 :
            likelyness = {
              TAG: /* VeryUnlikely */0,
              _0: selectedRating
            };
            break;
        case 2 :
        case 3 :
        case 4 :
        case 5 :
        case 6 :
            likelyness = {
              TAG: /* Unlikely */1,
              _0: selectedRating
            };
            break;
        case 7 :
        case 8 :
            likelyness = {
              TAG: /* Maybe */2,
              _0: selectedRating
            };
            break;
        case 9 :
            likelyness = {
              TAG: /* Likely */3,
              _0: selectedRating
            };
            break;
        case 10 :
            likelyness = {
              TAG: /* ExtremelyLikely */4,
              _0: selectedRating
            };
            break;
        default:
          likelyness = undefined;
      }
    } else {
      likelyness = undefined;
    }
  } else if (selectedRating !== undefined) {
    switch (selectedRating) {
      case 1 :
      case 2 :
          likelyness = {
            TAG: /* VeryUnlikely */0,
            _0: selectedRating
          };
          break;
      case 3 :
          likelyness = {
            TAG: /* Unlikely */1,
            _0: selectedRating
          };
          break;
      case 4 :
          likelyness = {
            TAG: /* Maybe */2,
            _0: selectedRating
          };
          break;
      case 5 :
          likelyness = {
            TAG: /* ExtremelyLikely */4,
            _0: selectedRating
          };
          break;
      default:
        likelyness = undefined;
    }
  } else {
    likelyness = undefined;
  }
  var filledBackgroundCSS;
  if (likelyness !== undefined) {
    switch (likelyness.TAG | 0) {
      case /* VeryUnlikely */0 :
          filledBackgroundCSS = "very-unlikely";
          break;
      case /* Unlikely */1 :
          filledBackgroundCSS = "unlikely";
          break;
      case /* Maybe */2 :
          filledBackgroundCSS = "maybe";
          break;
      case /* Likely */3 :
          filledBackgroundCSS = "likely";
          break;
      case /* ExtremelyLikely */4 :
          filledBackgroundCSS = "extremely-likely";
          break;
      
    }
  } else {
    filledBackgroundCSS = "";
  }
  var ratingEmoji;
  if (likelyness !== undefined) {
    switch (likelyness.TAG | 0) {
      case /* VeryUnlikely */0 :
          ratingEmoji = Image$ReactHooksTemplate.veryUnlikelySmiley;
          break;
      case /* Unlikely */1 :
          ratingEmoji = Image$ReactHooksTemplate.unlikelySmiley;
          break;
      case /* Maybe */2 :
          ratingEmoji = Image$ReactHooksTemplate.maybeSmiley;
          break;
      case /* Likely */3 :
          ratingEmoji = Image$ReactHooksTemplate.likelySmiley;
          break;
      case /* ExtremelyLikely */4 :
          ratingEmoji = Image$ReactHooksTemplate.extremelyLikelySmiley;
          break;
      
    }
  } else {
    ratingEmoji = "";
  }
  var ratingMessage;
  if (likelyness !== undefined) {
    switch (likelyness.TAG | 0) {
      case /* VeryUnlikely */0 :
          ratingMessage = Nps$ReactHooksTemplate.getVeryUnlikelyTranslation(nps, SurveyLanguage$ReactHooksTemplate.name(language));
          break;
      case /* Unlikely */1 :
          ratingMessage = Nps$ReactHooksTemplate.getUnlikelyTranslation(nps, SurveyLanguage$ReactHooksTemplate.name(language));
          break;
      case /* Maybe */2 :
          ratingMessage = Nps$ReactHooksTemplate.getMaybeTranslation(nps, SurveyLanguage$ReactHooksTemplate.name(language));
          break;
      case /* Likely */3 :
          ratingMessage = Nps$ReactHooksTemplate.getLikelyTranslation(nps, SurveyLanguage$ReactHooksTemplate.name(language));
          break;
      case /* ExtremelyLikely */4 :
          ratingMessage = Nps$ReactHooksTemplate.getExtremelyLikelyTranslation(nps, SurveyLanguage$ReactHooksTemplate.name(language));
          break;
      
    }
  } else {
    ratingMessage = "";
  }
  var rmName = CustomerInfo$ReactHooksTemplate.rmName(customerInfo);
  var questionText = Nps$ReactHooksTemplate.getTextTranslation(nps, SurveyLanguage$ReactHooksTemplate.name(language)).replace("${RM_NAME}", rmName);
  var count = scale ? 11 : 5;
  var ratings = $$Array.of_list(Belt_List.mapWithIndex(steps(count), (function (param, i) {
              return React.createElement("p", undefined, String(i));
            })));
  var items = function (a, b) {
    return $$Array.of_list(Belt_List.mapWithIndex(steps((b - a | 0) + 1 | 0), (function (i, x) {
                      var match = Nps$ReactHooksTemplate.representation(nps);
                      var tmp;
                      if (match) {
                        var match$1 = Organization$ReactHooksTemplate.name(client);
                        tmp = match$1 === "yesbank" ? "  small:w-6 small:h-6 w-12 h-12 focus:outline-none text-white small:text-xs text-xl\n    font-medium rounded " + (" " + selectedCssYesBank(x)) : normalCss + (" " + selectedCss(x));
                      } else {
                        tmp = "w-full";
                      }
                      var match$2 = Organization$ReactHooksTemplate.name(client);
                      var tmp$1 = match$2 === "yesbank" ? selectedImageCssYesBank(x) : "hidden";
                      var match$3 = Nps$ReactHooksTemplate.representation(nps);
                      return React.createElement(NpsForm$NpsItem, {
                                  step: includeZero ? x + a | 0 : (x + a | 0) - 1 | 0,
                                  className: tmp,
                                  imageClassName: tmp$1,
                                  onSelection: (function (scale) {
                                      return Curry._1(onChange, Nps$ReactHooksTemplate.setAnswer(nps, scale));
                                    }),
                                  style: match$3 ? ({
                                        backgroundColor: colorBasedOnLikelyness(x)
                                      }) : ({}),
                                  representation: Nps$ReactHooksTemplate.representation(nps),
                                  imageBasedOnIndex: imageBasedOnIndex,
                                  client: client,
                                  key: String(i + a | 0)
                                });
                    })));
  };
  var match = Organization$ReactHooksTemplate.name(client);
  switch (match) {
    case "bhima" :
    case "ncell" :
    case "nykaa" :
        break;
    case "yesbank" :
        var match$1 = Nps$ReactHooksTemplate.representation(nps);
        var tmp;
        if (match$1) {
          tmp = React.createElement("div", {
                className: "flex flex-col justify-between items-start"
              }, React.createElement("div", {
                    className: "flex justify-center small:gap-1 gap-2 w-full"
                  }, items(startRating, endRating)), React.createElement("div", {
                    className: " flex justify-between  w-full pt-6 small:pt-3\n              text-lg small:text-xs pb-10"
                  }, React.createElement("div", {
                        className: "flex flex-col text-center text-red-600"
                      }, React.createElement("p", undefined, NpsLabel$ReactHooksTemplate.left(Nps$ReactHooksTemplate.getLabelTranslation(nps, SurveyLanguage$ReactHooksTemplate.name(language))))), React.createElement("div", {
                        className: "flex flex-col text-center text-green-600"
                      }, React.createElement("p", undefined, NpsLabel$ReactHooksTemplate.right(Nps$ReactHooksTemplate.getLabelTranslation(nps, SurveyLanguage$ReactHooksTemplate.name(language)))))), mandatory ? React.createElement(MandatoryMessage$ReactHooksTemplate.make, {
                      language: language
                    }) : React.createElement(React.Fragment, undefined));
        } else {
          var tmp$1;
          if (likelyness !== undefined) {
            var tmp$2;
            if (likelyness.TAG === /* ExtremelyLikely */4) {
              tmp$2 = React.createElement("div", {
                    className: " flex justify-between divide-x w-full\n          " + filledBackgroundCSS + " h-78-px small:h-48-px rounded-lg\n            divide-tropicalbreeze mb-16-px small:mb-8-px"
                  }, items(startRating, endRating));
            } else {
              var rating = likelyness._0;
              var filledCount = String(includeZero ? rating + 1 | 0 : rating);
              var unFilledCount = String(stepsCount - (
                    includeZero ? rating + 1 | 0 : rating
                  ) | 0);
              tmp$2 = React.createElement("div", {
                    className: "flex w-full mb-17-px small:mb-4-px bg-tropicalbreeze rounded-lg"
                  }, React.createElement("div", {
                        className: " flex justify-between divide-x\n                        w-" + filledCount + "/" + stepsCount + "\n          h-78-px small:h-48-px rounded-lg rounded-r-none divide-tropicalbreeze\n            " + filledBackgroundCSS
                      }, items(startRating, rating)), React.createElement("div", {
                        className: " flex justify-between divide-x\n                        w-" + unFilledCount + "/" + stepsCount + "\n          bg-tropicalbreeze h-78-px small:h-48-px rounded-lg rounded-l-none divide-herringsilver"
                      }, items(rating + 1 | 0, endRating)));
            }
            tmp$1 = React.createElement(React.Fragment, undefined, tmp$2, React.createElement("div", {
                      className: "w-full flex flex-col items-center"
                    }, React.createElement("img", {
                          className: "mx-auto w-200-px small:w-160-px h-200-px\n                  small:h-160-px mb-8-px",
                          src: ratingEmoji
                        }), React.createElement("p", {
                          className: "font-normal font-semibold text-2xl small:text-xl leading-8\n                  small:leading-4 text-center tracking-wide"
                        }, ratingMessage)));
          } else {
            tmp$1 = React.createElement(React.Fragment, undefined, React.createElement("div", {
                      className: "flex justify-between divide-x w-full\n          bg-tropicalbreeze h-78-px small:h-48-px rounded-lg divide-herringsilver\n          mb-17-px small:mb-4-px"
                    }, items(startRating, endRating)), React.createElement("div", {
                      className: "flex justify-between w-full text-base small:text-sm font-semibold\n          leading-4 tracking-wide"
                    }, React.createElement("div", {
                          className: "flex flex-col text-center items-start"
                        }, React.createElement("span", undefined, React.createElement("img", {
                                  className: "w-60-px h-60-px",
                                  src: Image$ReactHooksTemplate.sadRedSmiley
                                })), React.createElement("p", undefined, NpsLabel$ReactHooksTemplate.left(Nps$ReactHooksTemplate.label(nps)))), React.createElement("div", {
                          className: "flex flex-col text-center items-end"
                        }, React.createElement("span", undefined, React.createElement("img", {
                                  className: "w-60-px h-60-px",
                                  src: Image$ReactHooksTemplate.happyGreenSmiley
                                })), React.createElement("p", undefined, NpsLabel$ReactHooksTemplate.right(Nps$ReactHooksTemplate.label(nps))))), mandatory ? React.createElement("p", {
                        className: " w-full pt-6 small:pt-3\n            text-lg opacity-50"
                      }, "* ", React.createElement("span", {
                            className: "text-xs"
                          }, "Request your response. This is a mandatory question")) : React.createElement(React.Fragment, undefined));
          }
          tmp = React.createElement("div", {
                className: "flex flex-col justify-between items-start"
              }, React.createElement("div", {
                    className: "flex justify-between w-full font-semibold text-base\n          text-center tracking-wide font-normal mb-8-px small:mb-4-px"
                  }, ratings), tmp$1);
        }
        return React.createElement("div", {
                    className: "w-full py-8 px-16 small:px-6 " + borderCss
                  }, React.createElement("div", undefined, React.createElement("div", {
                            className: "text-lg font-medium text-darkcharcoal py-6"
                          }, questionText), React.createElement("div", {
                            className: "text-base font-normal text-darkcharcoal pb-8"
                          }, Nps$ReactHooksTemplate.getDescriptionTranslation(nps, SurveyLanguage$ReactHooksTemplate.name(language)))), tmp);
    default:
      return React.createElement(NpsDefaultForm$ReactHooksTemplate.make, {
                  nps: nps,
                  customerInfo: customerInfo,
                  language: language,
                  mandatory: mandatory,
                  onChange: onChange,
                  borderCss: borderCss
                });
  }
  var match$2 = Nps$ReactHooksTemplate.representation(nps);
  var tmp$3;
  if (match$2) {
    tmp$3 = React.createElement("div", {
          className: "flex flex-col justify-between items-start"
        }, React.createElement("div", {
              className: "flex border rounded-sm justify-between divide-x w-full border-white bg-white"
            }, items(startRating, endRating)), React.createElement("div", {
              className: " flex justify-between  w-full pt-6 small:pt-3\n              text-lg small:text-xs pb-10"
            }, React.createElement("div", {
                  className: "flex flex-col text-center"
                }, React.createElement("p", undefined, NpsLabel$ReactHooksTemplate.left(Nps$ReactHooksTemplate.getLabelTranslation(nps, SurveyLanguage$ReactHooksTemplate.name(language)))), React.createElement("span", {
                      className: "flex justify-center"
                    }, React.createElement("img", {
                          className: "h-5",
                          src: Image$ReactHooksTemplate.sadRedSmileyYesbank
                        }))), React.createElement("p", undefined, NpsLabel$ReactHooksTemplate.center(Nps$ReactHooksTemplate.getLabelTranslation(nps, SurveyLanguage$ReactHooksTemplate.name(language)))), React.createElement("div", {
                  className: "flex flex-col justify-center"
                }, React.createElement("p", undefined, NpsLabel$ReactHooksTemplate.right(Nps$ReactHooksTemplate.getLabelTranslation(nps, SurveyLanguage$ReactHooksTemplate.name(language)))), React.createElement("span", {
                      className: "flex justify-center"
                    }, React.createElement("img", {
                          className: "h-5",
                          src: Image$ReactHooksTemplate.happyGreenSmileyYesbank
                        })))), mandatory ? React.createElement(MandatoryMessage$ReactHooksTemplate.make, {
                language: language
              }) : React.createElement(React.Fragment, undefined));
  } else {
    var tmp$4;
    if (likelyness !== undefined) {
      var tmp$5;
      if (likelyness.TAG === /* ExtremelyLikely */4) {
        tmp$5 = React.createElement("div", {
              className: " flex justify-between divide-x w-full\n          " + filledBackgroundCSS + " h-78-px small:h-48-px rounded-lg\n            divide-tropicalbreeze mb-16-px small:mb-8-px"
            }, items(startRating, endRating));
      } else {
        var rating$1 = likelyness._0;
        var filledCount$1 = String(includeZero ? rating$1 + 1 | 0 : rating$1);
        var unFilledCount$1 = String(stepsCount - (
              includeZero ? rating$1 + 1 | 0 : rating$1
            ) | 0);
        tmp$5 = React.createElement("div", {
              className: "flex w-full mb-17-px small:mb-4-px bg-tropicalbreeze rounded-lg"
            }, React.createElement("div", {
                  className: " flex justify-between divide-x\n                        w-" + filledCount$1 + "/" + stepsCount + "\n          h-78-px small:h-48-px rounded-lg rounded-r-none divide-tropicalbreeze\n            " + filledBackgroundCSS
                }, items(startRating, rating$1)), React.createElement("div", {
                  className: " flex justify-between divide-x\n                        w-" + unFilledCount$1 + "/" + stepsCount + "\n          bg-tropicalbreeze h-78-px small:h-48-px rounded-lg rounded-l-none divide-herringsilver"
                }, items(rating$1 + 1 | 0, endRating)));
      }
      tmp$4 = React.createElement(React.Fragment, undefined, tmp$5, React.createElement("div", {
                className: "w-full flex flex-col items-center"
              }, React.createElement("img", {
                    className: "mx-auto w-200-px small:w-160-px h-200-px\n                  small:h-160-px mb-8-px",
                    src: ratingEmoji
                  }), React.createElement("p", {
                    className: "font-normal font-semibold text-2xl small:text-xl leading-8\n                  small:leading-4 text-center tracking-wide"
                  }, ratingMessage)));
    } else {
      tmp$4 = React.createElement(React.Fragment, undefined, React.createElement("div", {
                className: "flex justify-between divide-x w-full\n          bg-tropicalbreeze h-78-px small:h-48-px rounded-lg divide-herringsilver\n          mb-17-px small:mb-4-px"
              }, items(startRating, endRating)), React.createElement("div", {
                className: "flex justify-between w-full text-base small:text-sm font-semibold\n          leading-4 tracking-wide"
              }, React.createElement("div", {
                    className: "flex flex-col text-center items-start"
                  }, React.createElement("span", undefined, React.createElement("img", {
                            className: "w-60-px h-60-px",
                            src: Image$ReactHooksTemplate.sadRedSmiley
                          })), React.createElement("p", undefined, NpsLabel$ReactHooksTemplate.left(Nps$ReactHooksTemplate.getLabelTranslation(nps, SurveyLanguage$ReactHooksTemplate.name(language))))), React.createElement("div", {
                    className: "flex flex-col text-center items-end"
                  }, React.createElement("span", undefined, React.createElement("img", {
                            className: "w-60-px h-60-px",
                            src: Image$ReactHooksTemplate.happyGreenSmiley
                          })), React.createElement("p", undefined, NpsLabel$ReactHooksTemplate.right(Nps$ReactHooksTemplate.getLabelTranslation(nps, SurveyLanguage$ReactHooksTemplate.name(language)))))), mandatory ? React.createElement("p", {
                  className: " w-full pt-6 small:pt-3\n            text-lg opacity-50"
                }, "* ", React.createElement("span", {
                      className: "text-xs"
                    }, "Request your response. This is a mandatory question")) : React.createElement(React.Fragment, undefined));
    }
    tmp$3 = React.createElement("div", {
          className: "flex flex-col justify-between items-start"
        }, React.createElement("div", {
              className: "flex justify-between w-full font-semibold text-base\n          text-center tracking-wide font-normal mb-8-px small:mb-4-px"
            }, ratings), tmp$4);
  }
  return React.createElement(React.Fragment, undefined, React.createElement(QuestionText$ReactHooksTemplate.make, {
                  text: questionText,
                  description: Nps$ReactHooksTemplate.getDescriptionTranslation(nps, SurveyLanguage$ReactHooksTemplate.name(language)),
                  mandatory: mandatory,
                  surveyTheme: surveyTheme
                }), tmp$3);
}

var make = NpsForm;

exports.NpsItem = NpsItem;
exports.colorBasedOnLikelyness = colorBasedOnLikelyness;
exports.imageBasedOnIndex = imageBasedOnIndex;
exports.make = make;
/* react Not a pure module */

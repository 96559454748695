// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var List = require("rescript/lib/js/list.js");
var $$Array = require("rescript/lib/js/array.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Http$ReactHooksTemplate = require("./common/http.bs.js");

function id(t) {
  return t.id;
}

function title(t) {
  return t.title;
}

function link(t) {
  return t.link;
}

function description(t) {
  return t.description;
}

function decode(json) {
  return {
          id: Json_decode.field("id", Json_decode.$$int, json),
          title: Json_decode.field("title", Json_decode.string, json),
          link: Json_decode.field("link", Json_decode.string, json),
          description: Json_decode.field("description", Json_decode.string, json)
        };
}

var PageItem = {
  id: id,
  title: title,
  link: link,
  description: description,
  decode: decode
};

function AdminView(Props) {
  var match = React.useState(function () {
        return /* [] */0;
      });
  var setState = match[1];
  React.useEffect((function () {
          Http$ReactHooksTemplate.fetchGetJSONDecode("/api/v1/client_specific_pages", (function (param) {
                    return Json_decode.field("pages", (function (param) {
                                  return Json_decode.list(decode, param);
                                }), param);
                  })).then(function (page_item) {
                return Promise.resolve(Curry._1(setState, (function (param) {
                                  return page_item;
                                })));
              });
          
        }), []);
  return React.createElement("div", {
              className: "p-20-px"
            }, React.createElement("div", {
                  className: "container mt-4 mx-auto"
                }, React.createElement("h2", {
                      className: "mb-32-px text-2xl"
                    }, "Admin View"), React.createElement("div", {
                      className: "grid grid-cols-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4"
                    }, $$Array.of_list(List.map((function (sList) {
                                return React.createElement("div", {
                                            key: String(sList.id),
                                            className: "card m-2 border border-gray-400 rounded-lg hover:shadow-md hover:border-opacity-0 transform hover:-translate-y-1 transition-all duration-200"
                                          }, React.createElement("div", {
                                                className: "m-3"
                                              }, React.createElement("h2", {
                                                    className: "text-lg mb-2"
                                                  }, sList.title, React.createElement("span", {
                                                        className: "text-sm text-teal-800 font-mono bg-teal-100 inline rounded-full px-2 align-top float-right animate-pulse"
                                                      }, React.createElement("a", {
                                                            className: "text-blue underline",
                                                            href: sList.link
                                                          }, "View"))), React.createElement("p", {
                                                    className: "font-light font-mono text-sm text-gray-700 hover:text-gray-900 transition-all duration-200"
                                                  }, sList.description)));
                              }), match[0])))));
}

var emptyState = /* [] */0;

var make = AdminView;

exports.PageItem = PageItem;
exports.emptyState = emptyState;
exports.make = make;
/* react Not a pure module */

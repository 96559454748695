// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var MobileNumber$ReactHooksTemplate = require("./MobileNumber.bs.js");
var SurveyLanguage$ReactHooksTemplate = require("./surveyLanguage.bs.js");
var MandatoryMessage$ReactHooksTemplate = require("./MandatoryMessage.bs.js");

function MobileNumberDefaultForm(Props) {
  var mobileNumber = Props.mobileNumber;
  var language = Props.language;
  var mandatory = Props.mandatory;
  var onChange = Props.onChange;
  var borderCss = Props.borderCss;
  var handleOnchange = function ($$event) {
    var value = $$event.target.value;
    var firstChar = value[0];
    var check = Belt_Array.getBy([
          "<",
          "=",
          "+",
          "-",
          "@"
        ], (function (x) {
            return firstChar === x;
          }));
    if (check !== undefined) {
      Curry._1(onChange, MobileNumber$ReactHooksTemplate.setAnswer(mobileNumber, ""));
    } else {
      Curry._1(onChange, MobileNumber$ReactHooksTemplate.setAnswer(mobileNumber, value));
    }
    var validNumber = value.search(/^\d{1,17}$/);
    if (validNumber !== 0) {
      return Curry._1(onChange, MobileNumber$ReactHooksTemplate.setAnswer(mobileNumber, ""));
    } else {
      return Curry._1(onChange, MobileNumber$ReactHooksTemplate.setAnswer(mobileNumber, value));
    }
  };
  return React.createElement("div", {
              className: "w-full py-8 px-16 small:px-6 " + borderCss
            }, React.createElement("div", undefined, React.createElement("div", {
                      className: "text-lg font-medium text-darkcharcoal py-6"
                    }, MobileNumber$ReactHooksTemplate.getTextTranslation(mobileNumber, SurveyLanguage$ReactHooksTemplate.name(language))), React.createElement("div", {
                      className: "text-base font-normal text-darkcharcoal pb-8"
                    }, MobileNumber$ReactHooksTemplate.getDescriptionTranslation(mobileNumber, SurveyLanguage$ReactHooksTemplate.name(language)))), React.createElement("div", {
                  className: "flex w-full pb-6"
                }, React.createElement("input", {
                      className: "h-48-px py-2 px-4 bg-alicebluepurple focus:border-gray-600 text-xs w-full",
                      maxLength: 15,
                      placeholder: MobileNumber$ReactHooksTemplate.getPlaceholderTranslation(mobileNumber, SurveyLanguage$ReactHooksTemplate.name(language)),
                      type: "text",
                      value: MobileNumber$ReactHooksTemplate.answerToString(mobileNumber),
                      onChange: handleOnchange
                    })), mandatory ? React.createElement(MandatoryMessage$ReactHooksTemplate.make, {
                    language: language
                  }) : React.createElement(React.Fragment, undefined));
}

var make = MobileNumberDefaultForm;

exports.make = make;
/* react Not a pure module */

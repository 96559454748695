// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");

function name(t) {
  return t.name;
}

function empty(param) {
  return {
          name: ""
        };
}

function decode(json) {
  return {
          name: Json_decode.field("name", Json_decode.string, json)
        };
}

var templateType = "CustomReport";

exports.name = name;
exports.templateType = templateType;
exports.empty = empty;
exports.decode = decode;
/* No side effect */

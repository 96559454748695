// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var $$Array = require("rescript/lib/js/array.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var DataField$ReactHooksTemplate = require("../settings/dataField.bs.js");
var AggregateField$ReactHooksTemplate = require("./AggregateField.bs.js");
var AggregateReport$ReactHooksTemplate = require("./AggregateReport.bs.js");

function AggregateReportForm(Props) {
  var template = Props.template;
  var handleAggregateReportChange = Props.handleAggregateReportChange;
  var dataFields = Props.dataFields;
  var metric = AggregateReport$ReactHooksTemplate.metric(template);
  var aggregateField = AggregateReport$ReactHooksTemplate.aggregateFields(template);
  var tmp;
  if (aggregateField !== undefined) {
    var dataField = AggregateField$ReactHooksTemplate.dataField(aggregateField);
    tmp = dataField !== undefined ? DataField$ReactHooksTemplate.name(Caml_option.valFromOption(dataField)) : "Select DataField";
  } else {
    tmp = "Select DataField";
  }
  var aggregateField$1 = AggregateReport$ReactHooksTemplate.aggregateFields(template);
  var tmp$1;
  if (aggregateField$1 !== undefined && AggregateField$ReactHooksTemplate.isTimestamp(aggregateField$1)) {
    var aggregateField$2 = AggregateReport$ReactHooksTemplate.aggregateFields(template);
    var tmp$2;
    if (aggregateField$2 !== undefined) {
      var timeFrame = AggregateField$ReactHooksTemplate.timeFrame(aggregateField$2);
      tmp$2 = timeFrame !== undefined ? AggregateField$ReactHooksTemplate.timeFrameToString(timeFrame) : "Select Timeframe";
    } else {
      tmp$2 = "Select Timeframe";
    }
    tmp$1 = React.createElement("div", {
          className: "flex mb-30-px items-center"
        }, React.createElement("div", {
              className: "flex gap-1 items-center w-full"
            }, React.createElement("div", {
                  className: "text-sm font-medium w-1/5"
                }, "Group by"), React.createElement("select", {
                  className: "block border px-3 py-2 border-gray-400\n                                w-3/5 bg-white rounded capitalize mr-6 text-sm",
                  placeholder: "Select Timeframe",
                  value: tmp$2,
                  onChange: Curry._2(handleAggregateReportChange, template, "timeFrame")
                }, React.createElement("option", {
                      key: "0",
                      disabled: true,
                      value: "Select Timeframe"
                    }, "Select Timeframe"), $$Array.of_list(Belt_List.map(AggregateField$ReactHooksTemplate.timeFrames, (function (timeFrame) {
                            return React.createElement("option", {
                                        key: timeFrame,
                                        value: timeFrame
                                      }, timeFrame);
                          }))))));
  } else {
    tmp$1 = React.createElement(React.Fragment, undefined);
  }
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  className: "flex mb-20-px items-center"
                }, React.createElement("div", {
                      className: "flex gap-1 items-center w-full"
                    }, React.createElement("div", {
                          className: "text-sm font-bold"
                        }, "Aggregate Report Fields"))), React.createElement("div", {
                  className: "flex mb-30-px items-center"
                }, React.createElement("div", {
                      className: "flex gap-1 items-center w-full"
                    }, React.createElement("div", {
                          className: "text-sm font-medium w-1/5"
                        }, "Metric"), React.createElement("select", {
                          className: "block border px-3 py-2 border-gray-400\n                      w-3/5 bg-white rounded capitalize mr-6 text-sm",
                          placeholder: "Select Metric",
                          value: metric !== undefined ? AggregateReport$ReactHooksTemplate.metricToString(metric) : "Select Metric",
                          onChange: Curry._2(handleAggregateReportChange, template, "metric")
                        }, React.createElement("option", {
                              key: "0",
                              disabled: true,
                              value: "Select Metric"
                            }, "Select Metric"), $$Array.of_list(Belt_List.map(AggregateReport$ReactHooksTemplate.metrics, (function (metricString) {
                                    return React.createElement("option", {
                                                key: metricString,
                                                value: metricString
                                              }, metricString);
                                  })))))), React.createElement("div", {
                  className: "flex mb-30-px items-center"
                }, React.createElement("div", {
                      className: "flex gap-1 items-center w-full"
                    }, React.createElement("div", {
                          className: "text-sm font-medium w-1/5"
                        }, "DataField"), React.createElement("select", {
                          className: "block border px-3 py-2 border-gray-400\n                      w-3/5 bg-white rounded mr-6 text-sm",
                          placeholder: "Select DataField",
                          value: tmp,
                          onChange: Curry._2(handleAggregateReportChange, template, "dataField")
                        }, React.createElement("option", {
                              key: "0",
                              disabled: true,
                              value: "Select DataField"
                            }, "Select DataField"), $$Array.of_list(Belt_List.map(dataFields, (function (dataField) {
                                    return React.createElement("option", {
                                                key: String(DataField$ReactHooksTemplate.id(dataField)),
                                                value: DataField$ReactHooksTemplate.name(dataField)
                                              }, DataField$ReactHooksTemplate.name(dataField));
                                  })))))), tmp$1);
}

var make = AggregateReportForm;

exports.make = make;
/* react Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Image$ReactHooksTemplate = require("../common/image.bs.js");

function UserKpiListHeader(Props) {
  return React.createElement("thead", {
              className: "font-medium p-4 bg-ghostwhite text-aurometalsaurus"
            }, React.createElement("tr", undefined, React.createElement("th", {
                      className: "px-4 py-3"
                    }, React.createElement("div", {
                          className: "flex"
                        }, "Email", React.createElement("img", {
                              className: "ml-4-px",
                              src: Image$ReactHooksTemplate.heading
                            }))), React.createElement("th", {
                      className: "px-4 py-3"
                    }, React.createElement("div", {
                          className: "flex"
                        }, "Field", React.createElement("img", {
                              className: "ml-4-px",
                              src: Image$ReactHooksTemplate.heading
                            }))), React.createElement("th", {
                      className: "px-4 py-3"
                    }, React.createElement("div", {
                          className: "flex"
                        }, "Value", React.createElement("img", {
                              className: "ml-4-px",
                              src: Image$ReactHooksTemplate.heading
                            }))), React.createElement("th", {
                      className: "px-4 py-3"
                    }, React.createElement("div", {
                          className: "flex"
                        }, "KPI", React.createElement("img", {
                              className: "ml-4-px",
                              src: Image$ReactHooksTemplate.heading
                            }))), React.createElement("th", {
                      className: "px-4 py-3"
                    }, React.createElement("div", {
                          className: "flex"
                        }, ""))));
}

var make = UserKpiListHeader;

exports.make = make;
/* react Not a pure module */

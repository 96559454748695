// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");

function empty(param) {
  return "";
}

function isEmpty(t) {
  return t === "";
}

var $eq = Caml_obj.caml_equal;

function create(t) {
  return t;
}

function show(t) {
  return t;
}

var decode = Json_decode.string;

var Email = {
  empty: empty,
  isEmpty: isEmpty,
  $eq: $eq,
  create: create,
  show: show,
  decode: decode
};

function empty$1(param) {
  return "";
}

function isEmpty$1(t) {
  return t === "";
}

var $eq$1 = Caml_obj.caml_equal;

function create$1(t) {
  return t;
}

function show$1(t) {
  return t;
}

var decode$1 = Json_decode.string;

var Name = {
  empty: empty$1,
  isEmpty: isEmpty$1,
  $eq: $eq$1,
  create: create$1,
  show: show$1,
  decode: decode$1
};

function empty$2(param) {
  return "";
}

function isEmpty$2(t) {
  return t === "";
}

var $eq$2 = Caml_obj.caml_equal;

function create$2(t) {
  return t;
}

function show$2(t) {
  return t;
}

var decode$2 = Json_decode.string;

var FirstName = {
  empty: empty$2,
  isEmpty: isEmpty$2,
  $eq: $eq$2,
  create: create$2,
  show: show$2,
  decode: decode$2
};

function empty$3(param) {
  return "";
}

function isEmpty$3(t) {
  return t === "";
}

var $eq$3 = Caml_obj.caml_equal;

function create$3(t) {
  return t;
}

function show$3(t) {
  return t;
}

var decode$3 = Json_decode.string;

var LastName = {
  empty: empty$3,
  isEmpty: isEmpty$3,
  $eq: $eq$3,
  create: create$3,
  show: show$3,
  decode: decode$3
};

function empty$4(param) {
  return "";
}

function isEmpty$4(t) {
  return t === "";
}

var $eq$4 = Caml_obj.caml_equal;

function create$4(t) {
  return t;
}

function show$4(t) {
  return t;
}

var decode$4 = Json_decode.string;

var Description = {
  empty: empty$4,
  isEmpty: isEmpty$4,
  $eq: $eq$4,
  create: create$4,
  show: show$4,
  decode: decode$4
};

function empty$5(param) {
  return "";
}

var $eq$5 = Caml_obj.caml_equal;

function isEmpty$5(t) {
  return Caml_obj.caml_equal(t, "");
}

function create$5(t) {
  return t;
}

function show$5(t) {
  return t;
}

var decode$5 = Json_decode.string;

var Password = {
  empty: empty$5,
  isEmpty: isEmpty$5,
  $eq: $eq$5,
  create: create$5,
  show: show$5,
  decode: decode$5
};

function empty$6(param) {
  return "";
}

var $eq$6 = Caml_obj.caml_equal;

function isEmpty$6(t) {
  return Caml_obj.caml_equal(t, "");
}

function create$6(t) {
  return t;
}

function show$6(t) {
  return t;
}

var decode$6 = Json_decode.string;

var Msisdn = {
  empty: empty$6,
  isEmpty: isEmpty$6,
  $eq: $eq$6,
  create: create$6,
  show: show$6,
  decode: decode$6
};

var $$String = {
  Email: Email,
  Name: Name,
  FirstName: FirstName,
  LastName: LastName,
  Description: Description,
  Password: Password,
  Msisdn: Msisdn
};

var Email$1;

var Name$1;

var Description$1;

var Password$1;

var Msisdn$1;

var FirstName$1;

var LastName$1;

exports.$$String = $$String;
exports.Email = Email$1;
exports.Name = Name$1;
exports.Description = Description$1;
exports.Password = Password$1;
exports.Msisdn = Msisdn$1;
exports.FirstName = FirstName$1;
exports.LastName = LastName$1;
/* No side effect */

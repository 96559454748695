// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var List = require("rescript/lib/js/list.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");
var RandomId$ReactHooksTemplate = require("./RandomId.bs.js");

function decode(json) {
  return {
          text: Json_decode.field("text", Json_decode.string, json)
        };
}

function make(text) {
  return {
          id: undefined,
          uuid: RandomId$ReactHooksTemplate.generate(undefined),
          text: text,
          image: undefined,
          translations: /* [] */0
        };
}

function empty(param) {
  return {
          id: undefined,
          uuid: RandomId$ReactHooksTemplate.generate(undefined),
          text: "",
          image: undefined,
          translations: /* [] */0
        };
}

function text(t) {
  return t.text;
}

function getTextTranslation(t, language) {
  var translation = List.find_opt((function (param) {
          return param[0] === language;
        }), t.translations);
  if (translation !== undefined) {
    return translation[1].text;
  } else {
    return t.text;
  }
}

function setText(t, text) {
  return {
          id: t.id,
          uuid: t.uuid,
          text: text,
          image: t.image,
          translations: t.translations
        };
}

function image(t) {
  return t.image;
}

function uuid(t) {
  return t.uuid;
}

function id(t) {
  return t.id;
}

function toJson(destroyOpt, position, t) {
  var destroy = destroyOpt !== undefined ? destroyOpt : false;
  return Json_encode.object_({
              hd: [
                "text",
                t.text
              ],
              tl: {
                hd: [
                  "position",
                  position
                ],
                tl: {
                  hd: [
                    "id",
                    Json_encode.nullable((function (prim) {
                            return prim;
                          }), t.id)
                  ],
                  tl: {
                    hd: [
                      "uuid",
                      RandomId$ReactHooksTemplate.toJson(t.uuid)
                    ],
                    tl: {
                      hd: [
                        "_destroy",
                        destroy
                      ],
                      tl: /* [] */0
                    }
                  }
                }
              }
            });
}

function decodeTranslation(json) {
  return [
          Json_decode.field("survey_language", (function (param) {
                  return Json_decode.field("name", Json_decode.string, param);
                }), json),
          Json_decode.field("key_string_mapping", decode, json)
        ];
}

function fromJson(json) {
  return {
          id: Json_decode.optional((function (param) {
                  return Json_decode.field("id", Json_decode.$$int, param);
                }), json),
          uuid: Json_decode.field("uuid", RandomId$ReactHooksTemplate.fromJson, json),
          text: Json_decode.field("text", Json_decode.string, json),
          image: Json_decode.field("img_url", (function (param) {
                  return Json_decode.optional(Json_decode.string, param);
                }), json),
          translations: Json_decode.field("translations", (function (param) {
                  return Json_decode.list(decodeTranslation, param);
                }), json)
        };
}

var categoryType = "thank_you";

exports.categoryType = categoryType;
exports.make = make;
exports.empty = empty;
exports.text = text;
exports.getTextTranslation = getTextTranslation;
exports.image = image;
exports.uuid = uuid;
exports.id = id;
exports.setText = setText;
exports.toJson = toJson;
exports.fromJson = fromJson;
/* RandomId-ReactHooksTemplate Not a pure module */

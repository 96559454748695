// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Moment = require("moment");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var FeedFile$ReactHooksTemplate = require("./feedFile.bs.js");

function id(t) {
  return t.id;
}

function uid(t) {
  return t.uid;
}

function status(t) {
  return t.status;
}

function customerSourceType(t) {
  return t.customerSourceType;
}

function customerSource(t) {
  return t.customerSource;
}

function createdAt(t) {
  return t.createdAt;
}

function decode(json) {
  Json_decode.field("customer_source_type", Json_decode.string, json);
  return {
          id: Json_decode.field("id", Json_decode.$$int, json),
          uid: Json_decode.field("uid", Json_decode.string, json),
          customerSource: /* FeedFile */{
            _0: Json_decode.field("customer_source", FeedFile$ReactHooksTemplate.decode, json)
          },
          customerSourceType: Json_decode.field("customer_source_type", Json_decode.string, json),
          status: Json_decode.field("status", Json_decode.string, json),
          createdAt: Moment(new Date(Json_decode.field("created_at", Json_decode.string, json)))
        };
}

exports.id = id;
exports.uid = uid;
exports.status = status;
exports.customerSourceType = customerSourceType;
exports.customerSource = customerSource;
exports.createdAt = createdAt;
exports.decode = decode;
/* moment Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var List = require("rescript/lib/js/list.js");
var $$Array = require("rescript/lib/js/array.js");
var React = require("react");
var Image$ReactHooksTemplate = require("../../common/image.bs.js");
var ChartId$ReactHooksTemplate = require("./chartId.bs.js");
var TableAttribute$ReactHooksTemplate = require("./tableAttribute.bs.js");

function TableComponent(Props) {
  var attribute = Props.attribute;
  var chartHeight = Props.chartHeight;
  var chartId = Props.chartId;
  var match = ChartId$ReactHooksTemplate.name(chartId);
  switch (match) {
    case "Digital Journey Wise Report" :
        return React.createElement("div", {
                    className: " " + chartHeight + " w-full overflow-y-auto "
                  }, React.createElement("table", {
                        className: "w-full h-full text-center border-collapse table-fixed px-2 pb-2"
                      }, React.createElement("thead", {
                            className: "bg-gray-100"
                          }, React.createElement("tr", undefined, React.createElement("th", {
                                    className: "py-3 px-4 text-left text-gray-700 font-semibold border-b"
                                  }, "Customer Journey"), React.createElement("th", {
                                    className: "py-3 px-4 text-left text-gray-700 font-semibold border-b"
                                  }, "Awareness"), React.createElement("th", {
                                    className: "py-3 px-4 text-left text-gray-700 font-semibold border-b"
                                  }, "Interest"), React.createElement("th", {
                                    className: "py-3 px-4 text-left text-gray-700 font-semibold border-b"
                                  }, "Consideration"), React.createElement("th", {
                                    className: "py-3 px-4 text-left text-gray-700 font-semibold border-b"
                                  }, "Signup"), React.createElement("th", {
                                    className: "py-3 px-4 text-left text-gray-700 font-semibold border-b"
                                  }, "Setup"), React.createElement("th", {
                                    className: "py-3 px-4 text-left text-gray-700 font-semibold border-b"
                                  }, "First Use"), React.createElement("th", {
                                    className: "py-3 px-4 text-left text-gray-700 font-semibold border-b"
                                  }, "Customer Support"), React.createElement("th", {
                                    className: "py-3 px-4 text-left text-gray-700 font-semibold border-b"
                                  }, "Ongoing Use"), React.createElement("th", {
                                    className: "py-3 px-4 text-left text-gray-700 font-semibold border-b"
                                  }, "Upsell/Cross-Sell"), React.createElement("th", {
                                    className: "py-3 px-4 text-left text-gray-700 font-semibold border-b"
                                  }, "Feedback & Advocacy"))), React.createElement("tbody", undefined, React.createElement("tr", undefined, React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800"
                                  }, "Overall SAT"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800"
                                  }, React.createElement("span", {
                                        className: "inline-flex items-center space-x-1"
                                      }, "60", React.createElement("svg", {
                                            className: "h-8 w-8 text-green-500",
                                            height: "24",
                                            width: "24",
                                            fill: "none",
                                            stroke: "currentColor",
                                            strokeLinecap: "round",
                                            strokeLinejoin: "round",
                                            strokeWidth: "2",
                                            viewBox: "0 0 24 24"
                                          }, React.createElement("path", {
                                                d: "M0 0h24v24H0z",
                                                stroke: "none"
                                              }), React.createElement("path", {
                                                d: "M18 15l-6-6l-6 6h12"
                                              })))), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800"
                                  }, React.createElement("span", {
                                        className: "inline-flex items-center space-x-1"
                                      }, "70", React.createElement("svg", {
                                            className: "h-8 w-8 text-red-500",
                                            height: "24",
                                            width: "24",
                                            fill: "none",
                                            stroke: "currentColor",
                                            strokeLinecap: "round",
                                            strokeLinejoin: "round",
                                            strokeWidth: "2",
                                            viewBox: "0 0 24 24"
                                          }, React.createElement("path", {
                                                d: "M0 0h24v24H0z",
                                                stroke: "none"
                                              }), React.createElement("path", {
                                                d: "M18 15l-6-6l-6 6h12",
                                                transform: "rotate(180 12 12)"
                                              })))), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800"
                                  }, React.createElement("span", {
                                        className: "inline-flex items-center space-x-1"
                                      }, "50", React.createElement("svg", {
                                            className: "h-8 w-8 text-green-500",
                                            height: "24",
                                            width: "24",
                                            fill: "none",
                                            stroke: "currentColor",
                                            strokeLinecap: "round",
                                            strokeLinejoin: "round",
                                            strokeWidth: "2",
                                            viewBox: "0 0 24 24"
                                          }, React.createElement("path", {
                                                d: "M0 0h24v24H0z",
                                                stroke: "none"
                                              }), React.createElement("path", {
                                                d: "M18 15l-6-6l-6 6h12"
                                              })))), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800"
                                  }, React.createElement("span", {
                                        className: "inline-flex items-center space-x-1"
                                      }, "85", React.createElement("svg", {
                                            className: "h-8 w-8 text-red-500",
                                            height: "24",
                                            width: "24",
                                            fill: "none",
                                            stroke: "currentColor",
                                            strokeLinecap: "round",
                                            strokeLinejoin: "round",
                                            strokeWidth: "2",
                                            viewBox: "0 0 24 24"
                                          }, React.createElement("path", {
                                                d: "M0 0h24v24H0z",
                                                stroke: "none"
                                              }), React.createElement("path", {
                                                d: "M18 15l-6-6l-6 6h12",
                                                transform: "rotate(180 12 12)"
                                              })))), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800"
                                  }, React.createElement("span", {
                                        className: "inline-flex items-center space-x-1"
                                      }, "65", React.createElement("svg", {
                                            className: "h-8 w-8 text-red-500",
                                            height: "24",
                                            width: "24",
                                            fill: "none",
                                            stroke: "currentColor",
                                            strokeLinecap: "round",
                                            strokeLinejoin: "round",
                                            strokeWidth: "2",
                                            viewBox: "0 0 24 24"
                                          }, React.createElement("path", {
                                                d: "M0 0h24v24H0z",
                                                stroke: "none"
                                              }), React.createElement("path", {
                                                d: "M18 15l-6-6l-6 6h12",
                                                transform: "rotate(180 12 12)"
                                              })))), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800"
                                  }, React.createElement("span", {
                                        className: "inline-flex items-center space-x-1"
                                      }, "70", React.createElement("svg", {
                                            className: "h-8 w-8 text-green-500",
                                            height: "24",
                                            width: "24",
                                            fill: "none",
                                            stroke: "currentColor",
                                            strokeLinecap: "round",
                                            strokeLinejoin: "round",
                                            strokeWidth: "2",
                                            viewBox: "0 0 24 24"
                                          }, React.createElement("path", {
                                                d: "M0 0h24v24H0z",
                                                stroke: "none"
                                              }), React.createElement("path", {
                                                d: "M18 15l-6-6l-6 6h12"
                                              })))), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800"
                                  }, React.createElement("span", {
                                        className: "inline-flex items-center space-x-1"
                                      }, "90", React.createElement("svg", {
                                            className: "h-8 w-8 text-red-500",
                                            height: "24",
                                            width: "24",
                                            fill: "none",
                                            stroke: "currentColor",
                                            strokeLinecap: "round",
                                            strokeLinejoin: "round",
                                            strokeWidth: "2",
                                            viewBox: "0 0 24 24"
                                          }, React.createElement("path", {
                                                d: "M0 0h24v24H0z",
                                                stroke: "none"
                                              }), React.createElement("path", {
                                                d: "M18 15l-6-6l-6 6h12",
                                                transform: "rotate(180 12 12)"
                                              })))), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800"
                                  }, React.createElement("span", {
                                        className: "inline-flex items-center space-x-1"
                                      }, "55", React.createElement("svg", {
                                            className: "h-8 w-8 text-red-500",
                                            height: "24",
                                            width: "24",
                                            fill: "none",
                                            stroke: "currentColor",
                                            strokeLinecap: "round",
                                            strokeLinejoin: "round",
                                            strokeWidth: "2",
                                            viewBox: "0 0 24 24"
                                          }, React.createElement("path", {
                                                d: "M0 0h24v24H0z",
                                                stroke: "none"
                                              }), React.createElement("path", {
                                                d: "M18 15l-6-6l-6 6h12",
                                                transform: "rotate(180 12 12)"
                                              })))), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800"
                                  }, React.createElement("span", {
                                        className: "inline-flex items-center space-x-1"
                                      }, "40", React.createElement("svg", {
                                            className: "h-8 w-8 text-green-500",
                                            height: "24",
                                            width: "24",
                                            fill: "none",
                                            stroke: "currentColor",
                                            strokeLinecap: "round",
                                            strokeLinejoin: "round",
                                            strokeWidth: "2",
                                            viewBox: "0 0 24 24"
                                          }, React.createElement("path", {
                                                d: "M0 0h24v24H0z",
                                                stroke: "none"
                                              }), React.createElement("path", {
                                                d: "M18 15l-6-6l-6 6h12"
                                              })))), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800"
                                  }, React.createElement("span", {
                                        className: "inline-flex items-center space-x-1"
                                      }, "80", React.createElement("svg", {
                                            className: "h-8 w-8 text-red-500",
                                            height: "24",
                                            width: "24",
                                            fill: "none",
                                            stroke: "currentColor",
                                            strokeLinecap: "round",
                                            strokeLinejoin: "round",
                                            strokeWidth: "2",
                                            viewBox: "0 0 24 24"
                                          }, React.createElement("path", {
                                                d: "M0 0h24v24H0z",
                                                stroke: "none"
                                              }), React.createElement("path", {
                                                d: "M18 15l-6-6l-6 6h12",
                                                transform: "rotate(180 12 12)"
                                              }))))), React.createElement("tr", undefined, React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800"
                                  }, "Overall Sentiment"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800 bg-yellow-200"
                                  }, "Neutral"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800 bg-green-200"
                                  }, "Positive"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800 bg-yellow-200"
                                  }, "Neutral"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800 bg-green-200"
                                  }, "Positive"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800 bg-yellow-200"
                                  }, "Neutral"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800 bg-green-200"
                                  }, "Positive"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800 bg-green-200"
                                  }, "Positive"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800 bg-red-200"
                                  }, "Negative"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800 bg-red-200"
                                  }, "Negative"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800 bg-green-200"
                                  }, "Positive")), React.createElement("tr", undefined, React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800"
                                  }, "Touchpoints"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800"
                                  }, "Social Media"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800"
                                  }, "Website", React.createElement("br", undefined), "App Store"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800"
                                  }, "App Store Reviews", React.createElement("br", undefined), "Blogs"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800"
                                  }, "Mobile App"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800"
                                  }, "Website", React.createElement("br", undefined), "Mobile App", React.createElement("br", undefined), "Email"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800"
                                  }, "Website", React.createElement("br", undefined), "Mobile App"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800"
                                  }, "In-app Chat", React.createElement("br", undefined), "Phone"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800"
                                  }, "Website", React.createElement("br", undefined), "Mobile App", React.createElement("br", undefined), "Notifications"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800"
                                  }, "Website", React.createElement("br", undefined), "Mobile App", React.createElement("br", undefined), "Email"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800"
                                  }, "Feeback Systems", React.createElement("br", undefined), "App Store Reviews", React.createElement("br", undefined), "Social Media")), React.createElement("tr", undefined, React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800"
                                  }, "Top Reasons for Promoters"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800"
                                  }, "1.Clear Messaging and Branding", React.createElement("br", undefined), "2.Smooth Onboarding Experience", "3.Engaging Content and Features"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800"
                                  }, "1.Targeted and Relevant Offers", React.createElement("br", undefined), "2.High-Quality Content", React.createElement("br", undefined), "3.Compelling Visuals and Calls to Action"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800"
                                  }, "1.Product Comparisons and Testimonials", React.createElement("br", undefined), "2.Trust Signals", React.createElement("br", undefined), "3.Interactive Demos or Trials"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800"
                                  }, "1.Quick and Easy Signup Process", React.createElement("br", undefined), "2.Social Media or One-Click Signups", React.createElement("br", undefined), "3.Clear Privacy and Data Policies"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800"
                                  }, "1.Guided Setup Instructions", React.createElement("br", undefined), "2.Customizable Preferences", React.createElement("br", undefined), "3.Proactive Assistance (eg: Chatbots)"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800"
                                  }, "1.Immediate Value", React.createElement("br", undefined), "2.Interactive Onboarding", React.createElement("br", undefined), "3.Personalization"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800"
                                  }, "1.Responsive Support Channels", React.createElement("br", undefined), "2.Multiple Support Options (e.g., Chat, Phone)", React.createElement("br", undefined), "3.Self-Help Resources"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800"
                                  }, "1.Regular Feature Updates", React.createElement("br", undefined), "2.Reliable Performance", React.createElement("br", undefined), "3.Personalized Experiences"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800"
                                  }, "1.Relevant Product Suggestions", React.createElement("br", undefined), "2.Exclusive Offers", React.createElement("br", undefined), "3.Clear Value Addition"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800"
                                  }, "1.Easy-to-Use Feedback Channels", React.createElement("br", undefined), "2.Recognition of Feedback", React.createElement("br", undefined), "3.Incentivized Advocacy")), React.createElement("tr", undefined, React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800"
                                  }, "Top Reasons for Detractors"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800"
                                  }, "1.Confusing Layout or Overwhelming Design", React.createElement("br", undefined), "2.Slow Load Times or Glitches", React.createElement("br", undefined), "3.Lack of Trust Signals"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800"
                                  }, "1.Overly Pushy Marketing", React.createElement("br", undefined), "2.Generic Content", React.createElement("br", undefined), "3.Unclear Value Proposition"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800"
                                  }, "1.Complicated or Overwhelming Information", React.createElement("br", undefined), "2.Limited Customer Feedback", React.createElement("br", undefined), "3.Lack of Detailed Information"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800"
                                  }, "1.Lengthy or Complex Signup Forms", React.createElement("br", undefined), "2.Hidden Costs or Subscription Fees", React.createElement("br", undefined), "3.No Guest or Test Account Option"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800"
                                  }, "1.Complicated Setup Process", React.createElement("br", undefined), "2.Poor Documentation or Help Resources", React.createElement("br", undefined), "3.Mandatory Configurations"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800"
                                  }, "1.Overly Complex Interface", React.createElement("br", undefined), "2.Lack of Clear Instructions", React.createElement("br", undefined), "3.Unexpected Errors or Crashes"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800"
                                  }, "1.Long Response Times", React.createElement("br", undefined), "2.Unhelpful or Scripted Responses", React.createElement("br", undefined), "3.Difficult-to-Find Support Channels"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800"
                                  }, "1.Frequent Bugs or Downtime", React.createElement("br", undefined), "2.Complex Updates", React.createElement("br", undefined), "3.Lack of Engagement or Innovation"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800"
                                  }, "1.Overly Aggressive Tactics", React.createElement("br", undefined), "2.Irrelevant Product Suggestions", React.createElement("br", undefined), "3.Confusing Pricing or Terms"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800"
                                  }, "1.Ignored Feedback", React.createElement("br", undefined), "2.Difficult Feedback Mechanism", React.createElement("br", undefined), "3.Unrewarded Loyalty")))));
    case "NPS Monthly Cohort" :
        return React.createElement("div", {
                    className: " " + chartHeight + " w-full overflow-y-auto "
                  }, React.createElement("table", {
                        className: "w-full h-full text-center border-collapse table-fixed px-2 pb-2"
                      }, React.createElement("thead", {
                            className: "bg-gray-100"
                          }, React.createElement("tr", undefined, React.createElement("th", {
                                    className: "py-2 px-4 border-b text-left"
                                  }, "Joining Month"), React.createElement("th", {
                                    className: "py-2 px-4 border-b text-left"
                                  }, "Total Customers"), React.createElement("th", {
                                    className: "py-2 px-4 border-b text-left"
                                  }, "Promoters (P)"), React.createElement("th", {
                                    className: "py-2 px-4 border-b text-left"
                                  }, "Passives (Pa)"), React.createElement("th", {
                                    className: "py-2 px-4 border-b text-left"
                                  }, "Detractors (D)"), React.createElement("th", {
                                    className: "py-2 px-4 border-b text-left"
                                  }, "NPS"))), React.createElement("tbody", undefined, React.createElement("tr", undefined, React.createElement("td", {
                                    className: "py-2 px-4 border-b"
                                  }, "Jan 2023"), React.createElement("td", {
                                    className: "py-2 px-4 border-b"
                                  }, "150"), React.createElement("td", {
                                    className: "py-2 px-4 border-b"
                                  }, "90"), React.createElement("td", {
                                    className: "py-2 px-4 border-b"
                                  }, "30"), React.createElement("td", {
                                    className: "py-2 px-4 border-b"
                                  }, "30"), React.createElement("td", {
                                    className: "py-2 px-4 border-b bg-custom-gradient"
                                  }, "40")), React.createElement("tr", undefined, React.createElement("td", {
                                    className: "py-2 px-4 border-b"
                                  }, "Feb 2023"), React.createElement("td", {
                                    className: "py-2 px-4 border-b"
                                  }, "200"), React.createElement("td", {
                                    className: "py-2 px-4 border-b"
                                  }, "120"), React.createElement("td", {
                                    className: "py-2 px-4 border-b"
                                  }, "40"), React.createElement("td", {
                                    className: "py-2 px-4 border-b"
                                  }, "40"), React.createElement("td", {
                                    className: "py-2 px-4 border-b bg-custom-gradient"
                                  }, "40")), React.createElement("tr", undefined, React.createElement("td", {
                                    className: "py-2 px-4 border-b"
                                  }, "Mar 2023"), React.createElement("td", {
                                    className: "py-2 px-4 border-b"
                                  }, "200"), React.createElement("td", {
                                    className: "py-2 px-4 border-b"
                                  }, "70"), React.createElement("td", {
                                    className: "py-2 px-4 border-b"
                                  }, "40"), React.createElement("td", {
                                    className: "py-2 px-4 border-b"
                                  }, "90"), React.createElement("td", {
                                    className: "py-2 px-4 border-b bg-custom-red-gradient"
                                  }, "15")), React.createElement("tr", undefined, React.createElement("td", {
                                    className: "py-2 px-4 border-b"
                                  }, "Apr 2023"), React.createElement("td", {
                                    className: "py-2 px-4 border-b"
                                  }, "160"), React.createElement("td", {
                                    className: "py-2 px-4 border-b"
                                  }, "80"), React.createElement("td", {
                                    className: "py-2 px-4 border-b"
                                  }, "50"), React.createElement("td", {
                                    className: "py-2 px-4 border-b"
                                  }, "30"), React.createElement("td", {
                                    className: "py-2 px-4 border-b bg-custom-yellow-gradient"
                                  }, "31.3")), React.createElement("tr", undefined, React.createElement("td", {
                                    className: "py-2 px-4 border-b"
                                  }, "May 2023"), React.createElement("td", {
                                    className: "py-2 px-4 border-b"
                                  }, "190"), React.createElement("td", {
                                    className: "py-2 px-4 border-b"
                                  }, "110"), React.createElement("td", {
                                    className: "py-2 px-4 border-b"
                                  }, "40"), React.createElement("td", {
                                    className: "py-2 px-4 border-b"
                                  }, "40"), React.createElement("td", {
                                    className: "py-2 px-4 border-b bg-custom-gradient"
                                  }, "36.8")), React.createElement("tr", undefined, React.createElement("td", {
                                    className: "py-2 px-4 border-b"
                                  }, "Jun 2023"), React.createElement("td", {
                                    className: "py-2 px-4 border-b"
                                  }, "170"), React.createElement("td", {
                                    className: "py-2 px-4 border-b"
                                  }, "85"), React.createElement("td", {
                                    className: "py-2 px-4 border-b"
                                  }, "45"), React.createElement("td", {
                                    className: "py-2 px-4 border-b"
                                  }, "40"), React.createElement("td", {
                                    className: "py-2 px-4 border-b bg-custom-yellow-gradient"
                                  }, "26.5")), React.createElement("tr", undefined, React.createElement("td", {
                                    className: "py-2 px-4 border-b"
                                  }, "Jul 2023"), React.createElement("td", {
                                    className: "py-2 px-4 border-b"
                                  }, "160"), React.createElement("td", {
                                    className: "py-2 px-4 border-b"
                                  }, "90"), React.createElement("td", {
                                    className: "py-2 px-4 border-b"
                                  }, "40"), React.createElement("td", {
                                    className: "py-2 px-4 border-b"
                                  }, "30"), React.createElement("td", {
                                    className: "py-2 px-4 border-b bg-custom-yellow-gradient"
                                  }, "37.5")), React.createElement("tr", undefined, React.createElement("td", {
                                    className: "py-2 px-4 border-b"
                                  }, "Aug 2023"), React.createElement("td", {
                                    className: "py-2 px-4 border-b"
                                  }, "200"), React.createElement("td", {
                                    className: "py-2 px-4 border-b"
                                  }, "68"), React.createElement("td", {
                                    className: "py-2 px-4 border-b"
                                  }, "30"), React.createElement("td", {
                                    className: "py-2 px-4 border-b"
                                  }, "102"), React.createElement("td", {
                                    className: "py-2 px-4 border-b bg-custom-red-gradient"
                                  }, "19")), React.createElement("tr", undefined, React.createElement("td", {
                                    className: "py-2 px-4 border-b"
                                  }, "Sep 2023"), React.createElement("td", {
                                    className: "py-2 px-4 border-b"
                                  }, "150"), React.createElement("td", {
                                    className: "py-2 px-4 border-b"
                                  }, "80"), React.createElement("td", {
                                    className: "py-2 px-4 border-b"
                                  }, "40"), React.createElement("td", {
                                    className: "py-2 px-4 border-b"
                                  }, "30"), React.createElement("td", {
                                    className: "py-2 px-4 border-b bg-custom-yellow-gradient"
                                  }, "33.3")), React.createElement("tr", undefined, React.createElement("td", {
                                    className: "py-2 px-4 border-b"
                                  }, "Oct 2023"), React.createElement("td", {
                                    className: "py-2 px-4 border-b"
                                  }, "190"), React.createElement("td", {
                                    className: "py-2 px-4 border-b"
                                  }, "110"), React.createElement("td", {
                                    className: "py-2 px-4 border-b"
                                  }, "50"), React.createElement("td", {
                                    className: "py-2 px-4 border-b"
                                  }, "30"), React.createElement("td", {
                                    className: "py-2 px-4 border-b bg-custom-gradient"
                                  }, "42.1")), React.createElement("tr", undefined, React.createElement("td", {
                                    className: "py-2 px-4 border-b"
                                  }, "Nov 2023"), React.createElement("td", {
                                    className: "py-2 px-4 border-b"
                                  }, "200"), React.createElement("td", {
                                    className: "py-2 px-4 border-b"
                                  }, "120"), React.createElement("td", {
                                    className: "py-2 px-4 border-b"
                                  }, "50"), React.createElement("td", {
                                    className: "py-2 px-4 border-b"
                                  }, "30"), React.createElement("td", {
                                    className: "py-2 px-4 border-b bg-custom-gradient"
                                  }, "45.0")), React.createElement("tr", undefined, React.createElement("td", {
                                    className: "py-2 px-4 border-b"
                                  }, "Dec 2023"), React.createElement("td", {
                                    className: "py-2 px-4 border-b"
                                  }, "210"), React.createElement("td", {
                                    className: "py-2 px-4 border-b"
                                  }, "130"), React.createElement("td", {
                                    className: "py-2 px-4 border-b"
                                  }, "50"), React.createElement("td", {
                                    className: "py-2 px-4 border-b"
                                  }, "30"), React.createElement("td", {
                                    className: "py-2 px-4 border-b bg-custom-gradient"
                                  }, "47.6")))));
    case "NPS Shift Report" :
        return React.createElement("div", {
                    className: " " + chartHeight + " w-full overflow-y-auto "
                  }, React.createElement("table", {
                        className: "w-full h-full text-center border-collapse table-fixed px-2 pb-2"
                      }, React.createElement("thead", undefined, React.createElement("tr", undefined, React.createElement("th", {
                                    className: "px-4 py-2 bg-gray-200 text-left"
                                  }, "Shift"), React.createElement("th", {
                                    className: "px-4 py-2 bg-gray-200 text-left"
                                  }, "Jan"), React.createElement("th", {
                                    className: "px-4 py-2 bg-gray-200 text-left"
                                  }, "Feb"), React.createElement("th", {
                                    className: "px-4 py-2 bg-gray-200 text-left"
                                  }, "Mar"), React.createElement("th", {
                                    className: "px-4 py-2 bg-gray-200 text-left"
                                  }, "Apr"), React.createElement("th", {
                                    className: "px-4 py-2 bg-gray-200 text-left"
                                  }, "May"), React.createElement("th", {
                                    className: "px-4 py-2 bg-gray-200 text-left"
                                  }, "Jun"), React.createElement("th", {
                                    className: "px-4 py-2 bg-gray-200 text-left"
                                  }, "Jul"), React.createElement("th", {
                                    className: "px-4 py-2 bg-gray-200 text-left"
                                  }, "Aug"), React.createElement("th", {
                                    className: "px-4 py-2 bg-gray-200 text-left"
                                  }, "Sep"), React.createElement("th", {
                                    className: "px-4 py-2 bg-gray-200 text-left"
                                  }, "Oct"), React.createElement("th", {
                                    className: "px-4 py-2 bg-gray-200 text-left"
                                  }, "Nov"), React.createElement("th", {
                                    className: "px-4 py-2 bg-gray-200 text-left"
                                  }, "Dec"))), React.createElement("tbody", undefined, React.createElement("tr", undefined, React.createElement("td", {
                                    className: "px-4 py-2 border-t border-gray-300"
                                  }, "Promoter to Detractor"), React.createElement("td", {
                                    className: "px-4 py-2 border-t border-gray-300 bg-red-100"
                                  }, "10"), React.createElement("td", {
                                    className: "px-4 py-2 border-t border-gray-300 bg-red-200"
                                  }, "12"), React.createElement("td", {
                                    className: "px-4 py-2 border-t border-gray-300 bg-red-100"
                                  }, "8"), React.createElement("td", {
                                    className: "px-4 py-2 border-t border-gray-300 bg-red-100"
                                  }, "9"), React.createElement("td", {
                                    className: "px-4 py-2 border-t border-gray-300 bg-red-200"
                                  }, "11"), React.createElement("td", {
                                    className: "px-4 py-2 border-t border-gray-300 bg-red-100"
                                  }, "10"), React.createElement("td", {
                                    className: "px-4 py-2 border-t border-gray-300 bg-red-300"
                                  }, "13"), React.createElement("td", {
                                    className: "px-4 py-2 border-t border-gray-300 bg-red-100"
                                  }, "7"), React.createElement("td", {
                                    className: "px-4 py-2 border-t border-gray-300 bg-red-400"
                                  }, "15"), React.createElement("td", {
                                    className: "px-4 py-2 border-t border-gray-300 bg-red-100"
                                  }, "9"), React.createElement("td", {
                                    className: "px-4 py-2 border-t border-gray-300 bg-red-200"
                                  }, "11"), React.createElement("td", {
                                    className: "px-4 py-2 border-t border-gray-300 bg-red-300"
                                  }, "14")), React.createElement("tr", undefined, React.createElement("td", {
                                    className: "px-4 py-2 border-t border-gray-300"
                                  }, "Promoter to Passive"), React.createElement("td", {
                                    className: "px-4 py-2 border-t border-gray-300 bg-yellow-100"
                                  }, "4"), React.createElement("td", {
                                    className: "px-4 py-2 border-t border-gray-300 bg-yellow-200"
                                  }, "5"), React.createElement("td", {
                                    className: "px-4 py-2 border-t border-gray-300 bg-yellow-300"
                                  }, "7"), React.createElement("td", {
                                    className: "px-4 py-2 border-t border-gray-300 bg-yellow-200"
                                  }, "6"), React.createElement("td", {
                                    className: "px-4 py-2 border-t border-gray-300 bg-yellow-300"
                                  }, "8"), React.createElement("td", {
                                    className: "px-4 py-2 border-t border-gray-300 bg-yellow-200"
                                  }, "7"), React.createElement("td", {
                                    className: "px-4 py-2 border-t border-gray-300 bg-yellow-400"
                                  }, "9"), React.createElement("td", {
                                    className: "px-4 py-2 border-t border-gray-300 bg-yellow-100"
                                  }, "5"), React.createElement("td", {
                                    className: "px-4 py-2 border-t border-gray-300 bg-yellow-500"
                                  }, "10"), React.createElement("td", {
                                    className: "px-4 py-2 border-t border-gray-300 bg-yellow-300"
                                  }, "8"), React.createElement("td", {
                                    className: "px-4 py-2 border-t border-gray-300 bg-yellow-200"
                                  }, "7"), React.createElement("td", {
                                    className: "px-4 py-2 border-t border-gray-300 bg-yellow-400"
                                  }, "9")), React.createElement("tr", undefined, React.createElement("td", {
                                    className: "px-4 py-2 border-t border-gray-300"
                                  }, "Promoter to Promoter"), React.createElement("td", {
                                    className: "px-4 py-2 border-t border-gray-300 bg-green-100"
                                  }, "15"), React.createElement("td", {
                                    className: "px-4 py-2 border-t border-gray-300 bg-green-100"
                                  }, "16"), React.createElement("td", {
                                    className: "px-4 py-2 border-t border-gray-300 bg-green-100"
                                  }, "17"), React.createElement("td", {
                                    className: "px-4 py-2 border-t border-gray-300 bg-green-100"
                                  }, "16"), React.createElement("td", {
                                    className: "px-4 py-2 border-t border-gray-300 bg-green-100"
                                  }, "18"), React.createElement("td", {
                                    className: "px-4 py-2 border-t border-gray-300 bg-green-100"
                                  }, "19"), React.createElement("td", {
                                    className: "px-4 py-2 border-t border-gray-300 bg-green-100"
                                  }, "17"), React.createElement("td", {
                                    className: "px-4 py-2 border-t border-gray-300 bg-green-100"
                                  }, "16"), React.createElement("td", {
                                    className: "px-4 py-2 border-t border-gray-300 bg-green-100"
                                  }, "18"), React.createElement("td", {
                                    className: "px-4 py-2 border-t border-gray-300 bg-green-100"
                                  }, "19"), React.createElement("td", {
                                    className: "px-4 py-2 border-t border-gray-300 bg-green-100"
                                  }, "18"), React.createElement("td", {
                                    className: "px-4 py-2 border-t border-gray-300 bg-green-100"
                                  }, "17")), React.createElement("tr", undefined, React.createElement("td", {
                                    className: "px-4 py-2 border-t border-gray-300"
                                  }, "Detractor to Promoter"), React.createElement("td", {
                                    className: "px-4 py-2 border-t border-gray-300 bg-green-100"
                                  }, "5"), React.createElement("td", {
                                    className: "px-4 py-2 border-t border-gray-300 bg-green-200"
                                  }, "7"), React.createElement("td", {
                                    className: "px-4 py-2 border-t border-gray-300 bg-green-100"
                                  }, "6"), React.createElement("td", {
                                    className: "px-4 py-2 border-t border-gray-300 bg-green-200"
                                  }, "8"), React.createElement("td", {
                                    className: "px-4 py-2 border-t border-gray-300 bg-green-100"
                                  }, "5"), React.createElement("td", {
                                    className: "px-4 py-2 border-t border-gray-300 bg-green-300"
                                  }, "9"), React.createElement("td", {
                                    className: "px-4 py-2 border-t border-gray-300 bg-green-200"
                                  }, "7"), React.createElement("td", {
                                    className: "px-4 py-2 border-t border-gray-300 bg-green-100"
                                  }, "6"), React.createElement("td", {
                                    className: "px-4 py-2 border-t border-gray-300 bg-green-100"
                                  }, "4"), React.createElement("td", {
                                    className: "px-4 py-2 border-t border-gray-300 bg-green-200"
                                  }, "8"), React.createElement("td", {
                                    className: "px-4 py-2 border-t border-gray-300 bg-green-100"
                                  }, "5"), React.createElement("td", {
                                    className: "px-4 py-2 border-t border-gray-300 bg-green-200"
                                  }, "6")), React.createElement("tr", undefined, React.createElement("td", {
                                    className: "px-4 py-2 border-t border-gray-300 bg-yellow-100"
                                  }, "Detractor to Passive"), React.createElement("td", {
                                    className: "px-4 py-2 border-t border-gray-300 bg-yellow-100"
                                  }, "3"), React.createElement("td", {
                                    className: "px-4 py-2 border-t border-gray-300 bg-yellow-200"
                                  }, "4"), React.createElement("td", {
                                    className: "px-4 py-2 border-t border-gray-300 bg-yellow-300"
                                  }, "5"), React.createElement("td", {
                                    className: "px-4 py-2 border-t border-gray-300 bg-yellow-400"
                                  }, "6"), React.createElement("td", {
                                    className: "px-4 py-2 border-t border-gray-300 bg-yellow-200"
                                  }, "4"), React.createElement("td", {
                                    className: "px-4 py-2 border-t border-gray-300 bg-yellow-300"
                                  }, "5"), React.createElement("td", {
                                    className: "px-4 py-2 border-t border-gray-300 bg-yellow-400"
                                  }, "6"), React.createElement("td", {
                                    className: "px-4 py-2 border-t border-gray-300 bg-yellow-100"
                                  }, "3"), React.createElement("td", {
                                    className: "px-4 py-2 border-t border-gray-300 bg-yellow-500"
                                  }, "7"), React.createElement("td", {
                                    className: "px-4 py-2 border-t border-gray-300 bg-yellow-300"
                                  }, "5"), React.createElement("td", {
                                    className: "px-4 py-2 border-t border-gray-300 bg-yellow-200"
                                  }, "4"), React.createElement("td", {
                                    className: "px-4 py-2 border-t border-gray-300 bg-yellow-400"
                                  }, "6")), React.createElement("tr", undefined, React.createElement("td", {
                                    className: "px-4 py-2 border-t border-gray-300"
                                  }, "Detractor to Detractor"), React.createElement("td", {
                                    className: "px-4 py-2 border-t border-gray-300 bg-red-100"
                                  }, "12"), React.createElement("td", {
                                    className: "px-4 py-2 border-t border-gray-300 bg-red-100"
                                  }, "14"), React.createElement("td", {
                                    className: "px-4 py-2 border-t border-gray-300 bg-red-100"
                                  }, "13"), React.createElement("td", {
                                    className: "px-4 py-2 border-t border-gray-300 bg-red-100"
                                  }, "11"), React.createElement("td", {
                                    className: "px-4 py-2 border-t border-gray-300 bg-red-100"
                                  }, "12"), React.createElement("td", {
                                    className: "px-4 py-2 border-t border-gray-300 bg-red-100"
                                  }, "15"), React.createElement("td", {
                                    className: "px-4 py-2 border-t border-gray-300 bg-red-100"
                                  }, "14"), React.createElement("td", {
                                    className: "px-4 py-2 border-t border-gray-300 bg-red-100"
                                  }, "13"), React.createElement("td", {
                                    className: "px-4 py-2 border-t border-gray-300 bg-red-100"
                                  }, "16"), React.createElement("td", {
                                    className: "px-4 py-2 border-t border-gray-300 bg-red-100"
                                  }, "14"), React.createElement("td", {
                                    className: "px-4 py-2 border-t border-gray-300 bg-red-100"
                                  }, "13"), React.createElement("td", {
                                    className: "px-4 py-2 border-t border-gray-300 bg-red-100"
                                  }, "15")), React.createElement("tr", undefined, React.createElement("td", {
                                    className: "px-4 py-2 border-t border-gray-300"
                                  }, "Passive to Promoter"), React.createElement("td", {
                                    className: "px-4 py-2 border-t border-gray-300 bg-green-100"
                                  }, "8"), React.createElement("td", {
                                    className: "px-4 py-2 border-t border-gray-300 bg-green-100"
                                  }, "9"), React.createElement("td", {
                                    className: "px-4 py-2 border-t border-gray-300 bg-green-100"
                                  }, "10"), React.createElement("td", {
                                    className: "px-4 py-2 border-t border-gray-300 bg-green-200"
                                  }, "11"), React.createElement("td", {
                                    className: "px-4 py-2 border-t border-gray-300 bg-green-100"
                                  }, "9"), React.createElement("td", {
                                    className: "px-4 py-2 border-t border-gray-300 bg-green-100"
                                  }, "10"), React.createElement("td", {
                                    className: "px-4 py-2 border-t border-gray-300 bg-green-100"
                                  }, "8"), React.createElement("td", {
                                    className: "px-4 py-2 border-t border-gray-300 bg-green-300"
                                  }, "12"), React.createElement("td", {
                                    className: "px-4 py-2 border-t border-gray-300 bg-green-200"
                                  }, "11"), React.createElement("td", {
                                    className: "px-4 py-2 border-t border-gray-300 bg-green-200"
                                  }, "10"), React.createElement("td", {
                                    className: "px-4 py-2 border-t border-gray-300 bg-green-100"
                                  }, "9"), React.createElement("td", {
                                    className: "px-4 py-2 border-t border-gray-300 bg-green-200"
                                  }, "11")), React.createElement("tr", undefined, React.createElement("td", {
                                    className: "px-4 py-2 border-t border-gray-300"
                                  }, "Passive to Detractor"), React.createElement("td", {
                                    className: "px-4 py-2 border-t border-gray-300 bg-red-100"
                                  }, "6"), React.createElement("td", {
                                    className: "px-4 py-2 border-t border-gray-300 bg-red-100"
                                  }, "5"), React.createElement("td", {
                                    className: "px-4 py-2 border-t border-gray-300 bg-red-200"
                                  }, "8"), React.createElement("td", {
                                    className: "px-4 py-2 border-t border-gray-300 bg-red-200"
                                  }, "7"), React.createElement("td", {
                                    className: "px-4 py-2 border-t border-gray-300 bg-red-100"
                                  }, "6"), React.createElement("td", {
                                    className: "px-4 py-2 border-t border-gray-300 bg-red-300"
                                  }, "9"), React.createElement("td", {
                                    className: "px-4 py-2 border-t border-gray-300 bg-red-100"
                                  }, "7"), React.createElement("td", {
                                    className: "px-4 py-2 border-t border-gray-300 bg-red-400"
                                  }, "10"), React.createElement("td", {
                                    className: "px-4 py-2 border-t border-gray-300 bg-red-200"
                                  }, "8"), React.createElement("td", {
                                    className: "px-4 py-2 border-t border-gray-300 bg-red-100"
                                  }, "7"), React.createElement("td", {
                                    className: "px-4 py-2 border-t border-gray-300 bg-red-200"
                                  }, "8"), React.createElement("td", {
                                    className: "px-4 py-2 border-t border-gray-300 bg-red-300"
                                  }, "9")), React.createElement("tr", undefined, React.createElement("td", {
                                    className: "px-4 py-2 border-t border-gray-300"
                                  }, "Passive to Passive"), React.createElement("td", {
                                    className: "px-4 py-2 border-t border-gray-300 bg-yellow-100"
                                  }, "10"), React.createElement("td", {
                                    className: "px-4 py-2 border-t border-gray-300 bg-yellow-100"
                                  }, "11"), React.createElement("td", {
                                    className: "px-4 py-2 border-t border-gray-300 bg-yellow-100"
                                  }, "12"), React.createElement("td", {
                                    className: "px-4 py-2 border-t border-gray-300 bg-yellow-100"
                                  }, "13"), React.createElement("td", {
                                    className: "px-4 py-2 border-t border-gray-300 bg-yellow-100"
                                  }, "11"), React.createElement("td", {
                                    className: "px-4 py-2 border-t border-gray-300 bg-yellow-100"
                                  }, "12"), React.createElement("td", {
                                    className: "px-4 py-2 border-t border-gray-300 bg-yellow-100"
                                  }, "13"), React.createElement("td", {
                                    className: "px-4 py-2 border-t border-gray-300 bg-yellow-100"
                                  }, "14"), React.createElement("td", {
                                    className: "px-4 py-2 border-t border-gray-300 bg-yellow-100"
                                  }, "12"), React.createElement("td", {
                                    className: "px-4 py-2 border-t border-gray-300 bg-yellow-100"
                                  }, "13"), React.createElement("td", {
                                    className: "px-4 py-2 border-t border-gray-300 bg-yellow-100"
                                  }, "12"), React.createElement("td", {
                                    className: "px-4 py-2 border-t border-gray-300 bg-yellow-100"
                                  }, "11")))));
    case "New Customer Addition - NPS Cohort" :
        return React.createElement("div", {
                    className: " " + chartHeight + " w-full overflow-y-auto "
                  }, React.createElement("table", {
                        className: "w-full h-full text-center border-collapse table-fixed px-2 pb-2"
                      }, React.createElement("thead", {
                            className: "bg-gray-100"
                          }, React.createElement("tr", undefined, React.createElement("th", {
                                    className: "py-3 px-4 text-left text-gray-700 font-semibold border-b"
                                  }, "Month of Joining"), React.createElement("th", {
                                    className: "py-3 px-4 text-left text-gray-700 font-semibold border-b"
                                  }, "No.New customers"), React.createElement("th", {
                                    className: "py-3 px-4 text-left text-gray-700 font-semibold border-b"
                                  }, "1st Month"), React.createElement("th", {
                                    className: "py-3 px-4 text-left text-gray-700 font-semibold border-b"
                                  }, "2nd Month"), React.createElement("th", {
                                    className: "py-3 px-4 text-left text-gray-700 font-semibold border-b"
                                  }, "3rd Month"), React.createElement("th", {
                                    className: "py-3 px-4 text-left text-gray-700 font-semibold border-b"
                                  }, "4th Month"), React.createElement("th", {
                                    className: "py-3 px-4 text-left text-gray-700 font-semibold border-b"
                                  }, "5th Month"), React.createElement("th", {
                                    className: "py-3 px-4 text-left text-gray-700 font-semibold border-b"
                                  }, "6th Month"), React.createElement("th", {
                                    className: "py-3 px-4 text-left text-gray-700 font-semibold border-b"
                                  }, "7th Month"), React.createElement("th", {
                                    className: "py-3 px-4 text-left text-gray-700 font-semibold border-b"
                                  }, "8th Month"), React.createElement("th", {
                                    className: "py-3 px-4 text-left text-gray-700 font-semibold border-b"
                                  }, "9th Month"), React.createElement("th", {
                                    className: "py-3 px-4 text-left text-gray-700 font-semibold border-b"
                                  }, "10th Month"), React.createElement("th", {
                                    className: "py-3 px-4 text-left text-gray-700 font-semibold border-b"
                                  }, "11th Month"), React.createElement("th", {
                                    className: "py-3 px-4 text-left text-gray-700 font-semibold border-b"
                                  }, "12th Month"))), React.createElement("tbody", undefined, React.createElement("tr", undefined, React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800"
                                  }, "Feb 2021"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800"
                                  }, "2436"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800 bg-yellow-400"
                                  }, "37.2"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800 bg-yellow-200"
                                  }, "51.37"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800 bg-green-100"
                                  }, "58.77"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800 bg-green-300"
                                  }, "65.12"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800 bg-green-300"
                                  }, "69.91"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800 bg-green-400"
                                  }, "73.14"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800 bg-green-300"
                                  }, "65.96"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800 bg-green-400"
                                  }, "73.02"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800 bg-green-500"
                                  }, "82.7"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800 bg-green-500"
                                  }, "85"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800 bg-green-500"
                                  }, "87.36"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800 bg-green-500"
                                  }, "90")), React.createElement("tr", undefined, React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800"
                                  }, "Mar 2021"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800"
                                  }, "4149"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800 bg-yellow-400"
                                  }, "38.3"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800 bg-yellow-300"
                                  }, "47.85"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800 bg-green-100"
                                  }, "54.98"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800 bg-green-200"
                                  }, "60.8"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800 bg-green-300"
                                  }, "64.99"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800 bg-green-300"
                                  }, "68.25"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800 bg-green-200"
                                  }, "60.34"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800 bg-green-300"
                                  }, "69.02"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800 bg-green-400"
                                  }, "77.71"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800 bg-green-400"
                                  }, "79.74"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800 bg-green-500"
                                  }, "81.31"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800"
                                  }, "")), React.createElement("tr", undefined, React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800"
                                  }, "Apr 2021"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800"
                                  }, "2417"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800 bg-yellow-400"
                                  }, "34.82"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800 bg-yellow-300"
                                  }, "45.51"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800 bg-green-100"
                                  }, "54.33"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800 bg-green-200"
                                  }, "60.32"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800 bg-green-300"
                                  }, "65.09"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800 bg-green-300"
                                  }, "69.28"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800 bg-green-300"
                                  }, "65.37"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800 bg-green-300"
                                  }, "70.18"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800 bg-green-400"
                                  }, "79.16"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800 bg-green-400"
                                  }, "80.74"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800"
                                  }, ""), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800"
                                  }, "")), React.createElement("tr", undefined, React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800"
                                  }, "May 2021"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800"
                                  }, "2703"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800 bg-yellow-400"
                                  }, "34.97"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800 bg-yellow-300"
                                  }, "44.28"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800 bg-yellow-200"
                                  }, "51.39"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800 bg-green-100"
                                  }, "56.78"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800 bg-green-200"
                                  }, "61.91"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800 bg-green-300"
                                  }, "66.12"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800 bg-green-100"
                                  }, "58.58"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800 bg-green-300"
                                  }, "68.22"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800 bg-green-400"
                                  }, "74.2"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800"
                                  }, ""), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800"
                                  }, ""), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800"
                                  }, "")), React.createElement("tr", undefined, React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800"
                                  }, "Jun 2021"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800"
                                  }, "2503"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800 bg-yellow-400"
                                  }, "32.49"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800 bg-yellow-300"
                                  }, "44.09"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800 bg-yellow-300"
                                  }, "45.47"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800 bg-yellow-200"
                                  }, "51.1"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800 bg-green-100"
                                  }, "54.83"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800 bg-green-100"
                                  }, "57.71"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800 bg-yellow-300"
                                  }, "45.59"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800 bg-green-100"
                                  }, "58.05"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800"
                                  }, ""), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800"
                                  }, ""), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800"
                                  }, ""), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800"
                                  }, "")), React.createElement("tr", undefined, React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800"
                                  }, "July 2021"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800"
                                  }, "1618"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800 bg-yellow-400"
                                  }, "33.61"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800 bg-yellow-300"
                                  }, "44.77"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800 bg-green-100"
                                  }, "54.23"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800 bg-green-200"
                                  }, "60.39"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800 bg-green-300"
                                  }, "65.69"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800 bg-green-300"
                                  }, "70.46"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800 bg-yellow-300"
                                  }, "45.25"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800"
                                  }, ""), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800"
                                  }, ""), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800"
                                  }, ""), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800"
                                  }, ""), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800"
                                  }, "")), React.createElement("tr", undefined, React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800"
                                  }, "Aug 2021"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800"
                                  }, "1654"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800 bg-yellow-400"
                                  }, "30.81"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800 bg-yellow-300"
                                  }, "43.07"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800 bg-yellow-200"
                                  }, "51.4"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800 bg-green-100"
                                  }, "56.84"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800 bg-green-200"
                                  }, "61.73"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800 bg-green-300"
                                  }, "64.2"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800"
                                  }, ""), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800"
                                  }, ""), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800"
                                  }, ""), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800"
                                  }, ""), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800"
                                  }, ""), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800"
                                  }, "")), React.createElement("tr", undefined, React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800"
                                  }, "Sep 2021"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800"
                                  }, "2735"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800 bg-yellow-400"
                                  }, "30.78"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800 bg-yellow-300"
                                  }, "42.42"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800 bg-yellow-200"
                                  }, "50.98"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800 bg-green-100"
                                  }, "57.48"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800 bg-green-200"
                                  }, "61.02"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800"
                                  }, ""), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800"
                                  }, ""), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800"
                                  }, ""), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800"
                                  }, ""), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800"
                                  }, ""), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800"
                                  }, ""), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800"
                                  }, "")), React.createElement("tr", undefined, React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800"
                                  }, "Oct 2021"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800"
                                  }, "2587"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800 bg-yellow-400"
                                  }, "35.45"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800 bg-yellow-300"
                                  }, "44.91"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800 bg-yellow-200"
                                  }, "52.57"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800 bg-green-100"
                                  }, "56.06"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800"
                                  }, ""), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800"
                                  }, ""), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800"
                                  }, ""), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800"
                                  }, ""), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800"
                                  }, ""), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800"
                                  }, ""), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800"
                                  }, "")), React.createElement("tr", undefined, React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800"
                                  }, "Nov 2021"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800"
                                  }, "1164"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800 bg-yellow-500"
                                  }, "20.23"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800 bg-yellow-500"
                                  }, "28.85"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800 bg-yellow-400"
                                  }, "33.55"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800"
                                  }, ""), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800"
                                  }, ""), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800"
                                  }, ""), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800"
                                  }, ""), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800"
                                  }, ""), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800"
                                  }, ""), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800"
                                  }, "")), React.createElement("tr", undefined, React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800"
                                  }, "Dec 2021"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800"
                                  }, "659"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800 bg-yellow-400"
                                  }, "36.15"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800 bg-yellow-300"
                                  }, "43.05"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800"
                                  }, ""), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800"
                                  }, ""), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800"
                                  }, ""), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800"
                                  }, ""), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800"
                                  }, ""), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800"
                                  }, ""), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800"
                                  }, "")), React.createElement("tr", undefined, React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800"
                                  }, "Jan 2022"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800"
                                  }, "361"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800 bg-yellow-400"
                                  }, "34.94"), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800"
                                  }, ""), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800"
                                  }, ""), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800"
                                  }, ""), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800"
                                  }, ""), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800"
                                  }, ""), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800"
                                  }, ""), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800"
                                  }, ""), React.createElement("td", {
                                    className: "py-3 px-4 border-b text-gray-800"
                                  }, "")))));
    default:
      return React.createElement("div", {
                  className: " " + chartHeight + " w-full overflow-y-auto "
                }, React.createElement("table", {
                      className: "w-full h-full text-center border-collapse\n              table-fixed px-2 pb-2"
                    }, React.createElement("thead", {
                          className: "bg-gray-200 h-10 border rounded text-sm sticky top-0"
                        }, $$Array.of_list(List.map((function (x) {
                                    return React.createElement("th", {
                                                className: "p-10-px sticky top-0"
                                              }, React.createElement("div", {
                                                    className: "flex justify-center"
                                                  }, React.createElement("p", undefined, x), React.createElement("img", {
                                                        className: "h-16-px mt-2-px ml-4-px",
                                                        src: Image$ReactHooksTemplate.heading
                                                      })));
                                  }), TableAttribute$ReactHooksTemplate.headers(attribute)))), React.createElement("tbody", {
                          className: "font-semibold text-16-px"
                        }, $$Array.of_list(List.map((function (xs) {
                                    return React.createElement("tr", {
                                                className: "border rounded border-gray-200"
                                              }, $$Array.of_list(List.map((function (x) {
                                                          return React.createElement("td", {
                                                                      className: "p-10-px"
                                                                    }, React.createElement("p", undefined, x));
                                                        }), xs)));
                                  }), TableAttribute$ReactHooksTemplate.rows(attribute))))));
  }
}

var make = TableComponent;

exports.make = make;
/* react Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");

function id(t) {
  return t.id;
}

function ratingOneSmiley(t) {
  return t.ratingOneSmiley;
}

function ratingTwoSmiley(t) {
  return t.ratingTwoSmiley;
}

function ratingThreeSmiley(t) {
  return t.ratingThreeSmiley;
}

function ratingFourSmiley(t) {
  return t.ratingFourSmiley;
}

function ratingFiveSmiley(t) {
  return t.ratingFiveSmiley;
}

function selectedStateRatingSmileySet(t) {
  return t.selectedStateRatingSmileySet;
}

function fromJson(json) {
  return {
          id: Json_decode.field("id", Json_decode.$$int, json),
          name: Json_decode.field("name", Json_decode.string, json),
          default: Json_decode.field("default", Json_decode.bool, json),
          ratingOneSmiley: Json_decode.field("smiley_urls", (function (param) {
                  return Json_decode.field("rating_one_smiley", Json_decode.string, param);
                }), json),
          ratingTwoSmiley: Json_decode.field("smiley_urls", (function (param) {
                  return Json_decode.field("rating_two_smiley", Json_decode.string, param);
                }), json),
          ratingThreeSmiley: Json_decode.field("smiley_urls", (function (param) {
                  return Json_decode.field("rating_three_smiley", Json_decode.string, param);
                }), json),
          ratingFourSmiley: Json_decode.field("smiley_urls", (function (param) {
                  return Json_decode.field("rating_four_smiley", Json_decode.string, param);
                }), json),
          ratingFiveSmiley: Json_decode.field("smiley_urls", (function (param) {
                  return Json_decode.field("rating_five_smiley", Json_decode.string, param);
                }), json),
          selectedStateRatingSmileySet: Json_decode.optional((function (param) {
                  return Json_decode.field("selected_state_rating_smiley_set", fromJson, param);
                }), json)
        };
}

exports.id = id;
exports.ratingOneSmiley = ratingOneSmiley;
exports.ratingTwoSmiley = ratingTwoSmiley;
exports.ratingThreeSmiley = ratingThreeSmiley;
exports.ratingFourSmiley = ratingFourSmiley;
exports.ratingFiveSmiley = ratingFiveSmiley;
exports.selectedStateRatingSmileySet = selectedStateRatingSmileySet;
exports.fromJson = fromJson;
/* No side effect */

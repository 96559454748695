// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var List = require("rescript/lib/js/list.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Caml_exceptions = require("rescript/lib/js/caml_exceptions.js");
var LongText$ReactHooksTemplate = require("./LongText.bs.js");
var Question$ReactHooksTemplate = require("./Question.bs.js");
var ShortText$ReactHooksTemplate = require("./ShortText.bs.js");
var MobileNumber$ReactHooksTemplate = require("./MobileNumber.bs.js");

var AnswerNotFound = /* @__PURE__ */Caml_exceptions.create("StringCondition-ReactHooksTemplate.AnswerNotFound");

function lhs(t) {
  return t.lhs;
}

function operator(t) {
  return t.operator;
}

function rhs(t) {
  return t.rhs;
}

function setLhs(t, lhs) {
  return {
          lhs: lhs,
          operator: t.operator,
          rhs: t.rhs
        };
}

function setOperator(t, operator) {
  return {
          lhs: t.lhs,
          operator: operator,
          rhs: t.rhs
        };
}

function setRhs(t, rhs) {
  return {
          lhs: t.lhs,
          operator: t.operator,
          rhs: rhs
        };
}

function availableOperators(param) {
  return {
          hd: "equals",
          tl: {
            hd: "not_equals",
            tl: {
              hd: "contains",
              tl: {
                hd: "not_contains",
                tl: /* [] */0
              }
            }
          }
        };
}

function decodeLhs(json, questions) {
  var lhsId = Json_decode.field("lhs_id", Json_decode.$$int, json);
  return List.find((function (n) {
                return Belt_Option.getExn(Question$ReactHooksTemplate.id(n)) === lhsId;
              }), questions);
}

function decode(json, questions) {
  return {
          lhs: decodeLhs(json, questions),
          operator: Json_decode.field("operator", Json_decode.string, json),
          rhs: Json_decode.field("rhs", Json_decode.string, json)
        };
}

function encodeRhs(t) {
  return t.rhs;
}

function humanizeOperator(text) {
  switch (text) {
    case "contains" :
        return "Contains";
    case "equals" :
        return "Equals";
    case "not_contains" :
        return "Not Contains";
    case "not_equals" :
        return "Not Equals";
    default:
      return "";
  }
}

function maximumLength(question) {
  var match = Question$ReactHooksTemplate.questionType(question);
  switch (match.TAG | 0) {
    case /* LongText */2 :
        return LongText$ReactHooksTemplate.maximumLength;
    case /* ShortText */3 :
        return ShortText$ReactHooksTemplate.maximumLength;
    case /* MobileNumber */11 :
        return MobileNumber$ReactHooksTemplate.maximumLength;
    default:
      return 0;
  }
}

function getAnswer(question) {
  var longText = question.questionType;
  switch (longText.TAG | 0) {
    case /* LongText */2 :
        return LongText$ReactHooksTemplate.answerToString(longText._0);
    case /* ShortText */3 :
        return ShortText$ReactHooksTemplate.answerToString(longText._0);
    case /* MobileNumber */11 :
        return MobileNumber$ReactHooksTemplate.answerToString(longText._0);
    default:
      return ;
  }
}

function evaluate(t) {
  if (Question$ReactHooksTemplate.answered(t.lhs)) {
    var x = getAnswer(t.lhs);
    if (x !== undefined) {
      var match = t.operator;
      switch (match) {
        case "contains" :
            return t.rhs.includes(x);
        case "equals" :
            return x === t.rhs;
        case "not_contains" :
            return !t.rhs.includes(x);
        case "not_equals" :
            return x !== t.rhs;
        default:
          return false;
      }
    } else {
      throw {
            RE_EXN_ID: AnswerNotFound,
            _1: "The Answer Not Found",
            Error: new Error()
          };
    }
  } else {
    throw {
          RE_EXN_ID: AnswerNotFound,
          _1: "The Answer Not Found",
          Error: new Error()
        };
  }
}

exports.lhs = lhs;
exports.operator = operator;
exports.rhs = rhs;
exports.setLhs = setLhs;
exports.setOperator = setOperator;
exports.setRhs = setRhs;
exports.decode = decode;
exports.encodeRhs = encodeRhs;
exports.maximumLength = maximumLength;
exports.humanizeOperator = humanizeOperator;
exports.availableOperators = availableOperators;
exports.getAnswer = getAnswer;
exports.evaluate = evaluate;
/* LongText-ReactHooksTemplate Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");
var DateFilter$ReactHooksTemplate = require("./dateFilter.bs.js");
var ListFilter$ReactHooksTemplate = require("./listFilter.bs.js");
var TextFilter$ReactHooksTemplate = require("./textFilter.bs.js");
var EmailFilter$ReactHooksTemplate = require("./emailFilter.bs.js");
var MsisdnFilter$ReactHooksTemplate = require("./msisdnFilter.bs.js");
var NumberFilter$ReactHooksTemplate = require("./numberFilter.bs.js");

function id(t) {
  return t.id;
}

function title(t) {
  return t.title;
}

function filterType(t) {
  return t.filterType;
}

function setFilterTypes(filterType, t) {
  return {
          id: t.id,
          title: t.title,
          filterType: filterType
        };
}

function toString(t) {
  var number = t.filterType;
  var tmp;
  switch (number.TAG | 0) {
    case /* Number */0 :
        tmp = NumberFilter$ReactHooksTemplate.toString(number._0);
        break;
    case /* Date */1 :
        tmp = DateFilter$ReactHooksTemplate.toString(number._0);
        break;
    case /* List */2 :
        tmp = ListFilter$ReactHooksTemplate.toString(number._0);
        break;
    case /* Msisdn */3 :
        tmp = MsisdnFilter$ReactHooksTemplate.toString(number._0);
        break;
    case /* Email */4 :
        tmp = EmailFilter$ReactHooksTemplate.toString(number._0);
        break;
    case /* FreeText */5 :
        tmp = TextFilter$ReactHooksTemplate.toString(number._0);
        break;
    
  }
  return t.title + (" : " + tmp);
}

function isSelected(t) {
  var number = t.filterType;
  switch (number.TAG | 0) {
    case /* Number */0 :
        return NumberFilter$ReactHooksTemplate.isSelected(number._0);
    case /* Date */1 :
        return DateFilter$ReactHooksTemplate.isSelected(number._0);
    case /* List */2 :
        return ListFilter$ReactHooksTemplate.isSelected(number._0);
    case /* Msisdn */3 :
        return MsisdnFilter$ReactHooksTemplate.isSelected(number._0);
    case /* Email */4 :
        return EmailFilter$ReactHooksTemplate.isSelected(number._0);
    case /* FreeText */5 :
        return TextFilter$ReactHooksTemplate.isSelected(number._0);
    
  }
}

function resetSelected(t) {
  var number = t.filterType;
  var filterType;
  switch (number.TAG | 0) {
    case /* Number */0 :
        filterType = {
          TAG: /* Number */0,
          _0: NumberFilter$ReactHooksTemplate.resetSelected(number._0)
        };
        break;
    case /* Date */1 :
        filterType = {
          TAG: /* Date */1,
          _0: DateFilter$ReactHooksTemplate.resetSelected(number._0)
        };
        break;
    case /* List */2 :
        filterType = {
          TAG: /* List */2,
          _0: ListFilter$ReactHooksTemplate.resetSelected(number._0)
        };
        break;
    case /* Msisdn */3 :
        filterType = {
          TAG: /* Msisdn */3,
          _0: MsisdnFilter$ReactHooksTemplate.resetSelected(number._0)
        };
        break;
    case /* Email */4 :
        filterType = {
          TAG: /* Email */4,
          _0: EmailFilter$ReactHooksTemplate.resetSelected(number._0)
        };
        break;
    case /* FreeText */5 :
        filterType = {
          TAG: /* FreeText */5,
          _0: TextFilter$ReactHooksTemplate.resetSelected(number._0)
        };
        break;
    
  }
  return setFilterTypes(filterType, t);
}

function decodeFilterType(json) {
  var match = Json_decode.field("category_type", Json_decode.string, json);
  switch (match) {
    case "date" :
        return {
                TAG: /* Date */1,
                _0: Json_decode.field("params", DateFilter$ReactHooksTemplate.fromJson, json)
              };
    case "email" :
        return {
                TAG: /* Email */4,
                _0: Json_decode.field("params", EmailFilter$ReactHooksTemplate.fromJson, json)
              };
    case "list" :
        return {
                TAG: /* List */2,
                _0: Json_decode.field("params", ListFilter$ReactHooksTemplate.fromJson, json)
              };
    case "msisdn" :
        return {
                TAG: /* Msisdn */3,
                _0: Json_decode.field("params", MsisdnFilter$ReactHooksTemplate.fromJson, json)
              };
    default:
      return {
              TAG: /* FreeText */5,
              _0: Json_decode.field("params", TextFilter$ReactHooksTemplate.fromJson, json)
            };
  }
}

function decodeFilterItem(json) {
  return {
          id: Json_decode.field("id", Json_decode.$$int, json),
          title: Json_decode.field("title", Json_decode.string, json),
          filterType: Json_decode.field("filter", decodeFilterType, json)
        };
}

function fromJson(json) {
  return Json_decode.field("filters", (function (param) {
                return Json_decode.list(decodeFilterItem, param);
              }), json);
}

function encodeFilter(t) {
  var numberFilter = t.filterType;
  switch (numberFilter.TAG | 0) {
    case /* Number */0 :
        return NumberFilter$ReactHooksTemplate.encode(numberFilter._0);
    case /* Date */1 :
        return DateFilter$ReactHooksTemplate.encode(numberFilter._0);
    case /* List */2 :
        return ListFilter$ReactHooksTemplate.encode(numberFilter._0);
    case /* Msisdn */3 :
        return MsisdnFilter$ReactHooksTemplate.encode(numberFilter._0);
    case /* Email */4 :
        return EmailFilter$ReactHooksTemplate.encode(numberFilter._0);
    case /* FreeText */5 :
        return TextFilter$ReactHooksTemplate.encode(numberFilter._0);
    
  }
}

function encode(t) {
  return Json_encode.object_({
              hd: [
                "id",
                t.id
              ],
              tl: {
                hd: [
                  "title",
                  t.title
                ],
                tl: {
                  hd: [
                    "filter",
                    encodeFilter(t)
                  ],
                  tl: /* [] */0
                }
              }
            });
}

exports.fromJson = fromJson;
exports.title = title;
exports.filterType = filterType;
exports.id = id;
exports.setFilterTypes = setFilterTypes;
exports.toString = toString;
exports.isSelected = isSelected;
exports.resetSelected = resetSelected;
exports.encode = encode;
exports.decodeFilterItem = decodeFilterItem;
/* DateFilter-ReactHooksTemplate Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var $$Array = require("rescript/lib/js/array.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Http$ReactHooksTemplate = require("../common/http.bs.js");
var Image$ReactHooksTemplate = require("../common/image.bs.js");
var Modal$ReactHooksTemplate = require("./Modal.bs.js");
var Utils$ReactHooksTemplate = require("../common/utils.bs.js");
var ChartId$ReactHooksTemplate = require("../dashboard/dashboard/chartId.bs.js");
var Filters$ReactHooksTemplate = require("../dashboard/filters/filters.bs.js");
var Loading$ReactHooksTemplate = require("../dashboard/dashboard/Loading.bs.js");
var DateFilter$ReactHooksTemplate = require("../dashboard/filters/dateFilter.bs.js");
var ChartComponent$ReactHooksTemplate = require("../dashboard/dashboard/ChartComponent.bs.js");

var initialState = {
  loading: true,
  charts: /* [] */0,
  enlargedChart: undefined,
  toggleFilter: false,
  dateRange: undefined
};

function reducer(state, x) {
  if (typeof x === "number") {
    if (x === /* FetchChartsInProgress */0) {
      return {
              loading: true,
              charts: state.charts,
              enlargedChart: state.enlargedChart,
              toggleFilter: state.toggleFilter,
              dateRange: state.dateRange
            };
    } else {
      return {
              loading: state.loading,
              charts: state.charts,
              enlargedChart: state.enlargedChart,
              toggleFilter: !state.toggleFilter,
              dateRange: state.dateRange
            };
    }
  }
  switch (x.TAG | 0) {
    case /* FetchChartsCompleted */0 :
        return {
                loading: false,
                charts: x._0,
                enlargedChart: state.enlargedChart,
                toggleFilter: state.toggleFilter,
                dateRange: state.dateRange
              };
    case /* UpdateDateRange */1 :
        return {
                loading: state.loading,
                charts: state.charts,
                enlargedChart: state.enlargedChart,
                toggleFilter: state.toggleFilter,
                dateRange: x._0
              };
    case /* EnlargeChart */2 :
        return {
                loading: state.loading,
                charts: state.charts,
                enlargedChart: x._0,
                toggleFilter: state.toggleFilter,
                dateRange: state.dateRange
              };
    
  }
}

function CampaignDashboard(Props) {
  var campaignId = Props.campaignId;
  var match = React.useReducer(reducer, initialState);
  var dispatch = match[1];
  var state = match[0];
  var fetchVisualizations = function (param) {
    Curry._1(dispatch, /* FetchChartsInProgress */0);
    return Http$ReactHooksTemplate.fetchGetJSONDecode("/api/v1/campaigns/" + (campaignId + "/visualizations"), ChartId$ReactHooksTemplate.fromJson).then(function (xs) {
                      console.log(xs);
                      return Promise.resolve(xs);
                    }).then(function (xs) {
                    Http$ReactHooksTemplate.fetchGetJSONDecode("/api/v1/filters/date_range", DateFilter$ReactHooksTemplate.fromJson).then(function (dateRange) {
                          return Promise.resolve(Curry._1(dispatch, {
                                          TAG: /* UpdateDateRange */1,
                                          _0: Caml_option.some(dateRange)
                                        }));
                        });
                    return Promise.resolve(xs);
                  }).then(function (xs) {
                  return Promise.resolve((setTimeout((function (param) {
                                      return Curry._1(dispatch, {
                                                  TAG: /* FetchChartsCompleted */0,
                                                  _0: xs
                                                });
                                    }), Utils$ReactHooksTemplate.timeout), undefined));
                }).catch(function (error) {
                console.log(error);
                return Promise.resolve(Curry._1(dispatch, {
                                TAG: /* FetchChartsCompleted */0,
                                _0: /* [] */0
                              }));
              });
  };
  React.useEffect((function () {
          fetchVisualizations(undefined);
          
        }), []);
  var onApplyFilter = function (param) {
    fetchVisualizations(undefined);
    
  };
  var toggleFilter = function (param) {
    return Curry._1(dispatch, /* ToggleFilter */1);
  };
  var chartId = state.enlargedChart;
  var tmp;
  if (chartId !== undefined) {
    var chartId$1 = Caml_option.valFromOption(chartId);
    tmp = React.createElement(Modal$ReactHooksTemplate.make, {
          show: true,
          children: React.createElement("div", {
                className: "w-screen bg-white pt-6 pb-10"
              }, React.createElement(ChartComponent$ReactHooksTemplate.make, {
                    chartId: chartId$1,
                    drillDownChartDetails: undefined,
                    onClick: (function (param, param$1, param$2) {
                        
                      }),
                    onEnlarge: (function (param) {
                        return Curry._1(dispatch, {
                                    TAG: /* EnlargeChart */2,
                                    _0: Caml_option.some(chartId$1)
                                  });
                      }),
                    enlarged: true,
                    campaign: true,
                    key: String(ChartId$ReactHooksTemplate.id(chartId$1))
                  })),
          handleClose: (function (param) {
              return Curry._1(dispatch, {
                          TAG: /* EnlargeChart */2,
                          _0: undefined
                        });
            })
        });
  } else {
    tmp = React.createElement(React.Fragment, undefined);
  }
  var tmp$1;
  if (state.loading) {
    tmp$1 = React.createElement("div", {
          className: "h-screen w-full"
        }, React.createElement(Loading$ReactHooksTemplate.make, {
              img: Image$ReactHooksTemplate.dashboardLoading,
              text: "Loading Charts"
            }));
  } else {
    var chartIds = state.charts;
    tmp$1 = React.createElement(React.Fragment, undefined, React.createElement("div", {
              id: "dashboard"
            }, React.createElement("div", undefined, React.createElement("div", {
                      className: "flex justify-between gap-2 p-16-px"
                    }, React.createElement("div", {
                          className: "flex items-center"
                        }, React.createElement("img", {
                              className: "mr-2 h-8 w-8",
                              src: Image$ReactHooksTemplate.dashboardIcon
                            }), React.createElement("p", {
                              className: "font-semibold text-xl text-left"
                            }, "Dashboard")), React.createElement("div", {
                          className: "flex gap-4"
                        }, React.createElement("div", {
                              className: "bg-white"
                            }, React.createElement("div", {
                                  className: "capitalize tracking-wide"
                                }, React.createElement("div", undefined, React.createElement(Filters$ReactHooksTemplate.make, {
                                          onClose: toggleFilter,
                                          onApply: onApplyFilter,
                                          showDateFilter: true,
                                          showFilterPills: false
                                        })))), React.createElement("div", undefined, React.createElement("button", {
                                  className: "bg-ghostwhite border border-gray-400\n                              shadow-inner flex rounded py-2 px-3 text-xs w-20",
                                  id: "filters",
                                  onClick: (function (param) {
                                      return Curry._1(dispatch, /* ToggleFilter */1);
                                    })
                                }, React.createElement("img", {
                                      className: "h-14-px mr-2-px",
                                      src: Image$ReactHooksTemplate.filterButton
                                    }), "Filters")))), React.createElement("div", undefined, chartIds ? React.createElement(React.Fragment, undefined, React.createElement("div", {
                                className: "flex flex-wrap p-16-px gap-4 pt-0"
                              }, $$Array.of_list(Belt_List.map(Belt_List.keep(chartIds, (function (xs) {
                                              return ChartId$ReactHooksTemplate.visualizationType(xs) === /* ScoreCard */1;
                                            })), (function (chartId) {
                                          return React.createElement(ChartComponent$ReactHooksTemplate.make, {
                                                      chartId: chartId,
                                                      drillDownChartDetails: undefined,
                                                      onClick: (function (param, param$1, param$2) {
                                                          
                                                        }),
                                                      onEnlarge: (function (param) {
                                                          return Curry._1(dispatch, {
                                                                      TAG: /* EnlargeChart */2,
                                                                      _0: Caml_option.some(chartId)
                                                                    });
                                                        }),
                                                      scoreCard: true,
                                                      campaign: true,
                                                      key: ChartId$ReactHooksTemplate.name(chartId)
                                                    });
                                        })))), React.createElement("div", {
                                className: "flex flex-wrap p-16-px"
                              }, $$Array.of_list(Belt_List.map(Belt_List.keep(chartIds, (function (xs) {
                                              return ChartId$ReactHooksTemplate.visualizationType(xs) !== /* ScoreCard */1;
                                            })), (function (chartId) {
                                          return React.createElement(ChartComponent$ReactHooksTemplate.make, {
                                                      chartId: chartId,
                                                      drillDownChartDetails: undefined,
                                                      onClick: (function (param, param$1, param$2) {
                                                          
                                                        }),
                                                      onEnlarge: (function (param) {
                                                          return Curry._1(dispatch, {
                                                                      TAG: /* EnlargeChart */2,
                                                                      _0: Caml_option.some(chartId)
                                                                    });
                                                        }),
                                                      campaign: true,
                                                      key: String(ChartId$ReactHooksTemplate.id(chartId))
                                                    });
                                        }))))) : "No Charts Loaded for the Dashboard"))));
  }
  return React.createElement(React.Fragment, undefined, tmp, React.createElement("div", undefined, state.toggleFilter ? React.createElement(Filters$ReactHooksTemplate.make, {
                        onClose: toggleFilter,
                        onApply: onApplyFilter,
                        showDateFilter: false,
                        showFilterPills: false
                      }) : React.createElement(React.Fragment, undefined), tmp$1));
}

var make = CampaignDashboard;

exports.initialState = initialState;
exports.reducer = reducer;
exports.make = make;
/* react Not a pure module */

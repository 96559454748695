// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var $$Array = require("rescript/lib/js/array.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Caml_array = require("rescript/lib/js/caml_array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var ScoreCardSetting$ReactHooksTemplate = require("./scoreCardSetting.bs.js");
var ScoreCardChartAttribute$ReactHooksTemplate = require("./scoreCardChartAttribute.bs.js");

function ScoreCardComponent(Props) {
  var chartId = Props.chartId;
  var chartAttribute = Props.chartAttribute;
  var onClick = Props.onClick;
  var match = ScoreCardChartAttribute$ReactHooksTemplate.onClick(chartAttribute);
  var cursorStyle = typeof match === "number" ? "" : "cursor-pointer hover:border hover:border-1 hover:border-purple-800";
  var setting = ScoreCardChartAttribute$ReactHooksTemplate.settings(chartAttribute);
  var titleTextColor;
  if (setting !== undefined) {
    switch (setting.TAG | 0) {
      case /* ChartSetting */0 :
      case /* MapSetting */1 :
          titleTextColor = "text-white";
          break;
      case /* ScoreCardSetting */2 :
          titleTextColor = ScoreCardSetting$ReactHooksTemplate.titleTextColor(setting._0);
          break;
      
    }
  } else {
    titleTextColor = "text-charcoal";
  }
  var setting$1 = ScoreCardChartAttribute$ReactHooksTemplate.settings(chartAttribute);
  var background;
  if (setting$1 !== undefined) {
    switch (setting$1.TAG | 0) {
      case /* ChartSetting */0 :
      case /* MapSetting */1 :
          background = "bg-white";
          break;
      case /* ScoreCardSetting */2 :
          background = ScoreCardSetting$ReactHooksTemplate.background(setting$1._0);
          break;
      
    }
  } else {
    background = "bg-aliceblue";
  }
  var setting$2 = ScoreCardChartAttribute$ReactHooksTemplate.settings(chartAttribute);
  var boxBackground;
  if (setting$2 !== undefined) {
    switch (setting$2.TAG | 0) {
      case /* ChartSetting */0 :
      case /* MapSetting */1 :
          boxBackground = "bg-white";
          break;
      case /* ScoreCardSetting */2 :
          boxBackground = ScoreCardSetting$ReactHooksTemplate.boxBackground(setting$2._0);
          break;
      
    }
  } else {
    boxBackground = "bg-verdigris";
  }
  var setting$3 = ScoreCardChartAttribute$ReactHooksTemplate.settings(chartAttribute);
  var innerContainerBackground;
  if (setting$3 !== undefined) {
    switch (setting$3.TAG | 0) {
      case /* ChartSetting */0 :
      case /* MapSetting */1 :
          innerContainerBackground = "bg-white";
          break;
      case /* ScoreCardSetting */2 :
          innerContainerBackground = ScoreCardSetting$ReactHooksTemplate.innerContainerBackground(setting$3._0);
          break;
      
    }
  } else {
    innerContainerBackground = "bg-azureishwhite";
  }
  var setting$4 = ScoreCardChartAttribute$ReactHooksTemplate.settings(chartAttribute);
  var borderColor;
  if (setting$4 !== undefined) {
    switch (setting$4.TAG | 0) {
      case /* ChartSetting */0 :
      case /* MapSetting */1 :
          borderColor = "bg-white";
          break;
      case /* ScoreCardSetting */2 :
          borderColor = ScoreCardSetting$ReactHooksTemplate.innerContainerBackground(setting$4._0);
          break;
      
    }
  } else {
    borderColor = "border-verdigris";
  }
  var formatter = function (value) {
    if (Math.abs(value) > 999.0) {
      return (value / 1000.0).toFixed(1) + "K";
    } else {
      return value.toString();
    }
  };
  return React.createElement("div", {
              className: "box rounded-lg h-154-px " + background + " " + cursorStyle + "\n    border " + borderColor,
              onClick: (function (param) {
                  return Curry._3(onClick, Belt_Option.getWithDefault(Caml_array.get(ScoreCardChartAttribute$ReactHooksTemplate.title(chartAttribute).split(/:/), 0), ""), chartId, ScoreCardChartAttribute$ReactHooksTemplate.onClick(chartAttribute));
                })
            }, React.createElement("div", {
                  className: "animated fadeIn p-20-px pb-0\n      text-base font-semibold " + titleTextColor + " leading-none flex"
                }, React.createElement("div", {
                      className: "min-w-30-px h-30-px " + boxBackground + " flex rounded-lg mr-2"
                    }, React.createElement("div", {
                          className: "bg-white h-8-px w-8-px m-auto rounded-lg"
                        })), React.createElement("div", {
                      className: "flex"
                    }, React.createElement("div", {
                          className: "m-auto"
                        }, ScoreCardChartAttribute$ReactHooksTemplate.title(chartAttribute)))), React.createElement("div", {
                  className: "flex flex-wrap gap-4 px-4 animated fadeIn justify-evenly items-center"
                }, $$Array.of_list(Belt_List.map(ScoreCardChartAttribute$ReactHooksTemplate.data(chartAttribute), (function (param) {
                            var value = param[1];
                            var id = param[0];
                            return React.createElement("div", {
                                        key: id,
                                        className: "text-center " + innerContainerBackground + " w-110-px h-78-px rounded mt-2"
                                      }, value !== undefined ? React.createElement(React.Fragment, undefined, React.createElement("div", {
                                                  className: "font-semibold h-24-px text-xl leading-tight text-center\n                m-auto text-22-px mt-17-px tooltip"
                                                }, formatter(value), React.createElement("span", {
                                                      className: "tooltiptext " + boxBackground + " text-base"
                                                    }, value.toString().toLocaleUpperCase()))) : React.createElement(React.Fragment, undefined, React.createElement("div", {
                                                  className: "font-semibold h-24-px text-xl leading-tight\n                text-center m-auto text-22-px mt-17-px"
                                                }, "--")), React.createElement("div", {
                                            className: "text-xs pt-8-px font-normal leading-tight text-charcoal tracking-normal"
                                          }, id));
                          })))));
}

var make = ScoreCardComponent;

exports.make = make;
/* react Not a pure module */

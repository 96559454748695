// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var TicketAssign$ReactHooksTemplate = require("./ticketAssign.bs.js");

function TicketAssignView(Props) {
  var activity = Props.activity;
  return React.createElement("div", undefined, React.createElement("div", {
                  className: "flex justify-between text-darkcharcoal"
                }, React.createElement("div", {
                      className: "text-sm font-semibold"
                    }, "Ticket Assigned"), React.createElement("div", {
                      className: "text-xs font-normal"
                    }, TicketAssign$ReactHooksTemplate.assigner(activity))), React.createElement("div", {
                  className: "flex text-xs font-normal mt-2"
                }, "Assigned To: ", TicketAssign$ReactHooksTemplate.assignee(activity)));
}

var make = TicketAssignView;

exports.make = make;
/* react Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var TicketReopen$ReactHooksTemplate = require("./ticketReopen.bs.js");

function TicketReopenView(Props) {
  var activity = Props.activity;
  return React.createElement("div", undefined, React.createElement("div", {
                  className: "flex justify-between text-darkcharcoal"
                }, React.createElement("div", {
                      className: "text-sm font-semibold"
                    }, "Ticket Reopened"), React.createElement("div", {
                      className: "text-xs font-normal"
                    }, TicketReopen$ReactHooksTemplate.user_email(activity))));
}

var make = TicketReopenView;

exports.make = make;
/* react Not a pure module */

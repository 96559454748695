// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var RcSlider = require("rc-slider");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var NumberFilter$ReactHooksTemplate = require("./numberFilter.bs.js");
var NumberSelected$ReactHooksTemplate = require("./numberSelected.bs.js");

function NumberFilterForm(Props) {
  var filter = Props.filter;
  var onChange = Props.onChange;
  var handleOnChange = function (value) {
    return Curry._1(onChange, {
                TAG: /* Number */0,
                _0: NumberFilter$ReactHooksTemplate.setSelected(Caml_option.some(NumberSelected$ReactHooksTemplate.make(Belt_Array.get(value, 0), Belt_Array.get(value, 1))), filter)
              });
  };
  var displayRange = function (min$p, max$p) {
    var min = Belt_Option.getWithDefault(min$p, 0);
    var max = Belt_Option.getWithDefault(max$p, 100);
    return React.createElement(RcSlider.Range, {
                className: "p-2 text-sm",
                min: min,
                max: max,
                allowCross: false,
                defaultValue: [
                  min,
                  max
                ],
                onChange: handleOnChange
              });
  };
  return React.createElement("label", undefined, displayRange(NumberFilter$ReactHooksTemplate.getMin(filter), NumberFilter$ReactHooksTemplate.getMax(filter)));
}

var className = "border border-gray-200 hover:border-gray-400 focus:border-teal-500 outline-none w-full py-10-px px-1 text-sm bg-transparent";

var make = NumberFilterForm;

exports.className = className;
exports.make = make;
/* react Not a pure module */

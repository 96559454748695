// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Nps$ReactHooksTemplate = require("./Nps.bs.js");
var Utils$ReactHooksTemplate = require("../common/utils.bs.js");
var NpsLabel$ReactHooksTemplate = require("./NpsLabel.bs.js");
var ToggleInput$ReactHooksTemplate = require("../common/ToggleInput.bs.js");
var ToggleMandatory$ReactHooksTemplate = require("./ToggleMandatory.bs.js");

function NpsEditor(Props) {
  var nps = Props.nps;
  var mandatory = Props.mandatory;
  var onNpsChange = Props.onNpsChange;
  var onMandatoryChange = Props.onMandatoryChange;
  var leftCount = NpsLabel$ReactHooksTemplate.left(Nps$ReactHooksTemplate.label(nps)).length;
  var rightCount = NpsLabel$ReactHooksTemplate.right(Nps$ReactHooksTemplate.label(nps)).length;
  var onLabelChange = function (f, $$event) {
    var formTargetValue = Utils$ReactHooksTemplate.formTargetValue($$event);
    if (Utils$ReactHooksTemplate.checkMaxLength24(formTargetValue)) {
      return Curry._1(onNpsChange, Nps$ReactHooksTemplate.setLabel(nps, Curry._2(f, Nps$ReactHooksTemplate.label(nps), formTargetValue)));
    }
    
  };
  var match = Nps$ReactHooksTemplate.scale(nps);
  return React.createElement("div", undefined, React.createElement("div", undefined, React.createElement("div", {
                      className: "mb-4 pb-4 border-b border-gray-300"
                    }, React.createElement("div", {
                          className: "flex justify-between"
                        }, React.createElement("div", {
                              className: "text-darkcharcoal text-sm font-medium"
                            }, "Start From Zero"), React.createElement(ToggleInput$ReactHooksTemplate.make, {
                              checked: Nps$ReactHooksTemplate.includeZero(nps),
                              handleChange: (function (param) {
                                  !Nps$ReactHooksTemplate.includeZero(nps);
                                  var newIncludeZero = !Nps$ReactHooksTemplate.includeZero(nps);
                                  return Curry._1(onNpsChange, Nps$ReactHooksTemplate.setIncludeZero(nps, newIncludeZero));
                                }),
                              labelClassName: "flex items-center"
                            })))), React.createElement("div", undefined, React.createElement("div", {
                      className: "mb-4 pb-4 border-b border-gray-300"
                    }, React.createElement("div", {
                          className: "flex justify-between"
                        }, React.createElement("div", {
                              className: "text-darkcharcoal text-sm font-medium"
                            }, "5 Scale Rating"), React.createElement(ToggleInput$ReactHooksTemplate.make, {
                              checked: match ? false : true,
                              handleChange: (function (param) {
                                  var match = Nps$ReactHooksTemplate.scale(nps);
                                  var newScale = match ? /* FiveScale */0 : /* TenScale */1;
                                  return Curry._1(onNpsChange, Nps$ReactHooksTemplate.setScale(nps, newScale));
                                }),
                              labelClassName: "flex items-center"
                            })))), React.createElement(ToggleMandatory$ReactHooksTemplate.make, {
                  mandatory: mandatory,
                  onMandatoryChange: onMandatoryChange
                }), React.createElement("div", {
                  className: "mb-4"
                }, React.createElement("div", {
                      className: "text-darkcharcoal text-sm mb-2 font-medium flex justify-between"
                    }, "Left Label", React.createElement("p", {
                          className: "text-gray-500 text-xs p-1 flex justify-end"
                        }, String(leftCount) + (" / " + String(24)))), React.createElement("input", {
                      className: "h-40-px py-2 px-4 rounded bg-white border border-gray-300 focus:border-gray-600 text-xs w-full",
                      placeholder: "Enter Left Label",
                      type: "text",
                      value: NpsLabel$ReactHooksTemplate.left(Nps$ReactHooksTemplate.label(nps)),
                      onChange: (function (param) {
                          return onLabelChange(NpsLabel$ReactHooksTemplate.setLeft, param);
                        })
                    })), React.createElement("div", {
                  className: "mb-4"
                }, React.createElement("div", {
                      className: "text-darkcharcoal text-sm mb-2 font-medium flex justify-between"
                    }, "Right Label", React.createElement("p", {
                          className: "text-gray-500 text-xs p-1 flex justify-end"
                        }, String(rightCount) + (" / " + String(24)))), React.createElement("input", {
                      className: "h-40-px py-2 px-4 rounded bg-white border border-gray-300 focus:border-gray-600 text-xs w-full",
                      placeholder: "Enter Right Label",
                      type: "text",
                      value: NpsLabel$ReactHooksTemplate.right(Nps$ReactHooksTemplate.label(nps)),
                      onChange: (function (param) {
                          return onLabelChange(NpsLabel$ReactHooksTemplate.setRight, param);
                        })
                    })));
}

var maxCount = 24;

var make = NpsEditor;

exports.maxCount = maxCount;
exports.make = make;
/* react Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");
var Mode$ReactHooksTemplate = require("./mode.bs.js");
var ApplicationSetting$ReactHooksTemplate = require("../general_settings/applicationSetting.bs.js");

var emptyString = "";

function empty(param) {
  return {
          id: 0,
          uid: emptyString,
          name: emptyString,
          modes: /* [] */0,
          surveyName: emptyString,
          applicationSettings: /* [] */0
        };
}

function id(t) {
  return t.id;
}

function uid(t) {
  return t.uid;
}

function name(t) {
  return t.name;
}

function modes(t) {
  return t.modes;
}

function surveyName(t) {
  return t.surveyName;
}

function applicationSettings(t) {
  return t.applicationSettings;
}

function setApplicationSettings(t, xs) {
  return {
          id: t.id,
          uid: t.uid,
          name: t.name,
          modes: t.modes,
          surveyName: t.surveyName,
          applicationSettings: xs
        };
}

function decode(json) {
  return {
          id: Json_decode.field("id", Json_decode.$$int, json),
          uid: Json_decode.field("uid", Json_decode.string, json),
          name: Json_decode.field("name", Json_decode.string, json),
          modes: Json_decode.field("campaign_modes", (function (param) {
                  return Json_decode.list(Mode$ReactHooksTemplate.decoder, param);
                }), json),
          surveyName: Json_decode.field("survey", (function (param) {
                  return Json_decode.field("name", Json_decode.string, param);
                }), json),
          applicationSettings: Json_decode.withDefault(/* [] */0, (function (param) {
                  return Json_decode.field("system_settings", (function (param) {
                                return Json_decode.list(ApplicationSetting$ReactHooksTemplate.decode, param);
                              }), param);
                }), json)
        };
}

function encode(t) {
  return Json_encode.object_({
              hd: [
                "name",
                t.name
              ],
              tl: {
                hd: [
                  "system_settings_attributes",
                  Json_encode.list(ApplicationSetting$ReactHooksTemplate.encode, t.applicationSettings)
                ],
                tl: /* [] */0
              }
            });
}

exports.id = id;
exports.emptyString = emptyString;
exports.uid = uid;
exports.name = name;
exports.modes = modes;
exports.surveyName = surveyName;
exports.applicationSettings = applicationSettings;
exports.setApplicationSettings = setApplicationSettings;
exports.empty = empty;
exports.decode = decode;
exports.encode = encode;
/* No side effect */

// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Http$ReactHooksTemplate = require("../common/http.bs.js");
var Role$ReactHooksTemplate = require("./role.bs.js");
var RoleCreate$ReactHooksTemplate = require("./RoleCreate.bs.js");

function reducer(param, action) {
  if (action) {
    return /* Available */{
            _0: action._0
          };
  } else {
    return /* InvalidRole */1;
  }
}

function fetchRole(id) {
  var url = "/api/v1/roles/" + id;
  return Http$ReactHooksTemplate.fetchGetJSONDecode(url, (function (param) {
                return Json_decode.field("role", Role$ReactHooksTemplate.decode, param);
              }));
}

function RoleEdit(Props) {
  var id = Props.id;
  var match = React.useReducer(reducer, /* FetchingRoles */0);
  var dispatch = match[1];
  var state = match[0];
  React.useEffect((function () {
          fetchRole(id).then(function (role) {
                return Promise.resolve(Curry._1(dispatch, /* FetchedRole */{
                                _0: role
                              }));
              });
          
        }), []);
  if (typeof state === "number") {
    if (state !== 0) {
      return React.createElement("p", undefined, "Role not found");
    } else {
      return React.createElement("p", undefined, "Loading roles");
    }
  } else {
    return React.createElement("div", undefined, React.createElement(RoleCreate$ReactHooksTemplate.make, {
                    role: state._0
                  }));
  }
}

var make = RoleEdit;

exports.reducer = reducer;
exports.fetchRole = fetchRole;
exports.make = make;
/* react Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var $$Array = require("rescript/lib/js/array.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Utils$ReactHooksTemplate = require("../common/utils.bs.js");
var ListCondition$ReactHooksTemplate = require("./ListCondition.bs.js");
var MultiSelectDropDown$ReactHooksTemplate = require("../common/MultiSelectDropDown.bs.js");

function ListConditionForm(Props) {
  var listCondition = Props.listCondition;
  var updateCondition = Props.updateCondition;
  var operator = ListCondition$ReactHooksTemplate.operator(listCondition);
  var rhs = ListCondition$ReactHooksTemplate.rhs(listCondition);
  var availableOperators = ListCondition$ReactHooksTemplate.availableOperators(listCondition.lhs);
  var rhsOptions = ListCondition$ReactHooksTemplate.answerList(listCondition.lhs);
  var handleOperatorChange = function ($$event) {
    var selectedOperator = Utils$ReactHooksTemplate.formTargetValue($$event);
    var updatedCondition = ListCondition$ReactHooksTemplate.setOperator(listCondition, selectedOperator);
    return Curry._1(updateCondition, {
                TAG: /* ListCondition */2,
                _0: updatedCondition
              });
  };
  return React.createElement("div", {
              className: "flex items-center gap-2"
            }, React.createElement("div", {
                  className: "flex flex-col"
                }, React.createElement("div", {
                      className: "w-48-px h-20-px font-normal text-xs text-aurometalsaurus "
                    }, "Condition"), React.createElement("select", {
                      className: "h-40-px  py-2 px-1 rounded bg-white border border-gray-300 \n                   cursor-pointer focus:border-gray-600 text-xs",
                      placeholder: "Select Operator",
                      value: operator,
                      onChange: handleOperatorChange
                    }, $$Array.of_list(Belt_List.map(availableOperators, (function (operator) {
                                return React.createElement("option", {
                                            key: operator,
                                            className: "text-left bg-white-300 px-2 py-1 hover:bg-gray-300",
                                            value: operator
                                          }, ListCondition$ReactHooksTemplate.humanizeOperator(operator));
                              }))))), React.createElement("div", {
                  className: "flex flex-col"
                }, React.createElement("div", {
                      className: "h-20-px font-normal text-xs text-aurometalsaurus "
                    }, "Answer"), React.createElement(MultiSelectDropDown$ReactHooksTemplate.make, {
                      defaultPlaceholder: "Select Choices",
                      options: Belt_List.map(rhsOptions, (function (param) {
                              return param[1];
                            })),
                      selectedOptions: Belt_List.map(rhs, (function (param) {
                              return param[1];
                            })),
                      handleChange: (function (param) {
                          var selectedRhs = Belt_List.keep(rhsOptions, (function (param$1) {
                                  var text = param$1[1];
                                  return Belt_List.some(param, (function (x) {
                                                return text === x;
                                              }));
                                }));
                          var updatedCondition = ListCondition$ReactHooksTemplate.setRhs(listCondition, selectedRhs);
                          return Curry._1(updateCondition, {
                                      TAG: /* ListCondition */2,
                                      _0: updatedCondition
                                    });
                        }),
                      width: "w-full",
                      multiSelect: !(!ListCondition$ReactHooksTemplate.multipleAnswerEnabled(listCondition.lhs) && operator === "equals")
                    })));
}

var make = ListConditionForm;

exports.make = make;
/* react Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Image$ReactHooksTemplate = require("../common/image.bs.js");
var ToggleInput$ReactHooksTemplate = require("../common/ToggleInput.bs.js");
var CheckboxInput$ReactHooksTemplate = require("../common/CheckboxInput.bs.js");
var GenerateTicket$ReactHooksTemplate = require("./generateTicket.bs.js");

function GenerateTickets(Props) {
  var setting = Props.setting;
  var enforce = Props.enforce;
  var enforced = Props.enforced;
  var onChange = Props.onChange;
  var value = GenerateTicket$ReactHooksTemplate.value(setting);
  return React.createElement("div", undefined, React.createElement("div", {
                  className: "flex flex-col sm:flex-row justify-between w-full items-start sm:items-center"
                }, React.createElement("div", {
                      className: "flex flex-col"
                    }, React.createElement("div", {
                          className: "font-medium text-base text-darkcharcoal"
                        }, React.createElement("p", undefined, "Generate Ticket", enforced ? React.createElement("img", {
                                    className: "h-3 inline ml-1 mb-2 mt-0",
                                    title: "Cannot be modified. Enforced by higher level",
                                    src: Image$ReactHooksTemplate.lock
                                  }) : React.createElement(React.Fragment, undefined)))), React.createElement("div", {
                      className: "flex items-center space-x-8 mt-4 sm:mt-0"
                    }, React.createElement(CheckboxInput$ReactHooksTemplate.make, {
                          checked: enforce,
                          disabled: enforced,
                          label: "Enforce",
                          handleChange: (function (param) {
                              if (!enforced) {
                                return Curry._2(onChange, {
                                            TAG: /* GenerateTicket */1,
                                            _0: setting
                                          }, !enforce);
                              }
                              
                            }),
                          checkboxClassName: "custom_checkbox mt-5-px flex items-center"
                        }), React.createElement(ToggleInput$ReactHooksTemplate.make, {
                          checked: value,
                          disabled: enforced,
                          handleChange: (function (param) {
                              if (enforced) {
                                return ;
                              } else {
                                return Curry._2(onChange, {
                                            TAG: /* GenerateTicket */1,
                                            _0: GenerateTicket$ReactHooksTemplate.setValue(setting, !value)
                                          }, enforce);
                              }
                            }),
                          labelClassName: "flex items-center"
                        }))));
}

var make = GenerateTickets;

exports.make = make;
/* react Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");
var FormField$ReactHooksTemplate = require("../common/FormField.bs.js");

function empty(param) {
  return {
          message: ""
        };
}

function setMessage(x) {
  return {
          message: x
        };
}

function validMessage(x) {
  return x !== "";
}

function toForm(t) {
  return {
          message: t.message !== "" ? ({
                TAG: /* Valid */0,
                _0: t.message
              }) : ({
                TAG: /* Invalid */1,
                _0: t.message,
                _1: {
                  hd: "can't be empty",
                  tl: /* [] */0
                }
              })
        };
}

function fromForm(form) {
  return {
          message: FormField$ReactHooksTemplate.getInputValue(form.message)
        };
}

function encoder(t) {
  return Json_encode.object_({
              hd: [
                "message",
                t.message
              ],
              tl: /* [] */0
            });
}

function decoder(json) {
  return {
          message: Json_decode.field("message", Json_decode.string, json)
        };
}

var modeType = "AppLink";

exports.modeType = modeType;
exports.empty = empty;
exports.setMessage = setMessage;
exports.validMessage = validMessage;
exports.toForm = toForm;
exports.fromForm = fromForm;
exports.encoder = encoder;
exports.decoder = decoder;
/* No side effect */

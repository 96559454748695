// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var List = require("rescript/lib/js/list.js");
var $$Array = require("rescript/lib/js/array.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Utils$ReactHooksTemplate = require("../common/utils.bs.js");
var DropDown$ReactHooksTemplate = require("./DropDown.bs.js");
var RandomId$ReactHooksTemplate = require("./RandomId.bs.js");
var Organization$ReactHooksTemplate = require("../settings/organization.bs.js");
var QuestionText$ReactHooksTemplate = require("./QuestionText.bs.js");
var SurveyLanguage$ReactHooksTemplate = require("./surveyLanguage.bs.js");
var MandatoryMessage$ReactHooksTemplate = require("./MandatoryMessage.bs.js");
var DropDownDefaultForm$ReactHooksTemplate = require("./DropDownDefaultForm.bs.js");

function DropDownForm(Props) {
  var dropDown = Props.dropDown;
  var surveyTheme = Props.surveyTheme;
  var language = Props.language;
  var client = Props.client;
  var mandatory = Props.mandatory;
  var onChange = Props.onChange;
  var borderCss = Props.borderCss;
  var handleOnChange = function ($$event) {
    var choiceId = $$event.target.value;
    var selectedChoice = List.find((function (x) {
            return RandomId$ReactHooksTemplate.toString(DropDown$ReactHooksTemplate.choiceId(x)) === choiceId;
          }), DropDown$ReactHooksTemplate.choices(dropDown));
    return Curry._1(onChange, DropDown$ReactHooksTemplate.setAnswer(dropDown, selectedChoice));
  };
  var matchChoicesWithAnswer = function (choice) {
    var xs = DropDown$ReactHooksTemplate.getAnswer(dropDown);
    if (xs !== undefined) {
      return Belt_List.has(xs, choice, (function (xs, ys) {
                    return Caml_obj.caml_equal(DropDown$ReactHooksTemplate.choiceId(xs), DropDown$ReactHooksTemplate.choiceId(ys));
                  }));
    } else {
      return false;
    }
  };
  var match = DropDown$ReactHooksTemplate.representation(dropDown);
  var backGroundCss = match >= 2 ? "bg-black bg-opacity-50" : "bg-tropicalbreeze";
  var match$1 = Organization$ReactHooksTemplate.name(client);
  switch (match$1) {
    case "bhima" :
    case "ncell" :
    case "nykaa" :
        break;
    default:
      return React.createElement(DropDownDefaultForm$ReactHooksTemplate.make, {
                  dropDown: dropDown,
                  mandatory: mandatory,
                  onChange: onChange,
                  borderCss: borderCss
                });
  }
  var answer = DropDown$ReactHooksTemplate.getAnswer(dropDown);
  var tmp;
  if (answer !== undefined) {
    var choice = Belt_List.head(answer);
    tmp = choice !== undefined ? RandomId$ReactHooksTemplate.toString(DropDown$ReactHooksTemplate.choiceId(choice)) : "";
  } else {
    tmp = "Select here";
  }
  var match$2 = DropDown$ReactHooksTemplate.getAnswer(dropDown);
  var answer$1 = DropDown$ReactHooksTemplate.getAnswer(dropDown);
  var tmp$1;
  if (answer$1 !== undefined) {
    var choice$1 = Belt_List.head(answer$1);
    tmp$1 = choice$1 !== undefined && matchChoicesWithAnswer(choice$1) && DropDown$ReactHooksTemplate.getAllowNonSpecifiedText(choice$1) ? React.createElement("div", undefined, React.createElement("textarea", {
                className: " " + backGroundCss + " w-full border p-4",
                placeholder: "Specify if others",
                rows: 3,
                onChange: (function (param) {
                    return Curry._1(onChange, DropDown$ReactHooksTemplate.setNotSpecifiedTextForAnswer(dropDown, DropDown$ReactHooksTemplate.setNotSpecifiedText(Utils$ReactHooksTemplate.formTargetValue(param), choice$1)));
                  })
              })) : React.createElement(React.Fragment, undefined);
  } else {
    tmp$1 = React.createElement(React.Fragment, undefined);
  }
  return React.createElement("div", {
              className: "flex flex-col"
            }, React.createElement(QuestionText$ReactHooksTemplate.make, {
                  text: DropDown$ReactHooksTemplate.getTextTranslation(dropDown, SurveyLanguage$ReactHooksTemplate.name(language)),
                  description: DropDown$ReactHooksTemplate.getDescriptionTranslation(dropDown, SurveyLanguage$ReactHooksTemplate.name(language)),
                  mandatory: mandatory,
                  surveyTheme: surveyTheme
                }), React.createElement("div", {
                  className: "flex flex-col"
                }, React.createElement("label", {
                      className: "block mb-10-px"
                    }, React.createElement("select", {
                          className: "" + backGroundCss + " p-2 border border-white-300 rounded\n                      flex w-3/4 mb-2 ",
                          value: tmp,
                          onChange: handleOnChange
                        }, match$2 !== undefined ? React.createElement(React.Fragment, undefined) : React.createElement("option", {
                                key: "0",
                                value: "0"
                              }, "Select Field"), $$Array.of_list(Belt_List.map(DropDown$ReactHooksTemplate.nonEmptyChoices(dropDown), (function (choice) {
                                    return React.createElement("option", {
                                                key: RandomId$ReactHooksTemplate.toString(DropDown$ReactHooksTemplate.choiceId(choice)),
                                                value: RandomId$ReactHooksTemplate.toString(DropDown$ReactHooksTemplate.choiceId(choice))
                                              }, DropDown$ReactHooksTemplate.getChoiceTextTranslation(dropDown, choice, SurveyLanguage$ReactHooksTemplate.name(language)));
                                  }))))), tmp$1), mandatory ? React.createElement(MandatoryMessage$ReactHooksTemplate.make, {
                    language: language
                  }) : React.createElement(React.Fragment, undefined));
}

var make = DropDownForm;

exports.make = make;
/* react Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var $$Array = require("rescript/lib/js/array.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Belt_List = require("rescript/lib/js/belt_List.js");
var MultipleChoice$ReactHooksTemplate = require("./MultipleChoice.bs.js");
var SurveyLanguage$ReactHooksTemplate = require("./surveyLanguage.bs.js");
var MandatoryMessage$ReactHooksTemplate = require("./MandatoryMessage.bs.js");
var RadioButtonInput$ReactHooksTemplate = require("../common/RadioButtonInput.bs.js");

function MultipleChoiceDefaultForm(Props) {
  var multipleChoice = Props.multipleChoice;
  var language = Props.language;
  var mandatory = Props.mandatory;
  var onChange = Props.onChange;
  var borderCss = Props.borderCss;
  return React.createElement("div", {
              className: "w-full py-8 px-16 small:px-6 " + borderCss
            }, React.createElement("div", undefined, React.createElement("div", {
                      className: "text-lg font-medium text-darkcharcoal py-6"
                    }, MultipleChoice$ReactHooksTemplate.getTextTranslation(multipleChoice, SurveyLanguage$ReactHooksTemplate.name(language))), React.createElement("div", {
                      className: "text-base font-normal text-darkcharcoal pb-8"
                    }, MultipleChoice$ReactHooksTemplate.getDescriptionTranslation(multipleChoice, SurveyLanguage$ReactHooksTemplate.name(language)))), React.createElement("div", {
                  className: "flex w-full"
                }, React.createElement("div", {
                      className: "flex flex-col"
                    }, React.createElement("div", {
                          className: "pl-2"
                        }, $$Array.of_list(Belt_List.mapWithIndex(MultipleChoice$ReactHooksTemplate.getChoicesTranslation(multipleChoice, SurveyLanguage$ReactHooksTemplate.name(language)), (function (param, param$1) {
                                    var id = param$1[0];
                                    return React.createElement("label", {
                                                key: id,
                                                className: "flex gap-1 text-sm font-normal"
                                              }, React.createElement(RadioButtonInput$ReactHooksTemplate.make, {
                                                    checked: MultipleChoice$ReactHooksTemplate.$eq(multipleChoice, id),
                                                    label: param$1[1],
                                                    handleChange: (function (param) {
                                                        return Curry._1(onChange, MultipleChoice$ReactHooksTemplate.setAnswer(multipleChoice, id));
                                                      }),
                                                    className: "mb-2"
                                                  }));
                                  })))))), mandatory ? React.createElement(MandatoryMessage$ReactHooksTemplate.make, {
                    language: language
                  }) : React.createElement(React.Fragment, undefined));
}

var make = MultipleChoiceDefaultForm;

exports.make = make;
/* react Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var ReactDom = require("react-dom");
var Application$ReactHooksTemplate = require("./Application.bs.js");

var content = document.querySelector("#content");

if (!(content == null)) {
  ReactDom.render(React.createElement(Application$ReactHooksTemplate.make, {}), content);
}

/* content Not a pure module */

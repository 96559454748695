// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var LongText$ReactHooksTemplate = require("./LongText.bs.js");
var Organization$ReactHooksTemplate = require("../settings/organization.bs.js");
var QuestionText$ReactHooksTemplate = require("./QuestionText.bs.js");
var SurveyLanguage$ReactHooksTemplate = require("./surveyLanguage.bs.js");
var MandatoryMessage$ReactHooksTemplate = require("./MandatoryMessage.bs.js");
var LongTextDefaultForm$ReactHooksTemplate = require("./LongTextDefaultForm.bs.js");

function LongTextForm(Props) {
  var longText = Props.longText;
  var surveyTheme = Props.surveyTheme;
  var language = Props.language;
  var client = Props.client;
  var mandatory = Props.mandatory;
  var onChange = Props.onChange;
  var borderCss = Props.borderCss;
  var handleOnchange = function ($$event) {
    var value = $$event.target.value;
    var firstChar = value[0];
    var check = Belt_Array.getBy([
          "<",
          "=",
          "+",
          "-",
          "@"
        ], (function (x) {
            return firstChar === x;
          }));
    if (check !== undefined) {
      return Curry._1(onChange, LongText$ReactHooksTemplate.setAnswer(longText, ""));
    } else {
      return Curry._1(onChange, LongText$ReactHooksTemplate.setAnswer(longText, value));
    }
  };
  var match = LongText$ReactHooksTemplate.representation(longText);
  var backGroundCss = match >= 2 ? "bg-black bg-opacity-50 text-white" : "bg-tropicalbreeze";
  var match$1 = Organization$ReactHooksTemplate.name(client);
  switch (match$1) {
    case "bhima" :
    case "ncell" :
    case "nykaa" :
        break;
    default:
      return React.createElement(LongTextDefaultForm$ReactHooksTemplate.make, {
                  longText: longText,
                  language: language,
                  mandatory: mandatory,
                  onChange: onChange,
                  borderCss: borderCss
                });
  }
  return React.createElement("div", {
              className: "flex flex-col"
            }, React.createElement(QuestionText$ReactHooksTemplate.make, {
                  text: LongText$ReactHooksTemplate.getTextTranslation(longText, SurveyLanguage$ReactHooksTemplate.name(language)),
                  description: LongText$ReactHooksTemplate.getDescriptionTranslation(longText, SurveyLanguage$ReactHooksTemplate.name(language)),
                  mandatory: mandatory,
                  surveyTheme: surveyTheme
                }), React.createElement("p", {
                  className: "flex flex-wrap w-full"
                }, React.createElement("textarea", {
                      className: "" + backGroundCss + " h-127-px overflow-x-hidden\n            w-full outline-none focus:shadow-outline rounded-lg p-2 ",
                      maxLength: 3500,
                      placeholder: LongText$ReactHooksTemplate.getPlaceholderTranslation(longText, SurveyLanguage$ReactHooksTemplate.name(language)),
                      rows: 5,
                      value: Belt_Option.getWithDefault(LongText$ReactHooksTemplate.answer(longText), ""),
                      onChange: handleOnchange
                    })), mandatory ? React.createElement(MandatoryMessage$ReactHooksTemplate.make, {
                    language: language
                  }) : React.createElement(React.Fragment, undefined));
}

var make = LongTextForm;

exports.make = make;
/* react Not a pure module */

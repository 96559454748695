// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Js_json = require("rescript/lib/js/js_json.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var BizCharts$ReactHooksTemplate = require("../common/BizCharts.bs.js");
var GenericChartAttribute$ReactHooksTemplate = require("./genericChartAttribute.bs.js");

var noDataToDisplay = React.createElement("div", {
      className: "flex justify-center p-60-px"
    }, React.createElement("p", undefined, "No data to display"));

function WordcloudComponent(Props) {
  var chartId = Props.chartId;
  var chartAttribute = Props.chartAttribute;
  var handleWordSelection = function (x) {
    var data = Belt_Option.getExn((x == null) ? undefined : Caml_option.some(x)).data;
    var innerData = Belt_Option.getExn((data == null) ? undefined : Caml_option.some(data)).data;
    var word = Belt_Option.getExn((innerData == null) ? undefined : Caml_option.some(innerData)).text;
    window.location.href = "/responses?keyword=" + (word + ("&visualization_id=" + String(chartId)));
    
  };
  var data = GenericChartAttribute$ReactHooksTemplate.data(chartAttribute);
  var tmp;
  if (data !== undefined) {
    var data$1 = Caml_option.valFromOption(data);
    var match = Belt_Option.getWithDefault(Js_json.decodeArray(data$1), []);
    tmp = match.length !== 0 ? React.createElement(BizCharts$ReactHooksTemplate.Wordcloud.make, {
            data: data$1,
            onPointClick: handleWordSelection
          }) : noDataToDisplay;
  } else {
    tmp = noDataToDisplay;
  }
  return React.createElement("div", undefined, tmp);
}

var make = WordcloudComponent;

exports.noDataToDisplay = noDataToDisplay;
exports.make = make;
/* noDataToDisplay Not a pure module */

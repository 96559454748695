// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Belt_List = require("rescript/lib/js/belt_List.js");

function fromBool(x) {
  if (x) {
    return /* Checked */0;
  } else {
    return /* Unchecked */1;
  }
}

function toBool(t) {
  return t === 0;
}

function isIndeterminate(t) {
  return t >= 2;
}

function compare(t1, t2) {
  switch (t1) {
    case /* Checked */0 :
        if (t2 !== 0) {
          return /* Indeterminate */2;
        } else {
          return /* Checked */0;
        }
    case /* Unchecked */1 :
        if (t2 !== 1) {
          return /* Indeterminate */2;
        } else {
          return /* Unchecked */1;
        }
    case /* Indeterminate */2 :
        return /* Indeterminate */2;
    
  }
}

function reduce(xs) {
  if (xs) {
    return Belt_List.reduce(xs, xs.hd, compare);
  } else {
    return /* Unchecked */1;
  }
}

exports.fromBool = fromBool;
exports.toBool = toBool;
exports.isIndeterminate = isIndeterminate;
exports.reduce = reduce;
/* No side effect */

// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var List = require("rescript/lib/js/list.js");
var $$Array = require("rescript/lib/js/array.js");
var Curry = require("rescript/lib/js/curry.js");
var Fetch = require("bs-fetch/src/Fetch.bs.js");
var React = require("react");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Http$ReactHooksTemplate = require("../common/http.bs.js");
var Image$ReactHooksTemplate = require("../common/image.bs.js");
var Utils$ReactHooksTemplate = require("../common/utils.bs.js");
var FeedFile$ReactHooksTemplate = require("./feedFile.bs.js");
var AuthProvider$ReactHooksTemplate = require("../AuthProvider.bs.js");
var Distribution$ReactHooksTemplate = require("./distribution.bs.js");
var Notification$ReactHooksTemplate = require("../common/Notification.bs.js");
var DistributionCreate$ReactHooksTemplate = require("./DistributionCreate.bs.js");

var initialState = {
  loading: true,
  distributions: /* [] */0,
  notification: undefined,
  distributionCreate: false
};

function reducer(state, x) {
  if (typeof x !== "number") {
    if (x.TAG === /* FetchDistributionsCompleted */0) {
      return {
              loading: false,
              distributions: x._0,
              notification: state.notification,
              distributionCreate: state.distributionCreate
            };
    } else {
      return {
              loading: state.loading,
              distributions: state.distributions,
              notification: x._0,
              distributionCreate: state.distributionCreate
            };
    }
  }
  switch (x) {
    case /* FetchDistributionInProgress */0 :
        return {
                loading: true,
                distributions: state.distributions,
                notification: state.notification,
                distributionCreate: state.distributionCreate
              };
    case /* RemoveNotification */1 :
        return {
                loading: state.loading,
                distributions: state.distributions,
                notification: undefined,
                distributionCreate: state.distributionCreate
              };
    case /* ToggleDistributionCreate */2 :
        return {
                loading: state.loading,
                distributions: state.distributions,
                notification: state.notification,
                distributionCreate: !state.distributionCreate
              };
    
  }
}

function DistributionList(Props) {
  var campaignId = Props.campaignId;
  var match = React.useReducer(reducer, initialState);
  var dispatch = match[1];
  var state = match[0];
  var match$1 = React.useContext(AuthProvider$ReactHooksTemplate.authContext);
  var toggleDistributionCreate = function (option) {
    Curry._1(dispatch, /* ToggleDistributionCreate */2);
    if (option === "save") {
      fetch("/api/v1/campaigns/" + campaignId + "/distributions", Fetch.RequestInit.make(/* Get */0, undefined, undefined, undefined, undefined, undefined, /* SameOrigin */1, undefined, undefined, undefined, undefined, undefined)(undefined)).then(function (response) {
                return response.json();
              }).then(function (json) {
              return Promise.resolve(Json_decode.field("distributions", (function (param) {
                                return Json_decode.list(Distribution$ReactHooksTemplate.decode, param);
                              }), json));
            }).then(function (distributions) {
            Curry._1(dispatch, {
                  TAG: /* FetchDistributionsCompleted */0,
                  _0: distributions
                });
            return Promise.resolve(undefined);
          });
      return ;
    }
    
  };
  React.useEffect((function () {
          fetch("/api/v1/campaigns/" + campaignId + "/distributions", Fetch.RequestInit.make(/* Get */0, undefined, undefined, undefined, undefined, undefined, /* SameOrigin */1, undefined, undefined, undefined, undefined, undefined)(undefined)).then(function (response) {
                    return response.json();
                  }).then(function (json) {
                  return Promise.resolve(Json_decode.field("distributions", (function (param) {
                                    return Json_decode.list(Distribution$ReactHooksTemplate.decode, param);
                                  }), json));
                }).then(function (distributions) {
                Curry._1(dispatch, {
                      TAG: /* FetchDistributionsCompleted */0,
                      _0: distributions
                    });
                return Promise.resolve(undefined);
              });
          
        }), []);
  var removeNotification = function (param) {
    return Curry._1(dispatch, /* RemoveNotification */1);
  };
  var bgSourceStatusColor = function (status) {
    switch (status) {
      case "pending" :
          return "bg-candypink";
      case "processed" :
          return "bg-americangreen";
      case "processing" :
          return "bg-cobalt";
      default:
        return "hidden";
    }
  };
  var bgStatusColor = function (status) {
    switch (status) {
      case "finished" :
          return "bg-americangreen";
      case "paused" :
          return "bg-lavenderblush";
      case "pending" :
          return "bg-candypink";
      case "running" :
          return "bg-cobalt";
      case "scheduled" :
          return "bg-yellow-400";
      case "stopped" :
          return "bg-firebrick";
      default:
        return "hidden";
    }
  };
  if (state.loading) {
    return React.createElement("p", undefined, "Loading distributions...");
  }
  var notification = state.notification;
  return React.createElement(React.Fragment, undefined, notification !== undefined ? React.createElement(Notification$ReactHooksTemplate.make, {
                    text: notification,
                    handleClose: removeNotification
                  }) : React.createElement(React.Fragment, undefined), React.createElement("div", undefined, React.createElement("div", {
                      className: "flex justify-between items-center p-20-px"
                    }, React.createElement("h2", {
                          className: "text-xl font-semibold"
                        }, "Distributions"), React.createElement("div", {
                          className: "flex justify-end items-center"
                        }, Curry._1(match$1[0], /* CreateDistribution */21) ? React.createElement("div", {
                                className: "bg-ghostwhite border border-gray-400\n                    shadow-inner flex rounded py-2 px-3 text-xs cursor-pointer",
                                id: "add-distribution",
                                onClick: (function (param) {
                                    return Curry._1(dispatch, /* ToggleDistributionCreate */2);
                                  })
                              }, React.createElement("img", {
                                    className: "h-14-px mr-2-px",
                                    src: Image$ReactHooksTemplate.plusCircle
                                  }), "New Distribution") : React.createElement(React.Fragment, undefined))), React.createElement("div", {
                      className: "flex px-20-px"
                    }, React.createElement("table", {
                          className: "text-left table-auto w-full border-separate text-sm font-medium"
                        }, React.createElement("thead", {
                              className: "font-medium p-4 bg-ghostwhite text-aurometalsaurus"
                            }, React.createElement("tr", undefined, React.createElement("th", {
                                      className: "px-4 py-3"
                                    }, React.createElement("div", {
                                          className: "flex"
                                        }, "UID", React.createElement("img", {
                                              className: "ml-4-px",
                                              src: Image$ReactHooksTemplate.heading
                                            }))), React.createElement("th", {
                                      className: "px-4 py-3"
                                    }, React.createElement("div", {
                                          className: "flex"
                                        }, "Source Type", React.createElement("img", {
                                              className: "ml-4-px",
                                              src: Image$ReactHooksTemplate.heading
                                            }))), React.createElement("th", {
                                      className: "px-4 py-3"
                                    }, React.createElement("div", {
                                          className: "flex"
                                        }, "Source Detail", React.createElement("img", {
                                              className: "ml-4-px",
                                              src: Image$ReactHooksTemplate.heading
                                            }))), React.createElement("th", {
                                      className: "px-4 py-3"
                                    }, React.createElement("div", {
                                          className: "flex"
                                        }, "Source Status", React.createElement("img", {
                                              className: "ml-4-px",
                                              src: Image$ReactHooksTemplate.heading
                                            }))), React.createElement("th", {
                                      className: "px-4 py-3"
                                    }, React.createElement("div", {
                                          className: "flex"
                                        }, "Total", React.createElement("img", {
                                              className: "ml-4-px",
                                              src: Image$ReactHooksTemplate.heading
                                            }))), React.createElement("th", {
                                      className: "px-4 py-3"
                                    }, React.createElement("div", {
                                          className: "flex"
                                        }, "Status", React.createElement("img", {
                                              className: "ml-4-px",
                                              src: Image$ReactHooksTemplate.heading
                                            }))), React.createElement("th", {
                                      className: "px-4 py-3"
                                    }, React.createElement("div", {
                                          className: "flex"
                                        }, "Created At", React.createElement("img", {
                                              className: "ml-4-px",
                                              src: Image$ReactHooksTemplate.heading
                                            }))), React.createElement("th", {
                                      className: "px-4 py-3"
                                    }, React.createElement("div", {
                                          className: "flex"
                                        }, "Actions", React.createElement("img", {
                                              className: "ml-4-px",
                                              src: Image$ReactHooksTemplate.heading
                                            }))))), React.createElement("tbody", undefined, $$Array.of_list(List.map((function (x) {
                                        var y = Distribution$ReactHooksTemplate.customerSource(x);
                                        var y$1 = Distribution$ReactHooksTemplate.customerSource(x);
                                        var y$2 = y$1._0;
                                        var y$3 = Distribution$ReactHooksTemplate.customerSource(x);
                                        var match = Distribution$ReactHooksTemplate.status(x);
                                        var tmp = match === "pending" ? React.createElement("input", {
                                                className: "text-white text-sm bg-pigmentblue hover:bg-teal-800 py-1\n                                px-3 rounded w-80-px",
                                                type: "button",
                                                value: "Start",
                                                onClick: (function (param) {
                                                    param.preventDefault();
                                                    var distributionId = String(Distribution$ReactHooksTemplate.id(x));
                                                    var url = "/api/v1/distributions/" + distributionId + "/start";
                                                    Http$ReactHooksTemplate.fetchPutJSON(url, {}).then(function (response) {
                                                          var status = Json_decode.field("status", (function (param) {
                                                                  return Json_decode.withDefault("failed", Json_decode.string, param);
                                                                }), response);
                                                          if (status === "success") {
                                                            var distribution = Json_decode.field("distribution", Distribution$ReactHooksTemplate.decode, response);
                                                            Curry._1(dispatch, {
                                                                  TAG: /* FetchDistributionsCompleted */0,
                                                                  _0: Belt_List.map(state.distributions, (function (y) {
                                                                          if (Distribution$ReactHooksTemplate.id(distribution) === Distribution$ReactHooksTemplate.id(y)) {
                                                                            return distribution;
                                                                          } else {
                                                                            return y;
                                                                          }
                                                                        }))
                                                                });
                                                            Curry._1(dispatch, {
                                                                  TAG: /* AddNotification */1,
                                                                  _0: "Successfully started\n         distribution"
                                                                });
                                                          } else {
                                                            Curry._1(dispatch, {
                                                                  TAG: /* AddNotification */1,
                                                                  _0: "Something went wrong. Please try\n         again later"
                                                                });
                                                          }
                                                          return Promise.resolve(undefined);
                                                        });
                                                    
                                                  })
                                              }) : React.createElement(React.Fragment, undefined);
                                        return React.createElement("tr", {
                                                    key: Distribution$ReactHooksTemplate.uid(x),
                                                    className: "border-b border-bottom-gray-600"
                                                  }, React.createElement("td", {
                                                        className: "px-4 py-3"
                                                      }, React.createElement("div", {
                                                            className: "flex items-center"
                                                          }, React.createElement("div", {
                                                                className: "w-8 h-8 flex items-center text-white uppercase mr-3 rounded-lg " + Utils$ReactHooksTemplate.randomBgColor(undefined)
                                                              }, React.createElement("div", {
                                                                    className: "m-auto"
                                                                  }, Distribution$ReactHooksTemplate.uid(x)[0])), Distribution$ReactHooksTemplate.uid(x))), React.createElement("td", {
                                                        className: "px-4 py-3"
                                                      }, React.createElement("div", {
                                                            className: "text-xs px-3 py-2 rounded-2xl flex items-center\n                                        border border-gray-400 w-fit font-normal text-aurometalsaurus"
                                                          }, Distribution$ReactHooksTemplate.customerSourceType(x))), React.createElement("td", {
                                                        className: "px-4 py-3"
                                                      }, React.createElement("div", {
                                                            className: "text-xs px-3 py-2 w-fit font-normal text-aurometalsaurus"
                                                          }, FeedFile$ReactHooksTemplate.name(y._0))), React.createElement("td", {
                                                        className: "px-4 py-3"
                                                      }, React.createElement("div", {
                                                            className: "text-xs px-3 py-2 rounded-2xl flex gap-1 items-center\n                                            border border-gray-400 w-fit font-normal text-aurometalsaurus"
                                                          }, React.createElement("div", {
                                                                className: "h-3 w-3 rounded-full " + bgSourceStatusColor(FeedFile$ReactHooksTemplate.status(y$2))
                                                              }), FeedFile$ReactHooksTemplate.status(y$2).toUpperCase())), React.createElement("td", {
                                                        className: "px-4 py-3"
                                                      }, String(FeedFile$ReactHooksTemplate.count(y$3._0))), React.createElement("td", {
                                                        className: "px-4 py-3"
                                                      }, React.createElement("div", {
                                                            className: "text-xs px-3 py-2 rounded-2xl flex gap-1 items-center\n                                        border border-gray-400 w-fit font-normal text-aurometalsaurus"
                                                          }, React.createElement("div", {
                                                                className: "h-3 w-3 rounded-full " + bgStatusColor(Distribution$ReactHooksTemplate.status(x))
                                                              }), Distribution$ReactHooksTemplate.status(x).toUpperCase())), React.createElement("td", {
                                                        className: "px-4 py-3"
                                                      }, React.createElement("div", {
                                                            className: "text-xs px-3 py-2 rounded-2xl flex items-center\n                                    border border-gray-400 w-fit font-normal text-aurometalsaurus"
                                                          }, Distribution$ReactHooksTemplate.createdAt(x).format("lll"))), React.createElement("td", {
                                                        className: "px-4 py-3"
                                                      }, tmp));
                                      }), state.distributions)))))), state.distributionCreate ? React.createElement("div", {
                    className: "w-screen z-10 disabled-wrapper absolute inset-0 flex justify-center items-center",
                    style: {
                      height: "calc(100vh + 5rem)"
                    }
                  }, React.createElement("div", {
                        className: "bg-white modal-campaign z-10 shadow relative border border-gray-300 pointer-events-auto"
                      }, React.createElement("div", {
                            className: "p-8 text-lg font-bold flex justify-between"
                          }, React.createElement("div", undefined, "Create New Distribution"), React.createElement("img", {
                                className: "h-14-px float-right cursor-pointer",
                                src: Image$ReactHooksTemplate.closeIcon,
                                onClick: (function (param) {
                                    return Curry._1(dispatch, /* ToggleDistributionCreate */2);
                                  })
                              })), React.createElement("div", {
                            className: "border-t border-gray-200 text-base font-medium h-432-px"
                          }, React.createElement(DistributionCreate$ReactHooksTemplate.make, {
                                campaignId: campaignId,
                                toggleDistributionCreate: toggleDistributionCreate
                              })))) : React.createElement(React.Fragment, undefined));
}

var make = DistributionList;

exports.initialState = initialState;
exports.reducer = reducer;
exports.make = make;
/* react Not a pure module */

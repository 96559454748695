// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Api$ReactHooksTemplate = require("./Api.bs.js");
var Sftp$ReactHooksTemplate = require("./Sftp.bs.js");
var Languages$ReactHooksTemplate = require("./Languages.bs.js");
var SmsChannel$ReactHooksTemplate = require("./SmsChannel.bs.js");
var EmailChannel$ReactHooksTemplate = require("./EmailChannel.bs.js");
var SettingsMenu$ReactHooksTemplate = require("./SettingsMenu.bs.js");
var SurveyPolicy$ReactHooksTemplate = require("./SurveyPolicy.bs.js");
var SocialChannel$ReactHooksTemplate = require("./SocialChannel.bs.js");
var StandardPages$ReactHooksTemplate = require("./StandardPages.bs.js");
var CampaignPolicy$ReactHooksTemplate = require("./CampaignPolicy.bs.js");
var EmailTemplates$ReactHooksTemplate = require("./EmailTemplates.bs.js");
var WhatsAppChannel$ReactHooksTemplate = require("./WhatsAppChannel.bs.js");
var OrganisationProfile$ReactHooksTemplate = require("./OrganisationProfile.bs.js");
var OrganizationConfigurations$ReactHooksTemplate = require("./OrganizationConfigurations.bs.js");

function GeneralSettings(Props) {
  var match = React.useState(function () {
        return "generalSettings";
      });
  var selectedMainMenu = match[0];
  var match$1 = React.useState(function () {
        return "Organisation Profile";
      });
  var selectedSubMenu = match$1[0];
  var tmp;
  switch (selectedMainMenu) {
    case "distribution" :
        switch (selectedSubMenu) {
          case "API" :
              tmp = React.createElement(Api$ReactHooksTemplate.make, {});
              break;
          case "sFTP" :
              tmp = React.createElement(Sftp$ReactHooksTemplate.make, {});
              break;
          default:
            tmp = React.createElement(React.Fragment, undefined);
        }
        break;
    case "generalSettings" :
        switch (selectedSubMenu) {
          case "Campaign Policy" :
              tmp = React.createElement(CampaignPolicy$ReactHooksTemplate.make, {});
              break;
          case "Languages" :
              tmp = React.createElement(Languages$ReactHooksTemplate.make, {});
              break;
          case "Organisation Profile" :
              tmp = React.createElement(OrganisationProfile$ReactHooksTemplate.make, {});
              break;
          case "Organization Configurations" :
              tmp = React.createElement(OrganizationConfigurations$ReactHooksTemplate.make, {});
              break;
          case "Survey Policy" :
              tmp = React.createElement(SurveyPolicy$ReactHooksTemplate.make, {});
              break;
          default:
            tmp = React.createElement(React.Fragment, undefined);
        }
        break;
    case "messagingChannels" :
        switch (selectedSubMenu) {
          case "Email" :
              tmp = React.createElement(EmailChannel$ReactHooksTemplate.make, {});
              break;
          case "SMS" :
              tmp = React.createElement(SmsChannel$ReactHooksTemplate.make, {});
              break;
          case "Social" :
              tmp = React.createElement(SocialChannel$ReactHooksTemplate.make, {});
              break;
          case "WhatsApp" :
              tmp = React.createElement(WhatsAppChannel$ReactHooksTemplate.make, {});
              break;
          default:
            tmp = React.createElement(React.Fragment, undefined);
        }
        break;
    case "templates" :
        switch (selectedSubMenu) {
          case "Email" :
              tmp = React.createElement(EmailTemplates$ReactHooksTemplate.make, {});
              break;
          case "Standard Pages" :
              tmp = React.createElement(StandardPages$ReactHooksTemplate.make, {});
              break;
          default:
            tmp = React.createElement(React.Fragment, undefined);
        }
        break;
    default:
      tmp = React.createElement(React.Fragment, undefined);
  }
  return React.createElement("div", {
              className: "flex"
            }, React.createElement("div", {
                  className: "w-1/5 border-r-4 border-antiflashblue bg-ghostwhite h-screen"
                }, React.createElement(SettingsMenu$ReactHooksTemplate.make, {
                      selectedMainMenu: selectedMainMenu,
                      selectedSubMenu: selectedSubMenu,
                      setSelectedMainMenu: match[1],
                      setSelectedSubMenu: match$1[1]
                    })), React.createElement("div", {
                  className: "w-4/5"
                }, tmp));
}

var make = GeneralSettings;

exports.make = make;
/* react Not a pure module */

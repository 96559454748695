// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var List = require("rescript/lib/js/list.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Question$ReactHooksTemplate = require("./Question.bs.js");
var Condition$ReactHooksTemplate = require("./Condition.bs.js");

function empty(param) {
  return /* Empty */0;
}

function addNode(node, graph) {
  if (graph) {
    return /* Graph */{
            _0: {
              hd: node,
              tl: graph._0
            },
            _1: graph._1
          };
  } else {
    return /* Graph */{
            _0: {
              hd: node,
              tl: /* [] */0
            },
            _1: /* [] */0
          };
  }
}

function addEdge(edge, graph) {
  if (graph) {
    return /* Graph */{
            _0: graph._0,
            _1: {
              hd: edge,
              tl: graph._1
            }
          };
  } else {
    return /* Empty */0;
  }
}

function getEdges(node, graph) {
  if (graph) {
    return List.filter(function (x) {
                  return Question$ReactHooksTemplate.equals(node, x._0);
                })(graph._1);
  } else {
    return /* [] */0;
  }
}

function removeEdgeForNode(from_, graph) {
  if (!graph) {
    return /* Empty */0;
  }
  var edges = graph._1;
  var nodes = graph._0;
  if (edges) {
    return /* Graph */{
            _0: nodes,
            _1: List.filter(function (x) {
                    switch (x.TAG | 0) {
                      case /* DefaultLogic */0 :
                          return !Question$ReactHooksTemplate.equals(from_, x._0);
                      case /* SkipLogic */1 :
                      case /* JumpTo */2 :
                          return true;
                      
                    }
                  })(edges)
          };
  } else {
    return /* Graph */{
            _0: nodes,
            _1: /* [] */0
          };
  }
}

function changeEdge(from, edge, graph) {
  return addEdge(edge, removeEdgeForNode(from, graph));
}

function getNextNode(from, graph) {
  if (graph) {
    return Belt_List.head(List.fold_left((function (nextNodes, x) {
                      switch (x.TAG | 0) {
                        case /* DefaultLogic */0 :
                            var to_ = x._1;
                            if (to_.TAG === /* ThankYou */0 || !Question$ReactHooksTemplate.equals(x._0, from)) {
                              return nextNodes;
                            } else {
                              return {
                                      hd: to_,
                                      tl: nextNodes
                                    };
                            }
                        case /* SkipLogic */1 :
                            var to_$1 = x._1;
                            if (!(Question$ReactHooksTemplate.equals(x._0, from) && List.fold_left((function (a, x) {
                                        if (Condition$ReactHooksTemplate.isValid(x)) {
                                          return a;
                                        } else {
                                          return false;
                                        }
                                      }), true, x._2))) {
                              return nextNodes;
                            }
                            if (to_$1.TAG === /* ThankYou */0) {
                              return /* [] */0;
                            }
                            var x$1 = getNextNode(to_$1._0, graph);
                            if (x$1 !== undefined) {
                              return {
                                      hd: x$1,
                                      tl: nextNodes
                                    };
                            } else {
                              return /* [] */0;
                            }
                        case /* JumpTo */2 :
                            if (Question$ReactHooksTemplate.equals(x._0, from) && List.fold_left((function (a, x) {
                                      return Condition$ReactHooksTemplate.isValid(x) ? a : false;
                                    }), true, x._2)) {
                              return {
                                      hd: x._1,
                                      tl: nextNodes
                                    };
                            } else {
                              return nextNodes;
                            }
                        
                      }
                    }), /* [] */0, graph._1));
  }
  
}

function getPrevNode(to_, graph) {
  if (graph) {
    return Belt_List.head(List.fold_left((function (nextNodes, x) {
                      switch (x.TAG | 0) {
                        case /* DefaultLogic */0 :
                            var to__ = x._1;
                            if (to__.TAG === /* ThankYou */0 || !Question$ReactHooksTemplate.equals(to_, to__._0)) {
                              return nextNodes;
                            } else {
                              return {
                                      hd: {
                                        TAG: /* Question */1,
                                        _0: x._0
                                      },
                                      tl: nextNodes
                                    };
                            }
                        case /* SkipLogic */1 :
                            var to__$1 = x._1;
                            if (to__$1.TAG === /* ThankYou */0) {
                              return nextNodes;
                            }
                            if (!(Question$ReactHooksTemplate.equals(to_, to__$1._0) && List.fold_left((function (a, x) {
                                        if (Condition$ReactHooksTemplate.isValid(x)) {
                                          return a;
                                        } else {
                                          return false;
                                        }
                                      }), true, x._2))) {
                              return nextNodes;
                            }
                            var x$1 = getPrevNode(x._0, graph);
                            if (x$1 !== undefined) {
                              return {
                                      hd: x$1,
                                      tl: nextNodes
                                    };
                            } else {
                              return nextNodes;
                            }
                        case /* JumpTo */2 :
                            var to__$2 = x._1;
                            if (to__$2.TAG === /* ThankYou */0 || !(Question$ReactHooksTemplate.equals(to_, to__$2._0) && List.fold_left((function (a, x) {
                                        return Condition$ReactHooksTemplate.isValid(x) ? a : false;
                                      }), true, x._2))) {
                              return nextNodes;
                            } else {
                              return {
                                      hd: {
                                        TAG: /* Question */1,
                                        _0: x._0
                                      },
                                      tl: nextNodes
                                    };
                            }
                        
                      }
                    }), /* [] */0, graph._1));
  }
  
}

exports.addNode = addNode;
exports.empty = empty;
exports.addEdge = addEdge;
exports.getEdges = getEdges;
exports.changeEdge = changeEdge;
exports.getNextNode = getNextNode;
exports.getPrevNode = getPrevNode;
/* Question-ReactHooksTemplate Not a pure module */

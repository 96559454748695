// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Highcharts$ReactHooksTemplate = require("../../common/Highcharts.bs.js");

function MapComponent(Props) {
  var mapAttribute = Props.mapAttribute;
  var enlarged = Props.enlarged;
  var chartHeight = Props.chartHeight;
  var mapHeight = enlarged ? "50%" : (
      chartHeight === "h-64" ? "216" : "432"
    );
  return React.createElement("div", {
              className: chartHeight
            }, React.createElement(Highcharts$ReactHooksTemplate.HighchartsBinding.make, {
                  colorAxis: Highcharts$ReactHooksTemplate.buildColorAxisDict({
                        dataClasses: {
                          hd: {
                            from: -100,
                            _to: 0,
                            color: "#fc8181"
                          },
                          tl: {
                            hd: {
                              from: 0,
                              _to: 50,
                              color: "#f6ad55"
                            },
                            tl: {
                              hd: {
                                from: 50,
                                _to: 100,
                                color: "#68d391"
                              },
                              tl: /* [] */0
                            }
                          }
                        },
                        minColor: "#fc8181",
                        maxColor: "#68d391"
                      }),
                  series: mapAttribute.series,
                  drilldown: mapAttribute.drilldown,
                  mapHeight: mapHeight,
                  dataLabels: enlarged
                }));
}

var make = MapComponent;

exports.make = make;
/* react Not a pure module */

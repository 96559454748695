// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var List = require("rescript/lib/js/list.js");
var $$Array = require("rescript/lib/js/array.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var MomentRe = require("bs-moment/src/MomentRe.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Caml_array = require("rescript/lib/js/caml_array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_format = require("rescript/lib/js/caml_format.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Http$ReactHooksTemplate = require("../common/http.bs.js");
var Image$ReactHooksTemplate = require("../common/image.bs.js");
var Utils$ReactHooksTemplate = require("../common/utils.bs.js");
var Domain$ReactHooksTemplate = require("../settings/domain.bs.js");
var Ticket$ReactHooksTemplate = require("./ticket.bs.js");
var AuthProvider$ReactHooksTemplate = require("../AuthProvider.bs.js");
var TicketActivity$ReactHooksTemplate = require("./ticketActivity.bs.js");
var TicketHistoryView$ReactHooksTemplate = require("./TicketHistoryView.bs.js");
var TicketActivityView$ReactHooksTemplate = require("./TicketActivityView.bs.js");

function updateTicket(id, status, parkedTill, comments) {
  var statusEndpoint;
  switch (status) {
    case /* Open */0 :
        statusEndpoint = "reopen";
        break;
    case /* Parked */1 :
        statusEndpoint = "park";
        break;
    case /* Closed */2 :
        statusEndpoint = "force_close";
        break;
    case /* Resolved */3 :
        statusEndpoint = "resolve";
        break;
    
  }
  var params;
  if (status !== 1) {
    if (status !== 0) {
      var params$1 = {};
      params$1["comments"] = comments;
      var ticket = {};
      ticket["ticket"] = params$1;
      params = ticket;
    } else {
      params = {};
    }
  } else {
    var params$2 = {};
    params$2["parked_till"] = parkedTill;
    var ticket$1 = {};
    ticket$1["ticket"] = params$2;
    params = ticket$1;
  }
  return Http$ReactHooksTemplate.fetchPostJSON("/api/v1/tickets/" + id + "/" + statusEndpoint, params);
}

function isValidComment(comment) {
  if (comment === "") {
    return false;
  } else {
    return true;
  }
}

var emptyState = {
  ticketActivities: /* [] */0,
  ticketHistory: /* [] */0,
  currentPage: "1"
};

var initialAccordionState = {
  feedbackTab: false,
  ticketTab: false,
  campaignTab: false
};

function page(x) {
  if (x !== undefined) {
    return x;
  } else {
    return "1";
  }
}

function TicketView(Props) {
  var ticket = Props.ticket;
  var updateTickets = Props.updateTickets;
  var handleAssigneeSelection = Props.handleAssigneeSelection;
  var assignTicket = Props.assignTicket;
  var agents = Props.agents;
  var match = React.useState(function () {
        return /* Open */0;
      });
  var setAction = match[1];
  var action = match[0];
  var match$1 = React.useState(function () {
        return "activity";
      });
  var setActiveHeader = match$1[1];
  var activeHeader = match$1[0];
  var match$2 = React.useState(function () {
        return "";
      });
  var setParkedTill = match$2[1];
  var parkedTill = match$2[0];
  var match$3 = React.useState(function () {
        return "";
      });
  var setComments = match$3[1];
  var comments = match$3[0];
  var match$4 = React.useState(function () {
        return "";
      });
  var setTicketComment = match$4[1];
  var ticketComment = match$4[0];
  var match$5 = React.useState(function () {
        return initialAccordionState;
      });
  var setAccordionState = match$5[1];
  var accordionState = match$5[0];
  var match$6 = React.useState(function () {
        return false;
      });
  var setOpenStatusModal = match$6[1];
  var match$7 = React.useState(function () {
        return false;
      });
  var setOpenAssignModal = match$7[1];
  var match$8 = React.useState(function () {
        return emptyState;
      });
  var setState = match$8[1];
  var state = match$8[0];
  var match$9 = React.useContext(AuthProvider$ReactHooksTemplate.authContext);
  var hasPermission = match$9[0];
  var activeCss = function (currentHeader) {
    if (activeHeader === currentHeader) {
      return "text-black font-semibold border-b-2 border-palatinateblue m-b-0";
    } else {
      return "";
    }
  };
  var fetchTicketActivities = function (param) {
    var ticketId = String(Ticket$ReactHooksTemplate.id(ticket));
    return Http$ReactHooksTemplate.fetchGetJSONWithHeader("/api/v1/tickets/" + ticketId + "/ticket_activities").then(function (param) {
                return param[1].then(function (json) {
                            return Promise.resolve(Json_decode.field("ticket_activities", (function (param) {
                                              return Json_decode.list(TicketActivity$ReactHooksTemplate.decode, param);
                                            }), json));
                          });
              });
  };
  var fetchTicketHistory = function (pageNumber) {
    var email = Ticket$ReactHooksTemplate.email(ticket);
    var msisdn = Ticket$ReactHooksTemplate.msisdn(ticket);
    return Http$ReactHooksTemplate.fetchGetJSONWithHeader("/api/v1/tickets/tickets_by_customer?email=" + email + "&msisdn=" + msisdn + "&page=" + pageNumber).then(function (param) {
                var x = param[0].get("Page");
                var page = x !== null ? (
                    x === null ? undefined : Caml_option.some(x)
                  ) : "1";
                var ticket_history = param[1].then(function (json) {
                      return Promise.resolve(Json_decode.field("tickets", (function (param) {
                                        return Json_decode.list(Ticket$ReactHooksTemplate.decode, param);
                                      }), json));
                    });
                return Promise.resolve([
                            ticket_history,
                            page
                          ]);
              });
  };
  var domElement = React.useRef(null);
  var handleScroll = function (param) {
    var element = Belt_Option.getExn(Caml_option.nullable_to_opt(domElement.current));
    var bottom = element.scrollHeight - element.scrollTop - element.clientHeight < 5.0;
    if (!bottom) {
      return ;
    }
    var page = Caml_format.caml_int_of_string(state.currentPage);
    var pageNumber = String(page + 1 | 0);
    fetchTicketHistory(pageNumber).then(function (param) {
          var page = param[1];
          return Promise.resolve(param[0].then(function (ticket_history) {
                          var mergedTicketHistory = List.append(state.ticketHistory, ticket_history);
                          return Promise.resolve(Curry._1(setState, (function (param) {
                                            return {
                                                    ticketActivities: state.ticketActivities,
                                                    ticketHistory: mergedTicketHistory,
                                                    currentPage: page
                                                  };
                                          })));
                        }));
        });
    
  };
  React.useEffect((function () {
          switch (activeHeader) {
            case "activity" :
                fetchTicketActivities(state.currentPage).then(function (activities) {
                      return Promise.resolve(Curry._1(setState, (function (state) {
                                        return {
                                                ticketActivities: activities,
                                                ticketHistory: state.ticketHistory,
                                                currentPage: state.currentPage
                                              };
                                      })));
                    });
                return ;
            case "ticket_history" :
                fetchTicketHistory(state.currentPage).then(function (param) {
                      var page = param[1];
                      return Promise.resolve(param[0].then(function (ticket_history) {
                                      return Promise.resolve(Curry._1(setState, (function (param) {
                                                        return {
                                                                ticketActivities: state.ticketActivities,
                                                                ticketHistory: ticket_history,
                                                                currentPage: page
                                                              };
                                                      })));
                                    }));
                    });
                return ;
            default:
              return ;
          }
        }), [
        ticket,
        activeHeader
      ]);
  var emailText = function (assignee) {
    return Curry._1(Domain$ReactHooksTemplate.$$String.Email.show, Ticket$ReactHooksTemplate.Assignee.email(assignee));
  };
  var handleStatusChange = function ($$event) {
    var status = Ticket$ReactHooksTemplate.Status.fromString(Utils$ReactHooksTemplate.formTargetValue($$event));
    Curry._1(setAction, (function (param) {
            return status;
          }));
    return Curry._1(setOpenStatusModal, (function (param) {
                  return true;
                }));
  };
  var handleParkedTillChange = function ($$event) {
    var date = Utils$ReactHooksTemplate.formTargetValue($$event);
    return Curry._1(setParkedTill, (function (param) {
                  return date;
                }));
  };
  var handleCommentsChange = function ($$event) {
    var text = Utils$ReactHooksTemplate.formTargetValue($$event);
    return Curry._1(setComments, (function (param) {
                  return text;
                }));
  };
  var handleTicketCommentChanges = function ($$event) {
    var text = Utils$ReactHooksTemplate.formTargetValue($$event);
    return Curry._1(setTicketComment, (function (param) {
                  return text;
                }));
  };
  var handleSubmit = function (param) {
    var parkedMoment = MomentRe.moment(undefined, parkedTill);
    var today = MomentRe.moment(undefined, new Date().toISOString());
    var isParkable = parkedMoment.isAfter(today) && action === /* Parked */1;
    var isResolved = isValidComment(comments) && action === /* Resolved */3;
    var isValidForm = action === /* Open */0 || action === /* Closed */2 || isResolved || isParkable;
    if (isValidForm) {
      updateTicket(String(Ticket$ReactHooksTemplate.id(ticket)), action, parkedTill, comments).then(function (response) {
                return Promise.resolve((console.log(response), undefined));
              }).then(function (param) {
              return Promise.resolve(Curry._1(setOpenStatusModal, (function (param) {
                                return false;
                              })));
            }).then(function (param) {
            return Promise.resolve(Curry._2(updateTickets, Ticket$ReactHooksTemplate.id(ticket), action));
          });
      return ;
    }
    
  };
  var handleCommentSubmit = function (param) {
    var comment = {};
    comment["comment"] = ticketComment;
    var params = {};
    params["ticket"] = comment;
    var id = String(Ticket$ReactHooksTemplate.id(ticket));
    Http$ReactHooksTemplate.fetchPostJSON("/api/v1/tickets/" + id + "/update_comment", params).then(function (param) {
          return fetchTicketActivities(state.currentPage).then(function (activities) {
                      return Promise.resolve((Curry._1(setState, (function (state) {
                                          return {
                                                  ticketActivities: activities,
                                                  ticketHistory: state.ticketHistory,
                                                  currentPage: state.currentPage
                                                };
                                        })), Curry._1(setTicketComment, (function (param) {
                                          return "";
                                        }))));
                    });
        });
    
  };
  var displayTicketActivites = function (activity) {
    return React.createElement("div", {
                key: String(TicketActivity$ReactHooksTemplate.id(activity)),
                className: "w-full mb-4"
              }, React.createElement(TicketActivityView$ReactHooksTemplate.make, {
                    activity: activity
                  }));
  };
  var displayTicketHistory = function (ticket_history) {
    return React.createElement("div", {
                key: String(Ticket$ReactHooksTemplate.id(ticket_history)),
                className: "w-full mb-4"
              }, React.createElement(TicketHistoryView$ReactHooksTemplate.make, {
                    ticket_history: ticket_history
                  }));
  };
  var displayData = function (param) {
    console.log(state.ticketActivities);
    switch (activeHeader) {
      case "activity" :
          return React.createElement("div", {
                      className: "p-4 overflow-auto h-365-px"
                    }, $$Array.of_list(Belt_List.map(state.ticketActivities, displayTicketActivites)));
      case "ticket_history" :
          return React.createElement("div", {
                      ref: domElement,
                      className: "p-4 overflow-y-scroll",
                      style: {
                        height: "calc(100vh - 30rem)"
                      },
                      onScroll: handleScroll
                    }, React.createElement("div", {
                          className: "w-full mb-4"
                        }, $$Array.of_list(Belt_List.map(state.ticketHistory, displayTicketHistory))));
      case "user_log" :
          return React.createElement(React.Fragment, undefined);
      default:
        return React.createElement(React.Fragment, undefined);
    }
  };
  var ticketPriority = function (priority) {
    switch (priority) {
      case "High" :
          return "bg-orange-200 text-orange-500";
      case "Low" :
          return "bg-green-100 text-green-600";
      case "Medium" :
          return "bg-yellow-200 text-yellow-500";
      case "red_alert" :
          return "bg-lavenderblush text-firebrick";
      default:
        return "bg-white text-blackcoral";
    }
  };
  var ticketPriorityString = function (priority) {
    if (priority === "red_alert") {
      return "Red Alert";
    } else {
      return priority;
    }
  };
  var match$10 = Ticket$ReactHooksTemplate.sourceType(ticket);
  var tmp;
  if (match$10) {
    tmp = React.createElement(React.Fragment, undefined);
  } else {
    var tmp$1;
    if (accordionState.feedbackTab) {
      var response = Ticket$ReactHooksTemplate.normalResponses(ticket);
      tmp$1 = React.createElement("div", {
            className: "overflow-auto max-h-200-px"
          }, response !== undefined ? Belt_Array.mapWithIndex(response.header, (function (i, h) {
                    return React.createElement("div", {
                                className: "font-normal text-sm flex mt-4 text-left flex-wrap"
                              }, React.createElement("div", {
                                    className: "text-aurometalsaurus w-1/3 break-all"
                                  }, h), React.createElement("div", {
                                    className: "pl-2 w-2/3 break-all"
                                  }, Belt_Option.getWithDefault(Caml_array.get(response.contents, i), "")));
                  })) : React.createElement("span", undefined, "----No Response----"));
    } else {
      tmp$1 = React.createElement(React.Fragment, undefined);
    }
    tmp = React.createElement("div", {
          className: "w-full mt-12"
        }, React.createElement("div", {
              className: "flex justify-between font-semibold text-xs",
              onClick: (function (param) {
                  return Curry._1(setAccordionState, (function (accordionState) {
                                return {
                                        feedbackTab: !accordionState.feedbackTab,
                                        ticketTab: accordionState.ticketTab,
                                        campaignTab: accordionState.campaignTab
                                      };
                              }));
                })
            }, "Feedback Response", accordionState.feedbackTab ? React.createElement("img", {
                    className: "h-7-px",
                    src: Image$ReactHooksTemplate.chevronUp
                  }) : React.createElement("img", {
                    className: "h-7-px filter-gray-400",
                    src: Image$ReactHooksTemplate.chevronDown
                  })), tmp$1);
  }
  var match$11 = Ticket$ReactHooksTemplate.sourceType(ticket);
  var tmp$2;
  if (match$11) {
    tmp$2 = React.createElement(React.Fragment, undefined);
  } else {
    var tmp$3;
    if (accordionState.campaignTab) {
      var channel = Ticket$ReactHooksTemplate.channel(ticket);
      var touchPoint = Ticket$ReactHooksTemplate.touchPoint(ticket);
      var lob = Ticket$ReactHooksTemplate.lob(ticket);
      var esc_level_2 = Ticket$ReactHooksTemplate.escalation_level_2(ticket);
      var esc_level_3 = Ticket$ReactHooksTemplate.escalation_level_3(ticket);
      var esc_level_4 = Ticket$ReactHooksTemplate.escalation_level_4(ticket);
      var esc_level_5 = Ticket$ReactHooksTemplate.escalation_level_5(ticket);
      tmp$3 = React.createElement("div", {
            className: "overflow-auto max-h-200-px"
          }, channel !== undefined ? React.createElement("div", {
                  className: "font-normal text-sm flex mt-4 text-left flex-wrap"
                }, React.createElement("div", {
                      className: "text-aurometalsaurus w-1/3 break-all"
                    }, "Channel"), React.createElement("div", {
                      className: "pl-2 w-2/3 break-all"
                    }, channel)) : "", touchPoint !== undefined ? React.createElement("div", {
                  className: "font-normal text-sm flex mt-4 text-left flex-wrap"
                }, React.createElement("div", {
                      className: "text-aurometalsaurus w-1/3 break-all"
                    }, "Touch Point"), React.createElement("div", {
                      className: "pl-2 w-2/3 break-all"
                    }, touchPoint)) : "", lob !== undefined ? React.createElement("div", {
                  className: "font-normal text-sm flex mt-4 text-left flex-wrap"
                }, React.createElement("div", {
                      className: "text-aurometalsaurus w-1/3 break-all"
                    }, "Lob"), React.createElement("div", {
                      className: "pl-2 w-2/3 break-all"
                    }, lob)) : "", esc_level_2 !== undefined ? React.createElement("div", {
                  className: "font-normal text-sm flex mt-4 text-left flex-wrap"
                }, React.createElement("div", {
                      className: "text-aurometalsaurus w-1/3 break-all"
                    }, "Escalation Level 2"), React.createElement("div", {
                      className: "pl-2 w-2/3 break-all"
                    }, esc_level_2)) : "", esc_level_3 !== undefined ? React.createElement("div", {
                  className: "font-normal text-sm flex mt-4 text-left flex-wrap"
                }, React.createElement("div", {
                      className: "text-aurometalsaurus w-1/3 break-all"
                    }, "Escalation Level 3"), React.createElement("div", {
                      className: "pl-2 w-2/3 break-all"
                    }, esc_level_3)) : "", esc_level_4 !== undefined ? React.createElement("div", {
                  className: "font-normal text-sm flex mt-4 text-left flex-wrap"
                }, React.createElement("div", {
                      className: "text-aurometalsaurus w-1/3 break-all"
                    }, "Escalation Level 4"), React.createElement("div", {
                      className: "pl-2 w-2/3 break-all"
                    }, esc_level_4)) : "", esc_level_5 !== undefined ? React.createElement("div", {
                  className: "font-normal text-sm flex mt-4 text-left flex-wrap"
                }, React.createElement("div", {
                      className: "text-aurometalsaurus w-1/3 break-all"
                    }, "Escalation Level 5"), React.createElement("div", {
                      className: "pl-2 w-2/3 break-all"
                    }, esc_level_5)) : "");
    } else {
      tmp$3 = React.createElement(React.Fragment, undefined);
    }
    tmp$2 = React.createElement("div", {
          className: "w-full mt-12"
        }, React.createElement("div", {
              className: "flex justify-between font-semibold text-xs",
              onClick: (function (param) {
                  return Curry._1(setAccordionState, (function (accordionState) {
                                return {
                                        feedbackTab: accordionState.feedbackTab,
                                        ticketTab: accordionState.ticketTab,
                                        campaignTab: !accordionState.campaignTab
                                      };
                              }));
                })
            }, "Campaign Summary", accordionState.campaignTab ? React.createElement("img", {
                    className: "h-7-px",
                    src: Image$ReactHooksTemplate.chevronUp
                  }) : React.createElement("img", {
                    className: "h-7-px filter-gray-400",
                    src: Image$ReactHooksTemplate.chevronDown
                  })), tmp$3);
  }
  var tmp$4;
  if (match$6[0]) {
    var tmp$5;
    switch (action) {
      case /* Open */0 :
          tmp$5 = React.createElement(React.Fragment, undefined, "Reopen Ticket");
          break;
      case /* Parked */1 :
          tmp$5 = React.createElement("div", undefined, "Park Ticket");
          break;
      case /* Closed */2 :
          tmp$5 = React.createElement("div", undefined, "Close Ticket");
          break;
      case /* Resolved */3 :
          tmp$5 = React.createElement("div", undefined, "Resolve Ticket");
          break;
      
    }
    var tmp$6;
    switch (action) {
      case /* Open */0 :
          tmp$6 = React.createElement(React.Fragment, undefined, "Do you want to reopen the ticket?");
          break;
      case /* Parked */1 :
          tmp$6 = React.createElement("div", {
                className: "flex"
              }, React.createElement("div", {
                    className: "w-1/4 mt-2 font-medium text-base"
                  }, "Reactivate on"), React.createElement("div", {
                    className: "w-3/4"
                  }, React.createElement("input", {
                        className: "block border border-gray-400 hover:border-palatinateblue focus:border-palatinateblue\n                          outline-none py-10-px px-20-px rounded text-black",
                        type: "date",
                        onChange: handleParkedTillChange
                      })));
          break;
      case /* Closed */2 :
          tmp$6 = React.createElement("div", {
                className: "flex"
              }, React.createElement("div", {
                    className: "w-1/4 mt-2 font-medium text-base"
                  }, "Comments"), React.createElement("div", {
                    className: "w-3/4"
                  }, React.createElement("textarea", {
                        className: "w-full block border border-gray-400 hover:border-palatinateblue focus:border-palatinateblue\n                          outline-none rounded p-2 text-black",
                        placeholder: "Type comments",
                        rows: 3,
                        value: comments,
                        onChange: handleCommentsChange
                      })));
          break;
      case /* Resolved */3 :
          tmp$6 = React.createElement("div", {
                className: "flex"
              }, React.createElement("div", {
                    className: "w-1/4 mt-2 font-medium text-base"
                  }, "RCA"), React.createElement("div", {
                    className: "w-3/4"
                  }, React.createElement("textarea", {
                        className: "w-full block border border-gray-400 hover:border-palatinateblue focus:border-palatinateblue\n                          outline-none rounded p-2 text-black",
                        placeholder: "Type subject",
                        rows: 3,
                        value: comments,
                        onChange: handleCommentsChange
                      })));
          break;
      
    }
    tmp$4 = React.createElement("div", {
          className: "w-screen z-10 disabled-wrapper absolute inset-0 flex justify-center items-center",
          style: {
            height: "calc(100vh + 5rem)"
          }
        }, React.createElement("div", {
              className: "bg-white modal-dialog z-10 shadow relative border border-gray-300 pointer-events-auto w-1/6"
            }, React.createElement("div", {
                  className: "p-8 text-lg font-bold flex justify-between"
                }, tmp$5, React.createElement("img", {
                      className: "h-14-px float-right cursor-pointer",
                      src: Image$ReactHooksTemplate.closeIcon,
                      onClick: (function (param) {
                          return Curry._1(setOpenStatusModal, (function (param) {
                                        return false;
                                      }));
                        })
                    })), React.createElement("div", {
                  className: "p-8 border-t border-gray-200 text-base font-medium"
                }, tmp$6), React.createElement("div", {
                  className: "p-8 flex border-t border-gray-200 justify-end gap-3 font-medium\n              absolute bottom-0 text-base w-full"
                }, React.createElement("button", {
                      className: "bg-ghostwhite border border-gray-400\n                flex rounded py-3 px-6 text-xs",
                      id: "close_modal",
                      onClick: (function (param) {
                          return Curry._1(setOpenStatusModal, (function (param) {
                                        return false;
                                      }));
                        })
                    }, "Discard"), React.createElement("button", {
                      className: "bg-palatinateblue border border-gray-400\n                flex rounded py-3 px-6 text-xs text-white",
                      id: "save",
                      onClick: handleSubmit
                    }, action !== 0 ? "Save" : "Reopen"))));
  } else {
    tmp$4 = React.createElement(React.Fragment, undefined);
  }
  return React.createElement("div", undefined, React.createElement("div", {
                  key: String(Ticket$ReactHooksTemplate.id(ticket)),
                  className: "flex overflow-visible",
                  style: {
                    height: "calc(100vh - 10.8rem)"
                  }
                }, React.createElement("div", {
                      className: "w-3/4 bg-white overflow-visible rounded-lg"
                    }, React.createElement("div", {
                          className: "flex flex-col items-stretch h-full"
                        }, React.createElement("div", {
                              className: "flex justify-between p-4"
                            }, React.createElement("div", {
                                  className: "text-2xl font-semibold"
                                }, "#", String(Ticket$ReactHooksTemplate.id(ticket))), React.createElement("div", {
                                  className: "flex text-xs text-aurometalsaurus font-medium items-center"
                                }, React.createElement("div", {
                                      className: "flex mr-4"
                                    }, React.createElement("img", {
                                          className: "h-14-px mr-1",
                                          src: Image$ReactHooksTemplate.clockCircle
                                        }), Ticket$ReactHooksTemplate.createdAt(ticket).format("lll")), React.createElement("div", {
                                      className: "flex justify-center items-center font-normal border border-gray-300 rounded-2xl\n                py-1 px-3 uppercase text-10-px " + ticketPriority(Ticket$ReactHooksTemplate.Priority.name(Ticket$ReactHooksTemplate.priority(ticket)))
                                    }, ticketPriorityString(Ticket$ReactHooksTemplate.Priority.name(Ticket$ReactHooksTemplate.priority(ticket)))))), React.createElement("div", {
                              className: "h-full"
                            }, React.createElement("ul", {
                                  className: "flex border-b my-2 text-aurometalsaurus font-normal text-sm px-4"
                                }, React.createElement("li", {
                                      className: "mr-1"
                                    }, Curry._1(hasPermission, /* ShowDistribution */20) ? React.createElement("div", undefined, React.createElement("div", {
                                                className: activeCss("activity") + " inline-block py-3 mr-10",
                                                href: "",
                                                onClick: (function (param) {
                                                    return Curry._1(setActiveHeader, (function (param) {
                                                                  return "activity";
                                                                }));
                                                  })
                                              }, "Activity"), React.createElement("div", undefined)) : React.createElement(React.Fragment, undefined)), React.createElement("li", {
                                      className: "mr-1"
                                    }, Curry._1(hasPermission, /* ShowReport */22) ? React.createElement("div", {
                                            className: activeCss("ticket_history") + " inline-block py-3 mr-10",
                                            href: "",
                                            onClick: (function (param) {
                                                return Curry._1(setActiveHeader, (function (param) {
                                                              return "ticket_history";
                                                            }));
                                              })
                                          }, "Ticket History") : React.createElement(React.Fragment, undefined)), React.createElement("li", {
                                      className: "mr-1"
                                    }, Curry._1(hasPermission, /* ShowReport */22) ? React.createElement("div", {
                                            className: activeCss("user_log") + " inline-block py-3 mr-10",
                                            href: "",
                                            onClick: (function (param) {
                                                return Curry._1(setActiveHeader, (function (param) {
                                                              return "user_log";
                                                            }));
                                              })
                                          }, "User Log") : React.createElement(React.Fragment, undefined))), displayData(undefined)), React.createElement("div", {
                              className: "flex justify-center p-4 pb-5 w-full gap-3"
                            }, React.createElement("button", {
                                  className: "bg-ghostwhite border border-gray-400\n                flex rounded py-3 px-3 text-xs disabled-input",
                                  id: "share",
                                  disabled: true
                                }, React.createElement("img", {
                                      className: "h-14-px mr-2-px",
                                      src: Image$ReactHooksTemplate.share
                                    }), "Share"), Curry._1(hasPermission, /* ManageTickets */2) ? React.createElement("button", {
                                    className: "bg-ghostwhite border border-gray-400\n                    flex rounded py-3 px-3 text-xs",
                                    id: "assign",
                                    disabled: false,
                                    onClick: (function (param) {
                                        return Curry._1(setOpenAssignModal, (function (param) {
                                                      return true;
                                                    }));
                                      })
                                  }, React.createElement("img", {
                                        className: "h-14-px mr-2-px",
                                        src: Image$ReactHooksTemplate.userPlus
                                      }), "Assign") : React.createElement("button", {
                                    className: "bg-ghostwhite border border-gray-400\n                    flex rounded py-3 px-3 text-xs disabled-input",
                                    id: "assign",
                                    disabled: true
                                  }, React.createElement("img", {
                                        className: "h-14-px mr-2-px",
                                        src: Image$ReactHooksTemplate.userPlus
                                      }), "Assign"), React.createElement("button", {
                                  className: "bg-ghostwhite border border-gray-400\n                flex rounded py-3 px-3 text-xs disabled-input",
                                  id: "schedule",
                                  disabled: true
                                }, React.createElement("img", {
                                      className: "h-14-px mr-2-px",
                                      src: Image$ReactHooksTemplate.calendar
                                    }), "Schedule"), React.createElement("div", {
                                  className: "flex items-start bg-ghostwhite border border-gray-400\n                flex rounded py-3 px-3 text-xs"
                                }, React.createElement("div", {
                                      className: "mr-1 text-aurometalsaurus"
                                    }, "Status :"), React.createElement("select", {
                                      className: "bg-ghostwhite font-medium",
                                      value: Ticket$ReactHooksTemplate.Status.toString(Ticket$ReactHooksTemplate.status(ticket)),
                                      onChange: handleStatusChange
                                    }, React.createElement("option", {
                                          key: Ticket$ReactHooksTemplate.Status.toString(Ticket$ReactHooksTemplate.status(ticket)),
                                          value: Ticket$ReactHooksTemplate.Status.toString(Ticket$ReactHooksTemplate.status(ticket))
                                        }, Ticket$ReactHooksTemplate.Status.toString(Ticket$ReactHooksTemplate.status(ticket))), $$Array.of_list(List.map((function (status) {
                                                return React.createElement("option", {
                                                            key: Ticket$ReactHooksTemplate.Status.toString(status),
                                                            value: Ticket$ReactHooksTemplate.Status.toString(status)
                                                          }, Ticket$ReactHooksTemplate.Status.toString(status));
                                              }), Ticket$ReactHooksTemplate.Status.next(Ticket$ReactHooksTemplate.status(ticket)))))), React.createElement("div", {
                                  className: "flex items-start bg-ghostwhite border border-gray-400\n                flex rounded py-3 px-3 text-xs disabled-input"
                                }, React.createElement("div", {
                                      className: "mr-1 text-aurometalsaurus"
                                    }, "Priority :"), React.createElement("select", {
                                      className: "bg-ghostwhite font-medium",
                                      disabled: true
                                    }, React.createElement("option", {
                                          key: Ticket$ReactHooksTemplate.Priority.name(Ticket$ReactHooksTemplate.priority(ticket))
                                        }, Ticket$ReactHooksTemplate.Priority.name(Ticket$ReactHooksTemplate.priority(ticket)))))), React.createElement("div", {
                              className: "w-full flex self-end relative"
                            }, React.createElement("input", {
                                  className: "w-full h-16 bg-brightgray activity-comments p-4",
                                  placeholder: "Type Message",
                                  type: "text",
                                  value: ticketComment,
                                  onChange: handleTicketCommentChanges
                                }), React.createElement("img", {
                                  className: "mr-10-px mb-10-px absolute right-0 bottom-0",
                                  src: Image$ReactHooksTemplate.submit,
                                  onClick: handleCommentSubmit
                                })))), React.createElement("div", {
                      className: "w-1/4 bg-ghostwhitegray capitalize border-l border-gray-300 p-3 rounded-r-lg"
                    }, React.createElement("div", {
                          className: "w-full"
                        }, React.createElement("div", {
                              className: "p-4 bg-white rounded-lg border border-gray-300 flex"
                            }, React.createElement("div", {
                                  className: "mr-2"
                                }, React.createElement("img", {
                                      className: "h-72-px",
                                      src: Image$ReactHooksTemplate.avatar
                                    })), React.createElement("div", undefined, React.createElement("div", {
                                      className: "text-sm font-semibold mb-3"
                                    }, Ticket$ReactHooksTemplate.name(ticket)), React.createElement("div", {
                                      className: "flex font-normal text-xs mb-3 text-aurometalsaurus"
                                    }, React.createElement("img", {
                                          className: "h-3 mr-1 mt-2-px break-all",
                                          src: Image$ReactHooksTemplate.phone
                                        }), Curry._1(Domain$ReactHooksTemplate.$$String.Msisdn.show, Ticket$ReactHooksTemplate.msisdn(ticket))), React.createElement("div", {
                                      className: "flex font-normal text-xs text-aurometalsaurus"
                                    }, React.createElement("img", {
                                          className: "h-3 mr-1 mt-2-px break-all",
                                          src: Image$ReactHooksTemplate.email
                                        }), Curry._1(Domain$ReactHooksTemplate.$$String.Email.show, Ticket$ReactHooksTemplate.email(ticket)))))), tmp, React.createElement("div", {
                          className: "w-full mt-12"
                        }, React.createElement("div", {
                              className: "flex justify-between font-semibold text-xs",
                              onClick: (function (param) {
                                  return Curry._1(setAccordionState, (function (accordionState) {
                                                return {
                                                        feedbackTab: accordionState.feedbackTab,
                                                        ticketTab: !accordionState.ticketTab,
                                                        campaignTab: accordionState.campaignTab
                                                      };
                                              }));
                                })
                            }, "Ticket Details", accordionState.ticketTab ? React.createElement("img", {
                                    className: "h-7-px",
                                    src: Image$ReactHooksTemplate.chevronUp
                                  }) : React.createElement("img", {
                                    className: "h-7-px filter-gray-400",
                                    src: Image$ReactHooksTemplate.chevronDown
                                  })), accordionState.ticketTab ? React.createElement("div", {
                                className: "overflow-auto max-h-200-px"
                              }, React.createElement("div", {
                                    className: "font-normal text-sm flex mt-4 text-left flex-wrap"
                                  }, React.createElement("div", {
                                        className: "text-aurometalsaurus w-1/3 break-all"
                                      }, "Priority"), React.createElement("div", {
                                        className: "pl-2 w-2/3 break-all"
                                      }, Ticket$ReactHooksTemplate.Priority.name(Ticket$ReactHooksTemplate.priority(ticket)))), React.createElement("div", {
                                    className: "font-normal text-sm flex mt-4 text-left"
                                  }, React.createElement("div", {
                                        className: "text-aurometalsaurus w-1/3 break-all"
                                      }, "Status"), React.createElement("div", {
                                        className: "pl-2 w-2/3 break-all"
                                      }, Ticket$ReactHooksTemplate.Status.toString(Ticket$ReactHooksTemplate.status(ticket))))) : React.createElement(React.Fragment, undefined)), tmp$2), tmp$4, match$7[0] ? React.createElement("div", {
                        className: "w-screen z-10 disabled-wrapper absolute inset-0 flex justify-center items-center",
                        style: {
                          height: "calc(100vh + 5rem)"
                        }
                      }, React.createElement("div", {
                            className: "bg-white modal-dialog z-10 shadow relative border border-gray-300 pointer-events-auto w-1/6"
                          }, React.createElement("div", {
                                className: "p-8 text-lg font-bold flex justify-between"
                              }, React.createElement("div", undefined, "Assign Ticket"), React.createElement("img", {
                                    className: "h-14-px float-right cursor-pointer",
                                    src: Image$ReactHooksTemplate.closeIcon,
                                    onClick: (function (param) {
                                        return Curry._1(setOpenAssignModal, (function (param) {
                                                      return false;
                                                    }));
                                      })
                                  })), React.createElement("div", {
                                className: "p-8 border-t border-gray-200 text-base font-medium"
                              }, React.createElement("div", {
                                    className: "flex items-center px-30-px justify-center gap-4"
                                  }, React.createElement("div", {
                                        className: "mt-2 font-medium text-base"
                                      }, "Select Agent"), React.createElement("div", {
                                        className: "mt-2 font-medium text-base"
                                      }, React.createElement("select", {
                                            className: "block border px-3 py-2 border-gray-400\n                        w-52 bg-white rounded capitalize mr-6 text-sm",
                                            onChange: handleAssigneeSelection
                                          }, $$Array.of_list(List.map((function (assignee) {
                                                      return React.createElement("option", {
                                                                  key: String(Ticket$ReactHooksTemplate.Assignee.id(assignee)),
                                                                  value: emailText(assignee)
                                                                }, emailText(assignee));
                                                    }), {
                                                    hd: Ticket$ReactHooksTemplate.Assignee.empty,
                                                    tl: agents
                                                  })))))), React.createElement("div", {
                                className: "p-8 flex border-t border-gray-200 justify-end gap-3 font-medium\n                absolute bottom-0 text-base w-full"
                              }, React.createElement("button", {
                                    className: "bg-ghostwhite border border-gray-400\n                  flex rounded py-3 px-6 text-xs",
                                    id: "close_modal",
                                    onClick: (function (param) {
                                        return Curry._1(setOpenAssignModal, (function (param) {
                                                      return false;
                                                    }));
                                      })
                                  }, "Discard"), React.createElement("button", {
                                    className: "bg-palatinateblue border border-gray-400\n                  flex rounded py-3 px-6 text-xs text-white",
                                    id: "save",
                                    onClick: (function (param) {
                                        Curry._1(assignTicket, Ticket$ReactHooksTemplate.id(ticket));
                                        return Curry._1(setOpenAssignModal, (function (param) {
                                                      return false;
                                                    }));
                                      })
                                  }, "Assign Ticket")))) : React.createElement(React.Fragment, undefined)));
}

var make = TicketView;

exports.updateTicket = updateTicket;
exports.isValidComment = isValidComment;
exports.emptyState = emptyState;
exports.initialAccordionState = initialAccordionState;
exports.page = page;
exports.make = make;
/* react Not a pure module */

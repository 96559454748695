// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Moment = require("moment");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");

function toString(t) {
  switch (t) {
    case /* Queued */0 :
        return "queued";
    case /* InProgress */1 :
        return "in_progress";
    case /* Done */2 :
        return "done";
    
  }
}

function fromString(x) {
  var match = x.toLowerCase();
  switch (match) {
    case "done" :
        return /* Done */2;
    case "in_progress" :
        return /* InProgress */1;
    case "queued" :
        return /* Queued */0;
    default:
      return /* Queued */0;
  }
}

function decode(json) {
  return fromString(Json_decode.string(json));
}

var Status = {
  toString: toString,
  fromString: fromString,
  decode: decode
};

function id(t) {
  return t.id;
}

function name(t) {
  return t.name;
}

function status(t) {
  return t.status;
}

function createdAt(t) {
  return t.createdAt;
}

function decode$1(json) {
  return {
          id: Json_decode.field("id", Json_decode.$$int, json),
          name: Json_decode.field("name", Json_decode.string, json),
          status: Json_decode.field("status", decode, json),
          createdAt: Moment(new Date(Json_decode.field("created_at", Json_decode.string, json)))
        };
}

exports.Status = Status;
exports.id = id;
exports.name = name;
exports.status = status;
exports.createdAt = createdAt;
exports.decode = decode$1;
/* moment Not a pure module */

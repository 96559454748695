// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var List = require("rescript/lib/js/list.js");
var $$Array = require("rescript/lib/js/array.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var $$String = require("rescript/lib/js/string.js");
var Js_dict = require("rescript/lib/js/js_dict.js");
var Belt_Int = require("rescript/lib/js/belt_Int.js");
var Caml_int32 = require("rescript/lib/js/caml_int32.js");
var Caml_format = require("rescript/lib/js/caml_format.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Querystringify = require("querystringify");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var Http$ReactHooksTemplate = require("../common/http.bs.js");
var Filters$ReactHooksTemplate = require("../dashboard/filters/filters.bs.js");
var Loading$ReactHooksTemplate = require("../dashboard/dashboard/Loading.bs.js");
var Pagination$ReactHooksTemplate = require("../common/Pagination.bs.js");

function emptyState(param) {
  return {
          header: /* [] */0,
          contents: /* [] */0
        };
}

function noMessage(param) {
  return "";
}

function decodeDownloadReport(json) {
  return Json_decode.field("file_name", Json_decode.string, json);
}

function decodeReportDetails(header, json) {
  return List.map((function (name) {
                return Json_decode.optional((function (param) {
                              return Json_decode.field(name, Json_decode.string, param);
                            }), json);
              }), header);
}

function decode(json) {
  var header = Json_decode.field("header", (function (param) {
          return Json_decode.list(Json_decode.string, param);
        }), json);
  return {
          header: header,
          contents: Json_decode.field("report", (function (param) {
                  return Json_decode.list((function (param) {
                                return decodeReportDetails(header, param);
                              }), param);
                }), json)
        };
}

var Report = {
  emptyState: emptyState,
  noMessage: noMessage,
  decodeDownloadReport: decodeDownloadReport,
  decodeReportDetails: decodeReportDetails,
  decode: decode
};

var emptyReports = {
  header: /* [] */0,
  contents: /* [] */0
};

var initialState = {
  loading: true,
  reports: emptyReports,
  toggleFilter: false
};

function reducer(state, x) {
  if (typeof x === "number") {
    if (x !== 0) {
      return {
              loading: state.loading,
              reports: state.reports,
              toggleFilter: !state.toggleFilter
            };
    } else {
      return {
              loading: true,
              reports: state.reports,
              toggleFilter: state.toggleFilter
            };
    }
  } else {
    return {
            loading: false,
            reports: x._0,
            toggleFilter: state.toggleFilter
          };
  }
}

function totalRecord(x) {
  if (x !== undefined) {
    return x;
  } else {
    return "1";
  }
}

function perPage(x) {
  if (x !== undefined) {
    return x;
  } else {
    return "1";
  }
}

function totalPages(total_records, perPage) {
  return Caml_int32.div(total_records - 1 | 0, perPage) + 1 | 0;
}

var emptyPageInfo = {
  total: "1",
  perPage: "1"
};

function QuickSurveyReport(Props) {
  var search = Props.search;
  var match = React.useReducer(reducer, initialState);
  var dispatch = match[1];
  var state = match[0];
  var match$1 = React.useState(function () {
        return emptyPageInfo;
      });
  var setPageInfo = match$1[1];
  var pageInfo = match$1[0];
  var queryParams = Querystringify.parse(search);
  var x = Js_dict.get(queryParams, "page");
  var currentPage;
  if (x !== undefined && x !== "") {
    var page = Belt_Int.fromString(x);
    currentPage = page !== undefined ? page : 1;
  } else {
    currentPage = 1;
  }
  var url = "/api/v1/quick_survey_report?page=" + String(currentPage);
  var handlePageChange = function (pageNumber) {
    return RescriptReactRouter.push("/quick_survey/report/?page=" + String(pageNumber));
  };
  var fetchReport = function (param) {
    return Http$ReactHooksTemplate.fetchGetJSONWithHeader(url).then(function (param) {
                var headers = param[0];
                var x = headers.get("Total");
                var total = x !== null ? (
                    x === null ? undefined : Caml_option.some(x)
                  ) : "1";
                var x$1 = headers.get("Per-Page");
                var perPage = x$1 !== null ? (
                    x$1 === null ? undefined : Caml_option.some(x$1)
                  ) : "1";
                var report = param[1].then(function (json) {
                      return Promise.resolve(Json_decode.field("report", decode, json));
                    });
                return Promise.resolve([
                            report,
                            [
                              total,
                              perPage
                            ]
                          ]);
              });
  };
  var onApplyFilter = function (param) {
    Curry._1(dispatch, /* ToggleFilter */1);
    Curry._1(dispatch, /* FetchReportsInProgress */0);
    fetchReport(undefined).then(function (param) {
          var pageInfo = param[1];
          var perPage = pageInfo[1];
          var total = pageInfo[0];
          Curry._1(setPageInfo, (function (param) {
                  return {
                          total: total,
                          perPage: perPage
                        };
                }));
          param[0].then(function (report) {
                return Promise.resolve(Curry._1(dispatch, /* FetchReportsCompleted */{
                                _0: report
                              }));
              });
          return Promise.resolve(undefined);
        });
    
  };
  var toggleFilter = function (param) {
    return Curry._1(dispatch, /* ToggleFilter */1);
  };
  React.useEffect((function () {
          Curry._1(dispatch, /* FetchReportsInProgress */0);
          fetchReport(undefined).then(function (param) {
                var pageInfo = param[1];
                var perPage = pageInfo[1];
                var total = pageInfo[0];
                Curry._1(setPageInfo, (function (param) {
                        return {
                                total: total,
                                perPage: perPage
                              };
                      }));
                param[0].then(function (report) {
                      return Promise.resolve(Curry._1(dispatch, /* FetchReportsCompleted */{
                                      _0: report
                                    }));
                    });
                return Promise.resolve(undefined);
              });
          
        }), [currentPage]);
  return React.createElement(React.Fragment, undefined, state.toggleFilter ? React.createElement(Filters$ReactHooksTemplate.make, {
                    onClose: toggleFilter,
                    onApply: onApplyFilter,
                    showDateFilter: false,
                    showFilterPills: false
                  }) : React.createElement(React.Fragment, undefined), state.loading ? React.createElement("div", {
                    className: "h-screen w-full animated fadeIn"
                  }, React.createElement(Loading$ReactHooksTemplate.make, {
                        text: "Loading Survey..."
                      })) : React.createElement(React.Fragment, undefined, React.createElement("div", {
                        className: "p-20-px"
                      }, React.createElement("div", {
                            className: "flex justify-between items-center mb-30-px "
                          }, React.createElement("h2", {
                                className: "mb-32-px text-2xl"
                              }, "Campaign Report")), React.createElement("table", {
                            className: "w-full mb-10-px "
                          }, React.createElement("thead", {
                                className: "border border-gray-400 bg-ghostwhite text-aurometalsaurus rounded-t-lg"
                              }, React.createElement("tr", undefined, $$Array.of_list(List.mapi((function (i, header) {
                                              return React.createElement("th", {
                                                          key: String(i),
                                                          className: "text-left font-thin py-10-px pl-4"
                                                        }, $$String.capitalize_ascii(header));
                                            }), state.reports.header)))), React.createElement("tbody", {
                                className: "border border-gray-400"
                              }, $$Array.of_list(List.mapi((function (i, x) {
                                          return React.createElement("tr", {
                                                      key: String(i)
                                                    }, $$Array.of_list(List.mapi((function (k, step) {
                                                                if (step !== undefined) {
                                                                  return React.createElement("td", {
                                                                              key: String(k),
                                                                              className: "py-10-px pl-4"
                                                                            }, step);
                                                                } else {
                                                                  return React.createElement("td", {
                                                                              key: String(k),
                                                                              className: "py-10-px pl-4"
                                                                            }, "");
                                                                }
                                                              }), x)));
                                        }), state.reports.contents)))), React.createElement("div", {
                            className: "flex justify-end"
                          }, React.createElement(Pagination$ReactHooksTemplate.make, {
                                total: totalPages(Caml_format.caml_int_of_string(pageInfo.total), Caml_format.caml_int_of_string(pageInfo.perPage)),
                                currentPage: currentPage,
                                handlePageChange: handlePageChange
                              })))));
}

var noMessage$1 = "";

var make = QuickSurveyReport;

exports.Report = Report;
exports.emptyReports = emptyReports;
exports.initialState = initialState;
exports.reducer = reducer;
exports.totalRecord = totalRecord;
exports.perPage = perPage;
exports.totalPages = totalPages;
exports.emptyPageInfo = emptyPageInfo;
exports.noMessage = noMessage$1;
exports.make = make;
/* react Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var TicketResolve$ReactHooksTemplate = require("./ticketResolve.bs.js");

function TicketResolveView(Props) {
  var activity = Props.activity;
  return React.createElement("div", undefined, React.createElement("div", {
                  className: "flex justify-between text-darkcharcoal"
                }, React.createElement("div", {
                      className: "text-sm font-semibold"
                    }, "Ticket Resolved"), React.createElement("div", {
                      className: "text-xs font-normal"
                    }, TicketResolve$ReactHooksTemplate.user_email(activity))), React.createElement("div", {
                  className: "flex text-xs font-normal mt-2"
                }, "RCA: ", TicketResolve$ReactHooksTemplate.rca(activity)), React.createElement("div", {
                  className: "mt-2 text-xs font-normal"
                }, TicketResolve$ReactHooksTemplate.comment(activity)));
}

var make = TicketResolveView;

exports.make = make;
/* react Not a pure module */

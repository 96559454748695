// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");
var SmsMode$ReactHooksTemplate = require("./SmsMode.bs.js");
var EmailMode$ReactHooksTemplate = require("./EmailMode.bs.js");
var AppLinkMode$ReactHooksTemplate = require("./AppLinkMode.bs.js");
var SmsLinkMode$ReactHooksTemplate = require("./SmsLinkMode.bs.js");
var WebsiteLinkMode$ReactHooksTemplate = require("./WebsiteLinkMode.bs.js");
var WhatsappLinkMode$ReactHooksTemplate = require("./WhatsappLinkMode.bs.js");

function fromForm(form) {
  var emailForm = form.formMode;
  var mode;
  if (typeof emailForm === "number") {
    mode = emailForm === /* AnonymousLink */0 ? /* AnonymousLink */0 : /* Telephonic */1;
  } else {
    switch (emailForm.TAG | 0) {
      case /* Email */0 :
          mode = {
            TAG: /* Email */0,
            _0: EmailMode$ReactHooksTemplate.fromForm(emailForm._0)
          };
          break;
      case /* Sms */1 :
          mode = {
            TAG: /* Sms */1,
            _0: SmsMode$ReactHooksTemplate.fromForm(emailForm._0)
          };
          break;
      case /* SmsLink */2 :
          mode = {
            TAG: /* SmsLink */2,
            _0: SmsLinkMode$ReactHooksTemplate.fromForm(emailForm._0)
          };
          break;
      case /* WhatsappLink */3 :
          mode = {
            TAG: /* WhatsappLink */3,
            _0: WhatsappLinkMode$ReactHooksTemplate.fromForm(emailForm._0)
          };
          break;
      case /* AppLink */4 :
          mode = {
            TAG: /* AppLink */4,
            _0: AppLinkMode$ReactHooksTemplate.fromForm(emailForm._0)
          };
          break;
      case /* WebsiteLink */5 :
          mode = {
            TAG: /* WebsiteLink */5,
            _0: WebsiteLinkMode$ReactHooksTemplate.fromForm(emailForm._0)
          };
          break;
      
    }
  }
  return {
          id: form.id,
          mode: mode
        };
}

function toForm(t) {
  var emailForm = t.mode;
  var formMode;
  if (typeof emailForm === "number") {
    formMode = emailForm === /* AnonymousLink */0 ? /* AnonymousLink */0 : /* Telephonic */1;
  } else {
    switch (emailForm.TAG | 0) {
      case /* Email */0 :
          formMode = {
            TAG: /* Email */0,
            _0: EmailMode$ReactHooksTemplate.toForm(emailForm._0)
          };
          break;
      case /* Sms */1 :
          formMode = {
            TAG: /* Sms */1,
            _0: SmsMode$ReactHooksTemplate.toForm(emailForm._0)
          };
          break;
      case /* SmsLink */2 :
          formMode = {
            TAG: /* SmsLink */2,
            _0: SmsLinkMode$ReactHooksTemplate.toForm(emailForm._0)
          };
          break;
      case /* WhatsappLink */3 :
          formMode = {
            TAG: /* WhatsappLink */3,
            _0: WhatsappLinkMode$ReactHooksTemplate.toForm(emailForm._0)
          };
          break;
      case /* AppLink */4 :
          formMode = {
            TAG: /* AppLink */4,
            _0: AppLinkMode$ReactHooksTemplate.toForm(emailForm._0)
          };
          break;
      case /* WebsiteLink */5 :
          formMode = {
            TAG: /* WebsiteLink */5,
            _0: WebsiteLinkMode$ReactHooksTemplate.toForm(emailForm._0)
          };
          break;
      
    }
  }
  return {
          id: t.id,
          formMode: formMode
        };
}

function modeType(t) {
  var match = t.mode;
  if (typeof match === "number") {
    if (match === /* AnonymousLink */0) {
      return "AnonymousLink";
    } else {
      return "Telephonic";
    }
  }
  switch (match.TAG | 0) {
    case /* Email */0 :
        return EmailMode$ReactHooksTemplate.modeType;
    case /* Sms */1 :
        return SmsMode$ReactHooksTemplate.modeType;
    case /* SmsLink */2 :
        return SmsLinkMode$ReactHooksTemplate.modeType;
    case /* WhatsappLink */3 :
        return WhatsappLinkMode$ReactHooksTemplate.modeType;
    case /* AppLink */4 :
        return AppLinkMode$ReactHooksTemplate.modeType;
    case /* WebsiteLink */5 :
        return WebsiteLinkMode$ReactHooksTemplate.modeType;
    
  }
}

function encoder(t) {
  var email = t.mode;
  var tmp;
  if (typeof email === "number") {
    tmp = email === /* AnonymousLink */0 ? ({
          hd: [
            "id",
            Json_encode.nullable((function (prim) {
                    return prim;
                  }), t.id)
          ],
          tl: {
            hd: [
              "mode_type",
              "AnonymousLink"
            ],
            tl: {
              hd: [
                "mode_attributes",
                Json_encode.object_(/* [] */0)
              ],
              tl: /* [] */0
            }
          }
        }) : ({
          hd: [
            "id",
            Json_encode.nullable((function (prim) {
                    return prim;
                  }), t.id)
          ],
          tl: {
            hd: [
              "mode_type",
              "Telephonic"
            ],
            tl: {
              hd: [
                "mode_attributes",
                Json_encode.object_(/* [] */0)
              ],
              tl: /* [] */0
            }
          }
        });
  } else {
    switch (email.TAG | 0) {
      case /* Email */0 :
          tmp = {
            hd: [
              "id",
              Json_encode.nullable((function (prim) {
                      return prim;
                    }), t.id)
            ],
            tl: {
              hd: [
                "mode_type",
                EmailMode$ReactHooksTemplate.modeType
              ],
              tl: {
                hd: [
                  "mode_attributes",
                  EmailMode$ReactHooksTemplate.encoder(email._0)
                ],
                tl: /* [] */0
              }
            }
          };
          break;
      case /* Sms */1 :
          tmp = {
            hd: [
              "id",
              Json_encode.nullable((function (prim) {
                      return prim;
                    }), t.id)
            ],
            tl: {
              hd: [
                "mode_type",
                "Sms"
              ],
              tl: {
                hd: [
                  "mode_attributes",
                  SmsMode$ReactHooksTemplate.encoder(email._0)
                ],
                tl: /* [] */0
              }
            }
          };
          break;
      case /* SmsLink */2 :
          tmp = {
            hd: [
              "id",
              Json_encode.nullable((function (prim) {
                      return prim;
                    }), t.id)
            ],
            tl: {
              hd: [
                "mode_type",
                SmsLinkMode$ReactHooksTemplate.modeType
              ],
              tl: {
                hd: [
                  "mode_attributes",
                  SmsLinkMode$ReactHooksTemplate.encoder(email._0)
                ],
                tl: /* [] */0
              }
            }
          };
          break;
      case /* WhatsappLink */3 :
          tmp = {
            hd: [
              "id",
              Json_encode.nullable((function (prim) {
                      return prim;
                    }), t.id)
            ],
            tl: {
              hd: [
                "mode_type",
                WhatsappLinkMode$ReactHooksTemplate.modeType
              ],
              tl: {
                hd: [
                  "mode_attributes",
                  WhatsappLinkMode$ReactHooksTemplate.encoder(email._0)
                ],
                tl: /* [] */0
              }
            }
          };
          break;
      case /* AppLink */4 :
          tmp = {
            hd: [
              "id",
              Json_encode.nullable((function (prim) {
                      return prim;
                    }), t.id)
            ],
            tl: {
              hd: [
                "mode_type",
                AppLinkMode$ReactHooksTemplate.modeType
              ],
              tl: {
                hd: [
                  "mode_attributes",
                  AppLinkMode$ReactHooksTemplate.encoder(email._0)
                ],
                tl: /* [] */0
              }
            }
          };
          break;
      case /* WebsiteLink */5 :
          tmp = {
            hd: [
              "id",
              Json_encode.nullable((function (prim) {
                      return prim;
                    }), t.id)
            ],
            tl: {
              hd: [
                "mode_type",
                WebsiteLinkMode$ReactHooksTemplate.modeType
              ],
              tl: {
                hd: [
                  "mode_attributes",
                  WebsiteLinkMode$ReactHooksTemplate.encoder(email._0)
                ],
                tl: /* [] */0
              }
            }
          };
          break;
      
    }
  }
  return Json_encode.object_(tmp);
}

function decoder(json) {
  var modeType = Json_decode.field("mode_type", Json_decode.string, json);
  if (modeType === EmailMode$ReactHooksTemplate.modeType) {
    return {
            id: Json_decode.field("id", (function (param) {
                    return Json_decode.optional(Json_decode.$$int, param);
                  }), json),
            mode: {
              TAG: /* Email */0,
              _0: Json_decode.field("mode", EmailMode$ReactHooksTemplate.decoder, json)
            }
          };
  } else if (modeType === SmsLinkMode$ReactHooksTemplate.modeType) {
    return {
            id: Json_decode.field("id", (function (param) {
                    return Json_decode.optional(Json_decode.$$int, param);
                  }), json),
            mode: {
              TAG: /* SmsLink */2,
              _0: Json_decode.field("mode", SmsLinkMode$ReactHooksTemplate.decoder, json)
            }
          };
  } else if (modeType === WhatsappLinkMode$ReactHooksTemplate.modeType) {
    return {
            id: Json_decode.field("id", (function (param) {
                    return Json_decode.optional(Json_decode.$$int, param);
                  }), json),
            mode: {
              TAG: /* WhatsappLink */3,
              _0: Json_decode.field("mode", WhatsappLinkMode$ReactHooksTemplate.decoder, json)
            }
          };
  } else if (modeType === "AnonymousLink") {
    return {
            id: Json_decode.field("id", (function (param) {
                    return Json_decode.optional(Json_decode.$$int, param);
                  }), json),
            mode: /* AnonymousLink */0
          };
  } else if (modeType === "Sms") {
    return {
            id: Json_decode.field("id", (function (param) {
                    return Json_decode.optional(Json_decode.$$int, param);
                  }), json),
            mode: {
              TAG: /* Sms */1,
              _0: Json_decode.field("mode", SmsMode$ReactHooksTemplate.decoder, json)
            }
          };
  } else if (modeType === "Telephonic") {
    return {
            id: Json_decode.field("id", (function (param) {
                    return Json_decode.optional(Json_decode.$$int, param);
                  }), json),
            mode: /* Telephonic */1
          };
  } else if (modeType === "WebsiteLink") {
    return {
            id: Json_decode.field("id", (function (param) {
                    return Json_decode.optional(Json_decode.$$int, param);
                  }), json),
            mode: {
              TAG: /* WebsiteLink */5,
              _0: Json_decode.field("mode", WebsiteLinkMode$ReactHooksTemplate.decoder, json)
            }
          };
  } else {
    return {
            id: Json_decode.field("id", (function (param) {
                    return Json_decode.optional(Json_decode.$$int, param);
                  }), json),
            mode: {
              TAG: /* AppLink */4,
              _0: Json_decode.field("mode", AppLinkMode$ReactHooksTemplate.decoder, json)
            }
          };
  }
}

exports.fromForm = fromForm;
exports.toForm = toForm;
exports.modeType = modeType;
exports.encoder = encoder;
exports.decoder = decoder;
/* No side effect */

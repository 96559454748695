// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var $$Array = require("rescript/lib/js/array.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Campaign$ReactHooksTemplate = require("../surveys/campaign.bs.js");
var SurveyDetail$ReactHooksTemplate = require("../surveys/surveyDetail.bs.js");
var ResponseReport$ReactHooksTemplate = require("./ResponseReport.bs.js");
var MultiSelectDropDown$ReactHooksTemplate = require("../common/MultiSelectDropDown.bs.js");

function ResponseReportForm(Props) {
  var template = Props.template;
  var surveys = Props.surveys;
  var campaigns = Props.campaigns;
  var handleResponseReportSurveyChange = Props.handleResponseReportSurveyChange;
  var handleResponseReportCampaignChange = Props.handleResponseReportCampaignChange;
  var survey = ResponseReport$ReactHooksTemplate.survey(template);
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  className: "flex mb-20-px items-center"
                }, React.createElement("div", {
                      className: "flex gap-1 items-center w-full"
                    }, React.createElement("div", {
                          className: "text-sm font-bold"
                        }, "Response Report Fields"))), React.createElement("div", {
                  className: "flex mb-30-px items-center"
                }, React.createElement("div", {
                      className: "flex gap-1 items-center w-full"
                    }, React.createElement("div", {
                          className: "text-sm font-medium w-1/5"
                        }, "Survey"), React.createElement("select", {
                          className: "block border px-3 py-2 bordergray-400\n                      w-3/5 bg-white rounded capitalize mr-6 text-sm",
                          placeholder: "Select Survey",
                          value: survey !== undefined ? SurveyDetail$ReactHooksTemplate.name(survey) : "Select Survey",
                          onChange: Curry._1(handleResponseReportSurveyChange, template)
                        }, React.createElement("option", {
                              key: "0",
                              disabled: true,
                              value: "Select Survey"
                            }, "Select Survey"), $$Array.of_list(Belt_List.map(surveys, (function (survey) {
                                    return React.createElement("option", {
                                                key: String(SurveyDetail$ReactHooksTemplate.id(survey)),
                                                value: SurveyDetail$ReactHooksTemplate.name(survey)
                                              }, SurveyDetail$ReactHooksTemplate.name(survey));
                                  })))))), React.createElement("div", {
                  className: "flex mb-30-px items-center"
                }, React.createElement("div", {
                      className: "flex gap-1 items-start w-full"
                    }, React.createElement("div", {
                          className: "text-sm font-medium w-1/5 mt-2"
                        }, "Campaigns"), React.createElement(MultiSelectDropDown$ReactHooksTemplate.make, {
                          defaultPlaceholder: "Select Campaigns",
                          options: Belt_List.map(campaigns, Campaign$ReactHooksTemplate.name),
                          selectedOptions: Belt_List.map(ResponseReport$ReactHooksTemplate.campaigns(template), Campaign$ReactHooksTemplate.name),
                          handleChange: Curry._1(handleResponseReportCampaignChange, template)
                        }))));
}

var make = ResponseReportForm;

exports.make = make;
/* react Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var $$Array = require("rescript/lib/js/array.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");
var Initialize = require("react-dates/initialize").default;
var Http$ReactHooksTemplate = require("../../common/http.bs.js");
var Image$ReactHooksTemplate = require("../../common/image.bs.js");
var Utils$ReactHooksTemplate = require("../../common/utils.bs.js");
var Filter$ReactHooksTemplate = require("./filter.bs.js");
var DateFilterForm$ReactHooksTemplate = require("./DateFilterForm.bs.js");
var ListFilterForm$ReactHooksTemplate = require("./ListFilterForm.bs.js");
var TextFilterForm$ReactHooksTemplate = require("./TextFilterForm.bs.js");
var EmailFilterForm$ReactHooksTemplate = require("./EmailFilterForm.bs.js");
var MsisdnFilterForm$ReactHooksTemplate = require("./MsisdnFilterForm.bs.js");
var NumberFilterForm$ReactHooksTemplate = require("./NumberFilterForm.bs.js");
var FilterPillsElement$ReactHooksTemplate = require("./FilterPillsElement.bs.js");

function InsightsFilterForm(Props) {
  var filters = Props.filters;
  var filterPills = Props.filterPills;
  var onClose = Props.onClose;
  var onApply = Props.onApply;
  var handleSetFilter = Props.handleSetFilter;
  var handleResetFilter = Props.handleResetFilter;
  var currentFilter = Props.currentFilter;
  var correlation = Props.correlation;
  var match = React.useState(function () {
        return {
                applying: false,
                filterId: currentFilter
              };
      });
  var setState = match[1];
  var state = match[0];
  var onApplyBtnPress = function (param) {
    Http$ReactHooksTemplate.fetchPostEncodedJSON("/api/v1/insights/set_filters", Json_encode.object_({
                hd: [
                  "filters",
                  Json_encode.list(Filter$ReactHooksTemplate.encode, filters)
                ],
                tl: /* [] */0
              })).then(function (param) {
          return Promise.resolve((Curry._1(onApply, undefined), Curry._1(onClose, undefined)));
        });
    
  };
  var displayFilters = function (xs) {
    var filter = Filter$ReactHooksTemplate.filterType(xs);
    var tmp;
    switch (filter.TAG | 0) {
      case /* Number */0 :
          tmp = React.createElement(NumberFilterForm$ReactHooksTemplate.make, {
                filter: filter._0,
                onChange: (function (param) {
                    return Curry._1(handleSetFilter, Filter$ReactHooksTemplate.setFilterTypes(param, xs));
                  })
              });
          break;
      case /* Date */1 :
          tmp = React.createElement(DateFilterForm$ReactHooksTemplate.make, {
                filter: filter._0,
                onChange: (function (param) {
                    return Curry._1(handleSetFilter, Filter$ReactHooksTemplate.setFilterTypes(param, xs));
                  }),
                displayFilterPills: false,
                disableDatePicker: correlation
              });
          break;
      case /* List */2 :
          tmp = React.createElement(ListFilterForm$ReactHooksTemplate.make, {
                filter: filter._0,
                onChange: (function (param) {
                    return Curry._1(handleSetFilter, Filter$ReactHooksTemplate.setFilterTypes(param, xs));
                  })
              });
          break;
      case /* Msisdn */3 :
          tmp = React.createElement(MsisdnFilterForm$ReactHooksTemplate.make, {
                filter: filter._0,
                onChange: (function (param) {
                    return Curry._1(handleSetFilter, Filter$ReactHooksTemplate.setFilterTypes(param, xs));
                  })
              });
          break;
      case /* Email */4 :
          tmp = React.createElement(EmailFilterForm$ReactHooksTemplate.make, {
                filter: filter._0,
                onChange: (function (param) {
                    return Curry._1(handleSetFilter, Filter$ReactHooksTemplate.setFilterTypes(param, xs));
                  })
              });
          break;
      case /* FreeText */5 :
          tmp = React.createElement(TextFilterForm$ReactHooksTemplate.make, {
                filter: filter._0,
                onChange: (function (param) {
                    return Curry._1(handleSetFilter, Filter$ReactHooksTemplate.setFilterTypes(param, xs));
                  })
              });
          break;
      
    }
    return React.createElement("div", {
                className: "text-darkcharcoal"
              }, React.createElement("div", {
                    className: "font-medium mb-2"
                  }, Utils$ReactHooksTemplate.humanize(Filter$ReactHooksTemplate.title(xs))), tmp);
  };
  var displayDateFilter = function (xs) {
    var filter = Filter$ReactHooksTemplate.filterType(xs);
    var tmp;
    tmp = filter.TAG === /* Date */1 ? React.createElement(DateFilterForm$ReactHooksTemplate.make, {
            filter: filter._0,
            onChange: (function (param) {
                return Curry._1(handleSetFilter, Filter$ReactHooksTemplate.setFilterTypes(param, xs));
              }),
            displayFilterPills: true,
            disableDatePicker: correlation
          }) : React.createElement(React.Fragment, undefined);
    return React.createElement("div", undefined, tmp);
  };
  var selectedIndicator = function (xs) {
    var isOpenFilter = state.filterId === Filter$ReactHooksTemplate.id(xs);
    if (Filter$ReactHooksTemplate.isSelected(xs)) {
      return React.createElement("div", {
                  className: "w-6-px h-6-px my-2 rounded-lg " + (
                    isOpenFilter ? "bg-white" : "bg-palatinateblue"
                  )
                });
    } else {
      return React.createElement(React.Fragment, undefined);
    }
  };
  var displayFilterHeaders = function (xs) {
    var isOpenFilter = state.filterId === Filter$ReactHooksTemplate.id(xs);
    return React.createElement("div", {
                key: String(Filter$ReactHooksTemplate.id(xs)),
                className: "flex flex-col "
              }, React.createElement("div", {
                    className: "flex text-sm p-4 justify-between " + (
                      isOpenFilter ? "bg-palatinateblue text-white" : ""
                    ),
                    onClick: (function (param) {
                        var id = Filter$ReactHooksTemplate.id(xs);
                        return Curry._1(setState, (function (param) {
                                      return {
                                              applying: state.applying,
                                              filterId: id
                                            };
                                    }));
                      })
                  }, React.createElement("div", {
                        className: isOpenFilter ? "font-bold" : ""
                      }, Utils$ReactHooksTemplate.humanize(Filter$ReactHooksTemplate.title(xs))), selectedIndicator(xs)));
  };
  var displayFilterOptions = function (xs) {
    var isOpenFilter = state.filterId === Filter$ReactHooksTemplate.id(xs);
    return React.createElement("div", {
                key: String(Filter$ReactHooksTemplate.id(xs)),
                className: "flex flex-col 1/2 " + (
                  isOpenFilter ? "p-4" : ""
                )
              }, isOpenFilter ? displayFilters(xs) : React.createElement(React.Fragment, undefined));
  };
  var tmp;
  if (filters) {
    var tail = filters.tl;
    tmp = React.createElement(React.Fragment, undefined, displayDateFilter(filters.hd), React.createElement("div", {
              className: "flex flex-wrap text-aurometalsaurus p-5 border-b border-gray-400 gap-2"
            }, React.createElement("div", {
                  className: "flex items-center font-normal text-sm h-7"
                }, "Active Filters"), $$Array.of_list(Belt_List.mapWithIndex(filterPills, (function (i, t) {
                        return React.createElement("div", {
                                    key: String(i),
                                    className: "flex flex-wrap mr-1"
                                  }, React.createElement(FilterPillsElement$ReactHooksTemplate.make, {
                                        t: t,
                                        handleResetFilter: handleResetFilter,
                                        height: "h-7 py-1"
                                      }));
                      })))), React.createElement("div", {
              className: "flex h-full text-darkcharcoal"
            }, React.createElement("div", {
                  className: "w-1/3 border-r border-gray-400 overflow-y-scroll"
                }, $$Array.of_list(Belt_List.map(tail, displayFilterHeaders))), React.createElement("div", {
                  className: "w-2/3 overflow-y-scroll"
                }, $$Array.of_list(Belt_List.map(tail, displayFilterOptions)))));
  } else {
    tmp = React.createElement(React.Fragment, undefined);
  }
  return React.createElement("div", {
              className: "flex flex-col " + (
                state.applying ? "animate-pulse pointer-events-none" : ""
              ),
              style: {
                height: "100%"
              }
            }, React.createElement("div", {
                  className: "flex justify-between items-center p-5 border-b text-darkcharcoal bg-antiflashwhite"
                }, React.createElement("div", {
                      className: "text-base font-bold"
                    }, "Filters"), React.createElement("div", {
                      className: "flex items-center"
                    }, React.createElement("button", {
                          onClick: (function ($$event) {
                              $$event.preventDefault();
                              $$event.stopPropagation();
                              return Curry._1(onClose, undefined);
                            })
                        }, React.createElement("img", {
                              className: "w-3",
                              src: Image$ReactHooksTemplate.closeIcon
                            })))), React.createElement("div", {
                  style: {
                    height: "100%",
                    overflow: "scroll"
                  }
                }, React.createElement(React.Fragment, undefined, tmp)), React.createElement("div", {
                  className: "h-20 border-t p-5"
                }, React.createElement("button", {
                      className: "bg-palatinateblue text-white float-right h-10 w-32 rounded",
                      id: "filter_apply",
                      onClick: onApplyBtnPress
                    }, "Apply"), React.createElement("button", {
                      className: "text-darkcharcoal float-right h-10 w-24 mr-4 border border-gray-400 rounded",
                      id: "filter_close",
                      onClick: (function ($$event) {
                          $$event.preventDefault();
                          return Curry._1(onClose, undefined);
                        })
                    }, "Cancel")));
}

var make = InsightsFilterForm;

exports.make = make;
/*  Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var MapSetting$ReactHooksTemplate = require("./mapSetting.bs.js");
var ChartSetting$ReactHooksTemplate = require("./chartSetting.bs.js");
var ScoreCardSetting$ReactHooksTemplate = require("./scoreCardSetting.bs.js");

function fromJson(visualizationType, json) {
  if (visualizationType === "score_card") {
    return {
            TAG: /* ScoreCardSetting */2,
            _0: ScoreCardSetting$ReactHooksTemplate.fromJson(json)
          };
  } else if (visualizationType === "map") {
    return {
            TAG: /* MapSetting */1,
            _0: MapSetting$ReactHooksTemplate.fromJson(json)
          };
  } else {
    return {
            TAG: /* ChartSetting */0,
            _0: ChartSetting$ReactHooksTemplate.fromJson(json)
          };
  }
}

exports.fromJson = fromJson;
/* No side effect */

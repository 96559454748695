// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var DataField$ReactHooksTemplate = require("./dataField.bs.js");

function id(t) {
  return t.id;
}

function value(t) {
  return t.value;
}

function dataField(t) {
  return t.dataField;
}

function operator(t) {
  return t.operator;
}

function decode(json) {
  return {
          id: Json_decode.field("id", Json_decode.$$int, json),
          operator: Json_decode.field("operator", Json_decode.string, json),
          value: Json_decode.field("value", (function (param) {
                  return Json_decode.list(Json_decode.string, param);
                }), json),
          dataField: Json_decode.field("data_field", DataField$ReactHooksTemplate.decode, json)
        };
}

exports.id = id;
exports.value = value;
exports.dataField = dataField;
exports.operator = operator;
exports.decode = decode;
/* No side effect */

// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Image$ReactHooksTemplate = require("../common/image.bs.js");
var Welcome$ReactHooksTemplate = require("./Welcome.bs.js");
var SurveyLanguage$ReactHooksTemplate = require("./surveyLanguage.bs.js");

function WelcomeFormBhima(Props) {
  var $$default = Props.default;
  var welcome = Props.welcome;
  var textColor = Props.textColor;
  var buttonBgColor = Props.buttonBgColor;
  var handleStart = Props.handleStart;
  var language = Props.language;
  var withDefault = function ($$default, x) {
    if (x.length === 0) {
      return $$default;
    } else {
      return x;
    }
  };
  return React.createElement("div", {
              className: "flex w-full flex-col justify-center min-h-screen"
            }, React.createElement("div", {
                  className: "flex flex-col items-center my-auto px-80-px small:px-6 w-full"
                }, React.createElement("img", {
                      src: Image$ReactHooksTemplate.bhimaThankyou
                    }), React.createElement("p", {
                      className: " text-" + textColor + " pb-16-px font-bold text-xl text-center\n        small:text-lg uppercase "
                    }, withDefault(Welcome$ReactHooksTemplate.title($$default), Welcome$ReactHooksTemplate.getTitleTranslation(welcome, SurveyLanguage$ReactHooksTemplate.name(language)))), React.createElement("p", {
                      className: " text-" + textColor + " mb-10-px pb-16-px font-bold text-l\n        text-center small:text-sm "
                    }, withDefault(Welcome$ReactHooksTemplate.description($$default), Welcome$ReactHooksTemplate.getDescriptionTranslation(welcome, SurveyLanguage$ReactHooksTemplate.name(language)))), React.createElement("a", {
                      className: "text-black text-18-px font-semibold py-4 px-8 text-center rounded",
                      style: {
                        backgroundColor: buttonBgColor
                      },
                      href: "#",
                      onClick: (function ($$event) {
                          $$event.preventDefault();
                          return Curry._1(handleStart, undefined);
                        })
                    }, withDefault(Welcome$ReactHooksTemplate.button($$default), Welcome$ReactHooksTemplate.getButtonTranslation(welcome, SurveyLanguage$ReactHooksTemplate.name(language))))));
}

var make = WelcomeFormBhima;

exports.make = make;
/* react Not a pure module */

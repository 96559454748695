// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var Fetch = require("bs-fetch/src/Fetch.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");
var Utils$ReactHooksTemplate = require("./utils.bs.js");

function fetchDeleteJSON(url) {
  return fetch(url, Fetch.RequestInit.make(/* Delete */4, [
                      [
                        "Content-Type",
                        "application/json"
                      ],
                      [
                        "X-CSRF-TOKEN",
                        Utils$ReactHooksTemplate.csrfToken
                      ]
                    ], undefined, undefined, undefined, undefined, /* SameOrigin */1, undefined, undefined, undefined, undefined, undefined)(undefined)).then(function (prim) {
              return prim.json();
            });
}

function fetchPostJSON(url, item) {
  return fetch(url, Fetch.RequestInit.make(/* Post */2, [
                      [
                        "Content-Type",
                        "application/json"
                      ],
                      [
                        "X-CSRF-TOKEN",
                        Utils$ReactHooksTemplate.csrfToken
                      ]
                    ], Caml_option.some(JSON.stringify(item)), undefined, undefined, undefined, /* SameOrigin */1, undefined, undefined, undefined, undefined, undefined)(undefined)).then(function (prim) {
              return prim.json();
            });
}

function fetchPutJSON(url, item) {
  return fetch(url, Fetch.RequestInit.make(/* Put */3, [
                      [
                        "Content-Type",
                        "application/json"
                      ],
                      [
                        "X-CSRF-TOKEN",
                        Utils$ReactHooksTemplate.csrfToken
                      ]
                    ], Caml_option.some(JSON.stringify(item)), undefined, undefined, undefined, /* SameOrigin */1, undefined, undefined, undefined, undefined, undefined)(undefined)).then(function (response) {
              if (response.ok) {
                return response.json();
              } else {
                return Promise.resolve(Json_encode.object_({
                                hd: [
                                  "status",
                                  "error"
                                ],
                                tl: {
                                  hd: [
                                    "error",
                                    "Something went Wrong. Please try again later"
                                  ],
                                  tl: /* [] */0
                                }
                              }));
              }
            });
}

function fetchPostEncodedJSON(url, item) {
  return fetch(url, Fetch.RequestInit.make(/* Post */2, [
                      [
                        "Content-Type",
                        "application/json"
                      ],
                      [
                        "X-CSRF-TOKEN",
                        Utils$ReactHooksTemplate.csrfToken
                      ]
                    ], Caml_option.some(JSON.stringify(item)), undefined, undefined, undefined, /* SameOrigin */1, undefined, undefined, undefined, undefined, undefined)(undefined)).then(function (prim) {
              return prim.json();
            });
}

function fetchGetJSON(url) {
  return fetch(url, Fetch.RequestInit.make(/* Get */0, undefined, undefined, undefined, undefined, undefined, /* SameOrigin */1, undefined, undefined, undefined, undefined, undefined)(undefined)).then(function (prim) {
              return prim.json();
            });
}

function fetchGetJSONWithHeader(url) {
  return fetch(url, Fetch.RequestInit.make(/* Get */0, undefined, undefined, undefined, undefined, undefined, /* SameOrigin */1, undefined, undefined, undefined, undefined, undefined)(undefined)).then(function (response) {
                return Promise.resolve([
                            response.json(),
                            response.headers
                          ]);
              }).then(function (param) {
              return Promise.resolve([
                          param[1],
                          param[0]
                        ]);
            });
}

function fetchGetJSONDecode(url, decoder) {
  return fetchGetJSON(url).then(function (json) {
              return Promise.resolve(Curry._1(decoder, json));
            });
}

exports.fetchDeleteJSON = fetchDeleteJSON;
exports.fetchPostJSON = fetchPostJSON;
exports.fetchPutJSON = fetchPutJSON;
exports.fetchPostEncodedJSON = fetchPostEncodedJSON;
exports.fetchGetJSON = fetchGetJSON;
exports.fetchGetJSONWithHeader = fetchGetJSONWithHeader;
exports.fetchGetJSONDecode = fetchGetJSONDecode;
/* Utils-ReactHooksTemplate Not a pure module */

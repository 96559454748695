// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Utils$ReactHooksTemplate = require("./utils.bs.js");

function TextInputNew(Props) {
  var label = Props.label;
  var value = Props.value;
  var handleChange = Props.handleChange;
  var errorCSS = Props.errorCSS;
  var errorText = Props.errorText;
  var errorOpt = Props.error;
  var maxLength = Props.maxLength;
  var labelCSS = Props.labelCSS;
  var placeholder = Props.placeholder;
  var textInputClassNameOpt = Props.textInputClassName;
  var containerClassNameOpt = Props.containerClassName;
  var labelClassName = Props.labelClassName;
  var error = errorOpt !== undefined ? errorOpt : false;
  var textInputClassName = textInputClassNameOpt !== undefined ? textInputClassNameOpt : "outline-none border border-gray-400 h-10 w-3/5 rounded px-3 py-2 text-sm";
  var containerClassName = containerClassNameOpt !== undefined ? containerClassNameOpt : "flex justify-between items-center";
  var handleValidation = function ($$event) {
    var name_input = Utils$ReactHooksTemplate.formTargetValue($$event);
    var firstChar = name_input[0];
    var check = Belt_Array.getBy([
          "<",
          "!",
          "=",
          "+",
          "-",
          "@",
          ">",
          "?",
          "'",
          "\"",
          "$",
          "#",
          "%",
          "^",
          "*",
          "(",
          ")",
          "/",
          ".",
          "&",
          ",",
          ";",
          "`",
          "~"
        ], (function (x) {
            return firstChar === x;
          }));
    if (check !== undefined) {
      console.log("Invalid Characters!");
      return ;
    } else {
      return Curry._1(handleChange, $$event);
    }
  };
  var maximumLength = maxLength !== undefined ? maxLength : 524288;
  return React.createElement("div", {
              className: containerClassName
            }, React.createElement("div", {
                  className: labelCSS !== undefined ? labelCSS : "text-sm font-medium " + labelClassName
                }, label), React.createElement("input", {
                  className: textInputClassName,
                  maxLength: maximumLength,
                  placeholder: placeholder !== undefined ? placeholder : "",
                  type: "text",
                  value: value,
                  onChange: handleValidation
                }), error ? React.createElement("div", {
                    className: errorCSS !== undefined ? errorCSS : "text-red-600 font-medium float-right"
                  }, errorText !== undefined ? errorText : "Error") : React.createElement(React.Fragment, undefined));
}

var make = TextInputNew;

exports.make = make;
/* react Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';


function make(logo) {
  return {
          logo: logo
        };
}

function getLogo(t) {
  return t.logo;
}

exports.make = make;
exports.getLogo = getLogo;
/* No side effect */

// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var List = require("rescript/lib/js/list.js");
var $$Array = require("rescript/lib/js/array.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var Http$ReactHooksTemplate = require("../common/http.bs.js");
var Image$ReactHooksTemplate = require("../common/image.bs.js");
var Filters$ReactHooksTemplate = require("../dashboard/filters/filters.bs.js");

function emptyState(param) {
  return {
          mode: "",
          count: 0,
          total: 0,
          percentage: 0.0
        };
}

function mode(t) {
  return t.mode;
}

function count(t) {
  return t.count;
}

function total(t) {
  return t.total;
}

function percentage(t) {
  return t.percentage;
}

function decodeDownloadReport(json) {
  return Json_decode.field("file_name", Json_decode.string, json);
}

function decode(json) {
  return {
          mode: Json_decode.field("mode", Json_decode.string, json),
          count: Json_decode.field("count", Json_decode.$$int, json),
          total: Json_decode.field("total", Json_decode.$$int, json),
          percentage: Json_decode.field("percentage", Json_decode.$$float, json)
        };
}

var Report = {
  emptyState: emptyState,
  mode: mode,
  count: count,
  total: total,
  percentage: percentage,
  decodeDownloadReport: decodeDownloadReport,
  decode: decode
};

var emptyReports = {
  mode: "",
  count: 0,
  total: 0,
  percentage: 0.0
};

var initialState = {
  loading: true,
  reports: emptyReports,
  toggleFilter: false
};

function reducer(state, x) {
  if (typeof x === "number") {
    if (x !== 0) {
      return {
              loading: state.loading,
              reports: state.reports,
              toggleFilter: !state.toggleFilter
            };
    } else {
      return {
              loading: true,
              reports: state.reports,
              toggleFilter: state.toggleFilter
            };
    }
  } else {
    return {
            loading: false,
            reports: x._0,
            toggleFilter: state.toggleFilter
          };
  }
}

function ResponseModeReport(Props) {
  var campaignId = Props.campaignId;
  var match = React.useReducer(reducer, initialState);
  var dispatch = match[1];
  var match$1 = React.useState(function () {
        return /* [] */0;
      });
  var setReports = match$1[1];
  var toggleFilter = function (param) {
    return Curry._1(dispatch, /* ToggleFilter */1);
  };
  var url = "/api/v1/mode_wise_responses?campaign_id=" + campaignId;
  var fetchReport = function (param) {
    return Http$ReactHooksTemplate.fetchGetJSONDecode(url, (function (param) {
                  return Json_decode.field("mode_wise_count", (function (param) {
                                return Json_decode.list(decode, param);
                              }), param);
                }));
  };
  var onApplyFilter = function (param) {
    Curry._1(dispatch, /* ToggleFilter */1);
    Curry._1(dispatch, /* FetchReportsInProgress */0);
    fetchReport(undefined).then(function (reports) {
          return Promise.resolve(Curry._1(setReports, (function (param) {
                            return reports;
                          })));
        });
    
  };
  React.useEffect((function () {
          fetchReport(undefined).then(function (reports) {
                return Promise.resolve(Curry._1(setReports, (function (param) {
                                  return reports;
                                })));
              });
          
        }), []);
  return React.createElement(React.Fragment, undefined, match[0].toggleFilter ? React.createElement(Filters$ReactHooksTemplate.make, {
                    onClose: toggleFilter,
                    onApply: onApplyFilter,
                    showDateFilter: false,
                    showFilterPills: false
                  }) : React.createElement(React.Fragment, undefined), React.createElement("div", undefined, React.createElement("div", {
                      className: "flex justify-between items-center p-20-px"
                    }, React.createElement("h2", {
                          className: "text-xl font-semibold"
                        }, "Response Mode Wise Report"), React.createElement("div", {
                          className: "flex gap-4"
                        }, React.createElement("button", {
                              className: "bg-ghostwhite border border-gray-400\n            shadow-inner flex rounded py-2 px-3 text-xs",
                              id: "filters",
                              onClick: (function (param) {
                                  return Curry._1(dispatch, /* ToggleFilter */1);
                                })
                            }, React.createElement("img", {
                                  className: "h-14-px mr-2-px",
                                  src: Image$ReactHooksTemplate.filterButton
                                }), "Filters"), React.createElement("button", {
                              className: "bg-pigmentblue border border-gray-400\n              flex rounded py-2 px-3 text-xs text-white",
                              onClick: (function ($$event) {
                                  $$event.preventDefault();
                                  var download_url = "/api/v1/download_mode_wise_responses_report?campaign_id=" + campaignId;
                                  Http$ReactHooksTemplate.fetchGetJSONDecode(download_url, decodeDownloadReport).then(function (file_name) {
                                        return Promise.resolve((console.log(file_name), RescriptReactRouter.push("/campaigns/report_downloads/" + campaignId)));
                                      });
                                  
                                })
                            }, React.createElement("img", {
                                  className: "h-14-px mr-1 filter-white",
                                  src: Image$ReactHooksTemplate.download
                                }), "Download"))), React.createElement("div", {
                      className: "flex px-20-px"
                    }, React.createElement("table", {
                          className: "text-left table-auto w-full border-separate text-xs font-medium"
                        }, React.createElement("thead", {
                              className: "font-medium p-4 bg-ghostwhite text-aurometalsaurus"
                            }, React.createElement("tr", undefined, React.createElement("th", {
                                      className: "px-4 py-2"
                                    }, React.createElement("div", {
                                          className: "flex"
                                        }, "Mode", React.createElement("img", {
                                              className: "ml-4-px",
                                              src: Image$ReactHooksTemplate.heading
                                            }))), React.createElement("th", {
                                      className: "px-4 py-2"
                                    }, React.createElement("div", {
                                          className: "flex"
                                        }, "Total Response (All Modes)", React.createElement("img", {
                                              className: "ml-4-px",
                                              src: Image$ReactHooksTemplate.heading
                                            }))), React.createElement("th", {
                                      className: "px-4 py-2"
                                    }, React.createElement("div", {
                                          className: "flex"
                                        }, "Total Response (Individual Mode)", React.createElement("img", {
                                              className: "ml-4-px",
                                              src: Image$ReactHooksTemplate.heading
                                            }))), React.createElement("th", {
                                      className: "px-4 py-2"
                                    }, React.createElement("div", {
                                          className: "flex"
                                        }, "Response %", React.createElement("img", {
                                              className: "ml-4-px",
                                              src: Image$ReactHooksTemplate.heading
                                            }))))), React.createElement("tbody", undefined, $$Array.of_list(List.map((function (report) {
                                        return React.createElement("tr", {
                                                    className: "border-b border-bottom-gray-600"
                                                  }, React.createElement("td", {
                                                        className: "px-4 py-2"
                                                      }, report.mode), React.createElement("td", {
                                                        className: "px-4 py-2"
                                                      }, String(report.total)), React.createElement("td", {
                                                        className: "px-4 py-2"
                                                      }, String(report.count)), React.createElement("td", {
                                                        className: "px-4 py-2"
                                                      }, report.percentage.toString()));
                                      }), match$1[0])))))));
}

var emptyReport = /* [] */0;

var make = ResponseModeReport;

exports.emptyReport = emptyReport;
exports.Report = Report;
exports.emptyReports = emptyReports;
exports.initialState = initialState;
exports.reducer = reducer;
exports.make = make;
/* react Not a pure module */

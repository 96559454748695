// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var AggregateField$ReactHooksTemplate = require("./AggregateField.bs.js");

function metricFromString(metricString) {
  switch (metricString) {
    case "CSAT" :
        return /* CSAT */1;
    case "NPS" :
        return /* NPS */0;
    default:
      return /* None */2;
  }
}

function metricToString(metric) {
  switch (metric) {
    case /* NPS */0 :
        return "NPS";
    case /* CSAT */1 :
        return "CSAT";
    case /* None */2 :
        return "";
    
  }
}

function decodeMetric(json) {
  var metricString = Json_decode.field("metric", (function (param) {
          return Json_decode.optional(Json_decode.string, param);
        }), json);
  if (metricString !== undefined) {
    return metricFromString(metricString);
  } else {
    return /* None */2;
  }
}

function metric(t) {
  return t.metric;
}

function aggregateFields(t) {
  return t.aggregateFields;
}

function setMetric(metric, t) {
  return {
          metric: metric,
          aggregateFields: t.aggregateFields
        };
}

function setAggregateFields(aggregateFields, t) {
  return {
          metric: t.metric,
          aggregateFields: aggregateFields
        };
}

function setFields(fields) {
  return {
          metric: fields[0],
          aggregateFields: fields[1]
        };
}

function empty(param) {
  return {
          metric: undefined,
          aggregateFields: undefined
        };
}

function decode(json) {
  return {
          metric: decodeMetric(json),
          aggregateFields: Json_decode.field("aggregate_fields", (function (param) {
                  return Json_decode.optional(AggregateField$ReactHooksTemplate.decode, param);
                }), json)
        };
}

var metrics = {
  hd: "NPS",
  tl: {
    hd: "CSAT",
    tl: /* [] */0
  }
};

var templateType = "AggregateReport";

exports.metricFromString = metricFromString;
exports.metricToString = metricToString;
exports.decodeMetric = decodeMetric;
exports.metrics = metrics;
exports.templateType = templateType;
exports.metric = metric;
exports.aggregateFields = aggregateFields;
exports.setMetric = setMetric;
exports.setAggregateFields = setAggregateFields;
exports.setFields = setFields;
exports.empty = empty;
exports.decode = decode;
/* No side effect */

// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var List = require("rescript/lib/js/list.js");
var $$Array = require("rescript/lib/js/array.js");
var React = require("react");

function SelectBoxNew(Props) {
  var label = Props.label;
  var value = Props.value;
  var handleChange = Props.handleChange;
  var errorCSS = Props.errorCSS;
  var errorText = Props.errorText;
  var labelCSS = Props.labelCSS;
  var placeholder = Props.placeholder;
  var errorOpt = Props.error;
  var options = Props.options;
  var error = errorOpt !== undefined ? errorOpt : false;
  return React.createElement("div", {
              className: "flex justify-between items-center"
            }, React.createElement("div", {
                  className: labelCSS !== undefined ? labelCSS : "text-sm font-medium"
                }, label), React.createElement("select", {
                  className: " block border px-3 py-2 border-gray-400\n        w-3/5 bg-white rounded capitalize text-sm",
                  placeholder: placeholder !== undefined ? placeholder : "",
                  value: value,
                  onChange: handleChange
                }, $$Array.of_list(List.map((function (option) {
                            return React.createElement("option", {
                                        key: option,
                                        value: option
                                      }, option);
                          }), options))), error ? React.createElement("div", {
                    className: errorCSS !== undefined ? errorCSS : "text-red-600 font-medium float-right"
                  }, errorText !== undefined ? errorText : "Error") : React.createElement(React.Fragment, undefined));
}

var make = SelectBoxNew;

exports.make = make;
/* react Not a pure module */

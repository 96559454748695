// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var List = require("rescript/lib/js/list.js");
var $$Array = require("rescript/lib/js/array.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Image$ReactHooksTemplate = require("../common/image.bs.js");
var Question$ReactHooksTemplate = require("./Question.bs.js");
var RandomId$ReactHooksTemplate = require("./RandomId.bs.js");
var ThankYou$ReactHooksTemplate = require("./ThankYou.bs.js");
var OptionGroups$ReactHooksTemplate = require("../common/optionGroups.bs.js");

function FilteredSelectBox(Props) {
  var options = Props.options;
  var placeholderOpt = Props.placeholder;
  var onChange = Props.onChange;
  var classNameOpt = Props.className;
  var selectedOption = Props.selectedOption;
  var multipleSelectOpt = Props.multipleSelect;
  var placeholder = placeholderOpt !== undefined ? placeholderOpt : "";
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var multipleSelect = multipleSelectOpt !== undefined ? multipleSelectOpt : false;
  var match = React.useState(function () {
        return {
                open_: false
              };
      });
  var setState = match[1];
  var state = match[0];
  var domElement = React.useRef(null);
  var handleOutsideClick = function ($$event) {
    var targetElement = $$event.target;
    var selectBoxElement = Belt_Option.getExn(Caml_option.nullable_to_opt(domElement.current));
    if (!selectBoxElement.contains(targetElement)) {
      return Curry._1(setState, (function (param) {
                    return {
                            open_: false
                          };
                  }));
    }
    
  };
  React.useEffect((function () {
          document.addEventListener("mousedown", handleOutsideClick);
          return (function (param) {
                    document.removeEventListener("mousedown", handleOutsideClick);
                    
                  });
        }), []);
  var handleClick = function ($$event) {
    $$event.preventDefault();
    $$event.stopPropagation();
    return Curry._1(setState, (function (state) {
                  return {
                          open_: !state.open_
                        };
                }));
  };
  var removeQuestion = function (selectedItem, optionList) {
    if (selectedOption.TAG === /* Questions */0) {
      return List.filter(function (q) {
                    return !List.exists((function (x) {
                                  return Caml_obj.caml_equal(Question$ReactHooksTemplate.uuid(x), Question$ReactHooksTemplate.uuid(q));
                                }), optionList);
                  })(selectedItem);
    } else {
      return /* [] */0;
    }
  };
  var removeThankyou = function (selectedItem, optionList) {
    if (selectedOption.TAG === /* Questions */0) {
      return /* [] */0;
    } else {
      return List.filter(function (q) {
                    return !List.exists((function (x) {
                                  return Caml_obj.caml_equal(ThankYou$ReactHooksTemplate.uuid(x), ThankYou$ReactHooksTemplate.uuid(q));
                                }), optionList);
                  })(selectedItem);
    }
  };
  var handleOptionClick = function (optionItem, selected) {
    var updatedSelectedOptions;
    if (optionItem.TAG === /* Questions */0) {
      var xs = optionItem._0;
      var newList;
      if (selectedOption.TAG === /* Questions */0) {
        var selectedQuestions = selectedOption._0;
        newList = selected ? removeQuestion(selectedQuestions, xs) : Belt_List.concat(xs, selectedQuestions);
      } else {
        newList = xs;
      }
      updatedSelectedOptions = multipleSelect ? ({
            TAG: /* Questions */0,
            _0: newList
          }) : (
          Caml_obj.caml_equal(selectedOption, {
                TAG: /* Questions */0,
                _0: xs
              }) ? ({
                TAG: /* Questions */0,
                _0: /* [] */0
              }) : ({
                TAG: /* Questions */0,
                _0: xs
              })
        );
    } else {
      var xs$1 = optionItem._0;
      var newList$1;
      if (selectedOption.TAG === /* Questions */0) {
        newList$1 = xs$1;
      } else {
        var selectedThankyous = selectedOption._0;
        newList$1 = selected ? removeThankyou(selectedThankyous, xs$1) : Belt_List.concat(xs$1, selectedThankyous);
      }
      updatedSelectedOptions = multipleSelect ? ({
            TAG: /* ThankYous */1,
            _0: newList$1
          }) : (
          Caml_obj.caml_equal(selectedOption, {
                TAG: /* ThankYous */1,
                _0: xs$1
              }) ? ({
                TAG: /* ThankYous */1,
                _0: /* [] */0
              }) : ({
                TAG: /* ThankYous */1,
                _0: xs$1
              })
        );
    }
    Curry._1(setState, (function (state) {
            return {
                    open_: !state.open_
                  };
          }));
    return Curry._1(onChange, updatedSelectedOptions);
  };
  var renderItem = function (prefix, id, text) {
    return React.createElement("div", {
                className: "flex w-full gap-1"
              }, React.createElement("div", {
                    className: "flex justify-around w-6 h-6 rounded-full bg-pigmentblue text-center items-center"
                  }, React.createElement("div", {
                        className: "text-xs text-white"
                      }, prefix + String(id + 1 | 0))), React.createElement("div", {
                    className: "flex items-center truncate"
                  }, text));
  };
  var renderPlaceholder = function (placeholder) {
    return React.createElement("div", {
                className: "flex w-full gap-1 truncate"
              }, multipleSelect ? React.createElement("p", {
                      className: "flex items-center"
                    }, placeholder) : React.createElement("p", {
                      className: "mr-3 items-center"
                    }, placeholder));
  };
  var findIndex = function (list, predicate) {
    var _i = 0;
    var _l = list;
    while(true) {
      var l = _l;
      var i = _i;
      if (!l) {
        return ;
      }
      if (Curry._1(predicate, l.hd)) {
        return i;
      }
      _l = l.tl;
      _i = i + 1 | 0;
      continue ;
    };
  };
  var placeholderText;
  if (selectedOption.TAG === /* Questions */0) {
    var xs = selectedOption._0;
    if (multipleSelect) {
      placeholderText = xs ? $$Array.of_list(List.map((function (x) {
                    var index = findIndex(OptionGroups$ReactHooksTemplate.questions(options), (function (z) {
                            return Caml_obj.caml_equal(Question$ReactHooksTemplate.uuid(z), Question$ReactHooksTemplate.uuid(x));
                          }));
                    return React.createElement("div", {
                                key: RandomId$ReactHooksTemplate.toString(Question$ReactHooksTemplate.uuid(x)),
                                className: "flex gap-1 border border-gray-400 rounded p-1"
                              }, React.createElement("div", {
                                    className: "w-6 h-6 flex text-white rounded-full bg-pigmentblue p-1 mr-1"
                                  }, index !== undefined ? React.createElement("p", {
                                          className: "text-xs"
                                        }, "Q" + String(index + 1 | 0)) : React.createElement(React.Fragment, undefined)), React.createElement("p", {
                                    className: "truncate max-w-48-px p-1"
                                  }, Question$ReactHooksTemplate.text(x)), React.createElement("div", {
                                    className: "flex items-center align-middle place-items-center mr-2 cursor-default",
                                    onClick: (function (param) {
                                        return handleOptionClick({
                                                    TAG: /* Questions */0,
                                                    _0: {
                                                      hd: x,
                                                      tl: /* [] */0
                                                    }
                                                  }, true);
                                      })
                                  }, React.createElement("img", {
                                        className: "h-6-px",
                                        src: Image$ReactHooksTemplate.closeIcon
                                      })));
                  }), xs)) : renderPlaceholder(placeholder);
    } else {
      var x = Belt_List.head(xs);
      if (x !== undefined) {
        var q = List.find_opt((function (q) {
                return Caml_obj.caml_equal(Question$ReactHooksTemplate.uuid(q), Question$ReactHooksTemplate.uuid(x));
              }), OptionGroups$ReactHooksTemplate.questions(options));
        if (q !== undefined) {
          var index = findIndex(OptionGroups$ReactHooksTemplate.questions(options), (function (z) {
                  return Caml_obj.caml_equal(Question$ReactHooksTemplate.uuid(z), Question$ReactHooksTemplate.uuid(q));
                }));
          placeholderText = index !== undefined ? renderItem("Q", index, Question$ReactHooksTemplate.text(q)) : React.createElement(React.Fragment, undefined);
        } else {
          placeholderText = renderPlaceholder(placeholder);
        }
      } else {
        placeholderText = renderPlaceholder(placeholder);
      }
    }
  } else {
    var xs$1 = selectedOption._0;
    if (multipleSelect) {
      placeholderText = xs$1 ? $$Array.of_list(List.map((function (x) {
                    var index = findIndex(OptionGroups$ReactHooksTemplate.thankYous(options), (function (z) {
                            return Caml_obj.caml_equal(ThankYou$ReactHooksTemplate.uuid(z), ThankYou$ReactHooksTemplate.uuid(x));
                          }));
                    return React.createElement("div", {
                                key: RandomId$ReactHooksTemplate.toString(ThankYou$ReactHooksTemplate.uuid(x)),
                                className: "flex gap-1 border border-gray-400 rounded p-1"
                              }, React.createElement("div", {
                                    className: "w-6 h-6 flex text-white rounded-full bg-pigmentblue p-1 mr-1"
                                  }, index !== undefined ? React.createElement("p", {
                                          className: "text-xs"
                                        }, "T" + String(index + 1 | 0)) : React.createElement(React.Fragment, undefined)), React.createElement("p", {
                                    className: "truncate max-w-48-px p-1"
                                  }, ThankYou$ReactHooksTemplate.text(x)), React.createElement("div", {
                                    className: "flex items-center align-middle place-items-center mr-2 cursor-default",
                                    onClick: (function (param) {
                                        return handleOptionClick({
                                                    TAG: /* ThankYous */1,
                                                    _0: {
                                                      hd: x,
                                                      tl: /* [] */0
                                                    }
                                                  }, true);
                                      })
                                  }, React.createElement("img", {
                                        className: "h-6-px",
                                        src: Image$ReactHooksTemplate.closeIcon
                                      })));
                  }), xs$1)) : renderPlaceholder(placeholder);
    } else {
      var x$1 = Belt_List.head(xs$1);
      if (x$1 !== undefined) {
        var x$2 = Caml_option.valFromOption(x$1);
        var t = List.find_opt((function (t) {
                return Caml_obj.caml_equal(ThankYou$ReactHooksTemplate.uuid(t), ThankYou$ReactHooksTemplate.uuid(x$2));
              }), OptionGroups$ReactHooksTemplate.thankYous(options));
        if (t !== undefined) {
          var t$1 = Caml_option.valFromOption(t);
          var index$1 = findIndex(OptionGroups$ReactHooksTemplate.thankYous(options), (function (z) {
                  return Caml_obj.caml_equal(ThankYou$ReactHooksTemplate.uuid(z), ThankYou$ReactHooksTemplate.uuid(t$1));
                }));
          placeholderText = index$1 !== undefined ? renderItem("T", index$1, ThankYou$ReactHooksTemplate.text(t$1)) : React.createElement(React.Fragment, undefined);
        } else {
          placeholderText = renderPlaceholder(placeholder);
        }
      } else {
        placeholderText = renderPlaceholder(placeholder);
      }
    }
  }
  var listBoxCSS = state.open_ ? "block" : "hidden";
  return React.createElement("div", {
              ref: domElement,
              className: "relative"
            }, React.createElement("div", {
                  className: "border cursor-pointer rounded p-4 " + className,
                  onClick: handleClick
                }, React.createElement("div", undefined, state.open_ ? React.createElement("img", {
                            className: "float-right py-2",
                            src: Image$ReactHooksTemplate.chevronUp
                          }) : React.createElement("img", {
                            className: "float-right py-2",
                            src: Image$ReactHooksTemplate.chevronDown
                          })), React.createElement("div", undefined, React.createElement("div", {
                          className: "flex justify-between"
                        }, React.createElement("div", {
                              className: "text-sm flex flex-wrap gap-1"
                            }, placeholderText)))), React.createElement("div", {
                  className: "flex flex-col border z-50 bg-white cursor-pointer rounded overflow-y-auto " + listBoxCSS,
                  style: {
                    maxHeight: "40vh",
                    position: "absolute",
                    width: "100%"
                  }
                }, Belt_List.length(OptionGroups$ReactHooksTemplate.questions(options)) > 0 ? React.createElement(React.Fragment, undefined, React.createElement("div", {
                            className: "text-base font-medium text-darkcharcoal m-2 p-1"
                          }, "Questions"), $$Array.of_list(Belt_List.mapWithIndex(OptionGroups$ReactHooksTemplate.questions(options), (function (i, q) {
                                  var selected;
                                  if (selectedOption.TAG === /* Questions */0) {
                                    var xs = selectedOption._0;
                                    selected = xs ? List.exists((function (x) {
                                              return Caml_obj.caml_equal(Question$ReactHooksTemplate.uuid(x), Question$ReactHooksTemplate.uuid(q));
                                            }), xs) : false;
                                  } else {
                                    selected = false;
                                  }
                                  return React.createElement("div", {
                                              key: RandomId$ReactHooksTemplate.toString(Question$ReactHooksTemplate.uuid(q)),
                                              className: selected ? "text-left bg-ghostwhiteblue px-2 py-1" : "hover:bg-gray-300 text-left px-2 py-1",
                                              value: Question$ReactHooksTemplate.text(q),
                                              onClick: (function (param) {
                                                  return handleOptionClick({
                                                              TAG: /* Questions */0,
                                                              _0: {
                                                                hd: q,
                                                                tl: /* [] */0
                                                              }
                                                            }, selected);
                                                })
                                            }, React.createElement("div", {
                                                  className: "flex text-sm items-center border-b border-lavenderweb border-x-8 p-2 gap-1 w-full"
                                                }, React.createElement("div", {
                                                      className: "flex justify-around w-6 h-6 rounded-full bg-pigmentblue text-center items-center"
                                                    }, React.createElement("div", {
                                                          className: "text-xs text-white"
                                                        }, String(i + 1 | 0))), React.createElement("div", {
                                                      className: "flex items-center"
                                                    }, Question$ReactHooksTemplate.text(q))));
                                })))) : React.createElement(React.Fragment, undefined), Belt_List.length(OptionGroups$ReactHooksTemplate.thankYous(options)) > 0 ? React.createElement(React.Fragment, undefined, React.createElement("div", {
                            className: "text-base font-medium text-darkcharcoal m-2 p-1"
                          }, "Thank Yous"), $$Array.of_list(Belt_List.mapWithIndex(OptionGroups$ReactHooksTemplate.thankYous(options), (function (i, t) {
                                  var selected;
                                  if (selectedOption.TAG === /* Questions */0) {
                                    selected = false;
                                  } else {
                                    var xs = selectedOption._0;
                                    selected = xs ? List.exists((function (x) {
                                              return Caml_obj.caml_equal(ThankYou$ReactHooksTemplate.uuid(x), ThankYou$ReactHooksTemplate.uuid(t));
                                            }), xs) : false;
                                  }
                                  return React.createElement("div", {
                                              key: RandomId$ReactHooksTemplate.toString(ThankYou$ReactHooksTemplate.uuid(t)),
                                              className: selected ? "text-left bg-ghostwhiteblue px-2 py-1" : "hover:bg-gray-300 text-left px-2 py-1",
                                              value: ThankYou$ReactHooksTemplate.text(t),
                                              onClick: (function (param) {
                                                  return handleOptionClick({
                                                              TAG: /* ThankYous */1,
                                                              _0: {
                                                                hd: t,
                                                                tl: /* [] */0
                                                              }
                                                            }, selected);
                                                })
                                            }, React.createElement("div", {
                                                  className: "flex text-sm items-center border-b border-lavenderweb border-x-8 p-2 gap-1 w-full"
                                                }, React.createElement("div", {
                                                      className: "flex justify-around w-6 h-6 rounded-full bg-pigmentblue text-center items-center"
                                                    }, React.createElement("div", {
                                                          className: "text-xs text-white"
                                                        }, String(i + 1 | 0))), React.createElement("div", {
                                                      className: "flex items-center"
                                                    }, ThankYou$ReactHooksTemplate.text(t))));
                                })))) : React.createElement(React.Fragment, undefined)));
}

var make = FilteredSelectBox;

exports.make = make;
/* react Not a pure module */

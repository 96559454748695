// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var List = require("rescript/lib/js/list.js");
var $$Array = require("rescript/lib/js/array.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Http$ReactHooksTemplate = require("../common/http.bs.js");
var Image$ReactHooksTemplate = require("../common/image.bs.js");

function msisdn(t) {
  return t.msisdn;
}

function email(t) {
  return t.email;
}

function survey_url(t) {
  return t.survey_url;
}

function status(t) {
  return t.status;
}

function decode(json) {
  return {
          msisdn: Json_decode.field("customer_detail", (function (param) {
                  return Json_decode.field("msisdn", Json_decode.string, param);
                }), json),
          email: Json_decode.field("customer_detail", (function (param) {
                  return Json_decode.field("email", Json_decode.string, param);
                }), json),
          survey_url: Json_decode.field("survey_url", Json_decode.string, json),
          status: Json_decode.field("status", Json_decode.string, json)
        };
}

var Transaction = {
  msisdn: msisdn,
  email: email,
  survey_url: survey_url,
  status: status,
  decode: decode
};

function fetchTransactions(param) {
  return Http$ReactHooksTemplate.fetchGetJSONDecode("/api/v1/campaigns/telephonic_transactions", (function (param) {
                return Json_decode.field("transactions", (function (param) {
                              return Json_decode.list(decode, param);
                            }), param);
              }));
}

function TelephonicSurveys(Props) {
  var match = React.useState(function () {
        return /* Loading */0;
      });
  var setState = match[1];
  var state = match[0];
  React.useEffect((function () {
          fetchTransactions(undefined).then(function (transactions) {
                return Promise.resolve(Curry._1(setState, (function (param) {
                                  return /* Available */{
                                          _0: transactions
                                        };
                                })));
              });
          
        }), []);
  var bgStatusColor = function (status) {
    switch (status) {
      case "closed" :
          return "bg-firebrick";
      case "completed" :
          return "bg-americangreen";
      case "delivered" :
          return "bg-pigmentblue";
      case "open" :
          return "bg-candypink";
      case "responded" :
          return "bg-pelorous";
      default:
        return "hidden";
    }
  };
  return React.createElement("div", {
              className: "m-30-px"
            }, state ? React.createElement("div", {
                    className: "flex px-30-px pb-30-px"
                  }, React.createElement("table", {
                        className: "text-left table-auto w-full border-separate text-xs font-medium"
                      }, React.createElement("thead", {
                            className: "font-medium p-4 bg-ghostwhite text-aurometalsaurus"
                          }, React.createElement("tr", undefined, React.createElement("th", {
                                    className: "px-4 py-2"
                                  }, React.createElement("div", {
                                        className: "flex"
                                      }, "Msisdn", React.createElement("img", {
                                            className: "ml-4-px",
                                            src: Image$ReactHooksTemplate.heading
                                          }))), React.createElement("th", {
                                    className: "px-4 py-2"
                                  }, React.createElement("div", {
                                        className: "flex"
                                      }, "Email", React.createElement("img", {
                                            className: "ml-4-px",
                                            src: Image$ReactHooksTemplate.heading
                                          }))), React.createElement("th", {
                                    className: "px-4 py-2"
                                  }, React.createElement("div", {
                                        className: "flex"
                                      }, "Status", React.createElement("img", {
                                            className: "ml-4-px",
                                            src: Image$ReactHooksTemplate.heading
                                          }))), React.createElement("th", {
                                    className: "px-4 py-2"
                                  }, React.createElement("div", {
                                        className: "flex"
                                      }, "")))), React.createElement("tbody", undefined, $$Array.of_list(List.map((function (x) {
                                      return React.createElement("tr", {
                                                  className: "border-b border-bottom-gray-600"
                                                }, React.createElement("td", {
                                                      className: "px-4 py-2"
                                                    }, x.msisdn), React.createElement("td", {
                                                      className: "px-4 py-2"
                                                    }, x.email), React.createElement("td", {
                                                      className: "px-4 py-2"
                                                    }, React.createElement("div", {
                                                          className: "text-10-px px-2 py-1 rounded-2xl flex gap-1 items-center\n                                    border border-gray-400 w-fit font-normal text-aurometalsaurus"
                                                        }, React.createElement("div", {
                                                              className: "h-3 w-3 rounded-full " + bgStatusColor(x.status)
                                                            }), x.status.toUpperCase())), React.createElement("td", {
                                                      className: "px-4 py-2"
                                                    }, x.status !== "completed" ? React.createElement("button", {
                                                            className: "bg-ghostwhite border border-gray-400\n                                     shadow-inner flex rounded py-2 px-3 text-xs\n                                     hover:bg-pigmentblue hover:text-white",
                                                            onClick: (function (param) {
                                                                var newWindow = window.open(x.survey_url, "Name", "sfs");
                                                                if (!(newWindow == null)) {
                                                                  newWindow.addEventListener("drop", (function (param) {
                                                                          return Curry._1(setState, (function (param) {
                                                                                        return /* Loading */0;
                                                                                      }));
                                                                        }));
                                                                  return ;
                                                                }
                                                                
                                                              })
                                                          }, "Take Survey") : React.createElement(React.Fragment, undefined)));
                                    }), state._0))))) : React.createElement("p", undefined, "Loading"));
}

var make = TelephonicSurveys;

exports.Transaction = Transaction;
exports.fetchTransactions = fetchTransactions;
exports.make = make;
/* react Not a pure module */

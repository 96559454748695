// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var TicketClose$ReactHooksTemplate = require("./ticketClose.bs.js");

function TicketCloseView(Props) {
  var activity = Props.activity;
  return React.createElement("div", undefined, React.createElement("div", {
                  className: "flex justify-between text-darkcharcoal"
                }, React.createElement("div", {
                      className: "text-sm font-semibold"
                    }, "Ticket Closed"), React.createElement("div", {
                      className: "text-xs font-normal"
                    }, TicketClose$ReactHooksTemplate.user_email(activity))), React.createElement("div", {
                  className: "mt-2 text-xs font-normal"
                }, TicketClose$ReactHooksTemplate.comment(activity)));
}

var make = TicketCloseView;

exports.make = make;
/* react Not a pure module */

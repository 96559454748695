// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var $$Array = require("rescript/lib/js/array.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Utils$ReactHooksTemplate = require("../common/utils.bs.js");
var RandomId$ReactHooksTemplate = require("./RandomId.bs.js");
var CheckboxInput$ReactHooksTemplate = require("../common/CheckboxInput.bs.js");
var MultipleSelect$ReactHooksTemplate = require("./MultipleSelect.bs.js");
var SurveyLanguage$ReactHooksTemplate = require("./surveyLanguage.bs.js");
var MandatoryMessage$ReactHooksTemplate = require("./MandatoryMessage.bs.js");

function MultipleSelectDefaultForm(Props) {
  var multipleSelect = Props.multipleSelect;
  var language = Props.language;
  var mandatory = Props.mandatory;
  var onChange = Props.onChange;
  var borderCss = Props.borderCss;
  var matchChoicesWithAnswer = function (choice) {
    var xs = MultipleSelect$ReactHooksTemplate.getAnswer(multipleSelect);
    if (xs !== undefined) {
      return Belt_List.has(xs, choice, (function (xs, ys) {
                    return Caml_obj.caml_equal(MultipleSelect$ReactHooksTemplate.choiceId(xs), MultipleSelect$ReactHooksTemplate.choiceId(ys));
                  }));
    } else {
      return false;
    }
  };
  return React.createElement("div", {
              className: "w-full py-8 px-16 small:px-6 " + borderCss
            }, React.createElement("div", undefined, React.createElement("div", {
                      className: "text-lg font-medium text-darkcharcoal py-6"
                    }, MultipleSelect$ReactHooksTemplate.getTextTranslation(multipleSelect, SurveyLanguage$ReactHooksTemplate.name(language))), React.createElement("div", {
                      className: "text-base font-normal text-darkcharcoal pb-8"
                    }, MultipleSelect$ReactHooksTemplate.getDescriptionTranslation(multipleSelect, SurveyLanguage$ReactHooksTemplate.name(language)))), React.createElement("div", {
                  className: "flex w-full"
                }, React.createElement("div", {
                      className: "flex flex-col"
                    }, React.createElement("div", {
                          className: "pl-2"
                        }, $$Array.of_list(Belt_List.map(MultipleSelect$ReactHooksTemplate.nonEmptyChoices(multipleSelect), (function (choice) {
                                    return React.createElement(React.Fragment, undefined, React.createElement("div", {
                                                    key: RandomId$ReactHooksTemplate.toString(MultipleSelect$ReactHooksTemplate.choiceId(choice)),
                                                    className: "text-sm font-normal"
                                                  }, React.createElement(CheckboxInput$ReactHooksTemplate.make, {
                                                        checked: matchChoicesWithAnswer(choice),
                                                        label: MultipleSelect$ReactHooksTemplate.getChoiceTextTranslation(multipleSelect, choice, SurveyLanguage$ReactHooksTemplate.name(language)),
                                                        handleChange: (function (param) {
                                                            return Curry._1(onChange, MultipleSelect$ReactHooksTemplate.setAnswer(multipleSelect, choice));
                                                          }),
                                                        className: "mb-2",
                                                        checkboxClassName: "custom_checkbox"
                                                      })), React.createElement("div", undefined, matchChoicesWithAnswer(choice) && MultipleSelect$ReactHooksTemplate.getAllowNonSpecifiedText(choice) ? React.createElement("input", {
                                                          className: "h-48-px py-2 px-4 bg-alicebluepurple border-b border-gray-500 focus:border-gray-600 text-xs w-full",
                                                          placeholder: "Type Here",
                                                          type: "text",
                                                          onChange: (function (param) {
                                                              return Curry._1(onChange, MultipleSelect$ReactHooksTemplate.setNotSpecifiedTextForAnswer(multipleSelect, MultipleSelect$ReactHooksTemplate.setNotSpecifiedText(Utils$ReactHooksTemplate.formTargetValue(param), choice)));
                                                            })
                                                        }) : React.createElement(React.Fragment, undefined)));
                                  })))))), mandatory ? React.createElement(MandatoryMessage$ReactHooksTemplate.make, {
                    language: language
                  }) : React.createElement(React.Fragment, undefined));
}

var make = MultipleSelectDefaultForm;

exports.make = make;
/* react Not a pure module */
